import $ from 'jquery';
import BubbleView from './bubble-view';
import highlightDisabler from '../utils/highlight-disabler';
import templateGenerator from '../utils/template-generator';

var _containerId = '.question-set-container';
var _superView = BubbleView.create(_containerId);
var _showAnswersId = '.view-answers-button';
var _hideAnswersId = '.hide-answers-button';
var _gradeableDetails;
var _unGradeableDetails;
var _readingSelector = '.question-set-results';

export default {
   setGradeableDetails: function(text) {
      _gradeableDetails = text;
   },
   setUngradeableDetails: function(text) {
      _unGradeableDetails = text;
   },
   getViewMarkup: function() {
      return templateGenerator.generateQuestionSetReviewStatusMarkup(_gradeableDetails, _unGradeableDetails) + _superView.getOverlayMarkup();
   },
   getHideAnswersControlRef: function() {
      return _hideAnswersId;
   },
   getShowAnswersControlRef: function() {
      return _showAnswersId;
   },
   showView: function() {
      var $container = $(_containerId);
      $container.removeClass('minimized');
      _superView.showView();

      var $readableElement = $container.find(_readingSelector);
      $readableElement.focus();
      highlightDisabler.disableHighlights($readableElement);
   },
   hideView: function() {
      $(_containerId).addClass('minimized');
      _superView.hideView();
   },
   removeView: _superView.removeView,
   updateViewPosition: _superView.updateViewPosition,
   setBottomOffset: _superView.setBottomOffset,
   pointAt: _superView.pointAt

};
