import playerStringNames from '../constants/player-string-names';
import localizationStrings from '../models/localization-strings';
import templateGenerator from '../utils/template-generator';
import events from '../constants/events';

var ScormSessionEndedView = function($container) {
   var _$scormSessionEndedWrapper = $container.find('.scorm-session-ended-wrapper');
   var scormSessionEndedMarkup = templateGenerator.generateScormSessionEndedMarkup(localizationStrings.getPlayerString(playerStringNames.scormSessionEndedText));
   _$scormSessionEndedWrapper.html(scormSessionEndedMarkup);
   $container.trigger(events.Modals.Open);
};

export default {
   create: ScormSessionEndedView
};
