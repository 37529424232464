import VideoElementView from './media-views/video-element-view';
import YoutubeElementView from './media-views/youtube-element-view';
import PlaylistElementView from './media-views/playlist-element-view';
import ImageView from './media-views/image-view';
import mediaType from '../constants/media-type';
import Promise from 'promise-polyfill';
import templateGenerator from '../utils/template-generator';
import cssClasses from '../constants/css-classes';

/**
 * Interface for a media view
 *
 * @interface MediaView
 *
 * @property {jQuery} mediaWrapper - jQuery wrapper around media element
 * @property {HTMLImageElement|HTMLVideoElement} mediaElement - reference to the media element.
 * @property {Boolean} containsWebTrackCaptions - Does the view contain web track captions
 * @property {Number} mediaWidth - Width of the media
 * @property {Number} mediaHeight - Height of the media
 * @property {string} playerControlType - Type of controls to use with view.
 * @property {String} type - The view type
 * @property {Boolean} supportsClickToPlayControls - View supports click to play controls
 * @property {Boolean} supportsSCORM - Does the media view support SCORM.
 * @property {TSC.VideoPlaybackQualityMetrics} playbackQualityMetrics - Video playback quality metrics instance for view
 * @property {String} currentSrc - Current source string
 * @property {Number} currentTime - Current time of the media
 * @property {Number} duration - The duration of the media
 * @property {Boolean} ended - Has the media ended
 * @property {Boolean} paused - Is the media paused
 * @property {Boolean} seeking - Is the media seeking
 * @property {Number} volume - Volume of the media
 * @property {Boolean} muted - Is media muted
 * @property {Number} playbackRate - Playback rate of the media
 * @property {Array} buffered - The array of segments the media has buffered
 * @property {Array} played - The array of media segments that have been played
 * @property {Array} seekable - The array of media segments that are seekable
 */

/**
 * @memberof TSC
 * @class MediaViewFactory
 * @classdesc Media View Factory
 *  @param {JQuery} $rootContainer - Smart Player top-level container
 *  @param {JQuery} $mediaContainer - Smart Player container of media viewport
 *  @param {string} containerElementUniqueId - Smart Player main container id
 *  @param {TSC.MediaList} mediaList - the media sources to use when creating the view
 *  @param {Function} firstPlayCallback - callback function called on the first play click
 *  @param {Function} triggerRumDataEvent - Callback function to use when triggering rum data event
 *
 */
var MediaViewFactory = function($rootContainer, $mediaContainer, containerElementUniqueId, mediaList, firstPlayCallback, triggerRumDataEvent) {
   var includeControls = false;
   var haveYoutubeEmbedUrl = mediaList.isMediaTypeAvailable(mediaType.YOUTUBE);

   var createBaseVideoViewMarkup = function() {
      var playerMarkup = templateGenerator.generateVideoViewMarkup(containerElementUniqueId);
      $mediaContainer.html(playerMarkup);
   };

   if (!mediaList.hasImage) {
      createBaseVideoViewMarkup();
   }

   if (haveYoutubeEmbedUrl) {
      $mediaContainer.addClass(cssClasses.mediaTypeYouTube);
      return YoutubeElementView.create($rootContainer, $mediaContainer, containerElementUniqueId, mediaList.mediaList, firstPlayCallback);
   }

   if (mediaList.isPlaylist) {
      return Promise.resolve(PlaylistElementView.create($rootContainer, $mediaContainer, mediaList, includeControls, triggerRumDataEvent, firstPlayCallback));
   }

   if (mediaList.hasImage) {
      return Promise.resolve(ImageView.create($rootContainer, $mediaContainer, containerElementUniqueId, mediaList.currentMedia.media));
   }

   return Promise.resolve(VideoElementView.create($rootContainer, $mediaContainer, mediaList.mediaList, includeControls, triggerRumDataEvent, firstPlayCallback));
};

export default {
   /**
    *  Factory method that returns a new MediaViewFactory.
    *  @function create
    *  @memberof TSC.MediaViewFactory
    *  @static
    *  @param {jQuery} $container - Smart Player main container jquery element
    *  @param {string} videoTagUniqueId - Smart Player main container id
    *  @param {MediaList} mediaList - the media sources to use when creating the view
    *  @param {Function} firstPlayCallback - callback function called on the first play click
    *  @param {Function} triggerRumDataEvent - Callback function to use when triggering rum data event
    *  @return new Promise that resolves a MediaViewFactory instance
    */
   createView: MediaViewFactory
};
