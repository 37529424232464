import HotspotsView from '../views/hotspots-view';
import events from '../constants/events';
import playerConfiguration from '../models/player-configuration';
import analyticsService from '../services/analytics-service';
import urlService from '../services/url-service';
import videoApiUtils from '../utils/video-api-utils';

/**
 * @memberof TSC
 * @class HotspotController
 * @classdesc Controller for managing XMP features
 * @param {jQuery} $container - Smart Player main container jquery element
 * @param {MediaView} mediaView - Media view for current media. Created by MediaViewFactory.
 * @param {TSC.XmpModel} xmpModel
 * @param {TSC.MediaList} mediaList
 * @param {TSC.TabController} tabController
 *
 */
var HotspotController = function($container, mediaView, xmpModel, mediaList, tabController) {
   var _hotspotsView;
   var _$hotspotsContainer = $container.find('.hotspots-container');

   var setupXmpFeatures = function() {
      _hotspotsView = HotspotsView.create($container, xmpModel, mediaView.mediaElement, tabController);
      _$hotspotsContainer.replaceWith(_hotspotsView.$hotspots);
   };

   var onLoadedMetadata = function() {
      var currentFile = mediaList.isPlaylist ? mediaList.currentMedia.media : undefined;
      _hotspotsView.init(mediaView.mediaWidth, mediaView.mediaHeight, currentFile);
   };

   var onVideoTimeUpdate = function() {
      _hotspotsView.update(mediaView.currentTimeInMilliseconds);
   };

   var updateHotSpotsViewTime = function() {
      _hotspotsView.update(mediaView.currentTimeInMilliseconds);
   };

   var processHotspotClick = function(e, eventData) {
      if (eventData.jumpFile !== undefined) {
         mediaList.currentMediaIndex = mediaList.getMediaIndexByUri(eventData.jumpFile);
         $container.trigger(events.Media.SourceChanged);
      } else if (eventData.jumpTime !== undefined && eventData.jumpTime !== '') {
         var timeInSeconds = Number(eventData.jumpTime) / 1000;
         $container.trigger(events.Media.SeekToTime, {time: videoApiUtils.getSafeSeekTime(mediaView.mediaElement, timeInSeconds), playAfterSeek: true});
      } else if (eventData.location !== undefined && eventData.location !== '') {
         if (playerConfiguration.getTrackEvents()) {
            analyticsService.sendEvent(events.Analytics.HotspotAction, eventData.location);
            analyticsService.sendGA4Event(events.GA4.HotspotAction, {opened_url: eventData.location}); // eslint-disable-line
         }
         var targetWindow = '_parent';
         if (eventData.newWindow !== undefined && eventData.newWindow === true) {
            targetWindow = '_blank';
         }

         mediaView.mediaElement.pause();
         urlService.openUrl(eventData.location, targetWindow);
      } else {
         mediaView.mediaElement.play();
      }
   };

   var onSeeked = function() {
      _hotspotsView.resetHasPaused();
      _hotspotsView.update(mediaView.currentTimeInMilliseconds);
   };

   var bindToEvents = function() {
      $container.on(events.Controls.HotspotClicked, processHotspotClick);

      mediaView.mediaWrapper.on('ratechange', updateHotSpotsViewTime, false);
      mediaView.mediaWrapper.on('play', updateHotSpotsViewTime, false);

      var eventsToBind = {
         loadedmetadata: onLoadedMetadata,
         timeupdate: onVideoTimeUpdate,
         seeked: onSeeked
      };
      mediaView.mediaWrapper.on(eventsToBind);
   };

   var unbindToEvents = function() {
      $container.off(events.Controls.HotspotClicked, processHotspotClick);

      mediaView.mediaWrapper.off('ratechange', updateHotSpotsViewTime, false);
      mediaView.mediaWrapper.off('play', updateHotSpotsViewTime, false);

      var eventsToBind = {
         loadedmetadata: onLoadedMetadata,
         timeupdate: onVideoTimeUpdate,
         seeked: onSeeked
      };
      mediaView.mediaWrapper.off(eventsToBind);
   };

   var destroy = function() {
      unbindToEvents();
   };

   var hasHotspots = xmpModel && xmpModel.hasHotspotItems;
   if (!hasHotspots) {
      throw new Error('Attempted to create hotspot controller with no hotspots in xmp');
   }
   setupXmpFeatures();
   bindToEvents();

   return Object.defineProperties({
      destroy: destroy
   }, {
   });
};

export default {
   /**
    *  Factory method that returns a new HotspotController.
    *  @function create
    *  @memberof TSC.HotspotController
    *  @static
    *  @param {jQuery} $container - Smart Player main container jquery element
    *  @param {MediaView} mediaView - Media view for current media. Created by MediaViewFactory.
    *  @param {TSC.XmpModel} xmpModel
    *  @param {TSC.MediaList} mediaList
    *  @param {TSC.TabController} tabController
    *  @return new HotspotController instance
    */
   create: HotspotController
};
