import $ from 'jquery';
import Promise from 'promise-polyfill';
import scormConstants from '../constants/scorm-constants';
import playerConfiguration from '../models/player-configuration';

var DEFAULT_TITLE_TEXT = '';
var MAX_PARENTS_TO_SEARCH = 500;
var SCORM_MANIFEST_FILE_NAME = 'imsmanifest.xml';
var CAMTASIA_SCORM_1_2_MANIFEST_STRING = '<metadatascheme>ADL SCORM 1.2</metadatascheme>';

/**
 * @memberof TSC
 * @class ScormApiWrapper
 * @classdesc Wrapper around Scorm API that handles Scorm 1.2 and 2004
 */
var ScormApiWrapper = function() {
   var scormApi = null;
   var noAPIFound = false;
   var apiVersion = scormConstants.versions.NOT_DETECTED;
   var scormManifestDocString = null;

   /**
    * Get last error message
    * @function getLastError
    * @return {String} - An error string that contains error number, string and diagnostic information.
    */
   var getLastError = function() {
      var errorNumber;
      var errorString;
      var diagnostic;

      if (!scormApi) {
         return 'SCORM API not found';
      }

      if (apiVersion >= scormConstants.versions.SCORM_2004) {
         errorNumber = scormApi.GetLastError();
         errorString = scormApi.GetErrorString(errorNumber);
         diagnostic = scormApi.GetDiagnostic(errorNumber);
      } else {
         errorNumber = scormApi.LMSGetLastError();
         errorString = scormApi.LMSGetErrorString(errorNumber);
         diagnostic = scormApi.LMSGetDiagnostic(errorNumber);
      }

      return 'Number: ' + errorNumber + '\nDescription: ' + errorString + '\nDiagnostic: ' + diagnostic;
   };

   /**
    * This function is used to communicate with the API, if it is found.
    * @memberof TSC.ScormApiWrapper
    * @function apiCall
    * @param {string} scormFunctionName - Name of the api function to call
    * @param {string} [dataModalID] - Name of the data model id defined in scorm constants object.
    * @param [dmeValue] - Value to write to data model
    * @return {string} - Value returned from the api call.
    */
   var apiCall = function(scormFunctionName, dataModalID, dmeValue) {
      var dataModel;
      var result;

      if (scormApi) {
         switch (scormFunctionName) {
            case scormConstants.fn.initialize:
               var initializedResult;
               if (apiVersion >= scormConstants.versions.SCORM_2004) {
                  initializedResult = scormApi.Initialize('');
               } else {
                  initializedResult = scormApi.LMSInitialize('');
               }

               if (initializedResult === 'true') {
                  if (apiVersion >= scormConstants.versions.SCORM_2004) {
                     result = scormApi.GetValue(scormConstants.scorm2004DataModel.cmiCompletionStatus);
                  } else {
                     result = scormApi.LMSGetValue(scormConstants.scorm1Dot2DataModel.cmiCompletionStatus);
                  }
               }
               break;
            case scormConstants.fn.terminate:
               if (apiVersion >= scormConstants.versions.SCORM_2004) {
                  result = scormApi.Terminate('');
               } else {
                  result = scormApi.LMSFinish('');
               }
               break;
            case scormConstants.fn.getValue:
               if (apiVersion >= scormConstants.versions.SCORM_2004) {
                  dataModel = scormConstants.scorm2004DataModel[dataModalID];
                  if (dataModel) {
                     result = scormApi.GetValue(dataModel);
                  }
               } else {
                  dataModel = scormConstants.scorm1Dot2DataModel[dataModalID];
                  if (dataModel) {
                     result = scormApi.LMSGetValue(dataModel);
                  }
               }
               break;
            case scormConstants.fn.setValue:
               if (apiVersion >= scormConstants.versions.SCORM_2004) {
                  dataModel = scormConstants.scorm2004DataModel[dataModalID];
                  if (dataModel) {
                     result = scormApi.SetValue(dataModel, dmeValue);
                  }
               } else {
                  // In 1.2, lesson_status hold both complete/incomplete and pass/fail.
                  // So if we've already used it for pass/fail, we don't want to
                  // overwrite with 'completed'
                  dataModel = scormConstants.scorm1Dot2DataModel[dataModalID];
                  var canSetValue = !!dataModel;
                  if (dataModalID === scormConstants.dataModelID.cmiCompletionStatus) {
                     var lessonStatus = scormApi.LMSGetValue(scormConstants.scorm1Dot2DataModel.cmiCompletionStatus);
                     if (dmeValue !== scormConstants.status.passed && dmeValue !== scormConstants.status.failed) {
                        canSetValue = lessonStatus !== scormConstants.status.failed && lessonStatus !== scormConstants.status.passed;
                     }
                  }

                  if (canSetValue) {
                     result = scormApi.LMSSetValue(dataModel, dmeValue);
                  }
               }
               break;
            case scormConstants.fn.commit:
               if (apiVersion >= scormConstants.versions.SCORM_2004) {
                  result = scormApi.Commit('');
               } else {
                  result = scormApi.LMSCommit('');
               }
               break;
         }

         if (playerConfiguration.logScormApiCalls) {
            console.info('SCORM apiCall - ', scormFunctionName, dataModalID, dmeValue, ' returned ', result); // eslint-disable-line
         }

         return result;
      }
   };

   var loadScormManifest = function() {
      return $.ajax({
         type: 'GET',
         url: SCORM_MANIFEST_FILE_NAME,
         dataType: 'text'
      });
   };

   var getScormManifestDoc = function() {
      if (scormManifestDocString === null) {
         return new Promise(function(resolve, reject) {
            loadScormManifest().done(function(data) {
               scormManifestDocString = data;
               resolve(scormManifestDocString);
            }).fail(function() {
               reject();
            });
         });
      }

      return Promise.resolve(scormManifestDocString);
   };

   var getScormVersionFromManifestDoc = function() {
      return getScormManifestDoc().then(function(manifestDocAsString) {
         // manifest doc will contain a specific string only with SCORM version 1.2 produced with Camtasia
         if (manifestDocAsString.indexOf(CAMTASIA_SCORM_1_2_MANIFEST_STRING) !== -1) {
            return scormConstants.versions.SCORM_1_2;
         }

         return scormConstants.versions.SCORM_2004;
      });
   };

   var getScormApiOnWindow = function(windowRef) {
      // scorm 1.2 and 2004 api are defined on window
      if (windowRef.API && windowRef.API_1484_11) {
         return getScormVersionFromManifestDoc().then(function(scormApiVersion) {
            apiVersion = scormApiVersion;
            if (scormApiVersion === scormConstants.versions.SCORM_1_2) {
               return windowRef.API;
            }
            return windowRef.API_1484_11;
         });
         // look for scorm 1.2 api
      } else if (windowRef.API) {
         apiVersion = scormConstants.versions.SCORM_1_2;
         return Promise.resolve(windowRef.API);
         // look for scorm 2004 api
      } else if (windowRef.API_1484_11) {
         apiVersion = scormConstants.versions.SCORM_2004;
         return Promise.resolve(windowRef.API_1484_11);
      }
      return Promise.reject();
   };

   /**
    * Searches all the parents of a given window until it finds an object named "API_1484_11" or "API".
    * If an object of that name is found, a reference to it is returned. Otherwise, this function returns null.
    * @function findAPI
    * @param {window} win - Window object
    * @return {object} - If the API object is found, it's returned, otherwise null is returned
    */
   var findAPI = function(win) {
      var findAPITries = 0;

      // Search each parent window until we find the API, encounter a window with no parent / the
      // same as the current window, or have reached our MAX_PARENTS_TO_SEARCH to search threshold
      while (!win.API && !win.API_1484_11 && win.parent !== win && findAPITries <= MAX_PARENTS_TO_SEARCH) {
         findAPITries++;
         win = win.parent;
      }

      return getScormApiOnWindow(win);
   };

   var lookForApiOnParentWindow = function() {
      try {
         if (window.parent !== window) {
            return findAPI(window.parent);
         }
         return Promise.reject();
      } catch (e) {
         return Promise.reject(e);
      }
   };

   var lookForApiOnWindowTopOpener = function() {
      if (window.top.opener !== null) {
         return findAPI(window.top.opener);
      }
      return Promise.reject();
   };

   /**
    * This function looks for an object named API, first in the current window's
    * frame hierarchy and then, if necessary, in the current window's opener window
    * hierarchy (if there is an opener window).
    * @function getAPI
    * @return {Promise} - A Promise that will resolve with API object if found or promise is rejected.
    */
   var getAPI = function() {
      return lookForApiOnParentWindow().catch(lookForApiOnWindowTopOpener);
   };

   /**
    * Returns the handle to API object if it was previously set, otherwise it returns null
    * @function getAPIHandle
    * @return {Promise} - A Promise that will resolve with the available SCORM api.
    */
   var getAPIHandle = function() {
      if (noAPIFound) {
         return Promise.reject();
      }

      if (!scormApi) {
         return getAPI();
      }
      return Promise.resolve(scormApi);
   };

   var getTitleFromManifestDoc = function() {
      try {
         var manifestXml = $.parseXML(scormManifestDocString);
         var $manifestXml = $(manifestXml);
         var $item = $manifestXml.find('item');
         var $title = $item.find('title');
         return $title.text();
      } catch (e) {
         return DEFAULT_TITLE_TEXT;
      }
   };

   /**
    * Get title for SCORM module
    * @function getTitle
    * @return {Promise} - A Promise that will resolve with the title found in the SCORM manifest file or empty string.
    */
   var getTitle = function() {
      if (!scormManifestDocString) {
         return getScormManifestDoc().then(getTitleFromManifestDoc).catch(function() {
            return DEFAULT_TITLE_TEXT;
         });
      }
      return Promise.resolve(getTitleFromManifestDoc());
   };

   /**
    * Initialize SCORM module
    * @function init
    * @return {Promise} - A Promise that will resolve with the state of initialize.
    */
   var init = function() {
      return getAPIHandle().then(function(api) {
         scormApi = api;
         if (playerConfiguration.logScormApiCalls) {
            console.info('SCORM initialized with version: ', apiVersion); // eslint-disable-line
         }
         return apiCall(scormConstants.fn.initialize);
      }).catch(function() {
         noAPIFound = true;
         return 'false';
      });
   };

   return Object.defineProperties({
      init: init,
      apiCall: apiCall,
      getTitle: getTitle,
      getLastError: getLastError
   }, {
      apiVersion: {
         get: function() {
            return apiVersion;
         }
      }
   });
};

/**
 *  Factory method that returns a new Scorm Api Wrapper object.
 *  @function create
 *  @memberof TSC.ScormApiWrapper
 *  @static
 *  @return new ScormApiWrapper instance
 */
export default {
   create: ScormApiWrapper
};
