import cssClasses from '../constants/css-classes';
import events from '../constants/events';
import tabIndex from '../constants/tab-index';
import templateGenerator from '../utils/template-generator';
import localizationStrings from '../models/localization-strings';
import languageCodes from '../constants/language-codes';

/**
 * @memberof TSC
 * @class CaptionsControlView
 * @classdesc Captions Control View in player controls
 * @param {Object} $container - jQuery element that contains a ??? element where the control will be injected
 */
var CaptionsControlView = function($container) {
   var _captionsEnabled = false;
   var _$closedCaptionButton;
   var _$closedCaptionGlyph;
   var _normalButtonClass = cssClasses.closedCaptionButtonGlyph;
   var _activeButtonClass = cssClasses.closedCaptionButtonActiveGlyph;

   if (localizationStrings.getLanguage() !== languageCodes.ENGLISH) {
      _normalButtonClass = cssClasses.closedCaptionNonEnglishButtonGlyph;
      _activeButtonClass = cssClasses.closedCaptionNonEnglishButtonActiveGlyph;
   }

   var createView = function() {
      var _markup = templateGenerator.generateCaptionsButtonMarkup();
      $container.find('.caption-wrapper').html(_markup);
      _$closedCaptionButton = $container.find('.closed-caption-button');
      _$closedCaptionGlyph = $container.find('.closed-caption-button-glyph');
   };

   var setCaptionState = function(captionsEnabled) {
      _captionsEnabled = captionsEnabled;

      if (_captionsEnabled) {
         _$closedCaptionButton.attr('aria-pressed', true);
         _$closedCaptionGlyph.removeClass(_normalButtonClass).addClass(_activeButtonClass);
         _$closedCaptionButton.removeClass(cssClasses.tertiaryButton).addClass(cssClasses.tertiaryButtonActive);
      } else {
         _$closedCaptionButton.attr('aria-pressed', false);
         _$closedCaptionGlyph.removeClass(_activeButtonClass).addClass(_normalButtonClass);
         _$closedCaptionButton.removeClass(cssClasses.tertiaryButtonActive).addClass(cssClasses.tertiaryButton);
      }
   };

   var onButtonClick = function(e) {
      e.preventDefault();
      $container.trigger(events.Captions.Toggled, {captionsEnabled: !_captionsEnabled, savePreference: true});
   };

   var onCaptionsToggle = function(e, eventData) {
      setCaptionState(eventData.captionsEnabled);
   };

   var disableControl = function() {
      _$closedCaptionButton.attr('tabindex', tabIndex.Disabled);
   };

   var enableControl = function() {
      _$closedCaptionButton.attr('tabindex', tabIndex.DomOrder);
   };

   var savedStateLoaded = function(e, eventData) {
      $container.trigger(events.Captions.Toggled, {captionsEnabled: eventData.captionsEnabled, savePreference: false});
   };

   var initializeHandlers = function() {
      _$closedCaptionButton.on('click', onButtonClick);
      $container.on(events.Controls.Disable, disableControl);
      $container.on(events.Controls.Enable, enableControl);
      $container.on(events.Captions.StateLoaded, savedStateLoaded);
      $container.on(events.Captions.Toggled, onCaptionsToggle);
   };

   var setCaptionButtonVisible = function(captionButtonVisible) {
      if (captionButtonVisible) {
         _$closedCaptionButton.removeClass(cssClasses.hide);
      } else {
         _$closedCaptionButton.addClass(cssClasses.hide);
      }
   };

   var destroy = function() {
      $container.off(events.Controls.Disable, disableControl);
      $container.off(events.Controls.Enable, enableControl);
      $container.off(events.Captions.StateLoaded, savedStateLoaded);
      _$closedCaptionButton.off('click', onButtonClick);
      $container.off(events.Captions.Toggled, onCaptionsToggle);
   };

   createView();
   initializeHandlers();

   return Object.defineProperties({
      setCaptionButtonVisible: setCaptionButtonVisible,
      destroy: destroy
   }, {});
};

export default {
   /**
    *  Factory method that returns a new AudioDescriptionControlView object.
    *  @function create
    *  @memberof TSC.CaptionsControlView
    *  @static
    *  @param {Object} $container - jQuery element that contains a ??? element where the control will be injected
    *  @return new CaptionsControlView instance
    */
   create: CaptionsControlView
};
