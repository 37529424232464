import languageCodes from '../../constants/language-codes';
import playerStringNames from '../../constants/player-string-names';

var strings = {};
strings[playerStringNames.xmpError] = '访问此视频的某些功能似乎存在问题。请联系视频作者。';
strings[playerStringNames.xmpSecurity] = '由于本地网络安全，您视频中的某些功能当前已被禁用。从网站或服务器播放此视频时，这些功能正常工作。';
strings[playerStringNames.videoNotSupported] = '您的浏览器无法播放此视频。';
strings[playerStringNames.noWebMSupport] = '此浏览器不支持 WebM 视频。';
strings[playerStringNames.search] = '搜索';
strings[playerStringNames.searchPlaceholder] = '搜索...';
strings[playerStringNames.playerRateText] = '播放速度';
strings[playerStringNames.playerRateOptionsText] = {
   0.25: '0.25',
   0.5: '0.5',
   0.75: '0.75',
   1: '正常',
   1.25: '1.25',
   1.5: '1.5',
   1.75: '1.75',
   2.0: '2.0'
};
strings[playerStringNames.ccLanguageText] = '隐藏式字幕';
strings[playerStringNames.searchDisabledFullScreen] = '搜索在全屏模式下被禁用。';
strings[playerStringNames.mediaNotFound] = '很抱歉，我们无法连接到此内容。请尝试刷新页面或<a href= \'https://www.techsmith.com/redirect.asp?target=media_not_found&ver=4.*.*&lang=enu&os=na&product=smartplayer \' target= \'_blank \'>单击此处获取更多帮助。</a>';
strings[playerStringNames.videoLoading] = '视频正在加载';
strings[playerStringNames.close] = '关闭';
strings[playerStringNames.txtRequiredMessage] = '内容所有者要求以下内容：';
strings[playerStringNames.txtSkip] = '没有问题的情况下继续';
strings[playerStringNames.txtBegin] = '提交并继续';
strings[playerStringNames.txtEnterFirstName] = '输入名字';
strings[playerStringNames.txtEnterLastName] = '输入姓氏';
strings[playerStringNames.txtEnterEmail] = '输入电子邮件地址';
strings[playerStringNames.txtHotspot] = '热点';
strings[playerStringNames.txtHotspotScreenReaderAlert] = '{{numberOfHotspots}}新热点';
strings[playerStringNames.txtHotspotScreenReaderAlertPlural] = '{{numberOfHotspots}}新热点';
strings[playerStringNames.txtJumpToMedia] = '跳转到媒体';
strings[playerStringNames.txtJumpToTime] = '跳转到时间';
strings[playerStringNames.txtOpenLink] = '打开链接';
strings[playerStringNames.txtOpenLinkHeader] = '本视频希望发送至：';
strings[playerStringNames.txtOpenLinkSubHeader] = '';
strings[playerStringNames.txtReview] = '重播最后一部分';
strings[playerStringNames.txtReplayVideo] = '重播视频';
strings[playerStringNames.txtAnswerQuestion] = '立即进行测验';
strings[playerStringNames.txtPrev] = '上一个';
strings[playerStringNames.txtNext] = '下一个';
strings[playerStringNames.txtHideQuiz] = '隐藏';
strings[playerStringNames.txtSubmit] = '提交答案';
strings[playerStringNames.txtSubmittingAnswers] = '正在提交答案。';
strings[playerStringNames.txtContinue] = '继续';
strings[playerStringNames.txtReviewAnswer] = '查看答案';
strings[playerStringNames.txtBadImageQuiz] = '很抱歉，请求的测验与图片不兼容，无法加载。请联系本测验的作者。';
strings[playerStringNames.txtVideoQuizNetworkErrorMessage] = '很抱歉，网络错误导致测验无法加载。该视频可供观看。要尝试重新加载测验，请刷新浏览器页面或检查您的网络连接。';
strings[playerStringNames.txtImageQuizNetworkErrorMessage] = '很抱歉，网络错误导致测验无法加载。该图片可供查看。要尝试重新加载测验，请刷新浏览器页面或检查您的网络连接。';
strings[playerStringNames.txtQuizErrorMessage] = '我们正在尝试保存您的回答。请确保您有有效的互联网连接。';
strings[playerStringNames.txtQuizOutOfDateMessage] = '测验问题已更改。请刷新播放器以获取最新的测验。';
strings[playerStringNames.txtQuizBadDefinitionMessage] = '<a href="https://www.techsmith.com/redirect.asp?target=bad_quiz_definition&ver=5.*.*&lang=enu&os=na&product=smartplayer" target="_blank">无效测试无法提交测试反馈请视频所有者参阅此帮助文档</a>';
strings[playerStringNames.txtQuizOutOfDateRefreshButtonText] = '刷新播放器';
strings[playerStringNames.txtLoaderMessageDefinition] = '测验正在加载。';
strings[playerStringNames.txtQuestionCount] = '问题 {{currentQuestion}}/{{totalQuestions}}';
strings[playerStringNames.txtResults] = '结果：';
strings[playerStringNames.txtCorrectString] = '正确';
strings[playerStringNames.txtIncorrectString] = '不正确';
strings[playerStringNames.txtCorrect] = '{{numberCorrect}}正确';
strings[playerStringNames.txtIncorrect] = '{{numberIncorrect}}不正确';
strings[playerStringNames.txtYourAnswer] = '您的答案：';
strings[playerStringNames.txtCorrectAnswer] = '正确答案：';
strings[playerStringNames.txtVisitSite] = '访问网站';
strings[playerStringNames.accessBtnAudioDescription] = '音频描述';
strings[playerStringNames.accessBtnClearSearch] = '清除搜索';
strings[playerStringNames.accessBtnRewind] = '向后退回 {{duration}} 秒';
strings[playerStringNames.accessBtnForward] = '向前跳过 {{duration}} 秒';
strings[playerStringNames.accessBtnPlay] = '播放视频';
strings[playerStringNames.accessBtnPrevious] = '上一个';
strings[playerStringNames.accessBtnNext] = '下一个';
strings[playerStringNames.accessBtnProgressBar] = '进度滑块';
strings[playerStringNames.accessBtnVolume] = '静音';
strings[playerStringNames.accessTxtVolume] = '音量';
strings[playerStringNames.accessBtnClosedCaption] = '隐藏式字幕';
strings[playerStringNames.accessBtnTableOfContents] = '显示章节(T)';
strings[playerStringNames.accessBtnSettings] = '播放器设置';
strings[playerStringNames.accessBtnPip] = '画中画';
strings[playerStringNames.accessBtnFullScreen] = '全屏';
strings[playerStringNames.accessBtnFullScreenExpand] = '在新选项卡中打开';
strings[playerStringNames.accessBtnTakeQuiz] = '单击以参加测验。';
strings[playerStringNames.accessBtnShowQuiz] = '显示测验';
strings[playerStringNames.accessBtnHideQuiz] = '隐藏测验';
strings[playerStringNames.accessBtnPreviousQuestion] = '上一个问题';
strings[playerStringNames.accessBtnNextQuestion] = '下一个问题';
strings[playerStringNames.accessQuizHidden] = '已隐藏测验';
strings[playerStringNames.accessQuizShown] = '已显示测验';
strings[playerStringNames.accessBtnShowAnswer] = '显示答案';
strings[playerStringNames.accessBtnHideAnswer] = '隐藏答案';
strings[playerStringNames.accessBtnAnswersSubmitted] = '答案已提交！';
strings[playerStringNames.accessBtnWereNotGraded] = ' 未分级。';
strings[playerStringNames.accessBtnWasNotGraded] = ' 未分级。';
strings[playerStringNames.accessBtnCorrectOutOf] = ' 正确答案比例';
strings[playerStringNames.accessBtnGradedQuestions] = ' 已分级问题。';
strings[playerStringNames.accessBtnClickToReplayLink] = '重播';
strings[playerStringNames.accessResultsLabelCorrectSuffix] = '是正确的';
strings[playerStringNames.accessResultsLabelIncorrectSuffix] = '是错误的';
strings[playerStringNames.accessResultsLabelNoResponseSuffix] = '您没有回答这个问题。';
strings[playerStringNames.accessResultsLabelNoResponseGradedSuffix] = '您没有回答此问题，并且已被标记为错误。';
strings[playerStringNames.scormApiNotFoundMessage] = '无法找到 LMS 的 API Implementation。\n不会发生与 LMS 的通信。';
strings[playerStringNames.accessListTableOfContents] = '章节';
strings[playerStringNames.accessListSearchResults] = '按外观顺序搜索结果';
strings[playerStringNames.accessListNoSearchResults] = '未找到结果';
strings[playerStringNames.accessQuestionSetConfirmationTitle] = '测验';
strings[playerStringNames.accessVideoPausedForQuiz] = '视频因测验而暂停';
strings[playerStringNames.accessQuizResults] = '测验结果';
strings[playerStringNames.accessQuestionFeedbackContext] = '问题反馈：';
strings[playerStringNames.accessProgressBarTimeConjunction] = ' 的 ';
strings[playerStringNames.accessPlayerSettingsClose] = '关闭播放器设置';
strings[playerStringNames.accessBtnTheaterMode] = '剧院模式';
strings[playerStringNames.accessBtnExitTheaterMode] = '默认模式';
strings[playerStringNames.confirmOpenUrlModalScreenReaderAlertText] = '该视频想要打开以下网址 URL_TOKEN。';
strings[playerStringNames.confirmOpenUrlConfirmButtonScreenReaderText] = '继续到新网站';
strings[playerStringNames.fastForwardDisabledScreenReaderText] = '该视频的快进功能已被禁用';
strings[playerStringNames.scormCompleteVideoLater] = '稍后完成视频';
strings[playerStringNames.scormCompleteVideo] = '完成视频';
strings[playerStringNames.scormExitVideo] = '退出视频';
strings[playerStringNames.scormConfirmCompleteModalText] = '您确定要退出视频吗？';
strings[playerStringNames.scormConfirmCompleteModalResumeVideoText] = '视频将在您下次打开视频时从上次停播的地方继续播放。';
strings[playerStringNames.scormConfirmCompleteModalRestartVideoText] = '视频将在下一次开始时重新播放。您将失去当前的进度。';
strings[playerStringNames.scormConfirmCompleteModalFailedToSaveProgressText] = '保存您的进度时出现问题。该视频将在您下次观看时将从头开始播放。';
strings[playerStringNames.scormConfirmCompleteModalDismissButtonText] = '返回视频';
strings[playerStringNames.scormConfirmCompleteModalDismissButtonScreenReaderText] = '返回视频';
strings[playerStringNames.scormConfirmCompleteModalConfirmButtonText] = '退出视频';
strings[playerStringNames.scormConfirmCompleteModalConfirmButtonScreenReaderText] = '退出视频';
strings[playerStringNames.scormSessionEndedText] = '您可以关闭选项卡或返回课程。';
strings[playerStringNames.searchOrJumpTo] = '搜索或跳转到';
strings[playerStringNames.searchTextCleared] = '搜索文字已清除。';
strings[playerStringNames.searchResultsUpdated] = '搜索结果已更新。{{number}} 结果。';
strings[playerStringNames.tableOfContentsDisplayed] = '显示的章节。';
strings[playerStringNames.tableOfContentsHidden] = '隐藏的章节。';
strings[playerStringNames.defaultMediaTitle] = 'TechSmith Smart Player';
strings[playerStringNames.hideThumbnailDueToQuiz] = '进行测验以预览';
strings[playerStringNames.quizAtTimeHeading] = '测验所在位置：';
strings[playerStringNames.defaultFirstChapterName] = '介绍';
strings[playerStringNames.acknowledgementLinkName] = '致谢';

var getLanguage = function() {
   return languageCodes.CHINESE;
};

var getReadableTimeByLocale = function(hours, minutes, seconds) {
   var timeStringList = [];
   if (hours > 0) {
      var hoursReadableString = hours + '小时';
      timeStringList.push(hoursReadableString);
   }
   if (minutes > 0) {
      var minutesReadableString = minutes + '分';
      timeStringList.push(minutesReadableString);
   }
   if (seconds > 0) {
      var secondsReadableString = seconds + '秒';
      timeStringList.push(secondsReadableString);
   }

   return timeStringList.join('');
};

export default Object.defineProperties({
   getReadableTimeByLocale: getReadableTimeByLocale
}, {
   language: {
      get: getLanguage
   },
   strings: {
      get: function() {
         return strings;
      }
   }
});
