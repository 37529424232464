var scormConstants = Object.freeze({
   fn: {
      initialize: 'initialize',
      terminate: 'terminate',
      getValue: 'getValue',
      setValue: 'setValue',
      commit: 'commit'
   },
   dataModelID: {
      cmiExit: 'cmiExit',
      cmiLocation: 'cmiLocation',
      cmiSuspendData: 'cmiSuspendData',
      cmiProgressMeasure: 'cmiProgressMeasure',
      cmiScaledPassingScore: 'cmiScaledPassingScore',
      cmiScoreMin: 'cmiScoreMin',
      cmiScoreMax: 'cmiScoreMax',
      cmiScoreRaw: 'cmiScoreRaw',
      cmiScoreScaled: 'cmiScoreScaled',
      cmiSuccessStatus: 'cmiSuccessStatus',
      cmiCompletionStatus: 'cmiCompletionStatus',
      cmiSessionTime: 'cmiSessionTime',
      adlNavRequest: 'adlNavRequest'
   },
   scorm1Dot2DataModel: {
      cmiExit: 'cmi.core.exit',
      cmiLocation: 'cmi.core.lesson_location',
      cmiSuspendData: 'cmi.suspend_data',
      cmiProgressMeasure: null,
      cmiScaledPassingScore: 'cmi.student_data.mastery_score',
      cmiScoreMin: 'cmi.core.score.min',
      cmiScoreMax: 'cmi.core.score.max',
      cmiScoreRaw: 'cmi.core.score.raw',
      cmiScoreScaled: null,
      cmiSuccessStatus: null,
      cmiCompletionStatus: 'cmi.core.lesson_status',
      cmiSessionTime: 'cmi.core.session_time',
      adlNavRequest: null
   },
   scorm2004DataModel: {
      cmiExit: 'cmi.exit',
      cmiLocation: 'cmi.location',
      cmiSuspendData: 'cmi.suspend_data',
      cmiProgressMeasure: 'cmi.progress_measure',
      cmiScaledPassingScore: 'cmi.scaled_passing_score',
      cmiScoreMin: 'cmi.score.min',
      cmiScoreMax: 'cmi.score.max',
      cmiScoreRaw: 'cmi.score.raw',
      cmiScoreScaled: 'cmi.score.scaled',
      cmiSuccessStatus: 'cmi.success_status',
      cmiCompletionStatus: 'cmi.completion_status',
      cmiSessionTime: 'cmi.session_time',
      adlNavRequest: 'adl.nav.request'
   },
   status: {
      completed: 'completed',
      incomplete: 'incomplete',
      notAttempted: 'not attempted',
      unknown: 'unknown',
      passed: 'passed',
      failed: 'failed',
      browsed: 'browsed'
   },
   exitStates: {
      suspend: 'suspend',
      logout: 'logout',
      complete: ''
   },
   navRequestStates: {
      exitAll: 'exitAll',
      suspendAll: 'suspendAll'
   },
   versions: {
      NOT_DETECTED: -1,
      SCORM_1_2: 0,
      SCORM_2004: 1
   },
   assessment: {
      minScore: 0,
      maxScore: 100,
      scoreNormalizationDivider: 100
   }
});

export default scormConstants;
