import languageCodes from '../../constants/language-codes';
import playerStringNames from '../../constants/player-string-names';

var strings = {};
strings[playerStringNames.xmpError] = 'Il semblerait qu\'il y ait un problème pour accéder à certaines fonctionnalités de cette vidéo. Veuillez contacter l\'auteur de la vidéo.';
strings[playerStringNames.xmpSecurity] = 'Certaines fonctionnalités de votre vidéo sont actuellement désactivées pour des raisons de sécurité du réseau local. Elles fonctionnent correctement lorsque la vidéo est lue à partir d\'un site Web ou d\'un serveur.';
strings[playerStringNames.videoNotSupported] = 'Votre navigateur ne peut pas lire cette vidéo.';
strings[playerStringNames.noWebMSupport] = 'Ce navigateur ne prend pas en charge la vidéo WebM.';
strings[playerStringNames.search] = 'Rechercher';
strings[playerStringNames.searchPlaceholder] = 'Rechercher...';
strings[playerStringNames.playerRateText] = 'Vitesse de lecture';
strings[playerStringNames.playerRateOptionsText] = {
   0.25: '0,25',
   0.5: '0,5',
   0.75: '0,75',
   1: 'normale',
   1.25: '1,25',
   1.5: '1,5',
   1.75: '1,75',
   2.0: '2,0'
};
strings[playerStringNames.ccLanguageText] = 'Sous-titres activables';
strings[playerStringNames.searchDisabledFullScreen] = 'La recherche est désactivée en mode Plein écran.';
strings[playerStringNames.mediaNotFound] = 'Nous sommes désolés, nous n\'avons pas pu établir de connexion avec ce contenu. Essayez d\'actualiser la page ou <a href=\'https://www.techsmith.com/redirect.asp?target=media_not_found&ver=4.*.*&lang=enu&os=na&product=smartplayer\' target=\'_blank\'>cliquez ici pour obtenir de l\'aide.</a>';
strings[playerStringNames.videoLoading] = 'La vidéo est en cours de chargement.';
strings[playerStringNames.close] = 'Fermer';
strings[playerStringNames.txtRequiredMessage] = 'Le propriétaire du contenu demande les informations suivantes :';
strings[playerStringNames.txtSkip] = 'Continuer sans les questions';
strings[playerStringNames.txtBegin] = 'Envoyer et continuer';
strings[playerStringNames.txtEnterFirstName] = 'Saisir le prénom';
strings[playerStringNames.txtEnterLastName] = 'Saisir le nom';
strings[playerStringNames.txtEnterEmail] = 'Saisir l\'adresse e-mail';
strings[playerStringNames.txtHotspot] = 'Zone dynamique';
strings[playerStringNames.txtHotspotScreenReaderAlert] = '{{numberOfHotspots}} nouvelle zone interactive';
strings[playerStringNames.txtHotspotScreenReaderAlertPlural] = '{{numberOfHotspots}} nouvelles zones interactives';
strings[playerStringNames.txtJumpToMedia] = 'Atteindre un élément multimédia';
strings[playerStringNames.txtJumpToTime] = 'Atteindre une heure';
strings[playerStringNames.txtOpenLink] = 'Ouvrir le lien';
strings[playerStringNames.txtOpenLinkHeader] = 'Cette vidéo souhaite vous renvoyer vers :';
strings[playerStringNames.txtOpenLinkSubHeader] = '';
strings[playerStringNames.txtReview] = 'Revoir la dernière section';
strings[playerStringNames.txtReplayVideo] = 'Revoir la vidéo';
strings[playerStringNames.txtAnswerQuestion] = 'Répondre au questionnaire';
strings[playerStringNames.txtPrev] = 'Précédent';
strings[playerStringNames.txtNext] = 'Suivant';
strings[playerStringNames.txtHideQuiz] = 'Masquer';
strings[playerStringNames.txtSubmit] = 'Envoyer les réponses';
strings[playerStringNames.txtSubmittingAnswers] = 'Envoi des réponses en cours.';
strings[playerStringNames.txtContinue] = 'Continuer';
strings[playerStringNames.txtReviewAnswer] = 'Afficher les réponses';
strings[playerStringNames.txtBadImageQuiz] = 'Nous sommes désolés, le questionnaire demandé étant incompatible avec les images, il ne peut pas être chargé. Veuillez contacter l\'auteur de ce questionnaire.';
strings[playerStringNames.txtVideoQuizNetworkErrorMessage] = 'Nous sommes désolés, une erreur réseau nous empêche de charger le questionnaire. Vous pouvez afficher la vidéo. Pour essayer de recharger le questionnaire, actualisez la page Web ou vérifiez la connexion réseau.';
strings[playerStringNames.txtImageQuizNetworkErrorMessage] = 'Nous sommes désolés, une erreur réseau nous empêche de charger le questionnaire. Vous pouvez afficher l\'image. Pour essayer de recharger le questionnaire, actualisez la page Web ou vérifiez la connexion réseau.';
strings[playerStringNames.txtQuizErrorMessage] = 'Nous essayons d\'enregistrer votre réponse. Vérifiez que vous disposez d\'une connexion Internet active.';
strings[playerStringNames.txtQuizOutOfDateMessage] = 'Les questions ont changé. Veuillez actualiser le lecteur pour accéder à la dernière version du questionnaire.';
strings[playerStringNames.txtQuizBadDefinitionMessage] = 'Questionnaire non valide. Impossible d’envoyer les commentaires sur le questionnaire. Veuillez demander au propriétaire de la vidéo de consulter <a href="https://www.techsmith.com/redirect.asp?target=bad_quiz_definition&ver=5.*.*&lang=enu&os=na&product=smartplayer" target="_blank">cet article d’aide</a>.';
strings[playerStringNames.txtQuizOutOfDateRefreshButtonText] = 'Actualiser';
strings[playerStringNames.txtLoaderMessageDefinition] = 'Le questionnaire est en cours de chargement.';
strings[playerStringNames.txtQuestionCount] = 'Question {{currentQuestion}} sur {{totalQuestions}}';
strings[playerStringNames.txtResults] = 'Résultats : ';
strings[playerStringNames.txtCorrectString] = 'Correct';
strings[playerStringNames.txtIncorrectString] = 'Incorrect';
strings[playerStringNames.txtCorrect] = '{{numberCorrect}} Correct';
strings[playerStringNames.txtIncorrect] = '{{numberIncorrect}} Incorrect';
strings[playerStringNames.txtYourAnswer] = 'Votre réponse : ';
strings[playerStringNames.txtCorrectAnswer] = 'Réponse correcte : ';
strings[playerStringNames.txtVisitSite] = 'Consulter le site';
strings[playerStringNames.accessBtnAudioDescription] = 'Description audio';
strings[playerStringNames.accessBtnClearSearch] = 'Effacer la recherche';
strings[playerStringNames.accessBtnRewind] = 'Reculer de {{duration}} secondes';
strings[playerStringNames.accessBtnForward] = 'Avancer de {{duration}} secondes';
strings[playerStringNames.accessBtnPlay] = 'Lire la vidéo';
strings[playerStringNames.accessBtnPrevious] = 'Précédent';
strings[playerStringNames.accessBtnNext] = 'Suivant';
strings[playerStringNames.accessBtnProgressBar] = 'Curseur de progression';
strings[playerStringNames.accessBtnVolume] = 'Désactiver le son';
strings[playerStringNames.accessTxtVolume] = 'Volume';
strings[playerStringNames.accessBtnClosedCaption] = 'Sous-titre activable';
strings[playerStringNames.accessBtnTableOfContents] = 'Afficher les chapitres (T)';
strings[playerStringNames.accessBtnSettings] = 'Paramètres du Player';
strings[playerStringNames.accessBtnPip] = 'Incrustation d’images';
strings[playerStringNames.accessBtnFullScreen] = 'Plein écran';
strings[playerStringNames.accessBtnFullScreenExpand] = 'Ouvrir dans un nouvel onglet';
strings[playerStringNames.accessBtnTakeQuiz] = 'Cliquez pour répondre au questionnaire.';
strings[playerStringNames.accessBtnShowQuiz] = 'Afficher le questionnaire';
strings[playerStringNames.accessBtnHideQuiz] = 'Masquer le questionnaire';
strings[playerStringNames.accessBtnPreviousQuestion] = 'Question précédente';
strings[playerStringNames.accessBtnNextQuestion] = 'Question suivante';
strings[playerStringNames.accessQuizHidden] = 'Le questionnaire a été masqué';
strings[playerStringNames.accessQuizShown] = 'Le questionnaire est maintenant affiché';
strings[playerStringNames.accessBtnShowAnswer] = 'Afficher les réponses';
strings[playerStringNames.accessBtnHideAnswer] = 'Masquer les réponses';
strings[playerStringNames.accessBtnAnswersSubmitted] = 'Réponses envoyées.';
strings[playerStringNames.accessBtnWereNotGraded] = ' n\'ont pas été notées.';
strings[playerStringNames.accessBtnWasNotGraded] = ' n\'a pas été notée.';
strings[playerStringNames.accessBtnCorrectOutOf] = ' « Correct » sur ';
strings[playerStringNames.accessBtnGradedQuestions] = ' questions notées.';
strings[playerStringNames.accessBtnClickToReplayLink] = 'Relire';
strings[playerStringNames.accessResultsLabelCorrectSuffix] = 'est correcte.';
strings[playerStringNames.accessResultsLabelIncorrectSuffix] = 'est incorrecte.';
strings[playerStringNames.accessResultsLabelNoResponseSuffix] = 'vous n’avez pas répondu à cette question.';
strings[playerStringNames.accessResultsLabelNoResponseGradedSuffix] = 'vous n’avez pas répondu à cette question, qui a été marquée comme incorrecte.';
strings[playerStringNames.scormApiNotFoundMessage] = 'Impossible de localiser l\'implémentation API du LMS.\nLa communication avec le LMS sera impossible.';
strings[playerStringNames.accessListTableOfContents] = 'Chapitres';
strings[playerStringNames.accessListSearchResults] = 'Résultats de la recherche par ordre d\'affichage';
strings[playerStringNames.accessListNoSearchResults] = 'Aucun résultat';
strings[playerStringNames.accessQuestionSetConfirmationTitle] = 'Questionnaire';
strings[playerStringNames.accessVideoPausedForQuiz] = 'Vidéo sur pause pour le questionnaire';
strings[playerStringNames.accessQuizResults] = 'Résultats du questionnaire';
strings[playerStringNames.accessQuestionFeedbackContext] = 'Commentaires sur la question : ';
strings[playerStringNames.accessProgressBarTimeConjunction] = ' sur ';
strings[playerStringNames.accessPlayerSettingsClose] = 'Fermer les paramètres du Player';
strings[playerStringNames.accessBtnTheaterMode] = 'Mode cinéma';
strings[playerStringNames.accessBtnExitTheaterMode] = 'Mode standard';
strings[playerStringNames.confirmOpenUrlModalScreenReaderAlertText] = 'La vidéo tente d’ouvrir l’URL suivante URL_TOKEN.';
strings[playerStringNames.confirmOpenUrlConfirmButtonScreenReaderText] = 'Continuer vers l’autre site Web';
strings[playerStringNames.fastForwardDisabledScreenReaderText] = 'L’avance rapide est désactivée pour cette vidéo';
strings[playerStringNames.scormCompleteVideoLater] = 'Terminer la vidéo plus tard';
strings[playerStringNames.scormCompleteVideo] = 'Terminer la vidéo';
strings[playerStringNames.scormExitVideo] = 'Quitter la vidéo';
strings[playerStringNames.scormConfirmCompleteModalText] = 'Voulez-vous vraiment quitter cette vidéo ?';
strings[playerStringNames.scormConfirmCompleteModalResumeVideoText] = 'Elle reprendra au même endroit la prochaine fois que vous la visionnerez.';
strings[playerStringNames.scormConfirmCompleteModalRestartVideoText] = 'Elle reprendra au début la prochaine fois que vous la visionnerez. Tout progrès sera perdu.';
strings[playerStringNames.scormConfirmCompleteModalFailedToSaveProgressText] = 'Un problème est survenu lors de l’enregistrement de la progression. La lecture reprendra au début la prochaine fois que vous regarderez cette vidéo.';
strings[playerStringNames.scormConfirmCompleteModalDismissButtonText] = 'Revenir à la vidéo';
strings[playerStringNames.scormConfirmCompleteModalDismissButtonScreenReaderText] = 'Revenir à la vidéo';
strings[playerStringNames.scormConfirmCompleteModalConfirmButtonText] = 'Quitter la vidéo';
strings[playerStringNames.scormConfirmCompleteModalConfirmButtonScreenReaderText] = 'Quitter la vidéo';
strings[playerStringNames.scormSessionEndedText] = 'Vous pouvez fermer l’onglet ou revenir à votre cours.';
strings[playerStringNames.searchOrJumpTo] = 'Rechercher ou accéder à un élément.';
strings[playerStringNames.searchTextCleared] = 'Texte de recherche supprimé.';
strings[playerStringNames.searchResultsUpdated] = 'Résultats de recherche mis à jour. {{number}} résultats.';
strings[playerStringNames.tableOfContentsDisplayed] = 'Chapitres affichés.';
strings[playerStringNames.tableOfContentsHidden] = 'Chapitres masqués.';
strings[playerStringNames.defaultMediaTitle] = 'TechSmith Smart Player';
strings[playerStringNames.hideThumbnailDueToQuiz] = 'Répondez au questionnaire pour afficher l’aperçu';
strings[playerStringNames.quizAtTimeHeading] = 'Questionnaire situé à';
strings[playerStringNames.defaultFirstChapterName] = 'introduction';
strings[playerStringNames.acknowledgementLinkName] = 'Crédits';

var getLanguage = function() {
   return languageCodes.FRENCH;
};

var getReadableTimeByLocale = function(hours, minutes, seconds) {
   var timeStringList = [];
   if (hours > 0) {
      var hoursReadableString = hours + ' heure';
      hoursReadableString += hours > 1 ? 's' : '';
      timeStringList.push(hoursReadableString);
   }
   if (minutes > 0) {
      var minutesReadableString = minutes + ' minute';
      minutesReadableString += minutes > 1 ? 's' : '';
      timeStringList.push(minutesReadableString);
   }
   if (seconds > 0) {
      var secondsReadableString = seconds + ' seconde';
      secondsReadableString += seconds > 1 ? 's' : '';
      timeStringList.push(secondsReadableString);
   }

   return timeStringList.join(' et ');
};

export default Object.defineProperties({
   getReadableTimeByLocale: getReadableTimeByLocale
}, {
   language: {
      get: getLanguage
   },
   strings: {
      get: function() {
         return strings;
      }
   }
});
