import playerStrings from '../constants/player-string-names';

var strings = {};

var setString = function(stringKey, value) {
   if (!playerStrings[stringKey]) {
      throw new Error('Player string not found');
   }
   strings[playerStrings[stringKey]] = value;
};

var clearStrings = function() {
   strings = {};
};

export default Object.defineProperties({
   setString: setString,
   clearStrings: clearStrings
}, {
   strings: {
      get: function() {
         return strings;
      }
   }
});
