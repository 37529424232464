import questionType from '../constants/question-type';

var SHORT_ANSWER_SNAPSHOT_TEXT = 'n/a';

/**
 * @memberof TSC
 * @class Question
 * @param {tscme.Question} tscmeQuestionModel - Question model from Media-Extensions-js
 * @classdesc Question model
 */
var Question = function(tscmeQuestionModel) {
   var _isGraded = false;
   var _userResponse;
   var _isResponseCorrect;
   var _attemptDuration = 0;
   var _correctAnswer;
   var _responseLocked = false;
   var _feedbackActionExecuted = false;

   var getIsGraded = function(type, correctAnswer, answers) {
      return type === questionType.CHOICE && correctAnswer && correctAnswer > 0 ||
         type === questionType.FILL_IN && answers.length > 0;
   };

   _isGraded = getIsGraded(tscmeQuestionModel.type, tscmeQuestionModel.correctAnswer, tscmeQuestionModel.answers);
   _correctAnswer = tscmeQuestionModel.correctAnswer;

   var toJSON = function() {
      return {
         id: tscmeQuestionModel.id,
         type: tscmeQuestionModel.type,
         questionText: tscmeQuestionModel.text,
         choices: tscmeQuestionModel.answers.map(function(answer) {
            return answer.text;
         }),
         correctAnswer: _correctAnswer
      };
   };

   var getSnapShot = function() {
      // due to character limitations with SCORM data we will not include short answer user response
      var snapshotUserResponse = _userResponse;
      if (tscmeQuestionModel.type === questionType.SHORT && _userResponse) {
         snapshotUserResponse = SHORT_ANSWER_SNAPSHOT_TEXT;
      }

      return {
         id: tscmeQuestionModel.id,
         rl: _responseLocked,
         ad: _attemptDuration,
         ur: snapshotUserResponse,
         irc: _isResponseCorrect
      };
   };

   var populateQuestion = function(questionSnapShot) {
      if (questionSnapShot.id === tscmeQuestionModel.id) {
         _responseLocked = questionSnapShot.rl;
         _attemptDuration = questionSnapShot.ad;
         _userResponse = questionSnapShot.ur;
         _isResponseCorrect = questionSnapShot.irc;
      }
   };

   var getQuizServiceData = function(percentOfVideoSegmentWatched) {
      var questionData = {
         type: tscmeQuestionModel.type,
         id: tscmeQuestionModel.id,
         duration: _attemptDuration,
         response: _userResponse,
         correct: _isResponseCorrect,
         segmentWatched: percentOfVideoSegmentWatched
      };

      // no correct answer for MC question, don't include correct var
      if (tscmeQuestionModel.type === questionType.CHOICE && _correctAnswer === 0) {
         delete questionData.correct;
      }

      return questionData;
   };

   var getFeedbackForReason = function(reason) {
      var feedback = null;

      tscmeQuestionModel.feedback.forEach(function(feedbackItem) {
         if (feedbackItem.reason === reason) {
            feedback = feedbackItem;
         }
      });

      return feedback;
   };

   return Object.defineProperties({
      toJSON: toJSON,
      getSnapShot: getSnapShot,
      populateQuestion: populateQuestion,
      getQuizServiceData: getQuizServiceData,
      getFeedbackForReason: getFeedbackForReason
   }, {
      id: {
         get: function() {
            return tscmeQuestionModel.id;
         }
      },
      type: {
         get: function() {
            return tscmeQuestionModel.type;
         }
      },
      text: {
         get: function() {
            return tscmeQuestionModel.text;
         }
      },
      correctAnswer: {
         get: function() {
            return _correctAnswer;
         },
         set: function(correctAnswer) {
            _correctAnswer = correctAnswer;
         }
      },
      answers: {
         get: function() {
            return tscmeQuestionModel.answers;
         }
      },
      feedback: {
         get: function() {
            return tscmeQuestionModel.feedback;
         }
      },
      isGraded: {
         get: function() {
            return _isGraded;
         },
         set: function(isGraded) {
            _isGraded = isGraded;
         }
      },
      userResponse: {
         get: function() {
            return _userResponse;
         },
         set: function(userResponse) {
            if (_responseLocked) {
               return;
            }
            _userResponse = userResponse;
         }
      },
      isResponseCorrect: {
         get: function() {
            return _isResponseCorrect;
         },
         set: function(isResponseCorrect) {
            _isResponseCorrect = isResponseCorrect;
         }
      },
      attemptDuration: {
         get: function() {
            return _attemptDuration;
         },
         set: function(attemptDuration) {
            if (_responseLocked) {
               return;
            }
            _attemptDuration = attemptDuration;
         }
      },
      responseLocked: {
         get: function() {
            return _responseLocked;
         },
         set: function(responseLocked) {
            _responseLocked = responseLocked;
         }
      },
      feedbackActionExecuted: {
         get: function() {
            return _feedbackActionExecuted;
         },
         set: function(feedbackActionExecuted) {
            _feedbackActionExecuted = feedbackActionExecuted;
         }
      }
   });
};

export default {
   /**
    *  Factory method that returns a new Question object.
    *  @function create
    *  @memberof TSC.Question
    *  @param {tscme.Question} tscmeQuestionModel - Question model from Media-Extensions-js
    *  @static
    *  @return new Question instance
    */
   create: Question
};
