import $ from 'jquery';
import Promise from 'promise-polyfill';
import windowWrapper from '../../common/window-wrapper';
import BubbleView from './bubble-view';
import events from '../constants/events';
import rationaleActionType from '../constants/rationale-action-type';
import QuestionView from './question-view';
import deviceInfo from '../utils/device-info';
import templateGenerator from '../utils/template-generator';
import cssClasses from '../constants/css-classes';
import viewHelper from '../utils/view-helper';
import playerStringNames from '../constants/player-string-names';
import localizationStrings from '../models/localization-strings';

var SCREEN_READER_ALERT_REMOVAL_TIMEOUT = 1000;
var INIT_QUESTION_SET_TIMEOUT = 10;
var PEEK_AT_VIDEO_SHORT_CUT_KEYCODE = 72; // h key
var CONTAINER_CLASS = '.question-set-container';
var QUESTION_SET_CONTENT_CLASS = '.question-set-content';
var HIDE_CONTROL_CLASS = '.hide-quiz-button';
var SHOW_CONTROL_CLASS = '.show-quiz-button';
var PREV_CONTROL_CLASS = '.previous-button';
var NEXT_CONTROL_CLASS = '.next-button';
var SUBMIT_CONTROL_CLASS = '.submit-button';
var CONTINUE_CONTROL_CLASS = '.continue-button';
var QUESTION_COUNT_CLASS = '.question-count';
var QUESTION_GRADED_STATUS_CLASS = '.question-graded-status';
var QUESTION_CLASS = '.question-text';
var ANSWERS_CLASS = '.question-answers';
var FEEDBACK_CLASS = '.question-feedback';
var MINIMIZED_VIEW_CLASS = '.minimized-question-set-container';

/**
 * @memberof TSC
 * @class QuestionSetView
 * @param {Object} $container -- Container to add question set view.
 * @param {TSC.QuestionSet} questionSet -- Question Set model for view.
 * @param {Boolean} displayOverlay -- Display overlay.
 * @param {Boolean} mediaIsImage -- Media is an image.
 * @param {Boolean} showQuestionSetResults -- Show user results for question set.
 * @classdesc View for Question sets.
 */
var QuestionSetView = function($container, questionSet, displayOverlay, mediaIsImage, showQuestionSetResults, quizTime) {
   var _$document = windowWrapper.getDocument(true);
   var _currentQuestionIndex = 0;
   var _$container = $container;
   var _startTimeForQuestion = 0;
   var _endTimeForQuestion = 0;
   var _currentQuestionView = null;
   var _initTimeoutId = null;
   var _alertScreenReaderTimeoutId = null;
   var _$questionSetContainer = null;
   var _$questionSetContent = null;
   var _$questionTextContainer = null;
   var _$answersContainer = null;
   var _$feedbackContainer = null;
   var _$questionCountContainer = null;
   var _$questionGradedStatusContainer = null;
   var _$hideControl = null;
   var _$showControl = null;
   var _$prevControl = null;
   var _$nextControl = null;
   var _$continueControl = null;
   var _$submitControl = null;
   var _$minimizedContainer = null;
   var _minimizedByKeyboardShortcut = false;
   var _superView = BubbleView.create(CONTAINER_CLASS);

   var _resolveInitPromise;
   var initPromise = new Promise(function(resolve) {
      _resolveInitPromise = resolve;
   });

   var alertScreenReader = function(message) {
      var screenReaderAlertMarkup = templateGenerator.generateScreenReaderAlertMarkup(message);
      var $screenReaderAlert = $(screenReaderAlertMarkup);
      _$container.append($screenReaderAlert);

      _alertScreenReaderTimeoutId = setTimeout(function() {
         $screenReaderAlert.remove();
      }, SCREEN_READER_ALERT_REMOVAL_TIMEOUT);
   };

   var onHideQuizClick = function() {
      minimizeView();
   };

   var onShowQuizHandler = function() {
      maximizeView();
   };

   var onKeyDownHandler = function(e) {
      if (e && e.keyCode === PEEK_AT_VIDEO_SHORT_CUT_KEYCODE && !_currentQuestionView.isTextInputOrAreaFocused() && !_superView.viewIsMinimized) {
         _minimizedByKeyboardShortcut = true;
         _$document.on('keyup', onKeyUpHandler);
         minimizeView();
      }
   };

   var onKeyUpHandler = function() {
      _minimizedByKeyboardShortcut = false;
      _$document.off('keyup', onKeyUpHandler);
      maximizeView();
   };

   var minimizeView = function() {
      if (!_superView.viewIsMinimized) {
         $container.trigger(events.Quizzing.MinimizeUI);
         _$questionSetContent.addClass(cssClasses.hideElement);
         if (!_minimizedByKeyboardShortcut) {
            _$minimizedContainer.removeClass(cssClasses.hideElement);
         }
         viewHelper.removeFocusTrap();
         alertScreenReader(localizationStrings.getPlayerString(playerStringNames.accessQuizHidden));
         _superView.minimizeView();
      }
   };

   var maximizeView = function() {
      if (_superView.viewIsMinimized) {
         $container.trigger(events.Quizzing.MaximizeUI);
         _$questionSetContent.removeClass(cssClasses.hideElement);
         _$minimizedContainer.addClass(cssClasses.hideElement);
         _superView.maximizeView();
         alertScreenReader(localizationStrings.getPlayerString(playerStringNames.accessQuizShown));
         $container.focus();
         viewHelper.trapFocus(_$questionSetContent);
      }
   };

   var isOnFirstQuestion = function() {
      return _currentQuestionIndex === 0;
   };

   var isOnLastQuestion = function() {
      return _currentQuestionIndex === questionSet.questions.length - 1;
   };

   var showView = function() {
      _$questionSetContainer.removeClass('minimized');
      _superView.showView();
   };

   var hideView = function() {
      _$questionSetContainer.addClass('minimized');
      _superView.hideView();
   };

   var startTrackingQuestionTime = function() {
      _startTimeForQuestion = new Date().getTime();
   };

   var recordDurationOnQuestion = function() {
      _endTimeForQuestion = new Date().getTime();

      var timeBetween = _endTimeForQuestion - _startTimeForQuestion;
      questionSet.questions[_currentQuestionIndex].attemptDuration += timeBetween;
   };

   var storeUserResponse = function() {
      var userResponse = _currentQuestionView.getUserResponse();

      if (userResponse !== undefined && userResponse !== '') {
         questionSet.questions[_currentQuestionIndex].userResponse = userResponse;
      }
   };

   var saveQuestionUserInput = function() {
      if (showQuestionSetResults) {
         return;
      }

      storeUserResponse();
      recordDurationOnQuestion();
   };

   var lockResponses = function() {
      if (questionSet.usesRationale) {
         questionSet.questions[_currentQuestionIndex].responseLocked = true;
      } else {
         questionSet.questions.forEach(function(question) {
            question.responseLocked = true;
         });
      }
   };

   var getFeedbackInfoForCurrentQuestion = function() {
      var actionType = rationaleActionType.CONTINUE;
      var feedback;

      if (questionSet.questions[_currentQuestionIndex].isGraded) {
         var feedbackReason = questionSet.questions[_currentQuestionIndex].isResponseCorrect ? 'correct' : 'incorrect';
         feedback = questionSet.questions[_currentQuestionIndex].getFeedbackForReason(feedbackReason);

         if (feedback !== null) {
            if (feedback.location !== undefined) {
               actionType = rationaleActionType.LOCATION;
            } else if (feedback.jumpToTime !== undefined) {
               actionType = rationaleActionType.JUMP_TO_TIME;
            }
         }
      }

      return {feedback: feedback, actionType: actionType};
   };

   var executeContinueAction = function() {
      if (isOnLastQuestion()) {
         $container.trigger(events.Quizzing.CloseQuestionSetView);
      } else {
         displayNextQuestion();
      }
   };

   var executeRationaleAction = function() {
      var feedbackInfo;

      if (questionSet.questions[_currentQuestionIndex].feedbackActionExecuted) {
         feedbackInfo = {actionType: rationaleActionType.CONTINUE};
      } else {
         feedbackInfo = getFeedbackInfoForCurrentQuestion();
         questionSet.questions[_currentQuestionIndex].feedbackActionExecuted = true;
      }

      switch (feedbackInfo.actionType) {
         case rationaleActionType.CONTINUE:
            executeContinueAction();
            break;
         case rationaleActionType.LOCATION:
            $container.trigger(events.Quizzing.RationaleActionLocation, {location: feedbackInfo.feedback.location});
            executeContinueAction();
            break;
         case rationaleActionType.JUMP_TO_TIME:
            if (questionSet.startTime !== feedbackInfo.feedback.jumpToTime) {
               $container.trigger(events.Quizzing.RationaleActionJumpToTime, {jumpToTime: feedbackInfo.feedback.jumpToTime});
            } else {
               executeContinueAction();
            }
            break;
      }
   };

   var displayNextQuestion = function() {
      saveQuestionUserInput();

      _currentQuestionIndex++;
      displayQuestion();
   };

   var onPreviousQuestion = function(e) {
      e.preventDefault();

      saveQuestionUserInput();

      _currentQuestionIndex--;
      displayQuestion();
   };

   var onNextQuestion = function(e) {
      e.preventDefault();

      displayNextQuestion();
   };

   var onContinue = function(e) {
      e.preventDefault();

      if (questionSet.usesRationale && !showQuestionSetResults) {
         executeRationaleAction();
      } else {
         $container.trigger(events.Quizzing.CloseQuestionSetView);
      }
   };

   var onSubmit = function(e) {
      e.preventDefault();

      _$submitControl.attr('disabled', 'disabled');

      saveQuestionUserInput();
      lockResponses();

      $container.trigger(events.Quizzing.SubmitQuestionSet);
   };

   //This is a fix for iPhones whipping users back to the top of the page if there is a focused input.
   var onIphoneTouchstart = function() {
      _$questionSetContainer.find('.fill-in-the-blank-input').blur();
   };

   var getControlsConfig = function() {
      var currentQuestion = questionSet.questions[_currentQuestionIndex];
      var onFirstQuestion = isOnFirstQuestion();
      var onLastQuestion = isOnLastQuestion();
      var hasOneQuestion = onFirstQuestion && onLastQuestion;

      var controlsConfig = {
         showSubmitNav: false,
         showContinueNav: false,
         showPrevNav: false,
         showNextNav: false,
         prevDisabled: false,
         nextDisabled: false
      };

      if (questionSet.usesRationale && !showQuestionSetResults) {
         if (currentQuestion.responseLocked) {
            if (currentQuestion.feedbackActionExecuted && !onLastQuestion) {
               controlsConfig.showNextNav = true;
            } else {
               controlsConfig.showContinueNav = true;
            }
         } else {
            controlsConfig.showSubmitNav = true;
         }
         controlsConfig.showPrevNav = !hasOneQuestion && !onFirstQuestion;
      } else {
         controlsConfig.showSubmitNav = !showQuestionSetResults && onLastQuestion;
         controlsConfig.showContinueNav = showQuestionSetResults && onLastQuestion && !mediaIsImage;
         controlsConfig.showPrevNav = !hasOneQuestion;
         controlsConfig.showNextNav = !hasOneQuestion && !controlsConfig.showSubmitNav && !controlsConfig.showContinueNav;
         controlsConfig.prevDisabled = _currentQuestionIndex === 0;
         controlsConfig.nextDisabled = onLastQuestion;
      }

      return controlsConfig;
   };

   var updateControls = function() {
      var controlsConfig = getControlsConfig();

      _$prevControl.css('display', 'none');
      _$nextControl.css('display', 'none');
      _$submitControl.css('display', 'none');
      _$continueControl.css('display', 'none');

      if (controlsConfig.showPrevNav) {
         _$prevControl.css('display', 'inline');

         if (controlsConfig.prevDisabled) {
            _$prevControl.attr('disabled', 'disabled');
         } else {
            _$prevControl.removeAttr('disabled');
         }
      }

      if (controlsConfig.showNextNav) {
         _$nextControl.css('display', 'inline');

         if (controlsConfig.nextDisabled) {
            _$nextControl.attr('disabled', 'disabled');
         } else {
            _$nextControl.removeAttr('disabled');
         }
      }

      if (controlsConfig.showSubmitNav) {
         _$submitControl.removeAttr('disabled');
         _$submitControl.css('display', 'inline');
      }

      if (controlsConfig.showContinueNav) {
         _$continueControl.removeAttr('disabled');
         _$continueControl.css('display', 'inline');
      }
   };

   var displayQuestion = function() {
      var showResults = showQuestionSetResults;

      if (questionSet.usesRationale && questionSet.questions[_currentQuestionIndex].responseLocked) {
         showResults = true;
      }
      if (_currentQuestionView !== null) {
         _currentQuestionView.destroy();
      }
      _currentQuestionView = QuestionView.create(_$questionTextContainer, _$questionGradedStatusContainer, _$feedbackContainer, _$answersContainer, questionSet.questions[_currentQuestionIndex], showResults);
      updateQuestionCount();
      updateControls();
      startTrackingQuestionTime();
      viewHelper.trapFocus(_$questionSetContent);
   };

   var updateQuestionCount = function() {
      var questionCountMarkup = templateGenerator.generateQuestionSetQuestionCountMarkup(_currentQuestionIndex + 1, questionSet.questions.length);
      _$questionCountContainer.html(questionCountMarkup);
   };

   var showFeedbackForCurrentQuestion = function() {
      displayQuestion();
      updateControls();
   };

   var createView = function() {
      var questionSetViewMarkup = templateGenerator.generateQuestionSetMarkup(quizTime);

      if (displayOverlay) {
         questionSetViewMarkup += _superView.getOverlayMarkup();
      }

      _$container.append(questionSetViewMarkup);

      _$questionSetContainer = _$container.find(CONTAINER_CLASS);
      _$questionSetContent = _$container.find(QUESTION_SET_CONTENT_CLASS);
      _$questionTextContainer = _$container.find(QUESTION_CLASS);
      _$answersContainer = _$container.find(ANSWERS_CLASS);
      _$feedbackContainer = _$container.find(FEEDBACK_CLASS);
      _$questionCountContainer = _$container.find(QUESTION_COUNT_CLASS);
      _$questionGradedStatusContainer = _$container.find(QUESTION_GRADED_STATUS_CLASS);
      _$hideControl = _$container.find(HIDE_CONTROL_CLASS);
      _$showControl = _$container.find(SHOW_CONTROL_CLASS);
      _$prevControl = _$container.find(PREV_CONTROL_CLASS);
      _$nextControl = _$container.find(NEXT_CONTROL_CLASS);
      _$continueControl = _$container.find(CONTINUE_CONTROL_CLASS);
      _$submitControl = _$container.find(SUBMIT_CONTROL_CLASS);
      _$minimizedContainer = _$container.find(MINIMIZED_VIEW_CLASS);
   };

   var removeEvents = function() {
      _$hideControl.off('click', onHideQuizClick);
      _$showControl.off('click', onShowQuizHandler);
      _$prevControl.off('click', onPreviousQuestion);
      _$nextControl.off('click', onNextQuestion);
      _$submitControl.off('click', onSubmit);
      _$continueControl.off('click', onContinue);

      $container.off('keydown', onKeyDownHandler);

      viewHelper.removeFocusTrap();

      if (deviceInfo.isIPhoneOrIPod()) {
         _$questionSetContainer.off('touchstart', onIphoneTouchstart);
      }
   };

   var setupEvents = function() {
      _$hideControl.on('click', onHideQuizClick);
      _$showControl.on('click', onShowQuizHandler);
      _$prevControl.on('click', onPreviousQuestion);
      _$nextControl.on('click', onNextQuestion);
      _$submitControl.on('click', onSubmit);
      _$continueControl.on('click', onContinue);

      $container.on('keydown', onKeyDownHandler);

      if (deviceInfo.isIPhoneOrIPod()) {
         _$questionSetContainer.on('touchstart', onIphoneTouchstart);
      }
   };

   var checkQuestionSetProgress = function() {
      if (showQuestionSetResults) {
         return;
      }
      for (var i = 0; i < questionSet.questions.length; i++) {
         if (!questionSet.questions[i].responseLocked) {
            _currentQuestionIndex = i;
            break;
         }
      }
   };

   var removeView = function(completeCallback, destroyOverlay) {
      _initTimeoutId && clearTimeout(_initTimeoutId);
      _alertScreenReaderTimeoutId && clearTimeout(_alertScreenReaderTimeoutId);

      removeEvents();
      _currentQuestionView.destroy();
      _superView.removeView(completeCallback, destroyOverlay);
   };

   createView();
   checkQuestionSetProgress();
   setupEvents();

   // need to delay displaying first question for NVDA screen reader to pick up text.
   _initTimeoutId = setTimeout(function() {
      displayQuestion();
      _resolveInitPromise();
   }, INIT_QUESTION_SET_TIMEOUT);

   return Object.defineProperties({
      showView: showView,
      hideView: hideView,
      removeView: removeView,
      showFeedbackForCurrentQuestion: showFeedbackForCurrentQuestion
   }, {
      viewInitializedPromise: {
         get: function() {
            return initPromise;
         }
      }
   });
};

export default {
   create: QuestionSetView
};
