import scormConstants from '../constants/scorm-constants';

var V1_SUSPENDED_DATA_KEY = 'tscsp1';
var V2_SUSPENDED_DATA_KEY = 'tscsp2';

/**
 * @memberof TSC
 * @class ScormDataModel
 * @classdesc Data modal for smart player suspended scorm data
 */
var ScormDataModel = function() {
   var _percentageComplete = 0;
   var _quizPercentageCorrect = 0;
   var _quizCompletionStatus = scormConstants.status.unknown;
   var _quizModelSnapshot = '';
   var _videoAnalyticsSnapshot = '';
   var _videoTimeInSecondsSnapshot = 0;
   var _videoTimeInSeconds = 0;
   var _completionStatus = scormConstants.status.notAttempted;
   var _passingStatus = scormConstants.status.unknown;

   /**
    * @methodOf TSC.ScormDataModel
    * @method parseSuspendedDataString
    * @param {String} suspendedDataString - string containing suspended scorm data
    */
   var parseSuspendedDataString = function(suspendedDataString) {
      if (!suspendedDataString) {
         return;
      }

      try {
         var suspendedData = window.JSON.parse(suspendedDataString);
         if (suspendedData[V1_SUSPENDED_DATA_KEY]) {
            _percentageComplete = parseFloat(suspendedData[V1_SUSPENDED_DATA_KEY].pc);
            _videoAnalyticsSnapshot = suspendedData[V1_SUSPENDED_DATA_KEY].vas;
            _videoTimeInSecondsSnapshot = parseFloat(suspendedData[V1_SUSPENDED_DATA_KEY].cvt);
         } else if (suspendedData[V2_SUSPENDED_DATA_KEY]) {
            _percentageComplete = parseFloat(suspendedData[V2_SUSPENDED_DATA_KEY].pc);
            _videoAnalyticsSnapshot = suspendedData[V2_SUSPENDED_DATA_KEY].vas;
            _videoTimeInSecondsSnapshot = parseFloat(suspendedData[V2_SUSPENDED_DATA_KEY].cvt);
            _quizModelSnapshot = suspendedData[V2_SUSPENDED_DATA_KEY].qs;
         }
      } catch(e) {} // eslint-disable-line
   };

   /**
    * @methodOf TSC.ScormDataModel
    * @method getSuspendedDataString
    * Return string of suspended data
    * @returns {String} string containing suspended scorm data
    */
   var getSuspendedDataString = function() {
      var suspendedData = {};
      suspendedData[V2_SUSPENDED_DATA_KEY] = {
         pc: _percentageComplete,
         vas: _videoAnalyticsSnapshot,
         cvt: _videoTimeInSeconds,
         qs: _quizModelSnapshot
      };
      return window.JSON.stringify(suspendedData);
   };

   return Object.defineProperties(
      {
         parseSuspendedDataString: parseSuspendedDataString,
         getSuspendedDataString: getSuspendedDataString
      },
      {
         percentageComplete: {
            get: function() {
               return _percentageComplete;
            },
            set: function(percentageComplete) {
               _percentageComplete = percentageComplete;
            }
         },
         quizPercentageCorrect: {
            get: function() {
               return _quizPercentageCorrect;
            },
            set: function(quizPercentageCorrect) {
               _quizPercentageCorrect = quizPercentageCorrect;
            }
         },
         quizPercentageCorrectAsString: {
            get: function() {
               return _quizPercentageCorrect.toString();
            }
         },
         quizCompletionStatus: {
            get: function() {
               return _quizCompletionStatus;
            },
            set: function(quizCompletionStatus) {
               _quizCompletionStatus = quizCompletionStatus;
            }
         },
         quizModelSnapshot: {
            get: function() {
               return _quizModelSnapshot;
            },
            set: function(quizModelSnapshot) {
               _quizModelSnapshot = quizModelSnapshot;
            }
         },
         videoAnalyticsSnapshot: {
            get: function() {
               return _videoAnalyticsSnapshot;
            },
            set: function(snapshot) {
               _videoAnalyticsSnapshot = snapshot;
            }
         },
         videoTimeInSecondsSnapshot: {
            get: function() {
               return _videoTimeInSecondsSnapshot;
            }
         },
         videoTimeInSeconds: {
            get: function() {
               return _videoTimeInSeconds;
            },
            set: function(timeInSeconds) {
               _videoTimeInSeconds = timeInSeconds;
            }
         },
         completionStatus: {
            get: function() {
               return _completionStatus;
            },
            set: function(completionStatus) {
               _completionStatus = completionStatus;
            }
         },
         passingStatus: {
            get: function() {
               return _passingStatus;
            },
            set: function(passingStatus) {
               _passingStatus = passingStatus;
            }
         }
      });
};

export default {
   create: ScormDataModel
};
