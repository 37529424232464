import events from '../constants/events';
import cssClasses from '../constants/css-classes';
import templateGenerator from '../utils/template-generator';
import tabIndex from '../constants/tab-index';
import DisplayModes from '../constants/display-modes';
import localStorageWrapper from '../utils/local-storage-wrapper';
import localizationStrings from '../models/localization-strings';
import PlayerStringNames from '../constants/player-string-names';

const LOCAL_STORAGE_DISPLAY_MODE_KEY = 'tscSmartPlayerDisplayMode';

/**
 * @memberof TSC
 * @class TheaterModeControlView
 * @classdesc Theater Mode View Control
 * @param {Object} $container - jQuery element that contains a wrapper element where the theater mode control will be injected
 */
const TheaterModeControlView = ($container) => {
   let _theaterModeEnabled = false;
   let _$theaterModeButton;
   let _$theaterModeGlyph;

   const disableControl = () => {
      _$theaterModeButton.attr('tabindex', tabIndex.Disabled);
   };

   const enableControl = () => {
      _$theaterModeButton.attr('tabindex', tabIndex.DomOrder);
   };

   const sendTheaterModeChangedEvent = () => {
      $container.trigger(events.Controls.TheaterModeChanged, {displayMode: _theaterModeEnabled ? DisplayModes.Theater : DisplayModes.Normal});
   };

   const setDisplayMode = (enabled) => {
      _theaterModeEnabled = enabled;
      $container.playerState.displayMode = _theaterModeEnabled ? DisplayModes.Theater : DisplayModes.Normal;

      localStorageWrapper.setItem(LOCAL_STORAGE_DISPLAY_MODE_KEY, $container.playerState.displayMode);

      if (_theaterModeEnabled) {
         _$theaterModeButton.attr('title', localizationStrings.getPlayerString(PlayerStringNames.accessBtnExitTheaterMode));
         _$theaterModeButton.attr('aria-pressed', true);
         _$theaterModeGlyph.removeClass(cssClasses.theaterModeButtonGlyph).addClass(cssClasses.theaterModeButtonActiveGlyph);
         _$theaterModeButton.removeClass(cssClasses.tertiaryButton).addClass(cssClasses.tertiaryButtonActive);
      } else {
         _$theaterModeButton.attr('title', localizationStrings.getPlayerString(PlayerStringNames.accessBtnTheaterMode));
         _$theaterModeButton.attr('aria-pressed', false);
         _$theaterModeGlyph.removeClass(cssClasses.theaterModeButtonActiveGlyph).addClass(cssClasses.theaterModeButtonGlyph);
         _$theaterModeButton.removeClass(cssClasses.tertiaryButtonActive).addClass(cssClasses.tertiaryButton);
      }
   };

   const loadSavedSettings = function() {
      const savedDisplayStateStorage = localStorageWrapper.getItem(LOCAL_STORAGE_DISPLAY_MODE_KEY);
      if (savedDisplayStateStorage) {
         setDisplayMode(savedDisplayStateStorage === DisplayModes.Theater);
         sendTheaterModeChangedEvent();
      } else {
         setDisplayMode(_theaterModeEnabled);
      }
   };

   const onFullscreenChange = (e, eventData) => {
      if (eventData.inFullScreen) {
         _$theaterModeButton.hide();
      } else {
         _$theaterModeButton.show();
      }
   };

   const onButtonClick = (e) => {
      e.preventDefault();
      setDisplayMode(!_theaterModeEnabled);
      sendTheaterModeChangedEvent();
   };

   const createView = () => {
      const markup = templateGenerator.generateTheaterModeButtonMarkup();
      $container.find('.theater-mode-wrapper').html(markup);
      _$theaterModeButton = $container.find('.theater-mode-button');
      _$theaterModeGlyph = $container.find('.theater-mode-button-glyph');
   };

   const initializeHandlers = () => {
      _$theaterModeButton.on('click', onButtonClick);
      $container.on(events.Controls.PlayerInitialized, loadSavedSettings);
      $container.on(events.Controls.FullScreenChange, onFullscreenChange);
      $container.on(events.Controls.Disable, disableControl);
      $container.on(events.Controls.Enable, enableControl);
   };

   const destroy = () => {
      _$theaterModeButton.off('click', onButtonClick);
      $container.off(events.Controls.PlayerInitialized, loadSavedSettings);
      $container.off(events.Controls.FullScreenChange, onFullscreenChange);
      $container.off(events.Controls.Disable, disableControl);
      $container.off(events.Controls.Enable, enableControl);
   };

   createView();
   initializeHandlers();

   return Object.defineProperties({
      destroy: destroy
   }, {});
};

export default {
   create: TheaterModeControlView
};
