export default Object.freeze({
   accessBtnAnswersSubmitted: 'accessBtnAnswersSubmitted',
   accessBtnAudioDescription: 'accessBtnAudioDescription',
   accessBtnClearSearch: 'accessBtnClearSearch',
   accessBtnClickToReplayLink: 'accessBtnClickToReplayLink',
   accessBtnClosedCaption: 'accessBtnClosedCaption',
   accessBtnCorrectOutOf: 'accessBtnCorrectOutOf',
   accessResultsLabelCorrectSuffix: 'accessResultsLabelCorrectSuffix',
   accessResultsLabelIncorrectSuffix: 'accessResultsLabelIncorrectSuffix',
   accessResultsLabelNoResponseSuffix: 'accessResultsLabelNoResponseSuffix',
   accessResultsLabelNoResponseGradedSuffix: 'accessResultsLabelNoResponseGradedSuffix',
   accessBtnFullScreen: 'accessBtnFullScreen',
   accessBtnFullScreenExpand: 'accessBtnFullScreenExpand',
   accessBtnGradedQuestions: 'accessBtnGradedQuestions',
   accessBtnHideAnswer: 'accessBtnHideAnswer',
   accessBtnHideQuiz: 'accessBtnHideQuiz',
   accessBtnPreviousQuestion: 'accessBtnPreviousQuestion',
   accessBtnNextQuestion: 'accessBtnNextQuestion',
   accessBtnNext: 'accessBtnNext',
   accessBtnPip: 'accessBtnPip',
   accessBtnPlay: 'accessBtnPlay',
   accessBtnPrevious: 'accessBtnPrevious',
   accessBtnProgressBar: 'accessBtnProgressBar',
   accessBtnRewind: 'accessBtnRewind',
   accessBtnForward: 'accessBtnForward',
   accessBtnSettings: 'accessBtnSettings',
   accessBtnShowAnswer: 'accessBtnShowAnswer',
   accessBtnShowQuiz: 'accessBtnShowQuiz',
   accessBtnTableOfContents: 'accessBtnTableOfContents',
   accessBtnTakeQuiz: 'accessBtnTakeQuiz',
   accessBtnVolume: 'accessBtnVolume',
   accessBtnWasNotGraded: 'accessBtnWasNotGraded',
   accessBtnWereNotGraded: 'accessBtnWereNotGraded',
   accessListNoSearchResults: 'accessListNoSearchResults',
   accessListSearchResults: 'accessListSearchResults',
   accessListTableOfContents: 'accessListTableOfContents',
   accessQuestionSetConfirmationTitle: 'accessQuestionSetConfirmationTitle',
   accessQuizResults: 'accessQuizResults',
   accessQuestionFeedbackContext: 'accessQuestionFeedbackContext',
   accessTxtVolume: 'accessTxtVolume',
   accessVideoPausedForQuiz: 'accessVideoPausedForQuiz',
   accessQuizHidden: 'accessQuizHidden',
   accessQuizShown: 'accessQuizShown',
   accessProgressBarTimeConjunction: 'accessProgressBarTimeConjunction',
   accessPlayerSettingsClose: 'accessPlayerSettingsClose',
   accessBtnTheaterMode: 'accessBtnTheaterMode',
   accessBtnExitTheaterMode: 'accessBtnExitTheaterMode',
   ccLanguageText: 'ccLanguageText',
   close: 'close',
   mediaNotFound: 'mediaNotFound',
   noWebMSupport: 'noWebMSupport',
   playerRateOptionsText: 'playerRateOptionsText',
   playerRateText: 'playerRateText',
   scormApiNotFoundMessage: 'scormApiNotFoundMessage',
   search: 'search',
   searchDisabledFullScreen: 'searchDisabledFullScreen',
   searchPlaceholder: 'searchPlaceholder',
   txtAnswerQuestion: 'txtAnswerQuestion',
   txtBadImageQuiz: 'txtBadImageQuiz',
   txtBegin: 'txtBegin',
   txtContinue: 'txtContinue',
   txtCorrect: 'txtCorrect',
   txtCorrectAnswer: 'txtCorrectAnswer',
   txtCorrectString: 'txtCorrectString',
   txtEnterEmail: 'txtEnterEmail',
   txtEnterFirstName: 'txtEnterFirstName',
   txtEnterLastName: 'txtEnterLastName',
   txtHotspot: 'txtHotspot',
   txtHotspotScreenReaderAlert: 'txtHotspotScreenReaderAlert',
   txtHotspotScreenReaderAlertPlural: 'txtHotspotScreenReaderAlertPlural',
   txtImageQuizNetworkErrorMessage: 'txtImageQuizNetworkErrorMessage',
   txtIncorrect: 'txtIncorrect',
   txtIncorrectString: 'txtIncorrectString',
   txtJumpToMedia: 'txtJumpToMedia',
   txtJumpToTime: 'txtJumpToTime',
   txtLoaderMessageDefinition: 'txtLoaderMessageDefinition',
   txtNext: 'txtNext',
   txtHideQuiz: 'txtHideQuiz',
   txtOpenLink: 'txtOpenLink',
   txtOpenLinkHeader: 'txtOpenLinkHeader',
   txtOpenLinkSubHeader: 'txtOpenLinkSubHeader',
   txtPrev: 'txtPrev',
   txtQuestionCount: 'txtQuestionCount',
   txtQuizBadDefinitionMessage: 'txtQuizBadDefinitionMessage',
   txtQuizErrorMessage: 'txtQuizErrorMessage',
   txtQuizOutOfDateMessage: 'txtQuizOutOfDateMessage',
   txtQuizOutOfDateRefreshButtonText: 'txtQuizOutOfDateRefreshButtonText',
   txtReplayVideo: 'txtReplayVideo',
   txtRequiredMessage: 'txtRequiredMessage',
   txtResults: 'txtResults',
   txtReview: 'txtReview',
   txtReviewAnswer: 'txtReviewAnswer',
   txtSkip: 'txtSkip',
   txtSubmit: 'txtSubmit',
   txtSubmittingAnswers: 'txtSubmittingAnswers',
   txtVideoQuizNetworkErrorMessage: 'txtVideoQuizNetworkErrorMessage',
   txtVisitSite: 'txtVisitSite',
   txtYourAnswer: 'txtYourAnswer',
   videoLoading: 'videoLoading',
   videoNotSupported: 'videoNotSupported',
   xmpSecurity: 'xmpSecurity',
   xmpError: 'xmpError',
   confirmOpenUrlModalScreenReaderAlertText: 'confirmOpenUrlModalScreenReaderAlertText',
   confirmOpenUrlConfirmButtonScreenReaderText: 'confirmOpenUrlConfirmButtonScreenReaderText',
   fastForwardDisabledScreenReaderText: 'fastForwardDisabledScreenReaderText',
   scormCompleteVideoLater: 'scormCompleteVideoLater',
   scormCompleteVideo: 'scormCompleteVideo',
   scormExitVideo: 'scormExitVideo',
   scormConfirmCompleteModalText: 'scormConfirmCompleteModalText',
   scormConfirmCompleteModalRestartVideoText: 'scormConfirmCompleteModalRestartVideoText',
   scormConfirmCompleteModalResumeVideoText: 'scormConfirmCompleteModalResumeVideoText',
   scormConfirmCompleteModalFailedToSaveProgressText: 'scormConfirmCompleteModalFailedToSaveProgressText',
   scormConfirmCompleteModalDismissButtonText: 'scormConfirmCompleteModalDismissButtonText',
   scormConfirmCompleteModalDismissButtonScreenReaderText: 'scormConfirmCompleteModalDismissButtonScreenReaderText',
   scormConfirmCompleteModalConfirmButtonText: 'scormConfirmCompleteModalConfirmButtonText',
   scormConfirmCompleteModalConfirmButtonScreenReaderText: 'scormConfirmCompleteModalConfirmButtonScreenReaderText',
   scormSessionEndedText: 'scormSessionEndedText',
   searchOrJumpTo: 'searchOrJumpTo',
   searchTextCleared: 'searchTextCleared',
   searchResultsUpdated: 'searchResultsUpdated',
   tableOfContentsDisplayed: 'tableOfContentsDisplayed',
   tableOfContentsHidden: 'tableOfContentsHidden',
   defaultMediaTitle: 'defaultMediaTitle',
   hideThumbnailDueToQuiz: 'hideThumbnailDueToQuiz',
   defaultFirstChapterName: 'defaultFirstChapterName',
   acknowledgementLinkName: 'acknowledgementLinkName'
});
