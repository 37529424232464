import playerConfiguration from '../models/player-configuration';
import log from '../../common/log';

var _analyticsCategory = null;

var getCategory = function() {
   return _analyticsCategory;
};

var setCategory = function(analyticsCategory) {
   _analyticsCategory = analyticsCategory;
};

var initGoogleAnalytics = function() {
   log.logMessage('Built in Google Analytics has been removed');
};

var sendEvent = function(action, label, value) {
   if (_analyticsCategory === null) {
      log.logMessage('AnalyticsService :: Could not send analytics event, no category was set');
      return;
   }
   var customEventCallback = playerConfiguration.getCustomEventCallback();
   if (playerConfiguration.getCustomEventTracking() && typeof customEventCallback === 'function') {
      var eventCategory = playerConfiguration.getAltEventCategoryAsFilename() ? playerConfiguration.getMediaFileName() : _analyticsCategory;
      customEventCallback({category: eventCategory, action: action, label: label, value: value});
   }
};

const sendGA4Event = (eventName, eventParams) => {
   if (playerConfiguration.ga4EventCallback && typeof playerConfiguration.ga4EventCallback === 'function') {
      playerConfiguration.ga4EventCallback({eventName, eventParams});
   }
};

export default {
   getCategory: getCategory,
   setCategory: setCategory,
   initGoogleAnalytics: initGoogleAnalytics,
   sendEvent: sendEvent,
   sendGA4Event: sendGA4Event
};
