const questionSetTemplate = ({submittingImg, submittingImgAltText, previousQuestionButtonA11yText, previousButtonName, nextQuestionButtonA11yText, nextButtonName, submitButtonName, continueButtonName, hideQuizA11yText, hideQuizText, showQuizText, quizTime, quizAtTimeHeading, videoId}) => `<div class="question-set-container">
    <div class="submitting-response-container">
        <span class="submitting-text">Submitting...</span>
        <img class="loading-image" src="${submittingImg}" alt="${submittingImgAltText}"/>
    </div>
    <section class="question-set-content" aria-labelledby="${videoId}-quiz-header">
        <h1 id="${videoId}-quiz-header" class="sr-only">${quizAtTimeHeading} ${quizTime}</h1>
        <div class="question-content-container" aria-live="assertive" aria-atomic="true">
           <div class="question-header">
             <div class="question-status-container">
                <div class="question-count"></div>
             </div>
             <div class="question-text"></div>
          </div>
          <div class="question-feedback hide-element"></div>
       </div>
       <div class="question-answers"></div>
       <div class="question-set-controls">
          <button type="button" class="previous-button button button-secondary" aria-label="${previousQuestionButtonA11yText}" disabled="disabled">${previousButtonName}</button>
          <button type="button" class="next-button button button-secondary" aria-label="${nextQuestionButtonA11yText}" disabled="disabled">${nextButtonName}</button>
          <button type="button" class="submit-button button button-primary" disabled="disabled">${submitButtonName}</button>
          <button type="button" class="continue-button button button-primary" disabled="disabled">${continueButtonName}</button>
       </div>
       <div class="question-badge-container">
          <div class="question-graded-status"></div>
          <button type="button" aria-label="${hideQuizA11yText}" class="hide-quiz-button button button-secondary">${hideQuizText}</button>
       </div>
    </section>
   <div class="minimized-question-set-container hide-element">
      <button class="show-quiz-button button button-primary">${showQuizText}</button>
   </div>
</div>`;

export default questionSetTemplate;
