import log from '../../common/log';
import Xmp from '../models/xmp';
import events from '../constants/events';
import playerConfiguration from '../models/player-configuration';

var _fathomId;
var _getCompleteCallback;
var _errorHandlerCallback;
var _mockCalls = false;
var _xmpModel;

var _baseUrl = 'https://results.techsmith.com/';
var _getFathomEndPoint = 'Assessment/Xmp/';

var getBaseUrl = function() {
   if (playerConfiguration.getResultsServiceBaseUrl()) {
      return playerConfiguration.getResultsServiceBaseUrl();
   }
   return _baseUrl;
};

var onFathomLoad = function(xmlModel) {
   if (_getCompleteCallback) {
      _getCompleteCallback.call(this, xmlModel);
   }
};

var errorHandler = function() {
   if (_errorHandlerCallback) {
      _errorHandlerCallback.call();
   }
};

var getFathom = function() {
   if (_mockCalls) {
      setTimeout(onFathomLoad, 1000);
   } else {
      var url = getBaseUrl() + _getFathomEndPoint + _fathomId + '?noop=1';

      _xmpModel = Xmp.create();
      _xmpModel.load(url, true);
      _xmpModel.ready.then(function() {
         onFathomLoad(_xmpModel);
      }).catch(function(error) {
         log.logMessage('fathom-service: getFathom() failed. ' + error);
         errorHandler();
      });
   }
};

var abortPendingRequests = function() {
   _xmpModel && _xmpModel.abortPendingRequests();
};

export default {
   getFathom: function(fathomId) {
      _fathomId = fathomId;
      getFathom();
   },
   abortPendingRequests: abortPendingRequests,
   addEventListener: function(type, listener) {
      if (type === events.Fathom.GetComplete) {
         _getCompleteCallback = listener;
      } else if (type === events.Fathom.Error) {
         _errorHandlerCallback = listener;
      }
   },
   removeEventListener: function(type) {
      if (type === events.Fathom.GetComplete) {
         _getCompleteCallback = undefined;
      } else if (type === events.Fathom.Error) {
         _errorHandlerCallback = undefined;
      }
   }
};
