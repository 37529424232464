import ConfirmOpenUrlView from '../views/confirm-open-url-view';
import endActionType from '../constants/end-action-type';
import playerConfiguration from '../models/player-configuration';
import events from '../constants/events';
import mediaViewType from '../constants/media-view-type';

/**
 * @memberof TSC
 * @class EndActionsController
 * @classdesc Controller for managing XMP features
 * @param {jQuery} $container - Smart Player main container jquery element
 * @param {MediaView} mediaView - Media view for current media. Created by MediaViewFactory.
 * @param {TSC.ProgressBarControlView} progressBarControlView
 * @param {TSC.BeforeAfterPlayControlsView} beforeAfterPlayControlsView
 *
 */
var EndActionsController = function($container, mediaView, progressBarControlView, beforeAfterPlayControlsView) {
   var _processingEndOfVideo = false;

   var getScrubbing = function() {
      return progressBarControlView ? progressBarControlView.scrubbing : false;
   };

   var pause = function() {
      mediaView.mediaElement && mediaView.mediaElement.pause();
   };

   var replayVideo = function(time, playAfterSeek) {
      if (mediaView.type === mediaViewType.playlist) {
         mediaView.reshowVideoAfterClickToReplay();
      } else {
         $container.trigger(events.Media.SeekToTime, {time: time, playAfterSeek: playAfterSeek});
      }
   };

   var showClickToReplay = function() {
      beforeAfterPlayControlsView.showVideoClickToReplayLink();
   };

   var onVideoReplayHandler = function(e, eventData) {
      var replayTime = eventData && eventData.time || 0;
      var playAfterSeek = eventData && eventData.playAfterSeek !== undefined ? eventData.playAfterSeek : true;
      replayVideo(replayTime, playAfterSeek);
   };

   var onVideoEnd = function() {
      var isPlaybackEnded = !mediaView || mediaView.processVideoEnd();
      $container.trigger(events.Media.End, {
         isPlaybackEnded: isPlaybackEnded
      });

      if (isPlaybackEnded) {
         pause();

         executeEndAction($container, replayVideo, showClickToReplay);
      }
   };

   var processEndOfMedia = function() {
      _processingEndOfVideo = true;
      progressBarControlView.updateQuizMarkers();
      pause();
      onVideoEnd();
      _processingEndOfVideo = false;
   };

   var onVideoTimeUpdate = function() {
      // check for duration equal zero to fix issue seen on some Android devices
      if (mediaView.mediaElement.duration === 0) {
         return;
      }

      var atVideoEndTime = Number(mediaView.mediaElement.currentTime.toFixed(2)) >= Number(mediaView.mediaElement.duration.toFixed(2));
      if (!getScrubbing() && atVideoEndTime && !_processingEndOfVideo) {
         processEndOfMedia();
      }
   };

   var bindToEvents = function() {
      $container.on(events.Scrubbing.Ended, onVideoTimeUpdate);
      $container.on(events.Media.Replay, onVideoReplayHandler);

      var eventsToBind = {
         timeupdate: onVideoTimeUpdate
      };
      mediaView.mediaWrapper.on(eventsToBind);
   };

   var unbindToEvents = function() {
      $container.off(events.Scrubbing.Ended, onVideoTimeUpdate);
      $container.off(events.Media.Replay, onVideoReplayHandler);

      var eventsToBind = {
         timeupdate: onVideoTimeUpdate
      };
      mediaView.mediaWrapper.off(eventsToBind);
   };

   var destroy = function() {
      unbindToEvents();
   };


   bindToEvents();
   onVideoTimeUpdate();

   return Object.defineProperties({
      destroy: destroy
   }, {
   });
};

var getEndActionType = function() {
   var xmpEndActionType = playerConfiguration.getEndActionType();

   if (xmpEndActionType !== undefined) {
      var xmpEndActionTypeLowerCase = xmpEndActionType.toLowerCase();
      var endActionParam = playerConfiguration.getEndActionParam();

      switch (xmpEndActionTypeLowerCase) {
         case 'loop': {
            return endActionType.loop;
         }
         case 'stop': {
            if (endActionParam === 'false' || endActionParam === '0') {
               return endActionType.hideReplayControls;
            }
            return endActionType.showReplayControls;
         }
         case 'jumptotime': {
            return endActionType.jumpToTimeAndPlay;
         }
         case 'jumptotimeandstop': {
            return endActionType.jumpToTImeAndStop;
         }
         case 'jumptourl': {
            return endActionType.openUrl;
         }
         case 'jumptourlnew': {
            return endActionType.openUrlInNewWindow;
         }
         default: {
            return endActionType.notAvailable;
         }
      }
   }
   return endActionType.notAvailable;
};

var executeSeekAction = function(onReplayHandler, playAfterSeek) {
   var seekTime = parseFloat(playerConfiguration.getEndActionParam());
   var seekTimeInSeconds = !seekTime ? 0 : seekTime / 1000;
   onReplayHandler(seekTimeInSeconds, playAfterSeek);
};

var displayConfirmOpenUrlView = function($localContainer, inNewWindow) {
   var urlString = '' + playerConfiguration.getEndActionParam();
   ConfirmOpenUrlView.create($localContainer, urlString, inNewWindow);
};

var executeEndAction = function($localContainer, replayVideoHandler, showReplayControlsHandler) {
   var type = getEndActionType();
   if (type === endActionType.notAvailable) {
      return;
   }

   switch (type) {
      case endActionType.loop: {
         executeSeekAction(replayVideoHandler, true);
         break;
      }
      case endActionType.jumpToTimeAndPlay: {
         executeSeekAction(replayVideoHandler, true);
         break;
      }
      case endActionType.jumpToTImeAndStop: {
         executeSeekAction(replayVideoHandler, false);
         break;
      }
      case endActionType.showReplayControls: {
         showReplayControlsHandler();
         break;
      }
      case endActionType.openUrl: {
         displayConfirmOpenUrlView($localContainer, false);
         break;
      }
      case endActionType.openUrlInNewWindow: {
         displayConfirmOpenUrlView($localContainer, true);
         break;
      }
   }
};

export default {
   /**
    *  Factory method that returns a new EndActionsController.
    *  @function create
    *  @memberof TSC.EndActionsController
    *  @static
    *  @param {jQuery} $container - Smart Player main container jquery element
    *  @param {MediaView} mediaView - Media view for current media. Created by MediaViewFactory.
    *  @param {TSC.ProgressBarControlView} progressBarControlView
    *  @param {TSC.BeforeAfterPlayControlsView} beforeAfterPlayControlsView
    *  @return new EndActionsController instance
    */
   create: EndActionsController,
   getEndActionType: getEndActionType,
   executeEndAction: executeEndAction
};
