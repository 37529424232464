var setItem = function(key, value) {
   try {
      window.localStorage.setItem(key, value);
   } catch (e) {
      //oh well
   }
};

var getItem = function(key) {
   try {
      return window.localStorage.getItem(key);
   } catch (e) {
      return null;
   }
};

export default {
   /**
    *  Wraps native local storage setItem with a try/catch.
    *  @function setItem
    *  @memberof TSC.localStorageWrapper
    *  @static
    *  @param {String} key - the key of the value to set in local storage
    *  @param {String} value - the value to set in local storage
    */
   setItem: setItem,
   /**
    *  Wraps native local storage getItem.
    *  @function getItem
    *  @memberof TSC.localStorageWrapper
    *  @static
    *  @param {String} key - the key of the value to retrieve in local storage
    *  @returns {String} value from local storage (could be null or undefined)
    */
   getItem: getItem
};
