import $ from 'jquery';
import events from '../constants/events';
import playerConfiguration from '../models/player-configuration';
import tabIndex from '../constants/tab-index';
import cssClasses from '../constants/css-classes';
import hotspotViewState from '../constants/hotspot-active-state';
import templateGenerator from '../utils/template-generator';

/**
 * @memberof TSC
 * @class HotspotView
 * @classdesc Hotspot View
 * @param {Object} spContainer
 * @param {HTMLVideoElement} mediaElement
 * @param {jquery} $hotspotsContainerElement
 * @param {TSC.HotspotViewModel} hotspotViewModel
 * @param {Number} hotspotContainerWidth
 * @param {Number} hotspotContainerHeight
 */
var HotspotView = function(spContainer, mediaElement, $hotspotsContainerElement, hotspotViewModel, hotspotContainerWidth, hotspotContainerHeight) {
   var _$hotspotSvgElement;
   var _$hotspotLinkElement;
   var _timerId;

   var enableTabbing = function() {
      _$hotspotLinkElement.attr('tabindex', tabIndex.DomOrder);
      _$hotspotLinkElement.attr('focusable', true);
   };

   var disableTabbing = function() {
      _$hotspotLinkElement.attr('tabindex', tabIndex.Disabled);
      _$hotspotLinkElement.attr('focusable', false);
   };

   var onQuizEventHandler = function(e) {
      switch (e.type) {
         case events.Quizzing.Complete:
         case events.Quizzing.Review:
            enableTabbing();
            break;
         case events.Quizzing.Paused:
            disableTabbing();
            break;
      }
   };

   var onControlsUpdatedHandler = function() {
      if (playerConfiguration.disableInteractions) {
         hideHotspot();
      } else if (hotspotViewModel.isHotspotWithinTime(getCurrentTimeInMilliseconds())) {
         displayHotspot();
      }
   };

   var getCurrentTimeInMilliseconds = function() {
      return mediaElement.currentTime > mediaElement.duration ? mediaElement.duration : mediaElement.currentTime * 1000;
   };

   var getSvgElementWithLowerIndex = function(hotspotIndex) {
      var $svgElementWithLowerIndex = null;
      var svgElements = $hotspotsContainerElement.find('svg');
      svgElements.each(function(index, element) {
         var $svg = $(element);
         if (hotspotIndex > Number($svg.attr('data-hotspot-index'))) {
            $svgElementWithLowerIndex = $svg;
         }
      });
      return $svgElementWithLowerIndex;
   };

   var addHotspotViewToDom = function($svgView) {
      var $svgElementWithLowerIndex = getSvgElementWithLowerIndex(hotspotViewModel.index);
      if ($svgElementWithLowerIndex !== null) {
         $svgElementWithLowerIndex.after($svgView);
      } else {
         $hotspotsContainerElement.prepend($svgView);
      }
      _$hotspotSvgElement = $hotspotsContainerElement.find('svg[data-hotspot-index="' + hotspotViewModel.index + '"]');
      _$hotspotLinkElement = _$hotspotSvgElement.find('a');
   };

   var createView = function() {
      var hotspotCoordinates = hotspotViewModel.getCoordinates(hotspotContainerWidth, hotspotContainerHeight);
      var hotspotCoordinatesSeparationBySpace = hotspotCoordinates.replace(/,/g, ' ');
      var descriptionId = 'hotspot-description-' + hotspotViewModel.index;
      var hotspotLinkClassName = 'hotspot-link-' + hotspotViewModel.index;

      var svgViewMarkup = templateGenerator.generateHotspotMarkup(hotspotContainerWidth, hotspotContainerHeight, hotspotViewModel.index, descriptionId, hotspotViewModel.description, playerConfiguration.displayHotspotsShape, hotspotCoordinatesSeparationBySpace, hotspotLinkClassName);
      var $svgView = $(svgViewMarkup);

      addHotspotViewToDom($svgView);
   };

   var bindEvents = function() {
      spContainer.on(events.Quizzing.Complete, onQuizEventHandler);
      spContainer.on(events.Quizzing.Review, onQuizEventHandler);
      spContainer.on(events.Quizzing.Paused, onQuizEventHandler);
      spContainer.on(events.Controls.Updated, onControlsUpdatedHandler);
   };

   var displayHotspot = function() {
      if (playerConfiguration.disableInteractions) {
         return;
      }
      _$hotspotSvgElement.addClass(cssClasses.hotspotVisible);
      enableTabbing();
   };

   var hideHotspot = function() {
      _$hotspotSvgElement.removeClass(cssClasses.hotspotVisible);
      disableTabbing();
   };

   var getTimeToHotspotEnd = function(timeInMilliseconds, mediaPlaybackRate) {
      var frameBuffer = 10 / (mediaPlaybackRate * 30) * 1000;
      return hotspotViewModel.getTimeLeftUntilEnd(timeInMilliseconds) / mediaPlaybackRate - frameBuffer;
   };

   var pauseOnHotspot = function() {
      _timerId = null;
      hotspotViewModel.hasPausedAtEnd = true;
      mediaElement.pause();

      var eventData = {
         hasTimeChangeAction: !hotspotViewModel.xmpModel.location
      };
      spContainer.trigger(events.Hotspots.Paused, eventData);
   };

   var initPauseOnHotspot = function(timeInMilliseconds) {
      window.clearTimeout(_timerId);

      if (window.document.hidden) { //Can't use setTimeout if document is hidden
         pauseOnHotspot();
      } else {
         _timerId = setTimeout(pauseOnHotspot, getTimeToHotspotEnd(timeInMilliseconds, mediaElement.playbackRate));
      }
   };
   var update = function(timeInMilliseconds) {
      var hotspotState = hotspotViewState.hidden;
      if (hotspotViewModel.isHotspotWithinTime(timeInMilliseconds)) {
         if (!hotspotViewModel.isActive) {
            hotspotState = hotspotViewState.firstVisible;
            hotspotViewModel.isActive = true;
            displayHotspot();
         } else {
            hotspotState = hotspotViewState.visible;
         }
         if (hotspotViewModel.shouldPauseAtTheEnd(timeInMilliseconds) && !mediaElement.paused) {
            initPauseOnHotspot(timeInMilliseconds);
         }
      } else if (hotspotViewModel.isActive) {
         hotspotViewModel.isActive = false;
         hideHotspot();

         if (!_timerId && !mediaElement.paused) {
            resetHasPaused();
         }
      }
      return hotspotState;
   };

   var resetHasPaused = function() {
      hotspotViewModel.hasPausedAtEnd = false;
   };

   createView();
   bindEvents();
   hideHotspot();

   return Object.defineProperties({
      update: update,
      resetHasPaused: resetHasPaused
   }, {
      hotspotViewModel: {
         get: function() {
            return hotspotViewModel;
         }
      },
      controlElement: {
         get: function() {
            return _$hotspotLinkElement;
         }
      }
   });
};

export default {
   create: HotspotView
};
