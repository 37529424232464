var _noneType = 'NONE';
var _apiType = 'API';
var _scormType = 'SCORM';
var _apiAndScormType = 'APIANDSCORM';

var reportType = Object.freeze({
   NONE: _noneType,
   API: _apiType,
   SCORM: _scormType,
   APIANDSCORM: _apiAndScormType,
   isValid: function(type) {
      return type === _noneType || type === _apiType || type === _scormType || type === _apiAndScormType;
   }
});

export default reportType;
