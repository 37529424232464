import languageCodes from '../../constants/language-codes';
import playerStringNames from '../../constants/player-string-names';

var strings = {};
strings[playerStringNames.xmpError] = 'Einige Merkmale dieses Videos sind offenbar nicht zugänglich. Bitte setzen Sie sich mit dem Autor des Videos in Verbindung.';
strings[playerStringNames.xmpSecurity] = 'Einige Merkmale Ihrem Video sind zurzeit aufgrund der Sicherheitseinstellungen im lokalen Netzwerk deaktiviert. Diese Merkmale funktionieren wie vorgesehen, wenn das Video über eine Website oder einen Server wiedergegeben wird.';
strings[playerStringNames.videoNotSupported] = 'Ihr Browser kann dieses Video nicht wiedergeben.';
strings[playerStringNames.noWebMSupport] = 'Dieser Browser bietet keine WebM-Video-Unterstützung.';
strings[playerStringNames.search] = 'Suchen';
strings[playerStringNames.searchPlaceholder] = 'Suchen...';
strings[playerStringNames.playerRateText] = 'Wiedergabe-Geschwindigkeit';
strings[playerStringNames.playerRateOptionsText] = {
   0.25: '0,25',
   0.5: '0,5',
   0.75: '0,75',
   1: 'normal',
   1.25: '1,25',
   1.5: '1,5',
   1.75: '1,75',
   2.0: '2,0'
};
strings[playerStringNames.ccLanguageText] = 'Geschlossene Untertitel';
strings[playerStringNames.searchDisabledFullScreen] = 'Die Suche ist im Vollbildmodus deaktiviert.';
strings[playerStringNames.mediaNotFound] = 'Leider können wir im Moment keine Verbindung zu diesen Inhalten herstellen. Bitte versuchen Sie die Seite neu zu laden oder <a href=\'https://www.techsmith.com/redirect.asp?target=media_not_found&ver=4.*.*&lang=deu&os=na&product=smartplayer\' target=\'_blank\'>klicken Sie hier für weitere Hilfe.</a>';
strings[playerStringNames.videoLoading] = 'Das Video wird geladen.';
strings[playerStringNames.close] = 'Schließen';
strings[playerStringNames.txtRequiredMessage] = 'Der Content-Eigentümer fordert die folgenden Angaben an:';
strings[playerStringNames.txtSkip] = 'Ohne Fragen fortfahren';
strings[playerStringNames.txtBegin] = 'Abschicken und fortfahren';
strings[playerStringNames.txtEnterFirstName] = 'Bitte Vornamen eingeben';
strings[playerStringNames.txtEnterLastName] = 'Bitte Nachnamen eingeben';
strings[playerStringNames.txtEnterEmail] = 'Bitte E-Mail Adresse eingeben';
strings[playerStringNames.txtHotspot] = 'Hotspot';
strings[playerStringNames.txtHotspotScreenReaderAlert] = '{{numberOfHotspots}} neuer Hotspot';
strings[playerStringNames.txtHotspotScreenReaderAlertPlural] = '{{numberOfHotspots}} neue Hotspots';
strings[playerStringNames.txtJumpToMedia] = 'Zu Medien springen';
strings[playerStringNames.txtJumpToTime] = 'Zu Zeitpunkt springen';
strings[playerStringNames.txtOpenLink] = 'Link öffnen';
strings[playerStringNames.txtOpenLinkHeader] = 'Sie werden aus diesem Video an folgende Website weitergeleitet:';
strings[playerStringNames.txtOpenLinkSubHeader] = '';
strings[playerStringNames.txtReview] = 'Letzten Abschnitt erneut wiedergeben';
strings[playerStringNames.txtReplayVideo] = 'Video erneut abspielen';
strings[playerStringNames.txtAnswerQuestion] = 'Jetzt am Quiz teilnehmen';
strings[playerStringNames.txtPrev] = 'Zurück';
strings[playerStringNames.txtNext] = 'Weiter';
strings[playerStringNames.txtHideQuiz] = 'Ausblenden';
strings[playerStringNames.txtSubmit] = 'Antworten abschicken';
strings[playerStringNames.txtSubmittingAnswers] = 'Antwort wird gesendet.';
strings[playerStringNames.txtContinue] = 'Fortsetzen';
strings[playerStringNames.txtReviewAnswer] = 'Antworten anzeigen';
strings[playerStringNames.txtBadImageQuiz] = 'Leider ist das angeforderte Quiz nicht kompatibel mit Bildern und konnte nicht geladen werden. Bitte kontaktieren Sie den Autor.';
strings[playerStringNames.txtVideoQuizNetworkErrorMessage] = 'Das Quiz konnte aufgrund eines Netzwerkfehlers nicht geladen werden. Das Video steht zur Ansicht bereit. Sie können versuchen das Quiz erneut zu laden, indem Sie die Browserseite aktualisieren bzw. Ihre Netzwerkverbindung überprüfen.';
strings[playerStringNames.txtImageQuizNetworkErrorMessage] = 'Leider hat ein Netzwerkfehler das Laden des Quiz verhindert. Das Bild kann angeschaut werden. Um das Quiz neu zu laden, aktualisieren Sie die Browser-Seite oder prüfen Sie Ihre Netzwerkverbindung.';
strings[playerStringNames.txtQuizErrorMessage] = 'Wir versuchen, Ihre Antworten zu speichern. Bitte achten Sie darauf, dass Sie eine aktive Internet Verbindung haben.';
strings[playerStringNames.txtQuizOutOfDateMessage] = 'Die Quizfragen sind geändert worden. Bitte aktualisieren Sie den Player, damit das aktuelle Quiz angezeigt wird.';
strings[playerStringNames.txtQuizBadDefinitionMessage] = 'Ungültiges Quiz. Quiz-Feedback kann nicht übermittelt werden. Bitte weisen Sie den Video-Eigentümer auf <a href="https://www.techsmith.com/redirect.asp?target=bad_quiz_definition&ver=5.*.*&lang=enu&os=na&product=smartplayer" target="_blank">diesen Hilfeartikel hin</a>.';
strings[playerStringNames.txtQuizOutOfDateRefreshButtonText] = 'Player aktualisieren';
strings[playerStringNames.txtLoaderMessageDefinition] = 'Das Quiz wird geladen.';
strings[playerStringNames.txtQuestionCount] = 'Frage {{currentQuestion}} von {{totalQuestions}}';
strings[playerStringNames.txtResults] = 'Ergebnisse: ';
strings[playerStringNames.txtCorrectString] = 'Richtig';
strings[playerStringNames.txtIncorrectString] = 'Falsch';
strings[playerStringNames.txtCorrect] = '{{numberCorrect}} Richtig';
strings[playerStringNames.txtIncorrect] = '{{numberIncorrect}} Falsch';
strings[playerStringNames.txtYourAnswer] = 'Ihre Antwort: ';
strings[playerStringNames.txtCorrectAnswer] = 'Richtige Antwort: ';
strings[playerStringNames.txtVisitSite] = 'Website besuchen';
strings[playerStringNames.accessBtnAudioDescription] = 'Audio-Beschreibung';
strings[playerStringNames.accessBtnClearSearch] = 'Suche löschen';
strings[playerStringNames.accessBtnRewind] = '{{duration}} Sekunden zurück';
strings[playerStringNames.accessBtnForward] = '{{duration}} Sekunden vor';
strings[playerStringNames.accessBtnPlay] = 'Video wiedergeben';
strings[playerStringNames.accessBtnPrevious] = 'Zurück';
strings[playerStringNames.accessBtnNext] = 'Weiter';
strings[playerStringNames.accessBtnProgressBar] = 'Fortschrittsbalken';
strings[playerStringNames.accessBtnVolume] = 'Stumm';
strings[playerStringNames.accessTxtVolume] = 'Volumen';
strings[playerStringNames.accessBtnClosedCaption] = 'Geschlossene Beschriftung';
strings[playerStringNames.accessBtnTableOfContents] = 'Kapitel anzeigen (T)';
strings[playerStringNames.accessBtnSettings] = 'Einstellungen';
strings[playerStringNames.accessBtnPip] = 'PIP';
strings[playerStringNames.accessBtnFullScreen] = 'Vollbild';
strings[playerStringNames.accessBtnFullScreenExpand] = 'In neuem Tab öffnen';
strings[playerStringNames.accessBtnTakeQuiz] = 'Klicken Sie hier, um die Quizfragen zu beantworten.';
strings[playerStringNames.accessBtnShowQuiz] = 'Quiz anzeigen';
strings[playerStringNames.accessBtnHideQuiz] = 'Quiz ausblenden';
strings[playerStringNames.accessBtnPreviousQuestion] = 'Vorherige Frage';
strings[playerStringNames.accessBtnNextQuestion] = 'Nächste Frage';
strings[playerStringNames.accessQuizHidden] = 'Quiz wurde ausgeblendet';
strings[playerStringNames.accessQuizShown] = 'Quiz wird jetzt angezeigt';
strings[playerStringNames.accessBtnShowAnswer] = 'Antworten anzeigen';
strings[playerStringNames.accessBtnHideAnswer] = 'Antworten ausblenden';
strings[playerStringNames.accessBtnAnswersSubmitted] = 'Antworten wurden übermittelt!';
strings[playerStringNames.accessBtnWereNotGraded] = ' wurden nicht bewertet.';
strings[playerStringNames.accessBtnWasNotGraded] = ' wurden nicht bewertet.';
strings[playerStringNames.accessBtnCorrectOutOf] = ' korrekte Antworten von ';
strings[playerStringNames.accessBtnGradedQuestions] = ' bewerteten Antworten.';
strings[playerStringNames.accessBtnClickToReplayLink] = 'Wiederholen';
strings[playerStringNames.accessResultsLabelCorrectSuffix] = 'ist richtig.';
strings[playerStringNames.accessResultsLabelIncorrectSuffix] = 'ist falsch.';
strings[playerStringNames.accessResultsLabelNoResponseSuffix] = 'Sie haben diese Frage nicht beantwortet.';
strings[playerStringNames.accessResultsLabelNoResponseGradedSuffix] = 'Sie haben diese Frage nicht beantwortet und sie wurde als falsch markiert.';
strings[playerStringNames.accessBtnTheaterMode] = 'Theatermodus';
strings[playerStringNames.accessBtnExitTheaterMode] = 'Standardmodus';
strings[playerStringNames.scormApiNotFoundMessage] = 'Die API-Implementierung des LMS-Systems kann nicht gefunden werden.\nDie Kommunikation mit dem LMS-System kann nicht hergestellt werden.';
strings[playerStringNames.accessListTableOfContents] = 'Kapitel';
strings[playerStringNames.accessListSearchResults] = 'Suchergebnisse nach Zeitpunkt der Anzeige';
strings[playerStringNames.accessListNoSearchResults] = 'Keine Ergebnisse gefunden';
strings[playerStringNames.accessQuestionSetConfirmationTitle] = 'Quiz';
strings[playerStringNames.accessVideoPausedForQuiz] = 'Video wird angehalten - es folgt ein Quiz';
strings[playerStringNames.accessQuizResults] = 'Quizergebnisse';
strings[playerStringNames.accessQuestionFeedbackContext] = 'Feedback zur Frage: ';
strings[playerStringNames.accessProgressBarTimeConjunction] = ' von ';
strings[playerStringNames.accessPlayerSettingsClose] = 'SmartPlayer-Einstellungen schließen';
strings[playerStringNames.confirmOpenUrlModalScreenReaderAlertText] = 'Das Video leitet auf den folgenden Link weiter URL_TOKEN:';
strings[playerStringNames.confirmOpenUrlConfirmButtonScreenReaderText] = 'Weiter zur neuen Website';
strings[playerStringNames.fastForwardDisabledScreenReaderText] = 'Der Schnellvorlauf wurde für dieses Video deaktiviert.';
strings[playerStringNames.scormCompleteVideoLater] = 'Video später abschließen';
strings[playerStringNames.scormCompleteVideo] = 'Abgeschlossenes Video';
strings[playerStringNames.scormExitVideo] = 'Video beenden';
strings[playerStringNames.scormConfirmCompleteModalText] = 'Wollen Sie das Video wirklich beenden?';
strings[playerStringNames.scormConfirmCompleteModalResumeVideoText] = 'Das Video wird beim nächsten Öffnen dort fortgesetzt, wo Sie es angehalten haben.';
strings[playerStringNames.scormConfirmCompleteModalRestartVideoText] = 'Das Video wird beim nächsten Mal von Beginn an abgespielt. Ihr bisheriger Fortschritt geht verloren.';
strings[playerStringNames.scormConfirmCompleteModalFailedToSaveProgressText] = 'Beim Speichern Ihres Fortschritts ist ein Problem aufgetreten. Das Video beginnt von Anfang an, wenn Sie es das nächste Mal abspielen.';
strings[playerStringNames.scormConfirmCompleteModalDismissButtonText] = 'Zurück zum Video';
strings[playerStringNames.scormConfirmCompleteModalDismissButtonScreenReaderText] = 'Zurück zum Video';
strings[playerStringNames.scormConfirmCompleteModalConfirmButtonText] = 'Video beenden';
strings[playerStringNames.scormConfirmCompleteModalConfirmButtonScreenReaderText] = 'Video beenden';
strings[playerStringNames.scormSessionEndedText] = 'Sie können die Registerkarte schließen oder zu Ihrem Kurs zurückkehren.';
strings[playerStringNames.searchOrJumpTo] = 'Suchen oder springen zu.';
strings[playerStringNames.searchTextCleared] = 'Suchtext gelöscht.';
strings[playerStringNames.searchResultsUpdated] = 'Suchergebnisse aktualisiert. {{number}} Ergebnisse.';
strings[playerStringNames.tableOfContentsDisplayed] = 'Kapitel werden angezeigt.';
strings[playerStringNames.tableOfContentsHidden] = 'Kapitel sind ausgeblendet.';
strings[playerStringNames.defaultMediaTitle] = 'TechSmith Smart Player';
strings[playerStringNames.hideThumbnailDueToQuiz] = 'Lösen Sie das Quiz, um die Vorschau anzuzeigen';
strings[playerStringNames.quizAtTimeHeading] = 'Quizfrage bei';
strings[playerStringNames.defaultFirstChapterName] = 'Einleitung';
strings[playerStringNames.acknowledgementLinkName] = 'Lizenzhinweise';

var getLanguage = function() {
   return languageCodes.GERMAN;
};

var getReadableTimeByLocale = function(hours, minutes, seconds) {
   var timeStringList = [];
   if (hours > 0) {
      var hoursReadableString = hours + ' Stunde';
      hoursReadableString += hours > 1 ? 'n' : '';
      timeStringList.push(hoursReadableString);
   }
   if (minutes > 0) {
      var minutesReadableString = minutes + ' Minute';
      minutesReadableString += minutes > 1 ? 'n' : '';
      timeStringList.push(minutesReadableString);
   }
   if (seconds > 0) {
      var secondsReadableString = seconds + ' Sekunde';
      secondsReadableString += seconds > 1 ? 'n' : '';
      timeStringList.push(secondsReadableString);
   }

   return timeStringList.join(' und ');
};

export default Object.defineProperties({
   getReadableTimeByLocale: getReadableTimeByLocale
}, {
   language: {
      get: getLanguage
   },
   strings: {
      get: function() {
         return strings;
      }
   }
});
