const questionSetReviewStatusTemplate = ({questionSetResultsTabIndex, txtAnswersSubmitted, gradeableDetails, unGradeableDetails, txtViewAnswers, txtHideAnswers}) => `<div class="question-set-container">
   <div class="question-set-results" tabindex="${questionSetResultsTabIndex}">
      <div class="results-title">${txtAnswersSubmitted}</div>
      <div class="gradeable-details">${gradeableDetails}</div>
      <div class="ungradeable-details">${unGradeableDetails}</div>
      <button type="button" class="view-answers-button button button-primary">${txtViewAnswers}</button>
      <button type="button" class="hide-answers-button button button-secondary">${txtHideAnswers}</button>
   </div>
   <div class="chat-bubble-arrow"></div>
</div>`;

export default questionSetReviewStatusTemplate;
