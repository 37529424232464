import events from '../constants/events';
import keys from '../constants/keys';

/**
 * @memberof TSC
 * @class TabController
 * @classdesc Controller for tabs
 * @param {jQuery} $container - Smart Player main container jquery element
 * @param {jQuery} $defaultFirstTabEnabledElement - The default first tab that should be enabled
 * @param {jQuery} $defaultLastTabEnabledElement - The default last tab that should be enabled
 *
 */
var TabController = function($container, $defaultFirstTabEnabledElement, $defaultLastTabEnabledElement) {
   var _defaultFirstTabEnabledElement = $defaultFirstTabEnabledElement;
   var _defaultLastTabEnabledElement = $defaultLastTabEnabledElement;
   var _firstTabEnabledElement = null;
   var _lastTabEnabledElement = null;
   var _playerInFullScreenMode = false;

   var onFullScreenChange = function(event, eventData) {
      _playerInFullScreenMode = eventData.inFullScreen;
   };

   var unbindEventsToTabEnabledElement = function(element) {
      if (element) {
         element.off('keydown');
      }
   };

   var setFirstTabEnabledElement = function(firstTabEnabledElement) {
      if (_firstTabEnabledElement) {
         unbindEventsToTabEnabledElement(_firstTabEnabledElement);
      }

      if (firstTabEnabledElement === null) {
         _firstTabEnabledElement = _defaultFirstTabEnabledElement;
      } else {
         _firstTabEnabledElement = firstTabEnabledElement;
      }

      _firstTabEnabledElement.on('keydown', function(e) {
         if (_playerInFullScreenMode && keys.isKeyOfType(e.key, keys.keyTypes.tab) && e.shiftKey === true) {
            e.preventDefault();
            _lastTabEnabledElement.focus();
         }
      });
   };

   var setLastTabEnabledElement = function(lastTabEnabledElement) {
      if (_lastTabEnabledElement) {
         unbindEventsToTabEnabledElement(_lastTabEnabledElement);
      }

      if (lastTabEnabledElement === null) {
         _lastTabEnabledElement = _defaultLastTabEnabledElement;
      } else {
         _lastTabEnabledElement = lastTabEnabledElement;
      }

      _lastTabEnabledElement.on('keydown', function(e) {
         if (_playerInFullScreenMode && keys.isKeyOfType(e.key, keys.keyTypes.tab) && e.shiftKey === false) {
            e.preventDefault();
            _firstTabEnabledElement.focus();
         }
      });
   };

   var initController = function() {
      if (!_defaultFirstTabEnabledElement || !_defaultLastTabEnabledElement) {
         throw new Error('Tab controller is missing a default tab enabled element');
      }
      setFirstTabEnabledElement(_defaultFirstTabEnabledElement);
      setLastTabEnabledElement(_defaultLastTabEnabledElement);
      $container.on(events.Controls.FullScreenChange, onFullScreenChange);
   };

   var destroy = function() {
      unbindEventsToTabEnabledElement(_firstTabEnabledElement);
      unbindEventsToTabEnabledElement(_lastTabEnabledElement);
      $container.off(events.Controls.FullScreenChange, onFullScreenChange);
   };

   initController();

   return Object.defineProperties({
      destroy: destroy
   }, {
      firstTabEnabledElement: {
         get: function() {
            return _firstTabEnabledElement;
         },
         set: setFirstTabEnabledElement
      },
      lastTabEnabledElement: {
         get: function() {
            return _lastTabEnabledElement;
         },
         set: setLastTabEnabledElement
      }
   });
};

export default {
   create: TabController
};
