var MEASURE_NOT_FOUND_VALUE = -1;
var _performanceApiSupported = window.performance && window.performance.mark && window.performance.measure && window.performance.getEntriesByType;

var getMeasureDuration = function(measureName) {
   if (!_performanceApiSupported) {
      return MEASURE_NOT_FOUND_VALUE;
   }

   try {
      var measureDuration = MEASURE_NOT_FOUND_VALUE;
      var measures = window.performance.getEntriesByType('measure');

      for (var i = 0; i < measures.length; i++) {
         if (measures[i].name === measureName) {
            measureDuration = measures[i].duration;
         }
      }

      return Math.round(measureDuration);
   } catch (e) {
      return MEASURE_NOT_FOUND_VALUE;
   }
};

var createMarker = function(markerName) {
   if (!_performanceApiSupported) {
      return;
   }

   try {
      window.performance.mark(markerName);
   } catch (e) {
      // ignore the error
   }
};

var createMeasure = function(measureName, startMark, endMark) {
   if (!_performanceApiSupported) {
      return;
   }

   try {
      // IE11 and Edge throw a syntax error if undefined is passed in to performance.measure
      if (endMark) {
         window.performance.measure(measureName, startMark, endMark);
      } else {
         window.performance.measure(measureName, startMark);
      }
   } catch (e) {
      // ignore the error
   }
};

var getRumEventData = function(measureNames) {
   if (!_performanceApiSupported) {
      return [];
   }

   var rumData = [];
   measureNames.forEach(function(measureName) {
      var measureDuration = getMeasureDuration(measureName);
      if (measureDuration !== MEASURE_NOT_FOUND_VALUE) {
         rumData.push({
            name: measureName,
            time: measureDuration
         });
      }
   });
   return rumData;
};

export default {
   createMarker: createMarker,
   createMeasure: createMeasure,
   getRumEventData: getRumEventData
};
