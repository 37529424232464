var _english = 'en-US';
var _german = 'de-DE';
var _japanese = 'ja-JP';
var _french = 'fr-FR';
var _spanish = 'es-ES';
var _chinese = 'zh-CN';
var _portuguese = 'pt-BR';
var _supportedLanguages = [
   _english,
   _german,
   _japanese,
   _french,
   _spanish,
   _chinese,
   _portuguese
];

var languageCodes = Object.freeze({
   ENGLISH: _english,
   GERMAN: _german,
   JAPANESE: _japanese,
   FRENCH: _french,
   SPANISH: _spanish,
   CHINESE: _chinese,
   PORTUGUESE: _portuguese,
   ENU: _english,
   DEU: _german,
   JPN: _japanese,
   FRA: _french,
   SP: _spanish,
   ZH: _chinese,
   PT: _portuguese,
   isValid: function(language) {
      return _supportedLanguages.some(function(supportedLanguage) {
         return supportedLanguage === language;
      });
   }
});
export default languageCodes;
