import Promise from 'promise-polyfill';

var DEFAULT_RETRY_LIMIT = 2;
var DEFAULT_BACKOFF = 2000;
var DEFAULT_MAX_DELAY_BETWEEN_RETRIES = 20000;

var BackoffStrategy = function(retryLimit, backoffTime, maxDelay) {
   if (typeof retryLimit === 'undefined') {
      retryLimit = DEFAULT_RETRY_LIMIT;
   }

   if (typeof backoffTime === 'undefined') {
      backoffTime = DEFAULT_BACKOFF;
   }

   if (typeof maxDelay === 'undefined') {
      maxDelay = DEFAULT_MAX_DELAY_BETWEEN_RETRIES;
   }

   var create = function() {
      var tries = 0;
      var backoff = function() {
         tries++;

         if (tries > retryLimit) {
            return Promise.reject();
         }

         var delay = Math.min(backoffTime * tries, maxDelay);

         return new Promise(function(resolve) {
            window.setTimeout(resolve, delay);
         });
      };

      return {
         backoff: backoff
      };
   };

   return {
      create: create
   };
};

var BackoffStrategyWithUnlimitedRetries = function(backoffTime, maxDelay) {
   if (typeof backoffTime === 'undefined') {
      backoffTime = DEFAULT_BACKOFF;
   }

   if (typeof maxDelay === 'undefined') {
      maxDelay = DEFAULT_MAX_DELAY_BETWEEN_RETRIES;
   }

   var create = function() {
      var tries = 0;
      var backoff = function() {
         tries++;
         var delay = Math.min(backoffTime * tries, maxDelay);
         return new Promise(function(resolve) {
            window.setTimeout(resolve, delay);
         });
      };

      return {
         backoff: backoff
      };
   };

   return {
      create: create
   };
};

export default {
   create: BackoffStrategy,
   createWithUnlimitedRetries: BackoffStrategyWithUnlimitedRetries
};
