import {languageIdentifiers, LanguageIdentifier} from '@techsmith/tsc-media-extensions-js';
import playerStringNames from '../constants/player-string-names';
import localizationStrings from '../models/localization-strings';
import deviceInfo from '../utils/device-info';
import cssClasses from '../constants/css-classes';
import keys from '../constants/keys';
import tabIndex from '../constants/tab-index';
import events from '../constants/events';
import highlightDisabler from '../utils/highlight-disabler';
import hotkeyService from '../services/hotkey-service';
import templateGenerator from '../utils/template-generator';
import viewHelper from '../utils/view-helper';
import localStorageWrapper from '../utils/local-storage-wrapper';
import playerConfiguration from '../models/player-configuration';

var LOCAL_STORAGE_PLAYBACK_SPEED_KEY = 'tscSmartPlayerPlaybackSpeed';

var _defaultPlaybackRate = 1.0;
var _defaultAvailablePlaybackRates = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2];

var hideButton = function($container) {
   $container.find('.settings-button').hide();
};

/**
 * @memberof TSC
 * @class PlayerSettingsView
 * @classdesc PlayerSettings View
 */
var PlayerSettingsView = function($container, mediaElement, xmp, playbackRates, audioDescriptionMediaSrc) {
   var _isOpen = false;
   var _currentPlaybackRate = _defaultPlaybackRate;
   var _availablePlaybackRates = playbackRates || _defaultAvailablePlaybackRates;
   var _minPlaybackRate = _availablePlaybackRates[0];
   var _maxPlaybackRate = _availablePlaybackRates[_availablePlaybackRates.length - 1];
   mediaElement.playbackRate = _currentPlaybackRate;
   mediaElement.defaultPlaybackRate = _currentPlaybackRate;

   var getPlaybackRates = function() {
      var playerRateOptionsLocalizedStrings = localizationStrings.getPlayerString(playerStringNames.playerRateOptionsText);
      var playbackRatesList = [];

      for (var j = _availablePlaybackRates.length - 1; j >= 0; j--) {
         var rate = _availablePlaybackRates[j];
         playbackRatesList.push({rateValue: rate, rateLabel: playerRateOptionsLocalizedStrings[rate], selected: rate === _defaultPlaybackRate});
      }

      return playbackRatesList;
   };

   var getClosedCaptionLanguages = function() {
      var ccLanguageList = [];
      var selectedLanguageIdentifier = xmp.captionController.getActiveLanguageIdentifier();
      if (selectedLanguageIdentifier.identifier === languageIdentifiers.identifiers.default) {
         selectedLanguageIdentifier = LanguageIdentifier.create(localizationStrings.getLanguage());
      }

      var foundSelectedLanguageInAvailableList = false;
      xmp.captionController.getAvailableLanguages().forEach(function(languageIdentifierString) {
         var languageIdentifier = LanguageIdentifier.create(languageIdentifierString);
         var languageLabel = localizationStrings.getLanguageNameFromIdentifierString(languageIdentifierString);
         var languageShouldBeSelected = languageIdentifier.language === selectedLanguageIdentifier.language;
         if (languageShouldBeSelected) {
            foundSelectedLanguageInAvailableList = true;
         }
         ccLanguageList.push({languageValue: languageIdentifierString, languageLabel: languageLabel, selected: languageShouldBeSelected});
      });

      if (foundSelectedLanguageInAvailableList) {
         xmp.captionController.setActiveLanguageIdentifier(selectedLanguageIdentifier.identifier);
      } else {
         xmp.captionController.setActiveLanguageIdentifier(languageIdentifiers.identifiers.default);
      }

      return ccLanguageList;
   };

   var playerSettingsViewMarkup = templateGenerator.generateSettingsMarkup(xmp ? xmp.captionsHasMultipleLanguages : false, getPlaybackRates(), xmp ? getClosedCaptionLanguages() : [], xmp && xmp.hasCaptionItems, audioDescriptionMediaSrc);
   var _$playerSettingsWrapper = $container.find('.player-settings-wrapper');
   _$playerSettingsWrapper.html(playerSettingsViewMarkup);

   var _$playerSettingsViewEl = $container.find('.player-settings-container');
   var _$playbackRateSelectEl = _$playerSettingsViewEl.find('.player-speed-select');
   var _$ccLanguageSelectEl = _$playerSettingsViewEl.find('.cc-language-select');
   var _$playerSettingsButton = $container.find('.settings-button');
   var _$playerSettingsButtonGlyph = $container.find('.settings-button-glyph');
   var _$closedCaptionToggleContainer = $container.find('.closed-caption-setting');
   var _$closedCaptionToggle = _$playerSettingsViewEl.find('.cc-toggle');
   var _$closedCaptionToggleElement = _$closedCaptionToggle[0];
   var _$audioDescriptionToggle = _$playerSettingsViewEl.find('.ad-toggle');
   var _$audioDescriptionToggleElement = _$audioDescriptionToggle[0];
   var _$settingsCloseButton = _$playerSettingsViewEl.find('.settings-close-button');
   var _$settingsHeaderContainer = _$playerSettingsViewEl.find('.setting-header-container');

   if (!playerConfiguration.showAcknowledgementLink) {
      _$playerSettingsWrapper.find('.settings-acknowledgementlink').hide();
   }

   var hideView = function() {
      _isOpen = false;
      $container.trigger(events.Controls.PlayerSettingsClose);
      _$playerSettingsViewEl.hide();
      _$playerSettingsButton.attr('aria-expanded', false);
      _$playerSettingsButton.removeClass(cssClasses.tertiaryButtonActive).addClass(cssClasses.tertiaryButton);
      _$playerSettingsButtonGlyph.removeClass(cssClasses.settingsButtonActiveGlyph).addClass(cssClasses.settingsButtonGlyph);
      removeClickHandler();
      viewHelper.removeFocusTrap();
   };

   var showView = function() {
      _isOpen = true;
      $container.trigger(events.Controls.PlayerSettingsOpen);
      _$playerSettingsViewEl.show();
      _$playerSettingsButton.attr('aria-expanded', true);
      _$playerSettingsButton.removeClass(cssClasses.tertiaryButton).addClass(cssClasses.tertiaryButtonActive);
      _$playerSettingsButtonGlyph.removeClass(cssClasses.settingsButtonGlyph).addClass(cssClasses.settingsButtonActiveGlyph);
      addClickHandler();

      trapFocusInModalView();
   };

   var isModalView = function() {
      return _$settingsHeaderContainer.css('display') !== 'none';
   };

   var trapFocusInModalView = function() {
      if (isModalView()) {
         viewHelper.trapFocus(_$playerSettingsViewEl);
      }
   };

   var addClickHandler = function() {
      $container.mouseup(hideSettingsViewOnClickOutside);
   };

   var removeClickHandler = function() {
      $container.off('mouseup', hideSettingsViewOnClickOutside);
   };

   var hideSettingsViewOnClickOutside = function(e) {
      if (!_isOpen) {
         return;
      }
      if (!_$playerSettingsViewEl.is(e.target) && _$playerSettingsViewEl.has(e.target).length === 0) {
         //This timeout is so that the click handler for the settings view button sees the right state
         setTimeout(function() {
            hideView();
         }, 50);
      }
   };

   var syncPlaybackRateControlsWithPlaybackRateValue = function() {
      var realPlaybackRate = mediaElement.playbackRate;
      if (realPlaybackRate !== Number(_$playbackRateSelectEl.find(':selected').val())) {
         if (realPlaybackRate > _maxPlaybackRate) {
            setPlaybackRate(_maxPlaybackRate);
         } else if (realPlaybackRate < _minPlaybackRate) {
            setPlaybackRate(_minPlaybackRate);
         } else {
            _$playbackRateSelectEl.find('.player-speed-option[value="' + mediaElement.playbackRate + '"]').prop('selected', true);
         }
      }
   };

   var setPlaybackRate = function(playbackSpeed) {
      if (!playbackSpeed) {
         playbackSpeed = _defaultPlaybackRate;
      } else if (playbackSpeed < _minPlaybackRate) {
         playbackSpeed = _minPlaybackRate;
      } else if (playbackSpeed > _maxPlaybackRate) {
         playbackSpeed = _maxPlaybackRate;
      }
      _currentPlaybackRate = playbackSpeed;
      mediaElement.playbackRate = playbackSpeed;
      mediaElement.defaultPlaybackRate = playbackSpeed;

      localStorageWrapper.setItem(LOCAL_STORAGE_PLAYBACK_SPEED_KEY, playbackSpeed.toString());

      // workaround to get iOS to apply the playback rate change right away
      if (deviceInfo.isIOS() && !mediaElement.paused) {
         mediaElement.pause();
         mediaElement.play();
      }
   };

   var onPlaybackRateControlChange = function() {
      var playbackSpeed = Number(_$playbackRateSelectEl.find(':selected').val());
      setPlaybackRate(playbackSpeed);
   };

   var onCCLanguageControlChange = function() {
      var ccLanguageCode = _$ccLanguageSelectEl.find(':selected').val();
      xmp.captionController.setActiveLanguageIdentifier(ccLanguageCode);
      $container.trigger(events.Captions.LanguageChanged);
   };

   var onAudioDescriptionToggleChange = function() {
      var checked = _$audioDescriptionToggleElement.checked;

      $container.trigger(events.AudioDescription.Changed, {audioDescriptionEnabled: checked});
   };

   var onAudioDescriptionChange = function(e, eventData) {
      if (eventData.audioDescriptionEnabled) {
         _$audioDescriptionToggleElement.checked = true;
      } else {
         _$audioDescriptionToggleElement.checked = false;
      }
   };

   var onClosedCaptionToggleChange = function() {
      var checked = _$closedCaptionToggleElement.checked;

      $container.trigger(events.Captions.Toggled, {captionsEnabled: checked, savePreference: true});
   };

   var onCaptionsToggled = function(e, eventData) {
      if (_$closedCaptionToggleElement) {
         if (eventData.captionsEnabled) {
            _$closedCaptionToggleElement.checked = true;
         } else {
            _$closedCaptionToggleElement.checked = false;
         }
      }
   };

   var onPlaybackRateValueChange = function() {
      syncPlaybackRateControlsWithPlaybackRateValue();
   };

   var incrementPlaybackRate = function() {
      var indexOfCurrentRate = _availablePlaybackRates.indexOf(_currentPlaybackRate);
      if (indexOfCurrentRate === -1) {
         return;
      }
      if (indexOfCurrentRate + 1 < _availablePlaybackRates.length) {
         setPlaybackRate(_availablePlaybackRates[indexOfCurrentRate + 1]);
      }
   };

   var decrementPlaybackRate = function() {
      var indexOfCurrentRate = _availablePlaybackRates.indexOf(_currentPlaybackRate);
      if (indexOfCurrentRate === -1) {
         return;
      }
      if (indexOfCurrentRate > 0) {
         setPlaybackRate(_availablePlaybackRates[indexOfCurrentRate - 1]);
      }
   };

   var onKeyboardShortCut = function(e) {
      if (!hotkeyService.areHotkeysEnabled()) {
         return;
      }

      if (keys.isKeyOfType(e.key, keys.keyTypes.upArrow) && e.shiftKey) {
         e.preventDefault();
         incrementPlaybackRate();
      } else if (keys.isKeyOfType(e.key, keys.keyTypes.downArrow) && e.shiftKey) {
         e.preventDefault();
         decrementPlaybackRate();
      } else if (keys.isKeyOfType(e.key, keys.keyTypes.escape)) {
         if (_isOpen) {
            if (_$playbackRateSelectEl.is(':focus') || _$ccLanguageSelectEl.is(':focus')) {
               _$playerSettingsButton.focus();
            }
            hideView();
         }
      }
   };

   var stopPropagation = function(e) {
      e.stopPropagation();
   };

   var disableControl = function() {
      _$playerSettingsButton.attr('tabindex', tabIndex.Disabled);
   };

   var enableControl = function() {
      _$playerSettingsButton.attr('tabindex', tabIndex.DomOrder);
   };

   var onPlayerSettingsButtonClick = function(e) {
      e.preventDefault();
      if (_isOpen) {
         hideView();
      } else {
         showView();
         highlightDisabler.disableHighlightsOnMouseClick(_$playbackRateSelectEl, e);
      }
   };

   var onDisplayCaptionControl = function(e, eventData) {
      if (eventData.displayCaptionControl) {
         _$closedCaptionToggleContainer.removeClass(cssClasses.hide);
      } else {
         _$closedCaptionToggleContainer.addClass(cssClasses.hide);
      }
   };

   var loadSavedSettings = function() {
      var savedPlayBackSpeedFromLocalStorage = localStorageWrapper.getItem(LOCAL_STORAGE_PLAYBACK_SPEED_KEY);
      if (savedPlayBackSpeedFromLocalStorage) {
         setPlaybackRate(+savedPlayBackSpeedFromLocalStorage);
      }
   };

   var initializeHandlers = function() {
      _$playbackRateSelectEl.on('click', stopPropagation);
      _$playbackRateSelectEl.on('mouseup', stopPropagation);
      _$playbackRateSelectEl.on('change', onPlaybackRateControlChange);

      _$ccLanguageSelectEl.on('click', stopPropagation);
      _$ccLanguageSelectEl.on('mouseup', stopPropagation);
      _$ccLanguageSelectEl.on('change', onCCLanguageControlChange);

      _$playerSettingsButton.on('click', onPlayerSettingsButtonClick);

      _$settingsCloseButton.on('click', close);

      _$audioDescriptionToggle.on('change', onAudioDescriptionToggleChange);

      _$closedCaptionToggle.on('change', onClosedCaptionToggleChange);

      if (mediaElement.addEventListener) {
         mediaElement.addEventListener('ratechange', onPlaybackRateValueChange, false);
      } else if (mediaElement.on) {
         mediaElement.on('ratechange', onPlaybackRateValueChange);
      }

      $container.on(events.Controls.Disable, disableControl);
      $container.on(events.Controls.Enable, enableControl);
      $container.on('keydown', onKeyboardShortCut);
      $container.on(events.AudioDescription.Changed, onAudioDescriptionChange);
      $container.on(events.Captions.Toggled, onCaptionsToggled);
      $container.on(events.Controls.DisplayCaptionControl, onDisplayCaptionControl);
   };

   var destroy = function() {
      _$playbackRateSelectEl.off('click', stopPropagation);
      _$playbackRateSelectEl.off('mouseup', stopPropagation);
      _$playbackRateSelectEl.off('change', onPlaybackRateControlChange);
      _$ccLanguageSelectEl.off('click', stopPropagation);
      _$ccLanguageSelectEl.off('mouseup', stopPropagation);
      _$ccLanguageSelectEl.off('change', onCCLanguageControlChange);
      _$playerSettingsButton.off('click', onPlayerSettingsButtonClick);
      _$settingsCloseButton.off('click', close);
      _$audioDescriptionToggle.off('change', onAudioDescriptionToggleChange);
      _$closedCaptionToggle.off('change', onClosedCaptionToggleChange);

      if (mediaElement.removeEventListener) {
         mediaElement.removeEventListener('ratechange', onPlaybackRateValueChange);
      } else if (mediaElement.off) {
         mediaElement.off('ratechange', onPlaybackRateValueChange);
      }

      $container.off(events.Controls.Disable, disableControl);
      $container.off(events.Controls.Enable, enableControl);
      $container.off('keydown', onKeyboardShortCut);
      $container.off(events.AudioDescription.Changed, onAudioDescriptionChange);
      $container.off(events.Captions.Toggled, onCaptionsToggled);
      $container.off(events.Controls.DisplayCaptionControl, onDisplayCaptionControl);
   };

   var open = function() {
      if (!_isOpen) {
         showView();
      }
   };

   var close = function() {
      if (_isOpen) {
         hideView();
      }
   };

   loadSavedSettings();
   initializeHandlers();
   hideView();

   return Object.defineProperties({
      open: open,
      close: close,
      destroy: destroy
   }, {
      isOpen: {
         get: function() {
            return _isOpen;
         }
      },
      playbackRate: {
         get: function() {
            return _currentPlaybackRate;
         },
         set: setPlaybackRate
      }
   });
};

export default {
   /**
    *  Factory method that returns a new PlayerSettingsView object.
    *  @function create
    *  @memberof TSC.PlayerSettingsView
    *  @static
    *  @param {Object} $container - jQuery element that contains a .settings-button element and a .player-settings-wrapper element where the player settings controls will be injected
    *  @param {Object} mediaElement - html5 media DOM element (or wrapper presenting same interface)
    *  @param {Array} playbackRates - optional - a list of available playback rates
    *  @param {TSC.xmp} xmp - optional - xmp data model
    *  @param {string} audioDescriptionMediaSrc - optional - string containing media source to determine if Audio Description button is needed
    *  @return new PlayerSettingsView instance
    */
   create: PlayerSettingsView,
   /**
    *  Method that hides the settings button within a container.
    *  @function hideButton
    *  @memberof TSC.PlayerSettingsView
    *  @static
    *  @param {Object} $container - jQuery element that contains a .settings-button element to be hidden
    */
   hideButton: hideButton
};
