const deprecatedConfigSetter = (deprecatedName) => {
   console.info(`[SmartPlayer] ${deprecatedName} setter is deprecated.`); // eslint-disable-line no-console
};

const deprecatedConfigGetter = (deprecatedName, returnValue) => {
   console.info(`[SmartPlayer] ${deprecatedName} getter is deprecated.`); // eslint-disable-line no-console
   return returnValue;
};

export {deprecatedConfigGetter, deprecatedConfigSetter};
