import cssClasses from '../constants/css-classes';
import events from '../constants/events';
import tabIndex from '../constants/tab-index';
import templateGenerator from '../utils/template-generator';

var PrevNextControlsView = function($container) {
   var _markup = templateGenerator.generatePrevNextControlsMarkup();
   $container.find('.prev-next-controls').html(_markup);

   var _$previousControl = $container.find('.previous-control');
   var _$nextControl = $container.find('.next-control');

   var disableKeyListeners = function() {
      _$previousControl.attr('tabindex', tabIndex.Disabled);
      _$nextControl.attr('tabindex', tabIndex.Disabled);
   };

   var enableKeyListeners = function() {
      _$previousControl.attr('tabindex', tabIndex.DomOrder);
      _$nextControl.attr('tabindex', tabIndex.DomOrder);
   };

   $container.on(events.Controls.Disable, disableKeyListeners);
   $container.on(events.Controls.Enable, enableKeyListeners);

   _$previousControl.click(function() {
      $container.trigger(events.Controls.ClickPrev);
   });

   _$nextControl.click(function() {
      $container.trigger(events.Controls.ClickNext);
   });

   var hide = function() {
      _$previousControl.addClass(cssClasses.hide);
      _$nextControl.addClass(cssClasses.hide);
   };

   var show = function() {
      _$previousControl.removeClass(cssClasses.hide);
      _$nextControl.removeClass(cssClasses.hide);
   };

   var disablePreviousButton = function() {
      _$previousControl.addClass(cssClasses.disabled).attr('aria-disabled', true);
   };

   var enablePreviousButton = function() {
      _$previousControl.removeClass(cssClasses.disabled).attr('aria-disabled', false);
   };

   var disableNextButton = function() {
      _$nextControl.addClass(cssClasses.disabled).attr('aria-disabled', true);
   };

   var enableNextButton = function() {
      _$nextControl.removeClass(cssClasses.disabled).attr('aria-disabled', false);
   };

   var destroy = function() {
      $container.off(events.Controls.Disable, disableKeyListeners);
      $container.off(events.Controls.Enable, enableKeyListeners);
      $container.find('.prev-next-controls').html('');
   };

   return {
      show: show,
      hide: hide,
      disablePreviousButton: disablePreviousButton,
      enablePreviousButton: enablePreviousButton,
      disableNextButton: disableNextButton,
      enableNextButton: enableNextButton,
      destroy: destroy
   };
};

export default {
   create: PrevNextControlsView
};
