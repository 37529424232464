import $ from 'jquery';
import highlightDisabler from '../utils/highlight-disabler';
import keys from '../constants/keys';

var _previousActiveElement;
var _previousActiveElementWasHighlighted;
var _$focusTrapElement = null;
var _firstFocusableEl = null;
var _lastFocusableEl = null;
var _$window = $(window);

var scrollActiveElementIntoView = function() {
   try {
      var activeElement = document.activeElement;
      if (activeElement) {
         scrollIntoView(activeElement);
      }
   } catch (e) {
      /* eslint  no-empty:'off' */
   }
};

var scrollIntoView = function(e) {
   setTimeout(function() {
      var element = e.currentTarget ? e.currentTarget : e;
      var container = $(element).scrollParent();

      if (!element || !container || !element.scrollIntoViewIfNeeded) {
         return;
      }

      // Android uses container innerHeight while ios uses window innerHeight
      var elementFitsInsideContainer = element.offsetHeight <= container.innerHeight() && element.offsetHeight < window.innerHeight;
      element.scrollIntoViewIfNeeded(elementFitsInsideContainer);
   }, 200);
};

var saveActiveElement = function() {
   try {
      _previousActiveElement = document.activeElement;
      _previousActiveElementWasHighlighted = highlightDisabler.checkIfElementIsHighlighted($(document.activeElement));
   } catch (e) {
      /* eslint  no-empty:'off' */
   }
};

var disableHighlightsOnPreviousActiveElement = function() {
   if (_previousActiveElement) {
      var $previousActiveElement = $(_previousActiveElement);
      if (!_previousActiveElementWasHighlighted) {
         highlightDisabler.disableHighlights($previousActiveElement);
      }
   }
};

var focusPreviousElement = function() {
   if (_previousActiveElement) {
      var $previousActiveElement = $(_previousActiveElement);
      $previousActiveElement.focus();
      disableHighlightsOnPreviousActiveElement();
   }
};

var elementOrChildHasFocus = function($element) {
   try {
      return $element.is(':focus') || $element.has(document.activeElement).length > 0;
   } catch (e) {
      return false;
   }
};

var getActiveElement = function(document) {
   document = document || window.document;

   if (document.body === document.activeElement || document.activeElement.tagName.toLowerCase() === 'iframe') {
      var iframes = document.getElementsByTagName('iframe');

      for (var i = 0; i < iframes.length; i++) {
         var focused = getActiveElement(iframes[i].contentWindow.document);

         if (focused !== false) {
            return focused;
         }
      }
   } else {
      return document.activeElement;
   }

   return false;
};

var onFocusTrapTab = function(e) {
   var isTabPressed = keys.isKeyOfType(e.key, keys.keyTypes.tab);
   if (!isTabPressed) {
      return;
   }

   if (e.shiftKey && document.activeElement === _firstFocusableEl) {
      _lastFocusableEl.focus();
      e.preventDefault();
   } else if (!e.shiftKey && document.activeElement === _lastFocusableEl) {
      _firstFocusableEl.focus();
      e.preventDefault();
   }
};

var removeFocusTrap = function() {
   if (!_$focusTrapElement) {
      return;
   }
   
   _$focusTrapElement.off('keydown', onFocusTrapTab);
   _firstFocusableEl = null;
   _lastFocusableEl = null;
};

var trapFocus = function($element) {
   if (_$focusTrapElement) {
      removeFocusTrap();
   }

   _$focusTrapElement = $element;
   var focusableEls = $element.find('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="email"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])');
   _firstFocusableEl = focusableEls[0];
   _lastFocusableEl = focusableEls[focusableEls.length - 1];

   _$focusTrapElement.on('keydown', onFocusTrapTab);
};

var init = function() {
   _$window.blur(saveActiveElement).focus(disableHighlightsOnPreviousActiveElement);
};

init();

export default {
   trapFocus: trapFocus,
   removeFocusTrap: removeFocusTrap,
   scrollActiveElementIntoView: scrollActiveElementIntoView,
   scrollIntoView: scrollIntoView,
   saveActiveElement: saveActiveElement,
   focusPreviousElement: focusPreviousElement,
   elementOrChildHasFocus: elementOrChildHasFocus,
   getActiveElement: getActiveElement
};
