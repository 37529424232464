import deviceInfo from '../utils/device-info';
import playerStringNames from '../constants/player-string-names';
import localizationStrings from '../models/localization-strings';
import themeNames from '../../common/theme-names';
import preloadOptions, {isValidPreloadOption} from '../constants/preload-options';

var DEFAULT_SKIP_DURATION_IN_SECONDS = 5;
var endsWithSlashRegex = /\/$/;

var _mediaSources = [];
var _xmpSrc;
var _posterImageSrc;
var _mediaTitle = '';
var _tocSpriteSrc;
var _tocTitle = null;
var _mediaRotation = 0;
var _endActionType = 'stop';
var _endActionParam = true;
var _ignoreXmpControllerParameters = [];
var _ignoreQuizQuestions = false;
var _isSearchable = true; // make it undefined for initial value
var _autoPlayMedia = false;
var _forceAutoPlayMedia = false;
var _skipVideoDuration = -1;
var _skipVideoControlsEnabled = true;
var _captionsEnabled = null;
var _ignoreStoredCaptionVisibleSetting = false;
var _sidebarEnabled = false;
var _sidebarLocation = 'left';
var _backgroundColor = '#000';
var _useUnicode = false;
var _autoHideControls = true;
var _reportScormComplete = false;
var _isScormLesson = false;
var _enforceLinearAssessment = true;
var _disableFullframeMode = true;
var _disableControls = false;
var _disableYouTubeControls = true;
var _disablePictureInPicture = false;
var _disableContinueWithoutQuestions = false;
var _mobileWebViewCanAutoPlay = false;
var _googleAnalyticsID;
var _gaPageViewTrack = true;
var _gaEventTrack = true;
var _customEventTracking = false;
var _customEventCallback;
var _ga4EventCallback;
var _altEventCategoryAsFilename = false;
var _altLoadTimeAsSeconds = false;
var _jumpToTime;
var _techSmithAccessTokenEndpoint;
var _isUserIdentified = false;
var _fathomId;
var _embedIFrameId;
var _additionalAppQueryString = '';
var _useSlimAppSplashScreen = true;
var _showYouTubeAnnotations = false;
var _preRollSrc;
var _contentType = '';
var _preload = preloadOptions.none;
var _fullViewPort = true;
var _webConnector;
var _contentBasePath = '';
var _disableContextMenuForVideo = false;
var _theme = themeNames.DUSK;
var _enableSingleClickControl = true;
var _enableDoubleClickControl = true;
var _pluginController = null;
var _useLargerProgressBar = false;
var _displayHotspotsShape = false;
var _audioDescriptionMediaSrc = null;
var _captionSrcList = [];
var _disableInteractions = false;
var _allowFastForward = true;
var _showADACompliantCaptions = false;
var _rememberUserInfo = false;
var _logScormApiCalls = false;
var _userInfo = {};
var _videoThumbnailsSrc = null;
var _videoThumbnailWidth = 0;
var _videoThumbnailHeight = 0;
var _videoThumbnailInterval = 0;
var _videoThumbnailColumns = 0;
var _tocModelFromDescription = null;
var _completeScormOnRequirementsComplete = true;
var _customFullScreenDomElement = null;
var _quizServiceBaseUrl = null;
var _resultsServiceBaseUrl = null;
var _enableTheaterMode = false;
var _showAcknowledgementLink = true;

var isValidTheme = function(themeName) {
   return Object.keys(themeNames).some(function(themeKey) {
      return themeNames[themeKey] === themeName;
   });
};

var decodedApostrophe = function(val) { //if runs on a server and is IE
   return val.replace(/&#8217;/g, '\'');
};

var containsNonLatinCodepoints = function(str) {
   return /[^\u0000-\u00ff]/.test(str); // eslint-disable-line no-control-regex
};

var runOnServer = function() {
   return window.document.location.href.indexOf('http') === 0;
};

var getUnicodeSafeString = function(val) {
   if (val === undefined) {
      return val;
   }

   if (!_useUnicode) {
      return val;
   }

   if (!deviceInfo.isIE()) {
      return encodeURI(val);
   }

   if (!runOnServer()) {
      return decodeURI(val);
   }

   if (containsNonLatinCodepoints(val)) {
      return encodeURI(val);
   }

   return val;
};

var clearConfiguration = function() {
   _mediaSources = [];
   _preload = preloadOptions.none;
   _ignoreStoredCaptionVisibleSetting = false;
   _captionsEnabled = null;
   _pluginController = null;
   _ignoreXmpControllerParameters = [];
   _ignoreQuizQuestions = false;
   _captionSrcList = [];
   _skipVideoControlsEnabled = true;
   _skipVideoDuration = -1;
   _allowFastForward = true;
   _showADACompliantCaptions = false;
   _rememberUserInfo = false;
   _mediaTitle = '';
   _tocTitle = null;
   _userInfo = {};
   _logScormApiCalls = false;
   _videoThumbnailsSrc = null;
   _videoThumbnailWidth = 0;
   _videoThumbnailHeight = 0;
   _videoThumbnailInterval = 0;
   _videoThumbnailColumns = 0;
   _tocModelFromDescription = null;
   _completeScormOnRequirementsComplete = true;
   _quizServiceBaseUrl = null;
   _resultsServiceBaseUrl = null;
   _customFullScreenDomElement = null;
   _enableTheaterMode = false;
   _ga4EventCallback = null;
   _showAcknowledgementLink = true;
};

var getUnableToDisplayContentString = function() {
   return localizationStrings.getPlayerString(playerStringNames.videoNotSupported);
};

var useCustomEventTracking = function(customEventTracking, customEventCallback) {
   _customEventTracking = customEventTracking;
   _customEventCallback = customEventCallback;
};

var getGA4EventCallback = function() {
   return _ga4EventCallback;
};

var setGA4EventCallback = function(ga4EventCallback) {
   _ga4EventCallback = ga4EventCallback;
};

var getTrackEvents = function() {
   return _customEventTracking || _googleAnalyticsID !== undefined;
};

var getCustomEventTracking = function() {
   return _customEventTracking;
};

var getCustomEventCallback = function() {
   return _customEventCallback;
};

var getIsUserIdentified = function() {
   return _isUserIdentified;
};

var setAdditionalAppQueryParamsFromJson = function(params) {
   var queryString = '';
   for (var key in params) {
      queryString += '&' + key + '=' + params[key];
   }
   _additionalAppQueryString = queryString;
};

var getAdditionalAppQueryString = function() {
   return _additionalAppQueryString;
};

var addMediaSrc = function(mediaSrc) {
   if (typeof mediaSrc === 'string') {
      _mediaSources.push(decodedApostrophe(mediaSrc));
   } else {
      _mediaSources.push(mediaSrc);
   }
};

var getMediaSources = function() {
   return _mediaSources;
};

var getMediaFileName = function() {
   var mediaSource = _mediaSources.length > 0 ? _mediaSources[0] : undefined;

   if (mediaSource) {
      var splitEl = mediaSource.split('/');
      var mediaFileName = splitEl[splitEl.length - 1];
      return mediaFileName.split('?')[0] || 'Unnamed Media';
   }
   return 'No Media';
};

var saveConfigurationFromQueryString = function(configParams) {
   if (!(configParams instanceof Array)) {
      return;
   }
   var param;
   var numberParams = configParams.length;

   for (var i = 0; i < numberParams; i++) {
      param = configParams[i].split('=');
      if (param.length === 2) {
         switch (param[0].toLowerCase()) {
            case 'embediframeid': {
               _embedIFrameId = param[1];
               break;
            }
            case 't': {
               _jumpToTime = param[1];
               break;
            }
            case 'autoplay': {
               if (param[1].toLowerCase() === 'true') {
                  _autoPlayMedia = true;
               } else if (param[1].toLowerCase() === 'false') {
                  _autoPlayMedia = false;
               }
               break;
            }
            case 'forceautoplay': {
               if (param[1].toLowerCase() === 'true') {
                  _forceAutoPlayMedia = true;
               } else if (param[1].toLowerCase() === 'false') {
                  _forceAutoPlayMedia = false;
               }
               break;
            }
            case 'autohidecontrols': {
               if (param[1].toLowerCase() === 'true') {
                  _autoHideControls = true;
               } else if (param[1].toLowerCase() === 'false') {
                  _autoHideControls = false;
               }
               break;
            }
            case 'theme': {
               if (isValidTheme(param[1])) {
                  _theme = param[1];
               }
            }
         }
      }
   }
};

var isParamBeingIgnored = function(param) {
   for (var i = 0; i < _ignoreXmpControllerParameters.length; i++) {
      if (param === _ignoreXmpControllerParameters[i]) {
         return true;
      }
   }

   return false;
};

var shouldLoadXmpParam = function(param1, param2) {
   if (param1 !== param2) {
      return false;
   }

   return !isParamBeingIgnored(param1);
};

var configureFromXmp = function(xmp) {
   if (isParamBeingIgnored('*')) {
      return;
   }

   xmp.controllerParameters.forEach(function(controllerParameter) {
      var param = controllerParameter.name.toUpperCase();
      switch (true) {
         case shouldLoadXmpParam('AUTOHIDE', param):
            _autoHideControls = controllerParameter.value === 'true';
            break;
         case shouldLoadXmpParam('AUTOSTART', param):
         case shouldLoadXmpParam('AUTOPLAY', param):
            if (!_autoPlayMedia) {
               _autoPlayMedia = controllerParameter.value === 'true';
            }
            break;
         case shouldLoadXmpParam('SEARCHABLE', param):
            _isSearchable = controllerParameter.value === 'true';
            break;
         case shouldLoadXmpParam('CAPTIONSENABLED', param):
            if (!_ignoreStoredCaptionVisibleSetting) {
               _captionsEnabled = controllerParameter.value === 'true';
            }
            break;
         case shouldLoadXmpParam('SIDEBARENABLED', param):
            _sidebarEnabled = controllerParameter.value === 'true';
            break;
         case shouldLoadXmpParam('UNICODEENABLED', param):
            _useUnicode = controllerParameter.value === 'true';
            break;
         case shouldLoadXmpParam('BACKGROUNDCOLOR', param):
            var bgColor = controllerParameter.value;
            if (bgColor.indexOf('#') === -1) {
               bgColor = '#' + bgColor;
            }
            _backgroundColor = bgColor;
            break;
         case shouldLoadXmpParam('SIDEBARLOCATION', param):
            _sidebarLocation = controllerParameter.value === 'right' || controllerParameter.value === 'r' ? 'right' : 'left';
            break;
         case shouldLoadXmpParam('ENDACTION', param):
            _endActionType = controllerParameter.value;
            break;
         case shouldLoadXmpParam('ENDACTIONPARAM', param):
            _endActionParam = controllerParameter.value;
            break;
      }
   });
   // Only allow URLs that start with http:// or https:// so that
   // javascript: URLs cannot be used maliciously.
   var endActionType = _endActionType.toLowerCase();
   if (endActionType === 'jumptourl' || endActionType === 'jumptourlnew') {
      var endActionParam = _endActionParam.toLowerCase();
      if (endActionParam.indexOf('http://') !== 0 && endActionParam.indexOf('https://') !== 0) {
         _endActionType = 'stop';
         _endActionParam = 'true';
      }
   }
};

var getMediaSrc = function() {
   // pull the first media source from the list.  Maintain backward compliance with version 1.0.20 and earlier
   return _mediaSources.length > 0 ? _mediaSources[0] : '';
};

var setMediaSrc = function(mediaSrc) {
   _mediaSources[0] = decodedApostrophe(mediaSrc);
};

var getXMPSrc = function() {
   return getUnicodeSafeString(_xmpSrc);
};

var setXMPSrc = function(xmpSrc) {
   _xmpSrc = decodedApostrophe(xmpSrc);
};

var getDisableInteractions = function() {
   return _disableInteractions;
};

var setDisableInteractions = function(disableInteractions) {
   _disableInteractions = disableInteractions;
};

var getPosterImageSrc = function() {
   return getUnicodeSafeString(_posterImageSrc);
};

var setPosterImageSrc = function(posterImageSrc) {
   _posterImageSrc = posterImageSrc;
};

var getTOCSpriteSrc = function() {
   return getUnicodeSafeString(_tocSpriteSrc);
};

var setTOCSpriteSrc = function(tocSpriteSrc) {
   _tocSpriteSrc = tocSpriteSrc;
};

var getMediaTitle = function() {
   return _mediaTitle;
};

var setMediaTitle = function(mediaTitle) {
   _mediaTitle = mediaTitle;
};

var getTOCTitle = function() {
   return _tocTitle;
};

var setTOCTitle = function(tocTitle) {
   _tocTitle = tocTitle;
};

var getMediaRotation = function() {
   return _mediaRotation;
};

var setMediaRotation = function(rotation) {
   _mediaRotation = rotation;
};

var getEndActionType = function() {
   return _endActionType;
};

var setEndActionType = function(endActionType) {
   _endActionType = endActionType;
};

var getEndActionParam = function() {
   return _endActionParam;
};

var setEndActionParam = function(endActionParam) {
   _endActionParam = endActionParam;
};

var getIgnoreXmpControllerParameters = function() {
   return _ignoreXmpControllerParameters;
};

var setIgnoreXmpControllerParameters = function(ignoreXmpControllerParameters) {
   var upperCasedIgnores = ignoreXmpControllerParameters.map(function(p) {
      return p.toUpperCase();
   });
   _ignoreXmpControllerParameters = upperCasedIgnores;
};

var getIgnoreQuizQuestions = function() {
   return _ignoreQuizQuestions;
};

var setIgnoreQuizQuestions = function(ignoreQuizQuestions) {
   _ignoreQuizQuestions = ignoreQuizQuestions;
};

var getIsSearchable = function() {
   return _isSearchable;
};

var setIsSearchable = function(isSearchable) {
   _isSearchable = isSearchable;
};

var getAutoPlayMedia = function() {
   return _autoPlayMedia;
};

var setAutoPlayMedia = function(autoPlayMedia) {
   _autoPlayMedia = autoPlayMedia;
};

var getForceAutoPlayMedia = function() {
   return _forceAutoPlayMedia;
};

var setForceAutoPlayMedia = function(forceAutoPlayMedia) {
   _forceAutoPlayMedia = forceAutoPlayMedia;
};

var getCaptionsEnabled = function() {
   return _captionsEnabled === true;
};

var setCaptionsEnabled = function(captionsEnabled) {
   _captionsEnabled = captionsEnabled;
};

var getIgnoreStoredCaptionVisibleSetting = function() {
   return _ignoreStoredCaptionVisibleSetting;
};

var setIgnoreStoredCaptionVisibleSetting = function(ignoreStored) {
   _ignoreStoredCaptionVisibleSetting = ignoreStored;
};

var getSidebarEnabled = function() {
   return _sidebarEnabled;
};

var setSidebarEnabled = function(sidebarEnabled) {
   _sidebarEnabled = sidebarEnabled;
};

var getSidebarLocation = function() {
   return _sidebarLocation;
};

var setSidebarLocation = function(sidebarLocation) {
   _sidebarLocation = sidebarLocation;
};

var getBackgroundColor = function() {
   return _backgroundColor;
};

var setBackgroundColor = function(backgroundColor) {
   _backgroundColor = backgroundColor;
};

var getAutoHideControls = function() {
   return _autoHideControls;
};

var setAutoHideControls = function(autoHideControls) {
   _autoHideControls = autoHideControls;
};

var getProcessUnicodeNames = function() {
   return _useUnicode;
};

var setProcessUnicodeNames = function(useUnicode) {
   _useUnicode = useUnicode;
};

var getEnforceLinearAssessment = function() {
   return _enforceLinearAssessment;
};

var setEnforceLinearAssessment = function(enforceLinearAssessment) {
   _enforceLinearAssessment = enforceLinearAssessment;
};

var getDisableFullframeMode = function() {
   return _disableFullframeMode;
};

var setDisableFullframeMode = function(disableFullframeMode) {
   _disableFullframeMode = disableFullframeMode;
};

var getDisableControls = function() {
   return _disableControls;
};

var setDisableControls = function(disableControls) {
   _disableControls = disableControls;
};

var getDisableYouTubeControls = function() {
   return _disableYouTubeControls;
};

var setDisableYouTubeControls = function(disableYouTubeControls) {
   _disableYouTubeControls = disableYouTubeControls;
};

var getDisablePictureInPicture = function() {
   return _disablePictureInPicture;
};

var setDisablePictureInPicture = function(disablePictureInPicture) {
   _disablePictureInPicture = disablePictureInPicture;
};

var getDisableContinueWithoutQuestions = function() {
   return _disableContinueWithoutQuestions;
};

var setDisableContinueWithoutQuestions = function(disableContinueWithoutQuestions) {
   _disableContinueWithoutQuestions = disableContinueWithoutQuestions;
};

var getMobileWebViewCanAutoPlay = function() {
   return _mobileWebViewCanAutoPlay;
};

var setMobileWebViewCanAutoPlay = function(canAutoPlay) {
   _mobileWebViewCanAutoPlay = canAutoPlay;
};

var getGoogleAnalyticsID = function() {
   return _googleAnalyticsID;
};

var setGoogleAnalyticsID = function(id) {
   console.warn('Setting custom Google Analytics has been removed'); // eslint-disable-line
   _googleAnalyticsID = id;
};

var getGAPageViewTracking = function() {
   return _gaPageViewTrack;
};

var setGAPageViewTracking = function(gaPageViewTrack) {
   _gaPageViewTrack = gaPageViewTrack;
};

var getGAEventTracking = function() {
   return _gaEventTrack;
};

var setGAEventTracking = function(gaEventTrack) {
   _gaEventTrack = gaEventTrack;
};

var getAltEventCategoryAsFilename = function() {
   return _altEventCategoryAsFilename;
};

var setAltEventCategoryAsFilename = function(altEventCategoryAsFilename) {
   _altEventCategoryAsFilename = altEventCategoryAsFilename;
};

var getAltLoadTimeAsSeconds = function() {
   return _altLoadTimeAsSeconds;
};

var setAltLoadTimeAsSeconds = function(altLoadTimeAsSeconds) {
   _altLoadTimeAsSeconds = altLoadTimeAsSeconds;
};

var getTechSmithAccessTokenEndpoint = function() {
   return _techSmithAccessTokenEndpoint;
};

var setTechSmithAccessTokenEndpoint = function(accessTokenEndpoint) {
   _techSmithAccessTokenEndpoint = accessTokenEndpoint;
   setUserSignedIn(true);
};

var getJumpToTime = function() {
   return _jumpToTime;
};

var setJumpToTime = function(time) {
   _jumpToTime = time;
};

var getFathomId = function() {
   return _fathomId;
};

var setFathomId = function(fathomId) {
   _fathomId = fathomId;
};

var getEmbedIFrameId = function() {
   return _embedIFrameId;
};

var setEmbedIFrameId = function(embedIFrameId) {
   _embedIFrameId = embedIFrameId;
};

var getUseSlimAppSplashScreen = function() {
   return _useSlimAppSplashScreen;
};

var setUseSlimAppSplashScreen = function(useSlimAppSplashScreen) {
   _useSlimAppSplashScreen = useSlimAppSplashScreen;
};

var getDisableContextMenuForVideo = function() {
   return _disableContextMenuForVideo;
};

var setDisableContextMenuForVideo = function(disableContextMenuForVideo) {
   _disableContextMenuForVideo = disableContextMenuForVideo;
};

var getShowYouTubeAnnotations = function() {
   return _showYouTubeAnnotations;
};

var setShowYouTubeAnnotations = function(showAnnotations) {
   _showYouTubeAnnotations = showAnnotations;
};

var getPreRollSrc = function() {
   return _preRollSrc;
};

var setPreRollSrc = function(preRollSrc) {
   _preRollSrc = preRollSrc;
};

var getContentBase = function() {
   return _contentBasePath;
};

var setContentBase = function(value) {
   _contentBasePath = value;
};

var getContentType = function() {
   return _contentType;
};

var setContentType = function(contentType) {
   _contentType = contentType;
};

var getPreload = function() {
   return _preload;
};

var setPreload = function(preloadOption) {
   if (isValidPreloadOption(preloadOption)) {
      _preload = preloadOption;
   }
};

var getFullViewPort = function() {
   return _fullViewPort;
};

var setFullViewPort = function(value) {
   _fullViewPort = value;
};

var getWebConnector = function() {
   return _webConnector;
};

var setWebConnector = function(value) {
   _webConnector = value;
};

var getTheme = function() {
   return _theme;
};

var setTheme = function(value) {
   if (isValidTheme(value)) {
      _theme = value;
   }
};

var getEnableSingleClickControl = function() {
   return _enableSingleClickControl;
};

var setEnableSingleClickControl = function(value) {
   _enableSingleClickControl = value;
};

var getEnableDoubleClickControl = function() {
   return _enableDoubleClickControl;
};

var setEnableDoubleClickControl = function(value) {
   _enableDoubleClickControl = value;
};

var getPluginController = function() {
   return _pluginController;
};

var setPluginController = function(pluginController) {
   _pluginController = pluginController;
};

var getUseLargerProgressBar = function() {
   return _useLargerProgressBar;
};

var setUseLargerProgressBar = function(useLargerProgressBar) {
   _useLargerProgressBar = useLargerProgressBar;
};

// legacy scorm support
var getReportScormComplete = function() {
   return _reportScormComplete;
};

var setReportScormComplete = function(reportScorm) {
   _reportScormComplete = reportScorm;
};

// built in scorm support
var getIsScormLesson = function() {
   return _isScormLesson;
};

var setIsScormLesson = function(isScormLesson) {
   _isScormLesson = isScormLesson;
};

var getDisplayHotspotsShape = function() {
   return _displayHotspotsShape;
};

var setDisplayHotspotsShape = function(displayHotspotsShape) {
   _displayHotspotsShape = displayHotspotsShape;
};

var getAudioDescriptionMediaSrc = function() {
   return _audioDescriptionMediaSrc;
};

var setAudioDescriptionMediaSrc = function(audioDescriptionMediaSrc) {
   _audioDescriptionMediaSrc = audioDescriptionMediaSrc;
};

var addCaptionSrc = function(captionSrc, languageIdentifierString) {
   _captionSrcList.push({
      src: captionSrc,
      languageIdentifier: languageIdentifierString
   });
};

var getCaptionSrcList = function() {
   return _captionSrcList;
};

var getSkipVideoControlsEnabled = function() {
   return _skipVideoControlsEnabled;
};

var setSkipVideoControlsEnabled = function(skipVideoControlsEnabled) {
   _skipVideoControlsEnabled = skipVideoControlsEnabled;
};

var getSkipVideoDuration = function() {
   return _skipVideoDuration === -1 ? DEFAULT_SKIP_DURATION_IN_SECONDS : _skipVideoDuration;
};

var setSkipVideoDuration = function(skipVideoDuration) {
   if (typeof skipVideoDuration !== 'number') {
      return;
   }
   _skipVideoDuration = skipVideoDuration;
};

var getAllowFastForward = function() {
   return _allowFastForward;
};

var setAllowFastForward = function(allowFastForward) {
   _allowFastForward = allowFastForward;
};

var getShowADACompliantCaptions = function() {
   return _showADACompliantCaptions;
};

var setShowADACompliantCaptions = function(showADACompliantCaptions) {
   _showADACompliantCaptions = showADACompliantCaptions;
};

var getRememberUserInfo = function() {
   return _rememberUserInfo;
};

var setRememberUserInfo = function(rememberUserInfo) {
   _rememberUserInfo = rememberUserInfo;
};

var getLogScormApiCalls = function() {
   return _logScormApiCalls;
};

var setLogScormApiCalls = function(logScormApiCalls) {
   _logScormApiCalls = logScormApiCalls;
};

var getUserInfo = function() {
   return _userInfo;
};

var setUserInfo = function(userInfo) {
   _userInfo = userInfo;
};

var setUserSignedIn = function(userSignedIn) {
   _isUserIdentified = userSignedIn;
};

var getVideoThumbnailSrc = function() {
   return _videoThumbnailsSrc;
};

var setVideoThumbnailSrc = function(videoThumbnailSrc) {
   _videoThumbnailsSrc = videoThumbnailSrc;
};

var getVideoThumbnailWidth = function() {
   return _videoThumbnailWidth;
};

var setVideoThumbnailWidth = function(videoThumbnailWidth) {
   _videoThumbnailWidth = videoThumbnailWidth;
};

var getVideoThumbnailHeight = function() {
   return _videoThumbnailHeight;
};

var setVideoThumbnailHeight = function(videoThumbnailHeight) {
   _videoThumbnailHeight = videoThumbnailHeight;
};

var getVideoThumbnailInterval = function() {
   return _videoThumbnailInterval;
};

var setVideoThumbnailInterval = function(videoThumbnailInterval) {
   _videoThumbnailInterval = videoThumbnailInterval;
};

var getVideoThumbnailColumns = function() {
   return _videoThumbnailColumns;
};

var setVideoThumbnailColumns = function(videoThumbnailColumns) {
   _videoThumbnailColumns = videoThumbnailColumns;
};

var getTocModelFromDescription = function() {
   return _tocModelFromDescription;
};

var setTocModelFromDescription = function(tocModelFromDescription) {
   _tocModelFromDescription = tocModelFromDescription;
};

var getCompleteScormOnRequirementsComplete = function() {
   return _completeScormOnRequirementsComplete;
};

var setCompleteScormOnRequirementsComplete = function(completeScormOnRequirementsComplete) {
   _completeScormOnRequirementsComplete = completeScormOnRequirementsComplete;
};

var getCustomFullScreenDomElement = function() {
   return _customFullScreenDomElement;
};

var setCustomFullScreenDomElement = function(customFullScreenDomElement) {
   _customFullScreenDomElement = customFullScreenDomElement;
};

var getQuizServiceBaseUrl = function() {
   return _quizServiceBaseUrl;
};

var setQuizServiceBaseUrl = function(quizServiceBaseUrl) {
   if (quizServiceBaseUrl && quizServiceBaseUrl.search(endsWithSlashRegex) === -1) {
      quizServiceBaseUrl += '/';
   }
   _quizServiceBaseUrl = quizServiceBaseUrl;
};

var getResultsServiceBaseUrl = function() {
   return _resultsServiceBaseUrl;
};

var setResultsServiceBaseUrl = function(resultsServiceBaseUrl) {
   if (resultsServiceBaseUrl && resultsServiceBaseUrl.search(endsWithSlashRegex) === -1) {
      resultsServiceBaseUrl += '/';
   }
   _resultsServiceBaseUrl = resultsServiceBaseUrl;
};

var getEnableTheaterMode = function() {
   return _enableTheaterMode;
};

var setEnableTheaterMode = function(enabled) {
   _enableTheaterMode = enabled;
};

var getShowAcknowledgementLink = function() {
   return _showAcknowledgementLink;
};

var setShowAcknowledgementLink = function(showAcknowledgementLink) {
   _showAcknowledgementLink = showAcknowledgementLink;
};

var deprecatedNoOp = function() {}; // eslint-disable-line

export default Object.defineProperties({
   clearConfiguration: clearConfiguration,
   runOnServer: runOnServer,
   addMediaSrc: addMediaSrc,
   getMediaSources: getMediaSources,
   addCaptionSrc: addCaptionSrc,
   getMediaFileName: getMediaFileName,
   getUnicodeSafeString: getUnicodeSafeString,
   getUnableToDisplayContentString: getUnableToDisplayContentString,
   useCustomEventTracking: useCustomEventTracking,
   getTrackEvents: getTrackEvents,
   getCustomEventTracking: getCustomEventTracking,
   getCustomEventCallback: getCustomEventCallback,
   getIsUserIdentified: getIsUserIdentified,
   setAdditionalAppQueryParamsFromJson: setAdditionalAppQueryParamsFromJson,
   getAdditionalAppQueryString: getAdditionalAppQueryString,
   saveConfigurationFromQueryString: saveConfigurationFromQueryString,
   configureFromXmp: configureFromXmp,
   getMediaSrc: getMediaSrc,
   setMediaSrc: setMediaSrc,
   getXMPSrc: getXMPSrc,
   setXMPSrc: setXMPSrc,
   getPosterImageSrc: getPosterImageSrc,
   setPosterImageSrc: setPosterImageSrc,
   getMediaTitle: getMediaTitle,
   setMediaTitle: setMediaTitle,
   getTOCSpriteSrc: getTOCSpriteSrc,
   setTOCSpriteSrc: setTOCSpriteSrc,
   getTOCTitle: getTOCTitle,
   setTOCTitle: setTOCTitle,
   getAudioDescriptionMediaSrc: getAudioDescriptionMediaSrc,
   setAudioDescriptionMediaSrc: setAudioDescriptionMediaSrc,
   getMediaRotation: getMediaRotation,
   setMediaRotation: setMediaRotation,
   getEndActionType: getEndActionType,
   setEndActionType: setEndActionType,
   getEndActionParam: getEndActionParam,
   setEndActionParam: setEndActionParam,
   getIgnoreXmpControllerParameters: getIgnoreXmpControllerParameters,
   setIgnoreXmpControllerParameters: setIgnoreXmpControllerParameters,
   getIgnoreQuizQuestions: getIgnoreQuizQuestions,
   setIgnoreQuizQuestions: setIgnoreQuizQuestions,
   getIsSearchable: getIsSearchable,
   setIsSearchable: setIsSearchable,
   getDisableInteractions: getDisableInteractions,
   setDisableInteractions: setDisableInteractions,
   getAutoPlayMedia: getAutoPlayMedia,
   setAutoPlayMedia: setAutoPlayMedia,
   getForceAutoPlayMedia: getForceAutoPlayMedia,
   setForceAutoPlayMedia: setForceAutoPlayMedia,
   getCaptionsEnabled: getCaptionsEnabled,
   setCaptionsEnabled: setCaptionsEnabled,
   getIgnoreStoredCaptionVisibleSetting: getIgnoreStoredCaptionVisibleSetting,
   setIgnoreStoredCaptionVisibleSetting: setIgnoreStoredCaptionVisibleSetting,
   getSidebarEnabled: getSidebarEnabled,
   setSidebarEnabled: setSidebarEnabled,
   getSidebarLocation: getSidebarLocation,
   setSidebarLocation: setSidebarLocation,
   getBackgroundColor: getBackgroundColor,
   setBackgroundColor: setBackgroundColor,
   getAutoHideControls: getAutoHideControls,
   setAutoHideControls: setAutoHideControls,
   getProcessUnicodeNames: getProcessUnicodeNames,
   setProcessUnicodeNames: setProcessUnicodeNames,
   getReportScormComplete: getReportScormComplete,
   setReportScormComplete: setReportScormComplete,
   getEnforceLinearAssessment: getEnforceLinearAssessment,
   setEnforceLinearAssessment: setEnforceLinearAssessment,
   getDisableFullframeMode: getDisableFullframeMode,
   setDisableFullframeMode: setDisableFullframeMode,
   getDisableControls: getDisableControls,
   setDisableControls: setDisableControls,
   getDisableYouTubeControls: getDisableYouTubeControls,
   setDisableYouTubeControls: setDisableYouTubeControls,
   getDisablePictureInPicture: getDisablePictureInPicture,
   setDisablePictureInPicture: setDisablePictureInPicture,
   getDisableContinueWithoutQuestions: getDisableContinueWithoutQuestions,
   setDisableContinueWithoutQuestions: setDisableContinueWithoutQuestions,
   getMobileWebViewCanAutoPlay: getMobileWebViewCanAutoPlay,
   setMobileWebViewCanAutoPlay: setMobileWebViewCanAutoPlay,
   getGoogleAnalyticsID: getGoogleAnalyticsID,
   setGoogleAnalyticsID: setGoogleAnalyticsID,
   getGAPageViewTracking: getGAPageViewTracking,
   setGAPageViewTracking: setGAPageViewTracking,
   getGAEventTracking: getGAEventTracking,
   setGAEventTracking: setGAEventTracking,
   getAltEventCategoryAsFilename: getAltEventCategoryAsFilename,
   setAltEventCategoryAsFilename: setAltEventCategoryAsFilename,
   getAltLoadTimeAsSeconds: getAltLoadTimeAsSeconds,
   setAltLoadTimeAsSeconds: setAltLoadTimeAsSeconds,
   getTechSmithAccessTokenEndpoint: getTechSmithAccessTokenEndpoint,
   setTechSmithAccessTokenEndpoint: setTechSmithAccessTokenEndpoint,
   getJumpToTime: getJumpToTime,
   setJumpToTime: setJumpToTime,
   getFathomId: getFathomId,
   setFathomId: setFathomId,
   getEmbedIFrameId: getEmbedIFrameId,
   setEmbedIFrameId: setEmbedIFrameId,
   getUseSlimAppSplashScreen: getUseSlimAppSplashScreen,
   setUseSlimAppSplashScreen: setUseSlimAppSplashScreen,
   getDisableContextMenuForVideo: getDisableContextMenuForVideo,
   setDisableContextMenuForVideo: setDisableContextMenuForVideo,
   getShowYouTubeAnnotations: getShowYouTubeAnnotations,
   setShowYouTubeAnnotations: setShowYouTubeAnnotations,
   getPreRollSrc: getPreRollSrc,
   setPreRollSrc: setPreRollSrc,
   getContentBase: getContentBase,
   setContentBase: setContentBase,
   getContentType: getContentType,
   setContentType: setContentType,
   getPreload: getPreload,
   setPreload: setPreload,
   getFullViewPort: getFullViewPort,
   setFullViewPort: setFullViewPort,
   getWebConnector: getWebConnector,
   setWebConnector: setWebConnector,
   getTheme: getTheme,
   setTheme: setTheme,
   getEnableSingleClickControl: getEnableSingleClickControl,
   setEnableSingleClickControl: setEnableSingleClickControl,
   getEnableDoubleClickControl: getEnableDoubleClickControl,
   setEnableDoubleClickControl: setEnableDoubleClickControl,
   getPluginController: getPluginController,
   setPluginController: setPluginController,
   getUseLargerProgressBar: getUseLargerProgressBar,
   setUseLargerProgressBar: setUseLargerProgressBar,
   getDisplayHotspotsShape: getDisplayHotspotsShape,
   setDisplayHotspotsShape: setDisplayHotspotsShape,
   getSkipVideoControlsEnabled: getSkipVideoControlsEnabled,
   setSkipVideoControlsEnabled: setSkipVideoControlsEnabled,
   getSkipVideoDuration: getSkipVideoDuration,
   setSkipVideoDuration: setSkipVideoDuration,
   getAllowFastForward: getAllowFastForward,
   setAllowFastForward: setAllowFastForward,
   getShowADACompliantCaptions: getShowADACompliantCaptions,
   setShowADACompliantCaptions: setShowADACompliantCaptions,
   getRememberUserInfo: getRememberUserInfo,
   setRememberUserInfo: setRememberUserInfo,
   getLogScormApiCalls: getLogScormApiCalls,
   setLogScormApiCalls: setLogScormApiCalls,
   getUserInfo: getUserInfo,
   setUserInfo: setUserInfo,
   getUserSignedIn: getIsUserIdentified,
   setUserSignedIn: setUserSignedIn,
   getVideoThumbnailSrc: getVideoThumbnailSrc,
   setVideoThumbnailSrc: setVideoThumbnailSrc,
   getVideoThumbnailWidth: getVideoThumbnailWidth,
   setVideoThumbnailWidth: setVideoThumbnailWidth,
   getVideoThumbnailHeight: getVideoThumbnailHeight,
   setVideoThumbnailHeight: setVideoThumbnailHeight,
   getVideoThumbnailInterval: getVideoThumbnailInterval,
   setVideoThumbnailInterval: setVideoThumbnailInterval,
   getVideoThumbnailColumns: getVideoThumbnailColumns,
   setVideoThumbnailColumns: setVideoThumbnailColumns,
   getTocModelFromDescription: getTocModelFromDescription,
   setTocModelFromDescription: setTocModelFromDescription,
   getCompleteScormOnRequirementsComplete: getCompleteScormOnRequirementsComplete,
   setCompleteScormOnRequirementsComplete: setCompleteScormOnRequirementsComplete,
   getCustomFullScreenDomElement: getCustomFullScreenDomElement,
   setCustomFullScreenDomElement: setCustomFullScreenDomElement,
   getQuizServiceBaseUrl: getQuizServiceBaseUrl,
   setQuizServiceBaseUrl: setQuizServiceBaseUrl,
   getResultsServiceBaseUrl: getResultsServiceBaseUrl,
   setResultsServiceBaseUrl: setResultsServiceBaseUrl,
   getEnableTheaterMode: getEnableTheaterMode,
   setEnableTheaterMode: setEnableTheaterMode,
   getGA4EventCallback: getGA4EventCallback,
   setGA4EventCallback: setGA4EventCallback,
   getShowAcknowledgementLink: getShowAcknowledgementLink,
   setShowAcknowledgementLink: setShowAcknowledgementLink,
   // deprecated methods
   setSwfBase: setContentBase,
   isIEOnServerWithUnicode: deprecatedNoOp,
   setFlashUpdateBootstrapSwf: deprecatedNoOp,
   getAdvancedSeeking: deprecatedNoOp,
   setAdvancedSeeking: deprecatedNoOp,
   getDebugUIMode: deprecatedNoOp,
   setDebugUIMode: deprecatedNoOp,
   getPlayerWidth: deprecatedNoOp,
   setPlayerWidth: deprecatedNoOp,
   getPlayerHeight: deprecatedNoOp,
   setPlayerHeight: deprecatedNoOp,
   setFlashPlayerSwf: deprecatedNoOp,
   setForceFlashPlayer: deprecatedNoOp,
   setForceHtml5Player: deprecatedNoOp,
   setConfigurationSrc: deprecatedNoOp,
   setPrioritizeFullScreen: deprecatedNoOp,
   getDisableYouTubeFullscreen: deprecatedNoOp,
   setDisableYouTubeFullscreen: deprecatedNoOp,
   setAllowRewind: deprecatedNoOp
},
{
   mediaSrc: {
      get: getMediaSrc,
      set: setMediaSrc
   },
   xmpSrc: {
      get: getXMPSrc,
      set: setXMPSrc
   },
   posterImageSrc: {
      get: getPosterImageSrc,
      set: setPosterImageSrc
   },
   mediaTitle: {
      get: getMediaTitle,
      set: setMediaTitle
   },
   tocSpriteSrc: {
      get: getTOCSpriteSrc,
      set: setTOCSpriteSrc
   },
   tocTitle: {
      get: getTOCTitle,
      set: setTOCTitle
   },
   mediaRotation: {
      get: getMediaRotation,
      set: setMediaRotation
   },
   endActionType: {
      get: getEndActionType,
      set: setEndActionType
   },
   endActionParam: {
      get: getEndActionParam,
      set: setEndActionParam
   },
   ignoreXmpControllerParameters: {
      get: getIgnoreXmpControllerParameters,
      set: setIgnoreXmpControllerParameters
   },
   ignoreQuizQuestions: {
      get: getIgnoreQuizQuestions,
      set: setIgnoreQuizQuestions
   },
   disableInteractions: {
      get: getDisableInteractions,
      set: setDisableInteractions
   },
   isSearchable: {
      get: getIsSearchable,
      set: setIsSearchable
   },
   autoPlayMedia: {
      get: getAutoPlayMedia,
      set: setAutoPlayMedia
   },
   forceAutoPlayMedia: {
      get: getForceAutoPlayMedia,
      set: setForceAutoPlayMedia
   },
   captionsEnabled: {
      get: getCaptionsEnabled,
      set: setCaptionsEnabled
   },
   ignoreStoredCaptionVisibleSetting: {
      get: getIgnoreStoredCaptionVisibleSetting,
      set: setIgnoreStoredCaptionVisibleSetting
   },
   sidebarEnabled: {
      get: getSidebarEnabled,
      set: setSidebarEnabled
   },
   sidebarLocation: {
      get: getSidebarLocation,
      set: setSidebarLocation
   },
   backgroundColor: {
      get: getBackgroundColor,
      set: setBackgroundColor
   },
   autoHideControls: {
      get: getAutoHideControls,
      set: setAutoHideControls
   },
   processUnicodeNames: {
      get: getProcessUnicodeNames,
      set: setProcessUnicodeNames
   },
   enforceLinearAssessment: {
      get: getEnforceLinearAssessment,
      set: setEnforceLinearAssessment
   },
   disableFullframeMode: {
      get: getDisableFullframeMode,
      set: setDisableFullframeMode
   },
   disableControls: {
      get: getDisableControls,
      set: setDisableControls
   },
   disableYouTubeControls: {
      get: getDisableYouTubeControls,
      set: setDisableYouTubeControls
   },
   disablePictureInPicture: {
      get: getDisablePictureInPicture,
      set: setDisablePictureInPicture
   },
   disableContinueWithoutQuestions: {
      get: getDisableContinueWithoutQuestions,
      set: setDisableContinueWithoutQuestions
   },
   mobileWebViewCanAutoPlay: {
      get: getMobileWebViewCanAutoPlay,
      set: setMobileWebViewCanAutoPlay
   },
   googleAnalyticsID: {
      get: getGoogleAnalyticsID,
      set: setGoogleAnalyticsID
   },
   googleAnalyticsPageViewTracking: {
      get: getGAPageViewTracking,
      set: setGAPageViewTracking
   },
   googleAnalyticsEventTracking: {
      get: getGAEventTracking,
      set: setGAEventTracking
   },
   altEventCategoryAsFilename: {
      get: getAltEventCategoryAsFilename,
      set: setAltEventCategoryAsFilename
   },
   altLoadTimeAsSeconds: {
      get: getAltLoadTimeAsSeconds,
      set: setAltLoadTimeAsSeconds
   },
   techSmithAccessTokenEndpoint: {
      get: getTechSmithAccessTokenEndpoint,
      set: setTechSmithAccessTokenEndpoint
   },
   jumpToTime: {
      get: getJumpToTime,
      set: setJumpToTime
   },
   fathomId: {
      get: getFathomId,
      set: setFathomId
   },
   embedIFrameId: {
      get: getEmbedIFrameId,
      set: setEmbedIFrameId
   },
   useSlimAppSplashScreen: {
      get: getUseSlimAppSplashScreen,
      set: setUseSlimAppSplashScreen
   },
   disableContextMenuForVideo: {
      get: setDisableContextMenuForVideo,
      set: setDisableContextMenuForVideo
   },
   showYouTubeAnnotations: {
      get: getShowYouTubeAnnotations,
      set: setShowYouTubeAnnotations
   },
   preRollSrc: {
      get: getPreRollSrc,
      set: setPreRollSrc
   },
   contentBase: {
      get: getContentBase,
      set: setContentBase
   },
   contentType: {
      get: getContentType,
      set: setContentType
   },
   preload: {
      get: getPreload,
      set: setPreload
   },
   fullViewPort: {
      get: getFullViewPort,
      set: setFullViewPort
   },
   webConnector: {
      get: getWebConnector,
      set: setWebConnector
   },
   theme: {
      get: getTheme,
      set: setTheme
   },
   enableSingleClickControl: {
      get: getEnableSingleClickControl,
      set: setEnableSingleClickControl
   },
   enableDoubleClickControl: {
      get: getEnableDoubleClickControl,
      set: setEnableDoubleClickControl
   },
   pluginController: {
      get: getPluginController,
      set: setPluginController
   },
   useLargerProgressBar: {
      get: getUseLargerProgressBar,
      set: setUseLargerProgressBar
   },
   isScormLesson: {
      get: getIsScormLesson,
      set: setIsScormLesson
   },
   displayHotspotsShape: {
      get: getDisplayHotspotsShape,
      set: setDisplayHotspotsShape
   },
   audioDescriptionMediaSrc: {
      get: getAudioDescriptionMediaSrc,
      set: setAudioDescriptionMediaSrc
   },
   captionSrcList: {
      get: getCaptionSrcList
   },
   skipVideoControlsEnabled: {
      get: getSkipVideoControlsEnabled,
      set: setSkipVideoControlsEnabled
   },
   skipVideoDuration: {
      get: getSkipVideoDuration,
      set: setSkipVideoDuration
   },
   allowFastForward: {
      get: getAllowFastForward,
      set: setAllowFastForward
   },
   showADACompliantCaptions: {
      get: getShowADACompliantCaptions,
      set: setShowADACompliantCaptions
   },
   rememberUserInfo: {
      get: getRememberUserInfo,
      set: setRememberUserInfo
   },
   logScormApiCalls: {
      get: getLogScormApiCalls,
      set: setLogScormApiCalls
   },
   userInfo: {
      get: getUserInfo,
      set: setUserInfo
   },
   userSignedIn: {
      get: getIsUserIdentified,
      set: setUserSignedIn
   },
   videoThumbnailsSrc: {
      get: getVideoThumbnailSrc,
      set: setVideoThumbnailSrc
   },
   videoThumbnailWidth: {
      get: getVideoThumbnailWidth,
      set: setVideoThumbnailWidth
   },
   videoThumbnailHeight: {
      get: getVideoThumbnailHeight,
      set: setVideoThumbnailHeight
   },
   videoThumbnailInterval: {
      get: getVideoThumbnailInterval,
      set: setVideoThumbnailInterval
   },
   videoThumbnailColumns: {
      get: getVideoThumbnailColumns,
      set: setVideoThumbnailColumns
   },
   tocModelFromDescription: {
      get: getTocModelFromDescription,
      set: setTocModelFromDescription
   },
   completeScormOnRequirementsComplete: {
      get: getCompleteScormOnRequirementsComplete,
      set: setCompleteScormOnRequirementsComplete
   },
   customFullScreenDomElement: {
      get: getCustomFullScreenDomElement,
      set: setCustomFullScreenDomElement
   },
   enableTheaterMode: {
      get: getEnableTheaterMode,
      set: setEnableTheaterMode
   },
   ga4EventCallback: {
      get: getGA4EventCallback,
      set: setGA4EventCallback
   },
   showAcknowledgementLink: {
      get: getShowAcknowledgementLink,
      set: setShowAcknowledgementLink
   }
});
