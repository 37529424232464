import playerStringNames from '../constants/player-string-names';
import localizationStrings from '../models/localization-strings';
import templateGenerator from '../utils/template-generator';
import events from '../constants/events';
import tabIndex from '../constants/tab-index';
import playerConfiguration from '../models/player-configuration';

var ScormNavigationBarView = function($container) {
   var _$scormNavigationBarContainer = $container.find('.scorm-module-navigation-container');
   var _$scormHeaderElement = null;
   var _$scormCompleteSessionButton = null;
   var _scoObjectiveHasBeenCompleted = false;

   var onCompleteSessionHandler = function(e) {
      e.preventDefault();
      $container.trigger(events.SCORM.ExitSession);
   };

   var getButtonLabel = function() {
      if (_scoObjectiveHasBeenCompleted) {
         return playerConfiguration.completeScormOnRequirementsComplete ? localizationStrings.getPlayerString(playerStringNames.scormExitVideo) : localizationStrings.getPlayerString(playerStringNames.scormCompleteVideo);
      }
      return localizationStrings.getPlayerString(playerStringNames.scormCompleteVideoLater);
   };

   var createView = function() {
      var buttonLabel = getButtonLabel();
      var isPrimaryButton = _scoObjectiveHasBeenCompleted;
      var scormNavigationBarMarkup = templateGenerator.generateScormNavigationBarMarkup(buttonLabel, isPrimaryButton);
      _$scormNavigationBarContainer.html(scormNavigationBarMarkup);

      _$scormHeaderElement = _$scormNavigationBarContainer.find('.scorm-module-title');
      _$scormCompleteSessionButton = _$scormNavigationBarContainer.find('.complete-scorm-session-button');
   };

   var disableControls = function() {
      _$scormCompleteSessionButton && _$scormCompleteSessionButton.attr('tabindex', tabIndex.Disabled);
   };

   var enableControls = function() {
      _$scormCompleteSessionButton && _$scormCompleteSessionButton.attr('tabindex', tabIndex.DomOrder);
   };

   var setupEvents = function() {
      $container.on(events.Modals.Open, disableControls);
      $container.on(events.Modals.Closed, enableControls);
      _$scormCompleteSessionButton.on('click', onCompleteSessionHandler);
   };

   var setTitle = function(scormTitle) {
      if (_$scormHeaderElement) {
         _$scormHeaderElement.html(scormTitle);
      }
   };

   var destroy = function() {
      if (_$scormCompleteSessionButton) {
         _$scormCompleteSessionButton.off();
      }
      $container.off(events.Modals.Open, disableControls);
      $container.off(events.Modals.Closed, enableControls);
   };

   var init = function() {
      createView();
      setupEvents();
   };

   var updateView = function(scoObjectiveHasBeenCompleted) {
      _scoObjectiveHasBeenCompleted = scoObjectiveHasBeenCompleted;
      destroy();
      init();
   };

   return Object.defineProperties({
      setTitle: setTitle,
      updateView: updateView
   }, {});
};

export default {
   create: ScormNavigationBarView
};
