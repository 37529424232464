var CHANGED_PIP_MODE_SAFARI_EVENT = 'webkitpresentationmodechanged';
var SAFARI_INLINE_MODE = 'inline';
var SAFARI_PIP_MODE = 'picture-in-picture';

/**
 * @memberof TSC
 * @class LegacySafariPipController
 * @classdesc Controller to interface with Safari PIP api.  This API is supported before Safari 14.
 *  @param {Object} mediaElement - html5 media DOM element (or wrapper presenting same interface)
 */
var LegacySafariPipController = function(mediaElement) {
   var enter = function() {
      mediaElement.webkitSetPresentationMode(SAFARI_PIP_MODE);
   };

   var exit = function() {
      mediaElement.webkitSetPresentationMode(SAFARI_INLINE_MODE);
   };

   var bindChangeEvent = function(onChangeHandler) {
      mediaElement.addEventListener(CHANGED_PIP_MODE_SAFARI_EVENT, onChangeHandler);
   };

   var unbindChangeEvent = function(onChangeHandler) {
      mediaElement.removeEventListener(CHANGED_PIP_MODE_SAFARI_EVENT, onChangeHandler);
   };

   return Object.defineProperties({
      enter: enter,
      exit: exit,
      bindChangeEvent: bindChangeEvent,
      unbindChangeEvent: unbindChangeEvent
   }, {
      inPip: {
         get: function() {
            return mediaElement.webkitPresentationMode === SAFARI_PIP_MODE;
         }
      },
      isSupported: {
         get: function() {
            return true;
         }
      }
   });
};

var isControllerSupported = function(mediaElement) {
   return mediaElement && typeof mediaElement.webkitSetPresentationMode === 'function' && typeof mediaElement.webkitSupportsPresentationMode === 'function' && mediaElement.webkitSupportsPresentationMode(SAFARI_PIP_MODE);
};

export default {
   /**
    *  Factory method that returns a new LegacySafariPipController object
    *  @function create
    *  @memberof TSC.LegacySafariPipController
    *  @param {Object} mediaElement - html5 media DOM element (or wrapper presenting same interface)
    *  @static
    *  @return new LegacySafariPipController instance
    */
   create: LegacySafariPipController,
   /**
    *  Check to see if LegacySafariPipController is supported for platform being run on
    *  @function isControllerSupported
    *  @memberof TSC.LegacySafariPipController
    *  @param {Object} mediaElement - html5 media DOM element (or wrapper presenting same interface)
    *  @static
    *  @return {Boolean} is controller supported on platform
    */
   isControllerSupported: isControllerSupported
};
