var CAPTION_POSITION = {
   under: 'under'
};

var CAPTION_ALIGNMENT = {
   top: 'top',
   left: 'left',
   right: 'right'
};

export {
   CAPTION_POSITION,
   CAPTION_ALIGNMENT
};
