import Promise from 'promise-polyfill';
import MediaViewFactory from './media-view-factory';
import VideoControlsController from '../controllers/video-controls-controller';
import VideoControlsDisplayModeController from '../controllers/video-controls-display-mode-controller';
import playerControlsType from '../constants/player-controls-type';
import MediaExtensionsController from '../controllers/media-extensions-controller';
import TabController from '../controllers/tab-controller';
import ExternalEventsController from '../controllers/external-events-controller';
import PlayerState from '../models/player-state';
import log from '../../common/log';
import events from '../constants/events';
import performanceWrapper from '../utils/performance-wrapper';
import IoOverlayView from './io-overlay-view';
import mediaViewType from '../constants/media-view-type';
import videoApiUtils from '../utils/video-api-utils';
import cssClasses from '../constants/css-classes';
import localizationStrings from '../models/localization-strings';
import playerStringNames from '../constants/player-string-names';
import {AnalyticsRumVideoCategorySuffix} from '../constants/analytics-constants';
import MessageBarView from './message-bar-view';
import messageBarType from '../constants/message-bar-type';
import templateGenerator from '../utils/template-generator';
import {CAPTION_POSITION} from '../constants/captions';
import cssVariables from '../constants/css-variables';
import supportedPluginViewElements from '../constants/supported-plugin-view-elements';
import deviceInfo from '../utils/device-info';

var VIDEO_TIME_COMPARE_PRECISION = 2;

/**
 * @memberof TSC
 * @class SmartPlayerView
 * @classdesc Smart Player view
 * @param {String} playerInstanceUUID
 * @param {JQuery} $rootContainer
 * @param {TSC.MediaList} mediaList
 * @param {TSC.XmpModel} xmpModel
 * @param {TSC.Quiz} quizModel
 * @param {TSC.CaptionService} captionService
 * @param {TSC.playerConfiguration} playerConfiguration
 * @param {TSC.ResponsiveController} responsiveController
 * @param {Boolean} xmpInErrorState
 */
var SmartPlayerView = function(playerInstanceUUID, $rootContainer, mediaList, xmpModel, quizModel, captionService, playerConfiguration, responsiveController, xmpInErrorState) { // eslint-disable-line
   var _mediaElementUniqueID = [$rootContainer.attr('id').replace(/^[#]/, ''), playerInstanceUUID].join('-');
   var _mediaViewReady;
   var _viewHasBeenInitialized;
   var _mediaExtensionsController;
   var _tabController;
   var _externalEventsController;
   var _scormErrorMessageBarView;
   var _$playerPluginContainer;
   var _viewHasFailedToInitialized; // eslint-disable-line
   var _viewInitializedPromise = new Promise(function(resolve, reject) {
      _viewHasBeenInitialized = resolve; // eslint-disable-line
      _viewHasFailedToInitialized = reject; // eslint-disable-line
   });
   var _playerViewEventCallbacks = {};
   var _queuedMediaBinds = {};
   var _mediaView = null;
   var _ioOverlayView = null;
   var _videoControlsController = null;
   var _videoControlsDisplayModeController = null;
   var _controlsReady = false;
   var _mediaExtensionsReady = false;
   var _mediaHasBeenLoaded = false;

   var _playerState = PlayerState.create();
   _playerState.mediaHasQuizQuestions = quizModel && quizModel.questionSets.length > 0;

   $rootContainer.playerState = _playerState;

   var _$mediaViewPortContainer = null;
   var _$mediaContainer = null;

   var _$playerLayoutControlsContainer = null;

   var displayXmpErrorMessage = function() {
      if (deviceInfo.isLocal()) {
         MessageBarView.create($rootContainer, messageBarType.info, localizationStrings.getPlayerString(playerStringNames.xmpSecurity), localizationStrings.getPlayerString(playerStringNames.close));
      } else {
         MessageBarView.create($rootContainer, messageBarType.info, localizationStrings.getPlayerString(playerStringNames.xmpError));
      }
   };

   var onFirstPlayHandler = function() {
      $rootContainer.trigger(events.Media.FirstPlay);
   };

   var triggerRumDataEvent = function(timingMeasureList, eventCategorySuffix) {
      var eventData = {
         categorySuffix: eventCategorySuffix,
         rum: performanceWrapper.getRumEventData(timingMeasureList)
      };

      _playerViewEventCallbacks[events.External.PlayerRumData].forEach(function(callback) {
         callback(eventData);
      });
   };

   var externalEventIsSupported = function(eventName) {
      return Object.keys(events.External).some(function(eventKey) {
         return events.External[eventKey] === eventName;
      });
   };

   var setupExternalEventCallbackHarness = function() {
      Object.keys(events.External).forEach(function(eventKey) {
         var eventName = events.External[eventKey];
         _playerViewEventCallbacks[eventName] = [];
      });
   };

   var getCaptionsVisible = function() {
      return _mediaExtensionsController.captionController ? _mediaExtensionsController.captionController.getCaptionsVisible() : false;
   };

   var setCaptionsVisible = function(value) {
      if (_mediaExtensionsController.captionController) {
         _mediaExtensionsController.captionController.setCaptionsVisible(value);
      }
   };

   var addEventListener = function(type, listener) {
      if (externalEventIsSupported(type)) {
         _playerViewEventCallbacks[type].push(listener);
      }
   };

   var removeEventListener = function(type, listener) {
      if (externalEventIsSupported(type)) {
         // just in case the same listener pointer is bound more than once?
         while (_playerViewEventCallbacks[type].indexOf(listener) !== -1) {
            _playerViewEventCallbacks[type].splice(_playerViewEventCallbacks[type].indexOf(listener), 1);
         }
      }
   };

   var addMediaEventListener = function(event, handler) {
      if (_mediaView && _mediaView.mediaWrapper) {
         _mediaView.mediaWrapper.on(event, handler);
      } else {
         if (typeof _queuedMediaBinds[event] !== 'object') {
            _queuedMediaBinds[event] = [];
         }
         _queuedMediaBinds[event].push(handler);
      }
   };

   var removeMediaEventListener = function(event, handler) {
      if (_mediaView.mediaWrapper) {
         _mediaView.mediaWrapper.off(event, handler);
      } else {
         if (typeof _queuedMediaBinds[event] !== 'object') {
            return;
         }
         // if the same handler is bound multiple times?
         while (_queuedMediaBinds[event].indexOf(handler) !== -1) {
            _queuedMediaBinds[event].splice(_queuedMediaBinds[event].indexOf(handler), 1);
         }
      }
   };

   var processQueuedMediaBinds = function() {
      Object.keys(_queuedMediaBinds).forEach(function(event) {
         if (typeof _queuedMediaBinds[event] === 'object') {
            _queuedMediaBinds[event].forEach(function(callback) {
               _mediaView.mediaWrapper.on(event, callback);
            });
         }
      });
      _queuedMediaBinds = {};
   };

   var triggerVideoStartEventCallbacks = function() {
      // dispatch callback for media started event (note: after _mediaWrapper and _mediaElement are set)
      _playerViewEventCallbacks[events.External.VideoStart].forEach(function(callback) {
         callback();
      });
   };

   var createPlayerLayout = function() {
      $rootContainer.html(templateGenerator.generatePlayerLayoutMarkup());

      _$mediaViewPortContainer = $rootContainer.find('.media-wrapper').first();
      _$playerLayoutControlsContainer = $rootContainer.find('.video-controls-container').first();
      _$playerPluginContainer = $rootContainer.find('.player-plugin-container').first();
   };

   var createMediaView = function() {
      return MediaViewFactory.createView($rootContainer, _$mediaViewPortContainer, _mediaElementUniqueID, mediaList, onFirstPlayHandler, triggerRumDataEvent);
   };

   var onMediaError = function(e, data) {
      _ioOverlayView.hideView();
      _ioOverlayView.displayMessage(data.errorString, true);
   };

   var showVideoLoadingMessage = function() {
      if (_mediaView.type !== mediaViewType.youtube && _mediaView.type !== mediaViewType.image) {
         _ioOverlayView.displayMessage(localizationStrings.getPlayerString(playerStringNames.videoLoading));
      }
   };

   var onHideLoadingMessage = function() {
      _ioOverlayView.hideView();
   };

   var loadMediaWhenComponentsAreReady = function() {
      if (_controlsReady && _mediaExtensionsReady && !_mediaHasBeenLoaded) {
         showVideoLoadingMessage();
         loadMedia();
      }
   };

   var onControlsVisible = function() {
      if (_$playerLayoutControlsContainer.length > 0 && captionService.position === CAPTION_POSITION.under) {
         $rootContainer[0].style.setProperty(cssVariables.reservedSizeControls, `${Math.round(_$playerLayoutControlsContainer.height())}px`);
      }
   };

   var onControlsReady = function() {
      _controlsReady = true;
      loadMediaWhenComponentsAreReady();
   };

   var onMediaExtensionsReady = function() {
      _mediaExtensionsReady = true;
      loadMediaWhenComponentsAreReady();
   };

   var onFailedToFindSCORMApi = function() {
      _ioOverlayView.hideView();
      _scormErrorMessageBarView = MessageBarView.create($rootContainer, messageBarType.info, localizationStrings.getPlayerString(playerStringNames.scormApiNotFoundMessage), localizationStrings.getPlayerString(playerStringNames.close));
   };

   var onShowQuizSetup = function() {
      $rootContainer.find('.video-wrapper').removeClass(cssClasses.hide);
   };

   var startViewPortObserver = function() {
      _mediaView.mediaWrapper.off(events.ExternalVideo.VideoLoadedMetadata, startViewPortObserver);
      // at this point, these should have been initialized
      _$mediaContainer = $rootContainer.find('.media-container');
      responsiveController.monitorMediaViewPortSize(_$mediaViewPortContainer, _$mediaContainer, _mediaView.mediaWidth, _mediaView.mediaHeight);
   };

   var onImagePointerOver = function() {
      $rootContainer.trigger(events.Images.PointerOver);
   };

   var onImagePointerLeave = function() {
      $rootContainer.trigger(events.Images.PointerLeave);
   };

   var onMediaViewInitialized = function(mediaView) {
      _mediaView = mediaView;

      _ioOverlayView = IoOverlayView.create($rootContainer);
      $rootContainer.on(events.Media.Error, onMediaError);
      $rootContainer.on(events.Player.MediaControlsReady, onControlsReady);
      $rootContainer.on(events.Controls.VideoControlsVisible, onControlsVisible);
      $rootContainer.on(events.Player.MediaExtensionsReady, onMediaExtensionsReady);
      $rootContainer.on(events.Controls.HideLoadingMessage, onHideLoadingMessage);
      $rootContainer.on(events.SCORM.FailedToFindApi, onFailedToFindSCORMApi);
      $rootContainer.on(events.Quizzing.ShowQuizSetup, onShowQuizSetup);
      $rootContainer.on(events.Plugin.Added, initPlugins);
      _mediaView.mediaWrapper.on(events.ExternalVideo.VideoLoadedMetadata, startViewPortObserver);

      if (_mediaView.playerControlType === playerControlsType.video || _mediaView.playerControlType === playerControlsType.videoPlaylist) {
         _videoControlsController = VideoControlsController.create($rootContainer, _mediaView, xmpModel, quizModel, playerConfiguration);
         _videoControlsDisplayModeController = VideoControlsDisplayModeController.create($rootContainer, _videoControlsController, playerConfiguration);
         _tabController = TabController.create($rootContainer, _videoControlsController.progressBarControlView.controlElement, _videoControlsController.fullscreenControlView.controlElement);
      } else {
         // if not creating videoControls just yank the dom structure, perhaps this should be its own template
         $rootContainer.find('.video-controls-container').remove();
         if (_mediaView.type === mediaViewType.image && !deviceInfo.isTouchInterface()) {
            $rootContainer.on('pointerover', onImagePointerOver);
            $rootContainer.on('pointerleave', onImagePointerLeave);
         }
      }
      var progressBarControlView = _videoControlsController ? _videoControlsController.progressBarControlView : null;
      var beforeAfterPlayControlsView = _videoControlsController ? _videoControlsController.beforeAfterPlayControlsView : null;
      _externalEventsController = ExternalEventsController.create($rootContainer, mediaView, mediaList, _playerViewEventCallbacks);
      processQueuedMediaBinds();
      _mediaExtensionsController = MediaExtensionsController.create(
         $rootContainer,
         mediaView,
         xmpModel,
         quizModel,
         mediaList,
         captionService,
         playerConfiguration,
         responsiveController,
         _tabController,
         progressBarControlView,
         beforeAfterPlayControlsView,
         _ioOverlayView
      );

      if (!_videoControlsController || !_videoControlsController.userNeedToClickHeroPlayControl) {
         $rootContainer.trigger(events.Player.MediaControlsReady);
      }

      $rootContainer.trigger(events.Controls.PlayerInitialized);

      triggerRumDataEvent([events.Timing.PlayerLoaded, events.Timing.PlayerInitialized], AnalyticsRumVideoCategorySuffix);

      _viewHasBeenInitialized();
   };

   var destroy = function() {
      $rootContainer.off(events.Media.Error, onMediaError);
      $rootContainer.off(events.Player.MediaControlsReady, onControlsReady);
      $rootContainer.off(events.Controls.VideoControlsVisible, onControlsVisible);
      $rootContainer.off(events.Player.MediaExtensionsReady, onMediaExtensionsReady);
      $rootContainer.off(events.Controls.HideLoadingMessage, onHideLoadingMessage);
      $rootContainer.off(events.SCORM.FailedToFindApi, onFailedToFindSCORMApi);
      $rootContainer.off(events.Quizzing.ShowQuizSetup, onShowQuizSetup);
      $rootContainer.off(events.Plugin.Added, initPlugins);
      $rootContainer.off('pointerover', onImagePointerOver);
      $rootContainer.off('pointerleave', onImagePointerLeave);
      _mediaView.mediaWrapper.off(events.ExternalVideo.VideoLoadedMetadata, startViewPortObserver);
      _videoControlsController && _videoControlsController.destroy();
      _videoControlsDisplayModeController && _videoControlsDisplayModeController.destroy();
      _mediaView && _mediaView.destroy();
      _ioOverlayView && _ioOverlayView.destroy();
      _mediaExtensionsController && _mediaExtensionsController.destroy();
      _tabController && _tabController.destroy();
      _externalEventsController && _externalEventsController.destroy();
      _scormErrorMessageBarView && _scormErrorMessageBarView.remove();
   };

   var playVideo = function() {
      if (_mediaView && _mediaView.mediaElement && _mediaView.type !== mediaViewType.image) {
         if (_mediaView.mediaElement.ended) {
            $rootContainer.trigger(events.Media.Replay);
         } else {
            _mediaView.mediaElement.play();
         }
      }
      return Promise.reject('Media Element not yet initialized');
   };

   var pauseVideo = function() {
      if (_mediaView && _mediaView.mediaElement && _mediaView.type !== mediaViewType.image) {
         _mediaView.mediaElement.pause();
      }
   };

   var isInFullScreenMode = function() {
      if (_videoControlsController && _videoControlsController.fullscreenControlView) {
         return _videoControlsController.fullscreenControlView.isFullScreen;
      }
      return false;
   };

   var getIsScrubbing = function() {
      if (_videoControlsController && _videoControlsController.progressBarControlView) {
         return _videoControlsController.progressBarControlView.scrubbing;
      }
      return false;
   };

   var setIsScrubbing = function(isScrubbingVideo) {
      if (_videoControlsController && _videoControlsController.progressBarControlView) {
         _videoControlsController.progressBarControlView.scrubbing = isScrubbingVideo;
      }
   };

   var getSafeSeekTime = function(time) {
      if (!_mediaView && _mediaView.type === mediaViewType.image && !_mediaView.mediaElement) {
         return 0;
      }

      return videoApiUtils.getSafeSeekTime(_mediaView.mediaElement, time);
   };

   var loadMedia = function() {
      if (_mediaHasBeenLoaded) {
         return;
      }

      _mediaHasBeenLoaded = true;
      $rootContainer.find('.video-wrapper').removeClass(cssClasses.hide);

      _mediaView.loadMedia();

      if (playerConfiguration.getAutoPlayMedia() || playerConfiguration.getForceAutoPlayMedia()) {
         $rootContainer.trigger(events.Media.TryToAutoPlay);
      }
   };

   var setExternalCurrentTime = function(timeInSeconds) {
      var safeSeekTime = videoApiUtils.getSafeSeekTime(_mediaView.mediaElement, timeInSeconds);
      if (_videoControlsController && _mediaView.mediaElement.currentTime.toFixed(VIDEO_TIME_COMPARE_PRECISION) !== safeSeekTime.toFixed(VIDEO_TIME_COMPARE_PRECISION)) {
         _videoControlsController.beforeAfterPlayControlsView.hideVideoClickToReplayLink();
      }

      if (_mediaView.mediaElement.ended) {
         $rootContainer.trigger(events.Media.Replay, {time: safeSeekTime, playAfterSeek: false});
      } else {
         $rootContainer.trigger(events.Media.SeekToTime, {time: safeSeekTime});
      }
   };

   var initPlugins = function(event, data) {
      var pluginController = playerConfiguration.getPluginController();
      if (pluginController && data.viewElementType === supportedPluginViewElements.PLAYER) {
         pluginController.initializePluginsForViewElement(supportedPluginViewElements.PLAYER, _$playerPluginContainer);
      }
   };

   setupExternalEventCallbackHarness();

   createPlayerLayout();
   if (xmpInErrorState) {
      displayXmpErrorMessage();
   }
   _mediaViewReady = createMediaView(_$mediaViewPortContainer);
   _mediaViewReady.then(onMediaViewInitialized).catch(function(error) {
      log.logException(error);
   });

   return Object.defineProperties({
      loadMedia: loadMedia,
      destroy: destroy,
      play: playVideo,
      pause: pauseVideo,
      // custom view stuff
      isInFullScreenMode: isInFullScreenMode,
      addEventListener: addEventListener,
      removeEventListener: removeEventListener,
      addMediaEventListener: addMediaEventListener,
      removeMediaEventListener: removeMediaEventListener,
      // controls state internal to smart-player-view
      getCaptionsVisible: getCaptionsVisible,
      setCaptionsVisible: setCaptionsVisible,
      getScrubbing: getIsScrubbing,
      setScrubbing: setIsScrubbing,
      // events
      triggerVideoStartEventCallbacks: triggerVideoStartEventCallbacks,
      // util methods
      getSafeSeekTime: getSafeSeekTime
   }, {
      initializedPromise: {
         get: function() {
            return _viewInitializedPromise;
         }
      },
      currentSrc: {
         get: function() {
            return _mediaView ? _mediaView.currentSrc : '';
         }
      },
      currentTime: {
         get: function() {
            return _mediaView ? _mediaView.currentTime : 0;
         },
         set: setExternalCurrentTime
      },
      duration: {
         get: function() {
            return _mediaView ? _mediaView.duration : 0;
         }
      },
      paused: {
         get: function() {
            return _mediaView ? _mediaView.paused : false;
         }
      },
      ended: {
         get: function() {
            return _mediaView ? _mediaView.ended : false;
         }
      },
      seeking: {
         get: function() {
            return _mediaView ? _mediaView.seeking : false;
         }
      },
      buffered: {
         get: function() {
            return _mediaView ? _mediaView.buffered : [];
         }
      },
      played: {
         get: function() {
            return _mediaView ? _mediaView.played : [];
         }
      },
      seekable: {
         get: function() {
            return _mediaView ? _mediaView.seekable : [];
         }
      },
      volume: {
         get: function() {
            return _mediaView ? _mediaView.volume : 1;
         },
         set: function(volumeValue) {
            $rootContainer.trigger(events.Media.SetVolume, {volumeValue: volumeValue});
         }
      },
      muted: {
         get: function() {
            return _mediaView ? _mediaView.muted : false;
         },
         set: function(isMuted) {
            $rootContainer.trigger(events.Media.SetMutedState, {isMuted: isMuted});
         }
      },
      playbackRate: {
         get: function() {
            return _mediaView ? _mediaView.playbackRate : 1;
         },
         set: function(playbackRate) {
            $rootContainer.trigger(events.Media.SetPlaybackRate, {playbackRate: playbackRate});
         }
      },
      progressBarDisplayWidth: {
         get: function() {
            return _videoControlsController ? _videoControlsController.progressBarControlView.displayWidth : 0;
         }
      },
      currentDisplayMode: {
         get: function() {
            return _playerState.displayMode;
         }
      }
   });
};

export default {
   /**
    *  Factory method that returns a new SmartPlayerView object
    *  @function create
    *  @memberof TSC.SmartPlayerView
    *  @param {String} playerInstanceUUID
    *  @param {jQuery} $rootContainer
    *  @param {TSC.MediaList} mediaList
    *  @param {TSC.XmpModel} xmpModel
    *  @param {TSC.Quiz} quizModel
    *  @param {TSC.CaptionService} captionService
    *  @param {Object} playerConfiguration
    *  @static
    *  @return new SmartPlayerView instance
    */
   create: SmartPlayerView
};
