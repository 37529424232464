import $ from 'jquery';
import keys from '../constants/keys';
import playerStringNames from '../constants/player-string-names';
import localizationStrings from '../models/localization-strings';
import events from '../constants/events';
import cssClasses from '../constants/css-classes';
import hotspotViewState from '../constants/hotspot-active-state';
import HotspotViewModel from '../models/hotspot-view-model';
import HotspotView from './hotspot-view';
import templateGenerator from '../utils/template-generator';

/**
 *  @memberof TSC
 *  @class HotspotsView
 *  @classdesc Hotspots View
 *  @param {Object} spContainer - jQuery element that is the smartplayer top-level container
 *  @param {TSC.Xmp} xmp - XMP model
 *  @param {HTMLVideoElement} mediaElement - html5 media DOM element (or wrapper presenting same interface)
 *  @param {TSC.TabController} tabController - Tab controller instance
 */
var HotspotsView = function(spContainer, xmp, mediaElement, tabController) {
   var _xmp = xmp;
   var _eventContainer = spContainer;
   var _spContainerId = spContainer.attr('id');
   var _hotspotsContainerId = [_spContainerId, 'hotspots'].join('-');
   var _hotspotXmpModels = [];
   var _hotspotViews = [];
   var _$hotspots;
   var _mediaNativeWidth = null;
   var _mediaNativeHeight = null;
   var _oneOrMoreHotspotActive = false;
   var _currentFile;

   var createView = function() {
      var hotspotsMarkup = templateGenerator.generateHotspotsMarkup(_hotspotsContainerId);
      _$hotspots = $($.parseHTML(hotspotsMarkup));
   };

   var preventEvent = function(e) {
      e.preventDefault();
      e.stopPropagation();
   };

   var bindEvents = function() {
      _$hotspots.on('click', 'svg', onHotspotClick);
      _$hotspots.on('mousedown', preventEvent);
      _$hotspots.on('touchend', 'svg', onHotspotClick);
      _$hotspots.on('keydown', 'svg', function(e) {
         if (keys.isKeyOfType(e.key, keys.keyTypes.space)) {
            onHotspotClick(e);
         }
      });
   };

   var init = function(videoNativeWidth, videoNativeHeight, currentFile) {
      _currentFile = currentFile;
      _mediaNativeWidth = videoNativeWidth;
      _mediaNativeHeight = videoNativeHeight;
      _oneOrMoreHotspotActive = false;

      // remove all current hotspots in the case we are loading consecutive video in a playlist
      _$hotspots.empty();
      _hotspotViews = [];
      _hotspotXmpModels = [];
      _xmp.hotspotItemArray.filter(function(hotspotXmpModel) {
         // only init hotspots for this video
         var isHotspotForADifferentFile = hotspotXmpModel.file !== undefined && hotspotXmpModel.file !== _currentFile;
         return !isHotspotForADifferentFile;
      }).forEach(function(hotspotXmpModel, index) {
         var hotspotViewModel = HotspotViewModel.create(hotspotXmpModel, index, _mediaNativeWidth, _mediaNativeHeight);
         var hotspotView = HotspotView.create(spContainer, mediaElement, _$hotspots, hotspotViewModel, _mediaNativeWidth, _mediaNativeHeight);
         _hotspotViews.push(hotspotView);
         _hotspotXmpModels.push(hotspotXmpModel);
      });
   };

   var removeScreenReaderAlert = function() {
      _$hotspots.find('.' + cssClasses.screenReaderAlert).remove();
   };

   var addScreenReaderAlert = function(numberHotspotsJustDisplayed) {
      var hotspotAlertStringToken = '{{numberOfHotspots}}';
      var hotspotAlertText = localizationStrings.getPlayerString(playerStringNames.txtHotspotScreenReaderAlert);
      if (numberHotspotsJustDisplayed > 1) {
         hotspotAlertText = localizationStrings.getPlayerString(playerStringNames.txtHotspotScreenReaderAlertPlural);
      }
      hotspotAlertText = hotspotAlertText.replace(hotspotAlertStringToken, numberHotspotsJustDisplayed);

      var screenReaderAlertMarkup = templateGenerator.generateScreenReaderAlertMarkup(hotspotAlertText);
      var $screenReaderAlert = $(screenReaderAlertMarkup);
      _$hotspots.append($screenReaderAlert);
   };

   var getLastActiveHotspotView = function() {
      for (var i = _hotspotViews.length - 1; i >= 0; i--) {
         if (_hotspotViews[i].hotspotViewModel.isActive) {
            return _hotspotViews[i];
         }
      }
      return null;
   };

   var onHotspotActiveChange = function(oneOrMoreHotspotActive, numberHotspotsJustDisplayed) {
      spContainer.playerState.oneOrMoreHotspotsWithTimeActionArePaused = isHotspotWithTimeActionPaused();

      if (_oneOrMoreHotspotActive !== oneOrMoreHotspotActive) {
         _oneOrMoreHotspotActive = oneOrMoreHotspotActive;

         var eventName = _oneOrMoreHotspotActive ? events.Hotspots.AreaActive : events.Hotspots.AreaInactive;
         _eventContainer.trigger(eventName);
      }

      if (_oneOrMoreHotspotActive) {
         var lastActiveHotspotView = getLastActiveHotspotView();
         if (lastActiveHotspotView) {
            tabController.lastTabEnabledElement = lastActiveHotspotView.controlElement;
         }

         if (numberHotspotsJustDisplayed > 0) {
            addScreenReaderAlert(numberHotspotsJustDisplayed);
         }
      } else {
         tabController.lastTabEnabledElement = null;
         removeScreenReaderAlert();
      }
   };

   var update = function(currentMediaTimeInMilliseconds) {
      var oneOrMoreHotspotActive = false;
      var numberHotspotsJustDisplayed = 0;
      _hotspotViews.forEach(function(hotspotView) {
         var hotspotState = hotspotView.update(currentMediaTimeInMilliseconds);
         if (hotspotState === hotspotViewState.firstVisible) {
            numberHotspotsJustDisplayed++;
         }
         if (hotspotState === hotspotViewState.firstVisible || hotspotState === hotspotViewState.visible) {
            oneOrMoreHotspotActive = true;
         }
      });
      onHotspotActiveChange(oneOrMoreHotspotActive, numberHotspotsJustDisplayed);
   };

   var onHotspotClick = function(event) {
      event.preventDefault();
      event.stopPropagation();

      var hotspotIndex = Number($(event.target).attr('data-hotspot-index'));
      var targetHotspotXmpModel = _hotspotXmpModels[hotspotIndex];
      var targetHotspotViewModel = _hotspotViews[hotspotIndex].hotspotViewModel;

      var eventData = {
         jumpFile: targetHotspotXmpModel.jumpFile,
         jumpTime: targetHotspotXmpModel.jumpTime,
         location: targetHotspotXmpModel.location,
         newWindow: targetHotspotXmpModel.newWindow
      };

      if (!eventData.location && targetHotspotViewModel.hasPausedAtEnd) {
         _eventContainer.trigger(events.Hotspots.Resume);
      }

      _eventContainer.trigger(events.Controls.HotspotClicked, eventData);
   };

   var resetHasPaused = function() {
      _hotspotViews.forEach(function(hotspotView) {
         hotspotView.resetHasPaused();
      });
   };

   var isHotspotWithTimeActionPaused = function() {
      for (var i = 0; i < _hotspotViews.length; i++) {
         if (_hotspotViews[i].hotspotViewModel.hasPausedAtEnd && !_hotspotViews[i].hotspotViewModel.xmpModel.location) {
            return true;
         }
      }
      return false;
   };

   createView();
   bindEvents();

   return Object.defineProperties({
      init: init,
      update: update,
      resetHasPaused: resetHasPaused
   }, {
      $hotspots: {
         get: function() {
            return _$hotspots;
         }
      },
      oneOrMoreHotspotActive: {
         get: function() {
            return _oneOrMoreHotspotActive;
         }
      },
      oneOrMoreHotspotsWithTimeActionArePaused: {
         get: isHotspotWithTimeActionPaused
      }
   });
};

export default {
   create: HotspotsView
};
