import {LanguageIdentifier, languageIdentifiers} from '@techsmith/tsc-media-extensions-js';
import languageCodes from '../constants/language-codes';
import log from '../../common/log';
import localeFactory from './locale-factory';
import customStrings from './custom-strings';
import playerStringNames from '../constants/player-string-names';

var CURRENT_QUESTION_TOKEN = '{{currentQuestion}}';
var TOTAL_QUESTIONS_TOKEN = '{{totalQuestions}}';
var _language = null;
var _currentLocale = null;

var setLanguage = function(language) {
   if (languageCodes.isValid(language)) {
      _language = language;
      _currentLocale = localeFactory.getLocaleByLanguageCode(_language);
   }
};

var getLanguage = function() {
   return _language;
};

var getPlayerString = function(stringName) {
   if (customStrings.strings[stringName]) {
      return customStrings.strings[stringName];
   } else if (_currentLocale.strings[stringName]) {
      return _currentLocale.strings[stringName];
   }
   return '';
};

var getLanguageNameFromIdentifierString = function(languageIdentifierString) {
   var languageIdentifier = LanguageIdentifier.create(languageIdentifierString);
   return languageIdentifiers.identifiers[languageIdentifier.language] ? languageIdentifiers.identifiers[languageIdentifier.language].displayName : languageIdentifierString;
};

var getQuestionCountString = function(currentQuestionNumber, totalNumberQuestions) {
   var questionCountString = getPlayerString(playerStringNames.txtQuestionCount);
   questionCountString = questionCountString.replace(CURRENT_QUESTION_TOKEN, currentQuestionNumber);
   return questionCountString.replace(TOTAL_QUESTIONS_TOKEN, totalNumberQuestions);
};

var configureLanguageFromXmp = function(xmp) {
   xmp.controllerParameters.forEach(function(controllerParameter) {
      if (controllerParameter.name.toLowerCase() === 'locale') {
         setLanguage(controllerParameter.value);
      }
   });
};

var configureCustomStringsFromXmp = function(xmp) {
   xmp.quizParameters.forEach(function(quizParameter) {
      try {
         customStrings.setString(quizParameter.name, quizParameter.value);
      } catch (e) {
         log.logMessage(e.message);
      }
   });
};

var configureFromXmp = function(xmp) {
   configureLanguageFromXmp(xmp); //Configure language first so that _language is properly set
   configureCustomStringsFromXmp(xmp);
};

var getReadableTimeByLocale = function(hours, minutes, seconds) {
   return _currentLocale.getReadableTimeByLocale(hours, minutes, seconds);
};

var reset = function() {
   customStrings.clearStrings();
   setLanguage(languageCodes.ENGLISH);
};

setLanguage(languageCodes.ENGLISH);

export default Object.defineProperties({
   setLanguage: setLanguage,
   getLanguage: getLanguage,
   getLanguageNameFromIdentifierString: getLanguageNameFromIdentifierString,
   configureFromXmp: configureFromXmp,
   getReadableTimeByLocale: getReadableTimeByLocale,
   getPlayerString: getPlayerString,
   getQuestionCountString: getQuestionCountString,
   reset: reset
}, {});
