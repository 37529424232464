import events from '../constants/events';

/**
 * @memberof TSC
 * @class ExternalEventsController
 * @classdesc Controller for managing external events
 * @param {jQuery} $container - Smart Player main container jquery element
 * @param {MediaView} mediaView - Media view for current media. Created by MediaViewFactory.
 * @param {TSC.MediaList} mediaList
 * @param {Object} playerViewEventCallbacks
 *
 */
var ExternalEventsController = function($container, mediaView, mediaList, playerViewEventCallbacks) {
   var onLoadedMetadata = function() {
      playerViewEventCallbacks[events.External.MediaReady].forEach(function(callback) {
         callback();
      });
   };

   var onFullScreenChange = function() {
      playerViewEventCallbacks[events.External.PlayerFullScreenModeChanged].forEach(function(callback) {
         callback();
      });
   };

   var onTheaterModeChanged = function(e, eventData) {
      const newDisplayMode = eventData && eventData.displayMode;
      playerViewEventCallbacks[events.External.DisplayModeChanged].forEach(function(callback) {
         callback(newDisplayMode);
      });
   };

   var onCanPlayVideo = function() {
      playerViewEventCallbacks[events.External.VideoStart].forEach(function(callback) {
         callback();
      });

      mediaView.mediaWrapper.off('canplay', onCanPlayVideo);
   };

   var onMediaEnd = function(e, eventData) {
      if (eventData.isPlaybackEnded) {
         playerViewEventCallbacks[events.External.VideoEnd].forEach(function(callback) {
            callback();
         });
      }
   };

   var onVideoReplayHandler = function() {
      playerViewEventCallbacks[events.External.VideoReplay].forEach(function(callback) {
         callback();
      });
   };

   var onVideoError = function() {
      playerViewEventCallbacks[events.External.MediaError].forEach(function(callback) {
         callback();
      });
   };

   var onPlayingEvent = function() {
      mediaView.mediaWrapper.off('playing', onPlayingEvent);

      // send a playing/streaming confirmation if applicable
      playerViewEventCallbacks[events.External.VideoPlay].forEach(function(callback) {
         callback();
      });
   };

   var onStartedPlaybackHandler = function() {
      playerViewEventCallbacks[events.External.VideoStartInitiated].forEach(function(callback) {
         callback();
      });
   };

   var triggerQuizVisible = function() {
      playerViewEventCallbacks[events.External.QuizVisible].forEach(function(callback) {
         callback();
      });
   };

   var triggerQuizHidden = function() {
      playerViewEventCallbacks[events.External.QuizHidden].forEach(function(callback) {
         callback();
      });
   };

   var triggerQuizPromptVisible = function() {
      playerViewEventCallbacks[events.External.ShowStartQuizPrompt].forEach(function(callback) {
         callback();
      });
   };

   var triggerQuizPromptHidden = function() {
      playerViewEventCallbacks[events.External.HideStartQuizPrompt].forEach(function(callback) {
         callback();
      });
   };

   var processCaptionChanged = function(e, eventData) {
      if (eventData) {
         playerViewEventCallbacks[events.External.CaptionChange].forEach(function(callback) {
            callback(eventData.plainText);
         });
      }
   };

   var processCaptionToggled = function(e, eventData) {
      if (eventData) {
         const externalCaptionEvent = eventData.captionsEnabled ? events.External.CaptionsVisible : events.External.CaptionsHidden;
         playerViewEventCallbacks[externalCaptionEvent].forEach(function(callback) {
            callback();
         });
      }
   };

   var triggerPlayerSettingsOpen = function() {
      playerViewEventCallbacks[events.External.PlayerSettingsOpen].forEach(function(callback) {
         callback();
      });
   };

   var triggerPlayerSettingsClose = function() {
      playerViewEventCallbacks[events.External.PlayerSettingsClose].forEach(function(callback) {
         callback();
      });
   };

   var triggerVideoControlsHidden = function() {
      playerViewEventCallbacks[events.External.PlayerControlsHidden].forEach(function(callback) {
         callback();
      });
   };

   var triggerVideoControlsVisible = function() {
      playerViewEventCallbacks[events.External.PlayerControlsVisible].forEach(function(callback) {
         callback();
      });
   };

   var triggerExternalPlayerResize = function() {
      playerViewEventCallbacks[events.External.PlayerResize].forEach(function(callback) {
         callback();
      });
   };

   var triggerHeroControlHidden = function() {
      playerViewEventCallbacks[events.External.HeroControlHidden].forEach(function(callback) {
         callback();
      });
   };

   var triggerImagePointerOver = function() {
      playerViewEventCallbacks[events.External.ImagePointerOver].forEach(function(callback) {
         callback();
      });
   };

   var triggerImagePointerLeave = function() {
      playerViewEventCallbacks[events.External.ImagePointerLeave].forEach(function(callback) {
         callback();
      });
   };

   var bindToEvents = function() {
      $container.on(events.Controls.StartedPlayback, onStartedPlaybackHandler);
      $container.on(events.Controls.FullScreenChange, onFullScreenChange);
      $container.on(events.Controls.TheaterModeChanged, onTheaterModeChanged);
      $container.on(events.Media.End, onMediaEnd);
      $container.on(events.Media.Replay, onVideoReplayHandler);

      $container.on(events.Media.Error, onVideoError);

      $container.on(events.Quizzing.ShowStartQuizPrompt, triggerQuizVisible);
      $container.on(events.Quizzing.Review, triggerQuizHidden);
      $container.on(events.Quizzing.ShowQuestionSetView, triggerQuizVisible);
      $container.on(events.Quizzing.Complete, triggerQuizHidden);

      $container.on(events.Quizzing.ShowQuizSetup, triggerQuizPromptVisible);
      $container.on(events.Quizzing.BeginClicked, triggerQuizPromptHidden);
      $container.on(events.Quizzing.SkipClicked, triggerQuizPromptHidden);

      $container.on(events.Captions.Changed, processCaptionChanged);
      $container.on(events.Captions.Toggled, processCaptionToggled);

      $container.on(events.Controls.PlayerSettingsOpen, triggerPlayerSettingsOpen);
      $container.on(events.Controls.PlayerSettingsClose, triggerPlayerSettingsClose);

      $container.on(events.Controls.VideoControlsHidden, triggerVideoControlsHidden);
      $container.on(events.Controls.VideoControlsVisible, triggerVideoControlsVisible);

      $container.on(events.Controls.PlayerResize, triggerExternalPlayerResize);

      $container.on(events.Controls.HeroPlayControlClicked, triggerHeroControlHidden);

      $container.on(events.Images.PointerOver, triggerImagePointerOver);
      $container.on(events.Images.PointerLeave, triggerImagePointerLeave);

      var eventsToBind = {
         loadedmetadata: onLoadedMetadata,
         canplay: onCanPlayVideo,
         playing: onPlayingEvent
      };
      mediaView.mediaWrapper.on(eventsToBind);
   };

   var unbindToEvents = function() {
      $container.off(events.Controls.StartedPlayback, onStartedPlaybackHandler);
      $container.off(events.Controls.FullScreenChange, onFullScreenChange);
      $container.off(events.Controls.TheaterModeChanged, onTheaterModeChanged);
      $container.off(events.Media.End, onMediaEnd);
      $container.off(events.Media.Replay, onVideoReplayHandler);

      $container.off(events.Media.Error, onVideoError);

      $container.off(events.Quizzing.ShowStartQuizPrompt, triggerQuizVisible);
      $container.off(events.Quizzing.Review, triggerQuizHidden);
      $container.off(events.Quizzing.ShowQuestionSetView, triggerQuizVisible);
      $container.off(events.Quizzing.Complete, triggerQuizHidden);

      $container.off(events.Quizzing.ShowQuizSetup, triggerQuizPromptVisible);
      $container.off(events.Quizzing.BeginClicked, triggerQuizPromptHidden);
      $container.off(events.Quizzing.SkipClicked, triggerQuizPromptHidden);

      $container.off(events.Captions.Changed, processCaptionChanged);
      $container.off(events.Captions.Toggled, processCaptionToggled);

      $container.off(events.Controls.PlayerSettingsOpen, triggerPlayerSettingsOpen);
      $container.off(events.Controls.PlayerSettingsClose, triggerPlayerSettingsClose);

      $container.off(events.Controls.VideoControlsHidden, triggerVideoControlsHidden);
      $container.off(events.Controls.VideoControlsVisible, triggerVideoControlsVisible);

      $container.off(events.Controls.PlayerResize, triggerExternalPlayerResize);

      $container.off(events.Controls.HeroPlayControlClicked, triggerHeroControlHidden);

      $container.off(events.Images.PointerOver, triggerImagePointerOver);
      $container.off(events.Images.PointerLeave, triggerImagePointerLeave);

      var eventsToBind = {
         loadedmetadata: onLoadedMetadata,
         canplay: onCanPlayVideo,
         playing: onPlayingEvent
      };
      mediaView.mediaWrapper.off(eventsToBind);
   };

   var destroy = function() {
      unbindToEvents();
   };

   bindToEvents();

   return Object.defineProperties({
      destroy: destroy
   }, {
   });
};

export default {
   /**
    *  Factory method that returns a new ExternalEventsController.
    *  @function create
    *  @memberof TSC.ExternalEventsController
    *  @static
    *  @param {jQuery} $container - Smart Player main container jquery element
    *  @param {MediaView} mediaView - Media view for current media. Created by MediaViewFactory.
    *  @param {TSC.MediaList} mediaList
    *  @param {Object} playerViewEventCallbacks
    *  @return new ExternalEventsController instance
    */
   create: ExternalEventsController
};
