import events from '../constants/events';
import TocView from '../views/toc-view';
import deviceInfo from '../utils/device-info';
import mediaViewType from '../constants/media-view-type';

/**
 * @memberof TSC
 * @class TableOfContentsController
 * @classdesc Controller for managing XMP features
 * @param {jQuery} $container - Smart Player main container jquery element
 * @param {MediaView} mediaView - Media view for current media. Created by MediaViewFactory.
 * @param {TSC.XmpModel} xmpModel
 * @param {TSC.MediaList} mediaList
 * @param {TSC.ProgressBarControlView} progressBarControlView
 * @param {TSC.playerConfiguration} playerConfiguration
 *
 */
var TableOfContentsController = function($container, mediaView, xmpModel, mediaList, progressBarControlView, playerConfiguration) {
   var hasTableOfContents = xmpModel && xmpModel.hasSearchableItems;
   if (!hasTableOfContents) {
      throw new Error('Attempted to create table of contents controller with no table of contents or searchable items in xmp');
   }

   var _animationSpeed = 300;
   var _tocView;
   var _inFullScreen;
   var _controlsHidden;

   var setupXmpFeatures = function() {
      _tocView = TocView.create($container, xmpModel);
      $container.trigger(events.Controls.DisplayTocControl);
   };

   var resizeTableOfContents = function() {
      if (_tocView.isOpen) {
         _tocView.resize();
      }
   };

   var onPlayerResize = function() {
      resizeTableOfContents();
   };

   var onFullScreenChange = function(e, eventData) {
      _inFullScreen = eventData.inFullScreen;
      resizeTableOfContents();
   };

   var onLoadedMetadata = function() {
      mediaView.mediaWrapper.off('loadedmetadata', onLoadedMetadata);

      if (xmpModel.hasTocItems && mediaView.type !== mediaViewType.playlist) {
         progressBarControlView.createTocChapters(xmpModel.tocItemArray);
      }

      resizeTableOfContents();
      if (_tocView.isOpen) {
         $container.trigger(events.Controls.ShowToc);
      }
   };

   var onTocItemClicked = function(timeToSeekTo, fileToSeekTo) {
      if (fileToSeekTo !== undefined) {
         var videoIndex = mediaList.getMediaIndexByUri(fileToSeekTo);
         if (videoIndex === mediaList.currentMediaIndex) {
            $container.trigger(events.Media.SeekToTime, {time: timeToSeekTo});
         } else {
            mediaList.currentMediaIndex = videoIndex;
            $container.trigger(events.Media.SourceChanged);
         }
      } else if (playerConfiguration.allowFastForward || timeToSeekTo < mediaView.currentTime) {
         $container.trigger(events.Media.SeekToTime, {time: timeToSeekTo});
      }
   };

   var displayToc = function() {
      _tocView.open();
   };

   var hideToc = function() {
      _tocView.close();
   };

   var mobileOrientationChange = function() {
      if (_inFullScreen) {
         // when keyboard is up on ipad, the size of screen
         // is reported wrong until we get rid of it
         if (_tocView.searchIsFocused) {
            _tocView.blurSearchInput();
            setTimeout(resizeTableOfContents, 500);
         } else {
            resizeTableOfContents();
         }
      }
   };

   var hideControls = function() {
      if (_tocView.tocContainsActiveElement) {
         return;
      }

      _controlsHidden = true;

      if (_tocView.isOpen) {
         _tocView.hide(_animationSpeed);
      }
   };

   var showControls = function() {
      if (_controlsHidden) {
         _controlsHidden = false;
         if (_tocView.isOpen) {
            _tocView.show(_animationSpeed);
         }
      }
   };

   var onQuizMinimizeUI = function() {
      if (_tocView.isOpen) {
         _tocView.close();
      }

      hideControls();
   };

   var onQuizMaximizeUI = function() {
      showControls();
   };

   var onQuizReview = function() {
      if (_tocView.isOpen) {
         _tocView.show(_animationSpeed);
      }
   };

   var onQuizPaused = function() {
      if (_tocView.isOpen) {
         _tocView.hide(_animationSpeed);
      }
   };

   var onTocDisplayToggled = function(e, data) {
      if (data.displayToc) {
         displayToc();
      } else {
         hideToc();
      }
   };

   var bindToEvents = function() {
      _tocView.addEventListener('clickTocItem', onTocItemClicked);
      $container.on(events.Controls.ShowToc, displayToc);
      $container.on(events.Controls.HideToc, hideToc);
      $container.on(events.Controls.TocDisplayToggled, onTocDisplayToggled);

      if (deviceInfo.isIOS() || deviceInfo.isAndroid()) {
         window.addEventListener('orientationchange', mobileOrientationChange);
      }

      $container.on(events.Quizzing.MinimizeUI, onQuizMinimizeUI);
      $container.on(events.Quizzing.MaximizeUI, onQuizMaximizeUI);
      $container.on(events.Quizzing.Review, onQuizReview);
      $container.on(events.Quizzing.Paused, onQuizPaused);

      $container.on(events.Controls.FullScreenChange, onFullScreenChange);
      $container.on(events.Controls.PlayerResize, onPlayerResize);

      var eventsToBind = {
         loadedmetadata: onLoadedMetadata
      };
      mediaView.mediaWrapper.on(eventsToBind);
   };

   var unbindToEvents = function() {
      _tocView.removeEventListener('clickTocItem', onTocItemClicked);
      $container.off(events.Controls.ShowToc, displayToc);
      $container.off(events.Controls.HideToc, hideToc);
      $container.off(events.Controls.TocDisplayToggled, onTocDisplayToggled);

      if (deviceInfo.isIOS() || deviceInfo.isAndroid()) {
         window.removeEventListener('orientationchange', mobileOrientationChange);
      }

      $container.off(events.Quizzing.MinimizeUI, onQuizMinimizeUI);
      $container.off(events.Quizzing.MaximizeUI, onQuizMaximizeUI);
      $container.off(events.Quizzing.Review, onQuizReview);
      $container.on(events.Quizzing.Paused, onQuizPaused);

      $container.off(events.Controls.FullScreenChange, onFullScreenChange);
      $container.off(events.Controls.PlayerResize, onPlayerResize);

      var eventsToBind = {
         loadedmetadata: onLoadedMetadata
      };
      mediaView.mediaWrapper.off(eventsToBind);
   };

   var destroy = function() {
      unbindToEvents();
      _tocView && _tocView.destroy();
   };

   setupXmpFeatures();
   bindToEvents();

   return Object.defineProperties({
      destroy: destroy
   }, {
   });
};

export default {
   /**
    *  Factory method that returns a new TableOfContentsController.
    *  @function create
    *  @memberof TSC.TableOfContentsController
    *  @static
    *  @param {jQuery} $container - Smart Player main container jquery element
    *  @param {MediaView} mediaView - Media view for current media. Created by MediaViewFactory.
    *  @param {TSC.XmpModel} xmpModel
    *  @param {TSC.MediaList} mediaList
    *  @param {TSC.ProgressBarControlView} progressBarControlView
    *  @param {TSC.playerConfiguration} playerConfiguration
    *  @return new TableOfContentsController instance
    */
   create: TableOfContentsController
};
