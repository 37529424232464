import playerConfiguration from '../models/player-configuration';
import cssClasses from '../constants/css-classes';
import tabIndex from '../constants/tab-index';
import events from '../constants/events';
import viewHelper from '../utils/view-helper';
import highlightDisabler from '../utils/highlight-disabler';
import templateGenerator from '../utils/template-generator';
import videoApiUtils from '../utils/video-api-utils';

var PlayRewindControlsView = function($container, mediaElement) {
   var skipDuration = playerConfiguration.skipVideoDuration;
   var _markup = templateGenerator.generatePlayRewindControlsMarkup(skipDuration);
   $container.find('.play-rewind-controls').html(_markup);

   var _disabled = false;
   var _$playControl = $container.find('.play-control');
   var _$rewindControl = $container.find('.rewind-control');
   var _$forwardControl = $container.find('.forward-control');
   var _$playButtonGlyph = _$playControl.find('.play-button-glyph');
   var _$pauseButtonGlyph = _$playControl.find('.pause-button-glyph');

   var disableKeyListeners = function() {
      _$playControl.attr('tabindex', tabIndex.Disabled);
      _$rewindControl.attr('tabindex', tabIndex.Disabled);
      _$forwardControl.attr('tabindex', tabIndex.Disabled);
      _disabled = true;
   };

   var enableKeyListeners = function() {
      _$playControl.attr('tabindex', tabIndex.DomOrder);
      _$rewindControl.attr('tabindex', tabIndex.DomOrder);
      _$forwardControl.attr('tabindex', tabIndex.DomOrder);
      _disabled = false;
   };

   var pauseMedia = function() {
      mediaElement.pause();
   };

   $container.on(events.Controls.Disable, disableKeyListeners);
   $container.on(events.Controls.Enable, enableKeyListeners);
   $container.on(events.SCORM.ExitSession, pauseMedia);

   mediaElement.addEventListener(events.Media.Play, function() {
      _$playButtonGlyph.hide();
      _$pauseButtonGlyph.show();
      _$playControl.attr('aria-pressed', true);
   });

   mediaElement.addEventListener(events.Media.Pause, function() {
      _$pauseButtonGlyph.hide();
      _$playButtonGlyph.show();
      _$playControl.attr('aria-pressed', false);
   });

   var onPlayHandler = function(e) {
      e.preventDefault();
      togglePlay();
   };

   var togglePlay = function() {
      if (_disabled) {
         return;
      }

      if (mediaElement.paused) {
         if (mediaElement.ended) {
            $container.trigger(events.Media.Replay);
         } else {
            mediaElement.play();
         }
      } else {
         pauseMedia();
      }
   };

   var seekVideo = function(timeInSeconds) {
      $container.trigger(events.Media.SeekToTime, {time: videoApiUtils.getSafeSeekTime(mediaElement, timeInSeconds)});
   };

   var rewindVideo = function(e) {
      e.preventDefault();
      seekVideo(mediaElement.currentTime - playerConfiguration.skipVideoDuration);
   };

   var skipVideoForward = function(e) {
      e.preventDefault();
      seekVideo(mediaElement.currentTime + playerConfiguration.skipVideoDuration);
   };

   var setInitialFocus = function() {
      if (viewHelper.elementOrChildHasFocus($container)) {
         _$playControl.focus();
         highlightDisabler.disableHighlights(_$playControl);
      }
   };

   var destroy = function() {
      _$playControl.off('click', onPlayHandler);
      _$rewindControl.off('click', rewindVideo);
      _$forwardControl.off('click', skipVideoForward);
   };

   if (playerConfiguration.skipVideoControlsEnabled) {
      _$rewindControl.on('click', rewindVideo);
      if (playerConfiguration.allowFastForward) {
         _$forwardControl.on('click', skipVideoForward);
      } else {
         _$forwardControl.addClass(cssClasses.hide);
      }
   } else {
      _$rewindControl.addClass(cssClasses.hide);
      _$forwardControl.addClass(cssClasses.hide);
   }

   _$playControl.on('click', onPlayHandler);
   _$pauseButtonGlyph.hide();

   return Object.defineProperties({
      togglePlay: togglePlay,
      setInitialFocus: setInitialFocus,
      destroy: destroy
   }, {});
};

export default {
   create: PlayRewindControlsView
};
