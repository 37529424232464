const fillAnswerTemplate = ({questionText, inputId, inputLabel, placeHolderImg, inputName, userResponse, useAutofocus, focusableAnswerInput}) => `<form onsubmit="return false;">
   <fieldset>
      <legend class="sr-only">${questionText}</legend>
      <label for="${inputId}" class="sr-only float-element-left">${inputLabel}</label>
      <div class="fill-in-and-short-answer-answer-container">
         <img src='${placeHolderImg}' class='image-feedback feedback-placeholder' aria-hidden="true" alt="" />
         <input type="text" id="${inputId}" class="fill-in-the-blank-input ${focusableAnswerInput}" maxlength="140" name="${inputName}" value="${userResponse ? userResponse : ''}" autocomplete="off" ${useAutofocus ? 'autofocus' : ''} />
      </div>
   </fieldset>
</form>`;

export default fillAnswerTemplate;
