import Promise from 'promise-polyfill';
import HttpService from './http-service';

var httpService = HttpService.create();

var getTechSmithAccessToken = function(playerConfiguration) {
   var accessTokenEndpoint = playerConfiguration.getTechSmithAccessTokenEndpoint();
   if (accessTokenEndpoint) {
      return httpService.get(accessTokenEndpoint).then(function(data) {
         return data.AccessToken;
      });
   }
   return Promise.resolve();
};

var abortPendingRequests = function() {
   httpService.abortPendingRequests();
};

export default {
   getTechSmithAccessToken: getTechSmithAccessToken,
   abortPendingRequests: abortPendingRequests
};
