var DEFAULT_SEGMENT_LENGTH = 30000;
var SEGMENT_CHOICES = [
   {duration: 300000, segmentLength: 2000},
   {duration: 1800000, segmentLength: 5000}
];

var _videoLoadStartTime;
var _videoLoadEndTime;
var _videoDuration = 0;
var _segmentLength = DEFAULT_SEGMENT_LENGTH;
var _numberSegments = 0;
var _videoSegments = [];
var _numberVideoSegmentWatched = 0;
var _currentPlayTime = 0;
var _enabled = false;

export default {
   setVideoLoadStartTime: function(time) {
      _videoLoadStartTime = time;
   },
   setVideoLoadEndTime: function(time) {
      _videoLoadEndTime = time;
   },
   getVideoLoadTime: function() {
      var loadTime = 0;

      if (_videoLoadStartTime && _videoLoadEndTime) {
         loadTime = _videoLoadEndTime - _videoLoadStartTime;
      }

      return loadTime;
   },
   getNumberSegments: function() {
      return _numberSegments;
   },
   setCurrentPlayTime: function(time) {
      _currentPlayTime = time;
   },
   getCurrentPlayTime: function() {
      return _currentPlayTime;
   },
   getNumberWatchedSegments: function() {
      return _numberVideoSegmentWatched;
   },
   isPercentWatchedInitialized: function() {
      return _videoDuration > 0;
   },
   clearPercentWatched: function() {
      _numberSegments = 0;
      _videoSegments = [];
      _numberVideoSegmentWatched = 0;
      _currentPlayTime = 0;
      _videoDuration = 0;
   },
   initPercentWatched: function(videoDurationInMilliseconds) {
      if (videoDurationInMilliseconds <= 0) {
         return;
      }

      _videoDuration = videoDurationInMilliseconds;

      // determine video segment length
      _segmentLength = DEFAULT_SEGMENT_LENGTH;
      for (var i = 0; i < SEGMENT_CHOICES.length; i++) {
         if (videoDurationInMilliseconds <= SEGMENT_CHOICES[i].duration) {
            _segmentLength = SEGMENT_CHOICES[i].segmentLength;
            break;
         }
      }

      // create array to store percentage watched
      _videoSegments = [];

      _numberSegments = Math.ceil(videoDurationInMilliseconds / _segmentLength);

      for (var j = 0; j < _numberSegments; j++) {
         _videoSegments.push(false);
      }

      _numberVideoSegmentWatched = 0;
   },
   markTimeWatched: function(videoTimeInMilliseconds) {
      videoTimeInMilliseconds = Math.min(videoTimeInMilliseconds, _videoDuration);

      _currentPlayTime = videoTimeInMilliseconds / 1000;
      var index = Math.floor(videoTimeInMilliseconds / _segmentLength);

      if (index < 0 || index >= _numberSegments) {
         return;
      }

      if (!_videoSegments[index]) {
         _numberVideoSegmentWatched++;
         _videoSegments[index] = true;
      }
   },
   getPercentageWatched: function() {
      if (_numberSegments && _numberSegments > 0) {
         return _numberVideoSegmentWatched / _numberSegments;
      }
      return 0;
   },
   getPercentageWatchedForVideoSegment: function(startTimeInMilliseconds, endTimeInMilliseconds) {
      var percentageWatched = 0;

      if (_videoDuration > 0 && startTimeInMilliseconds <= endTimeInMilliseconds) {
         var startSegmentIndex = Math.floor(startTimeInMilliseconds / _segmentLength);
         var endSegmentIndex = Math.floor(endTimeInMilliseconds / _segmentLength);

         var numberSegmentsWatched = 0;
         var totalSegmentsInRange = endSegmentIndex - startSegmentIndex + 1;

         for (var i = startSegmentIndex; i <= endSegmentIndex; i++) {
            if (_videoSegments[i]) {
               numberSegmentsWatched++;
            }
         }

         percentageWatched = numberSegmentsWatched / totalSegmentsInRange;
      }

      return percentageWatched;
   },
   setEnabled: function(enabled) {
      _enabled = enabled;
   },
   isEnabled: function() {
      return _enabled;
   },
   getVideoSegmentSnapShot: function() {
      return _videoSegments.map(function(segmentValue) {
         return segmentValue === true ? '1' : '0';
      }).join(',');
   },
   populateVideoSegmentsWatch: function(videoSegmentSnapshot) {
      var videoSegmentSnapshotList = videoSegmentSnapshot.split(',');
      if (_videoSegments.length !== videoSegmentSnapshotList.length) {
         return;
      }

      videoSegmentSnapshotList.forEach(function(segmentValue, index) {
         if (segmentValue === '1') {
            _numberVideoSegmentWatched++;
            _videoSegments[index] = true;
         }
      });
   }
};
