/**
 * @memberof TSC
 * @class nullPipController
 * @classdesc Null controller when PIP feature is not available
 */
var noop = function() {}; // eslint-disable-line

export default Object.defineProperties({
   enter: noop,
   exit: noop,
   bindChangeEvent: noop,
   unbindChangeEvent: noop
}, {
   inPip: {
      get: function() {
         return false;
      }
   },
   isSupported: {
      get: function() {
         return false;
      }
   }
});
