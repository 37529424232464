import playerStringNames from '../constants/player-string-names';
import localizationStrings from './localization-strings';
import timeFormatter from '../utils/time-formatter';

// time buffer value used to check if a media time is at the end of the hotspot
var endingTimeBufferSizeInMilliseconds = 1000;

var createHotspotDescription = function(hotspotXmpModel) {
   var hotspotText = localizationStrings.getPlayerString(playerStringNames.txtHotspot);
   var jumpMediaText = localizationStrings.getPlayerString(playerStringNames.txtJumpToMedia);
   var jumpTimeText = localizationStrings.getPlayerString(playerStringNames.txtJumpToTime);
   var openLinkText = localizationStrings.getPlayerString(playerStringNames.txtOpenLink);
   var playText = localizationStrings.getPlayerString(playerStringNames.accessBtnPlay);

   if (hotspotXmpModel.jumpFile !== undefined) {
      return hotspotText + ': ' + jumpMediaText + ': ' + hotspotXmpModel.jumpFile;
   } else if (hotspotXmpModel.jumpTime !== undefined && hotspotXmpModel.jumpTime !== '') {
      return hotspotText + ': ' + jumpTimeText + ': ' + timeFormatter.formatTimeForReadability(Number(hotspotXmpModel.jumpTime) / 1000);
   } else if (hotspotXmpModel.location !== undefined && hotspotXmpModel.location !== '') {
      return hotspotText + ': ' + openLinkText + ': ' + hotspotXmpModel.location;
   }
   return hotspotText + ': ' + playText;
};

var getHotspotViewPoints = function(hotspotXmpModel, videoNativeWidth, videoNativeHeight) {
   var viewPoints = [];
   hotspotXmpModel.boundingPoly.coordinates.forEach(function(coordinate) {
      var hsViewData = {
         xOrig: Math.round(coordinate.x),
         yOrig: Math.round(coordinate.y),
         xScale: Math.round(coordinate.x) / videoNativeWidth,
         yScale: Math.round(coordinate.y) / videoNativeHeight
      };

      viewPoints.push(hsViewData);
   });
   return viewPoints;
};

/**
 * @memberof TSC
 * @class HotspotViewModel
 * @classdesc HotSpot View Model
 * @param {Object} hotspotXmpModel - Hotspot XMP model
 * @param {Number} index - index of hotspot within all hotspots
 * @param {Number} videoNativeWidth - original width of video
 * @param {Number} videoNativeHeight - original height of video
 */
var HotspotViewModel = function(hotspotXmpModel, index, videoNativeWidth, videoNativeHeight) {
   var _hotspotXmpModel = hotspotXmpModel;
   var _hotspotIndex = index;
   var _isActive = false;
   var _hasPausedAtEnd = false;
   var _viewPoints = getHotspotViewPoints(hotspotXmpModel, videoNativeWidth, videoNativeHeight);
   var _description = createHotspotDescription(hotspotXmpModel);

   var getCoordinates = function(hotspotViewWidth, hotspotViewHeight) {
      var coordinates = [];
      _viewPoints.forEach(function(viewPoint) {
         coordinates.push(viewPoint.xOrig >= 0 ? Math.round(viewPoint.xScale * hotspotViewWidth) : 0);
         coordinates.push(viewPoint.yOrig >= 0 ? Math.round(viewPoint.yScale * hotspotViewHeight) : 0);
      });
      return coordinates.join(',');
   };

   var isWithinTime = function(timeInMilliseconds) {
      return _hotspotXmpModel.startTime < timeInMilliseconds && timeInMilliseconds <= _hotspotXmpModel.endTime;
   };

   var isTimeNearEndOfHotspot = function(timeInMilliseconds) {
      return getTimeLeftUntilEnd(timeInMilliseconds) <= endingTimeBufferSizeInMilliseconds;
   };

   var shouldPauseAtTheEnd = function(timeInMilliseconds) {
      return _hotspotXmpModel.pause === true && !_hasPausedAtEnd && isTimeNearEndOfHotspot(timeInMilliseconds);
   };

   var getTimeLeftUntilEnd = function(timeInMilliseconds) {
      return _hotspotXmpModel.endTime - timeInMilliseconds;
   };

   return Object.defineProperties({
      getCoordinates: getCoordinates,
      isHotspotWithinTime: isWithinTime,
      isTimeNearEndOfHotspot: isTimeNearEndOfHotspot,
      shouldPauseAtTheEnd: shouldPauseAtTheEnd,
      getTimeLeftUntilEnd: getTimeLeftUntilEnd
   }, {
      index: {
         get: function() {
            return _hotspotIndex;
         }
      },
      description: {
         get: function() {
            return _description;
         }
      },
      isActive: {
         get: function() {
            return _isActive;
         },
         set: function(isActive) {
            _isActive = isActive;
         }
      },
      hasPausedAtEnd: {
         get: function() {
            return _hasPausedAtEnd;
         },
         set: function(hasPausedAtEnd) {
            _hasPausedAtEnd = hasPausedAtEnd;
         }
      },
      xmpModel: {
         get: function() {
            return _hotspotXmpModel;
         }
      }
   });
};

export default {
   /**
    *  Factory method that returns a new HotspotViewModel object.
    *  @function create
    *  @memberof TSC.HotspotViewModel
    *  @static
    *  @param {Object} hotspotXmpModel - Hotspot XMP model
    *  @param {Number} index - index of hotspot within all hotspots
    *  @param {Number} videoNativeWidth - original width of video
    *  @param {Number} videoNativeHeight - original height of video
    *  @return new HotspotViewModel instance
    */
   create: HotspotViewModel
};
