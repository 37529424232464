import windowWrapper from '../../common/window-wrapper';

/**
 * @memberof TSC
 * @class urlService
 * @classdesc Url opening service.
 */
var ensureUrlHasValidScheme = function(url) {
   if (typeof url === 'string' && url.indexOf('http://') !== 0 && url.indexOf('https://') !== 0) {
      url = 'http://' + url;
   }
   return url;
};

var openUrl = function(url, features) {
   url = ensureUrlHasValidScheme(url);
   if (features) {
      windowWrapper.open(url, features);
   } else {
      windowWrapper.open(url);
   }
};

var isUrlAbsolute = function(url) {
   return url.indexOf('http://') === 0 || url.indexOf('https://') === 0;
};

var generateQueryStringWithExtraParam = function(key, value) {
   key = encodeURI(key);
   value = encodeURI(value);

   var windowLocation = windowWrapper.getLocation();
   var kvp = windowLocation.search.substr(1).split('&');

   var i = kvp.length;
   var x;
   while (i--) {
      x = kvp[i].split('=');

      if (x[0] === key) {
         x[1] = value;
         kvp[i] = x.join('=');
         break;
      }
   }

   if (i < 0) {
      kvp[kvp.length] = [key, value].join('=');
   }

   var query = kvp.join('&');
   if (query.indexOf('&') === 0) {
      query = query.substr(1);
   }

   return '?' + query;
};

var generateCurrentUrlWithExtraQueryParam = function(paramName, paramValue) {
   var search = generateQueryStringWithExtraParam(paramName, paramValue);
   var windowLocation = windowWrapper.getLocation();
   var port = windowLocation.port ? ':' + windowLocation.port : '';
   return windowLocation.protocol + '//' + windowLocation.hostname + port + windowLocation.pathname + search + windowLocation.hash;
};

var convertUrlTime = function(val) {
   if (isNaN(val)) {
      var hrs = 0;
      var min = 0;
      var sec = 0;
      var strTime = val.toLowerCase();
      // Regex patterns ----------------------------------------------------
      var hrsPattern = new RegExp('(\\d+[\h])', 'g'); //( 999h )
      var minPattern = new RegExp('(\\d+[\m])', 'g'); //( 999m )
      var secPattern = new RegExp('(\\d+[\s])', 'g'); //( 999s )
      var numPattern = new RegExp('\\d+', 'g'); // * numerals
      // Match regex Tests -------------------------------------------------
      var hrsTest = strTime.match(hrsPattern);
      var minTest = strTime.match(minPattern);
      var secTest = strTime.match(secPattern);
      var numTest = strTime.match(numPattern);

      if (secTest || minTest || hrsTest) {
         // assumes we want the 1st pattern encountered in each array is
         // the time we want to use.

         if (hrsTest) {
            // 3600 seconds in 1 hour
            hrs = Number(hrsTest[0].split('h').join('')) * 60 * 60;
         }

         if (minTest) {
            // 60 seconds in 1 minute
            min = Number(minTest[0].split('m').join('')) * 60;
         }

         if (secTest) {
            // 1 seconds = 1 second
            sec = Number(secTest[0].split('s').join(''));
         }
         // return the Time in seconds
         return Number(hrs + min + sec);
      } else if (numTest) {
         sec = Number(numTest[0]);
         // return the Time assuming seconds
         return sec;
      }
      // not valid time or all non-numeric characters
      return 0;
   }
   if (val < 0) {
      val = 0;
   }

   return val;
};

export default {
   openUrl: openUrl,
   isUrlAbsolute: isUrlAbsolute,
   generateCurrentUrlWithExtraQueryParam: generateCurrentUrlWithExtraQueryParam,
   convertUrlTime: convertUrlTime
};
