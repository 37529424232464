var preloadOptions = Object.freeze({
   none: 'none',
   metadata: 'metadata',
   auto: 'auto'
});

export var isValidPreloadOption = function(preloadOption) {
   return Object.keys(preloadOptions).some(function(option) {
      return preloadOption === preloadOptions[option];
   });
};

export default preloadOptions;
