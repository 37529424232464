import $ from 'jquery';
import Promise from 'promise-polyfill';
import {Xmp} from '@techsmith/tsc-media-extensions-js';
import log from '../../common/log';
import playerConfiguration from './player-configuration';
import urlService from '../services/url-service';
import CaptionService from '../services/caption-service';

/**
 * @memberof TSC
 * @class XmpModel
 * @classdesc Xmp model
 */
var XmpModel = function() {
   var _readyPromiseResolve;
   var _readyPromiseReject;
   var _readyPromise = new Promise(function(resolve, reject) {
      _readyPromiseResolve = resolve;
      _readyPromiseReject = reject;
   });
   var _xmpModel = Xmp.create('');
   var _maxAttemptTime = 5000;
   var _retryLimit = 2;
   var _timeBetweenRetries = 0;
   var _groupedScreenTextItems = null;
   var _captionDefaults = CaptionService.defaults();
   var _xmpAjaxPromise = null;
   var _captionFileAjaxPromiseList = [];

   var loadXmpWithRetry = function(xmpSrc, retrylimit, crossDomainRequest) {
      _xmpAjaxPromise = $.ajax({
         type: 'GET',
         url: xmpSrc,
         crossDomain: crossDomainRequest,
         dataType: 'text',
         timeout: _maxAttemptTime
      }).done(function(data) {
         if (retrylimit < _retryLimit) {
            log.logMessage('xmp: loadXMP() succeeded after ' + (_retryLimit - retrylimit) + ' failed attempts.');
         }
         _xmpAjaxPromise = null;
         parseXmp(data);
      }).fail(function() {
         _xmpAjaxPromise = null;
         if (retrylimit > 0) {
            setTimeout(function() {
               loadXmpWithRetry(xmpSrc, --retrylimit);
            }, _timeBetweenRetries, crossDomainRequest);
         } else {
            _readyPromiseReject('xmp: loadXMP() failed after ' + _retryLimit + ' retries.');
         }
      });
   };

   var loadXmp = function(xmpSrc, crossDomainRequest) {
      loadXmpWithRetry(xmpSrc, _retryLimit, crossDomainRequest);
   };

   var parseXmp = function(xmpXml) {
      try {
         _xmpModel = Xmp.create(xmpXml);
         _readyPromiseResolve();
      } catch (err) {
         console.warn('Smart Player XMP parsing error - ', err);
         _readyPromiseReject('xmp - failed to parse XMP string');
      }
   };

   var groupScreenTextByStartTime = function() {
      var itemStartTime = null;
      var groupIndex = -1;
      _groupedScreenTextItems = [];

      _xmpModel.screenTextTrack.markers.forEach(function(item) {
         if (item.startTime !== itemStartTime) {
            groupIndex++;
            itemStartTime = item.startTime;
            _groupedScreenTextItems[groupIndex] = {
               startTime: item.startTime,
               file: item.file,
               image: item.image,
               imageRectangle: item.imageRectangle,
               items: []
            };
         }
         _groupedScreenTextItems[groupIndex].items.push(item);
      });

      _groupedScreenTextItems.forEach(function(groupedItem) {
         groupedItem.text = groupedItem.items
            .map(function(item) {
               return item.name;
            })
            .reduce(function(previousItemName, currentItemName) {
               return previousItemName + ' ' + currentItemName;
            });
      });
   };

   var loadCaptionFile = function(src) {
      var captionAjaxPromise;
      return new Promise(function(resolve) {
         captionAjaxPromise = $.ajax({
            type: 'GET',
            url: src,
            dataType: 'text',
            timeout: _maxAttemptTime
         }).done(function(captionData) {
            captionAjaxPromise = null;
            resolve(captionData);
         }).fail(function() {
            console.warn('Failed to load captions file [' + src + ']');
            captionAjaxPromise = null;
            resolve();
         });

         _captionFileAjaxPromiseList.push(captionAjaxPromise);
      });
   };

   var importCaptionsFromFile = function(captionsSrcList) {
      var loadedCaptionData = {};
      return Promise.all(captionsSrcList.map(function(captionSrcItem) {
         return loadCaptionFile(captionSrcItem.src).then(function(captionData) {
            loadedCaptionData[captionSrcItem.languageIdentifier] = captionData;
         });
      })).then(function() {
         _xmpModel.captionController.clearCaptions();

         // import the caption data in the same order as configured
         captionsSrcList.forEach(function(captionSrcItem) {
            if (loadedCaptionData[captionSrcItem.languageIdentifier]) {
               _xmpModel.importCaptionsFromFile(captionSrcItem.src, loadedCaptionData[captionSrcItem.languageIdentifier], captionSrcItem.languageIdentifier);
            }
         });
      });
   };

   var abortPendingRequests = function() {
      _xmpAjaxPromise && _xmpAjaxPromise.abort();
      _captionFileAjaxPromiseList.forEach(function(captionAjaxPromise) {
         captionAjaxPromise && captionAjaxPromise.abort();
      });
   };

   var imageExistsForTocFromDescription = function() {
      return playerConfiguration.tocModelFromDescription && playerConfiguration.tocModelFromDescription.items.length && playerConfiguration.tocModelFromDescription.items[0].image;
   };

   return Object.defineProperties({
      load: loadXmp,
      parse: parseXmp,
      importCaptionsFromFile: importCaptionsFromFile,
      abortPendingRequests: abortPendingRequests
   }, {
      ready: {
         get: function() {
            return _readyPromise;
         }
      },
      hasAdvancedFeatures: {
         get: function() {
            return playerConfiguration.tocModelFromDescription || _xmpModel.containsToc || _xmpModel.containsCaptions || _xmpModel.containsHotspots || _xmpModel.containsQuiz;
         }
      },
      hasTocItems: {
         get: function() {
            return playerConfiguration.tocModelFromDescription || _xmpModel.containsToc;
         }
      },
      hasCaptionItems: {
         get: function() {
            return _xmpModel.containsCaptions;
         }
      },
      hasHotspotItems: {
         get: function() {
            return _xmpModel.containsHotspots;
         }
      },
      hasQuiz: {
         get: function() {
            return _xmpModel.containsQuiz;
         }
      },
      hasScreenTextItems: {
         get: function() {
            return _xmpModel.containsScreenText;
         }
      },
      hasSpeechTextItems: {
         get: function() {
            return _xmpModel.containsSpeechText;
         }
      },
      hasSearchableItems: {
         get: function() {
            var hasAnySearchableItems = _xmpModel.containsCaptions || _xmpModel.containsScreenText || _xmpModel.containsSpeechText;
            return playerConfiguration.tocModelFromDescription || _xmpModel.containsToc || hasAnySearchableItems && playerConfiguration.getIsSearchable();
         }
      },
      project: {
         get: function() {
            return _xmpModel.project;
         }
      },
      originId: {
         get: function() {
            return _xmpModel.originId;
         }
      },
      mediaDuration: {
         get: function() {
            return _xmpModel.duration.value;
         }
      },
      title: {
         get: function() {
            return _xmpModel.title;
         }
      },
      controllerParameters: {
         get: function() {
            return _xmpModel.controller && _xmpModel.controller.parameters ? _xmpModel.controller.parameters : [];
         }
      },
      tocImageSrc: {
         get: function() {
            return _xmpModel.containsToc ? _xmpModel.tableOfContentsTrack.image : null;
         }
      },
      tocCellLayoutType: {
         get: function() {
            return _xmpModel.containsToc ? _xmpModel.tableOfContentsTrack.cellLayout : null;
         }
      },
      tocItemArray: {
         get: function() {
            if (playerConfiguration.tocModelFromDescription) {
               return playerConfiguration.tocModelFromDescription.items;
            } else if (_xmpModel.containsToc) {
               return _xmpModel.tableOfContentsTrack.items;
            }
            return [];
         }
      },
      speechTextItemArray: {
         get: function() {
            return _xmpModel.containsSpeechText ? _xmpModel.speechTrack.markers : [];
         }
      },
      screenTextItemArray: {
         get: function() {
            if (_xmpModel.containsScreenText && _groupedScreenTextItems === null) {
               groupScreenTextByStartTime();
            }
            return _xmpModel.containsScreenText ? _groupedScreenTextItems : [];
         }
      },
      hotspotItemArray: {
         get: function() {
            return _xmpModel.containsHotspots ? _xmpModel.hotspotTrack.hotspots : [];
         }
      },
      quizGuid: {
         get: function() {
            return _xmpModel.containsQuiz ? _xmpModel.quizTrack.quizGuid : null;
         }
      },
      quizAuthoredEmail: {
         get: function() {
            return _xmpModel.containsQuiz ? _xmpModel.quizTrack.authoredEmail : null;
         }
      },
      quizRequireUserId: {
         get: function() {
            return _xmpModel.containsQuiz ? _xmpModel.quizTrack.requireUserId : null;
         }
      },
      quizLocale: {
         get: function() {
            return _xmpModel.containsQuiz ? _xmpModel.quizTrack.locale : null;
         }
      },
      quizReportMethod: {
         get: function() {
            return _xmpModel.containsQuiz ? _xmpModel.quizTrack.reportMethod : null;
         }
      },
      quizClientId: {
         get: function() {
            return _xmpModel.containsQuiz ? _xmpModel.quizTrack.clientId : null;
         }
      },
      quizHash: {
         get: function() {
            return _xmpModel.containsQuiz ? _xmpModel.quizTrack.quizHash : null;
         }
      },
      quizHideReplay: {
         get: function() {
            return _xmpModel.containsQuiz ? _xmpModel.quizTrack.hideReplay : null;
         }
      },
      quizAllowSkipQuiz: {
         get: function() {
            return _xmpModel.containsQuiz ? _xmpModel.quizTrack.allowSkipQuiz : null;
         }
      },
      quizParameters: {
         get: function() {
            return _xmpModel.containsQuiz && _xmpModel.quizTrack.parameters ? _xmpModel.quizTrack.parameters : [];
         }
      },
      quizQuestionSetArray: {
         get: function() {
            return _xmpModel.containsQuiz ? _xmpModel.quizTrack.questionSets : [];
         }
      },
      captionController: {
         get: function() {
            return _xmpModel.captionController;
         }
      },
      captionsHasMultipleLanguages: {
         get: function() {
            return _xmpModel.containsCaptions && _xmpModel.captionController.getAvailableLanguages().length > 1;
         }
      },
      captionItems: {
         get: function() {
            return _xmpModel.containsCaptions ? _xmpModel.captionController.activeCaptionTrack.captionCollection.captions : [];
         }
      },
      captionFontFamily: {
         get: function() {
            return _xmpModel.containsCaptions && _xmpModel.captionController.activeCaptionTrack.fontFamily !== undefined ? _xmpModel.captionController.activeCaptionTrack.fontFamily : _captionDefaults.captionFontFamily;
         }
      },
      captionFontSize: {
         get: function() {
            return _xmpModel.containsCaptions && _xmpModel.captionController.activeCaptionTrack.fontSize !== undefined ? _xmpModel.captionController.activeCaptionTrack.fontSize : _captionDefaults.captionFontSize;
         }
      },
      minCaptionFontSize: {
         get: function() {
            return _captionDefaults.captionFontSizeMin;
         }
      },
      maxCaptionFontSize: {
         get: function() {
            return _captionDefaults.captionFontSizeMax;
         }
      },
      captionBgOpacity: {
         get: function() {
            return _xmpModel.containsCaptions && _xmpModel.captionController.activeCaptionTrack.bgOpacity !== undefined ? _xmpModel.captionController.activeCaptionTrack.bgOpacity : _captionDefaults.captionBgColor.getAlpha();
         }
      },
      captionTextColor: {
         get: function() {
            return _xmpModel.containsCaptions && _xmpModel.captionController.activeCaptionTrack.fgHexColor !== undefined ? _xmpModel.captionController.activeCaptionTrack.fgHexColor : _captionDefaults.captionTextColor.toHexString();
         }
      },
      captionBgColor: {
         get: function() {
            return _xmpModel.containsCaptions && _xmpModel.captionController.activeCaptionTrack.bgColor !== undefined ? _xmpModel.captionController.activeCaptionTrack.bgColor : _captionDefaults.captionBgColor.toHexString();
         }
      },
      captionBg: {
         get: function() {
            return _xmpModel.containsCaptions && _xmpModel.captionController.activeCaptionTrack.bgRGBACssColor ? _xmpModel.captionController.activeCaptionTrack.bgRGBACssColor : _captionDefaults.captionBgColor.toRgbString();
         }
      },
      captionPosition: {
         get: function() {
            return _xmpModel.containsCaptions && _xmpModel.captionController.activeCaptionTrack.position !== undefined ? _xmpModel.captionController.activeCaptionTrack.position : _captionDefaults.captionPosition;
         }
      },
      captionBarHeight: {
         get: function() {
            return _xmpModel.containsCaptions && _xmpModel.captionController.activeCaptionTrack.barHeight !== undefined ? _xmpModel.captionController.activeCaptionTrack.barHeight : _captionDefaults.captionBarHeight;
         }
      },
      tocContainsThumbs: {
         get: function() {
            return imageExistsForTocFromDescription() || _xmpModel.containsTocThumbnails;
         }
      },
      tocThumbImageSrc: {
         get: function() {
            if (imageExistsForTocFromDescription()) {
               return playerConfiguration.tocModelFromDescription.items[0].image;
            } else if (playerConfiguration.getTOCSpriteSrc()) {
               return playerConfiguration.getTOCSpriteSrc();
            } else if (!urlService.isUrlAbsolute(_xmpModel.tocThumbnailImageSrc)) {
               return playerConfiguration.getContentBase() + playerConfiguration.getUnicodeSafeString(_xmpModel.tocThumbnailImageSrc);
            }
            return playerConfiguration.getUnicodeSafeString(_xmpModel.tocThumbnailImageSrc);
         }
      }
   });
};

export default {
   /**
    *  Factory method that returns a new XmpModel object.
    *  @function create
    *  @memberof TSC.XmpModel
    *  @static
    *  @return new XmpModel instance
    */
   create: XmpModel
};
