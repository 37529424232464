import events from '../constants/events';
import uuidGenerator from './uuid-generator';
import windowWrapper from '../../common/window-wrapper';
import performanceWrapper from './performance-wrapper';
import analyticsService from '../services/analytics-service';

var haveEnoughDataTimeoutInMilliseconds = 20000;
var EVENT_DELAY_TIME_MILLISECONDS = 100;
var EVENT_TIME_DIFF_TO_REMOVE_FROM_QUEUE = 200;

var readyStates = {
   HAVE_NOTHING: 0, // No information is available about the media resource.
   HAVE_METADATA: 1, // Enough of the media resource has been retrieved that the metadata attributes are initialized. Seeking will no longer raise an exception.
   HAVE_CURRENT_DATA: 2, // Data is available for the current playback position, but not enough to actually play more than one frame.
   HAVE_FUTURE_DATA: 3, // Data for the current playback position as well as for at least a little bit of time into the future is available (in other words, at least two frames of video, for example).
   HAVE_ENOUGH_DATA: 4 // Enough data is available—and the download rate is high enough—that the media can be played through to the end without interruption.
};

/**
 *  @memberof TSC
 *  @class VideoPlaybackQualityMetrics
 *  @classdesc Utility to monitor and report on how video is streaming
 *  @param {TSC.VideoElementView} videoElementView - Video element view
 *  @param {Function} triggerRumDataEventCallback - callback function for rum timing events
 *  @param {Function} eventLabelSuffixRetriever - function for retrieving event label suffix (optional)
 *  @param {TSC.playerState} playerState - player state
 */
var VideoPlaybackQualityMetrics = function(videoElementView, triggerRumDataEventCallback, eventLabelSuffixRetriever, playerState) {
   var _readyStateHasAtLeastEnoughDataOnce = false;
   var _readyStateHasEnoughDataAfterSeeked = false;
   var _timeToReachFirstHaveEnoughData = -1;
   var _videoLoadStartTimeInMilliseconds = -1;
   var _uniqueID = uuidGenerator.getUUID();
   var _mediaLocation = windowWrapper.getLocation();
   var _eventListeners = {};
   var _currentReadyState = null;
   var _isSeeking = false;
   var _playingEventShouldFireAfterStalled = false; // workaround for Safari annoyance
   var _mediaElement = videoElementView.mediaElement;
   var _eventLabel = null;
   var _queuedEvents = [];
   var _sendEventsDelayID = -1;

   eventLabelSuffixRetriever = eventLabelSuffixRetriever || function() {
      return '';
   };

   var setEventLabel = function(eventLabel) {
      _eventLabel = eventLabel;
   };

   var getEventLabel = function() {
      var id = _eventLabel ? _eventLabel : 'UUID_' + _uniqueID;
      return id + '__' + _mediaLocation;
   };

   var triggerShowVideoBufferingViewEvent = function() {
      _playingEventShouldFireAfterStalled = true;
      if (_eventListeners[events.Controls.ShowVideoBufferingView]) {
         _eventListeners[events.Controls.ShowVideoBufferingView]();
      }
   };

   var triggerHideVideoBufferingView = function() {
      _playingEventShouldFireAfterStalled = false;
      if (_eventListeners[events.Controls.HideVideoBufferingView]) {
         _eventListeners[events.Controls.HideVideoBufferingView]();
      }
   };

   var sendQueuedEvents = function() {
      _queuedEvents.forEach(function(queuedEvent) {
         analyticsService.sendEvent(queuedEvent.event.type, queuedEvent.event.label, queuedEvent.event.value);
         analyticsService.sendGA4Event(queuedEvent.ga4Event.eventName, queuedEvent.ga4Event.eventParams);
      });

      _queuedEvents = [];
   };

   var checkVideoReadyState = function(currentReadyState) {
      // workaround for Safari not dispatching 'playing' event as expected
      if (_playingEventShouldFireAfterStalled && !_mediaElement.paused && !_isSeeking && currentReadyState >= readyStates.HAVE_ENOUGH_DATA) {
         triggerHideVideoBufferingView();
      }

      if (currentReadyState === _currentReadyState) {
         return;
      }

      var previousReadyState = _currentReadyState;
      _currentReadyState = currentReadyState;

      if (previousReadyState === readyStates.HAVE_ENOUGH_DATA && _currentReadyState < readyStates.HAVE_FUTURE_DATA && !_mediaElement.ended && _readyStateHasEnoughDataAfterSeeked && !playerState.pausedForQuiz) {
         var eventLabel = getEventLabel();
         eventLabel += eventLabelSuffixRetriever();

         const eventTime = performance.now();

         _queuedEvents.push({
            time: eventTime,
            event: {
               type: events.Analytics.PlaybackWasStoppedToBuffer,
               label: eventLabel,
               value: 1
            },
            ga4Event: {
               eventName: events.GA4.PlaybackWasStoppedToBuffer,
               eventParams: {
                  video_session_id: eventLabel // eslint-disable-line
               }
            }
         });

         triggerShowVideoBufferingViewEvent();
      }

      if (_currentReadyState === readyStates.HAVE_ENOUGH_DATA) {
         if (_timeToReachFirstHaveEnoughData === -1) {
            performanceWrapper.createMeasure(events.Timing.VideoHasEnoughData, events.Timing.VideoHasEnoughData);
            triggerRumDataEventCallback([events.Timing.VideoHasEnoughData]);
            _timeToReachFirstHaveEnoughData = window.performance.now() - _videoLoadStartTimeInMilliseconds;
         }
         _readyStateHasAtLeastEnoughDataOnce = true;
         _readyStateHasEnoughDataAfterSeeked = true;
      }

      if (_sendEventsDelayID !== -1) {
         clearTimeout(_sendEventsDelayID);
      }
      _sendEventsDelayID = setTimeout(sendQueuedEvents, EVENT_DELAY_TIME_MILLISECONDS);
   };

   var onStalledEventHandler = function(e) {
      if (e.target && e.target.readyState !== undefined) {
         checkVideoReadyState(e.target.readyState);
      }
   };

   var onProgressEventHandler = function(e) {
      if (e.target && e.target.readyState !== undefined) {
         checkVideoReadyState(e.target.readyState);
      }
   };

   var removeStoppedToBufferEventIfNeeded = function() {
      var time = performance.now();

      for (var i = _queuedEvents.length - 1; i >= 0; i--) {
         if (time - _queuedEvents[i].time <= EVENT_TIME_DIFF_TO_REMOVE_FROM_QUEUE) {
            _queuedEvents.splice(i, 1);
         }
      }
   };

   var onVideoSeekingHandler = function(e) {
      removeStoppedToBufferEventIfNeeded();

      if (!_isSeeking) {
         _isSeeking = true;
         _readyStateHasEnoughDataAfterSeeked = false;

         triggerShowVideoBufferingViewEvent();

         if (e.target && e.target.readyState !== undefined) {
            checkVideoReadyState(e.target.readyState);
         }
      }
   };

   var onVideoSeekedHandler = function(e) {
      if (e.target && e.target.readyState !== undefined) {
         checkVideoReadyState(e.target.readyState);
      }

      triggerHideVideoBufferingView();

      _isSeeking = false;
   };

   var onVideoWaitingHandler = function(e) {
      if (e.target && e.target.readyState !== undefined) {
         checkVideoReadyState(e.target.readyState);
      }
   };

   var onVideoTimeUpdateHandler = function(e) {
      if (e.target && e.target.readyState !== undefined) {
         checkVideoReadyState(e.target.readyState);
      }
   };

   var onVideoLoadStartHandler = function() {
      performanceWrapper.createMarker(events.Timing.VideoMetadataLoaded);
      performanceWrapper.createMarker(events.Timing.VideoLoadedData);
      performanceWrapper.createMarker(events.Timing.VideoCanPlay);
      performanceWrapper.createMarker(events.Timing.VideoHasEnoughData);

      _videoLoadStartTimeInMilliseconds = window.performance.now();
      window.setTimeout(function() {
         if (!_readyStateHasAtLeastEnoughDataOnce) {
            analyticsService.sendEvent(events.Analytics.CouldNotPlaySmoothlyIn20Secs, getEventLabel());
            analyticsService.sendGA4Event(events.GA4.CouldNotPlaySmoothlyIn20Secs, {
               video_session_id: getEventLabel() // eslint-disable-line
            });
         }
      }, haveEnoughDataTimeoutInMilliseconds);
      _mediaElement.removeEventListener(events.ExternalVideo.VideoLoadStart, onVideoLoadStartHandler);
   };

   var onVideoMetadataLoadedHandler = function() {
      _mediaElement.removeEventListener(events.ExternalVideo.VideoLoadedMetadata, onVideoMetadataLoadedHandler);
      performanceWrapper.createMeasure(events.Timing.VideoMetadataLoaded, events.Timing.VideoMetadataLoaded);
      triggerRumDataEventCallback([events.Timing.VideoMetadataLoaded]);
   };

   var onVideoLoadedDataHandler = function() {
      _mediaElement.removeEventListener(events.ExternalVideo.VideoLoadedData, onVideoLoadedDataHandler);
      performanceWrapper.createMeasure(events.Timing.VideoLoadedData, events.Timing.VideoLoadedData);
      triggerRumDataEventCallback([events.Timing.VideoLoadedData]);
   };

   var onVideoCanPlayHandler = function() {
      _mediaElement.removeEventListener(events.ExternalVideo.VideoCanPlay, onVideoCanPlayHandler);
      performanceWrapper.createMeasure(events.Timing.VideoCanPlay, events.Timing.VideoCanPlay);
      triggerRumDataEventCallback([events.Timing.VideoCanPlay]);
   };

   var onVideoDurationChange = function() {
      removeStoppedToBufferEventIfNeeded();
      _currentReadyState = null;
      _readyStateHasAtLeastEnoughDataOnce = false;
      _readyStateHasEnoughDataAfterSeeked = false;
   };

   var onMediaPlayingEventHandler = function() {
      if (_isSeeking) {
         return;
      }
      triggerHideVideoBufferingView();
   };

   var addMediaEvents = function() {
      videoElementView.addEventListener(events.Controls.ShowVideoBufferingView, triggerShowVideoBufferingViewEvent);
      videoElementView.addEventListener(events.Controls.HideVideoBufferingView, triggerHideVideoBufferingView);
      _mediaElement.addEventListener(events.ExternalVideo.VideoLoadStart, onVideoLoadStartHandler);
      _mediaElement.addEventListener(events.ExternalVideo.VideoLoadedMetadata, onVideoMetadataLoadedHandler);
      _mediaElement.addEventListener(events.ExternalVideo.VideoLoadedData, onVideoLoadedDataHandler);
      _mediaElement.addEventListener(events.ExternalVideo.VideoCanPlay, onVideoCanPlayHandler);
      _mediaElement.addEventListener(events.ExternalVideo.VideoSeeking, onVideoSeekingHandler);
      _mediaElement.addEventListener(events.ExternalVideo.VideoSeeked, onVideoSeekedHandler);
      _mediaElement.addEventListener(events.ExternalVideo.VideoWaiting, onVideoWaitingHandler);
      _mediaElement.addEventListener(events.ExternalVideo.VideoTimeUpdate, onVideoTimeUpdateHandler);
      _mediaElement.addEventListener(events.ExternalVideo.VideoDurationChange, onVideoDurationChange);
      _mediaElement.addEventListener(events.Media.Progress, onProgressEventHandler);
      _mediaElement.addEventListener(events.Media.Stalled, onStalledEventHandler);
      _mediaElement.addEventListener(events.Media.Playing, onMediaPlayingEventHandler);
   };

   var addEventListener = function(eventName, eventHandler) {
      _eventListeners[eventName] = eventHandler;
   };

   var removeEventListener = function(eventName) {
      _eventListeners[eventName] = undefined;
   };

   var destroy = function() {
      videoElementView.removeEventListener(events.Controls.ShowVideoBufferingView);
      videoElementView.removeEventListener(events.Controls.HideVideoBufferingView);
      _mediaElement.removeEventListener(events.ExternalVideo.VideoLoadStart, onVideoLoadStartHandler);
      _mediaElement.removeEventListener(events.ExternalVideo.VideoLoadedMetadata, onVideoMetadataLoadedHandler);
      _mediaElement.removeEventListener(events.ExternalVideo.VideoLoadedData, onVideoLoadedDataHandler);
      _mediaElement.removeEventListener(events.ExternalVideo.VideoCanPlay, onVideoCanPlayHandler);
      _mediaElement.removeEventListener(events.ExternalVideo.VideoSeeking, onVideoSeekingHandler);
      _mediaElement.removeEventListener(events.ExternalVideo.VideoSeeked, onVideoSeekedHandler);
      _mediaElement.removeEventListener(events.ExternalVideo.VideoWaiting, onVideoWaitingHandler);
      _mediaElement.removeEventListener(events.ExternalVideo.VideoTimeUpdate, onVideoTimeUpdateHandler);
      _mediaElement.removeEventListener(events.ExternalVideo.VideoDurationChange, onVideoDurationChange);
      _mediaElement.removeEventListener(events.Media.Progress, onProgressEventHandler);
      _mediaElement.removeEventListener(events.Media.Stalled, onStalledEventHandler);
      _mediaElement.removeEventListener(events.Media.Playing, onMediaPlayingEventHandler);
   };

   addMediaEvents();

   return Object.defineProperties({
      addEventListener: addEventListener,
      removeEventListener: removeEventListener,
      setEventLabel: setEventLabel,
      destroy: destroy
   }, {});
};

/**
 *  Factory method that returns a new VideoPlaybackQualityMetrics object.
 *  @memberof TSC.VideoPlaybackQualityMetrics
 *  @function create
 *  @static
 *  @param {TSC.VideoElementView} videoElementView - Video element view
 *  @param {Function} triggerRumDataEventCallback - callback function for rum timing events
 *  @param {Function} eventLabelSuffixRetriever - function for retrieving event label suffix (optional)
 *  @param {TSC.playerState} playerState - player state
 *  @return new VideoPlaybackQualityMetrics instance
 */
export default {
   create: VideoPlaybackQualityMetrics
};
