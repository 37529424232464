var mediaInterfaceConstants = Object.freeze({
   controls: {
      true: 'true',
      false: 'false',
      autohide: 'autohide',
      youtube: 'youtube'
   }
});

export default mediaInterfaceConstants;
