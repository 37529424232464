import AnswersView from './answers-view';
import templateGenerator from '../utils/template-generator';
import playerStringNames from '../constants/player-string-names';
import localizationStrings from '../models/localization-strings';

var QUESTION_CORRECT_TEXT_CLASS = '.question-correct-text';
var QUESTION_INCORRECT_TEXT_CLASS = '.question-incorrect-text';

/**
 * @memberof TSC
 * @class QuestionView
 * @classdesc Question View
 * @params {Object} $questionTextContainer - jquery wrapper for question text container element
 * @params {Object} $questionGradedStatusContainer - jquery wrapper for question graded status container element
 * @params {Object} $feedbackContainer - jquery wrapper for feedback text container element
 * @params {Object} $answersContainer - jquery wrapper for answers container element
 * @params {TSC.Question} question - Question model for this view.
 * @params {Boolean} showResults - show results for question.
 */
var QuestionView = function($questionTextContainer, $questionGradedStatusContainer, $feedbackContainer, $answersContainer, question, showResults) {
   var _answersView;
   var _feedbackContextMarkup = templateGenerator.generateQuestionFeedbackContextMarkup(localizationStrings.getPlayerString(playerStringNames.accessQuestionFeedbackContext));

   var createView = function() {
      _answersView = AnswersView.create($answersContainer, question, showResults);
      $feedbackContainer.addClass('hide-element');

      var correctAnswerText = showResults ? _answersView.getCorrectAnswerText() : '';

      var questionTextMarkup = templateGenerator.generateQuestionTextMarkup(question.text, correctAnswerText);
      $questionTextContainer.html(questionTextMarkup);

      var questionGradedStatusMarkup = templateGenerator.generateQuestionGradedStatusMarkup();
      $questionGradedStatusContainer.html(questionGradedStatusMarkup);
   };

   var showFeedbackTextIfAvailable = function() {
      var feedbackMarkup = null;

      question.feedback.forEach(function(feedback) {
         if (question.isResponseCorrect && feedback.reason === 'correct') {
            feedbackMarkup = feedback.markupText;
         } else if (!question.isResponseCorrect && feedback.reason === 'incorrect') {
            feedbackMarkup = feedback.markupText;
         }
      });

      if (feedbackMarkup) {
         $feedbackContainer.html(_feedbackContextMarkup + feedbackMarkup);
         $feedbackContainer.removeClass('hide-element');
      }
   };

   var setupView = function() {
      if (showResults && question.isGraded) {
         showFeedbackTextIfAvailable();
         if (question.isResponseCorrect) {
            $questionGradedStatusContainer.find(QUESTION_CORRECT_TEXT_CLASS).removeClass('hide-element');
         } else {
            $questionGradedStatusContainer.find(QUESTION_INCORRECT_TEXT_CLASS).removeClass('hide-element');
         }
      }
   };

   createView();
   setupView();

   return {
      isTextInputOrAreaFocused: _answersView.isTextInputOrAreaFocused,
      destroy: _answersView.destroy,
      getUserResponse: _answersView.getUserResponse
   };
};

export default {
   /**
    *  Factory method that returns a new QuestionView object.
    *  @function create
    *  @memberof TSC.QuestionView
    *  @static
    *  @return new QuestionView instance
    */
   create: QuestionView
};
