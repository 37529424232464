const progressBarControlTemplate = ({progressIndex, accessBtnProgressBar}) => `<div class="plugin-container"></div>
<div class="progress-scrubbar-track" role="slider" tabindex="${progressIndex}" aria-label="${accessBtnProgressBar}">
   <div class="ui scrubbar-track"></div>
   <div class="markers"></div>
   <div class="scrub-preview">
      <div class="scrub-details">
         <canvas class="video-thumbnail"></canvas>
         <div class="hidden-video-thumbnail">
            <div class="hidden-glyph"></div>
         </div>
         <span class="chapter-name"></span>
         <span class="video-time"></span>
      </div>
   </div>
</div>`;

export default progressBarControlTemplate;
