import playerConfiguration from '../models/player-configuration';
import events from '../constants/events';
import supportedPluginViewElements from '../constants/supported-plugin-view-elements';

const enterKeyCode = 13;
const spacebarKeyCode = 32;

/**
 * @memberof TSC
 * @class PluginEventsController
 * @classdesc Controller for managing XMP features
 * @param {jQuery} $container - Smart Player main container jquery element
 *
 */
var PluginEventsController = function($container) {
   var _$mediaPluginContainer = $container.find('.media-plugin-container');
   _$mediaPluginContainer.hide();

   var onMediaPluginContainerKeyHandler = function(e) {
      if (e.type === 'keydown' && (e.keyCode === enterKeyCode || e.keyCode === spacebarKeyCode)) {
         $container.trigger(events.Plugin.MediaAreaPluginKeyDown);
      } else if (e.type === 'keyup' && (e.keyCode === enterKeyCode || e.keyCode === spacebarKeyCode)) {
         $container.trigger(events.Plugin.MediaAreaPluginKeyUp);
      }
   };

   var onMediaPluginContainerMouseHandler = function(e) {
      if (e.type === 'mousedown') {
         $container.trigger(events.Plugin.MediaAreaPluginMouseDown);
      } else if (e.type === 'mouseup') {
         $container.trigger(events.Plugin.MediaAreaPluginMouseUp);
      }
   };

   var initMediaPlugins = function() {
      var pluginController = playerConfiguration.getPluginController();
      if (!pluginController) {
         return;
      }
      _$mediaPluginContainer.show();
      pluginController.initializePluginsForViewElement(supportedPluginViewElements.MEDIA, _$mediaPluginContainer);
   };

   var onPluginAdded = function(eventName, data) {
      if (data.viewElementType === supportedPluginViewElements.MEDIA) {
         initMediaPlugins();
      }
   };

   var bindToEvents = function() {
      $container.on(events.Plugin.Added, onPluginAdded);
      _$mediaPluginContainer.on('mousedown', onMediaPluginContainerMouseHandler);
      _$mediaPluginContainer.on('mouseup', onMediaPluginContainerMouseHandler);
      _$mediaPluginContainer.on('keydown', onMediaPluginContainerKeyHandler);
      _$mediaPluginContainer.on('keyup', onMediaPluginContainerKeyHandler);
   };

   var unbindToEvents = function() {
      $container.off(events.Plugin.Added, onPluginAdded);
      _$mediaPluginContainer.off('mousedown', onMediaPluginContainerMouseHandler);
      _$mediaPluginContainer.off('mouseup', onMediaPluginContainerMouseHandler);
      _$mediaPluginContainer.off('keydown', onMediaPluginContainerKeyHandler);
      _$mediaPluginContainer.off('keyup', onMediaPluginContainerKeyHandler);
   };

   var destroy = function() {
      unbindToEvents();
   };

   bindToEvents();
   initMediaPlugins();

   return Object.defineProperties({
      destroy: destroy
   }, {
   });
};

export default {
   /**
    *  Factory method that returns a new PluginEventsController.
    *  @function create
    *  @memberof TSC.PluginEventsController
    *  @static
    *  @param {jQuery} $container - Smart Player main container jquery element
    *  @return new PluginEventsController instance
    */
   create: PluginEventsController
};
