var setItem = function(key, value) {
   try {
      window.sessionStorage.setItem(key, value);
   } catch (e) {
      // We want this to fail silently
   }
};

var getItem = function(key) {
   try {
      return window.sessionStorage.getItem(key);
   } catch (e) {
      return null;
   }
};

export default {
   /**
    *  Wraps native session storage setItem with a try/catch.
    *  @function setItem
    *  @memberof TSC.sessionStorageWrapper
    *  @static
    *  @param {String} key - the key of the value to set in session storage
    *  @param {String} value - the value to set in session storage
    */
   setItem: setItem,
   /**
    *  Wraps native session storage getItem.
    *  @function getItem
    *  @memberof TSC.sessionStorageWrapper
    *  @static
    *  @param {String} key - the key of the value to retrieve in session storage
    *  @returns {String} value from session storage (could be null or undefined)
    */
   getItem: getItem
};
