import $ from 'jquery';
import windowWrapper from '../../common/window-wrapper';
import cssClasses from '../constants/css-classes';
import events from '../constants/events';
import tabIndex from '../constants/tab-index';
import templateGenerator from '../utils/template-generator';
import deviceInfo from '../utils/device-info';
import hotkeyService from '../services/hotkey-service';
import keys from '../constants/keys';
import localizationStrings from '../models/localization-strings';
import playerStringNames from '../constants/player-string-names';

var REMOVE_SCREEN_READER_ALERT_TIME = 1000;

/**
 * @memberof TSC
 * @class TocControlView
 * @classdesc TOC Control View in player controls
 * @param {Object} $container - Smart Player root jquery element.
 * @param {TSC.playerConfiguration} playerConfiguration - Player configuration
 */
var TocControlView = function($container, playerConfiguration) {
   var _tocDisplayed = false;
   var _$tocButton;
   var _$tocGlyph;
   var _windowRef = windowWrapper.getWindow();

   var createView = function() {
      var tocButtonMarkup = templateGenerator.generateTocButtonMarkup();
      $container.find('.toc-button-wrapper').html(tocButtonMarkup);
      _$tocButton = $container.find('.toc-button');
      _$tocGlyph = $container.find('.toc-button-glyph');
   };

   var alertScreenReader = function(alertText) {
      var screenReaderAlertMarkup = templateGenerator.generateScreenReaderAlertMarkup(alertText);
      var $screenReaderAlert = $(screenReaderAlertMarkup);
      $container.append($screenReaderAlert);

      _windowRef.setTimeout(function() {
         $screenReaderAlert.remove();
      }, REMOVE_SCREEN_READER_ALERT_TIME);
   };

   var setTocDisplayState = function(displayToc) {
      _tocDisplayed = displayToc;

      if (_tocDisplayed) {
         _$tocButton.attr('aria-expanded', true);
         _$tocButton.removeClass(cssClasses.tertiaryButton).addClass(cssClasses.tertiaryButtonActive);
         // bug in IE11 that does not display SVG glyph when toggling SVG when enabling TOC on player load
         if (!(deviceInfo.isIE() && playerConfiguration.getSidebarEnabled())) {
            _$tocGlyph.addClass(cssClasses.tocButtonActiveGlyph);
         }
      } else {
         _$tocGlyph.removeClass(cssClasses.tocButtonActiveGlyph);
         _$tocButton.attr('aria-expanded', false);
         _$tocButton.removeClass(cssClasses.tertiaryButtonActive).addClass(cssClasses.tertiaryButton);
      }

      $container.trigger(events.Controls.TocDisplayToggled, {displayToc: _tocDisplayed});
   };

   var setInitialState = function() {
      setTocDisplayState(_tocDisplayed);
   };

   var onButtonClick = function(e) {
      e.preventDefault();
      setTocDisplayState(!_tocDisplayed);
   };

   var onKeyDownHandler = function(e) {
      if (!hotkeyService.areHotkeysEnabled()) {
         return;
      }

      var isTypingInSearchBox = e.target && e.target.classList && e.target.classList.contains('toc-search-input');
      if (isTypingInSearchBox) {
         return;
      }

      if (keys.isKeyOfType(e.key, keys.keyTypes.tableOfContents)) {
         if (_$tocButton.attr('tabindex') !== tabIndex.Disabled) {
            if (_$tocButton.is(':focus')) {
               alertScreenReader(_tocDisplayed ? localizationStrings.getPlayerString(playerStringNames.tableOfContentsHidden) : localizationStrings.getPlayerString(playerStringNames.tableOfContentsDisplayed));
            } else {
               _$tocButton.focus();
            }
            setTocDisplayState(!_tocDisplayed);
         }
      }
   };

   var disableControl = function() {
      _$tocButton.attr('tabindex', tabIndex.Disabled);
   };

   var enableControl = function() {
      _$tocButton.attr('tabindex', tabIndex.DomOrder);
   };

   var onHideToc = function() {
      setTocDisplayState(false);
   };

   var onShowToc = function() {
      setTocDisplayState(true);
   };

   var focusTocButton = function() {
      _$tocButton.focus();
   };

   var initializeHandlers = function() {
      _$tocButton.on('click', onButtonClick);
      $container.on('keydown', onKeyDownHandler);
      $container.on(events.Controls.Disable, disableControl);
      $container.on(events.Controls.Enable, enableControl);
      $container.on(events.Controls.FocusTocButton, focusTocButton);
      $container.on(events.Controls.HideToc, onHideToc);
      $container.on(events.Controls.ShowToc, onShowToc);
   };

   var destroy = function() {
      _$tocButton.off('click', onButtonClick);
      $container.off('keydown', onKeyDownHandler);
      $container.off(events.Controls.Disable, disableControl);
      $container.off(events.Controls.Enable, enableControl);
      $container.off(events.Controls.FocusTocButton, focusTocButton);
      $container.off(events.Controls.HideToc, onHideToc);
      $container.off(events.Controls.ShowToc, onShowToc);
   };

   createView();
   initializeHandlers();
   setInitialState();

   return Object.defineProperties({
      destroy: destroy
   }, {});
};

export default {
   /**
    *  Factory method that returns a new TocControlView object.
    *  @function create
    *  @memberof TSC.TocControlView
    *  @static
    *  @param {Object} $container - Smart Player root jquery element.
    *  @param {TSC.playerConfiguration} playerConfiguration - Player configuration
    *  @return new TocControlView instance
    */
   create: TocControlView
};
