import playerConfiguration from '../models/player-configuration';

var postMessage = function(eventName, domain, data) {
   var message = {};
   message.smartPlayerEventName = eventName;
   if (playerConfiguration.getEmbedIFrameId()) {
      message.iFrameId = playerConfiguration.getEmbedIFrameId();
   }
   if (data) {
      message.data = data;
   }
   window.top.postMessage(window.JSON.stringify(message), domain);
};

var isInIframe = function() {
   return window !== window.top;
};

export default {
   postMessage: postMessage,
   isInIframe: isInIframe
};
