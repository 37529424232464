// external smart player events (value casing matters to maintain backward capability)
var _videoStartInitiated = 'VIDEO_START_INITIATED';
var _videoStart = 'VIDEO_START';
var _mediaReady = 'MEDIA_READY';
var _captionChange = 'CAPTION_CHANGE';
var _ready = 'READY';
var _videoPlayEvent = 'VIDEO_PLAY';
var _playerFullScreenModeChanged = 'PLAYER_FULL_SCREEN_MODE_CHANGED';
var _playerRumData = 'PLAYER_RUM_DATA';
var _videoEnd = 'tscspVideoEnd';
var _videoReplay = 'tscspVideoReplay';
var _externalMediaError = 'tscspMediaError';
var _playerSettingsOpenEvent = 'tscspPlayerSettingsOpen';
var _playerSettingsCloseEvent = 'tscspPlayerSettingsClose';
var _playerControlsHiddenEvent = 'tscspPlayerControlsHiddenEvent';
var _playerControlsVisibleEvent = 'tscspPlayerControlsVisibleEvent';
var _externalPlayerResize = 'tscspPlayerResize';
var _heroControlHidden = 'tscspHeroControlHidden';
var _imagePointerOver = 'tscspImagePointerOver';
var _imagePointerLeave = 'tscspImagePointerLeave';
var _displayModeChanged = 'tscspDisplayModeChanged';
var _captionsHidden = 'tscspCaptionsHidden';
var _captionsVisible = 'tscspCaptionsVisible';
// this is the overload to set up a listener for the playerConfiguration.useCustomEventTracking related to analytics.  this event will fire the handler for several
// when they would normally trigger internally (i.e. 'Video_Started', 'Video_Replay', 'Video_Loading_Time', 'Video_Finished', others?)
// the event will trigger with the related event name and data formatted for older version google-analytics events
var _googleAnalyticsCustomEvent = 'gacustomevents'; // deprecated
var _analyticsEvent = 'analyticsEvent';
var _googleAnalytics4Event = 'ga4event';

// external smart player html5 events
// these events are bubbled up from the html5 video element and _most_ are simulated by the youtube player in our code
var _videoLoadStart = 'loadstart';
var _videoLoadedMetadata = 'loadedmetadata';
var _videoLoadedData = 'loadeddata';
var _externalVideoCanPlay = 'canplay';
var _videoCanPlayThrough = 'canplaythrough';
var _videoPlaying = 'playing';
var _videoWaiting = 'waiting';
var _videoSeeking = 'seeking';
var _videoSeeked = 'seeked';
var _videoEnded = 'ended';
var _videoPlay = 'play';
var _videoPause = 'pause';
var _videoResize = 'resize';
var _videoError = 'error';
var _videoVolumeChange = 'volumechange';
var _videoDurationChange = 'durationchange';
var _videoRateChange = 'ratechange';
var _videoTimeUpdate = 'timeupdate';
var _videoProgress = 'progress';
var _initializeCurrent = 'initializecurrent';
var _sourceChanged = 'sourcechanged';
var _tryToAutoPlay = 'tscspTryToAutoPlay';
var _firstPlay = 'tscspFirstPlayer';

var _videoSuspend = 'suspend';
var _videoAbort = 'abort';
var _videoEmptied = 'emptied';
var _videoStalled = 'stalled';

// Player Events
var _mediaControlsReady = 'tscspMediaControlsReady';
var _mediaExtensionsReady = 'tscspMediaExtensionsReady';

// Control Events
var _clickPrevControl = 'tscspClickPrevControl';
var _clickNextControl = 'tscspClickNextControl';
var _toggleFullScreen = 'tscspToggleFullScreen';
var _fullScreenChange = 'tscspFullScreenChange';
var _expandVideo = 'tscspExpandVideo';
var _showToc = 'tscspShowToc';
var _hideToc = 'tscspHideToc';
var _tocScroll = 'tscspTocScroll';
var _disableControls = 'tscspDisableControls';
var _enableControls = 'tscspEnableControls';
var _controlsUpdated = 'tscspControlsUpdated';
var _pipChanged = 'tscspPipChange';
var _theaterModeChanged = 'tscspTheaterModeChange';
var _playerResize = 'tscspPlayerResize';
var _mediaResize = 'tscspMediaResize';
var _controlsPlayerInitialized = 'tscspPlayerInitialized';
var _showVideoBufferingView = 'tscspShowVideoBufferingView';
var _hideVideoBufferingView = 'tscspHideVideoBufferingView';
var _switchToFullscreen = 'tscspSwitchToFullscreen';
var _exitFullscreen = 'tscspExitFullscreen';
var _videoControlsHidden = 'tscspVideoControlsHidden';
var _hideVideoControls = 'tscspHideVideoControls';
var _videoControlsVisible = 'tscspVideoControlsHVisible';
var _showVideoControls = 'tscspShowVideoControls';
var _displayCaptionControl = 'tscspDisplayCaptionControl';
var _displayTocControl = 'tscspDisplayTocControl';
var _tocDisplayToggled = 'tscspTocDisplayToggled';
var _focusTocButton = 'tscspFocusTocButton';
var _heroPlayControlClicked = 'tscspHeroPlayControlClicked';
var _heroPlayControlVisible = 'tscspHeroPlayControlVisible';
var _heroPlayControlHidden = 'tscspHeroPlayControlHidden';
var _replayControlVisible = 'tscspReplayControlVisible';
var _replayControlHidden = 'tscspReplayControlHidden';
var _startedPlayback = 'tscspStartedPlayback';
var _hideIOOverlay = 'tscspHideIOOverlay';
var _searchFocusChange = 'tscspSearchFocusChange';
var _hideLoadingMessage = 'tscspHideLoadingMessage';
var _playerSettingsOpen = 'tscspPlayerSettingsOpen';
var _playerSettingsClose = 'tscspPlayerSettingsClose';

// Media Element Events
var _seekMediaToTime = 'tscspSeekMediaToTime';
var _replayMedia = 'tscspReplayMedia';
var _endMedia = 'tscspEndMedia';
var _setVolume = 'tscspSetVolume';
var _setMutedState = 'tscspSetMutedState';
var _setPlaybackRate = 'tscspSetPlaybackRate';
var _mediaError = 'tscspMediaError';

// IFrame Events
var _enterFullFrame = 'ENTER-FULLFRAME';
var _exitFullFrame = 'EXIT-FULLFRAME';

// Update Events
var _updateQuizMarkers = 'tscspUpdateQuizMarkers';
var _updatePlayProgress = 'tscspUpdatePlayProgress';
var _updateLoadProgress = 'tscspUpdateLoadProgress';
var _updateNextQuestionPos = 'tscspUpdateNextQuestionXPos';

// Scrubbing Events
var _startedScrubbing = 'tscspStartedScrubbing';
var _endedScrubbing = 'tscspEndedScrubbing';

// Quiz Events
var _quizSetupComplete = 'tscspQuizSetupComplete';
var _quizPaused = 'tscspMediaPaused';
var _quizComplete = 'tscspQuizComplete';
var _quizReview = 'tscspQuizReview';
var _quizBegin = 'tscspQuizBegin';
var _quizBeginClicked = 'tscspQuizBeginClicked';
var _quizSkip = 'tscspQuizSkip';
var _quizSkipClicked = 'tscspSkipQuizClicked';
var _quizError = 'tscspQuizError';
var _quizStatus = 'tscspQuizStatus';
var _quizSubmittingInstance = 'tscspSubmittingInstance';
var _quizCreatedInstance = 'tscspCreatedInstance';
var _quizSubmitted = 'tscspQuizSubmitted';
var _quizShowSubmissionRetryMessage = 'tscspQuizShowSubmissionRetryMessage';
var _quizHideSubmissionRetryMessage = 'tscspQuizHideSubmissionRetryMessage';
var _quizHideLoadingMessage = 'tscspQuizHideLoadingMessage';
var _transitionImageQuizToAnswers = 'tscspTransitionImageQuizToAnswers';
var _hideQuiz = 'tscspHideQuiz';
var _showQuizBar = 'tscspShowQuizBar';
var _showStartQuizPrompt = 'tscspShowStartQuizPrompt';
var _showQuizSetup = 'tscspShowQuizSetup';
var _hideStartQuizPrompt = 'tscspHideStartQuizPrompt';
var _showQuestionSetView = 'tscspShowQuestionSetView';
var _closeQuestionSetView = 'tscspCloseQuestionSetView';
var _submitQuestionSet = 'tscspSubmitQuestionSet';
var _rationaleActionLocation = 'tscspRationaleActionLocation';
var _rationaleActionJumpToTime = 'tscspRationaleActionJumpToTime';
var _quizIsOutOfDate = 'tscspQuizIsOutOfDate';
var _quizBadDefinition = 'tscspQuizBadDefinition';
var _quizVisible = 'tscspQuizVisible';
var _quizHidden = 'tscspQuizHidden';
var _minimizeQuizUI = 'tscspMinimizeQuizUI';
var _maximizeQuizUI = 'tscspMaximizeQuizUI';
var _quizUIVisible = 'tscspQuizUIVisible';
var _quizUIHidden = 'tscspQuizUIHidden';

// Captions
var _captionsToggled = 'tscpCaptionsToggled';
var _stateLoaded = 'tscpCaptionsStateLoaded';
var _captionsChanged = 'tscspCaptionChanged';
var _captionsLanguageChanged = 'tscspLanguageChanged';
var _captionsDragStart = 'tscspCaptionsDragStart';
var _captionsDragEnd = 'tscspCaptionsDragEnd';
var _captionsResized = 'tscspCaptionsResized';

// Caption Service
var _newCaptionTrack = 'tscspNewCaptionTrack';

// Audio Description
var _audioDescriptionChanged = 'tscspAudioDescriptionChanged';
var _audioDescriptionFailed = 'tscspAudioDescriptionFailed';

// Hotspots
var _hotspotPaused = 'tscspHotspotPaused';
var _hotspotResume = 'tscspHotspotResume';
var _hotspotClicked = 'tscspHotspotClicked';
var _hotspotAreaActive = 'tscspHotspotAreaActive';
var _hotspotAreaInactive = 'tscspHotspotAreaInactive';

// Modals
var _modalOpen = 'tscspModalOpen';
var _modalClosed = 'tscspModalClosed';

//Timing
var _smartPlayerInitialized = 'smart_player_initialized';
var _smartPlayerLoaded = 'smart_player_loaded';
var _videoMetadataLoaded = 'video_metadata_loaded';
var _videoLoadedDataTiming = 'video_loaded_data';
var _videoCanPlay = 'video_can_play';
var _videoHasEnoughData = 'video_has_enough_data';

// Analytics
var _analyticsVideoStarted = 'Video_Started';
var _analyticsContentLoaded = 'Content_Loaded';
var _analyticsVideoPercentViewed = 'Video_Percent_Viewed';
var _analyticsVideoFinishedAction = 'Video_Finished_Action';
var _analyticsVideoFinished = 'Video_Finished';
var _analyticsVideoReplay = 'Video_Replay';
var _analyticsVideoLoadingTimeInSeconds = 'Video_Loading_Time_In_Seconds';
var _analyticsVideoLoadingTime = 'Video_Loading_Time';
var _analyticsOpenExternalLinkView = 'Open_External_Link_View';
var _analyticsOpenExternalLinkClick = 'Open_External_Link_Click';
var _analyticsHotspotAction = 'Hotspot_Action';
var _analyticsVideoCouldNotPlaySmoothlyIn20Secs = 'Could_Not_Play_Smoothly_In_20_Secs';
var _analyticsVideoPlaybackWasStoppedToBuffer = 'Video_Stopped_To_Buffer';

// GA4 Analytics
var _ga4VideoStarted = 'video_started';
var _ga4PlayerInitialized = 'player_initialized';
var _ga4VideoLoadingTimeInSeconds = 'video_loading_time_secs';
var _ga4VideoLoadingTime = 'video_loading_time';
var _ga4VideoPercentViewed = 'video_percent_viewed';
var _ga4VideoFinishedAction = 'video_finished_action';
var _ga4VideoFinished = 'video_finished';
var _ga4VideoReplay = 'video_replayed';
var _ga4OpenExternalLinkView = 'open_external_link_view';
var _ga4OpenExternalLinkClick = 'open_external_link_click';
var _ga4HotspotAction = 'hotspot_open_url_action';
var _ga4VideoCouldNotPlaySmoothlyIn20Secs = 'bad_playback_twenty_secs';
var _ga4VideoPlaybackWasStoppedToBuffer = 'video_stopped_to_buffer';

// Plugins
var _pluginAdded = 'pluginAdded';
var _mediaAreaPluginMouseDown = 'mediaAreaPluginMouseDown';
var _mediaAreaPluginMouseUp = 'mediaAreaPluginMouseUp';
var _mediaAreaPluginKeyDown = 'mediaAreaPluginKeyDown';
var _mediaAreaPluginKeyUp = 'mediaAreaPluginKeyUp';

// Network
var _retryingRequest = 'retryingRequest';

// Fathom
var _fathomGetComplete = 'GET_COMPLETE';
var _fathomError = 'ERROR';

// External link view
var _openUrlViewOpened = 'tscspExternalUrlViewOpen';
var _openUrlViewClosed = 'tscspExternalUrlViewClosed';
var _openUrlViewClicked = 'tscspExternalUrlViewClick';

// SCORM
var _exitScormSession = 'tscspExitScormSession';
var _exitScormSessionConfirmed = 'tscspExitScormSessionConfirmed';
var _exitScormSessionWithOutProgressConfirmed = 'tscspExitScormSessionWithOutProgressConfirmed';
var _resumeScormSession = 'tscspScormSession';
var _failedToFindApi = 'tscspScormFailedToFindApi';

// Images
var _imagesPointerOver = 'tscspImagesPointerOver';
var _imagesPointerLeave = 'tscspImagesPointerLeave';

var events = Object.freeze({
   External: {
      VideoStartInitiated: _videoStartInitiated,
      VideoStart: _videoStart,
      VideoEnd: _videoEnd,
      VideoReplay: _videoReplay,
      MediaError: _externalMediaError,
      MediaReady: _mediaReady,
      CaptionChange: _captionChange,
      Ready: _ready,
      VideoPlay: _videoPlayEvent,
      PlayerFullScreenModeChanged: _playerFullScreenModeChanged,
      PlayerRumData: _playerRumData,
      GoogleAnalyticsCustomEvent: _googleAnalyticsCustomEvent,
      AnalyticsEvent: _analyticsEvent,
      GoogleAnalytics4Event: _googleAnalytics4Event,
      QuizVisible: _quizVisible,
      QuizHidden: _quizHidden,
      ShowStartQuizPrompt: _showStartQuizPrompt,
      HideStartQuizPrompt: _hideStartQuizPrompt,
      SwitchToFullscreen: _switchToFullscreen,
      ExitFullscreen: _exitFullscreen,
      PlayerSettingsOpen: _playerSettingsOpenEvent,
      PlayerSettingsClose: _playerSettingsCloseEvent,
      PlayerControlsHidden: _playerControlsHiddenEvent,
      PlayerControlsVisible: _playerControlsVisibleEvent,
      PlayerResize: _externalPlayerResize,
      HeroControlHidden: _heroControlHidden,
      ImagePointerOver: _imagePointerOver,
      ImagePointerLeave: _imagePointerLeave,
      DisplayModeChanged: _displayModeChanged,
      CaptionsHidden: _captionsHidden,
      CaptionsVisible: _captionsVisible
   },
   ExternalVideo: {
      VideoLoadStart: _videoLoadStart,
      VideoLoadedMetadata: _videoLoadedMetadata,
      VideoLoadedData: _videoLoadedData,
      VideoCanPlay: _externalVideoCanPlay,
      VideoCanPlayThrough: _videoCanPlayThrough,
      VideoPlaying: _videoPlaying,
      VideoWaiting: _videoWaiting,
      VideoSeeking: _videoSeeking,
      VideoSeeked: _videoSeeked,
      VideoEnded: _videoEnded,
      VideoPlay: _videoPlay,
      VideoPause: _videoPause,
      VideoResize: _videoResize,
      VideoError: _videoError,
      VideoVolumeChange: _videoVolumeChange,
      VideoDurationChange: _videoDurationChange,
      VideoRateChange: _videoRateChange,
      VideoTimeUpdate: _videoTimeUpdate,
      VideoProgress: _videoProgress
   },
   Player: {
      MediaControlsReady: _mediaControlsReady,
      MediaExtensionsReady: _mediaExtensionsReady
   },
   Controls: {
      ClickPrev: _clickPrevControl,
      ClickNext: _clickNextControl,
      FullScreenChange: _fullScreenChange,
      ToggleFullScreen: _toggleFullScreen,
      ExpandVideo: _expandVideo,
      ShowToc: _showToc,
      HideToc: _hideToc,
      TocScroll: _tocScroll,
      Disable: _disableControls,
      Enable: _enableControls,
      Updated: _controlsUpdated,
      HotspotClicked: _hotspotClicked,
      PipChanged: _pipChanged,
      TheaterModeChanged: _theaterModeChanged,
      PlayerResize: _playerResize,
      MediaResize: _mediaResize,
      PlayerInitialized: _controlsPlayerInitialized,
      ShowVideoBufferingView: _showVideoBufferingView,
      HideVideoBufferingView: _hideVideoBufferingView,
      VideoControlsHidden: _videoControlsHidden,
      HideVideoControls: _hideVideoControls,
      VideoControlsVisible: _videoControlsVisible,
      ShowVideoControls: _showVideoControls,
      DisplayCaptionControl: _displayCaptionControl,
      DisplayTocControl: _displayTocControl,
      TocDisplayToggled: _tocDisplayToggled,
      FocusTocButton: _focusTocButton,
      HeroPlayControlClicked: _heroPlayControlClicked,
      HeroPlayControlVisible: _heroPlayControlVisible,
      HeroPlayControlHidden: _heroPlayControlHidden,
      ReplayControlVisible: _replayControlVisible,
      ReplayControlHidden: _replayControlHidden,
      StartedPlayback: _startedPlayback,
      HideIOOverlay: _hideIOOverlay,
      SearchFocusChange: _searchFocusChange,
      HideLoadingMessage: _hideLoadingMessage,
      PlayerSettingsOpen: _playerSettingsOpen,
      PlayerSettingsClose: _playerSettingsClose
   },
   Hotspots: {
      Paused: _hotspotPaused,
      Resume: _hotspotResume,
      AreaActive: _hotspotAreaActive,
      AreaInactive: _hotspotAreaInactive
   },
   Media: {
      Play: _videoPlay,
      Playing: _videoPlaying,
      Pause: _videoPause,
      SeekToTime: _seekMediaToTime,
      Replay: _replayMedia,
      End: _endMedia,
      Abort: _videoAbort,
      Suspend: _videoSuspend,
      Emptied: _videoEmptied,
      Stalled: _videoStalled,
      Progress: _videoProgress,
      InitializeCurrent: _initializeCurrent,
      SourceChanged: _sourceChanged,
      TryToAutoPlay: _tryToAutoPlay,
      SetVolume: _setVolume,
      SetMutedState: _setMutedState,
      SetPlaybackRate: _setPlaybackRate,
      FirstPlay: _firstPlay,
      Error: _mediaError
   },
   IFrame: {
      EnterFullFrame: _enterFullFrame,
      ExitFullFrame: _exitFullFrame
   },
   Update: {
      QuizMarkers: _updateQuizMarkers,
      PlayProgress: _updatePlayProgress,
      LoadProgress: _updateLoadProgress,
      NextQuestionPosition: _updateNextQuestionPos
   },
   Scrubbing: {
      Started: _startedScrubbing,
      Ended: _endedScrubbing
   },
   Quizzing: {
      QuizSetupComplete: _quizSetupComplete,
      Paused: _quizPaused,
      Complete: _quizComplete,
      Review: _quizReview,
      Begin: _quizBegin,
      BeginClicked: _quizBeginClicked,
      Skip: _quizSkip,
      SkipClicked: _quizSkipClicked,
      Error: _quizError,
      Status: _quizStatus,
      SubmittingInstance: _quizSubmittingInstance,
      CreatedInstance: _quizCreatedInstance,
      Submitted: _quizSubmitted,
      ShowSubmissionRetryMessage: _quizShowSubmissionRetryMessage,
      HideSubmissionRetryMessage: _quizHideSubmissionRetryMessage,
      HideLoadingMessage: _quizHideLoadingMessage,
      TransitionImageQuizToAnswers: _transitionImageQuizToAnswers,
      HideQuiz: _hideQuiz,
      ShowQuizBar: _showQuizBar,
      ShowStartQuizPrompt: _showStartQuizPrompt,
      ShowQuizSetup: _showQuizSetup,
      ShowQuestionSetView: _showQuestionSetView,
      CloseQuestionSetView: _closeQuestionSetView,
      SubmitQuestionSet: _submitQuestionSet,
      RationaleActionLocation: _rationaleActionLocation,
      RationaleActionJumpToTime: _rationaleActionJumpToTime,
      QuizIsOutOfDate: _quizIsOutOfDate,
      QuizBadDefinition: _quizBadDefinition,
      MinimizeUI: _minimizeQuizUI,
      MaximizeUI: _maximizeQuizUI,
      UIVisible: _quizUIVisible,
      UIHidden: _quizUIHidden
   },
   Captions: {
      Toggled: _captionsToggled,
      StateLoaded: _stateLoaded,
      Changed: _captionsChanged,
      LanguageChanged: _captionsLanguageChanged,
      DragStart: _captionsDragStart,
      DragEnd: _captionsDragEnd,
      Resized: _captionsResized
   },
   CaptionService: {
      NewCaptionTrack: _newCaptionTrack
   },
   AudioDescription: {
      Changed: _audioDescriptionChanged,
      Failed: _audioDescriptionFailed
   },
   Timing: {
      PlayerInitialized: _smartPlayerInitialized,
      PlayerLoaded: _smartPlayerLoaded,
      VideoMetadataLoaded: _videoMetadataLoaded,
      VideoLoadedData: _videoLoadedDataTiming,
      VideoCanPlay: _videoCanPlay,
      VideoHasEnoughData: _videoHasEnoughData
   },
   GA4: {
      PlayerInitialized: _ga4PlayerInitialized,
      VideoStarted: _ga4VideoStarted,
      VideoLoadingTimeInSeconds: _ga4VideoLoadingTimeInSeconds,
      VideoLoadingTime: _ga4VideoLoadingTime,
      VideoPercentViewed: _ga4VideoPercentViewed,
      VideoFinishedAction: _ga4VideoFinishedAction,
      VideoFinished: _ga4VideoFinished,
      VideoReplay: _ga4VideoReplay,
      OpenExternalLinkView: _ga4OpenExternalLinkView,
      OpenExternalLinkClick: _ga4OpenExternalLinkClick,
      HotspotAction: _ga4HotspotAction,
      CouldNotPlaySmoothlyIn20Secs: _ga4VideoCouldNotPlaySmoothlyIn20Secs,
      PlaybackWasStoppedToBuffer: _ga4VideoPlaybackWasStoppedToBuffer
   },
   Analytics: {
      VideoStarted: _analyticsVideoStarted,
      ContentLoaded: _analyticsContentLoaded,
      VideoPercentViewed: _analyticsVideoPercentViewed,
      VideoFinishedAction: _analyticsVideoFinishedAction,
      VideoFinished: _analyticsVideoFinished,
      VideoReplay: _analyticsVideoReplay,
      VideoLoadingTimeInSeconds: _analyticsVideoLoadingTimeInSeconds,
      VideoLoadingTime: _analyticsVideoLoadingTime,
      OpenExternalLinkView: _analyticsOpenExternalLinkView,
      OpenExternalLinkClick: _analyticsOpenExternalLinkClick,
      HotspotAction: _analyticsHotspotAction,
      CouldNotPlaySmoothlyIn20Secs: _analyticsVideoCouldNotPlaySmoothlyIn20Secs,
      PlaybackWasStoppedToBuffer: _analyticsVideoPlaybackWasStoppedToBuffer
   },
   Plugin: {
      Added: _pluginAdded,
      MediaAreaPluginMouseDown: _mediaAreaPluginMouseDown,
      MediaAreaPluginMouseUp: _mediaAreaPluginMouseUp,
      MediaAreaPluginKeyDown: _mediaAreaPluginKeyDown,
      MediaAreaPluginKeyUp: _mediaAreaPluginKeyUp
   },
   Network: {
      Retrying: _retryingRequest
   },
   Fathom: {
      GetComplete: _fathomGetComplete,
      Error: _fathomError
   },
   Modals: {
      Open: _modalOpen,
      Closed: _modalClosed
   },
   ExternalLinkView: {
      Open: _openUrlViewOpened,
      Closed: _openUrlViewClosed,
      Clicked: _openUrlViewClicked
   },
   SCORM: {
      ExitSession: _exitScormSession,
      ExitSessionConfirmed: _exitScormSessionConfirmed,
      ExitSessionWithOutProgressConfirmed: _exitScormSessionWithOutProgressConfirmed,
      ResumeSession: _resumeScormSession,
      FailedToFindApi: _failedToFindApi
   },
   Images: {
      PointerOver: _imagesPointerOver,
      PointerLeave: _imagesPointerLeave
   }
});

export default events;
