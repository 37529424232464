import {ImageRectangle, TableOfContentsItem, TableOfContentsTrack} from '@techsmith/tsc-media-extensions-js';
import timeFormatter from './time-formatter';
import playerStringNames from '../constants/player-string-names';
import localizationStrings from '../models/localization-strings';
import playerConfiguration from '../models/player-configuration';
import {getThumbnailRectangleByTime} from './video-thumbnails-utils';

const timeCodeRegex = /^((0?[0-9]:)?[0-5]?[0-9]):([0-5][0-9]) /;
const newlineRegex = /\r?\n/g;
const milliseconds = 1000;
const defaultStartTime = 0;
const defaultChapterName = localizationStrings.getPlayerString(playerStringNames.defaultFirstChapterName);

const getChapterNameFromLineString = (lineString) => {
   return lineString.indexOf(' ') === -1 ? defaultChapterName : lineString.substring(lineString.indexOf(' '), lineString.length).trim();
};

const createTocItem = (chapterName, startTime) => {
   const chapterParams = {
      name: chapterName,
      startTime: startTime
   };

   if (playerConfiguration.videoThumbnailsSrc) {
      chapterParams.image = playerConfiguration.videoThumbnailsSrc;
      const rect = getThumbnailRectangleByTime(startTime / milliseconds,
         playerConfiguration.videoThumbnailInterval,
         playerConfiguration.videoThumbnailColumns,
         playerConfiguration.videoThumbnailWidth,
         playerConfiguration.videoThumbnailHeight);

      chapterParams.imageRectangle = ImageRectangle.create({
         x: rect.x,
         y: rect.y,
         width: rect.width,
         height: rect.height
      });
   }

   return TableOfContentsItem.create(chapterParams);
};

const parseTableOfContentsItemFromString = (lineString) => {
   const timecodes = lineString.match(timeCodeRegex);
   if (timecodes) {
      const chapterName = getChapterNameFromLineString(lineString);
      const chapterStartTime = timeFormatter.convertTimecodeToMilliseconds(timecodes[0]);
      return createTocItem(chapterName, chapterStartTime);
   }
};

/**
 *  Utility function will take a block of text as a string and parse it looking for chapter information to convert to a TOC Model.
 *  @function textToTocModel
 *  @param {String} textString - A string of text to search to see if it contains chapter information to convert to a TOC model.
 *  @returns {TableOfContentsTrack} Table of Contents Track object from TSC media extensions lib.
 */
const textToTocModel = (textString) => {
   if (!textString || typeof textString !== 'string') {
      return null;
   }
   const textSplitNewlines = textString.split(newlineRegex);
   const chapterItems = [];
   let previousTocItemStartTime = -1;

   textSplitNewlines.forEach((textLine) => {
      if (timeCodeRegex.test(textLine)) {
         const tocItem = parseTableOfContentsItemFromString(textLine);
         if (tocItem && tocItem.startTime > previousTocItemStartTime) {
            previousTocItemStartTime = tocItem.startTime;
            chapterItems.push(tocItem);
         }
      }
   });

   // insert a default chapter at time 0 if the input did not specify one
   if (chapterItems.length && chapterItems[0].startTime !== 0) {
      chapterItems.unshift(createTocItem(defaultChapterName, defaultStartTime));
   }

   return chapterItems.length ? TableOfContentsTrack.create({items: chapterItems}) : null;
};

export {textToTocModel};
