import externalMessageController from '../controllers/external-message-controller';
import playerConfiguration from '../models/player-configuration';
import iFrameBridge from '../services/iframe-bridge';
import windowWrapper from '../../common/window-wrapper';

var parsePlayerURLParams = function(queryString) {
   var playerUrlParams = queryString.slice(queryString.indexOf('?') + 1).split('&');
   if (playerUrlParams && playerUrlParams.length > 0) {
      playerConfiguration.saveConfigurationFromQueryString(playerUrlParams);
   }
};

var requestEmbeddedURLParams = function() {
   externalMessageController.addCallback('PARENT-URL-PARAMS', parseEmbeddedURLParams);
   iFrameBridge.postMessage('GET-EMBEDDED-URL-VARS', '*');
};

var parseEmbeddedURLParams = function(data) {
   externalMessageController.removeCallback('PARENT-URL-PARAMS');
   parsePlayerURLParams(data);
};

export default {
   strip: function() {
      parsePlayerURLParams(windowWrapper.getLocationHref());
      if (iFrameBridge.isInIframe()) {
         requestEmbeddedURLParams();
      }
   }
};
