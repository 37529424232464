import $ from 'jquery';
import Promise from 'promise-polyfill';
import log from '../../common/log';
import BackoffStrategyFactory from './backoff-strategy-factory';
import statusCodes from '../constants/status-codes';
import events from '../constants/events';
import uuidGenerator from '../utils/uuid-generator';

$.support.cors = true;

var REQUEST_TIMEOUT = 10000;
var NO_RETRY_STATUS_CODES = [
   statusCodes.UnprocessableEntity
];

var HttpService = function(retryStrategyFactory, logFailures) {
   retryStrategyFactory = retryStrategyFactory || BackoffStrategyFactory.create();

   if (typeof logFailures === 'undefined') {
      logFailures = true;
   }

   var _eventHandlers = {};
   var _pendingRequestPromises = {};

   var removePendingRequestPromise = function(requestPromise) {
      if (requestPromise.UUID) {
         delete _pendingRequestPromises[requestPromise.UUID];
      }
   };

   var addRequestToPendingList = function(requestPromise) {
      var requestUUID = uuidGenerator.getUUID();
      requestPromise.UUID = requestUUID;
      _pendingRequestPromises[requestUUID] = requestPromise;
   };

   var get = function(url) {
      var request = {
         url: url,
         type: 'GET',
         contentType: 'application/json',
         crossDomain: true,
         timeout: REQUEST_TIMEOUT
      };

      var requestPromise = $.ajax(request);
      addRequestToPendingList(requestPromise);

      return Promise.resolve(requestPromise).then(function(data) {
         removePendingRequestPromise(requestPromise);
         return data;
      }).catch(function(response) {
         removePendingRequestPromise(requestPromise);
         if (logFailures && response && response.statusText !== 'timeout') {
            var errorString = '';
            try {
               errorString = 'Error: ' + window.JSON.stringify(response, null, 2) + ' Url: ' + url;
            } catch (error) {
               errorString = '(response failed conversion into a JSON string.)';
            }

            log.logException(errorString);
            return Promise.reject(errorString);
         }
      });
   };

   var sendFormDataPost = function(url, data) {
      return sendRequest(url, $.param(data), 'application/x-www-form-urlencoded; charset=UTF-8', 'POST');
   };

   var canRetryFromStatusCode = function(response) {
      if (!response) {
         return true;
      }

      for (var i = 0; i < NO_RETRY_STATUS_CODES.length; i++) {
         if (response.status === NO_RETRY_STATUS_CODES[i]) {
            return false;
         }
      }
      return true;
   };

   var sendRequest = function(url, data, contentType, httpVerb) {
      var retryStrategy = retryStrategyFactory.create();

      var request = {
         url: url,
         type: httpVerb,
         data: data,
         contentType: contentType,
         crossDomain: true,
         timeout: REQUEST_TIMEOUT
      };

      var send = function(retries) {
         return new Promise(function(resolve, reject) {
            var requestPromise = $.ajax(request);
            addRequestToPendingList(requestPromise);

            Promise.resolve(requestPromise).then(function(responseData) {
               removePendingRequestPromise(requestPromise);
               resolve(responseData);
            }).catch(function(response) {
               removePendingRequestPromise(requestPromise);
               if (logFailures && response && response.statusText !== 'timeout') {
                  var errorString = '';
                  try {
                     errorString = 'Error: ' + window.JSON.stringify(response, null, 2) + ' Url: ' + url + ' Data: ' + window.JSON.stringify(data, null, 2);
                  } catch (error) {
                     errorString = '(response failed conversion into a JSON string.)';
                  }

                  log.logException(errorString);
               }

               if (canRetryFromStatusCode(response)) {
                  retryStrategy.backoff().then(function() {
                     retries++;

                     if (_eventHandlers[events.Network.Retrying]) {
                        _eventHandlers[events.Network.Retrying].forEach(function(eventCallback) {
                           eventCallback(retries);
                        });
                     }

                     send(retries).then(resolve).catch(reject);
                  }).catch(function(retryResponse) {
                     log.logException('Call to ' + url + ' failed after ' + retries + ' retries.');
                     reject(retryResponse);
                  });
               } else {
                  log.logException('Call ' + url + ' failed with no retry status of ' + response.status + '.');
                  reject(response);
               }
            });
         });
      };

      return send(0);
   };

   var abortPendingRequests = function() {
      Object.keys(_pendingRequestPromises).forEach(function(requestPromise) {
         requestPromise && requestPromise.abort && requestPromise.abort();
      });
      _pendingRequestPromises = {};
   };

   var addEventListener = function(eventName, eventCallback) {
      if (!_eventHandlers[eventName]) {
         _eventHandlers[eventName] = [];
      }
      _eventHandlers[eventName].push(eventCallback);
   };

   var removeEventListener = function(eventName, eventCallback) {
      if (!_eventHandlers[eventName]) {
         return;
      }

      for (var i = _eventHandlers[eventName].length - 1; i >= 0; i--) {
         if (_eventHandlers[eventName][i] === eventCallback) {
            _eventHandlers[eventName].splice(i, 1);
            break;
         }
      }

      if (_eventHandlers[eventName].length === 0) {
         delete _eventHandlers[eventName];
      }
   };

   return {
      get: get,
      sendFormDataPost: sendFormDataPost,
      abortPendingRequests: abortPendingRequests,
      addEventListener: addEventListener,
      removeEventListener: removeEventListener
   };
};

export default {
   create: HttpService
};
