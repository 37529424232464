import $ from 'jquery';
import Promise from 'promise-polyfill';
import playerConfiguration from '../models/player-configuration';
import playedTracker from '../shims/played-tracker-shim';
import youtubeIdParser from '../utils/youtube-id-parser';

var addYouTubeApiReadyHandler = function() {
   if (!window.onYouTubeIframeAPIReady) {
      window.onYouTubeIframeAPIReady = function() {
         window.onYouTubeIframeAPIReady.ready = true;
         if (Array.isArray(window.onYouTubeIframeAPIReady.queued)) {
            for (var i = 0; i < window.onYouTubeIframeAPIReady.queued.length; i++) {
               if (typeof window.onYouTubeIframeAPIReady.queued[i] === 'function') {
                  window.onYouTubeIframeAPIReady.queued[i]();
               } else {
                  console.warn('window.onYouTubeIframeAPIReady.queued[' + i + '] is not a function');
               }
            }
         } else {
            console.warn('window.onYouTubeIframeAPIReady.queued is not an array');
         }
      };

      window.onYouTubeIframeAPIReady.queued = [];
   }
};

var youTubeApiUri = 'https://www.youtube.com/iframe_api';
var _playbackReadyPromiseResolve;
var _playbackReadyPromise = new Promise(function(resolve) {
   _playbackReadyPromiseResolve = resolve;
});

var YouTubePlayer = function(videoUrl, iFrameId, onFirstPlay, onError, playerWidth, playerHeight, startTimeInSeconds, disableFullScreen) {
   var that = this;
   //Keep local variables to minimize cross-iframe communication
   var events = {};
   var currentTime = 0;
   var duration = 0;
   var loadedPercent = 0;
   var playbackRate = 1;
   var playerState = -1;
   var supportPlaybackRate = false;
   var volume = 0.5;
   var muted = false;
   var availableRateSettings = [];
   var ended = false;
   var displayAnnotations = 3; //3 indicates that annotations should be hidden
   var videoId = youtubeIdParser.getIdFromUrl(videoUrl);
   var heartbeatIntervalId = null;

   if (typeof startTimeInSeconds !== 'number') {
      startTimeInSeconds = 0;
   }

   that.playbackReadyPromise = _playbackReadyPromise;

   this.addEventListener = function(eventName, handler) {
      events[eventName] = events[eventName] || [];
      events[eventName].push(handler);
   };

   this.removeEventListener = function(eventName, handler) {
      if (!events[eventName] || events[eventName].length === 0) {
         return;
      }
      var handlerIndex = events[eventName].indexOf(handler);
      if (handlerIndex !== -1) {
         events[eventName].splice(handlerIndex, 1);
      }
   };

   this.bind = function(arg1, arg2) {
      if (typeof arg1 === 'string') {
         this.addEventListener(arg1, arg2);
      } else if (typeof arg1 === 'object') {
         var keys = Object.keys(arg1);
         for (var i = 0; i < keys.length; i++) {
            var key = keys[i];
            that.addEventListener(key, arg1[key]);
         }
      }
   };

   this.on = this.bind;

   this.unbind = function(eventName, handler) {
      if (typeof handler !== 'undefined') {
         this.removeEventListener(eventName, handler);
      } else {
         events[eventName] = [];
      }
   };

   this.off = this.unbind;

   var errorHandler = function(event) {
      switch (event.data) {
         case 2:
            //The watch URL is probably wrong - missing/too many characters, or invalid characters
            dispatchEvent('error', 'Invalid video url.');//
            break;
         case 5:
            //The internal HTML5_VIDEO player cannot play the content
            dispatchEvent('error', 'The video cannot be played.');
            break;
         case 100:
            //Video has been removed or marked private
            dispatchEvent('error', 'This video has been removed from YouTube and is no longer available for playback.');
            break;
         case 101:
         case 150:
            //The video has embedding disabled
            dispatchEvent('error', 'This video is unavailable.  Its YouTube owner either disabled embedding or marked it private.');
            break;
         default:
         //Unknown video error?
      }
   };

   if (onError) {
      this.addEventListener('error', onError);
   }

   var firstPlay = function() {
      duration = player.getDuration();
      dispatchEvent('durationchange');

      if (onFirstPlay) {
         onFirstPlay();
      }
      dispatchEvent('loadedmetadata');
      dispatchEvent('loadeddata');
      dispatchEvent('canplay');
      //Ensure these events get fired in the proper order the first time, especially for mobile case
      that.removeEventListener('playing', firstPlay);
      dispatchEvent('playing');
      dispatchEvent('play');
   };

   var onStateChange = function(event) {
      playerState = event.data;
      switch (event.data) {
         case window.YT.PlayerState.PAUSED:
            dispatchEvent('pause');
            break;
         case window.YT.PlayerState.ENDED:
            ended = true;
            dispatchEvent('ended');
            break;
         case window.YT.PlayerState.PLAYING:
            dispatchEvent('playing');
            dispatchEvent('play');
            break;
         case window.YT.PlayerState.BUFFERING:
            dispatchEvent('waiting');
            break;
      }
   };

   var onPlaybackRateChange = function(event) {
      playbackRate = event.data;
      dispatchEvent('ratechange', playbackRate);
   };

   var removeFullScreenAttributes = function() {
      var playerIFrame = $('#' + iFrameId);
      var fullScreenAttributes = ['allowfullscreen', 'webkitAllowFullScreen', 'mozallowfullscreen'];
      fullScreenAttributes.forEach(function(attr) {
         playerIFrame.removeAttr(attr);
      });
   };

   var readyHandler = function() {
      dispatchEvent('loadstart');

      if (disableFullScreen) {
         removeFullScreenAttributes();
      }

      availableRateSettings = player.getAvailablePlaybackRates();
      if (availableRateSettings.length > 1) {
         supportPlaybackRate = true;
      }

      that.addEventListener('playing', firstPlay);
      _playbackReadyPromiseResolve();
   };

   if (playerConfiguration.getShowYouTubeAnnotations()) {
      displayAnnotations = 1;
   }

   /* eslint camelcase:'off' */
   var player = new window.YT.Player(iFrameId, {
      width: playerWidth,
      height: playerHeight,
      playerVars: {
         wmode: 'opaque', //Fix for overlays when iframe is hosting flash content
         loop: 0,
         autoplay: 0,
         controls: playerConfiguration.getDisableYouTubeControls() ? 0 : 1,
         disablekb: playerConfiguration.getDisableYouTubeControls() ? 1 : 0,
         rel: 0,
         showsearch: 0,
         showinfo: 0,
         iv_load_policy: displayAnnotations,
         playsinline: 1,
         fs: disableFullScreen ? 0 : 1,
         modestbranding: 1
      },
      events: {
         onReady: readyHandler,
         onStateChange: onStateChange,
         onPlaybackRateChange: onPlaybackRateChange,
         onError: errorHandler
      }
   });

   var dispatchEvent = function(eventName, arg) {
      var event = {
         type: eventName,
         target: that,
         data: arg
      };

      if (events.hasOwnProperty(eventName)) {
         var eventHandlers = events[eventName].slice(0);
         for (var i = 0; i < eventHandlers.length; i++) {
            eventHandlers[i](event);
         }
      }
   };

   var _playedTracker = new playedTracker(this);

   //As the name suggests, this is mocking the behavior of the html5 spec 'loaded' parameter
   var loadedMock = function() {
      return {
         start: function() {
            return 0;
         },
         end: function() {
            return duration * loadedPercent;
         },
         length: 1
      };
   };

   Object.defineProperty(this, 'buffered', {
      get: loadedMock
   });

   Object.defineProperty(this, 'currentSrc', {
      get: function() {
         return videoUrl;
      }
   });

   Object.defineProperty(this, 'currentTime', {
      get: function() {
         return player.getCurrentTime();
      },
      set: function(value) {
         dispatchEvent('seeking');

         if (+value > that.buffered.end()) {
            player.seekTo(+value, true);
         } else {
            player.seekTo(+value);
         }

         dispatchEvent('seeked');
      }
   });

   Object.defineProperty(this, 'duration', {
      get: function() {
         return duration;
      }
   });

   Object.defineProperty(this, 'paused', {
      get: function() {
         return playerState === window.YT.PlayerState.PAUSED;
      }
   });

   Object.defineProperty(this, 'playbackRate', {
      get: function() {
         return playbackRate;
      },
      set: function(value) {
         if (player.setPlaybackRate) {
            player.setPlaybackRate(+value);
         }
         //Don't set internal value here - let the emitted event update it
      }
   });

   Object.defineProperty(this, 'played', {
      get: function() {
         return _playedTracker;
      }
   });

   Object.defineProperty(this, 'ended', {
      get: function() {
         return ended;
      }
   });

   Object.defineProperty(this, 'seekable', {
      get: function() {
         return {
            start: function() {
               return 0;
            },
            end: function() {
               return duration;
            },
            length: 1
         };
      }
   });

   Object.defineProperty(this, 'supportPlaybackRate', {
      get: function() {
         return supportPlaybackRate;
      }
   });

   Object.defineProperty(this, 'validRateSettings', {
      get: function() {
         return availableRateSettings;
      }
   });

   Object.defineProperty(this, 'volume', {
      get: function() {
         return volume;
      },
      set: function(value) {
         //Youtube player decided to let volume be 1-100 instead of html5 spec 0-1
         var scaledValue = value * 100;
         player.setVolume && player.setVolume(scaledValue);
         volume = value;
         dispatchEvent('volumechange', null);
      }
   });

   Object.defineProperty(this, 'muted', {
      get: function() {
         return muted;
      },
      set: function(value) {
         if (muted !== value) {
            muted = value;
            dispatchEvent('volumechange', null);
         }

         if (value) {
            player.mute && player.mute();
         } else {
            player.unMute && player.unMute();
         }
      }
   });

   Object.defineProperty(this, 'height', {
      get: function() {
         return playerHeight;
      }
   });

   Object.defineProperty(this, 'width', {
      get: function() {
         return playerWidth;
      }
   });

   Object.defineProperty(this, 'playbackReadyPromise', {
      get: function() {
         return _playbackReadyPromise;
      }
   });

   this.play = function() {
      player.playVideo();
   };

   this.pause = function() {
      player.pauseVideo();
   };

   this.attr = function(property, value) {
      var domPlayer = document.getElementById(iFrameId);
      domPlayer.setAttribute(property, value);
   };

   this.load = function() {
      _playbackReadyPromise.then(function() {
         player.cueVideoById(videoId, startTimeInSeconds);
      });
   };

   this.destroy = function() {
      if (player) {
         player.destroy();
         window.onYouTubeIframeAPIReady = null;
      }
      if (heartbeatIntervalId) {
         clearInterval(heartbeatIntervalId);
      }
   };

   var heartbeat = function() {
      if (typeof player.getCurrentTime === 'undefined') {
         return;
      }

      playerState = player.getPlayerState();
      var time = player.getCurrentTime();
      if (currentTime !== time) {
         currentTime = time;
         dispatchEvent('timeupdate', time);
      }
      var videoLoadedFraction = player.getVideoLoadedFraction;
      if (typeof videoLoadedFraction === 'undefined' || videoLoadedFraction === null) {
         return;
      }
      var percentLoaded = player.getVideoLoadedFraction();

      if (loadedPercent !== percentLoaded) {
         loadedPercent = percentLoaded;
         dispatchEvent('progress');
         // not really sure on this, but if it's at 99%, should be able to play through...
         if (loadedPercent > 0.99) {
            dispatchEvent('canplaythrough');
         }
      }
   };
   heartbeatIntervalId = setInterval(heartbeat, 100);
};


var addApi = function() {
   addYouTubeApiReadyHandler();
   var youTubeApiFound = $("script[src='" + youTubeApiUri + "']").length;
   if (!youTubeApiFound) {
      $.getScript(youTubeApiUri);
   }
   return !youTubeApiFound;
};

export default {
   create: YouTubePlayer,
   addApi: addApi
};
