import $ from 'jquery';
import cssClasses from '../constants/css-classes';
import cssVariables from '../constants/css-variables';
import events from '../constants/events';

/**
 * @memberof TSC
 * @class responsiveController
 * @classdesc Control responsive classes and css variables on player container and media container
 * @param {jQuery} $rootContainer - Smart Player main container jquery element
 *
 */
var ResponsiveController = function($rootContainer) {
   var _$rootContainer = $rootContainer;
   var _$mediaViewPortContainer = null;
   var _$mediaSizingContainer = null;
   var _currentViewPortWidth = 0;
   var _currentViewPortHeight = 0;
   var _currentViewPortAspectRatio = 1.0;
   var _intrinsicWidth = 0;
   var _intrinsicHeight = 0;
   var _intrinsicAspectRatio = 1.0;

   var _rootContainerResizeObserver = null;
   var _viewPortContainerResizeObserver = null;
   var _initialCheck = true;

   var isApproximatelySameSize = function(newDimensionDouble, existingCssVarName) {
      // not pretty, but... at least chrome will trigger size change on partial pixel calculation changes, even if it lands in the same pixel, due to
      // compounding javascript rounding errors that happen in continued calculations of aspect-ratio, this can cause a subtle, slow run-away down/up-sizing
      // so, we compare the existing value from CSS var, parseInt to clip off the 'px' and get to number, and do an === compare from there.  if the whole numbers
      // really haven't changed, we just return early exit where this func is used to prevent run-away size changes
      return Math.round(newDimensionDouble) === Math.round(parseFloat(_$rootContainer[0].style.getPropertyValue(existingCssVarName), 10));
   };

   _rootContainerResizeObserver = new ResizeObserver(() => {
      if (!_initialCheck && isApproximatelySameSize(_$rootContainer.width(), cssVariables.playerWidth) && isApproximatelySameSize(_$rootContainer.height(), cssVariables.playerHeight)) {
         return;
      }

      _initialCheck = false;
      manageResponsiveClasses();
      _$rootContainer[0].style.setProperty(cssVariables.playerWidth, `${_$rootContainer.width()}px`);
      _$rootContainer[0].style.setProperty(cssVariables.playerHeight, `${_$rootContainer.height()}px`);
      _$rootContainer.trigger(events.Controls.PlayerResize);
   });

   _viewPortContainerResizeObserver = new ResizeObserver(() => {
      calculateMediaViewPortDimensions();
      setCssVariables(_$rootContainer[0]);
      _$rootContainer.trigger(events.Controls.MediaResize);
   });

   var _responsiveBreakpoints = Object.freeze({
      'small-mobile': {
         width: 1,
         height: 1
      },
      'medium-mobile': {
         width: 250,
         height: 250
      },
      'small-desktop': {
         width: 400,
         height: 400
      },
      'medium-desktop': {
         width: 510,
         height: 510
      }
   });

   var initializeIntrinsicSizing = function(intrinsicWidth, intrinsicHeight) {
      if (_intrinsicWidth === 0 && _intrinsicHeight === 0) {
         _intrinsicWidth = intrinsicWidth;
         _intrinsicHeight = intrinsicHeight;
         _intrinsicAspectRatio = intrinsicWidth / intrinsicHeight;
      }

      calculateMediaViewPortDimensions();
      setCssVariables($rootContainer[0]);
   };

   var monitorContainerSize = function() {
      _rootContainerResizeObserver.observe(_$rootContainer[0]);
   };

   var calculateMediaViewPortDimensions = function() {
      if (!_$mediaViewPortContainer || _intrinsicWidth === 0 || _intrinsicHeight === 0) {
         return;
      }

      const viewPortWidth = _$mediaViewPortContainer.width();
      const viewPortHeight = _$mediaViewPortContainer.height();

      if (viewPortWidth === 0 || viewPortHeight === 0) {
         return;
      }

      if (isApproximatelySameSize(viewPortWidth, cssVariables.mediaViewPortWidth) && isApproximatelySameSize(viewPortHeight, cssVariables.mediaViewPortHeight)) {
         return;
      }

      _currentViewPortWidth = viewPortWidth;
      _currentViewPortHeight = viewPortHeight;
      _currentViewPortAspectRatio = _currentViewPortWidth / _currentViewPortHeight;

      // note: i _hate_ that we have to resort to this, i fought for many days trying to get our browser support vector
      // to use just plain css to do these things:
      // 1) expand the 'media-container' to the max width/height available based on the native media and viewport size
      // 2) reduce the 'media-container' to the necessary width/height available based on the native media and viewport size
      // 3) preserve aspect-ratio of the native media (ideally by just using the intrinsic dimensions of the video tag itself)
      // 4) put a container around that native media that shrinks height _AND_ width to the size of the auto-sized media
      //    - this container can then contain the hotspots, plugins, other to allow them to autosize to 100%/100% absolute
      // even with several layers of divs, i could not achieve this and am reduced to managing aspect-ratio-driven calculations
      // this final solution manages some responsive classes and allows CSS and css-variables to do the calculations
      // but ideally we could use flex and/or grid to just allow media to flex shrink/grow, preserve aspect ratio, and contain a
      // wrapping container that shrinks to the size of the media auto-sized media. (JMD: 2022-07)
      if (_currentViewPortAspectRatio < _intrinsicAspectRatio) {
      // width == 100%, add constrain-height
         _$mediaSizingContainer.addClass(cssClasses.constrainMediaHeight);
         _$mediaSizingContainer.removeClass(cssClasses.constrainMediaWidth);
      } else {
      // height == 100%, add constrain-width
         _$mediaSizingContainer.addClass(cssClasses.constrainMediaWidth);
         _$mediaSizingContainer.removeClass(cssClasses.constrainMediaHeight);
      }
   };

   var setCssVariables = function(domContainer) {
      domContainer.style.setProperty(cssVariables.mediaIntrinsicWidth, `${_intrinsicWidth}px`);
      domContainer.style.setProperty(cssVariables.mediaIntrinsicHeight, `${_intrinsicHeight}px`);
      domContainer.style.setProperty(cssVariables.mediaIntrinsicAspectRatio, `${_intrinsicWidth} / ${_intrinsicHeight}`); // we dont round this because intrinsic media data should always come in as whole number dimensions
      domContainer.style.setProperty(cssVariables.mediaIntrinsicAspectRatioNumber, `${_intrinsicAspectRatio}`);
      if (_currentViewPortWidth !== 0 && _currentViewPortHeight !== 0) {
         domContainer.style.setProperty(cssVariables.mediaViewPortWidth, `${_currentViewPortWidth}px`);
         domContainer.style.setProperty(cssVariables.mediaViewPortHeight, `${_currentViewPortHeight}px`);
         domContainer.style.setProperty(cssVariables.mediaViewPortAspectRatio, `${Math.round(_currentViewPortWidth)} / ${Math.round(_currentViewPortHeight)}`); // round this, because it can come in as partial-pixel, the valid aspect-ratio for css are whole numbers
         domContainer.style.setProperty(cssVariables.mediaViewPortAspectRatioNumber, `${_currentViewPortAspectRatio}`);
      }
   };

   var monitorMediaViewPortSize = function($mediaViewPortContainer, $mediaSizingContainer, intrinsicWidth, intrinsicHeight) {
      _$mediaViewPortContainer = $mediaViewPortContainer;
      _$mediaSizingContainer = $mediaSizingContainer;

      if (intrinsicWidth > 0 && intrinsicHeight > 0) {
         _intrinsicWidth = intrinsicWidth;
         _intrinsicHeight = intrinsicHeight;
         _intrinsicAspectRatio = intrinsicHeight ? intrinsicWidth / intrinsicHeight : 1; // guard for div0 even though it really shouldnt happen
      }

      calculateMediaViewPortDimensions();
      setCssVariables(_$rootContainer[0]);

      _viewPortContainerResizeObserver.observe(_$mediaViewPortContainer[0]);
   };


   var manageResponsiveClasses = function() {
      if (!_$rootContainer) {
         return;
      }

      const containerWidth = _$rootContainer.width();
      const containerHeight = _$rootContainer.height();

      $.each(_responsiveBreakpoints, function(key, breakpoint) {
         var widthClassName = [key, 'width'].join('-');
         var heightClassName = [key, 'height'].join('-');

         if (breakpoint.width && containerWidth > breakpoint.width) {
            _$rootContainer.addClass(widthClassName);
         } else {
            _$rootContainer.removeClass(widthClassName);
         }
         if (breakpoint.height && containerHeight > breakpoint.height) {
            _$rootContainer.addClass(heightClassName);
         } else {
            _$rootContainer.removeClass(heightClassName);
         }
      });
   };

   var clearContainerMonitors = function() {
      _rootContainerResizeObserver.disconnect();
      _viewPortContainerResizeObserver.disconnect();

      // not resetting the $_rootContainer, _$mediaViewPortContainer or _$mediaSizingContainer because afaik, they persist for
      // the lifetime of the player?  if monitorMediaViewPortSize is called again, they will be re-established anyway
      _currentViewPortWidth = 0;
      _currentViewPortHeight = 0;
      _currentViewPortAspectRatio = 1.0;
      _intrinsicWidth = 0;
      _intrinsicHeight = 0;
      _intrinsicAspectRatio = 1.0;
   };

   return Object.defineProperties({
      /**
       *  Monitor the root player element for a change in size
       *  @function monitorContainerSize
       *  @memberof TSC.ResponsiveController
       *  @static
       */
      monitorContainerSize: monitorContainerSize,
      /**
       *  Monitor the viewport element for a change in size
       *  @function monitorMediaViewPortSize
       *  @param {JQuery} $mediaViewPortContainer - jquery element to monitor that is the media viewport
       *  @param {JQuery} $mediaSizingContainer - jquery element to resize based on aspect-ratio of media vs size of current viewport
       *  @param {number} intrinsicWidth - the current media intrinsic width.
       *  @param {number} intrinsicHeight - the current media intrinsic height.
       *  @memberof TSC.ResponsiveController
       *  @static
       */
      monitorMediaViewPortSize: monitorMediaViewPortSize,
      /**
       *  Configure some intrinsic media sizing hints for the media from data or configuration before media has been loaded.
       *  @function initializeIntrinsicSizing
       *  @param {number} intrinsicWidth - the current media intrinsic width.
       *  @param {number} intrinsicHeight - the current media intrinsic height.
       *  @memberof TSC.ResponsiveController
       *  @static
       */
      initializeIntrinsicSizing: initializeIntrinsicSizing,
      /**
       *  Stop monitoring the root player element for a change in size
       *  @function clearContainerMonitors
       *  @memberof TSC.ResponsiveController
       *  @static
       */
      clearContainerMonitors: clearContainerMonitors
   }, {
      responsiveBreakpoints: {
         get: function() {
            return _responsiveBreakpoints;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.ResponsiveController
       *  @property {number} currentMediaWidth - the current width of the media
       */
      currentMediaWidth: {
         get: function() {
            return _$mediaSizingContainer.width();
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.ResponsiveController
       *  @property {number} currentMediaHeight - the current height of the media
       */
      currentMediaHeight: {
         get: function() {
            return _$mediaSizingContainer.height();
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.ResponsiveController
       *  @property {number} intrinsicMediaWidth - the intrinsic (native) width of the media
       */
      intrinsicMediaWidth: {
         get: function() {
            return _intrinsicWidth;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.ResponsiveController
       *  @property {number} intrinsicMediaHeight - the intrinsic (native) height of the media
       */
      intrinsicMediaHeight: {
         get: function() {
            return _intrinsicHeight;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.ResponsiveController
       *  @property {number} intrinsicMediaAspectRatio - the intrinsic (native) aspect-ratio of the media
       */
      intrinsicMediaAspectRatio: {
         get: function() {
            return _intrinsicAspectRatio;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.ResponsiveController
       *  @property {number} currentViewPortWidth - the current width of the viewport
       */
      currentViewPortWidth: {
         get: function() {
            return _currentViewPortWidth;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.ResponsiveController
       *  @property {number} currentViewPortHeight - the current height of the viewport
       */
      currentViewPortHeight: {
         get: function() {
            return _currentViewPortHeight;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.ResponsiveController
       *  @property {number} currentViewPortAspectRatio - the current aspect-ratio of the viewport
       */
      currentViewPortAspectRatio: {
         get: function() {
            return _currentViewPortAspectRatio;
         }
      }
   });
};

export default {
   /**
    *  Factory method that returns a new ResponsiveController.
    *  @function create
    *  @memberof TSC.ResponsiveController
    *  @static
    *  @param {jQuery} $rootContainer - Smart Player main container jquery element
    *  @return new ResponsiveController instance
    */
   create: ResponsiveController
};

