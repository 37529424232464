import $ from 'jquery';
import BubbleView from './bubble-view';
import highlightDisabler from '../utils/highlight-disabler';
import templateGenerator from '../utils/template-generator';

var _superView = BubbleView.create('.review-confirmation-container');
var _containerId = '.controls-container';
var _continueControlId = '.continue-video-button';
var _reviewAnswersControlId = '.review-answers-button';

export default {
   getViewMarkup: function(numberCorrectText, percentCorrectText, numberInCorrectText, percentInCorrectText, continueText, reviewText) {
      return templateGenerator.generateQuestionSetReviewConfirmationMarkup(numberCorrectText, percentCorrectText, numberInCorrectText, percentInCorrectText, continueText, reviewText);
   },
   getContinueControlRef: function() {
      return _continueControlId;
   },
   getReviewControlRef: function() {
      return _reviewAnswersControlId;
   },
   hideView: function() {
      _superView.hideView();
   },
   showView: function() {
      _superView.showView(true);
      var $reviewControl = $(_containerId).find(_reviewAnswersControlId);
      $reviewControl.focus();
      highlightDisabler.disableHighlights($reviewControl);
   },
   removeView: _superView.removeView,
   updateViewPosition: _superView.updateViewPosition,
   setBottomOffset: _superView.setBottomOffset,
   pointAt: _superView.pointAt
};
