var switchToFullScreenMode = function(elementToFullScreen) {
   if (elementToFullScreen.requestFullscreen) {
      elementToFullScreen.requestFullscreen();
   } else if (elementToFullScreen.webkitRequestFullscreen) {
      elementToFullScreen.webkitRequestFullscreen();
   } else if (elementToFullScreen.webkitEnterFullscreen) {
      // legacy iOS full screen api
      elementToFullScreen.webkitEnterFullscreen();
   }
};

var exitFullscreenMode = function(fullScreenElement) {
   if (window.document.exitFullscreen) {
      window.document.exitFullscreen();
   } else if (window.document.webkitExitFullscreen) {
      window.document.webkitExitFullscreen();
   } else if (fullScreenElement && fullScreenElement.webkitExitFullscreen) {
      // legacy iOS full screen api
      fullScreenElement.webkitExitFullscreen();
   }
};

export default {
   switchToFullscreenMode: switchToFullScreenMode,
   exitFullscreenMode: exitFullscreenMode
};
