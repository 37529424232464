import documentWrapper from '../../common/document-wrapper';

var ENTER_PIP_EVENT = 'enterpictureinpicture';
var LEAVE_PIP_EVENT = 'leavepictureinpicture';

/**
 * @memberof TSC
 * @class StandardsPipController
 * @classdesc Controller to interface with standards PIP api.
 * @param {Object} mediaElement - html5 media DOM element (or wrapper presenting same interface)
 */
var StandardsPipController = function(mediaElement) {
   var documentRef = documentWrapper.getDocument();

   var enter = function() {
      mediaElement.requestPictureInPicture();
   };

   var exit = function() {
      documentRef.exitPictureInPicture();
   };

   var bindChangeEvent = function(onChangeHandler) {
      mediaElement.addEventListener(ENTER_PIP_EVENT, onChangeHandler);
      mediaElement.addEventListener(LEAVE_PIP_EVENT, onChangeHandler);
   };

   var unbindChangeEvent = function(onChangeHandler) {
      mediaElement.removeEventListener(ENTER_PIP_EVENT, onChangeHandler);
      mediaElement.removeEventListener(LEAVE_PIP_EVENT, onChangeHandler);
   };

   return Object.defineProperties({
      enter: enter,
      exit: exit,
      bindChangeEvent: bindChangeEvent,
      unbindChangeEvent: unbindChangeEvent
   }, {
      inPip: {
         get: function() {
            return mediaElement === documentRef.pictureInPictureElement;
         }
      },
      isSupported: {
         get: function() {
            return true;
         }
      }
   });
};

var isControllerSupported = function() {
   var documentRef = documentWrapper.getDocument();
   return 'pictureInPictureEnabled' in documentRef && documentRef.pictureInPictureEnabled;
};

export default {
   /**
    *  Factory method that returns a new StandardsPipController object
    *  @function create
    *  @memberof TSC.StandardsPipController
    *  @param {Object} mediaElement - html5 media DOM element (or wrapper presenting same interface)
    *  @static
    *  @return new StandardsPipController instance
    */
   create: StandardsPipController,
   /**
    *  Check to see if StandardsPipController is supported for platform being run on
    *  @function isControllerSupported
    *  @memberof TSC.StandardsPipController
    *  @static
    *  @return {Boolean} is controller supported on platform
    */
   isControllerSupported: isControllerSupported
};
