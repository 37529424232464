import $ from 'jquery';
import Promise from 'promise-polyfill';
import playerStringNames from '../constants/player-string-names';
import localizationStrings from '../models/localization-strings';
import playerConfiguration from '../models/player-configuration';
import deviceInfo from '../utils/device-info';
import searchTool from '../utils/search-tool';
import events from '../constants/events';
import keys from '../constants/keys';
import tabIndex from '../constants/tab-index';
import textSanitizer from '../utils/text-sanitizer';
import hotkeyService from '../services/hotkey-service';
import templateGenerator from '../utils/template-generator';
import viewHelper from '../utils/view-helper';
import cssVariables from '../constants/css-variables';

var TOC_WIDTH = 250;
var TOC_MAX_HEIGHT = 80;
var POINTER_MOVE_DETECTED_PIXEL_DELTA = 2;

/**
 * @memberof TSC
 * @class TocView
 * @classdesc Table of Contents View
 * @param {JQuery}} $container - jQuery top-level smartplayer container
 * @param {TSC.Xmp} xmpModel - Xmp Model
 */
var TocView = function($container, xmpModel) {
   var _viewVisible = false;
   var _viewIsOpen = false;
   var _searchFocus = false;
   var _scrolledToc = false;
   var _eventHandlers = {};
   var _keysEnabled = true;

   var _inputSearchClassName = '.toc-search-input';
   var _$tocHeadContainer = null;
   var _$searchContainer = null;
   var _$searchInput = null;
   var _$titleHeader = null;
   var _$clearSearchButton = null;
   var _$tocPipImageContainer = null;
   var _$searchInputDisabled = null;
   var _$tocContentScrollContainer = null;
   var _$tocContent = null;
   var _$tocContentScreenReaderLabel = null;
   var _$tocItemDomList = null;
   var _$tocContainer = null;
   var _$noSearchResultsAlert = null;

   var _xmp = xmpModel;

   var _tocThumbsImage = null;
   var _tocPipImage = null;

   var _pointerDownClientPoint = {
      x: 0,
      y: 0
   };

   var createView = function() {
      _$tocContainer = $container.find('.toc-container');

      var tocMarkup = templateGenerator.generateTocMarkup();
      _$tocContainer.html(tocMarkup);

      _$tocHeadContainer = _$tocContainer.find('.toc-head-container');
      _$searchInput = _$tocContainer.find(_inputSearchClassName);
      _$titleHeader = _$tocContainer.find('.toc-media-title');
      _$searchContainer = _$tocContainer.find('.toc-search-container');
      _$clearSearchButton = _$tocContainer.find('.clear-search-button');
      _$tocPipImageContainer = _$tocContainer.find('.toc-pip-image-container');
      _$searchInputDisabled = _$tocContainer.find('.toc-full-screen-search-alert');
      _$tocContentScrollContainer = _$tocContainer.find('.toc-content-scroll-container');
      _$tocContent = _$tocContainer.find('.toc-content-container');
      _$tocContentScreenReaderLabel = _$tocContainer.find('#tocContentScreenReaderLabel');
      _$noSearchResultsAlert = $('<div class="sr-only alert-only" role="alert">' + localizationStrings.getPlayerString(playerStringNames.accessListNoSearchResults) + '</div>');
   };

   var tocContainsActiveElement = function() {
      var activeElement = viewHelper.getActiveElement();
      return activeElement && (_$tocContainer[0] === activeElement || $.contains(_$tocContainer[0], activeElement));
   };

   var showView = function(animationSpeed) {
      if (animationSpeed) {
         _$tocContainer.stop(true, true).fadeIn(animationSpeed);
      } else {
         _$tocContainer.show();
      }
      _viewVisible = true;
   };

   var hideView = function(animationSpeed) {
      if (animationSpeed) {
         _$tocContainer.stop(true, true).fadeOut(animationSpeed);
      } else {
         _$tocContainer.hide();
      }
      _viewVisible = false;
   };

   var openView = function() {
      _viewIsOpen = true;
      showView();
      resizeView();
   };

   var closeView = function() {
      _viewIsOpen = false;
      hideView();
   };

   var blurSearchInput = function() {
      _$searchInput.blur();
   };

   var resizeView = function() {
      var headerHeight = _$tocHeadContainer.height();
      var containerHeight = $container.height();
      var playerControlHeight = $container.find('.video-controls-container').height();
      var tocTopPosition = containerHeight - playerControlHeight;
      $container[0].style.setProperty(cssVariables.tocTopOffset, `-${Math.round(tocTopPosition)}px`);
      _$tocContentScrollContainer.css('top', headerHeight + 'px');
   };

   var addEventListener = function(eventName, eventCallback) {
      if (typeof eventCallback !== 'function') {
         throw Error('Toc View : addEventListener expects eventCallback to be a function.');
      }
      _eventHandlers[eventName] = eventCallback;
   };

   var removeEventListener = function(eventName) {
      delete _eventHandlers[eventName];
   };

   var loadTocPipImage = function() {
      return new Promise(function(resolve, reject) {
         _tocPipImage = new Image();
         _tocPipImage.onerror = function() {
            reject();
         };
         _tocPipImage.onload = function() {
            resolve();
         };

         _tocPipImage.src = _xmp.tocImageSrc;
      });
   };

   var displayTocPipImage = function() {
      var imageWidth = _tocPipImage.width;
      var imageHeight = _tocPipImage.height;
      var scale = 1;
      var targetWidth;
      var targetHeight;
      var scaledReduction = .60;
      var padding = 20;

      if (imageHeight <= imageWidth) {
         if (imageWidth > TOC_WIDTH - padding) {
            scale = (TOC_WIDTH - padding) / imageWidth;
            targetWidth = TOC_WIDTH - padding;
            targetHeight = imageHeight * scale;
         } else {
            targetWidth = imageWidth;
            targetHeight = imageHeight;
         }
      } else if (imageHeight > TOC_WIDTH - padding) {
         scale = (imageWidth * scaledReduction - padding) / imageHeight;
         targetHeight = TOC_WIDTH * scaledReduction - padding;
         targetWidth = imageWidth * scale;
      } else {
         targetHeight = imageHeight;
         targetWidth = imageWidth;
      }

      var pipImageBackgroundStyle = 'background: url(' + _xmp.tocImageSrc + ') no-repeat;' +
         'width: ' + targetWidth + 'px;' +
         'height: ' + targetHeight + 'px;' +
         'margin-left: ' + (TOC_WIDTH - targetWidth) / 2 + 'px;' +
         'margin-right:' + (TOC_WIDTH - targetWidth) / 2 + 'px;';

      var pipImageBackground = $('<div />', {
         class: 'toc-pip-image-background',
         style: pipImageBackgroundStyle
      });

      _$tocPipImageContainer.append(pipImageBackground);
      _$tocPipImageContainer.show();
   };

   var loadTocThumbnailImage = function(tocThumbnailImageSrc) {
      return new Promise(function(resolve) {
         if (_xmp.tocContainsThumbs) {
            _tocThumbsImage = new Image();
            _tocThumbsImage.onload = function() {
               resolve();
            };
            _tocThumbsImage.onerror = function() {
               _tocThumbsImage.onload = undefined;
               _tocThumbsImage.onerror = undefined;
               _tocThumbsImage = null;
               resolve();
            };
            _tocThumbsImage.src = tocThumbnailImageSrc;
         } else {
            resolve();
         }
      });
   };

   var setupTocPosition = function() {
      _$tocContainer.toggleClass('right', playerConfiguration.getSidebarLocation() === 'right');
   };

   var setupSearch = function() {
      if (!playerConfiguration.getIsSearchable()) {
         _$searchContainer.hide();
      }
   };

   var setupTocHeader = function() {
      if (_xmp.tocImageSrc) {
         loadTocPipImage().then(displayTocPipImage).catch(function() {
            console.warn('Failed to load toc pip image ', _xmp.tocImageSrc);
         });
      }

      _$titleHeader.text(playerConfiguration.tocTitle || localizationStrings.getPlayerString(playerStringNames.accessListTableOfContents));
   };

   var clearTocContent = function() {
      _$tocContent.empty();
   };

   var generateTocItemThumbnail = function(tocItemThumbnailCanvasEl, imageThumbRect) {
      var defaultBgColor = '#000';
      var tocCellPadding = 20;
      var xPos = Number(imageThumbRect.x);
      var yPos = Number(imageThumbRect.y);
      var imageWidth = Number(imageThumbRect.width);
      var imageHeight = Number(imageThumbRect.height);

      var thumbScale = 1;
      if (imageWidth > TOC_WIDTH / 2 - tocCellPadding) {
         thumbScale = (TOC_WIDTH / 2 - tocCellPadding) / imageWidth;
         imageWidth = TOC_WIDTH / 2 - tocCellPadding;
      }
      if (imageHeight > TOC_MAX_HEIGHT - tocCellPadding) {
         var verticalThumbScale = (TOC_MAX_HEIGHT - tocCellPadding) / imageHeight;
         thumbScale = Math.min(thumbScale, verticalThumbScale);
      }

      var ctx = tocItemThumbnailCanvasEl.getContext('2d');
      tocItemThumbnailCanvasEl.width = imageWidth * thumbScale;
      tocItemThumbnailCanvasEl.height = imageHeight * thumbScale;
      ctx.scale(thumbScale, thumbScale);

      if (_tocThumbsImage) {
         ctx.drawImage(_tocThumbsImage, xPos, yPos, imageWidth, imageHeight, 0, 0, imageWidth, imageHeight);
      } else {
         ctx.fillStyle = defaultBgColor;
         ctx.fillRect(0, 0, imageWidth, imageHeight);
      }
   };

   var createTocItem = function(tocText, tocRawText, tocTime, tocFile, tocItemThumbnailCanvasEl, imageThumbRect, showText, showThumbnail) {
      var dataTocTime = 'data-time=' + tocTime;
      var dataFileText = tocFile ? 'data-file=' + tocFile : '';
      var centerContent = !showText;

      var tocItemMarkup = templateGenerator.generateTocItemMarkup(dataTocTime, dataFileText, showThumbnail, centerContent, showText, tocText, tocRawText);
      var $tocItem = $($.parseHTML(tocItemMarkup));

      if (showThumbnail) {
         generateTocItemThumbnail(tocItemThumbnailCanvasEl, imageThumbRect);
         tocItemThumbnailCanvasEl.classList.add('toc-item-thumbnail');
         $tocItem.find('.thumbnail-placeholder').replaceWith(tocItemThumbnailCanvasEl);
      }

      return $tocItem;
   };

   var createTocItems = function() {
      _$tocItemDomList = [];

      var tocItems = _xmp.tocItemArray;
      tocItems.forEach(function(tocItem) {
         var thumbnailCanvasEl = tocItem.imageRectangle ? document.createElement('canvas') : undefined;
         var showThumbnail = !!thumbnailCanvasEl;
         var showText = _xmp.tocCellLayoutType !== 'imageOnly' || !showThumbnail;
         _$tocItemDomList.push(createTocItem(textSanitizer.htmlEncode(tocItem.name), tocItem.name, tocItem.startTime, tocItem.file, thumbnailCanvasEl, tocItem.imageRectangle, showText, showThumbnail));
      });
   };

   var displayTocItems = function() {
      clearTocContent();

      if (_$tocItemDomList === null) {
         createTocItems();
      }

      _$tocContentScreenReaderLabel.html(localizationStrings.getPlayerString(playerStringNames.accessListTableOfContents));
      _$tocContent.append(_$tocItemDomList);
   };

   var setupView = function() {
      setupTocPosition();
      setupSearch();
      setupTocHeader();

      loadTocThumbnailImage(_xmp.tocThumbImageSrc).then(displayTocItems);

      if (playerConfiguration.getSidebarEnabled()) {
         openView();
      }

      _$tocContainer.click(function(e) {
         e.stopPropagation();
      });
   };

   var tocItemClickHandler = function(e) {
      e.stopImmediatePropagation();
      e.preventDefault();

      if (deviceInfo.isTouchInterface() && _scrolledToc) {
         return;
      }
      seekToTocItem(e.currentTarget);
   };

   var seekToTocItem = function(target) {
      if (_eventHandlers.clickTocItem) {
         var $currentTarget = $(target);
         var timeToSeekTo = Number($currentTarget.attr('data-time')) / 1000;
         var fileToSeekTo = $currentTarget.attr('data-file');
         _eventHandlers.clickTocItem(timeToSeekTo, fileToSeekTo);
      }
   };

   var onTocItemPointerDown = function(e) {
      _pointerDownClientPoint.x = e.originalEvent.clientX;
      _pointerDownClientPoint.y = e.originalEvent.clientY;
      _scrolledToc = false;
   };

   var onTocItemPointerMove = function(e) {
      // this event fires a lot when the browser natively implements pointer-events.  We need to actual check for movement between events.
      if (Math.abs(e.originalEvent.clientX - _pointerDownClientPoint.x) <= POINTER_MOVE_DETECTED_PIXEL_DELTA && Math.abs(e.originalEvent.clientY - _pointerDownClientPoint.y) <= POINTER_MOVE_DETECTED_PIXEL_DELTA) {
         return;
      }

      if (!deviceInfo.isTouchIEInterface()) {
         _scrolledToc = true;
      }
   };

   var onSearchTextInput = function(e) {
      updateSearchResults();

      if (keys.isKeyOfType(e.key, keys.keyTypes.enter)) {
         var $tocButtons = _$tocContent.find('button');
         if ($tocButtons.length > 0) {
            $tocButtons.first().focus();
         } else {
            _$tocContent.after(_$noSearchResultsAlert);
         }
      }
   };

   var updateSearchResults = function() {
      if (_$searchInput.val() !== '') {
         clearTocContent();

         var searchResults = searchTool.searchXmpForString(_xmp, _$searchInput.val());

         var searchResultsTocItems = [];
         searchResults.forEach(function(result) {
            var thumbnailCanvasEl = result.imageRect ? document.createElement('canvas') : undefined;
            var showThumbnail = !!thumbnailCanvasEl;
            var showText = true;
            searchResultsTocItems.push(createTocItem(result.text, result.rawText, result.startTime, result.file, thumbnailCanvasEl, result.imageRect, showText, showThumbnail));
         });

         _$tocContentScreenReaderLabel.html(localizationStrings.getPlayerString(playerStringNames.accessListSearchResults));
         _$tocContent.append(searchResultsTocItems);

         var updateSearchAlertStringToken = '{{number}}';
         var updatedSearchAlertText = localizationStrings.getPlayerString(playerStringNames.searchResultsUpdated);
         updatedSearchAlertText = updatedSearchAlertText.replace(updateSearchAlertStringToken, searchResultsTocItems.length);
         alertScreenReader(updatedSearchAlertText);
      } else {
         _$noSearchResultsAlert.remove();
         displayTocItems();
      }
   };

   var alertScreenReader = function(alertText) {
      var screenReaderAlertMarkup = templateGenerator.generateScreenReaderAlertMarkup(alertText);
      var $screenReaderAlert = $(screenReaderAlertMarkup);
      $container.append($screenReaderAlert);

      setTimeout(function() {
         $screenReaderAlert.remove();
      }, 1000);
   };

   var clearSearch = function() {
      _$searchInput.val('');
      _$noSearchResultsAlert.remove();
      displayTocItems();
      var clearSearchAlertText = localizationStrings.getPlayerString(playerStringNames.searchTextCleared);
      alertScreenReader(clearSearchAlertText);
   };

   var disableSearch = function() {
      _$searchContainer.hide();
      _$searchInputDisabled.show();
   };

   var enableSearch = function() {
      if (playerConfiguration.getIsSearchable()) {
         _$searchContainer.show();
         _$searchInputDisabled.hide();
      }
   };

   var onSearchInputFocus = function() {
      _searchFocus = true;
      $container.trigger(events.Controls.Disable);
      $container.trigger(events.Controls.SearchFocusChange, {searchIsFocused: true});
   };

   var onSearchInputBlur = function() {
      _searchFocus = false;
      $container.trigger(events.Controls.Enable);
      $container.trigger(events.Controls.SearchFocusChange, {searchIsFocused: false});
   };

   var onTocItemKeyUp = function(e) {
      if (!_keysEnabled) {
         return;
      }

      if (keys.isKeyOfType(e.key, keys.keyTypes.space, keys.keyTypes.enter)) {
         e.stopImmediatePropagation();
         e.preventDefault();
         seekToTocItem(e.currentTarget);
      }
   };

   var onTocItemKeyDown = function(e) {
      if (!_keysEnabled) {
         return;
      }

      if (keys.isKeyOfType(e.key, keys.keyTypes.space)) {
         e.stopImmediatePropagation();
         e.preventDefault();
      }
   };

   var onTocContentScrollHandler = function() {
      $container.trigger(events.Controls.TocScroll);
   };

   var onKeyDownHandler = function(e) {
      if (!hotkeyService.areHotkeysEnabled()) {
         return;
      }

      if (keys.isKeyOfType(e.key, keys.keyTypes.escape)) {
         if (_viewIsOpen) {
            if (_$tocContainer.is(':focus') || _$tocContainer.has(e.target).length > 0) {
               $container.trigger(events.Controls.FocusTocButton);
            }
            alertScreenReader(localizationStrings.getPlayerString(playerStringNames.tableOfContentsHidden));
            $container.trigger(events.Controls.HideToc);
         }
      }
   };

   var disableKeyListeners = function() {
      _$tocContainer.attr('tabindex', tabIndex.Disabled);
      _keysEnabled = false;
   };

   var enableKeyListeners = function() {
      _$tocContainer.attr('tabindex', tabIndex.DomOrder);
      _keysEnabled = true;
   };

   var bindEvents = function() {
      _$tocContent.on('pointerup', '.toc-item', tocItemClickHandler);
      _$tocContent.on('keyup', '.toc-item', onTocItemKeyUp);
      _$tocContent.on('keydown', '.toc-item', onTocItemKeyDown);
      _$tocContent.on('pointerdown', '.toc-item', onTocItemPointerDown);
      _$tocContent.on('pointermove', '.toc-item', onTocItemPointerMove);
      _$tocContentScrollContainer.on('scroll', onTocContentScrollHandler);
      $container.on('keydown', onKeyDownHandler);
      $container.on(events.Controls.Disable, disableKeyListeners);
      $container.on(events.Controls.Enable, enableKeyListeners);

      if (playerConfiguration.getIsSearchable()) {
         _$searchInput.on('keyup', onSearchTextInput);
         _$searchInput.on('focus', onSearchInputFocus);
         _$clearSearchButton.on('click', clearSearch);
         $container.on('blur', _inputSearchClassName, onSearchInputBlur); // need to use delegate for blur to consistently fire
         $container.on(events.Captions.LanguageChanged, updateSearchResults);
      }
   };

   var destroy = function() {
      _$tocContent.off('pointerup', '.toc-item', tocItemClickHandler);
      _$tocContent.off('keyup', '.toc-item', onTocItemKeyUp);
      _$tocContent.off('keydown', '.toc-item', onTocItemKeyDown);
      _$tocContent.off('pointerdown', '.toc-item', onTocItemPointerDown);
      _$tocContent.off('pointermove', '.toc-item', onTocItemPointerMove);
      _$tocContentScrollContainer.off('scroll', onTocContentScrollHandler);
      $container.off('keydown', onKeyDownHandler);
      $container.off(events.Controls.Disable, disableKeyListeners);
      $container.off(events.Controls.Enable, enableKeyListeners);

      if (playerConfiguration.getIsSearchable()) {
         _$searchInput.off('keyup', onSearchTextInput);
         _$searchInput.off('focus', onSearchInputFocus);
         $container.off('blur', _inputSearchClassName, onSearchInputBlur);
         _$clearSearchButton.off('click', clearSearch);
         $container.off(events.Captions.LanguageChanged, updateSearchResults);
      }
   };

   createView();
   setupView();
   bindEvents();

   return Object.defineProperties({
      open: openView,
      close: closeView,
      show: showView,
      hide: hideView,
      blurSearchInput: blurSearchInput,
      disableSearch: disableSearch,
      enableSearch: enableSearch,
      resize: resizeView,
      addEventListener: addEventListener,
      removeEventListener: removeEventListener,
      destroy: destroy
   }, {
      isOpen: {
         get: function() {
            return _viewIsOpen;
         }
      },
      visible: {
         get: function() {
            return _viewVisible;
         }
      },
      searchIsFocused: {
         get: function() {
            return _searchFocus;
         }
      },
      tocContainsActiveElement: {
         get: tocContainsActiveElement
      }
   });
};

export default {
   /**
    *  Factory method that returns a new TocView object.
    *  @function create
    *  @memberof TSC.TocView
    *  @static
    *  @param {Object} $container - jQuery element that contains a .toc-container element where ToC view will be injected
    *  @param {TSC.Xmp} xmpModel - Xmp Model
    *  @return new TocView instance
    */
   create: TocView
};
