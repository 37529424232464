import $ from 'jquery';
import cssClasses from '../constants/css-classes';
import events from '../constants/events';
import playerConfiguration from '../models/player-configuration';
import windowWrapper from '../../common/window-wrapper';
import captionViewFactory from './caption-view-factory';
import {CAPTION_POSITION, CAPTION_ALIGNMENT} from '../constants/captions';
import cssVariables from '../constants/css-variables';

var MAX_TOP_CAPTION_PERCENTAGE = 95;
var PERCENTAGE_MULTIPLICATION_DENOMINATOR = 100;
var DRAG_END_EVENT_TRIGGER_DELAY = 100;

/**
 * @memberof TSC
 * @class CaptionsView
 * @classdesc Captions View
 *
 * @param {jQuery} $container - Root Smart Player dom element
 * @param {CaptionService} captionsService - Caption service instance
 * @param {TSC.VideoElementView} videoElementView - Video element view for MP4 video, this is null for YouTube video.
 * @param {boolean} canDragCaptions - Captions can be dragged to a new location by the user.
 */
var CaptionsView = function($container, captionsService, videoElementView, canDragCaptions) {
   var _window = windowWrapper.getWindow();
   var _$document = $(_window.document);
   var _$positionContainer = $container.find('.caption');
   var _$textContainer = $container.find('.caption > p');
   var _canvasScale = 1.0;
   // if position is 'under' captions start as visible and the user cannot hide, else it is from config option
   var _visible = captionsService.position === CAPTION_POSITION.under ? true : playerConfiguration.getCaptionsEnabled();
   var _currentCaptionIndex = -1;
   var _lastUpdateCurrentTime = 0;
   var _lastUpdateCurrentFile;

   var _draggingCaption = false;
   var _initDragClientPosition = {
      x: 0,
      y: 0
   };

   var _onStartDragCaptionContainerSize = {
      width: 0,
      height: 0
   };

   var _initCaptionTextPosition = {
      top: 0,
      left: 0
   };

   var _customCaptionPosition = {
      top: 0,
      left: 0
   };

   var onCaptionLanguageChanged = function() {
      _currentCaptionIndex = -1;
      updateCaption(_lastUpdateCurrentTime, _lastUpdateCurrentFile);
   };

   var onDragAnimationFrame = function() {
      _$textContainer.css({top: _customCaptionPosition.top + '%', left: _customCaptionPosition.left + '%'});
      if (_draggingCaption) {
         _window.requestAnimationFrame(onDragAnimationFrame);
      }
   };

   var getInteractionPointFromEvent = function(event) {
      if (event.targetTouches && event.targetTouches.length === 1) {
         return {
            x: event.targetTouches[0].pageX,
            y: event.targetTouches[0].pageY
         };
      }

      return {
         x: event.clientX,
         y: event.clientY
      };
   };

   var onCaptionDragEnd = function(e) {
      e.preventDefault();
      e.stopImmediatePropagation();
      _draggingCaption = false;
      _$textContainer.removeClass(cssClasses.captionsCaptionSelected);

      _$document.off('pointermove touchmove', onCaptionDrag);
      _$document.off('pointerup mouseup touchend mouseleave', onCaptionDragEnd);

      setTimeout(function() {
         $container.trigger(events.Captions.DragEnd);
      }, DRAG_END_EVENT_TRIGGER_DELAY);
   };

   var onCaptionDrag = function(e) {
      e.preventDefault();

      var point = getInteractionPointFromEvent(e.originalEvent);
      var dragXOffset = point.x - _initDragClientPosition.x;
      var dragYOffset = point.y - _initDragClientPosition.y;

      var offsetXPercent = dragXOffset / _onStartDragCaptionContainerSize.width * PERCENTAGE_MULTIPLICATION_DENOMINATOR;
      var offsetYPercent = dragYOffset / _onStartDragCaptionContainerSize.height * PERCENTAGE_MULTIPLICATION_DENOMINATOR;

      _customCaptionPosition.top = _initCaptionTextPosition.top + offsetYPercent;
      _customCaptionPosition.left = _initCaptionTextPosition.left + offsetXPercent;

      var maxWidthPercentOffset = captionViewFactory.getCaptionView().captionWidth / _onStartDragCaptionContainerSize.width * PERCENTAGE_MULTIPLICATION_DENOMINATOR;
      var maxWidthPercentage = PERCENTAGE_MULTIPLICATION_DENOMINATOR - maxWidthPercentOffset;

      _customCaptionPosition.top = _customCaptionPosition.top < 0 ? 0 : _customCaptionPosition.top;
      _customCaptionPosition.top = _customCaptionPosition.top > MAX_TOP_CAPTION_PERCENTAGE ? MAX_TOP_CAPTION_PERCENTAGE : _customCaptionPosition.top;
      _customCaptionPosition.left = _customCaptionPosition.left < 0 ? 0 : _customCaptionPosition.left;
      _customCaptionPosition.left = _customCaptionPosition.left > maxWidthPercentage ? maxWidthPercentage : _customCaptionPosition.left;
   };

   var onCaptionDragStart = function(e) {
      e.preventDefault();
      e.stopImmediatePropagation();

      $container.trigger(events.Captions.DragStart);

      _$textContainer.addClass(cssClasses.captionsCaptionSelected);
      _$positionContainer.addClass(cssClasses.captionsCustomPosition);

      var currentCaptionWidth = _$positionContainer.innerWidth();
      var currentCaptionHeight = _$positionContainer.innerHeight();
      var currentCaptionOffset = _$positionContainer.offset();
      var currentTextOffset = _$textContainer.offset();

      var point = getInteractionPointFromEvent(e.originalEvent);
      _initDragClientPosition.x = point.x;
      _initDragClientPosition.y = point.y;
      _onStartDragCaptionContainerSize.width = currentCaptionWidth;
      _onStartDragCaptionContainerSize.height = currentCaptionHeight;

      var topPercent = (currentTextOffset.top - currentCaptionOffset.top) / currentCaptionHeight * PERCENTAGE_MULTIPLICATION_DENOMINATOR;
      var leftPercent = (currentTextOffset.left - currentCaptionOffset.left) / currentCaptionWidth * PERCENTAGE_MULTIPLICATION_DENOMINATOR;

      _initCaptionTextPosition.top = topPercent;
      _initCaptionTextPosition.left = leftPercent;
      _customCaptionPosition.top = topPercent;
      _customCaptionPosition.left = leftPercent;

      _$document.on('pointermove touchmove', onCaptionDrag);
      // Safari needs mouseup, Safari reports wrong event.buttons code after user
      // right clicks on player.  This causes pepjs to report pointerup event as pointermove event
      _$document.on('pointerup mouseup touchend mouseleave', onCaptionDragEnd);

      _draggingCaption = true;
      onDragAnimationFrame();
   };

   var onCaptionsToggle = function(e, eventData) {
      e.preventDefault();
      setVisible(eventData.captionsEnabled);
      handleDisplayCaptions();
   };

   var resizeCaptionsContainer = function() {
      // it's possible we can tie this function into sequential logic for internal sizeChange management, but this was moved to this file at
      // end of large refactor work where it was known to work when triggered off the Captions.Resized logic as it is done here.  so, that  event-driven
      // trigger was just left in place for now
      if (captionsService.position === CAPTION_POSITION.under) {
         $container[0].style.setProperty(cssVariables.reservedSizeCaptions, `${Math.round(_$positionContainer.height())}px`);
      }
   };

   var setupDomStructure = function() {
      if (captionsService.position === CAPTION_POSITION.under) {
         var mediaWrapper = $container.find('.media-wrapper').first();
         _$positionContainer.insertAfter(mediaWrapper);
      }
   };

   var setupEvents = function() {
      $container.on(events.Captions.Toggled, onCaptionsToggle);
      $container.on(events.Captions.LanguageChanged, onCaptionLanguageChanged);
      $container.on(events.Captions.Resized, resizeCaptionsContainer);

      if (canDragCaptions && captionsService.position !== CAPTION_POSITION.under) {
         _$textContainer.on('mousedown touchstart', onCaptionDragStart);
      }
   };

   var destroy = function() {
      $container.off(events.Captions.Toggled, onCaptionsToggle);
      $container.off(events.Captions.LanguageChanged, onCaptionLanguageChanged);
      $container.off(events.Captions.Resized, resizeCaptionsContainer);
      _$textContainer.off();
   };

   var removeCustomCaptionPosition = function() {
      _initDragClientPosition.x = _initDragClientPosition.y = 0;
      _onStartDragCaptionContainerSize.width = _onStartDragCaptionContainerSize.height = 0;
      _initCaptionTextPosition.top = _initCaptionTextPosition.left = 0;
      _customCaptionPosition.top = _customCaptionPosition.left = 0;
      _$positionContainer.removeClass(cssClasses.captionsCustomPosition);
      _$textContainer.css({top: '', left: ''});
   };

   var setVisible = function(value) {
      if (value === true && captionsService.hasCaptions) {
         updateView();

         removeCustomCaptionPosition();
         _$positionContainer.removeClass(cssClasses.hide);
      } else {
         _$positionContainer.addClass(cssClasses.hide);
      }
      _visible = value;
   };

   var getVerticalAlignmentClass = function(verticalAlignment) {
      return verticalAlignment === CAPTION_ALIGNMENT.top ? cssClasses.captionsVerticalAlignTop : cssClasses.captionsVerticalAlignBottom;
   };

   var getHorizontalAlignmentClass = function(horizontalAlignment) {
      switch (horizontalAlignment) {
         case CAPTION_ALIGNMENT.left:
            return cssClasses.captionsHorizontalAlignLeft;
         case CAPTION_ALIGNMENT.right:
            return cssClasses.captionsHorizontalAlignRight;
         default:
            return cssClasses.captionsHorizontalAlignCenter;
      }
   };

   var handleDisplayCaptions = function(captionStylesHaveChanged) {
      if (captionsService.hasCaptions) {
         if (captionStylesHaveChanged === true) {
            updateView();
         }
         // deal with setting up captions 'under' video, causes us to restructure DOM elements a little =(
         if (captionsService.position === CAPTION_POSITION.under) {
            _$textContainer.off();
         }
      }
   };

   var updateView = function() {
      _$textContainer.removeClass([cssClasses.captionXmpView, cssClasses.captionAdaView]);
      captionViewFactory.getCaptionView().setStyle(captionsService, _$positionContainer, _$textContainer);
   };

   var onSizeChange = function() {
      if (videoElementView === null) {
         return;
      }
      captionViewFactory.getCaptionView().setScale(captionsService, _canvasScale, _$positionContainer);
      captionViewFactory.getCaptionView().setWidth(videoElementView.mediaWidth * _canvasScale, _$textContainer, captionsService.position === CAPTION_POSITION.under);
      $container.trigger(events.Captions.Resized);
   };

   var updateCaption = function(currentTime, currentFile, forceActiveCaptionUpdate) {
      if (!captionsService.hasCaptions) {
         _$textContainer.html('');
         return;
      }

      _lastUpdateCurrentTime = currentTime;
      _lastUpdateCurrentFile = currentFile;

      var newCaptionIndex = -1;

      $.each(captionsService.captionController.activeCaptionTrack.captionCollection.captions, function(i, caption) {
         // this check is for playlist config, single media player use doesn't match here
         if (caption.file !== undefined && caption.file !== currentFile) {
            return;
         }

         if (caption.startTime <= currentTime && currentTime <= caption.endTime) {
            newCaptionIndex = i;
         }
      });

      if (newCaptionIndex === -1) {
         _$textContainer.addClass(cssClasses.hide);
         _$textContainer.html('');
         _currentCaptionIndex = -1;
         return;
      }

      if (forceActiveCaptionUpdate || newCaptionIndex !== _currentCaptionIndex) {
         _currentCaptionIndex = newCaptionIndex;
         var newCaption = captionsService.captionController.activeCaptionTrack.captionCollection.captions[newCaptionIndex];

         if (!(newCaption && newCaption.markupText)) {
            _$textContainer.addClass(cssClasses.hide);
            _$textContainer.html('');
            return;
         }

         _$textContainer.removeClass(cssClasses.hide);
         _$positionContainer.removeClass(cssClasses.captionsHorizontalAlignLeft + ' ' + cssClasses.captionsHorizontalAlignCenter + ' ' + cssClasses.captionsHorizontalAlignRight);
         _$positionContainer.addClass(getHorizontalAlignmentClass(newCaption.horizontalAlignment));

         if (captionsService.position !== CAPTION_POSITION.under) {
            _$positionContainer.removeClass(cssClasses.captionsVerticalAlignTop + ' ' + cssClasses.captionsVerticalAlignBottom);
            _$positionContainer.addClass(getVerticalAlignmentClass(newCaption.verticalAlignment));
         }

         captionViewFactory.getCaptionView().setText(newCaption, _$textContainer);
         updateView();
         onSizeChange();
         $container.trigger(events.Captions.Changed, newCaption);
      }
   };

   var showVideoTrackCaptions = function() {
      if (videoElementView === null) {
         return;
      }

      if (videoElementView.containsWebTrackCaptions) {
         _$positionContainer.addClass(cssClasses.hide);
         videoElementView.showCaptionTrack(captionsService.captionController.getActiveLanguageIdentifier());
      }
   };

   var hideVideoTrackCaptions = function() {
      if (videoElementView === null) {
         return;
      }

      if (videoElementView.containsWebTrackCaptions) {
         _$positionContainer.removeClass(cssClasses.hide);
         videoElementView.hideCaptionTrack(captionsService.captionController.getActiveLanguageIdentifier());
      }
   };

   setupDomStructure();
   setupEvents();
   updateView();

   if (!canDragCaptions) {
      _$positionContainer.addClass(cssClasses.captionsPreventCaptionDrag);
   }

   return Object.defineProperties({
      updateCaption: updateCaption,
      handleDisplayCaptions: handleDisplayCaptions,
      showVideoTrackCaptions: showVideoTrackCaptions,
      hideVideoTrackCaptions: hideVideoTrackCaptions,
      destroy: destroy
   }, {
      visible: {
         get: function() {
            return _visible;
         },
         set: setVisible
      },
      scale: {
         get: function() {
            return _canvasScale;
         },
         set: function(canvasScale) {
            _canvasScale = canvasScale;
            onSizeChange();
         }
      }
   });
};

export default {
   /**
    *  Factory method that returns a new CaptionsView object.
    *  @function create
    *  @memberof TSC.CaptionsView
    *  @static
    *  @param {jQuery} $container - Root Smart Player dom element
    *  @param {CaptionService} captionsService - Caption service instance
    *  @param {TSC.VideoElementView} videoElementView - Video element view for MP4 video, this is null for YouTube video.
    *  @param {boolean} canDragCaptions - Captions can be dragged to a new location by the user.
    *  @return new CaptionView instance
    */
   create: CaptionsView
};
