import $ from 'jquery';
import playerStringName from '../constants/player-string-names';
import localizationStrings from '../models/localization-strings';
import deviceInfo from '../utils/device-info';
import cssClasses from '../constants/css-classes';
import playerConfiguration from '../models/player-configuration';
import iFrameBridge from '../services/iframe-bridge';
import events from '../constants/events';
import tabIndex from '../constants/tab-index';
import templateGenerator from '../utils/template-generator';
import urlService from '../services/url-service';
import fullscreenHelper from '../utils/fullscreen-helper';

var FULL_SCREEN_BUTTON_MODE = 'fullScreenMode';
var FULL_FRAME_BUTTON_MODE = 'fullFrameMode';
var EXPAND_BUTTON_MODE = 'expandMode';
var HIDDEN_BUTTON_MODE = 'hiddenMode';

/**
 * @memberof TSC
 * @class FullscreenControlView
 * @classdesc Full Screen Control View
 * @param {jQuery} $container - top level Smart Player container
 * @param {HTMLVideoElement} videoElement
 */
var FullscreenControlView = function($container, videoElement) {
   var _inFullScreen = false;
   var _inFullFrame = false;
   var _$document = $(window.document);
   var _$fullScreenButton;
   var _$fullScreenGlyph;
   var _fullScreenDomElement = playerConfiguration.customFullScreenDomElement ? playerConfiguration.customFullScreenDomElement : $container.get(0);
   var _elementToFullscreen;
   var _buttonMode = null;
   var _legacyIOSFullScreenMode = false;

   var findButtonMode = function(canSupportExpandVideoMode) {
      if (deviceInfo.supportsFullScreen()) {
         _legacyIOSFullScreenMode = false;
         _elementToFullscreen = _fullScreenDomElement;
         _buttonMode = FULL_SCREEN_BUTTON_MODE;
      } else if (deviceInfo.supportsLegacyIOSFullScreen(videoElement)) {
         _legacyIOSFullScreenMode = true;
         _elementToFullscreen = videoElement;
         _buttonMode = FULL_SCREEN_BUTTON_MODE;
      } else if (iFrameBridge.isInIframe() && canSupportExpandVideoMode) {
         _buttonMode = playerConfiguration.getDisableFullframeMode() ? EXPAND_BUTTON_MODE : FULL_FRAME_BUTTON_MODE;
      } else {
         _buttonMode = HIDDEN_BUTTON_MODE;
      }
   };

   var updateButtonTitle = function() {
      var buttonTitle = localizationStrings.getPlayerString(playerStringName.accessBtnFullScreen);
      if (_buttonMode === EXPAND_BUTTON_MODE) {
         buttonTitle = localizationStrings.getPlayerString(playerStringName.accessBtnFullScreenExpand);
      }
      _$fullScreenButton.attr('title', buttonTitle);
   };

   var setButtonDisplay = function() {
      if (_buttonMode === HIDDEN_BUTTON_MODE) {
         _$fullScreenButton.hide();
      } else {
         _$fullScreenButton.show();
      }
   };

   var createView = function() {
      var buttonTitle = localizationStrings.getPlayerString(playerStringName.accessBtnFullScreen);
      if (_buttonMode === EXPAND_BUTTON_MODE) {
         buttonTitle = localizationStrings.getPlayerString(playerStringName.accessBtnFullScreenExpand);
      }

      var _markup = templateGenerator.generateFullscreenControlMarkup(buttonTitle);
      $container.find('.fullscreen-wrapper').html(_markup);
      _$fullScreenButton = $container.find('.fullscreen-button');
      _$fullScreenGlyph = $container.find('.fullscreen-button-glyph');
   };

   var onFullScreenChange = function() {
      _inFullScreen = !_inFullScreen;

      if (_inFullScreen) {
         _$fullScreenGlyph.removeClass(cssClasses.fullscreenButtonGlyph);
         _$fullScreenGlyph.addClass(cssClasses.fullscreenButtonActiveGlyph);
         _$fullScreenButton.attr('aria-pressed', true);

         _$fullScreenButton.focus();

         $container.addClass(cssClasses.fullscreenMode);
      } else {
         _$fullScreenGlyph.removeClass(cssClasses.fullscreenButtonActiveGlyph);
         _$fullScreenGlyph.addClass(cssClasses.fullscreenButtonGlyph);
         _$fullScreenButton.attr('aria-pressed', false);

         $container.removeClass(cssClasses.fullscreenMode);
      }

      $container.trigger(events.Controls.FullScreenChange, {inFullScreen: _inFullScreen});
   };

   var initializeFullScreenSupport = function(canSupportExpandVideoMode) {
      findButtonMode(canSupportExpandVideoMode);
      updateButtonTitle();
      setButtonDisplay();
      initializeHandlers();
   };

   var cancelFullScreen = function() {
      _$fullScreenButton.attr('aria-pressed', false);
      fullscreenHelper.exitFullscreenMode(videoElement);
   };

   var switchToFullScreenMode = function() {
      _$fullScreenButton.attr('aria-pressed', true);
      fullscreenHelper.switchToFullscreenMode(_elementToFullscreen);
   };

   var switchFromFullScreenMode = function() {
      cancelFullScreen();
   };

   var toggleFullScreenMode = function() {
      if (!_inFullScreen) {
         switchToFullScreenMode();
      } else {
         switchFromFullScreenMode();
      }
   };

   var toggleFullFrameMode = function() {
      _inFullFrame = !_inFullFrame;
      if (_inFullFrame) {
         _$fullScreenGlyph.removeClass(cssClasses.fullFrameButtonGlyph);
         _$fullScreenGlyph.addClass(cssClasses.fullFrameButtonActiveGlyph);
         _$fullScreenButton.attr('aria-pressed', true);
         iFrameBridge.postMessage(events.IFrame.EnterFullFrame, '*');
      } else {
         _$fullScreenGlyph.removeClass(cssClasses.fullFrameButtonActiveGlyph);
         _$fullScreenGlyph.addClass(cssClasses.fullFrameButtonGlyph);
         iFrameBridge.postMessage(events.IFrame.ExitFullFrame, '*');
         _$fullScreenButton.attr('aria-pressed', false);
      }
   };

   var expandVideo = function() {
      $container.trigger(events.Controls.ExpandVideo);
      urlService.openUrl(urlService.generateCurrentUrlWithExtraQueryParam('theme', playerConfiguration.getTheme()), '_blank');
   };

   var onFullScreenClick = function() {
      switch (_buttonMode) {
         case FULL_SCREEN_BUTTON_MODE:
            toggleFullScreenMode();
            break;
         case FULL_FRAME_BUTTON_MODE:
            toggleFullFrameMode();
            break;
         case EXPAND_BUTTON_MODE:
            expandVideo();
            break;
      }
   };

   var onDoubleClick = function() {
      switch (_buttonMode) {
         case FULL_SCREEN_BUTTON_MODE:
            toggleFullScreenMode();
            break;
         case FULL_FRAME_BUTTON_MODE:
            toggleFullFrameMode();
            break;
      }
   };

   var disableControl = function() {
      _$fullScreenButton.attr('tabindex', tabIndex.Disabled);
   };

   var enableControl = function() {
      _$fullScreenButton.attr('tabindex', tabIndex.DomOrder);
   };

   var checkToExitFullScreen = function() {
      if (_legacyIOSFullScreenMode) {
         switchFromFullScreenMode();
      }
   };

   var onFullButtonClick = function(e) {
      onFullScreenClick();
      e.preventDefault();
   };

   var initializeHandlers = function() {
      _$fullScreenButton.on('click', onFullButtonClick);
      $container.on(events.Controls.Disable, disableControl);
      $container.on(events.Controls.Enable, enableControl);
      $container.on(events.Quizzing.Paused, checkToExitFullScreen);
      $container.on(events.Hotspots.Paused, checkToExitFullScreen);
      $container.on(events.Media.End, checkToExitFullScreen);
      $container.on(events.Controls.ToggleFullScreen, toggleFullScreenMode);

      switch (_buttonMode) {
         case FULL_SCREEN_BUTTON_MODE:
            if (_legacyIOSFullScreenMode) {
               videoElement.addEventListener('webkitbeginfullscreen', onFullScreenChange);
               videoElement.addEventListener('webkitendfullscreen', onFullScreenChange);
            } else {
               var fullScreenEventNames = deviceInfo.getFullScreenChangeEventNames();
               _$document.off(fullScreenEventNames, onFullScreenChange);
               _$document.on(fullScreenEventNames, onFullScreenChange);
            }
            break;
         case FULL_FRAME_BUTTON_MODE:
            _$fullScreenGlyph.removeClass(cssClasses.fullscreenButtonGlyph);
            _$fullScreenGlyph.addClass(cssClasses.fullFrameButtonGlyph);
            break;
         case EXPAND_BUTTON_MODE:
            _$fullScreenGlyph.removeClass(cssClasses.fullscreenButtonGlyph);
            _$fullScreenGlyph.addClass(cssClasses.expandButtonGlyph);
            break;
      }
   };

   var onVideoEnd = function() {
      // must kick them out of full screen to get the replay button to show
      if (_inFullScreen) {
         cancelFullScreen();
      }
   };

   var destroy = function() {
      _$fullScreenButton.off('click', onFullButtonClick);
      videoElement.removeEventListener('webkitbeginfullscreen', onFullScreenChange);
      videoElement.removeEventListener('webkitendfullscreen', onFullScreenChange);
      $container.off(events.Controls.Disable, disableControl);
      $container.off(events.Controls.Enable, enableControl);
      $container.off(events.Quizzing.Paused, checkToExitFullScreen);
      $container.off(events.Hotspots.Paused, checkToExitFullScreen);
      $container.off(events.Media.End, checkToExitFullScreen);
      $container.off(events.Controls.ToggleFullScreen, toggleFullScreenMode);
   };

   findButtonMode(false);
   createView();
   setButtonDisplay();

   return Object.defineProperties({
      initializeFullScreenSupport: initializeFullScreenSupport,
      onVideoEnd: onVideoEnd,
      onDoubleClick: onDoubleClick,
      destroy: destroy
   }, {
      isFullScreen: {
         get: function() {
            return _inFullScreen;
         }
      },
      isFullFrame: {
         get: function() {
            return _inFullFrame;
         }
      },
      controlElement: {
         get: function() {
            return _$fullScreenButton;
         }
      }
   });
};

export default {
   /**
    *  Factory method that returns a new FullscreenControlView object.
    *  @function create
    *  @memberof TSC.FullscreenControlView
    *  @static
    *  @param {Object} $container - jQuery element that contains a .fullscreen-wrapper element where the fullscreen controls will be injected
    *  @param {Object} mediaElement - html5 media DOM element (or wrapper presenting same interface)
    *  @return new FullscreenControlView instance
    */
   create: FullscreenControlView
};
