import AudioDescriptionControlView from '../views/audio-description-control-view';
import AudioDescriptionTrackView from '../views/audio-description-track-view';

/**
 * @memberof TSC
 * @class AudioDescriptionController
 * @classdesc Controller for managing XMP features
 * @param {jQuery} $container - Smart Player main container jquery element
 * @param {MediaView} mediaView - Media view for current media. Created by MediaViewFactory.
 * @param {string} audioDescriptionMediaSrc - The source url for the audio description track
 *
 */
var AudioDescriptionController = function($container, mediaView, audioDescriptionMediaSrc) {
   var hasAudioDescription = !!audioDescriptionMediaSrc;
   if (!hasAudioDescription) {
      throw new Error('Attempted to create audio description controller with no audio description');
   }

   var _audioDescriptionControlView;
   var _audioDescriptionTrackView;

   var setupFeatures = function() {
      _audioDescriptionControlView = AudioDescriptionControlView.create($container);
      _audioDescriptionTrackView = AudioDescriptionTrackView.create($container, mediaView.mediaElement, audioDescriptionMediaSrc);
   };

   var destroy = function() {
      _audioDescriptionControlView && _audioDescriptionControlView.destroy();
      _audioDescriptionTrackView && _audioDescriptionTrackView.destroy();
   };

   setupFeatures();

   return Object.defineProperties({
      destroy: destroy
   }, {
   });
};

export default {
   /**
    *  Factory method that returns a new AudioDescriptionController.
    *  @function create
    *  @memberof TSC.AudioDescriptionController
    *  @static
    *  @param {jQuery} $container - Smart Player main container jquery element
    *  @param {MediaView} mediaView - Media view for current media. Created by MediaViewFactory.
    *  @param {string} audioDescriptionMediaSrc - The source url for the audio description track
    *  @return new AudioDescriptionController instance
    */
   create: AudioDescriptionController
};
