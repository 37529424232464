var endActionType = Object.freeze({
   notAvailable: 'notAvailable',
   loop: 'loop',
   jumpToTimeAndPlay: 'jumpToTime',
   jumpToTImeAndStop: 'jumpToTimeAndStop',
   showReplayControls: 'showReplayControls',
   hideReplayControls: 'hideReplayControls',
   openUrl: 'openUrl',
   openUrlInNewWindow: 'openUrlInNewWindow'
});

export default endActionType;

