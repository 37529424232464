var registeredEvents = {};
var listeningToWindowMessageEvent = false;

var onExternalMessage = function(e) {
   // filter out non smart player events
   if (!e.data || typeof e.data.indexOf !== 'function' || e.data.indexOf('smartPlayerEventName') === -1) {
      return;
   }
   var message = window.JSON.parse(e.data);
   if (registeredEvents[message.smartPlayerEventName]) {
      registeredEvents[message.smartPlayerEventName].call(this, message.data);
   }
};

var addWindowMessageListener = function() {
   if (listeningToWindowMessageEvent) {
      return;
   }
   if (window.addEventListener) {
      window.addEventListener('message', onExternalMessage, false);
   } else {
      window.attachEvent('onmessage', onExternalMessage);
   }
   listeningToWindowMessageEvent = true;
};

var removeWindowMessageListener = function() {
   if (!listeningToWindowMessageEvent) {
      return;
   }
   if (window.removeEventListener) {
      window.removeEventListener('message', onExternalMessage, false);
   } else {
      window.removeEvent('onmessage', onExternalMessage);
   }
   listeningToWindowMessageEvent = false;
};

export default {
   addCallback: function(eventName, callbackFunction) {
      addWindowMessageListener();
      registeredEvents[eventName] = callbackFunction;
   },
   removeCallback: function(eventName) {
      if (registeredEvents[eventName]) {
         delete registeredEvents[eventName];
      }
      if (Object.keys(registeredEvents).length === 0) {
         removeWindowMessageListener();
      }
   },
   removeAllCallbacks: function() {
      registeredEvents = {};
      removeWindowMessageListener();
   }
};
