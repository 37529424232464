/**
 *  Utility function that find the rectangle for the video frame in the video thumbnails sprite sheet.
 *  @function getThumbnailRectangleByTime
 *  @param {Number} timeInSeconds - Time in seconds to locate the video thumbnail
 *  @param {Number} videoThumbnailInterval - The interval in time between generated thumbnails in sprite sheet.
 *  @param {Number} videoThumbnailColumns - The max number of columns in the sprite sheet.
 *  @param {Number} videoThumbnailWidth - The width of a video frame thumbnail.
 *  @param {Number} videoThumbnailHeight - The height of a video frame thumbnail.
 *  @returns {Object} An object that contains the x, y, width, height of the video thumbnail frame.
 */
const getThumbnailRectangleByTime = (timeInSeconds, videoThumbnailInterval, videoThumbnailColumns, videoThumbnailWidth, videoThumbnailHeight) => {
   const timeIndex = Math.floor(timeInSeconds / videoThumbnailInterval);
   const row = Math.floor(timeIndex / videoThumbnailColumns);
   const column = timeIndex - videoThumbnailColumns * row;
   return {
      x: column * videoThumbnailWidth,
      y: row * videoThumbnailHeight,
      width: videoThumbnailWidth,
      height: videoThumbnailHeight
   };
};

export {getThumbnailRectangleByTime};
