/**
 * @memberof TSC
 * @class rationaleActionType
 * @classdesc Rationale Action Types
 */
var rationaleActionType = Object.defineProperties({}, {
   /**
    *  @public
    *  @readonly
    *  @static
    *  @memberof TSC.rationaleActionType
    *  @property {String} CONTINUE - continue type
    */
   CONTINUE: {
      value: 'continue'
   },
   /**
    *  @public
    *  @readonly
    *  @static
    *  @memberof TSC.rationaleActionType
    *  @property {String} LOCATION - location type
    */
   LOCATION: {
      value: 'location'
   },
   /**
    *  @public
    *  @readonly
    *  @static
    *  @memberof TSC.rationaleActionType
    *  @property {String} JUMP_TO_TIME - jump to time type
    */
   JUMP_TO_TIME: {
      value: 'jumpToTIme'
   }
});

export default rationaleActionType;
