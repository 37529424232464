var _registeredExceptionListeners = [];
var _registeredMessageListeners = [];

var removeValueFromArray = function(array, value) {
   var index = -1;
   for (var i = 0; i < array.length; i++) {
      if (array[i] === value) {
         index = i;
         break;
      }
   }
   if (index !== -1) {
      array.splice(index, 1);
      return true;
   }
   return false;
};

var log = {
   logException: function(error) {
      _registeredExceptionListeners.forEach(function(callback) {
         callback(new Error(error));
      });
   },
   logMessage: function(message) {
      _registeredMessageListeners.forEach(function(callback) {
         callback(message);
      });
   },
   addEventListener: function(type, listener) {
      if (listener && typeof listener === 'function') {
         if (type === 'EXCEPTION') {
            _registeredExceptionListeners.push(listener);
         } else if (type === 'MESSAGE') {
            _registeredMessageListeners.push(listener);
         } else {
            throw new Error('log.js: addEventListener() Type argument was not EXCEPTION or MESSAGE');
         }
      } else {
         throw new Error('log.js: addEventListener() Listener argument was not a function');
      }
   },
   removeEventListener: function(type, listener) {
      var throwError = function() {
         throw new Error('log.js: removeEventListener() ' + listener + ' was not found within the ' + type + ' callbacks.');
      };

      if (type === 'EXCEPTION') {
         if (!removeValueFromArray(_registeredExceptionListeners, listener)) {
            throwError();
         }
      } else if (type === 'MESSAGE') {
         if (!removeValueFromArray(_registeredMessageListeners, listener)) {
            throwError();
         }
      } else {
         throw new Error('log.js: removeEventListener() Type argument was not EXCEPTION or MESSAGE');
      }
   },
   removeAllEventListeners: function() {
      _registeredExceptionListeners = [];
      _registeredMessageListeners = [];
   }
};

export default log;
