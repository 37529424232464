var highlightProperty = 'outline-style';

var disableHighlights = function($element) {
   $element.css(highlightProperty, 'none').on('blur', function() {
      $element.off('blur').css(highlightProperty, '');
   });
};

var disableHighlightsOnMouseClick = function($element, e) {
   if (e.screenX || e.screenY) {
      disableHighlights($element);
   }
};

var checkIfElementIsHighlighted = function($element) {
   var highlightState = $element.css(highlightProperty);
   return highlightState !== 'none' && highlightState !== '';
};

export default {
   disableHighlights: disableHighlights,
   disableHighlightsOnMouseClick: disableHighlightsOnMouseClick,
   checkIfElementIsHighlighted: checkIfElementIsHighlighted
};
