import playerStringNames from '../constants/player-string-names';
import localizationStrings from '../models/localization-strings';
import events from '../constants/events';
import tabIndex from '../constants/tab-index';
import pipControllerFactory from '../controllers/pip-controller-factory';
import templateGenerator from '../utils/template-generator';

/**
 * @memberof TSC
 * @class PipControlView
 * @classdesc PIP View Control
 *  @param {Object} $container - jQuery element that contains a .settings-button element and a .player-settings-wrapper element where the player settings controls will be injected
 *  @param {Object} mediaElement - html5 media DOM element (or wrapper presenting same interface)
 */
var PipControlView = function($container, mediaElement) {
   var _$pipButton = null;
   var _pipApiController = null;

   var onPresentationModeChange = function() {
      $container.trigger(events.Controls.PipChanged, {pipViewActive: _pipApiController.inPip});
   };

   var onPipControlClick = function(e) {
      e.preventDefault();
      _pipApiController.enter();
   };

   var handleSmartPlayerActionEvents = function() {
      if (_pipApiController && _pipApiController.inPip) {
         _pipApiController.exit();
      }
   };

   var disableControl = function() {
      _$pipButton.attr('tabindex', tabIndex.Disabled);
   };

   var enableControl = function() {
      _$pipButton.attr('tabindex', tabIndex.DomOrder);
   };

   var createView = function() {
      var buttonTitle = localizationStrings.getPlayerString(playerStringNames.accessBtnPip);
      var _markup = templateGenerator.generatePipControlMarkup(buttonTitle);
      $container.find('.pip-wrapper').html(_markup);
      _$pipButton = $container.find('.pip-button');
   };

   var initializeHandlers = function() {
      _pipApiController.bindChangeEvent(onPresentationModeChange);

      _$pipButton.on('click', onPipControlClick);
      $container.on(events.Hotspots.Paused, handleSmartPlayerActionEvents);
      $container.on(events.Quizzing.Paused, handleSmartPlayerActionEvents);
      $container.on(events.Media.End, handleSmartPlayerActionEvents);
      $container.on(events.Controls.Disable, disableControl);
      $container.on(events.Controls.Enable, enableControl);
   };

   var initialize = function() {
      _pipApiController = pipControllerFactory.getPipController(mediaElement);
      if (_pipApiController.isSupported) {
         createView();
         initializeHandlers();
      }
   };

   var destroy = function() {
      if (_pipApiController && _pipApiController.isSupported) {
         _pipApiController.unbindChangeEvent(onPresentationModeChange);
         _$pipButton.off('click', onPipControlClick);
         $container.off(events.Hotspots.Paused, handleSmartPlayerActionEvents);
         $container.off(events.Quizzing.Paused, handleSmartPlayerActionEvents);
         $container.off(events.Media.End, handleSmartPlayerActionEvents);
         $container.off(events.Controls.Disable, disableControl);
         $container.off(events.Controls.Enable, enableControl);
      }
   };

   return Object.defineProperties({
      initialize: initialize,
      destroy: destroy
   }, {});
};

export default {
   /**
    *  Factory method that returns a new PipControlView object.
    *  @function create
    *  @memberof TSC.PipControlView
    *  @param {Object} $container - jQuery element that contains a .settings-button element and a .player-settings-wrapper element where the player settings controls will be injected
    *  @param {Object} mediaElement - html5 media DOM element (or wrapper presenting same interface)
    *  @static
    *  @return new PipControlView instance
    */
   create: PipControlView
};
