import $ from 'jquery';
import Promise from 'promise-polyfill';
import playerStringNames from '../constants/player-string-names';
import localizationStrings from '../models/localization-strings';
import events from '../constants/events';
import performanceWrapper from '../utils/performance-wrapper';
import deviceInfo from '../utils/device-info';
import queryParamsStripper from '../utils/query-params-stripper';
import iFrameBridge from '../services/iframe-bridge';
import playerConfiguration from '../models/player-configuration';
import mediaType from '../constants/media-type';
import MediaList from '../models/media-list';
import playerType from '../constants/player-type';
import mediaPlayerController from './media-player-controller';
import ResponsiveController from '../controllers/responsive-controller';
import reportType from '../constants/report-type';
import Quiz from '../models/quiz';
import Xmp from '../models/xmp';
import fathomService from '../services/fathom-service';
import CaptionService from '../services/caption-service';
import NullSmartPlayerView from '../views/null-smart-player-view';
import SmartPlayerView from '../views/smart-player-view';
import youTube from '../controllers/youtube-plugin';
import preRollController from './pre-roll-controller';
import themeController from './theme-controller';
import cssClasses from '../constants/css-classes';
import scormController from './scorm-controller';
import messageBarType from '../constants/message-bar-type';
import MessageBarView from '../views/message-bar-view';
import analyticsService from '../services/analytics-service';
import {externalVideoEventIsSupported} from '../utils/event-utils';
import {
   AnalyticsVideoEventCategory,
   AnalyticsAdaptiveVideoEventCategory,
   AnalyticsImageEventCategory,
   AnalyticsYouTubeEventCategory
} from '../constants/analytics-constants';
import uuidGenerator from '../utils/uuid-generator';

// major, minor, build
var PLAYER_VERSION = 'x.x.x';
var INIT_DEFAULT_WIDTH = 1920;
var INIT_DEFAULT_HEIGHT = 1080;

var _$rootSmartPlayerElement = null;
var _responsiveController = null;
var _currentPlayerType = null;
var _mediaList = null;
var _xmp = null;
var _quizModel = null;
var _captionService = null;
var _smartPlayerView = NullSmartPlayerView.create();
var _viewCreated = false;
var _viewInitialized = false;
var _queuedEvents = {};
var _queuedExternalConfiguration = {};
var _playerInstanceUUID = null;
var _xmpInErrorState = false;

var mediaListError = function() {
   MessageBarView.create(_$rootSmartPlayerElement, messageBarType.warning, localizationStrings.getPlayerString(playerStringNames.mediaNotFound));
};

var onPreRollStart = function() {
   continueInit();
};

var initPlayer = function(currentInstanceUUID) {
   _captionService = CaptionService.create();

   deviceInfo.lookAtDevice();

   queryParamsStripper.strip();

   themeController.setSmartPlayerContainerElement(_$rootSmartPlayerElement);

   if (playerConfiguration.getPreRollSrc()) {
      preRollController.createView(playerConfiguration.getPreRollSrc(), onPreRollStart);
   } else {
      continueInit(currentInstanceUUID);
   }
};

var continueInit = function(currentInstanceUUID) {
   var mediaSources = playerConfiguration.getMediaSources();
   _mediaList = MediaList.create(mediaSources);
   _mediaList.ready.then(function() {
      if (_playerInstanceUUID === currentInstanceUUID) {
         mediaListReady(currentInstanceUUID);
      }
   }, mediaListError);
};

var playbackInitiatedHandler = function() {
   _smartPlayerView.removeEventListener(events.External.VideoStartInitiated, playbackInitiatedHandler);
   if (preRollController && preRollController.viewExists()) {
      preRollController.destroyView();
   }
};

var mediaListReady = function(currentInstanceUUID) {
   _currentPlayerType = mediaPlayerController.getPlayerType(_mediaList);
   _responsiveController = ResponsiveController.create(_$rootSmartPlayerElement);

   switch (_currentPlayerType) {
      case playerType.YOUTUBE: {
         youTube.addApi();
         analyticsService.setCategory(AnalyticsYouTubeEventCategory);
         // we dont have any info on youtube media, so just default to 1080p, it tends to look right
         _responsiveController.initializeIntrinsicSizing(INIT_DEFAULT_WIDTH, INIT_DEFAULT_HEIGHT);
         processMediaAssets(currentInstanceUUID);
         break;
      }
      case playerType.HTML5_VIDEO: {
         analyticsService.setCategory(AnalyticsVideoEventCategory);
         if (playerConfiguration.getPosterImageSrc()) {
            // assumption that quick load of posterUrl will have sizing (or at least aspect ratio to match video)
            initIntrinsicSizingFromImageUrl(playerConfiguration.getPosterImageSrc());
         } else {
            _responsiveController.initializeIntrinsicSizing(INIT_DEFAULT_WIDTH, INIT_DEFAULT_HEIGHT);
         }
         processMediaAssets(currentInstanceUUID);
         break;
      }
      case playerType.IMAGE: {
         analyticsService.setCategory(AnalyticsImageEventCategory);
         initIntrinsicSizingFromImageUrl(_mediaList.currentMedia.uri);
         processMediaAssets(currentInstanceUUID);
         break;
      }
      case playerType.NOT_SUPPORTED: {
         if (_mediaList.currentMediaType === mediaType.WEBM) {
            MessageBarView.create(_$rootSmartPlayerElement, messageBarType.warning, localizationStrings.getPlayerString(playerStringNames.noWebMSupport));
         } else {
            MessageBarView.create(_$rootSmartPlayerElement, messageBarType.warning, localizationStrings.getPlayerString(playerStringNames.videoNotSupported));
         }
         break;
      }
   }
};

var setQuizModel = function(xmpModel) {
   if (xmpModel && xmpModel.hasQuiz) {
      _quizModel = Quiz.create(xmpModel);
   }
};

var initIntrinsicSizingFromImageUrl = function(imgUrl) {
   var img = new Image();
   img.onload = () => {
      _responsiveController.initializeIntrinsicSizing(img.naturalWidth, img.naturalHeight);
   };
   img.onerror = () => {
      _responsiveController.initializeIntrinsicSizing(INIT_DEFAULT_WIDTH, INIT_DEFAULT_HEIGHT);
   };
   img.src = imgUrl;
};

var processQuizzing = function() {
   return new Promise(function(resolve) {
      if (playerConfiguration.fathomId) {
         fathomService.addEventListener(events.Fathom.GetComplete, function(xmpModel) {
            resolve(xmpModel);
         });
         fathomService.addEventListener(events.Fathom.Error, function() {
            _xmpInErrorState = true;
            resolve();
         });
         fathomService.getFathom(playerConfiguration.fathomId);
      } else {
         resolve(_xmp);
      }
   });
};

var processCaptionSrc = function() {
   if (playerConfiguration.captionSrcList.length > 0) {
      if (_xmp === null) {
         _xmp = Xmp.create();
      }
      return _xmp.importCaptionsFromFile(playerConfiguration.captionSrcList);
   }
   return Promise.resolve();
};

var processXMP = function() {
   return new Promise(function(resolve) {
      if (playerConfiguration.xmpSrc) {
         _xmp = Xmp.create();

         if (window.TSC && typeof window.TSC.embedded_config_xml === 'string') {
            _xmp.parse(window.TSC.embedded_config_xml);
         } else {
            _xmp.load(playerConfiguration.xmpSrc, false);
         }

         _xmp.ready.then(resolve).catch(function() {
            _xmpInErrorState = true;
            _xmp.abortPendingRequests();
            _xmp = null;
            resolve();
         });
      } else {
         if (playerConfiguration.getTocModelFromDescription()) {
            _xmp = Xmp.create();
         }
         resolve();
      }
   });
};

var processMediaAssets = function(currentInstanceUUID) {
   if (playerConfiguration.getGoogleAnalyticsID()) {
      analyticsService.initGoogleAnalytics();
   }

   processXMP().then(function() {
      if (_playerInstanceUUID === currentInstanceUUID) {
         if (_xmp) {
            playerConfiguration.configureFromXmp(_xmp);
            localizationStrings.configureFromXmp(_xmp);
         }

         processCaptionSrc().then(function() {
            if (_playerInstanceUUID === currentInstanceUUID) {
               processQuizzing().then(function(xmpModel) {
                  if (_playerInstanceUUID === currentInstanceUUID) {
                     if (xmpModel) {
                        setQuizModel(xmpModel);
                     }
                     setupPlayer(currentInstanceUUID);
                  }
               });
            }
         });
      }
   });
};

var captionChangeHandler = function(text) {
   if (iFrameBridge.isInIframe()) {
      iFrameBridge.postMessage('CAPTION-CHANGED', '*', text);
   }
};

var setupBasicCss = function() {
   _$rootSmartPlayerElement.addClass(cssClasses.smartPlayerNamespace);
   _$rootSmartPlayerElement.addClass(cssClasses.playerControlsVisible);

   if (playerConfiguration.getUseLargerProgressBar()) {
      _$rootSmartPlayerElement.addClass(cssClasses.largerProgressBar);
   }

   // mainly for backwards compat for pre-version 3.38.0 traverse parent-chain and set height/pad/etc if default
   if (playerConfiguration.getFullViewPort()) {
      _$rootSmartPlayerElement.parents().each(function(index, parentNode) {
         var $parentNode = $(parentNode);
         $parentNode.height('100%');
         if ($parentNode.prop('tagName').toLowerCase() === 'body') {
            $parentNode.css({
               margin: 0,
               padding: 0,
               border: 0,
               overflow: 'hidden'
            });
         }
      });
   }
};

var addQueuedEventsToSmartPlayerView = function() {
   Object.keys(_queuedEvents).forEach(function(eventName) {
      _queuedEvents[eventName].forEach(function(eventCallback) {
         addEventListener(eventName, eventCallback);
      });
   });
};


var setQueuedConfigurationValuesToSmartPlayerView = function() {
   if (_queuedExternalConfiguration.volume !== undefined) {
      _smartPlayerView.volume = _queuedExternalConfiguration.volume;
   }

   if (_queuedExternalConfiguration.muted !== undefined) {
      _smartPlayerView.muted = _queuedExternalConfiguration.muted;
   }

   if (_queuedExternalConfiguration.playbackRate !== undefined) {
      _smartPlayerView.playbackRate = _queuedExternalConfiguration.playbackRate;
   }
};

var initPlayerView = function(currentInstanceUUID, displayLocalWarning) {
   _smartPlayerView = SmartPlayerView.create(
      currentInstanceUUID,
      _$rootSmartPlayerElement,
      _mediaList,
      _xmp,
      _quizModel,
      _captionService,
      playerConfiguration,
      _responsiveController,
      _xmpInErrorState
   );
   _viewCreated = true;
   addQueuedEventsToSmartPlayerView();
   _smartPlayerView.initializedPromise.then(function() {
      if (_playerInstanceUUID === currentInstanceUUID) {
         _viewInitialized = true;
         setQueuedConfigurationValuesToSmartPlayerView();
         _smartPlayerView.addEventListener(events.External.VideoStartInitiated, playbackInitiatedHandler);
         _smartPlayerView.addEventListener(events.External.CaptionChange, captionChangeHandler);

         if (playerConfiguration.getAutoPlayMedia() || playerConfiguration.getForceAutoPlayMedia()) {
            _smartPlayerView.loadMedia();
         }

         if (displayLocalWarning) {
            MessageBarView.create(_$rootSmartPlayerElement, messageBarType.info, localizationStrings.getPlayerString(playerStringNames.xmpSecurity), localizationStrings.getPlayerString(playerStringNames.close));
         }
      }
   });
};

var setupPlayer = function(currentInstanceUUID) {
   var displayLocalWarning = false;

   if (_currentPlayerType === playerType.IMAGE) {
      _$rootSmartPlayerElement.addClass('image-view');
   } else {
      _$rootSmartPlayerElement.addClass('video-view');
   }

   if (_xmp) {
      if (_xmp.hasCaptionItems) {
         if (iFrameBridge.isInIframe()) {
            iFrameBridge.postMessage('HAS-CAPTIONS', '*');
         }
      }
   }

   if (_quizModel && _quizModel.hasQuiz) {
      if (deviceInfo.isLocal() && _quizModel.reportMethod !== reportType.NONE) {
         displayLocalWarning = true;
         _quizModel.reportMethod = reportType.NONE;
      } else if (_quizModel.useScorm && _quizModel.totalNumberOfGradedQuestionSets === 0) {
         playerConfiguration.isScormLesson = true;
      }
   }

   if (playerConfiguration.isScormLesson) {
      scormController.init().then(function() {
         initPlayerView(currentInstanceUUID, displayLocalWarning);
      });
   } else {
      initPlayerView(currentInstanceUUID, displayLocalWarning);
   }
};

var triggerEvent = function(eventName, data) {
   if (_$rootSmartPlayerElement) {
      _$rootSmartPlayerElement.trigger(eventName, data);
   }
};

var init = function(rootElementID) {
   _playerInstanceUUID = uuidGenerator.getUUID();
   performanceWrapper.createMarker(events.Timing.PlayerInitialized);
   _$rootSmartPlayerElement = $(rootElementID);

   setupBasicCss();
   initPlayer(_playerInstanceUUID);
};

var getSmartPlayerRootElement = function() {
   return _$rootSmartPlayerElement;
};

var destroy = function() {
   _playerInstanceUUID = null;
   _currentPlayerType = null;
   _mediaList = null;
   _xmp && _xmp.abortPendingRequests();
   _xmp = null;
   _quizModel = null;
   _viewCreated = false;
   _viewInitialized = false;
   _captionService = null;
   _queuedEvents = {};
   _queuedExternalConfiguration = {};
   _xmpInErrorState = false;

   fathomService.abortPendingRequests();
   localizationStrings.reset();

   if (_$rootSmartPlayerElement) {
      _smartPlayerView.removeEventListener(events.External.VideoStartInitiated, playbackInitiatedHandler);
      _smartPlayerView.removeEventListener(events.External.CaptionChange, captionChangeHandler);
      _smartPlayerView.destroy();
      _smartPlayerView = NullSmartPlayerView.create();
      _$rootSmartPlayerElement.removeClass(cssClasses.smartPlayerNamespace + ' ' + cssClasses.largerProgressBar + ' video-view image-view');
      _$rootSmartPlayerElement = null;
   }
};

var getVersion = function() {
   return PLAYER_VERSION;
};

var playMedia = function() {
   _smartPlayerView.play();
};

var pauseMedia = function() {
   _smartPlayerView.pause();
};

var queueEventHandler = function(eventName, callback) {
   if (!_queuedEvents[eventName]) {
      _queuedEvents[eventName] = [];
   }
   _queuedEvents[eventName].push(callback);
};

var dequeueEventHandler = function(eventName, callback) {
   var eventEntry = _queuedEvents[eventName];
   if (eventEntry) {
      for (var i = 0; i < eventEntry.length; i++) {
         if (eventEntry[i] === callback) {
            eventEntry.splice(i, 1);
            break;
         }
      }
      if (eventEntry.length === 0) {
         _queuedEvents[eventName] = undefined;
      }
   }
};

var addEventListener = function(eventName, callback) {
   if (!_viewCreated) {
      queueEventHandler(eventName, callback);
   } else if (externalVideoEventIsSupported(eventName)) {
      _smartPlayerView.addMediaEventListener(eventName, callback);
   } else {
      _smartPlayerView.addEventListener(eventName, callback);
   }
};

var removeEventListener = function(eventName, callback) {
   if (!_viewInitialized) {
      dequeueEventHandler(eventName, callback);
   } else if (externalVideoEventIsSupported(eventName)) {
      _smartPlayerView.removeMediaEventListener(eventName, callback);
   } else {
      _smartPlayerView.removeEventListener(eventName, callback);
   }
};

var getCurrentSrc = function() {
   return _viewInitialized ? _smartPlayerView.currentSrc : playerConfiguration.getMediaSrc();
};

var getCurrentTime = function() {
   return _smartPlayerView.currentTime;
};

var setCurrentTime = function(timeInSeconds) {
   _smartPlayerView.currentTime = timeInSeconds;
};

var getDuration = function() {
   return _smartPlayerView.duration;
};

var getEnded = function() {
   return _smartPlayerView.ended;
};

var getPaused = function() {
   return _smartPlayerView.paused;
};

var getPlaybackRate = function() {
   return _smartPlayerView.playbackRate;
};

var setPlaybackRate = function(playbackRate) {
   if (_viewInitialized) {
      _smartPlayerView.playbackRate = playbackRate;
   } else {
      _queuedExternalConfiguration.playbackRate = playbackRate;
   }
};

var getBuffered = function() {
   return _smartPlayerView.buffered;
};

var getPlayed = function() {
   return _smartPlayerView.played;
};

var getSeekable = function() {
   return _smartPlayerView.seekable;
};

var getSeeking = function() {
   return _smartPlayerView.seeking;
};

var getVolume = function() {
   return _smartPlayerView.volume;
};

var setVolume = function(volumeValue) {
   if (_viewInitialized) {
      _smartPlayerView.volume = volumeValue;
   } else {
      _queuedExternalConfiguration.volume = volumeValue;
   }
};

var getMuted = function() {
   return _viewInitialized ? _smartPlayerView.muted : _queuedExternalConfiguration.muted;
};

var setMuted = function(isMuted) {
   if (_viewInitialized) {
      _smartPlayerView.muted = isMuted;
   } else {
      _queuedExternalConfiguration.muted = isMuted;
   }
};

var isInFullScreenMode = function() {
   return _smartPlayerView.isInFullScreenMode();
};

var getScrubbing = function() {
   return _smartPlayerView.getScrubbing();
};

var setScrubbing = function(isScrubbing) {
   _smartPlayerView.setScrubbing(isScrubbing);
};

var getSafeSeekTime = function(time) {
   return _smartPlayerView.getSafeSeekTime(time);
};

var getCaptionsVisible = function() {
   return _smartPlayerView.getCaptionsVisible();
};

var setCaptionsVisible = function(isVisible) {
   _smartPlayerView.setCaptionsVisible(isVisible);
};

var getIsYouTube = function() {
   return _mediaList ? mediaPlayerController.getPlayerType(_mediaList) === playerType.YOUTUBE : false;
};

var getCaptionCollection = function() {
   return _captionService.captionTrack.captionCollection;
};

var getCaptionTrack = function() {
   return _captionService.captionTrack;
};

var setCaptionTrack = function(value) {
   _captionService.captionTrack = value;
};

var getTocChapters = function() {
   if (!_xmp || !_xmp.hasTocItems) {
      return [];
   }
   return _xmp.tocItemArray;
};

var getProgressBarDisplayWidth = function() {
   return _smartPlayerView ? _smartPlayerView.progressBarDisplayWidth : 0;
};

var getCurrentDisplayMode = function() {
   return _smartPlayerView && _smartPlayerView.currentDisplayMode;
};

export default Object.defineProperties({
   init: init,
   destroy: destroy,
   getSmartPlayerRootElement: getSmartPlayerRootElement,
   getVersion: getVersion,
   jsSeekTime: setCurrentTime,
   jsPlay: playMedia,
   play: playMedia,
   pause: pauseMedia,
   getCurrentSrc: getCurrentSrc,
   getCurrentTime: getCurrentTime,
   setCurrentTime: setCurrentTime,
   getDuration: getDuration,
   getEnded: getEnded,
   getPaused: getPaused,
   getPlaybackRate: getPlaybackRate,
   setPlaybackRate: setPlaybackRate,
   getBuffered: getBuffered,
   getPlayed: getPlayed,
   getSeekable: getSeekable,
   getSeeking: getSeeking,
   getVolume: getVolume,
   setVolume: setVolume,
   getMuted: getMuted,
   setMuted: setMuted,
   isInFullScreenMode: isInFullScreenMode,
   getScrubbing: getScrubbing,
   setScrubbing: setScrubbing,
   getSafeSeekTime: getSafeSeekTime,
   addEventListener: addEventListener,
   removeEventListener: removeEventListener,
   triggerEvent: triggerEvent,
   getCaptionsVisible: getCaptionsVisible,
   setCaptionsVisible: setCaptionsVisible,
   getIsYouTube: getIsYouTube,
   getCaptionCollection: getCaptionCollection,
   getCaptionTrack: getCaptionTrack,
   setCaptionTrack: setCaptionTrack,
   getTocChapters: getTocChapters,
   getProgressBarDisplayWidth: getProgressBarDisplayWidth,
   getCurrentDisplayMode: getCurrentDisplayMode
}, {});
