import playerStringNames from '../../constants/player-string-names';
import localizationStrings from '../../models/localization-strings';
import events from '../../constants/events';
import templateGenerator from '../../utils/template-generator';
import fullscreenHelper from '../../utils/fullscreen-helper';
import windowWrapper from '../../../common/window-wrapper';
import playerControlsType from '../../constants/player-controls-type';
import mediaViewType from '../../constants/media-view-type';
import deviceInfo from '../../utils/device-info';

/**
 *  @memberof TSC
 *  @implement MediaView
 *  @class ImageView
 *  @classdesc Image View @param {jQuery} $rootContainer - Smart Player main container jquery element
 *  @param {jQuery} $rootContainer - Smart Player main container jquery element
 *  @param {jQuery} $mediaContainer - Smart Player media container jquery element
 *  @param {string} containerElementUniqueId - ID for container element
 *  @param {string} imageSrc - Image source
*/
var ImageView = function($rootContainer, $mediaContainer, containerElementUniqueId, imageSrc) {
   var _$imageEl;
   var _imageTapActive = true; // default to active
   var _$document = windowWrapper.getDocument(true);

   var onImageError = function(e) {
      e.preventDefault();
      $mediaContainer.trigger(events.Media.Error, {errorString: localizationStrings.getPlayerString(playerStringNames.mediaNotFound)});
      _$imageEl.hide();
   };

   var setBackgroundImage = function() {
      _$imageEl.attr('src', imageSrc);
   };

   var switchToFullscreenMode = function() {
      fullscreenHelper.switchToFullscreenMode($rootContainer[0]);
   };

   var exitFullscreenMode = function() {
      fullscreenHelper.exitFullscreenMode();
   };

   var onFullscreenChange = function() {
      if (_$document[0].fullscreenElement) {
         $rootContainer.addClass('fullscreen-mode');
      } else {
         $rootContainer.removeClass('fullscreen-mode');
      }
   };

   var initDom = function() {
      var imageViewMarkup = templateGenerator.generateImageMarkup(containerElementUniqueId);
      $mediaContainer.html(imageViewMarkup);
      _$imageEl = $mediaContainer.find('.smart-player-image-media');
   };

   var createView = function() {
      initDom($mediaContainer);
   };

   var loadImage = function() {
      setBackgroundImage();

      // simulate the loadedmetadata html5 video events, must be dispatchEvent as jQuery events dont propagate to addEventListener
      _$imageEl[0].dispatchEvent(new Event(events.ExternalVideo.VideoLoadedMetadata));
      _$imageEl[0].dispatchEvent(new Event(events.ExternalVideo.VideoLoadedData));
   };

   var destroy = function() {
      _$imageEl.off('pointerup', onImageTouchToggle);
      _$imageEl.off('click', switchToFullscreenMode);
      _$imageEl.off('error', onImageError);
      _$document.off('fullscreenchange', onFullscreenChange);
      $rootContainer.off(events.External.SwitchToFullscreen, switchToFullscreenMode);
      $rootContainer.off(events.External.ExitFullscreen, exitFullscreenMode);
   };

   var onImageTouchToggle = function() {
      _imageTapActive = !_imageTapActive;
      if (_imageTapActive) {
         $rootContainer.trigger(events.Images.PointerOver);
      } else {
         $rootContainer.trigger(events.Images.PointerLeave);
      }
   };

   var initializeHandlers = function() {
      if (deviceInfo.isTouchInterface()) {
         _$imageEl.on('pointerup', onImageTouchToggle);
      }

      _$imageEl.on('click', switchToFullscreenMode);
      _$imageEl.on('error', onImageError);
      _$document.on('fullscreenchange', onFullscreenChange);
      $rootContainer.on(events.External.SwitchToFullscreen, switchToFullscreenMode);
      $rootContainer.on(events.External.ExitFullscreen, exitFullscreenMode);
   };

   createView();
   initializeHandlers();

   var noOp = function() {}; // eslint-disable-line
   var hotfixViewAndControlsCSS = function($controlsView) {}; // eslint-disable-line

   return Object.defineProperties({
      hotfixViewAndControlsCSS: hotfixViewAndControlsCSS,
      // these are only defined on the image view
      exitFullscreenMode: exitFullscreenMode,
      switchToFullscreenMode: switchToFullscreenMode,
      // the rest of these are standard media element view
      showCaptionTrack: noOp,
      hideCaptionTrack: noOp,
      addEventListener: noOp,
      removeEventListener: noOp,
      reshowVideoAfterClickToReplay: noOp,
      processVideoEnd: noOp,
      loadMedia: loadImage,
      destroy: destroy
   }, {
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.ImageView
       *  @property {jQuery} mediaWrapper - jQuery wrapper of the imagre element.
       */
      mediaWrapper: {
         get: function() {
            return _$imageEl;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.ImageView
       *  @property {HTMLImageElement} mediaElement - HTML image element
       */
      mediaElement: {
         get: function() {
            return _$imageEl[0];
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.ImageView
       *  @property {Boolean} containsWebTrackCaptions - Does the view contain web track captions?
       */
      containsWebTrackCaptions: {
         get: function() {
            return false;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.PlaylistElementView
       *  @property {int} currentTimeInMilliseconds - The current time of the media, in milliseconds
       */
      currentTimeInMilliseconds: {
         get: function() {
            return 0;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.ImageView
       *  @property {Number} mediaWidth - image width
       */
      mediaWidth: {
         get: function() {
            return this.mediaElement.naturalWidth;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.ImageView
       *  @property {Number} mediaHeight - image height
       */
      mediaHeight: {
         get: function() {
            return this.mediaElement.naturalHeight;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.ImageView
       *  @property {Boolean}  - allows dragging extension content
       */
      canDragContentOnVideo: {
         get: function() {
            return false;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.ImageView
       *  @property {string} playerControlType - Type of player controls to use
       */
      playerControlType: {
         get: function() {
            return playerControlsType.none;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.ImageView
       *  @property {string} type - Media view type
       */
      type: {
         get: function() {
            return mediaViewType.image;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.ImageView
       *  @property {boolean} supportsClickToPlayControls - Does view support click to play controls over media
       */
      supportsClickToPlayControls: {
         get: function() {
            return false;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.ImageView
       *  @property {boolean} supportsSCORM - Does view support SCORM
       */
      supportsSCORM: {
         get: function() {
            return false;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.ImageView
       *  @property {TSC.VideoPlaybackQualityMetrics} playbackQualityMetrics - the playback quality metrics set up for this view (may be null)
       */
      playbackQualityMetrics: {
         get: function() {
            return null;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.ImageView
       *  @property {string} currentSrc - The current source path of the media
       */
      currentSrc: {
         get: function() {
            return imageSrc;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.ImageView
       *  @property {Number} currentTime - The current time for the media
       */
      currentTime: {
         get: function() {
            return 0;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.ImageView
       *  @property {Number} duration - The duration of the media
       */
      duration: {
         get: function() {
            return 0;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.ImageView
       *  @property {Boolean} ended - Has the media ended
       */
      ended: {
         get: function() {
            return false;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.ImageView
       *  @property {Boolean} paused - Is the media paused
       */
      paused: {
         get: function() {
            return false;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.ImageView
       *  @property {Boolean} seeking - Is the media seeking
       */
      seeking: {
         get: function() {
            return false;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.ImageView
       *  @property {Number} volume - The volume of the media
       */
      volume: {
         get: function() {
            return 1;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.ImageView
       *  @property {Boolean} muted - Is the media muted
       */
      muted: {
         get: function() {
            return false;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.ImageView
       *  @property {Number} playbackRate - Playback rate of the media
       */
      playbackRate: {
         get: function() {
            return 1;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.ImageView
       *  @property {Array} buffered - THe array of buffered segments
       */
      buffered: {
         get: function() {
            return [];
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.ImageView
       *  @property {Array} played - THe array of media segments that have been played
       */
      played: {
         get: function() {
            return [];
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.ImageView
       *  @property {Array} seekable - THe array of media segments that are seekable
       */
      seekable: {
         get: function() {
            return [];
         }
      }
   });
};

export default {
   /**
    *  Factory method that returns a new ImageView object.
    *  @function create
    *  @memberof TSC.ImageView
    *  @static
    *  @param {jQuery} $rootContainer - Smart Player main container jquery element
    *  @param {jQuery} $mediaContainer - Smart Player media container jquery element
    *  @param {string} containerElementUniqueId - ID for container element
    *  @param {string} imageSrc - Image source
    *  @return new ImageView instance
    */
   create: ImageView
};
