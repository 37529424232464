var EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

var htmlEncode = function(text) {
   if (typeof text === 'string') {
      return text.replace(/&/g, '&amp;')
         .replace(/</g, '&lt;')
         .replace(/>/g, '&gt;')
         .replace(/"/g, '&quot;')
         .replace(/'/g, '&apos;');
   }
   return text;
};

var isValidEmail = function(email) {
   return EMAIL_REGEX.test(email);
};

export default {
   htmlEncode: htmlEncode,
   isValidEmail: isValidEmail
};
