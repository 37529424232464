import $ from 'jquery';
import Promise from 'promise-polyfill';
import playerConfiguration from '../models/player-configuration';
import mediaType from '../constants/media-type';
import mediaMimeType from '../constants/media-mime-type';
import Media from '../models/media';

var PLAYLIST_EXT = 'm3u';

var getFileExtension = function(fileName) {
   return fileName.split('#')[0].split('?')[0].split('.').pop().toLowerCase();
};

var findMediaMimeTypeByRequestHeader = function(mediaSource) {
   return new Promise(function(resolve) {
      $.ajax({
         type: 'HEAD',
         url: mediaSource
      }).done(function(data, textStatus, jqXHR) {
         var contentType = jqXHR.getResponseHeader('content-type') || mediaMimeType.UNKNOWN;
         resolve(contentType);
      }).fail(function() {
         resolve(mediaMimeType.UNKNOWN);
      });
   });
};

var inferMimeTypeFromFileExtension = function(ext) {
   switch (ext) {
      case PLAYLIST_EXT:
         return mediaMimeType.M3U;
      case 'mp4':
      case 'm4v':
      case 'mov':
         return mediaMimeType.MP4;
      case 'webm':
         return mediaMimeType.WEBM;
      case 'ogv':
         return mediaMimeType.OGV;
      case 'jpg':
      case 'jpeg':
         return mediaMimeType.JPEG;
      case 'png':
         return mediaMimeType.PNG;
      case 'gif':
         return mediaMimeType.GIF;
      default:
         return mediaMimeType.UNKNOWN;
   }
};

var mediaTypeFromMimeType = function(mimeType) {
   switch (mimeType) {
      case mediaMimeType.M3U:
         return mediaType.PLAYLIST;
      case mediaMimeType.MP4:
         return mediaType.MP4;
      case mediaMimeType.WEBM:
         return mediaType.WEBM;
      case mediaMimeType.OGV:
         return mediaType.OGG;
      case mediaMimeType.PNG:
         return mediaType.PNG;
      case mediaMimeType.GIF:
         return mediaType.GIF;
      case mediaMimeType.JPEG:
         return mediaType.JPG;
      default:
         return mediaType.UNKNOWN;
   }
};

var loadPlaylistFile = function(playlistSrc) {
   return $.ajax({
      type: 'GET',
      url: playlistSrc,
      isLocal: true,
      dataType: 'text'
   });
};

/**
 * @memberof TSC
 * @class MediaListParser
 * @classdesc Parse media sources.
 * @param {Array} mediaSources - List of media source strings or objects.
 * @return new MediaListParser instance
 */
var MediaListParser = function(mediaSources) {
   var _mediaList = [];
   var _ready = null;
   var _mediaProcessedPromises = [];

   var parseM3UData = function(playlistData) {
      return new Promise(function(resolve, reject) {
         var playListMediaPromises = [];
         var playListMedia = [];
         var playlistMediaUrls = playlistData.split(/\r?\n/g).filter(function(value) {
            return value !== '';
         });

         playlistMediaUrls.forEach(function(mediaSrc, index) {
            playListMediaPromises.push(parseMediaUrl(mediaSrc, playListMedia, index));
         });

         Promise.all(playListMediaPromises).then(function() {
            resolve(playListMedia);
         }).catch(function() {
            reject('Failed to create media objects for the playlist sources.');
         });
      });
   };

   var parseYouTubeMedia = function(youTubeMediaSource, index) {
      var media = Media.create(youTubeMediaSource, mediaType.YOUTUBE, mediaMimeType.UNKNOWN, youTubeMediaSource);
      _mediaList[index] = media;
      return Promise.resolve(media);
   };

   var parsePlaylistSource = function(playlistMediaSource, index) {
      return new Promise(function(resolve, reject) {
         var jHxr = loadPlaylistFile(playlistMediaSource);
         jHxr.done(function(data) {
            parseM3UData(data).then(function(playlistMedia) {
               var media = Media.create(playlistMedia, mediaType.PLAYLIST, mediaMimeType.M3U, playlistMediaSource);
               _mediaList[index] = media;
               resolve(media);
            }).catch(function() {
               reject();
            });
         });
         jHxr.fail(function() {
            reject('Failed to load the supplied playlist (' + playlistMediaSource + ')');
         });
      });
   };

   var parseMediaUrl = function(mediaSource, mediaList, index) {
      return new Promise(function(resolve, reject) {
         var media = null;

         if (playerConfiguration.getContentType()) {
            media = Media.create(mediaSource, mediaTypeFromMimeType(playerConfiguration.getContentType()), playerConfiguration.getContentType(), mediaSource);
            mediaList[index] = media;
            resolve(media);
         } else {
            var inferredMimeType = inferMimeTypeFromFileExtension(getFileExtension(mediaSource));
            if (inferredMimeType === mediaMimeType.UNKNOWN) {
               findMediaMimeTypeByRequestHeader(mediaSource).then(function(mimeType) {
                  if (mimeType !== mediaMimeType.UNKNOWN) {
                     media = Media.create(mediaSource, mediaTypeFromMimeType(mimeType), mimeType, mediaSource);
                     mediaList[index] = media;
                     resolve(media);
                  } else {
                     reject('Could not determine the content type of media (' + mediaSource + ')');
                  }
               });
            } else {
               media = Media.create(mediaSource, mediaTypeFromMimeType(inferredMimeType), inferredMimeType, mediaSource);
               mediaList[index] = media;
               resolve(media);
            }
         }
      });
   };

   var parseMedia = function(mediaSource, index) {
      var youtubeRegex = /youtu(?:\.be|be\.com)\//;

      if (youtubeRegex.test(mediaSource)) {
         return parseYouTubeMedia(mediaSource, index);
      }

      if (getFileExtension(mediaSource) === PLAYLIST_EXT) {
         return parsePlaylistSource(mediaSource, index);
      }

      return parseMediaUrl(mediaSource, _mediaList, index);
   };

   mediaSources.forEach(function(source, index) {
      _mediaProcessedPromises.push(parseMedia(source, index));
   });
   _ready = Promise.all(_mediaProcessedPromises);

   return Object.defineProperties({}, {
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.MediaListParser
       *  @property {Array} ready - List of Media objects.
       */
      mediaList: {
         get: function() {
            return [].concat(_mediaList);
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.MediaListParser
       *  @property {Promise} ready - Promise that resolves when media sources has been parsed.
       */
      ready: {
         get: function() {
            return _ready;
         }
      }
   });
};

export default {
   /**
    *  Factory method that returns a new MediaListParser object.
    *  @function create
    *  @memberof TSC.MediaListParser
    *  @static
    *  @param {Array} mediaSources - List of media source strings or objects.
    *  @return new MediaListParser instance
    */
   create: MediaListParser
};
