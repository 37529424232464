import languageCodes from '../../constants/language-codes';
import playerStringNames from '../../constants/player-string-names';

var strings = {};
strings[playerStringNames.xmpError] = 'There seems to be a problem accessing certain features of this video. Please contact the video Author.';
strings[playerStringNames.xmpSecurity] = 'Certain features in your video are currently disabled due to Local Network Security. These features work correctly when the video is played from a Website or Server.';
strings[playerStringNames.videoNotSupported] = 'Your browser cannot play this video.';
strings[playerStringNames.noWebMSupport] = 'This browser does not support WebM video.';
strings[playerStringNames.search] = 'Search';
strings[playerStringNames.searchPlaceholder] = 'Search...';
strings[playerStringNames.playerRateText] = 'Playback Speed';
strings[playerStringNames.playerRateOptionsText] = {
   0.25: '0.25',
   0.5: '0.5',
   0.75: '0.75',
   1: 'normal',
   1.25: '1.25',
   1.5: '1.5',
   1.75: '1.75',
   2.0: '2.0'
};
strings[playerStringNames.ccLanguageText] = 'Closed Captions';
strings[playerStringNames.searchDisabledFullScreen] = 'Search is disabled in fullscreen mode.';
strings[playerStringNames.mediaNotFound] = 'We\'re sorry, but we are unable to connect to this content. Please try refreshing the page or <a href=\'https://www.techsmith.com/redirect.asp?target=media_not_found&ver=4.*.*&lang=enu&os=na&product=smartplayer\' target=\'_blank\'> click here for more help.</a>';
strings[playerStringNames.videoLoading] = 'The video is loading.';
strings[playerStringNames.close] = 'Close';
strings[playerStringNames.txtRequiredMessage] = 'The content owner requests the following:';
strings[playerStringNames.txtSkip] = 'Continue without Questions';
strings[playerStringNames.txtBegin] = 'Submit and Continue';
strings[playerStringNames.txtEnterFirstName] = 'Enter first name';
strings[playerStringNames.txtEnterLastName] = 'Enter last name';
strings[playerStringNames.txtEnterEmail] = 'Enter email address';
strings[playerStringNames.txtHotspot] = 'Hotspot';
strings[playerStringNames.txtHotspotScreenReaderAlert] = '{{numberOfHotspots}} new Hotspot';
strings[playerStringNames.txtHotspotScreenReaderAlertPlural] = '{{numberOfHotspots}} new Hotspots';
strings[playerStringNames.txtJumpToMedia] = 'Jump to media';
strings[playerStringNames.txtJumpToTime] = 'Jump to time';
strings[playerStringNames.txtOpenLink] = 'Open link';
strings[playerStringNames.txtOpenLinkHeader] = 'This video would like to send you to:';
strings[playerStringNames.txtOpenLinkSubHeader] = '';
strings[playerStringNames.txtReview] = 'Replay Last Section';
strings[playerStringNames.txtReplayVideo] = 'Replay Video';
strings[playerStringNames.txtAnswerQuestion] = 'Take Quiz Now';
strings[playerStringNames.txtPrev] = 'Previous';
strings[playerStringNames.txtNext] = 'Next';
strings[playerStringNames.txtHideQuiz] = 'Hide';
strings[playerStringNames.txtSubmit] = 'Submit Answers';
strings[playerStringNames.txtSubmittingAnswers] = 'Submitting answers.';
strings[playerStringNames.txtContinue] = 'Continue';
strings[playerStringNames.txtReviewAnswer] = 'View Answers';
strings[playerStringNames.txtBadImageQuiz] = 'We\'re sorry, the requested quiz is not compatible with images and could not be loaded.  Please contact the author of this quiz.';
strings[playerStringNames.txtVideoQuizNetworkErrorMessage] = 'We\'re sorry, a network error has prevented the quiz from loading. The video is available to view. To try and reload the quiz, refresh the browser page or check your network connection.';
strings[playerStringNames.txtImageQuizNetworkErrorMessage] = 'We’re sorry, a network error has prevented the quiz from loading. The image is available to view. To try and reload the quiz, refresh the browser page or check your network connection.';
strings[playerStringNames.txtQuizErrorMessage] = 'We\'re attempting to save your response. Please make sure you have an active internet connection.';
strings[playerStringNames.txtQuizOutOfDateMessage] = 'The quiz questions have changed. Please refresh the player to get the latest quiz.';
strings[playerStringNames.txtQuizBadDefinitionMessage] = 'Invalid Quiz. Unable to submit quiz feedback. Please refer the video owner to <a href="https://www.techsmith.com/redirect.asp?target=bad_quiz_definition&ver=5.*.*&lang=enu&os=na&product=smartplayer" target="_blank">this help article</a>.';
strings[playerStringNames.txtQuizOutOfDateRefreshButtonText] = 'Refresh Player';
strings[playerStringNames.txtLoaderMessageDefinition] = 'The quiz is loading.';
strings[playerStringNames.txtQuestionCount] = 'Question {{currentQuestion}} of {{totalQuestions}}';
strings[playerStringNames.txtResults] = 'Results: ';
strings[playerStringNames.txtCorrectString] = 'Correct';
strings[playerStringNames.txtIncorrectString] = 'Incorrect';
strings[playerStringNames.txtCorrect] = '{{numberCorrect}} Correct';
strings[playerStringNames.txtIncorrect] = '{{numberIncorrect}} Incorrect';
strings[playerStringNames.txtYourAnswer] = 'Your answer: ';
strings[playerStringNames.txtCorrectAnswer] = 'Correct answer: ';
strings[playerStringNames.txtVisitSite] = 'Visit Site';
strings[playerStringNames.accessBtnAudioDescription] = 'Audio Description';
strings[playerStringNames.accessBtnClearSearch] = 'Clear Search';
strings[playerStringNames.accessBtnRewind] = 'Skip back {{duration}} seconds';
strings[playerStringNames.accessBtnForward] = 'Skip forward {{duration}} seconds';
strings[playerStringNames.accessBtnPlay] = 'Play Video';
strings[playerStringNames.accessBtnPrevious] = 'Previous';
strings[playerStringNames.accessBtnNext] = 'Next';
strings[playerStringNames.accessBtnProgressBar] = 'Progress bar slider';
strings[playerStringNames.accessBtnVolume] = 'Mute';
strings[playerStringNames.accessTxtVolume] = 'Volume';
strings[playerStringNames.accessBtnClosedCaption] = 'Closed Caption';
strings[playerStringNames.accessBtnTableOfContents] = 'Show Chapters (T)';
strings[playerStringNames.accessBtnSettings] = 'Player Settings';
strings[playerStringNames.accessBtnPip] = 'Picture-in-Picture';
strings[playerStringNames.accessBtnFullScreen] = 'Full Screen';
strings[playerStringNames.accessBtnFullScreenExpand] = 'Open in new tab';
strings[playerStringNames.accessBtnTakeQuiz] = 'Click to take the quiz.';
strings[playerStringNames.accessBtnShowQuiz] = 'Show Quiz';
strings[playerStringNames.accessBtnHideQuiz] = 'Hide Quiz';
strings[playerStringNames.accessBtnPreviousQuestion] = 'Previous question';
strings[playerStringNames.accessBtnNextQuestion] = 'Next question';
strings[playerStringNames.accessQuizHidden] = 'Quiz has been hidden';
strings[playerStringNames.accessQuizShown] = 'Quiz is now displayed';
strings[playerStringNames.accessBtnShowAnswer] = 'Show Answers';
strings[playerStringNames.accessBtnHideAnswer] = 'Hide Answers';
strings[playerStringNames.accessBtnAnswersSubmitted] = 'Answers Submitted!';
strings[playerStringNames.accessBtnWereNotGraded] = ' were not graded.';
strings[playerStringNames.accessBtnWasNotGraded] = ' was not graded.';
strings[playerStringNames.accessBtnCorrectOutOf] = ' correct out of ';
strings[playerStringNames.accessBtnGradedQuestions] = ' graded questions.';
strings[playerStringNames.accessBtnClickToReplayLink] = 'Replay';
strings[playerStringNames.accessResultsLabelCorrectSuffix] = 'is correct.';
strings[playerStringNames.accessResultsLabelIncorrectSuffix] = 'is incorrect.';
strings[playerStringNames.accessResultsLabelNoResponseSuffix] = 'you did not answer this question.';
strings[playerStringNames.accessResultsLabelNoResponseGradedSuffix] = 'you did not answer this question and it has been marked incorrect.';
strings[playerStringNames.scormApiNotFoundMessage] = 'Unable to locate the LMS\'s API Implementation.\nCommunication with the LMS will not occur.';
strings[playerStringNames.accessListTableOfContents] = 'Chapters';
strings[playerStringNames.accessListSearchResults] = 'Search results in order of appearance';
strings[playerStringNames.accessListNoSearchResults] = 'No results found';
strings[playerStringNames.accessQuestionSetConfirmationTitle] = 'Quiz';
strings[playerStringNames.accessVideoPausedForQuiz] = 'Video paused for quiz';
strings[playerStringNames.accessQuizResults] = 'Quiz results';
strings[playerStringNames.accessQuestionFeedbackContext] = 'Question feedback: ';
strings[playerStringNames.accessProgressBarTimeConjunction] = ' of ';
strings[playerStringNames.accessPlayerSettingsClose] = 'Close Player Settings';
strings[playerStringNames.accessBtnTheaterMode] = 'Theater Mode';
strings[playerStringNames.accessBtnExitTheaterMode] = 'Default Mode';
strings[playerStringNames.confirmOpenUrlModalScreenReaderAlertText] = 'The video would like to open the following url URL_TOKEN.';
strings[playerStringNames.confirmOpenUrlConfirmButtonScreenReaderText] = 'Continue to new website';
strings[playerStringNames.fastForwardDisabledScreenReaderText] = 'Fast forward has been disabled for this video';
strings[playerStringNames.scormCompleteVideoLater] = 'Complete video later';
strings[playerStringNames.scormCompleteVideo] = 'Complete video';
strings[playerStringNames.scormExitVideo] = 'Exit video';
strings[playerStringNames.scormConfirmCompleteModalText] = 'Are you sure you want to exit the video?';
strings[playerStringNames.scormConfirmCompleteModalResumeVideoText] = 'The video will resume where you left off next time you open the video.';
strings[playerStringNames.scormConfirmCompleteModalRestartVideoText] = 'The video will restart at the beginning the next time. You will lose your current progress.';
strings[playerStringNames.scormConfirmCompleteModalFailedToSaveProgressText] = 'There was a problem saving your progress. The video will start from the beginning the next time you watch this video.';
strings[playerStringNames.scormConfirmCompleteModalDismissButtonText] = 'Return to video';
strings[playerStringNames.scormConfirmCompleteModalDismissButtonScreenReaderText] = 'Return to video';
strings[playerStringNames.scormConfirmCompleteModalConfirmButtonText] = 'Exit video';
strings[playerStringNames.scormConfirmCompleteModalConfirmButtonScreenReaderText] = 'Exit video';
strings[playerStringNames.scormSessionEndedText] = 'You can close the tab or head back to your course.';
strings[playerStringNames.searchOrJumpTo] = 'Search or jump to.';
strings[playerStringNames.searchTextCleared] = 'Search text cleared.';
strings[playerStringNames.searchResultsUpdated] = 'Search results updated. {{number}} results.';
strings[playerStringNames.tableOfContentsDisplayed] = 'Chapters displayed.';
strings[playerStringNames.tableOfContentsHidden] = 'Chapters hidden.';
strings[playerStringNames.defaultMediaTitle] = 'TechSmith Smart Player';
strings[playerStringNames.hideThumbnailDueToQuiz] = 'Take quiz to preview';
strings[playerStringNames.quizAtTimeHeading] = 'Quiz at';
strings[playerStringNames.defaultFirstChapterName] = 'Intro';
strings[playerStringNames.acknowledgementLinkName] = 'Acknowledgements';

var getLanguage = function() {
   return languageCodes.ENGLISH;
};

var getReadableTimeByLocale = function(hours, minutes, seconds) {
   var timeStringList = [];
   if (hours > 0) {
      var hoursReadableString = hours + ' hour';
      hoursReadableString += hours > 1 ? 's' : '';
      timeStringList.push(hoursReadableString);
   }
   if (minutes > 0) {
      var minutesReadableString = minutes + ' minute';
      minutesReadableString += minutes > 1 ? 's' : '';
      timeStringList.push(minutesReadableString);
   }
   if (seconds > 0) {
      var secondsReadableString = seconds + ' second';
      secondsReadableString += seconds > 1 ? 's' : '';
      timeStringList.push(secondsReadableString);
   }
   return timeStringList.join(' and ');
};

export default Object.defineProperties({
   getReadableTimeByLocale: getReadableTimeByLocale
}, {
   language: {
      get: getLanguage
   },
   strings: {
      get: function() {
         return strings;
      }
   }
});
