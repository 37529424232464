import $ from 'jquery';
import BubbleView from './bubble-view';
import highlightDisabler from '../utils/highlight-disabler';
import templateGenerator from '../utils/template-generator';

var _containerId = '.start-question-set-confirmation-container';
var _superView = BubbleView.create(_containerId);
var _reviewControlId = '.review-button';
var _confirmControlId = '.confirm-button';

export default {
   getViewMarkup: function(reviewText, confirmationText) {
      return templateGenerator.generateQuestionSetConfirmationMarkup(reviewText, confirmationText) + _superView.getOverlayMarkup();
   },
   getReviewControlRef: function() {
      return _reviewControlId;
   },
   getConfirmControlRef: function() {
      return _confirmControlId;
   },
   hideView: function() {
      _superView.hideView();
   },
   showView: function() {
      _superView.showView(true);
      var $confirmControl = $(_containerId).find(_confirmControlId);
      $confirmControl.focus();
      highlightDisabler.disableHighlights($confirmControl);
   },
   removeView: _superView.removeView,
   updateViewPosition: _superView.updateViewPosition,
   setBottomOffset: _superView.setBottomOffset,
   pointAt: _superView.pointAt

};
