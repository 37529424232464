import $ from 'jquery';
import deviceInfo from '../utils/device-info';
import viewHelper from '../utils/view-helper';
import textSanitizer from '../utils/text-sanitizer';
import events from '../constants/events';
import templateGenerator from '../utils/template-generator';
import playerConfiguration from '../models/player-configuration';
import sessionStorageWrapper from '../utils/session-storage-wrapper';
import windowWrapper from '../../common/window-wrapper';

var _$window = $(window);
var FIRST_NAME_CLASS = 'first_name';
var LAST_NAME_CLASS = 'last_name';
var rightParenRegex = /\)/gi;
var leftParenRegex = /\(/gi;

/**
 * @memberof TSC
 * @class QuizSetupView
 * @classdesc Quiz Setup View
 * @param {JQuery} $rootContainer - Smart player container element.
 */
var QuizSetupView = function($rootContainer) {
   var _$rootContainer = $rootContainer;
   var _$quizSetupView = null;
   var _$firstNameInput = null;
   var _$lastNameInput = null;
   var _$emailInput = null;
   var _$beginButton = null;
   var _$skipButton = null;

   var createView = function() {
      var quizSetupMarkup = templateGenerator.generateQuizSetupMarkup();
      _$quizSetupView = $($.parseHTML(quizSetupMarkup));

      _$firstNameInput = _$quizSetupView.find('.' + FIRST_NAME_CLASS);
      _$lastNameInput = _$quizSetupView.find('.' + LAST_NAME_CLASS);
      _$emailInput = _$quizSetupView.find('.email_address');
      _$beginButton = _$quizSetupView.find('.tsc_begin_button');
      _$skipButton = _$quizSetupView.find('.skip_control');

      _$rootContainer.trigger(events.Modals.Open);
   };

   var getKeys = function() {
      var hostname = windowWrapper.getLocation().hostname;
      return {
         first: hostname + '-first',
         last: hostname + '-last',
         email: hostname + '-email'
      };
   };

   var getUserInfo = function() {
      var keys = getKeys();

      var firstName = playerConfiguration.userInfo && playerConfiguration.userInfo.firstName;
      var lastName = playerConfiguration.userInfo && playerConfiguration.userInfo.lastName;
      var email = playerConfiguration.userInfo && playerConfiguration.userInfo.email;

      if (playerConfiguration.rememberUserInfo) {
         firstName = firstName || sessionStorageWrapper.getItem(keys.first);
         lastName = lastName || sessionStorageWrapper.getItem(keys.last);
         email = email || sessionStorageWrapper.getItem(keys.email);
      }

      return {
         firstName: firstName,
         lastName: lastName,
         email: email
      };
   };

   var cacheSelfIdentity = function(eventData) {
      var keys = getKeys();
      sessionStorageWrapper.setItem(keys.first, eventData.firstName);
      sessionStorageWrapper.setItem(keys.last, eventData.lastName);
      sessionStorageWrapper.setItem(keys.email, eventData.email);
   };

   var setupInitialValues = function() {
      var userInfo = getUserInfo();

      _$firstNameInput.val(userInfo.firstName);
      _$lastNameInput.val(userInfo.lastName);
      _$emailInput.val(userInfo.email);

      if (userInfo.firstName && userInfo.lastName && userInfo.email) {
         validateInput();
      }
   };

   var addPosterImageToBackground = function(posterSrc) {
      posterSrc = posterSrc.replace(rightParenRegex, '\\\)');
      posterSrc = posterSrc.replace(leftParenRegex, '\\\(');

      var url = 'url(' + posterSrc + ')';
      _$quizSetupView.css('background-image', url);
   };

   var validateInput = function() {
      var firstName = _$firstNameInput.val().replace(/ /g, '');
      var lastName = _$lastNameInput.val().replace(/ /g, '');
      var email = _$emailInput.val().replace(/ /g, '');

      if (firstName !== '' && lastName !== '' && email !== '' && textSanitizer.isValidEmail(email)) {
         _$beginButton.removeAttr('disabled');
         viewHelper.trapFocus(_$quizSetupView);
      } else {
         _$beginButton.attr('disabled', 'disabled');
      }
   };

   var destroy = function() {
      if (deviceInfo.isIPhoneOrIPod() || deviceInfo.isAndroid()) {
         _$window.off('resize', viewHelper.scrollActiveElementIntoView);
         _$firstNameInput.off('focus keydown', viewHelper.scrollIntoView);
         _$lastNameInput.off('focus keydown', viewHelper.scrollIntoView);
         _$emailInput.off('focus keydown', viewHelper.scrollIntoView);
      }

      _$quizSetupView.css('background-image', '');

      _$firstNameInput.off('keyup', validateInput);
      _$firstNameInput.off('change', validateInput);
      _$lastNameInput.off('keyup', validateInput);
      _$lastNameInput.off('change', validateInput);
      _$emailInput.off('keyup', validateInput);
      _$emailInput.off('change', validateInput);

      _$beginButton.off('click', beginQuiz);
      _$skipButton.off('click', skipQuiz);

      _$rootContainer.trigger(events.Modals.Closed);

      viewHelper.removeFocusTrap();
   };

   var hideSkipButton = function() {
      _$skipButton.attr('disabled', 'disabled');
      _$skipButton.hide();
      viewHelper.trapFocus(_$quizSetupView);
   };

   var beginQuiz = function(event) {
      event.preventDefault();

      var eventData = {
         firstName: _$firstNameInput.val().replace(/ /g, ''),
         lastName: _$lastNameInput.val().replace(/ /g, ''),
         email: _$emailInput.val().replace(/ /g, '')
      };

      if (playerConfiguration.rememberUserInfo) {
         cacheSelfIdentity(eventData);
      }

      _$rootContainer.trigger(events.Quizzing.BeginClicked, eventData);
   };

   var skipQuiz = function(event) {
      event.preventDefault();
      _$rootContainer.trigger(events.Quizzing.SkipClicked, {});
   };

   var bindEvents = function() {
      if (deviceInfo.isIPhoneOrIPod() || deviceInfo.isAndroid()) {
         _$window.on('resize', viewHelper.scrollActiveElementIntoView);
         _$firstNameInput.on('focus keydown', viewHelper.scrollIntoView);
         _$lastNameInput.on('focus keydown', viewHelper.scrollIntoView);
         _$emailInput.on('focus keydown', viewHelper.scrollIntoView);
      }

      _$firstNameInput.on('keyup', validateInput);
      _$firstNameInput.on('change', validateInput);
      _$lastNameInput.on('keyup', validateInput);
      _$lastNameInput.on('change', validateInput);
      _$emailInput.on('keyup', validateInput);
      _$emailInput.on('change', validateInput);

      _$beginButton.on('click', beginQuiz);
      _$skipButton.on('click', skipQuiz);

      _$rootContainer.append(_$quizSetupView);

      viewHelper.trapFocus(_$quizSetupView);

      if (viewHelper.elementOrChildHasFocus($rootContainer)) {
         _$firstNameInput.focus();
      }
   };

   createView();
   setupInitialValues();
   bindEvents();
   if (playerConfiguration.getPosterImageSrc()) {
      addPosterImageToBackground(playerConfiguration.getPosterImageSrc());
   }

   return Object.defineProperties({
      hideSkipButton: hideSkipButton,
      destroy: destroy
   }, {
      $view: {
         get: function() {
            return _$quizSetupView;
         }
      }
   });
};

export default {
   /**
    *  Factory method that returns a new QuizSetupView object.
    *  @function create
    *  @memberof TSC.QuizSetupView
    *  @param {JQuery} $rootContainer - Smart player container element.
    *  @static
    *  @return QuizSetupView instance
    */
   create: QuizSetupView

};
