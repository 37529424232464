import languageCodes from '../../constants/language-codes';
import playerStringNames from '../../constants/player-string-names';

var strings = {};
strings[playerStringNames.xmpError] = 'このビデオの特定機能にアクセスする時に問題が発生したようです。ビデオの作成者に連絡してください。';
strings[playerStringNames.xmpSecurity] = 'ローカル ネットワークのセキュリティのために、ビデオの特定機能が現在無効になっています。これらの機能は、Web サイトまたはサーバーからビデオを再生すると正しく動作します。';
strings[playerStringNames.videoNotSupported] = 'お使いのブラウザではこのビデオを再生できません。';
strings[playerStringNames.noWebMSupport] = 'このブラウザは WebM ビデオをサポートしていません。';
strings[playerStringNames.search] = '検索';
strings[playerStringNames.searchPlaceholder] = '検索...';
strings[playerStringNames.playerRateText] = '再生速度';
strings[playerStringNames.playerRateOptionsText] = {
   0.25: '0.25',
   0.5: '0.5',
   0.75: '0.75',
   1: '標準',
   1.25: '1.25',
   1.5: '1.5',
   1.75: '1.75',
   2.0: '2.0'
};
strings[playerStringNames.ccLanguageText] = 'クローズド キャプション';
strings[playerStringNames.searchDisabledFullScreen] = 'フル画面モードでは検索機能は無効です。';
strings[playerStringNames.mediaNotFound] = '申し訳ありません。このコンテンツに接続できません。ページを再読み込みするか、<a href=\'https://www.techsmith.com/redirect.asp?target=media_not_found&ver=4.*.*&lang=jpn&os=na&product=smartplayer\' target=\'_blank\'>ここをクリックしてヘルプをご参照ください。</a>';
strings[playerStringNames.videoLoading] = 'ビデオを読み込んでいます。';
strings[playerStringNames.close] = '閉じる';
strings[playerStringNames.txtRequiredMessage] = 'コンテンツの所有者が以下の情報を要求しています:';
strings[playerStringNames.txtSkip] = '質問を入力せずに続行';
strings[playerStringNames.txtBegin] = '送信して続行';
strings[playerStringNames.txtEnterFirstName] = '名を入力してください';
strings[playerStringNames.txtEnterLastName] = '姓を入力してください';
strings[playerStringNames.txtEnterEmail] = 'メールアドレスを入力してください';
strings[playerStringNames.txtHotspot] = 'ホットスポット';
strings[playerStringNames.txtHotspotScreenReaderAlert] = '{{numberOfHotspots}} 個の新しいホットスポット';
strings[playerStringNames.txtHotspotScreenReaderAlertPlural] = '{{numberOfHotspots}} 個の新しいホットスポット';
strings[playerStringNames.txtJumpToMedia] = 'メディアへジャンプ';
strings[playerStringNames.txtJumpToTime] = '時間へジャンプ';
strings[playerStringNames.txtOpenLink] = 'リンクを開く';
strings[playerStringNames.txtOpenLinkHeader] = 'このビデオは次の';
strings[playerStringNames.txtOpenLinkSubHeader] = 'を開こうとしています。';
strings[playerStringNames.txtReview] = '最後に選択した範囲をもう一度再生';
strings[playerStringNames.txtReplayVideo] = 'ビデオをもう一度再生';
strings[playerStringNames.txtAnswerQuestion] = '今すぐクイズに解答する';
strings[playerStringNames.txtPrev] = '前へ';
strings[playerStringNames.txtNext] = '次へ';
strings[playerStringNames.txtHideQuiz] = '非表示';
strings[playerStringNames.txtSubmit] = '解答・回答の送信';
strings[playerStringNames.txtSubmittingAnswers] = '回答を送信中です。';
strings[playerStringNames.txtContinue] = '続行';
strings[playerStringNames.txtReviewAnswer] = '解答・回答を表示';
strings[playerStringNames.txtBadImageQuiz] = '申し訳ありません。このクイズは画像と互換性がなくロードできませんでした。このクイズの作成者にお問い合わせください。';
strings[playerStringNames.txtVideoQuizNetworkErrorMessage] = '申し訳ありませんが、ネットワーク エラーによりクイズを読み込めませんでした。ビデオは表示できます。クイズを再度読み込むには、ブラウザのページを更新するか、ネットワーク接続を確認してください。';
strings[playerStringNames.txtImageQuizNetworkErrorMessage] = '申し訳ありません。ネットワーク エラーによりクイズがロードされませんでした。画像は表示できます。クイズを再度ロードするには、ブラウザーのページを再読み込みするか、またはご使用のネットワーク接続を確認してください。';
strings[playerStringNames.txtQuizErrorMessage] = '返答の保存を試みています。インターネットに正しく接続しているか確認してください。';
strings[playerStringNames.txtQuizOutOfDateMessage] = 'クイズの質問が変更されました。最新のクイズを取得するには、Player を更新してください。';
strings[playerStringNames.txtQuizBadDefinitionMessage] = '<a href="https://www.techsmith.com/redirect.asp?target=bad_quiz_definition&ver=5.*.*&lang=enu&os=na&product=smartplayer" target="_blank">クイズが無効です。クイズのフィードバックを送信できません。ビデオの所有者にこのヘルプ記事を参照するように伝えてください。</a>';
strings[playerStringNames.txtQuizOutOfDateRefreshButtonText] = 'Player の更新';
strings[playerStringNames.txtLoaderMessageDefinition] = 'クイズを読み込んでいます。';
strings[playerStringNames.txtQuestionCount] = '問 {{currentQuestion}} ({{totalQuestions}} 問中)';
strings[playerStringNames.txtResults] = '結果: ';
strings[playerStringNames.txtCorrectString] = '正解';
strings[playerStringNames.txtIncorrectString] = '不正解';
strings[playerStringNames.txtCorrect] = '正解 {{numberCorrect}}';
strings[playerStringNames.txtIncorrect] = '不正解 {{numberIncorrect}}';
strings[playerStringNames.txtYourAnswer] = '解答/回答: ';
strings[playerStringNames.txtCorrectAnswer] = '正解: ';
strings[playerStringNames.txtVisitSite] = 'サイトを開く';
strings[playerStringNames.accessBtnAudioDescription] = '音声ガイド';
strings[playerStringNames.accessBtnClearSearch] = '検索をクリア';
strings[playerStringNames.accessBtnRewind] = '{{duration}} 秒戻します';
strings[playerStringNames.accessBtnForward] = '{{duration}} 秒送ります';
strings[playerStringNames.accessBtnPlay] = 'ビデオを再生する';
strings[playerStringNames.accessBtnPrevious] = '前へ';
strings[playerStringNames.accessBtnNext] = '次へ';
strings[playerStringNames.accessBtnProgressBar] = '進行状況バー スライダー';
strings[playerStringNames.accessBtnVolume] = 'ミュート';
strings[playerStringNames.accessTxtVolume] = '音量';
strings[playerStringNames.accessBtnClosedCaption] = 'クローズド キャプション';
strings[playerStringNames.accessBtnTableOfContents] = 'チャプターを表示(T)';
strings[playerStringNames.accessBtnSettings] = 'プレーヤー設定';
strings[playerStringNames.accessBtnPip] = 'Picture-In-Picture';
strings[playerStringNames.accessBtnFullScreen] = '全画面';
strings[playerStringNames.accessBtnFullScreenExpand] = '新しいタブで開く';
strings[playerStringNames.accessBtnTakeQuiz] = 'クリックしてクイズに答える。';
strings[playerStringNames.accessBtnShowQuiz] = 'クイズの表示';
strings[playerStringNames.accessBtnHideQuiz] = 'クイズの非表示';
strings[playerStringNames.accessBtnPreviousQuestion] = '前の問題';
strings[playerStringNames.accessBtnNextQuestion] = '次の問題';
strings[playerStringNames.accessQuizHidden] = 'クイズが非表示になりました';
strings[playerStringNames.accessQuizShown] = 'クイズが表示されました';
strings[playerStringNames.accessBtnShowAnswer] = '解答・回答の表示';
strings[playerStringNames.accessBtnHideAnswer] = '解答・回答の非表示';
strings[playerStringNames.accessBtnAnswersSubmitted] = '解答・回答を送信しました!';
strings[playerStringNames.accessBtnWereNotGraded] = ' スコアに含まれない質問。';
strings[playerStringNames.accessBtnWasNotGraded] = ' スコアに含まれない質問。';
strings[playerStringNames.accessBtnCorrectOutOf] = ' 問中 ';
strings[playerStringNames.accessBtnGradedQuestions] = ' スコアに含まれる質問。';
strings[playerStringNames.accessBtnClickToReplayLink] = '再生';
strings[playerStringNames.accessResultsLabelCorrectSuffix] = 'は正解です。';
strings[playerStringNames.accessResultsLabelIncorrectSuffix] = 'は不正解です。';
strings[playerStringNames.accessResultsLabelNoResponseSuffix] = 'この問題に答えていません。';
strings[playerStringNames.accessResultsLabelNoResponseGradedSuffix] = 'この問題に答えていないため、不正解と判定されました。';
strings[playerStringNames.scormApiNotFoundMessage] = 'LMS の API 実装が見つかりません\nLMS とコミュニケーションできません';
strings[playerStringNames.accessListTableOfContents] = 'チャプター';
strings[playerStringNames.accessListSearchResults] = '検索結果 (出現順)';
strings[playerStringNames.accessListNoSearchResults] = '結果が見つかりません';
strings[playerStringNames.accessQuestionSetConfirmationTitle] = 'クイズ';
strings[playerStringNames.accessVideoPausedForQuiz] = 'クイズの場所でビデオを一時停止しました';
strings[playerStringNames.accessQuizResults] = 'クイズの結果';
strings[playerStringNames.accessQuestionFeedbackContext] = '問題のフィードバック: ';
strings[playerStringNames.accessProgressBarTimeConjunction] = '/';
strings[playerStringNames.accessPlayerSettingsClose] = 'Player の設定を閉じる';
strings[playerStringNames.accessBtnTheaterMode] = 'シアター モード';
strings[playerStringNames.accessBtnExitTheaterMode] = 'デフォルト モード';
strings[playerStringNames.confirmOpenUrlModalScreenReaderAlertText] = 'ビデオは次の URL URL_TOKEN を開こうとしています。';
strings[playerStringNames.confirmOpenUrlConfirmButtonScreenReaderText] = '新しい Web サイトに進む';
strings[playerStringNames.fastForwardDisabledScreenReaderText] = 'このビデオでは早送りが無効になっています';
strings[playerStringNames.scormCompleteVideoLater] = '後でビデオを完成させる';
strings[playerStringNames.scormCompleteVideo] = 'ビデオを完成させる';
strings[playerStringNames.scormExitVideo] = 'ビデオを閉じる';
strings[playerStringNames.scormConfirmCompleteModalText] = 'ビデオを閉じますか?';
strings[playerStringNames.scormConfirmCompleteModalResumeVideoText] = '次に開いたとき、ビデオは閉じた場所から再開されます。';
strings[playerStringNames.scormConfirmCompleteModalRestartVideoText] = '次に開いたとき、ビデオは先頭から再開されます。現在の進行状況は失われます。';
strings[playerStringNames.scormConfirmCompleteModalFailedToSaveProgressText] = '進行状況を保存する際に問題がありました。次にこのビデオを見るとき、ビデオは先頭から再生されます。';
strings[playerStringNames.scormConfirmCompleteModalDismissButtonText] = 'ビデオに戻る';
strings[playerStringNames.scormConfirmCompleteModalDismissButtonScreenReaderText] = 'ビデオに戻る';
strings[playerStringNames.scormConfirmCompleteModalConfirmButtonText] = 'ビデオを閉じる';
strings[playerStringNames.scormConfirmCompleteModalConfirmButtonScreenReaderText] = 'ビデオを閉じる';
strings[playerStringNames.scormSessionEndedText] = 'タブを閉じるか、コースに戻ることができます。';
strings[playerStringNames.searchOrJumpTo] = '検索するかジャンプ先を指定してください。';
strings[playerStringNames.searchTextCleared] = '検索文字をクリアしました。';
strings[playerStringNames.searchResultsUpdated] = '検索結果を更新しました。結果は {{number}} 件です。';
strings[playerStringNames.tableOfContentsDisplayed] = 'チャプターが表示されました。';
strings[playerStringNames.tableOfContentsHidden] = 'チャプターが非表示になりました。';
strings[playerStringNames.defaultMediaTitle] = 'TechSmith Smart Player';
strings[playerStringNames.hideThumbnailDueToQuiz] = 'プレビューするにはクイズに答えてください';
strings[playerStringNames.quizAtTimeHeading] = 'クイズ:';
strings[playerStringNames.defaultFirstChapterName] = 'イントロ';
strings[playerStringNames.acknowledgementLinkName] = '謝辞';

var getLanguage = function() {
   return languageCodes.JAPANESE;
};

var getReadableTimeByLocale = function(hours, minutes, seconds) {
   var timeStringList = [];
   if (hours > 0) {
      var hoursReadableString = hours + '時間';
      timeStringList.push(hoursReadableString);
   }
   if (minutes > 0) {
      var minutesReadableString = minutes + '分1';
      timeStringList.push(minutesReadableString);
   }
   if (seconds > 0) {
      var secondsReadableString = seconds + '秒';
      timeStringList.push(secondsReadableString);
   }

   return timeStringList.join('');
};

export default Object.defineProperties({
   getReadableTimeByLocale: getReadableTimeByLocale
}, {
   language: {
      get: getLanguage
   },
   strings: {
      get: function() {
         return strings;
      }
   }
});
