import Promise from 'promise-polyfill';
import MediaListParser from '../utils/media-list-parser';
import mediaType from '../constants/media-type';

/**
 * @memberof TSC
 * @class MediaList
 * @classdesc List of media
 * @param {Array} mediaSources - List of media source strings or objects.
 * @return new MediaList instance
 */
var MediaList = function(mediaSources) {
   var _readyPromiseResolve;
   var _readyPromiseReject;
   var _readyPromise = new Promise(function(resolve, reject) {
      _readyPromiseResolve = resolve;
      _readyPromiseReject = reject;
   });
   var _mediaListParser;
   var _currentMediaIndex = 0;
   var _mediaList;

   _mediaListParser = MediaListParser.create(mediaSources);
   _mediaListParser.ready.then(function() {
      _mediaList = _mediaListParser.mediaList[0].type === mediaType.PLAYLIST ? _mediaListParser.mediaList[0].media : _mediaListParser.mediaList;
      _readyPromiseResolve();
   }).catch(function() {
      _readyPromiseReject();
   });

   var getMediaIndexByUri = function(uri) {
      var mediaIndex = -1;
      _mediaList.some(function(media, index) {
         if (media.uri === uri) {
            mediaIndex = index;
         }
         return media.uri === uri;
      });
      return mediaIndex;
   };

   var isMediaTypeAvailable = function(type) {
      return _mediaList.some(function(media) {
         return type === media.type;
      });
   };

   var setCurrentMediaIndex = function(mediaPosition) {
      if (typeof mediaPosition === 'string') {
         if (mediaPosition === 'next') {
            if (_currentMediaIndex < _mediaList.length - 1) {
               _currentMediaIndex++;
            }
         } else if (mediaPosition === 'previous') {
            if (_currentMediaIndex > 0) {
               _currentMediaIndex--;
            }
         }
      } else if (typeof mediaPosition === 'number') {
         if (mediaPosition >= 0 && mediaPosition < _mediaList.length) {
            _currentMediaIndex = mediaPosition;
         }
      }
      return _currentMediaIndex;
   };

   return Object.defineProperties({
      /**
       *  Get the index of an uri in the media list.
       *  @function getMediaIndexByUri
       *  @memberof TSC.MediaList
       *  @param {String} uri - uri to search for.
       *  @return {Number} index of uri or -1 if not found.
       */
      getMediaIndexByUri: getMediaIndexByUri,
      /**
       *  Checks media list for supplied type and return true if found.
       *  @function isMediaTypeAvailable
       *  @memberof TSC.MediaList
       *  @param {String} type - media type.
       *  @return {Boolean} Is the type available.
       */
      isMediaTypeAvailable: isMediaTypeAvailable
   }, {
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.MediaList
       *  @property {Promise} ready - Promise that resolves when media list has been parsed.
       */
      ready: {
         get: function() {
            return _readyPromise;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.MediaList
       *  @property {TSC.Media} currentMedia - Current Media object.
       */
      currentMedia: {
         get: function() {
            return _mediaList[_currentMediaIndex];
         }
      },
      /**
       *  @public
       *  @memberof TSC.MediaList
       *  @property {Number} currentMediaIndex - Current index  in the media list.
       */
      currentMediaIndex: {
         get: function() {
            return _currentMediaIndex;
         },
         set: setCurrentMediaIndex
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.MediaList
       *  @property {Array} mediaList - List of media objects.
       */
      mediaList: {
         get: function() {
            return _mediaList;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.MediaList
       *  @property {String} currentMediaType - Current media object type.
       */
      currentMediaType: {
         get: function() {
            return _mediaList[_currentMediaIndex].type;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.MediaList
       *  @property {Boolean} onFirstMedia - Is the current index pointing to the first media element in the list.
       */
      onFirstMedia: {
         get: function() {
            return _currentMediaIndex === 0;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.MediaList
       *  @property {Boolean} onFirstMedia - Is the current index pointing to the last media element in the list.
       */
      onLastMedia: {
         get: function() {
            return _currentMediaIndex + 1 === _mediaList.length;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.MediaList
       *  @property {Boolean} hasImage - Is there an image in the media list.
       */
      hasImage: {
         get: function() {
            return _mediaList[0].type === mediaType.JPG ||
                   _mediaList[0].type === mediaType.GIF ||
                   _mediaList[0].type === mediaType.PNG;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.MediaList
       *  @property {Boolean} isPlaylist - Is the first media element a playlist.
       */
      isPlaylist: {
         get: function() {
            return _mediaListParser.mediaList[0].type === mediaType.PLAYLIST;
         }
      }
   });
};

export default {
   /**
    *  Factory method that returns a new MediaList object.
    *  @function create
    *  @memberof TSC.MediaList
    *  @static
    *  @param {Array} mediaSources - List of media source strings or objects.
    *  @return new MediaList instance
    */
   create: MediaList
};
