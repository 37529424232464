const playRewindControlsTemplate = ({accessBtnPlay, playIndex, accessBtnRewind, rewindIndex, accessBtnForward, forwardIndex}) => `<button type="button" name="Play" aria-pressed="false" class="ui control-button tertiary-button play-control" title="${accessBtnPlay}" tabindex="${playIndex}">
    <span class="ui play-button-glyph"></span>
    <span class="ui pause-button-glyph"></span>
</button>
<button type="button" name="Rewind" class="ui control-button tertiary-button rewind-control" title="${accessBtnRewind}" tabindex="${rewindIndex}">
   <span class="ui rewind-button-glyph"></span>
</button>
<button type="button" name="Forward" class="ui control-button tertiary-button forward-control" title="${accessBtnForward}" tabindex="${forwardIndex}">
   <span class="ui forward-button-glyph"></span>
</button>`;

export default playRewindControlsTemplate;
