var _smartPlayerNamespace = 'tsc-smartplayer';
var _videoWrapper = 'video-wrapper';
var _scormModule = 'scorm-module';
var _scormModuleNavigationContainer = 'scorm-module-navigation-container';
var _playerControlsVisible = 'player-controls-visible';
var _largerProgressBar = 'larger-progress-bar';
var _disabled = 'disabled-button';
var _hide = 'hide';
var _hideElement = 'hide-element';
var _volumeSliderHidden = 'volume-slider-hidden';
var _hideSecondaryControls = 'hide-secondary-controls';
var _fullscreenMode = 'fullscreen-mode';
var _volumeMutedButtonGlyph = 'volume-muted-button-glyph';
var _volumeLowButtonGlyph = 'volume-low-button-glyph';
var _volumeMediumButtonGlyph = 'volume-medium-button-glyph';
var _volumeHighButtonGlyph = 'volume-high-button-glyph';
var _playButtonGlyph = 'play-button-glyph';
var _pauseButtonGlyph = 'pause-button-glyph';
var _fullscreenButtonGlyph = 'fullscreen-button-glyph';
var _fullscreenButtonActiveGlyph = 'fullscreen-button-active-glyph';
var _fullFrameButtonGlyph = 'full-frame-button-glyph';
var _fullFrameButtonActiveGlyph = 'full-frame-button-active-glyph';
var _theaterModeButtonGlyph = 'theater-mode-button-glyph';
var _theaterModeButtonActiveGlyph = 'theater-mode-button-active-glyph';
var _expandButtonActiveGlyph = 'expand-button-glyph';
var _audioDescriptionButtonGlyph = 'audio-description-button-glyph';
var _audioDescriptionButtonActiveGlyph = 'audio-description-button-active-glyph';
var _closedCaptionButtonGlyph = 'closed-caption-glyph';
var _closedCaptionNonEnglishButtonGlyph = 'closed-caption-non-en-glyph';
var _closedCaptionButtonActiveGlyph = 'closed-caption-active-glyph';
var _closedCaptionNonEnglishButtonActiveGlyph = 'closed-caption-non-en-active-glyph';
var _tocButtonGlyph = 'toc-button-glyph';
var _tocButtonActiveGlyph = 'toc-button-active-glyph';
var _settingsButtonGlyph = 'settings-button-glyph';
var _settingsButtonActiveGlyph = 'settings-button-active-glyph';
var _tertiaryButton = 'tertiary-button';
var _tertiaryButtonActive = 'tertiary-button-active';
var _generatedPluginContainer = 'generated-plugin-container';
var _pluginNamedContainerPrefix = 'plugin-';
var _hotspotVisible = 'hotspot-visible';
var _screenReaderAlert = 'alert-only';
var _imageViewAnimateTransitions = 'animate-transitions';
var _safariPipIssueFix = 'patch-safari-pip-issue';
var _captionsUnderVideo = 'caption_under_video';
var _captionsVerticalAlignTop = 'captionVAlignTop';
var _captionsVerticalAlignBottom = 'captionVAlignBottom';
var _captionsHorizontalAlignLeft = 'captionHAlignLeft';
var _captionsHorizontalAlignCenter = 'captionHAlignCenter';
var _captionsHorizontalAlignRight = 'captionHAlignRight';
var _captionsCustomPosition = 'custom-caption-position';
var _captionsCaptionSelected = 'caption-selected';
var _captionsPreventCaptionDrag = 'prevent-caption-drag';
var _captionXmpView = 'xmp-caption';
var _captionAdaView = 'ada-caption';
var _progressBarTrackPreventSeeking = 'prevent-seeking';
var _hideBubbleArrow = 'hide-bubble-arrow';
var _hiddenVideoThumbnailVisible = 'hidden-video-thumbnail-visible';
var _scrubPreviewHasThumbnail = 'has-thumbnail';
var _focusableAnswerInput = 'focusable-answer-input';
var _constrainMediaHeight = 'constrain-media-height';
var _constrainMediaWidth = 'constrain-media-width';
var _mediaTypeYouTube = 'media-type-youtube';

var cssClasses = Object.defineProperties({}, {
   smartPlayerNamespace: {value: _smartPlayerNamespace},
   videoWrapper: {value: _videoWrapper},
   scormModule: {value: _scormModule},
   scormModuleNavigationContainer: {value: _scormModuleNavigationContainer},
   playerControlsVisible: {value: _playerControlsVisible},
   largerProgressBar: {value: _largerProgressBar},
   hide: {value: _hide},
   hideElement: {value: _hideElement},
   volumeSliderHidden: {value: _volumeSliderHidden},
   hideSecondaryControls: {value: _hideSecondaryControls},
   disabled: {value: _disabled},
   fullscreenMode: {value: _fullscreenMode},
   volumeMutedButtonGlyph: {value: _volumeMutedButtonGlyph},
   volumeLowButtonGlyph: {value: _volumeLowButtonGlyph},
   volumeMediumButtonGlyph: {value: _volumeMediumButtonGlyph},
   volumeHighButtonGlyph: {value: _volumeHighButtonGlyph},
   playButtonGlyph: {value: _playButtonGlyph},
   pauseButtonGlyph: {value: _pauseButtonGlyph},
   fullscreenButtonGlyph: {value: _fullscreenButtonGlyph},
   fullscreenButtonActiveGlyph: {value: _fullscreenButtonActiveGlyph},
   fullFrameButtonGlyph: {value: _fullFrameButtonGlyph},
   fullFrameButtonActiveGlyph: {value: _fullFrameButtonActiveGlyph},
   expandButtonGlyph: {value: _expandButtonActiveGlyph},
   audioDescriptionButtonGlyph: {value: _audioDescriptionButtonGlyph},
   audioDescriptionButtonActiveGlyph: {value: _audioDescriptionButtonActiveGlyph},
   closedCaptionButtonGlyph: {value: _closedCaptionButtonGlyph},
   closedCaptionNonEnglishButtonGlyph: {value: _closedCaptionNonEnglishButtonGlyph},
   closedCaptionButtonActiveGlyph: {value: _closedCaptionButtonActiveGlyph},
   closedCaptionNonEnglishButtonActiveGlyph: {value: _closedCaptionNonEnglishButtonActiveGlyph},
   tocButtonGlyph: {value: _tocButtonGlyph},
   tocButtonActiveGlyph: {value: _tocButtonActiveGlyph},
   settingsButtonGlyph: {value: _settingsButtonGlyph},
   settingsButtonActiveGlyph: {value: _settingsButtonActiveGlyph},
   tertiaryButton: {value: _tertiaryButton},
   tertiaryButtonActive: {value: _tertiaryButtonActive},
   generatedPluginContainer: {value: _generatedPluginContainer},
   pluginNamedContainerPrefix: {value: _pluginNamedContainerPrefix},
   hotspotVisible: {value: _hotspotVisible},
   screenReaderAlert: {value: _screenReaderAlert},
   imageViewAnimateTransitions: {value: _imageViewAnimateTransitions},
   safariPipIssueFix: {value: _safariPipIssueFix},
   captionsUnderVideo: {value: _captionsUnderVideo},
   captionsVerticalAlignTop: {value: _captionsVerticalAlignTop},
   captionsVerticalAlignBottom: {value: _captionsVerticalAlignBottom},
   captionsHorizontalAlignLeft: {value: _captionsHorizontalAlignLeft},
   captionsHorizontalAlignCenter: {value: _captionsHorizontalAlignCenter},
   captionsHorizontalAlignRight: {value: _captionsHorizontalAlignRight},
   captionsCustomPosition: {value: _captionsCustomPosition},
   captionsCaptionSelected: {value: _captionsCaptionSelected},
   captionsPreventCaptionDrag: {value: _captionsPreventCaptionDrag},
   captionXmpView: {value: _captionXmpView},
   captionAdaView: {value: _captionAdaView},
   progressBarTrackPreventSeeking: {value: _progressBarTrackPreventSeeking},
   hideBubbleArrow: {value: _hideBubbleArrow},
   hiddenVideoThumbnailVisible: {value: _hiddenVideoThumbnailVisible},
   scrubPreviewHasThumbnail: {value: _scrubPreviewHasThumbnail},
   focusableAnswerInput: {value: _focusableAnswerInput},
   constrainMediaHeight: {value: _constrainMediaHeight},
   constrainMediaWidth: {value: _constrainMediaWidth},
   mediaTypeYouTube: {value: _mediaTypeYouTube},
   theaterModeButtonGlyph: {value: _theaterModeButtonGlyph},
   theaterModeButtonActiveGlyph: {value: _theaterModeButtonActiveGlyph}
});

export default cssClasses;
