import playerConfiguration from '../../models/player-configuration';
import deviceInfo from '../../utils/device-info';
import {LanguageIdentifier, languageIdentifiers} from '@techsmith/tsc-media-extensions-js';
import cssClasses from '../../constants/css-classes';
import events from '../../constants/events';
import VideoPlaybackQualityMetrics from '../../utils/video-playback-quality-metrics';
import VideoSeekingSpinnerView from '../video-seeking-spinner-view';
import VideoApiUtils from './../../utils/video-api-utils';
import {AnalyticsRumVideoCategorySuffix} from '../../constants/analytics-constants';
import playerControlsType from '../../constants/player-controls-type';
import mediaViewType from '../../constants/media-view-type';
import localizationStrings from '../../models/localization-strings';
import playerStringNames from '../../constants/player-string-names';

var CAPTION_TRACK_SHOWING = 'showing';
var CAPTION_TRACK_HIDDEN = 'hidden';
var SAFARI_VERSION_WITH_PIP_ISSUE = 13;

/**
 *  @memberof TSC
 *  @implement MediaView
 *  @class VideoElementView
 *  @classdesc HTML 5 Video element view
 *  @param {jQuery} $rootContainer - Smart Player main container jquery element
 *  @param {jQuery} $mediaContainer - Smart Player media container jquery element
 *  @param {Array} mediaSources - html5 media DOM element (or wrapper presenting same interface)
 *  @param {Boolean} includeControls - Include the built in controls for the video element
 *  @param {Function} triggerRumDataEvent - Callback function to use when triggering rum data event
 *  @param {Function} firstPlayCallback - callback function called on the first play click
 *  @param {Boolean} [includePosterIfAvailable=false] - Include the poster image if available
 */
var VideoElementView = function($rootContainer, $mediaContainer, mediaSources, includeControls, triggerRumDataEvent, firstPlayCallback, includePosterIfAvailable) {
   var _containsWebTrackCaptions = false;
   var _view;
   var _mediaWrapper;
   var _mediaElement;
   var _videoSeekingSpinnerView;
   var _videoPlaybackQualityMetrics;
   var _videoFirstPlayTriggered = false;
   var _$videoAndVideoSources = null;
   var _captionTrackElements = {};
   var _eventHandlers = {};

   if (!includePosterIfAvailable) {
      includePosterIfAvailable = false;
   }

   var createView = function() {
      var videoElementMarkup = '<video';

      if (includePosterIfAvailable && playerConfiguration.getPosterImageSrc()) {
         videoElementMarkup += ' poster="' + playerConfiguration.getPosterImageSrc() + '"';
      }

      if (includeControls) {
         videoElementMarkup += ' controls';
      }

      if (deviceInfo.isIOS()) {
         videoElementMarkup += ' webkit-playsinline playsinline';
      }

      videoElementMarkup += '>';

      if (playerConfiguration.captionSrcList.length > 0) {
         playerConfiguration.captionSrcList.forEach(function(captionSrc) {
            if (VideoApiUtils.captionSrcIsWebCompatible(captionSrc.src)) {
               _containsWebTrackCaptions = true;
               var languageIdentifier = LanguageIdentifier.create(captionSrc.languageIdentifier);
               var languageDisplayName = languageIdentifiers.identifiers[languageIdentifier.language].displayName;
               videoElementMarkup += '<track label="' + languageDisplayName + '" kind="subtitles" srclang="' + languageIdentifier.language + '" src="' + captionSrc.src + '">';
            }
         });
      }

      videoElementMarkup += playerConfiguration.getUnableToDisplayContentString() + '</video>';

      var $videoElementContainer = $mediaContainer.find('.video-div');
      $videoElementContainer.html(videoElementMarkup);

      if (deviceInfo.isSafari() && +deviceInfo.getSafariMajorVersion() >= SAFARI_VERSION_WITH_PIP_ISSUE) {
         $videoElementContainer.addClass(cssClasses.safariPipIssueFix);
      }

      _mediaWrapper = $mediaContainer.find('video');
      if (_mediaWrapper.length > 0) {
         _mediaElement = _mediaWrapper[0];
      }

      _videoSeekingSpinnerView = VideoSeekingSpinnerView.create($mediaContainer);
   };

   var setVideoSources = function() {
      var numberMediaSource = mediaSources.length;
      for (var i = 0; i < numberMediaSource; i++) {
         var source = playerConfiguration.getUnicodeSafeString(mediaSources[i].media);
         _mediaWrapper.append('<source src="' + source + '" type="' + mediaSources[i].mimeType + '" />');
      }
      bindVideoSourceEvents();
   };

   var showVideoBufferingView = function() {
      _videoSeekingSpinnerView.showView();
   };

   var hideVideoBufferingView = function() {
      _videoSeekingSpinnerView.hideView();
   };

   var createPlaybackQualityMetrics = function(view) {
      _videoPlaybackQualityMetrics = VideoPlaybackQualityMetrics.create(view, function(timingMeasureList) {
         triggerRumDataEvent(timingMeasureList, AnalyticsRumVideoCategorySuffix);
      }, null, $rootContainer.playerState);
      _videoPlaybackQualityMetrics.addEventListener(events.Controls.ShowVideoBufferingView, showVideoBufferingView);
      _videoPlaybackQualityMetrics.addEventListener(events.Controls.HideVideoBufferingView, hideVideoBufferingView);
   };

   var cacheCaptionTrackElements = function() {
      if (_containsWebTrackCaptions) {
         var captionTrackElements = _mediaElement.textTracks;
         for (var i = 0, numberTracks = captionTrackElements.length; i < numberTracks; i++) {
            var captionLanguage = captionTrackElements[i].language;
            _captionTrackElements[captionLanguage] = captionTrackElements[i];
         }
      }
   };

   var showCaptionTrack = function(languageIdentifier) {
      if (_captionTrackElements[languageIdentifier.language]) {
         _captionTrackElements[languageIdentifier.language].mode = CAPTION_TRACK_SHOWING;
      }
   };

   var hideCaptionTrack = function(languageIdentifier) {
      if (_captionTrackElements[languageIdentifier.language]) {
         _captionTrackElements[languageIdentifier.language].mode = CAPTION_TRACK_HIDDEN;
      }
   };

   var onVideoErrorHandler = function() {
      $rootContainer.trigger(events.Media.Error, {errorString: localizationStrings.getPlayerString(playerStringNames.mediaNotFound)});
   };

   var onFirstPlayHandler = function() {
      if (!_videoFirstPlayTriggered) {
         firstPlayCallback();
         _videoFirstPlayTriggered = true;
         unbindVideoEvents();
      }
   };

   var unbindVideoEvents = function() {
      _mediaElement && _mediaElement.removeEventListener(events.ExternalVideo.VideoPlay, onFirstPlayHandler);
      _mediaElement && _mediaElement.removeEventListener(events.ExternalVideo.VideoSeeking, onFirstPlayHandler);
   };

   var bindVideoEvents = function() {
      _mediaElement && _mediaElement.addEventListener(events.ExternalVideo.VideoPlay, onFirstPlayHandler);
      _mediaElement && _mediaElement.addEventListener(events.ExternalVideo.VideoSeeking, onFirstPlayHandler);
   };

   var bindVideoSourceEvents = function() {
      // Selecting video and sources because ie throws errors to video and everyone to sources
      _$videoAndVideoSources = $mediaContainer.find('video, video source');
      _$videoAndVideoSources.on(events.ExternalVideo.VideoError, onVideoErrorHandler);
   };

   var processVideoEnd = function() {
      return true;
   };

   var reshowVideoAfterClickToReplay = function() {
      // do nothing
   };

   var destroy = function() {
      unbindVideoEvents();
      _$videoAndVideoSources && _$videoAndVideoSources.off();
      _videoPlaybackQualityMetrics.destroy();
   };

   var addEventListener = function(eventName, onEventHandler) {
      _eventHandlers[eventName] = onEventHandler;
   };

   var removeEventListener = function(eventName) {
      _eventHandlers[eventName] = undefined;
   };

   var hotfixViewAndControlsCSS = function($controlsView) {}; // eslint-disable-line

   createView();
   bindVideoEvents();
   cacheCaptionTrackElements();

   _view = Object.defineProperties({
      hotfixViewAndControlsCSS: hotfixViewAndControlsCSS,
      showCaptionTrack: showCaptionTrack,
      hideCaptionTrack: hideCaptionTrack,
      addEventListener: addEventListener,
      removeEventListener: removeEventListener,
      reshowVideoAfterClickToReplay: reshowVideoAfterClickToReplay,
      processVideoEnd: processVideoEnd,
      loadMedia: setVideoSources,
      destroy: destroy
   }, {
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.VideoElementView
       *  @property {jQuery} mediaWrapper - jQuery wrapper of the video element.
       */
      mediaWrapper: {
         get: function() {
            return _mediaWrapper;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.VideoElementView
       *  @property {HTMLVideoElement} mediaElement - HTML video element
       */
      mediaElement: {
         get: function() {
            return _mediaElement;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.VideoElementView
       *  @property {Boolean} containsWebTrackCaptions - Does the video contain web track captions?
       */
      containsWebTrackCaptions: {
         get: function() {
            return _containsWebTrackCaptions;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.VideoElementView
       *  @property {Number} mediaWidth - video width
       */
      mediaWidth: {
         get: function() {
            return _mediaElement.videoWidth;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.VideoElementView
       *  @property {Number} mediaHeight - video height
       */
      mediaHeight: {
         get: function() {
            return _mediaElement.videoHeight;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.VideoElementView
       *  @property {int} currentTimeInMilliseconds - The current time of the media, in milliseconds
       */
      currentTimeInMilliseconds: {
         get: function() {
            var mediaElement = this.mediaElement;
            var currentTimeInSeconds = mediaElement.currentTime > mediaElement.duration ? mediaElement.duration : mediaElement.currentTime;
            return currentTimeInSeconds ? currentTimeInSeconds * 1000 : 0;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.VideoElementView
       *  @property {Boolean}  - allows dragging extension content
       */
      canDragContentOnVideo: {
         get: function() {
            return true;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.VideoElementView
       *  @property {string} playerControlType - Type of player controls to use
       */
      playerControlType: {
         get: function() {
            return playerControlsType.video;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.VideoElementView
       *  @property {string} type - Media view type
       */
      type: {
         get: function() {
            return mediaViewType.video;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.VideoElementView
       *  @property {boolean} supportsClickToPlayControls - Does view support click to play controls over media
       */
      supportsClickToPlayControls: {
         get: function() {
            return true;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.VideoElementView
       *  @property {boolean} supportsSCORM - Does view support SCORM
       */
      supportsSCORM: {
         get: function() {
            return true;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.VideoElementView
       *  @property {TSC.VideoPlaybackQualityMetrics} playbackQualityMetrics - the playback quality metrics set up for this view (may be null)
       */
      playbackQualityMetrics: {
         get: function() {
            return _videoPlaybackQualityMetrics;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.VideoElementView
       *  @property {string} currentSrc - The current source path of the media
       */
      currentSrc: {
         get: function() {
            return _mediaElement.currentSrc;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.VideoElementView
       *  @property {Number} currentTime - The current time for the media
       */
      currentTime: {
         get: function() {
            return _mediaElement.currentTime;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.VideoElementView
       *  @property {Number} duration - The duration of the media
       */
      duration: {
         get: function() {
            return _mediaElement.duration;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.VideoElementView
       *  @property {Boolean} ended - Has the media ended
       */
      ended: {
         get: function() {
            return _mediaElement.ended;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.VideoElementView
       *  @property {Boolean} paused - Is the media paused
       */
      paused: {
         get: function() {
            return _mediaElement.paused;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.VideoElementView
       *  @property {Boolean} seeking - Is the media seeking
       */
      seeking: {
         get: function() {
            return _mediaElement.seeking;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.VideoElementView
       *  @property {Number} volume - The volume of the media
       */
      volume: {
         get: function() {
            return _mediaElement.volume;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.VideoElementView
       *  @property {Boolean} muted - Is the media muted
       */
      muted: {
         get: function() {
            return _mediaElement.muted;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.VideoElementView
       *  @property {Number} playbackRate - Playback rate of the media
       */
      playbackRate: {
         get: function() {
            return _mediaElement.playbackRate;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.VideoElementView
       *  @property {Array} buffered - THe array of buffered segments
       */
      buffered: {
         get: function() {
            return _mediaElement.buffered;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.VideoElementView
       *  @property {Array} played - THe array of media segments that have been played
       */
      played: {
         get: function() {
            return _mediaElement.played;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.VideoElementView
       *  @property {Array} seekable - THe array of media segments that are seekable
       */
      seekable: {
         get: function() {
            return _mediaElement.seekable;
         }
      }
   });

   createPlaybackQualityMetrics(_view);
   return _view;
};

export default {
   create: VideoElementView
};
