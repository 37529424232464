var mediaMimeType = Object.freeze({
   JSON: 'application/json',
   M3U: 'application/mpegurl',
   MP4: 'video/mp4',
   WEBM: 'video/webm',
   OGV: 'video/ogg',
   JPEG: 'image/jpeg',
   PNG: 'image/png',
   GIF: 'image/gif',
   UNKNOWN: ''
});

export default mediaMimeType;
