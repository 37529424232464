var _hotkeysEnabled = true;

var areHotkeysEnabled = function() {
   return _hotkeysEnabled;
};

var disableHotkeys = function() {
   _hotkeysEnabled = false;
};

var enableHotkeys = function() {
   _hotkeysEnabled = true;
};

export default {
   areHotkeysEnabled: areHotkeysEnabled,
   disableHotkeys: disableHotkeys,
   enableHotkeys: enableHotkeys
};
