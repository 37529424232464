var NullSmartPlayerView = function() {
   var noOp = function() {}; // eslint-disable-line

   return Object.defineProperties({
      loadMedia: noOp,
      destroy: noOp,
      play: noOp,
      pause: noOp,
      isInFullScreenMode: noOp,
      addEventListener: noOp,
      removeEventListener: noOp,
      addMediaEventListener: noOp,
      removeMediaEventListener: noOp,
      getCaptionsVisible: noOp,
      setCaptionsVisible: noOp,
      getScrubbing: noOp,
      setScrubbing: noOp,
      triggerVideoStartEventCallbacks: noOp,
      getSafeSeekTime: noOp
   }, {
      initializedPromise: {
         get: function() {
            return Promise.resolve();
         }
      },
      currentSrc: {
         get: function() {
            return undefined;
         }
      },
      currentTime: {
         get: function() {
            return 0;
         },
         set: noOp
      },
      duration: {
         get: function() {
            return 0;
         }
      },
      paused: {
         get: function() {
            return false;
         }
      },
      volume: {
         get: function() {
            return 1;
         },
         set: noOp
      },
      muted: {
         get: function() {
            return false;
         },
         set: noOp
      },
      playbackRate: {
         get: function() {
            return 1;
         },
         set: noOp
      },
      ended: {
         get: function() {
            return false;
         }
      },
      seeking: {
         get: function() {
            return false;
         }
      },
      buffered: {
         get: function() {
            return [];
         }
      },
      played: {
         get: function() {
            return [];
         }
      },
      seekable: {
         get: function() {
            return [];
         }
      }
   });
};

export default {
   create: NullSmartPlayerView
};
