const settingsViewTemplate = ({accessBtnSettings, settingsIndex, playerRateText, speedSettingIndex, playbackRates, hasMultipleLanguages, ccLanguageText, ccLanguageSettingIndex, ccLanguages, accessBtnAudioDescription, audioDescriptionMediaSrc, captionsExist, accessPlayerSettingsClose, acknowledgementLinkName, acknowledgmentUrl}) =>
   `<button name="Settings" type="button" class="settings-button ui control-button tertiary-button" title="${accessBtnSettings}" tabindex="${settingsIndex}" aria-expanded="false">
   <span class="ui settings-button-glyph"></span>
</button>
<div class="player-settings-container ui">
   <div class="acknowledgment-container-modal">
      <a href="${acknowledgmentUrl}" class="settings-acknowledgementlink" target="_blank" referrerpolicy="no-referrer">${acknowledgementLinkName}</a>
   </div>
   <div class="setting-header-container">
      <span class="setting-text-large">${accessBtnSettings}</span>
      <button class="settings-close-button" aria-label="${accessPlayerSettingsClose}">
         <span class="ui close-button-glyph"></span>
      </button>
   </div>
   ${audioDescriptionMediaSrc ? ` <div class="setting-container toggle-container audio-description-setting">
      <span class="setting-text">${accessBtnAudioDescription}</span>
      <label class="settings-select toggle">
         <input type="checkbox" class="ad-toggle" aria-label="${accessBtnAudioDescription}">
         <span class="slider round"></span>
      </label>
   </div>` : ''}
   ${hasMultipleLanguages ? `<div class="setting-container">
      <span class="setting-text">${ccLanguageText}</span>
      <select tabindex="${ccLanguageSettingIndex}" aria-label="${ccLanguageText}" class="settings-select cc-language-select">
         ${ccLanguages.map(({selected, languageValue, languageLabel}) => `<option class="cc-language-option" ${selected ? 'selected' : ''} value="${languageValue}" tabindex="-1">${languageLabel}</option>`).join('')}
      </select>
   </div>` : ''}
   <div class="setting-container toggle-container closed-caption-setting ${!captionsExist ? 'hide' : ''}">
      <span class="setting-text">${ccLanguageText}</span>
      <label class="settings-select toggle">
         <input type="checkbox" class="cc-toggle" aria-label="${ccLanguageText}">
         <span class="slider round"></span>
      </label>
   </div>
   <div class="setting-container">
      <span class="setting-text">${playerRateText}</span>
      <select tabindex="${speedSettingIndex}" aria-label="${playerRateText}" class="settings-select player-speed-select">
         ${playbackRates.map(({selected, rateValue, rateLabel}) => `<option class="player-speed-option" ${selected ? 'selected' : ''} value="${rateValue}" tabindex="-1">${rateLabel}</option>`).join('')}
      </select>
   </div>
   <div class="acknowledgment-container-panel">
      <a href="${acknowledgmentUrl}" class="settings-acknowledgementlink" target="_blank" referrerpolicy="no-referrer">${acknowledgementLinkName}</a>
   </div>
</div>`;
export default settingsViewTemplate;
