const hotspotViewTemplate = ({hotspotContainerWidth, hotspotContainerHeight, hotspotIndex, displayHotspotsShape, hotspotDescriptionId, hotspotDescription, hotspotLinkClassName, hotspotCoordinatesSeparationBySpace}) => `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
     width="100%" height="100%"
     viewBox="0 0 ${hotspotContainerWidth} ${hotspotContainerHeight}"
     version="1.1"
     data-hotspot-index="${hotspotIndex}"
     focusable="false"
     tabindex="-1"
     ${displayHotspotsShape ? 'class="display-hotspots-shape"' : ''}
   <desc id="${hotspotDescriptionId}">${hotspotDescription}</desc>
   <a xlink:href="#" class="${hotspotLinkClassName}" role="link" tabindex="0" focusable="true" aria-labelledby="${hotspotDescriptionId}" data-hotspot-index="${hotspotIndex}">
      <polygon points="${hotspotCoordinatesSeparationBySpace}" data-hotspot-index="${hotspotIndex}"></polygon>
   </a>
</svg>`;

export default hotspotViewTemplate;
