const tocTemplate = ({searchLabel, msgSearch, tocSearchIndex, searchOrJumpTo, accessBtnClearSearch, tocEraseIndex, msgSearchIsDisabledInFS}) => `<div class="toc-head-container">
     <div class="toc-pip-image-container"></div>
     <h2 class="toc-media-title"></h2>
     <div class="toc-search-container">
         <div class="ui toc-search-glyph"></div>
         <label class="toc-search-input-label">${searchLabel}
             <input type="text" class="toc-search-input" placeholder="${msgSearch}" tabindex="${tocSearchIndex}" aria-label="${searchOrJumpTo}"/>
         </label>
         <button class="ui clear-search-button" name="ClearSearch" type="button" title="${accessBtnClearSearch}" tabindex="${tocEraseIndex}"></button>
     </div>
     <div class="toc-full-screen-search-alert">
         <p>${msgSearchIsDisabledInFS}</p>
     </div>
 </div>
 <div class="toc-content-scroll-container">
     <h3 class="sr-only" id="tocContentScreenReaderLabel"></h3>
     <ol class="toc-content-container" role="directory" aria-labelledby="tocContentScreenReaderLabel"></ol>
 </div>`;

export default tocTemplate;

