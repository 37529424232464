import mediaMimeType from '../constants/media-mime-type';
import windowWrapper from '../../common/window-wrapper';
import documentWrapper from '../../common/document-wrapper';

var IPAD_OS_INITIAL_VERSION = 13;

var _supportsHTML5 = false;
var _supportsMP4 = false;
var _supportOgg = false;
var _supportWebM = false;
var _isIE = false;
var _isEdge = false;
var _ieVersion = null;
var _safariMajorVersion = null;
var _isFirefox = false;
var _isChrome = false;
var _isIPad = false;
var _isIPhone = false;
var _isIPod = false;
var _isIOS = false;
var _isAndroid = false;
var _isSafari = false;
var _isRetinaDisplay = false;
var _isSmallScreen = false;

var checkLocationProtocol = function() {
   switch (windowWrapper.getLocation().protocol) {
      case 'http:':
      case 'https:':
         return false;
      case 'file:':
         return true;
   }
};

var isTouchIEInterface = function() {
   var navigator = windowWrapper.getNavigator();
   return navigator.msMaxTouchPoints;
};

var isTouchInterface = function() {
   return 'ontouchstart' in documentWrapper.getDocument().documentElement || isTouchIEInterface();
};

var lookAtDevice = function() {
   var detect = documentWrapper.getDocument().createElement('video') || false;
   _supportsHTML5 = detect && typeof detect.canPlayType !== 'undefined';
   _supportsMP4 = _supportsHTML5 && detect.canPlayType(mediaMimeType.MP4);
   _supportOgg = _supportsHTML5 && detect.canPlayType(mediaMimeType.OGG);
   _supportWebM = _supportsHTML5 && detect.canPlayType(mediaMimeType.WEBM);

   var navigator = windowWrapper.getNavigator();
   _isIPad = navigator.userAgent.match(/iPad/i) !== null;
   _isIPhone = navigator.userAgent.match(/iPhone/i) !== null;
   _isIPod = navigator.userAgent.match(/iPod/i) !== null;
   _isIOS = _isIPad || _isIPhone || _isIPod;
   _isAndroid = navigator.userAgent.toLowerCase().indexOf('android') !== -1;
   _isChrome = navigator.userAgent.toLowerCase().indexOf('chrome') !== -1;
   _isIE = navigator.appName === 'Microsoft Internet Explorer' || navigator.appName === 'Netscape' && new RegExp('Trident/.*rv:([0-9]{1,}[\.0-9]{0,})').exec(navigator.userAgent) !== null;
   _isEdge = navigator.userAgent.match(/Edge/i) !== null;
   _isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') !== -1;
   // Chrome contains safari in it's user agent string, but safari doesn't include chrome
   _isSafari = navigator.userAgent.match(/Safari/i) !== null && !_isChrome;

   // chrome for iOS will match as safari, so we need to do an additional check
   var iOSChromeMatchExp = new RegExp('CriOS\/(.*)');
   if (navigator.appVersion.match(iOSChromeMatchExp)) {
      _isChrome = true;
      _isSafari = false;
   }

   // get IE version if IE
   if (_isIE) {
      var ua = navigator.userAgent;
      var re = new RegExp('MSIE ([0-9]{1,}[\.0-9]{0,})');
      if (re.exec(ua) !== null) {
         _ieVersion = parseFloat(RegExp.$1);
      }
   }

   if (_isSafari) {
      var matchExp = new RegExp('Version\/(.*) Safari');

      if (navigator.appVersion.match(matchExp)) {
         var safariVersion = navigator.appVersion.match(matchExp).slice(1)[0];
         _safariMajorVersion = safariVersion.substring(0, safariVersion.indexOf('.'));

         // iPad OS 13.x user agent does not contain information to ID the device as an iPad
         if (+_safariMajorVersion >= IPAD_OS_INITIAL_VERSION && !_isIPhone && !_isIPod && isTouchInterface()) {
            _isIPad = true;
            _isIOS = true;
         }
      }
   }

   // Portrait or landscape
   _isSmallScreen = windowWrapper.getWindow().screen.availWidth < 640 && windowWrapper.getWindow().screen.availHeight < 480 || windowWrapper.getWindow().screen.availWidth < 480 && windowWrapper.getWindow().screen.availHeight < 640;

   // Retina display is same res, but higher DPI, this detects that
   _isRetinaDisplay = windowWrapper.getWindow().devicePixelRatio && windowWrapper.getWindow().devicePixelRatio > 1;
};

var supportsFullScreen = function() {
   var documentRef = documentWrapper.getDocument();
   return documentRef.fullscreenEnabled || documentRef.webkitFullscreenEnabled;
};

var supportsLegacyIOSFullScreen = function(videoElement) {
   return _isIOS && videoElement && videoElement.webkitSupportsFullscreen;
};

export default {
   isSmallScreen: function() {
      return _isSmallScreen;
   },
   isRetinaDisplay: function() {
      return _isRetinaDisplay;
   },
   isIPad: function() {
      return _isIPad;
   },
   isIPhoneOrIPod: function() {
      return _isIPhone || _isIPod;
   },
   isIOS: function() {
      return _isIOS;
   },
   isIE: function() {
      return _isIE;
   },
   isEdge: function() {
      return _isEdge;
   },
   getIEVersion: function() {
      return _ieVersion;
   },
   getSafariMajorVersion: function() {
      return _safariMajorVersion;
   },
   isFirefox: function() {
      return _isFirefox;
   },
   isChrome: function() {
      return _isChrome;
   },
   isSafari: function() {
      return _isSafari;
   },
   isAndroid: function() {
      return _isAndroid;
   },
   canPlayHTML5Video: function() {
      return _supportsHTML5;
   },
   canPlayMP4: function() {
      return _supportsMP4;
   },
   canPlayOgg: function() {
      return _supportOgg;
   },
   canPlayWebM: function() {
      return _supportWebM;
   },
   isTouchInterface: isTouchInterface,
   isTouchIEInterface: isTouchIEInterface,
   getFullScreenChangeEventNames: function() {
      var documentRef = documentWrapper.getDocument();
      if (typeof documentRef.onfullscreenchange !== 'undefined') {
         return 'fullscreenchange';
      } else if (typeof documentRef.onwebkitfullscreenchange !== 'undefined') {
         return 'webkitfullscreenchange';
      }
   },
   requiresManualPreload: function() {
      return _isIOS || _isAndroid;
   },
   supportsFullScreen: supportsFullScreen,
   supportsLegacyIOSFullScreen: supportsLegacyIOSFullScreen,
   mediaLocation: function() {
      var path = windowWrapper.getLocation().href;
      var baseindex = path.lastIndexOf('/');
      var basex = path.substring(0, baseindex + 1);
      if (_isIE) {
         basex = encodeURI(basex);
      }
      basex = basex.split('%25').join('%');
      return basex;
   },
   isLocal: function() {
      return checkLocationProtocol();
   },
   lookAtDevice: function() {
      lookAtDevice();
   }
};
