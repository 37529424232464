import Promise from 'promise-polyfill';
import log from '../../common/log';
import Plugin from '../models/plugin';
import supportedPluginViewElements from '../constants/supported-plugin-view-elements';
import cssClasses from '../constants/css-classes';

var REMOVE_SPACES_REGEX = /\s+/g;

/**
 * @memberof TSC
 * @class PluginController
 * @classdesc Plugin Controller to wire up external plugins
 */
var PluginController = function() {
   var _plugins = {};

   /**
    * @function isViewElementTypeSupported
    * Check to see if a viewElementType is supported.
    * @param {String} viewElementType - view element type for plugin
    * @return {Boolean} return if passed in viewElementType is supported.
    */
   var isViewElementTypeSupported = function(viewElementType) {
      return Object.keys(supportedPluginViewElements).some(function(key) {
         return supportedPluginViewElements[key] === viewElementType;
      });
   };

   /**
    * @function getPluginsForViewElementType
    * Get a list of plugin objects for the passed in view element type.
    * @param {String} viewElementType - view element type for plugin
    * @return {Array} return an array of supported plugins for the passed in view element type
    */
   var getPluginsForViewElementType = function(viewElementType) {
      return Object.keys(_plugins).filter(function(key) {
         return _plugins[key].viewElementType === viewElementType;
      }).map(function(key) {
         return _plugins[key];
      });
   };

   /**
    * @function getPluginContainerElement
    * Get a new div element setup for a plugin to append elements to.
    * @param {String} pluginName - name of plugin
    * @return {Element} return a new div element setup for old plugin dom
    */
   var getPluginContainerElement = function(pluginName) {
      var pluginNameNoSpaces = pluginName.replace(REMOVE_SPACES_REGEX, '');
      var pluginNamedContainerClassName = cssClasses.pluginNamedContainerPrefix + pluginNameNoSpaces;
      var pluginContainerElement = document.createElement('div');
      pluginContainerElement.classList.add(cssClasses.generatedPluginContainer);
      pluginContainerElement.classList.add(pluginNamedContainerClassName);
      return pluginContainerElement;
   };

   /**
    * @function registerPlugin
    * @methodOf TSC.PluginController
    * @param {String} pluginName - name of plugin
    * @param {String} viewElementType - view element type to install plugin into
    * @return {Promise} return a promise that resolves when the specified view element has initialized the plugin
    */
   var registerPlugin = function(pluginName, viewElementType) {
      if (_plugins[pluginName]) {
         var pluginSameNameMessage = 'Plugin with name [' + pluginName + '] is already registered with the player.';
         log.logMessage(pluginSameNameMessage);
         return Promise.reject(pluginSameNameMessage);
      }

      if (!isViewElementTypeSupported(viewElementType)) {
         var pluginUnsupportedViewElementMessage = 'Plugin with name [' + pluginName + '] is registering with an unsupported view element [' + viewElementType + '].';
         log.logMessage(pluginUnsupportedViewElementMessage);
         return Promise.reject(pluginUnsupportedViewElementMessage);
      }

      var plugin = Plugin.create(pluginName, viewElementType);
      _plugins[pluginName] = plugin;

      return plugin.initializedPromise;
   };

   /**
    * @function initializePluginsForViewElement
    * @methodOf TSC.PluginController
    * Initialize all registered plugins for the passed in view element type
    * @param {String} viewElementType - view element type
    * @param {jQuery} $viewElementPluginContainer - jQuery element to add plugin dom elements into.
    */
   var initializePluginsForViewElement = function(viewElementType, $viewElementPluginContainer) {
      if (!isViewElementTypeSupported(viewElementType)) {
         log.logMessage('Tried to initialize plugin with unsupported view element type [' + viewElementType + ']');
         return;
      }
      var elementTypePlugins = getPluginsForViewElementType(viewElementType);
      elementTypePlugins.forEach(function(plugin) {
         if (plugin.initialized) {
            return;
         }
         var pluginContainerElement = getPluginContainerElement(plugin.pluginName);
         $viewElementPluginContainer.append(pluginContainerElement);
         plugin.init(pluginContainerElement);
      });

      if (elementTypePlugins.length === 0) {
         $viewElementPluginContainer.hide();
      } else {
         $viewElementPluginContainer.show();
      }
   };

   return Object.defineProperties({
      registerPlugin: registerPlugin,
      initializePluginsForViewElement: initializePluginsForViewElement
   }, {});
};

export default {
   /**
    *  Factory method that returns a new PluginController object.
    *  @function create
    *  @memberof TSC.PluginController
    *  @static
    *  @return new PluginController instance
    */
   create: PluginController
};
