var MARKER_BEGIN_OFFSET = 250; // 250 milliseconds
var MARKER_END_OFFSET = 100; // 100 milliseconds

/**
 * @memberof TSC
 * @class QuestionSetMarker
 * @classdesc Question Set Marker
 * @params {Number} questionSetIndex - Question Set index
 * @params {TSC.QuestionSet} questionSet - Question set object for this marker.
 */
var QuestionSetMarker = function(questionSetIndex, questionSet) {
   var _questionSetIndex = questionSetIndex;
   var _startTime = questionSet.startTime;
   var _rangeStart = questionSet.startTime - MARKER_BEGIN_OFFSET;
   var _rangeEnd = questionSet.startTime + MARKER_END_OFFSET;
   var _canSeekPast = false;

   return Object.defineProperties({}, {
      questionSetIndex: {
         get: function() {
            return _questionSetIndex;
         },
         set: function(questionSetId) {
            _questionSetIndex = questionSetId;
         }
      },
      rangeStart: {
         get: function() {
            return _rangeStart;
         },
         set: function(rangeStart) {
            _rangeStart = rangeStart;
         }
      },
      rangeEnd: {
         get: function() {
            return _rangeEnd;
         },
         set: function(rangeEnd) {
            _rangeEnd = rangeEnd;
         }
      },
      startTime: {
         get: function() {
            return _startTime;
         },
         set: function(time) {
            _startTime = time;
         }
      },
      completed: {
         get: function() {
            return questionSet.completed;
         }
      },
      canSeekPast: {
         get: function() {
            return questionSet.completed || _canSeekPast;
         },
         set: function(canSeekPast) {
            _canSeekPast = canSeekPast;
         }
      }
   });
};

export default {
   /**
    *  Factory method that returns a new QuestionSetMarker object.
    *  @function create
    *  @memberof TSC.QuestionSetMarker
    *  @params {Number} questionSetIndex - Question Set index
    *  @params {Number} startTime - Time of question marker relative to media.
    *  @static
    *  @return new QuestionSetMarker instance
    */
   create: QuestionSetMarker
};
