import playerStringNames from '../constants/player-string-names';
import localizationStrings from '../models/localization-strings';
import templateGenerator from '../utils/template-generator';
import events from '../constants/events';

/**
 * Options for the ScormConfirmCompleteModal
 * @typedef {Object} ScormModalOptions
 * @property {boolean} canResumeVideo - Can the SCORM module be resumed
 * @property {boolean} failedToSaveSuspendedData - Indicates whether the player could not save the current state to the suspended data api.
 */

/**
 * @memberof TSC
 * @class ScormConfirmCompleteModalView
 * @classdesc Confirmation modal used with SCORM operations
 * @param {jQuery} $container - jQuery wrapped element to add modal to.
 * @param {ScormModalOptions} options - Options to use when displaying modal.
 */
var ScormConfirmCompleteModalView = function($container, options) {
   var _$scormConfirmCompleteModalWrapper = $container.find('.scorm-confirm-complete-modal-wrapper');
   var _$confirmButton;
   var _$dismissButton;

   var createView = function() {
      var finalConfirmText;

      if (options.failedToSaveSuspendedData) {
         finalConfirmText = localizationStrings.getPlayerString(playerStringNames.scormConfirmCompleteModalFailedToSaveProgressText);
      } else {
         var confirmText = localizationStrings.getPlayerString(playerStringNames.scormConfirmCompleteModalText);
         var confirmWarningText = options.canResumeVideo ? localizationStrings.getPlayerString(playerStringNames.scormConfirmCompleteModalResumeVideoText) : localizationStrings.getPlayerString(playerStringNames.scormConfirmCompleteModalRestartVideoText);
         finalConfirmText = confirmText + ' ' + confirmWarningText;
      }

      var screenReaderAlertText = finalConfirmText;
      var dismissButtonText = localizationStrings.getPlayerString(playerStringNames.scormConfirmCompleteModalDismissButtonText);
      var dismissButtonScreenReaderText = localizationStrings.getPlayerString(playerStringNames.scormConfirmCompleteModalDismissButtonScreenReaderText);
      var confirmButtonText = localizationStrings.getPlayerString(playerStringNames.scormConfirmCompleteModalConfirmButtonText);
      var confirmButtonScreenReaderText = localizationStrings.getPlayerString(playerStringNames.scormConfirmCompleteModalConfirmButtonScreenReaderText);
      var scormConfirmCompleteModalMarkup = templateGenerator.generateScormConfirmCompleteModalMarkup(screenReaderAlertText, finalConfirmText, dismissButtonText, dismissButtonScreenReaderText, confirmButtonText, confirmButtonScreenReaderText, options.failedToSaveSuspendedData);
      _$scormConfirmCompleteModalWrapper.html(scormConfirmCompleteModalMarkup);

      _$dismissButton = _$scormConfirmCompleteModalWrapper.find('.dismiss-complete-button');
      _$confirmButton = _$scormConfirmCompleteModalWrapper.find('.confirm-complete-button');
   };

   var onDismissClickHandler = function(e) {
      e.preventDefault();
      destroy();
   };

   var onCompleteClickHandler = function(e) {
      e.preventDefault();
      if (options.failedToSaveSuspendedData) {
         $container.trigger(events.SCORM.ExitSessionWithOutProgressConfirmed);
      } else {
         $container.trigger(events.SCORM.ExitSessionConfirmed);
      }
   };

   var setupEvents = function() {
      _$confirmButton.on('click', onCompleteClickHandler);
      _$dismissButton.on('click', onDismissClickHandler);
   };

   var destroy = function() {
      $container.trigger(events.Modals.Closed);
      _$confirmButton && _$confirmButton.off();
      _$dismissButton && _$dismissButton.off();
      _$scormConfirmCompleteModalWrapper.html('');
   };

   createView();
   setupEvents();

   $container.trigger(events.Modals.Open);

   return Object.defineProperties({
      destroy: destroy
   }, {});
};

export default {
   create: ScormConfirmCompleteModalView
};
