import playerConfiguration from '../models/player-configuration';

var NUMBER_MILLISECONDS_IN_SECOND = 1000;
var WEB_COMPATIBLE_CAPTION_FILE_EXT = 'vtt';

var quizController = null;

var setQuizController = function(newQuizController) {
   quizController = newQuizController;
};

var getSafeSeekTime = function(mediaElement, timeInSeconds) {
   var timeInMilliseconds = timeInSeconds * NUMBER_MILLISECONDS_IN_SECOND;
   var questionSetMarker;

   var shouldRespectQuizMarkers = quizController && quizController.quizModel && quizController.quizEnabled && !playerConfiguration.ignoreQuizQuestions;

   if (shouldRespectQuizMarkers) {
      for (var i = 0; i < quizController.quizModel.questionSetMarkerTimes.length; i++) {
         questionSetMarker = quizController.quizModel.questionSetMarkerTimes[i];
         if (!questionSetMarker.canSeekPast && questionSetMarker.startTime < timeInMilliseconds) {
            return questionSetMarker.startTime / NUMBER_MILLISECONDS_IN_SECOND;
         }
      }
   }

   // video time bounds
   if (timeInSeconds < 0) {
      return 0;
   }
   if (timeInSeconds > mediaElement.duration) {
      return mediaElement.duration;
   }
   return timeInSeconds;
};

var calculateCanvasScale = function(mediaView, wrapperWidth, wrapperHeight) {
   var scale = 1;
   if (mediaView.mediaWidth > 0 && wrapperWidth > 0 && wrapperHeight > 0 && mediaView.mediaHeight > 0) {
      var ratioOfWidth = wrapperWidth / mediaView.mediaWidth;
      var ratioOfHeight = wrapperHeight / mediaView.mediaHeight;
      scale = Math.min(ratioOfWidth, ratioOfHeight);
   }

   return scale;
};

var captionSrcIsWebCompatible = function(captionSrc) {
   return captionSrc && captionSrc.toLowerCase().split('.').pop() === WEB_COMPATIBLE_CAPTION_FILE_EXT;
};

export default {
   getSafeSeekTime: getSafeSeekTime,
   setQuizController: setQuizController,
   calculateCanvasScale: calculateCanvasScale,
   captionSrcIsWebCompatible: captionSrcIsWebCompatible
};
