import CaptionsView from '../views/captions-view';
import events from '../constants/events';
import playerConfiguration from '../models/player-configuration';
import videoApiUtils from '../utils/video-api-utils';
import {CAPTION_POSITION} from '../constants/captions';
import cssClasses from '../constants/css-classes';
import localStorageWrapper from '../utils/local-storage-wrapper';

var DEFAULT_CAPTION_STATE = false;

/**
 * @memberof TSC
 * @class CaptionController
 * @classdesc Controller for managing XMP features
 * @param {jQuery} $container - Smart Player main container jquery element
 * @param {MediaView} mediaView - Media view for current media. Created by MediaViewFactory.
 * @param {TSC.XmpModel} xmpModel
 * @param {TSC.MediaList} mediaList
 * @param {TSC.CaptionService} captionService
 *
 */
var CaptionController = function($container, mediaView, xmpModel, mediaList, captionService) {
   var hasCaptions = xmpModel && xmpModel.hasCaptionItems || captionService.captionTrack;
   if (!hasCaptions) {
      throw new Error('Attempted to create caption controller with no captions in xmp');
   }

   var LOCAL_STORAGE_CAPTIONS_STATE_KEY = 'captionEnabled';

   var _captionsView;
   var _$controls = $container.find('.video-controls-container');

   var setupCaptionView = function(captionPosition) {
      _captionsView = CaptionsView.create($container, captionService, mediaView, mediaView.canDragContentOnVideo);
      _captionsView.handleDisplayCaptions(true);

      if (captionPosition === CAPTION_POSITION.under) {
         _$controls.addClass(cssClasses.captionsUnderVideo);
      }

      $container.trigger(events.Controls.DisplayCaptionControl, {displayCaptionControl: captionPosition !== CAPTION_POSITION.under, captionViewPosition: captionPosition});
   };

   var setupNonXmpFeatures = function() {
      captionService.initDefaults();
      setupCaptionView(captionService.position);
   };

   var setupXmpFeatures = function() {
      captionService.initFromXmp(xmpModel);
      setupCaptionView(xmpModel.captionPosition);
   };

   var isNotNullOrUndefined = function(input) {
      return input !== null && input !== undefined;
   };

   var loadInitialState = function() {
      var savedCaptionsStateFromLocalStorage = localStorageWrapper.getItem(LOCAL_STORAGE_CAPTIONS_STATE_KEY);
      savedCaptionsStateFromLocalStorage = savedCaptionsStateFromLocalStorage ? savedCaptionsStateFromLocalStorage === 'true' : undefined;
      var videoCaptionsSetting = playerConfiguration.getCaptionsEnabled();

      var initialState;
      if (playerConfiguration.getIgnoreStoredCaptionVisibleSetting()) {
         initialState = isNotNullOrUndefined(videoCaptionsSetting) ? videoCaptionsSetting : DEFAULT_CAPTION_STATE;
      } else if (isNotNullOrUndefined(savedCaptionsStateFromLocalStorage)) {
         initialState = savedCaptionsStateFromLocalStorage;
      } else if (isNotNullOrUndefined(videoCaptionsSetting)) {
         initialState = videoCaptionsSetting;
      } else {
         initialState = DEFAULT_CAPTION_STATE;
      }

      $container.trigger(events.Captions.StateLoaded, {captionsEnabled: initialState});
   };

   var onCaptionsToggle = function(e, eventData) {
      e.preventDefault();
      eventData.savePreference && localStorageWrapper.setItem(LOCAL_STORAGE_CAPTIONS_STATE_KEY, eventData.captionsEnabled);
   };

   var resizeCaptions = function() {
      if (!mediaView || mediaView.mediaWrapper === undefined || mediaView.mediaHeight <= 0) {
         return;
      }

      captionService.setFontSizeForPlayerDimensions($container.width(), $container.height());

      if (_captionsView) {
         // if caption-position under we use the top-level container for scaling
         var scaleContainer = captionService.captionPosition === CAPTION_POSITION.under ? $container : mediaView.mediaWrapper;
         _captionsView.scale = videoApiUtils.calculateCanvasScale(mediaView, scaleContainer.width(), scaleContainer.height());
      }
   };

   var updateCaptions = function(currentTimeInMilliseconds, forceActiveCaptionUpdate) {
      var currentFile = mediaList.isPlaylist ? mediaList.currentMedia.media : null;
      _captionsView.updateCaption(currentTimeInMilliseconds, currentFile, forceActiveCaptionUpdate);
   };

   var onPlayerResize = function() {
      resizeCaptions();
   };

   var onFullScreenChange = function() {
      resizeCaptions();
   };

   var onLoadedMetadata = function() {
      resizeCaptions();
   };

   var onVideoTimeUpdate = function() {
      updateCaptions(mediaView.currentTimeInMilliseconds, false);
   };

   var onSeeked = function() {
      updateCaptions(mediaView.currentTimeInMilliseconds, false);
   };

   var onPipChanged = function(event, eventData) {
      if (_captionsView && _captionsView.visible) {
         if (eventData.pipViewActive) {
            _captionsView.showVideoTrackCaptions();
         } else {
            _captionsView.hideVideoTrackCaptions();
         }
      }
   };

   var onNewCaptionTrack = function() {
      if (_captionsView) {
         _captionsView.handleDisplayCaptions();
      }
   };

   var bindToEvents = function() {
      captionService.addEventListener(events.CaptionService.NewCaptionTrack, onNewCaptionTrack);

      $container.on(events.Controls.FullScreenChange, onFullScreenChange);
      $container.on(events.Controls.MediaResize, onPlayerResize);
      $container.on(events.Controls.PipChanged, onPipChanged);
      $container.on(events.Captions.Toggled, onCaptionsToggle);

      mediaView.mediaWrapper.on('ratechange', onVideoTimeUpdate, false);
      mediaView.mediaWrapper.on('play', onVideoTimeUpdate, false);

      var eventsToBind = {
         loadedmetadata: onLoadedMetadata,
         timeupdate: onVideoTimeUpdate,
         seeked: onSeeked
      };
      mediaView.mediaWrapper.on(eventsToBind);
   };

   var unbindToEvents = function() {
      captionService.removeEventListener(events.CaptionService.NewCaptionTrack, onNewCaptionTrack);

      $container.off(events.Controls.FullScreenChange, onFullScreenChange);
      $container.off(events.Controls.MediaResize, onPlayerResize);
      $container.off(events.Controls.PipChanged, onPipChanged);
      $container.off(events.Captions.Toggled, onCaptionsToggle);

      mediaView.mediaWrapper.off('ratechange', onVideoTimeUpdate, false);
      mediaView.mediaWrapper.off('play', onVideoTimeUpdate, false);

      var eventsToBind = {
         loadedmetadata: onLoadedMetadata,
         timeupdate: onVideoTimeUpdate,
         seeked: onSeeked
      };
      mediaView.mediaWrapper.off(eventsToBind);
   };

   var getCaptionCollection = function() {
      return captionService.captionTrack.captionCollection;
   };

   var getCaptionTrack = function() {
      return captionService.captionTrack;
   };

   var setCaptionTrack = function(value) {
      captionService.captionTrack = value;
      if (_captionsView) {
         _captionsView.handleDisplayCaptions();
      }
   };

   var getCaptionsVisible = function() {
      return _captionsView ? _captionsView.visible : playerConfiguration.getCaptionsEnabled();
   };

   var setCaptionsVisible = function(value) {
      if (_captionsView) {
         _captionsView.visible = value;
         _captionsView.handleDisplayCaptions();
         updateCaptions(mediaView.currentTimeInMilliseconds, true);
      } else {
         playerConfiguration.setCaptionsEnabled(value);
      }

      var showCaptionControls = value && captionService.hasCaptions;
      $container.trigger(events.Controls.DisplayCaptionControl, {displayCaptionControl: showCaptionControls && captionService.captionPosition !== CAPTION_POSITION.under, captionViewPosition: captionService.captionPosition});
      $container.trigger(events.Captions.StateLoaded, {captionsEnabled: showCaptionControls});
   };

   var destroy = function() {
      unbindToEvents();
      _captionsView && _captionsView.destroy();
   };

   if (xmpModel) {
      setupXmpFeatures();
   } else {
      setupNonXmpFeatures();
   }

   bindToEvents();
   loadInitialState();

   return Object.defineProperties({
      destroy: destroy,
      getCaptionCollection: getCaptionCollection,
      getCaptionTrack: getCaptionTrack,
      setCaptionTrack: setCaptionTrack,
      getCaptionsVisible: getCaptionsVisible,
      setCaptionsVisible: setCaptionsVisible
   }, {
   });
};

export default {
   /**
    *  Factory method that returns a new CaptionController.
    *  @function create
    *  @memberof TSC.CaptionController
    *  @static
    *  @param {jQuery} $container - Smart Player main container jquery element
    *  @param {MediaView} mediaView - Media view for current media. Created by MediaViewFactory.
    *  @param {TSC.XmpModel} xmpModel
    *  @param {TSC.MediaList} mediaList
    *  @param {TSC.CaptionService} captionService
    *  @return new CaptionController instance
    */
   create: CaptionController
};
