// pulled from https://gist.github.com/WebReflection/df05641bd04954f6d366
const escape = (s) => {
   if (typeof s !== 'string') {
      return s;
   }
   const escaped = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      "'": '&#39;',
      '"': '&quot;'
   };
   return s.replace(/[&<>'"]/g, (m) => {
      return escaped[m];
   });
};

const unescape = (s) => {
   if (typeof s !== 'string') {
      return s;
   }
   var re = /&(?:amp|#38|lt|#60|gt|#62|apos|#39|quot|#34);/g;
   var unescaped = {
      '&amp;': '&',
      '&#38;': '&',
      '&lt;': '<',
      '&#60;': '<',
      '&gt;': '>',
      '&#62;': '>',
      '&apos;': "'",
      '&#39;': "'",
      '&quot;': '"',
      '&#34;': '"'
   };
   return s.replace(re, (m) => {
      return unescaped[m];
   });
};

export {escape, unescape};
