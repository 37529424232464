import $ from 'jquery';
import cssClasses from '../constants/css-classes';

var OVERLAY_TRANSPARENCY = 0.7;

/**
 * @memberof TSC
 * @class BubbleView
 * @classdesc Bubble View
 * @param {String} containerId - id for container element
 */
var BubbleView = function(containerId) {
   var _viewIsMinimized = false;
   var _windowPadding = 2;
   var _viewBottom = 48;
   var _minArrowDistanceFromEdge = 9;
   var _ignoreBreakpoints = false;
   var _containerId = containerId;
   var _overlayId = '.tsc_overlay';
   var _arrowBubbleClass = '.chat-bubble-arrow';
   var _overlayMarkup = '<div class="tsc_overlay"></div>';
   var _topContainer = null;
   var _$container = null;
   var _$overlay = null;
   var _$arrowBubble = null;

   var clickCapture = function(event) {
      event.stopPropagation();
   };

   var isDesktopBreakpoint = function() {
      return _topContainer.hasClass('small-desktop-width') && _topContainer.hasClass('small-desktop-height');
   };

   var cacheElements = function() {
      _topContainer = _topContainer || $(_containerId).parents('.' + cssClasses.smartPlayerNamespace);
      _$container = _$container || $(_containerId);
      _$overlay = _$overlay || $(_overlayId);
      _$arrowBubble = _$arrowBubble || $(_arrowBubbleClass);
   };

   var minimizeView = function() {
      _viewIsMinimized = true;
      _$overlay.css('opacity', 0.0);
   };

   var maximizeView = function() {
      _viewIsMinimized = false;
      _$overlay.css('opacity', OVERLAY_TRANSPARENCY);
   };

   var showView = function(ignoreBreakpoints) {
      _ignoreBreakpoints = ignoreBreakpoints === undefined ? false : ignoreBreakpoints;
      cacheElements();
      _$container.css('opacity', 1);
      _$overlay.css('opacity', OVERLAY_TRANSPARENCY);
      _$container.on('click', clickCapture);
   };

   var hideView = function() {
      _$container.css('opacity', 0);
      _$overlay.css('opacity', 0);
      _$container.off('click', clickCapture);
   };

   var updateViewPosition = function() {
      cacheElements();
      if (_ignoreBreakpoints || isDesktopBreakpoint()) {
         _$container.css('bottom', _viewBottom + 'px');
      } else {
         _$container.css('bottom', '0px');
      }
   };

   var removeView = function(destroyOverlay) {
      if (destroyOverlay) {
         _$overlay.remove();
      }

      _$container.remove();
      _topContainer = null;
      _$container = null;
      _$overlay = null;
      _$arrowBubble = null;
   };

   var getOverlayMarkup = function() {
      return _overlayMarkup;
   };

   var setBottomOffset = function(bottom) {
      _viewBottom = bottom;
   };

   var pointAt = function(xPos) {
      if (_$container === null) {
         return;
      }

      var playerWidth = _topContainer.outerWidth(true);
      var viewWidth = _$container.outerWidth(true);
      var halfViewWidth = viewWidth / 2;
      var borderWidth = _$arrowBubble.css('border-left-width').replace(/px/, '');
      var arrowWidth = borderWidth * 2;
      var quizXPos;
      var arrowPos;

      // left bounds
      if (!_ignoreBreakpoints && !isDesktopBreakpoint()) {
         quizXPos = 0;
         arrowPos = 0;
      } else if (xPos - (halfViewWidth + _windowPadding) < 0) {
         quizXPos = _windowPadding;
         arrowPos = xPos - _windowPadding - borderWidth;
         // right bounds
      } else if (xPos + halfViewWidth + _windowPadding > playerWidth) {
         quizXPos = playerWidth - (viewWidth + _windowPadding);
         arrowPos = xPos - quizXPos - borderWidth;
         // center on point
      } else {
         quizXPos = xPos - halfViewWidth;
         arrowPos = halfViewWidth - borderWidth;
      }

      _$container.css('left', quizXPos);
      _$arrowBubble.css('left', arrowPos);

      if (arrowPos < _minArrowDistanceFromEdge || viewWidth - arrowPos - arrowWidth < _minArrowDistanceFromEdge) {
         _$arrowBubble.hide();
      } else {
         _$arrowBubble.show();
      }
   };

   return Object.defineProperties({
      showView: showView,
      hideView: hideView,
      maximizeView: maximizeView,
      minimizeView: minimizeView,
      updateViewPosition: updateViewPosition,
      removeView: removeView,
      getOverlayMarkup: getOverlayMarkup,
      setBottomOffset: setBottomOffset,
      pointAt: pointAt
   }, {
      viewIsMinimized: {
         get: function() {
            return _viewIsMinimized;
         }
      }
   });
};

export default {
   /**
    *  Factory method that returns a new BubbleView object.
    *  @function create
    *  @memberof TSC.BubbleView
    *  @param {String} containerId - id for container element
    *  @static
    *  @return new BubbleView instance
    */
   create: BubbleView
};
