const playerLayoutTemplate = ({videoWrapperIndex}) => `
<div class="scorm-module-navigation-container"></div>
<div class="media-wrapper video-wrapper click-controls hide" tabindex="${videoWrapperIndex}"></div>
<div class="ui control-backdrop video-controls-container hide">
   <div class="progress-bar-control"></div>
   <div class="player-controls">
      <div class="bottom-controls primary-controls">
         <div class="play-rewind-controls"></div>
         <div class="prev-next-controls"></div>
         <div class="volume-wrapper"></div>
         <div class="play-time" aria-hidden="true">0:00 / 0:00</div>
      </div>
      <div class="bottom-controls secondary-controls">
         <div class="audio-description-control-wrapper"></div>
         <div class="caption-wrapper"></div>
         <div class="toc-button-wrapper"></div>
         <div class="toc-container"></div>
         <div class="player-settings-wrapper"></div>
         <div class="pip-wrapper"></div>
         <div class="theater-mode-wrapper"></div>
         <div class="fullscreen-wrapper"></div>
      </div>
   </div>
</div>
<div class="before-after-play-controls"></div>
<div class="scorm-confirm-complete-modal-wrapper"></div>
<div class="scorm-session-ended-wrapper"></div>
<div class="open-external-link"></div>
<div class="player-plugin-container"></div>
`;

export default playerLayoutTemplate;
