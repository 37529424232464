import $ from 'jquery';
import events from '../constants/events';
import tabIndex from '../constants/tab-index';
import keys from '../constants/keys';
import mouseButtons from '../constants/mouse-buttons';
import highlightDisabler from '../utils/highlight-disabler';
import timeFormatter from '../utils/time-formatter';
import playerConfiguration from '../models/player-configuration';
import supportedPluginViewElements from '../constants/supported-plugin-view-elements';
import templateGenerator from '../utils/template-generator';
import videoApiUtils from '../utils/video-api-utils';
import localizationStrings from '../models/localization-strings';
import playerStringNames from '../constants/player-string-names';
import cssClasses from '../constants/css-classes';
import ProgressChapterModel from '../models/progress-chapter-model';
import windowWrapper from '../../common/window-wrapper';
import {getThumbnailRectangleByTime} from '../utils/video-thumbnails-utils';

var SHIFT_KEY_SKIP_DURATION_MODIFIER = 4;
var SCRUB_PREVIEW_OFFSET = 10;
var SCRUB_PREVIEW_BOX_PADDING = 8;
var MAX_NUMBER_CHAPTERS_CAN_RENDER = 100;
var MAX_LOAD_VALUE = 100;
var CHECK_VIDEO_LOAD_PROGRESS_INTERVAL = 1000;
var HIDE_SCRUB_PREVIEW_DELAY = 200;

/**
 * @memberof TSC
 * @class ProgressBarControlView
 * @classdesc Video progress bar view
 * @param {jQuery} $container - Smart Player root dom element
 * @param {HTMLVideoElement} mediaElement
 * @param {TSC.Quiz} quizModel
 */
var ProgressBarControlView = function($container, mediaElement, quizModel) {
   var markup = templateGenerator.generateProgressBarMarkup();
   $container.find('.progress-bar-control').html(markup);

   var _$document = windowWrapper.getDocument(true);
   var _documentRef = _$document[0];
   var _windowRef = windowWrapper.getWindow();
   var _requestAnimationId = null;
   var _scrubbing = false;
   var _lastScrubTime = 0;
   var _wasPlayingBeforeSeek = false;
   var _playStatusAfterSeek = false;
   var _quizModel = quizModel;
   var _$playTimeDisplay = $container.find('.play-time');
   var _$scrubBar = $container.find('.progress-scrubbar-track');
   var _$scrubbarTrack = $container.find('.scrubbar-track');
   var _$quizMarkers = $container.find('.markers');
   var _$scrubPreview = $container.find('.scrub-preview');
   var _videoThumbnailImage;
   var _$scrubVideoThumbnail = _$scrubPreview.find('.video-thumbnail');
   var _$hiddenVideoThumbnail = _$scrubPreview.find('.hidden-video-thumbnail');
   var _scrubVideoThumbnailCanvasCtx = null;
   var _$scrubPreviewChapterName = _$scrubPreview.find('.chapter-name');
   var _$scrubPreviewVideoTime = _$scrubPreview.find('.video-time');
   var _$pluginContainer = $container.find('.plugin-container');
   var _markers = [];
   var _keysEnabled = true;
   var _durationDisplayInitialized = false;
   var _markersCreated = false;
   var _formattedDuration;
   var _readableDuration;
   var _lastUpdatedFormattedMediaTime = -1;
   var _lastUpdatedScrubTime = -1;
   var _showSeekPreview = false;
   var _lastSeekHintVideoPercent = -1;
   var _lastSeekPreviewDetails = {
      hideThumbnail: false,
      videoTimeInSeconds: 0,
      displayAtVideoPercent: -1,
      chapterName: null,
      formattedVideoTime: null
   };
   var _chaptersCache = [];
   var _shouldRespectQuizMarkers = false;
   var _updateBufferedTimeout = -1;
   var _hideSeekPreviewTimeoutId = -1;
   var _currentProgressBarWidth = 0;

   var setShouldRespectQuizMarkers = function(newShouldRespectQuizMarkers) {
      _shouldRespectQuizMarkers = newShouldRespectQuizMarkers;
   };

   var shouldRespectQuizMarkers = function() {
      return _shouldRespectQuizMarkers;
   };

   var getClickX = function(event) {
      if (event.targetTouches && event.targetTouches[0] && event.targetTouches[0].pageX) {
         return event.targetTouches[0].pageX;
      }

      return event.pageX;
   };

   var getClosestRequiredQuizMarkerTimeBeforeVideoTime = function(timeInSeconds) {
      if (!shouldRespectQuizMarkers()) {
         return null;
      }

      var timeInMilliseconds = timeInSeconds * 1000;
      for (var i = 0; i < _quizModel.questionSetMarkerTimes.length; i++) {
         if (_quizModel.questionSetMarkerTimes[i].startTime <= timeInMilliseconds && !_quizModel.questionSetMarkerTimes[i].canSeekPast) {
            return _quizModel.questionSetMarkerTimes[i].startTime / 1000;
         }
      }

      return null;
   };

   var allowSkipQuestionSetMarkersBeforeTime = function(timeInSeconds) {
      if (!shouldRespectQuizMarkers()) {
         return;
      }

      var timeInMilliseconds = timeInSeconds * 1000;
      _quizModel.questionSetMarkerTimes.forEach(function(questionSetMarker) {
         questionSetMarker.canSeekPast = questionSetMarker.startTime < timeInMilliseconds;
      });
   };

   var resetSeekPastFlagForQuizMarkersPastTime = function(timeInSeconds) {
      if (!shouldRespectQuizMarkers()) {
         return;
      }

      var timeInMilliseconds = timeInSeconds * 1000;
      _quizModel.questionSetMarkerTimes.forEach(function(questionSetMarker) {
         if (timeInMilliseconds <= questionSetMarker.startTime) {
            questionSetMarker.canSeekPast = false;
         }
      });
   };

   var getChapterNameAtVideoTime = function(videoTimeInMilliseconds) {
      for (var i = _chaptersCache.length - 1; i >= 0; i--) {
         if (videoTimeInMilliseconds >= _chaptersCache[i].startTime) {
            return _chaptersCache[i].name;
         }
      }
      return null;
   };

   var getChapterPercentageFromTotalPercentage = function(totalPercent, chapter) {
      if (totalPercent >= chapter.startAsPercent && totalPercent <= chapter.endAsPercent) {
         return (totalPercent - chapter.startAsPercent) / chapter.durationAsPercent * 100;
      } else if (totalPercent > chapter.endAsPercent) {
         return 100;
      }
      return 0;
   };

   var getVideoTimeFromXPosition = function(xPos) {
      var closestRequiredQuizMarker;
      var relativeXPos = xPos - _$scrubBar.offset().left;
      var scrubBarWidth = _$scrubBar.width();
      var amountPlayedPercentage = Math.max(Math.min(relativeXPos / scrubBarWidth, 1), 0);

      var pendingVideoTime = mediaElement.duration * amountPlayedPercentage;
      closestRequiredQuizMarker = getClosestRequiredQuizMarkerTimeBeforeVideoTime(pendingVideoTime);

      return {
         videoTime: pendingVideoTime,
         closestRequiredQuizMarker: closestRequiredQuizMarker
      };
   };

   var updateLoadProgress = function(progress) {
      _chaptersCache.forEach(function(chapter) {
         chapter.$loadProgressElement.css({width: getChapterPercentageFromTotalPercentage(progress, chapter) + '%'});
      });
   };

   var onDownSeek = function(e) {
      e.preventDefault();
      if (_scrubbing) {
         return;
      }

      _$document.on('pointermove touchmove', onMoveSeek);
      // Safari needs mouseup, Safari reports wrong event.buttons code after user
      // right clicks on player.  This causes pepjs to report pointerup event as pointermove event
      _$document.on('pointerup mouseup touchend mouseleave', onUpSeek);

      if (!mediaElement.paused) {
         _wasPlayingBeforeSeek = true;
         mediaElement.pause();
      } else {
         _wasPlayingBeforeSeek = false;
      }

      _playStatusAfterSeek = _wasPlayingBeforeSeek;

      setScrubbing(true);
      _lastUpdatedScrubTime = -1;

      setPlayProgress(getClickX(e.originalEvent));
   };

   var moveScrubPreview = function(videoPercent) {
      var scrubBarWidth = _$scrubBar.width();
      var previewLeftPos = scrubBarWidth * videoPercent;
      var scrubPreviewWidth = _$scrubPreview.width();
      previewLeftPos -= scrubPreviewWidth / 2;

      if (previewLeftPos < SCRUB_PREVIEW_OFFSET) {
         previewLeftPos = SCRUB_PREVIEW_OFFSET;
      } else if (previewLeftPos + scrubPreviewWidth > scrubBarWidth - SCRUB_PREVIEW_OFFSET) {
         previewLeftPos = scrubBarWidth - (SCRUB_PREVIEW_OFFSET + scrubPreviewWidth);
      }

      _$scrubPreview.css('left', previewLeftPos + 'px');
   };

   var drawSeekHint = function(videoPercent) {
      var videoPercentNormalized = videoPercent * 100;
      _chaptersCache.forEach(function(chapter) {
         chapter.$seekHintProgressElement.css({width: getChapterPercentageFromTotalPercentage(videoPercentNormalized, chapter) + '%'});
      });
   };

   var updateScrubPreviewText = function(chapterName, formattedVideoTime) {
      var previewDisplayText = '';
      if (_lastSeekPreviewDetails.hideThumbnail) {
         previewDisplayText = localizationStrings.getPlayerString(playerStringNames.hideThumbnailDueToQuiz);
      } else if (chapterName) {
         previewDisplayText = chapterName;
      }
      _$scrubPreviewChapterName.text(previewDisplayText);
      _$scrubPreviewVideoTime.text(formattedVideoTime);
   };

   var setScrubPreviewDetailsAtTime = function(videoSeekTimes) {
      var unrestrictedVideoPercent = videoSeekTimes.videoTime / mediaElement.duration;
      var restrictedVideoPercent = videoSeekTimes.closestRequiredQuizMarker !== null ? videoSeekTimes.closestRequiredQuizMarker / mediaElement.duration : unrestrictedVideoPercent;

      _lastSeekPreviewDetails.videoTimeInSeconds = videoSeekTimes.videoTime;
      _lastSeekPreviewDetails.hideThumbnail = unrestrictedVideoPercent !== restrictedVideoPercent;
      _lastSeekPreviewDetails.chapterName = getChapterNameAtVideoTime(videoSeekTimes.videoTime * 1000);
      _lastSeekPreviewDetails.formattedVideoTime = timeFormatter.formatTime(videoSeekTimes.videoTime);
      _lastSeekPreviewDetails.displayAtVideoPercent = unrestrictedVideoPercent;
      _lastSeekHintVideoPercent = restrictedVideoPercent;
   };

   var onMouseMoveToPreview = function(e) {
      var videoSeekTimes = getVideoTimeFromXPosition(getClickX(e.originalEvent));
      setScrubPreviewDetailsAtTime(videoSeekTimes);
   };

   var clearHideScrubPreviewDelay = function() {
      _hideSeekPreviewTimeoutId !== -1 && clearTimeout(_hideSeekPreviewTimeoutId);
      _hideSeekPreviewTimeoutId = -1;
   };

   var showScrubPreview = function(e) {
      clearHideScrubPreviewDelay();
      _showSeekPreview = true;
      onMouseMoveToPreview(e);
      updateSeekHint();
      _$scrubPreview.show();
      _$document.on('pointermove touchmove', onMouseMoveToPreview);
   };

   var hideScrubPreview = function() {
      _hideSeekPreviewTimeoutId = -1;
      _showSeekPreview = false;
      _$scrubPreview.hide();
      _chaptersCache.forEach(function(chapter) {
         chapter.$seekHintProgressElement.css({width: '0'});
      });
      _$document.off('pointermove touchmove', onMouseMoveToPreview);
   };

   var onScrubBarMouseOut = function() {
      clearHideScrubPreviewDelay();
      _hideSeekPreviewTimeoutId = setTimeout(hideScrubPreview, HIDE_SCRUB_PREVIEW_DELAY);
   };

   var onMoveSeek = function(e) {
      if (!_scrubbing) {
         return;
      }

      if (!mediaElement.paused) {
         mediaElement.pause();
      }

      setPlayProgress(getClickX(e.originalEvent));
   };

   var onUpSeek = function() {
      if (!_scrubbing) {
         return;
      }

      _$document.off('pointermove touchmove', onMoveSeek);
      _$document.off('pointerup mouseup touchend mouseleave', onUpSeek);

      var atVideoEndTime = Number(mediaElement.currentTime.toFixed(2)) >= Number(mediaElement.duration.toFixed(2));

      if (_playStatusAfterSeek && !atVideoEndTime) {
         mediaElement.play();
      }

      setScrubbing(false);
   };

   var setPlayProgress = function(xPos) {
      var videoTimes = getVideoTimeFromXPosition(xPos);
      if (videoTimes.closestRequiredQuizMarker) {
         _playStatusAfterSeek = false;
      } else {
         _playStatusAfterSeek = _wasPlayingBeforeSeek;
      }
      _lastScrubTime = videoTimes.closestRequiredQuizMarker || videoTimes.videoTime;
   };

   var updatePlayProgress = function() {
      if (mediaElement.duration === 0) {
         return;
      }

      if (_scrubbing) {
         if (_lastScrubTime === _lastUpdatedScrubTime || _lastScrubTime < 0) {
            return;
         }

         _lastUpdatedScrubTime = _lastScrubTime;

         if (mediaElement.ended) {
            $container.trigger(events.Media.Replay, {time: _lastScrubTime, playAfterSeek: false});
         } else {
            $container.trigger(events.Media.SeekToTime, {time: _lastScrubTime});
         }

         updateUi(_lastScrubTime);
      } else {
         if (mediaElement.seeking) {
            return;
         }
         updateUi(mediaElement.currentTime);
      }
   };

   var updateVideoThumbnail = function() {
      if (!_scrubVideoThumbnailCanvasCtx) {
         return;
      }

      if (_lastSeekPreviewDetails.hideThumbnail) {
         _$hiddenVideoThumbnail.addClass(cssClasses.hiddenVideoThumbnailVisible);
         _$scrubVideoThumbnail.hide();
      } else {
         _$hiddenVideoThumbnail.removeClass(cssClasses.hiddenVideoThumbnailVisible);
         _$scrubVideoThumbnail.show();
         var thumbnailRect = getThumbnailRectangleByTime(_lastSeekPreviewDetails.videoTimeInSeconds,
            playerConfiguration.videoThumbnailInterval,
            playerConfiguration.videoThumbnailColumns,
            playerConfiguration.videoThumbnailWidth,
            playerConfiguration.videoThumbnailHeight);
         _scrubVideoThumbnailCanvasCtx.drawImage(_videoThumbnailImage, thumbnailRect.x, thumbnailRect.y, thumbnailRect.width, thumbnailRect.height, 0, 0, playerConfiguration.videoThumbnailWidth, playerConfiguration.videoThumbnailHeight);
      }
   };

   var updateSeekHint = function() {
      if (!_showSeekPreview) {
         return;
      }

      updateVideoThumbnail();
      updateScrubPreviewText(_lastSeekPreviewDetails.chapterName, _lastSeekPreviewDetails.formattedVideoTime);
      moveScrubPreview(_lastSeekPreviewDetails.displayAtVideoPercent);
      drawSeekHint(_lastSeekHintVideoPercent);
   };

   var updateUi = function(currentTime) {
      var percentPlayed = currentTime / mediaElement.duration * 100;
      _chaptersCache.forEach(function(chapter) {
         chapter.$playProgressElement.css({width: getChapterPercentageFromTotalPercentage(percentPlayed, chapter) + '%'});
      });

      updateTimeDisplay(currentTime);
   };

   var updateTimeDisplay = function(currentTime) {
      var formattedCurrentTime = timeFormatter.formatTime(currentTime);
      if (formattedCurrentTime === _lastUpdatedFormattedMediaTime && _durationDisplayInitialized) {
         return;
      }

      _lastUpdatedFormattedMediaTime = formattedCurrentTime;

      var progressDisplayText = formattedCurrentTime;

      if (!_durationDisplayInitialized && mediaElement.duration) {
         _durationDisplayInitialized = true;
         _formattedDuration = timeFormatter.formatTime(mediaElement.duration);
         _readableDuration = timeFormatter.formatTimeForReadability(mediaElement.duration);
         _$scrubBar.attr('aria-valuemax', mediaElement.duration);
      }

      if (_durationDisplayInitialized) {
         progressDisplayText += ' / ' + _formattedDuration;
         _$playTimeDisplay.html(progressDisplayText);
         var ariaValueText = timeFormatter.formatTimeForReadability(currentTime) + localizationStrings.getPlayerString(playerStringNames.accessProgressBarTimeConjunction) + _readableDuration;
         _$scrubBar.attr('aria-valuenow', currentTime);
         _$scrubBar.attr('aria-valuetext', ariaValueText);
      }
   };

   var setMarkersXPos = function() {
      var markerSize = 4;
      var mediaDurationInMilliseconds = mediaElement.duration * 1000;
      var xPos = 0;
      var timePercent = 0;
      var quizMarkerContainerWidth = _$quizMarkers.width();

      _quizModel.questionSetMarkerTimes.forEach(function(questionSetMarker, i) {
         timePercent = questionSetMarker.startTime / mediaDurationInMilliseconds;
         xPos = Math.round(quizMarkerContainerWidth * timePercent - markerSize / 2);

         var marker = _markers[i];
         marker && marker.css('left', xPos + 'px');
      });
   };

   var cacheProgressBarWidth = function() {
      _currentProgressBarWidth = _$scrubBar.width();
   };

   var onPlayerResize = function() {
      updateQuizMarkers();
      cacheProgressBarWidth();
   };

   var updateQuizMarkers = function() {
      if (!shouldRespectQuizMarkers()) {
         return;
      }

      if (!_markersCreated) {
         createQuizMarkers();
      }

      updateMarkerVisibility();
   };

   var updateMarkerVisibility = function() {
      _quizModel.questionSetMarkerTimes.forEach(function(questionSetMarker, i) {
         if (questionSetMarker.completed) {
            var marker = _markers[i];
            marker && marker.hide();
         }
      });

      setMarkersXPos();
   };

   var createQuizMarkers = function() {
      if (!shouldRespectQuizMarkers()) {
         return;
      }

      _markersCreated = true;

      _quizModel.questionSetMarkerTimes.forEach(function() {
         _markers.push($('<div></div>').addClass('quiz-marker'));
      });

      _$quizMarkers.append(_markers);
   };

   var blockTextSelection = function() {
      _documentRef.body.focus();
      _documentRef.onselectstart = function() {
         return false;
      };
   };

   var unblockTextSelection = function() {
      _documentRef.onselectstart = function() {
         return true;
      };
   };

   var setScrubbing = function(value) {
      _scrubbing = value;
      if (_scrubbing) {
         blockTextSelection();
         $container.trigger(events.Scrubbing.Started);
      } else {
         unblockTextSelection();
         $container.trigger(events.Scrubbing.Ended);
      }
   };

   var alertScreenReaderFastForwardIsDisabled = function() {
      var fastForwardDisabledText = localizationStrings.getPlayerString(playerStringNames.fastForwardDisabledScreenReaderText);

      var screenReaderAlertMarkup = templateGenerator.generateScreenReaderAlertMarkup(fastForwardDisabledText);
      var $screenReaderAlert = $(screenReaderAlertMarkup);
      $container.append($screenReaderAlert);
   };

   var keyPressed = function(e) {
      if (!_keysEnabled) {
         return;
      }

      var timeToSkip = e.shiftKey ? SHIFT_KEY_SKIP_DURATION_MODIFIER * playerConfiguration.skipVideoDuration : playerConfiguration.skipVideoDuration;
      if (keys.isKeyOfType(e.key, keys.keyTypes.rightArrow)) {
         if (playerConfiguration.allowFastForward) {
            mediaElement.currentTime = videoApiUtils.getSafeSeekTime(mediaElement, mediaElement.currentTime + timeToSkip);
         } else {
            alertScreenReaderFastForwardIsDisabled();
         }
      } else if (keys.isKeyOfType(e.key, keys.keyTypes.leftArrow)) {
         mediaElement.currentTime = videoApiUtils.getSafeSeekTime(mediaElement, mediaElement.currentTime - timeToSkip);
      } else if (keys.isKeyOfType(e.key, keys.keyTypes.space) && _documentRef.activeElement === _$scrubBar[0]) {
         e.preventDefault();
         if (mediaElement.paused) {
            mediaElement.play();
         } else {
            mediaElement.pause();
         }
      }
   };

   var onMousedown = function(e) {
      if (e.button === mouseButtons.mainButton) {
         onDownSeek(e);
      } else {
         highlightDisabler.disableHighlights($(this));
      }
   };

   var disableKeyListeners = function() {
      _$scrubBar.attr('tabindex', tabIndex.Disabled);
      _keysEnabled = false;
   };

   var enableKeyListeners = function() {
      _$scrubBar.attr('tabindex', tabIndex.DomOrder);
      _keysEnabled = true;
   };

   var getXPositionForQuestionSetMarker = function(questionSetMarkerId) {
      if (!shouldRespectQuizMarkers()) {
         return;
      }

      var questionSetMarker = null;
      var mediaDurationInMilliseconds = mediaElement.duration * 1000;
      var measuredContainerWidth = $container.width();
      var measuredQuizMarkerWidth = _$quizMarkers.width();
      // use the max width, when control is hidden width measurement is not correct.
      var usedElementWidth = Math.max(measuredContainerWidth, measuredQuizMarkerWidth);
      var scrubBarLeftOffset = _$scrubBar.offset().left;
      for (var i = 0; i < _quizModel.questionSetMarkerTimes.length; i++) {
         questionSetMarker = quizModel.questionSetMarkerTimes[i];
         if (questionSetMarker.questionSetIndex === questionSetMarkerId) {
            var timePercent = questionSetMarker.startTime / mediaDurationInMilliseconds;
            return Math.round(usedElementWidth * timePercent) + scrubBarLeftOffset;
         }
      }
   };

   var onQuizzingCompleteHandler = function() {
      allowSkipQuestionSetMarkersBeforeTime(mediaElement.currentTime);
   };

   var onMediaSeekedEvent = function() {
      resetSeekPastFlagForQuizMarkersPastTime(mediaElement.currentTime);
   };

   var onLoadedMetadata = function() {
      _$scrubBar.show();
      updateQuizMarkers();
      cacheProgressBarWidth();
   };

   var createDefaultChapter = function() {
      var progressBarChapterSegmentMarkup = templateGenerator.generateProgressBarChapterSegmentMarkup(0);
      var $chapterElement = $(progressBarChapterSegmentMarkup);
      $chapterElement.css('width', '100%');
      _$scrubbarTrack.append($chapterElement);

      _chaptersCache = [ProgressChapterModel.create(
         0,
         null,
         0,
         0,
         100,
         100,
         $chapterElement)];
   };

   var onVideoThumbnailLoaded = function() {
      _scrubVideoThumbnailCanvasCtx = _$scrubVideoThumbnail[0].getContext('2d');
      _videoThumbnailImage.onload = undefined;
      _videoThumbnailImage.onerror = undefined;
   };

   var loadVideoThumbnailsImage = function() {
      _videoThumbnailImage = new Image();
      _videoThumbnailImage.onload = onVideoThumbnailLoaded;
      _videoThumbnailImage.onerror = function() {
         _videoThumbnailImage.onload = undefined;
         _videoThumbnailImage.onerror = undefined;
         _videoThumbnailImage = null;
      };
      _videoThumbnailImage.src = playerConfiguration.videoThumbnailsSrc;
   };

   var initPlugins = function() {
      var pluginController = playerConfiguration.getPluginController();
      if (!pluginController) {
         return;
      }
      pluginController.initializePluginsForViewElement(supportedPluginViewElements.PROGRESS_BAR, _$pluginContainer);
   };

   var onAddPlugin = function() {
      initPlugins();
   };

   var setup = function() {
      _$scrubBar.attr('aria-valuemin', 0);

      if (playerConfiguration.allowFastForward) {
         _$scrubBar.on('touchstart', onDownSeek);
         _$scrubBar.on('mousedown', onMousedown);
         _$scrubBar.on('mouseover touchstart', showScrubPreview);
         _$scrubBar.on('mouseout touchend', onScrubBarMouseOut);
      } else {
         _$scrubBar.addClass(cssClasses.progressBarTrackPreventSeeking);
      }

      $container.on('keydown', keyPressed);
      $container.on(events.Modals.Open, disableKeyListeners);
      $container.on(events.Modals.Closed, enableKeyListeners);
      $container.on(events.Controls.Disable, disableKeyListeners);
      $container.on(events.Controls.Enable, enableKeyListeners);
      $container.on(events.Quizzing.Complete, onQuizzingCompleteHandler);
      $container.on(events.Controls.PlayerResize, onPlayerResize);
      $container.on(events.Plugin.Added, onAddPlugin);
      mediaElement.addEventListener(events.ExternalVideo.VideoSeeked, onMediaSeekedEvent);
      mediaElement.addEventListener(events.ExternalVideo.VideoLoadedMetadata, onLoadedMetadata);
      mediaElement.addEventListener(events.ExternalVideo.VideoDurationChange, onVideoDurationChange);
      mediaElement.addEventListener(events.ExternalVideo.VideoProgress, onVideoProgress);

      var onRequestAnimationFrame = function() {
         updatePlayProgress();
         updateSeekHint();
         _requestAnimationId = _windowRef.requestAnimationFrame(onRequestAnimationFrame);
      };

      _requestAnimationId = _windowRef.requestAnimationFrame(onRequestAnimationFrame);

      // will be re-shown when metadata loads
      cacheProgressBarWidth();
      _$scrubBar.hide();

      if (!playerConfiguration.videoThumbnailsSrc) {
         _$scrubVideoThumbnail.hide();
      } else {
         _$scrubPreview.addClass(cssClasses.scrubPreviewHasThumbnail);
         _$scrubPreview.css({width: `${playerConfiguration.videoThumbnailWidth + SCRUB_PREVIEW_BOX_PADDING}px`});
         _$hiddenVideoThumbnail.css({width: `${playerConfiguration.videoThumbnailWidth}px`, height: `${playerConfiguration.videoThumbnailHeight}px`});
         _$scrubVideoThumbnail.attr('width', `${playerConfiguration.videoThumbnailWidth}px`);
         _$scrubVideoThumbnail.attr('height', `${playerConfiguration.videoThumbnailHeight}px`);
         loadVideoThumbnailsImage();
      }

      createDefaultChapter();
      initPlugins();
   };

   var createTocChapters = function(xmpTocItems) {
      if (!mediaElement.duration) {
         return;
      }
      // limit number chapters the player will render, protect from kayak man projects :)
      if (xmpTocItems.length > MAX_NUMBER_CHAPTERS_CAN_RENDER) {
         console.info('The Table of contents contains too many items to render in the progress bar.'); // eslint-disable-line
         return;
      }
      _$scrubbarTrack.empty();
      _chaptersCache = [];

      var mediaDurationInMilliseconds = mediaElement.duration * 1000;
      var lastStartTime = 0;
      var lastChapterDuration = 0;
      for (var i = 0; i < xmpTocItems.length; i++) {
         if (xmpTocItems[i].startTime >= mediaDurationInMilliseconds) {
            continue;
         }
         var nextChapterTimeOrDuration = i < xmpTocItems.length - 1 ? xmpTocItems[i + 1].startTime : mediaDurationInMilliseconds;
         var chapterDurationInMilliseconds = nextChapterTimeOrDuration - lastStartTime;
         var chapterPercentage = chapterDurationInMilliseconds / mediaDurationInMilliseconds * 100;
         var progressBarChapterSegmentMarkup = templateGenerator.generateProgressBarChapterSegmentMarkup(i);
         var $chapterElement = $(progressBarChapterSegmentMarkup);
         $chapterElement.css('width', chapterPercentage + '%');
         _$scrubbarTrack.append($chapterElement);

         _chaptersCache.push(ProgressChapterModel.create(
            i,
            xmpTocItems[i].name,
            xmpTocItems[i].startTime,
            lastChapterDuration,
            lastChapterDuration + chapterPercentage,
            chapterPercentage,
            $chapterElement));

         lastChapterDuration += chapterPercentage;
         lastStartTime = nextChapterTimeOrDuration;
      }
   };

   var onVideoProgress = function() {
      var downloadedPercent = 0;
      if (mediaElement) {
         if (mediaElement.buffered && mediaElement.buffered.length) {
            downloadedPercent = Math.min(MAX_LOAD_VALUE, Math.ceil(mediaElement.buffered.end(mediaElement.buffered.length - 1) * MAX_LOAD_VALUE / mediaElement.duration));
         }

         updateLoadProgress(downloadedPercent);

         if (downloadedPercent === MAX_LOAD_VALUE && _updateBufferedTimeout !== -1) {
            _windowRef.clearInterval(_updateBufferedTimeout);
         }
      }
   };

   var onVideoDurationChange = function() {
      // this is a workaround because the ipad/iphone do not support the
      // progress event so we must check the buffering every second
      onVideoProgress();

      if (_updateBufferedTimeout !== -1) {
         _windowRef.clearInterval(_updateBufferedTimeout);
      }

      updateQuizMarkers();

      _updateBufferedTimeout = _windowRef.setInterval(onVideoProgress, CHECK_VIDEO_LOAD_PROGRESS_INTERVAL);
   };

   var destroy = function() {
      if (_updateBufferedTimeout !== -1) {
         _windowRef.clearInterval(_updateBufferedTimeout);
      }

      _$scrubBar.off('touchstart', onDownSeek);
      _$scrubBar.off('mousedown', onMousedown);
      _$scrubBar.off('mouseover touchstart', showScrubPreview);
      _$scrubBar.off('mouseout touchend', onScrubBarMouseOut);
      $container.off('keydown', keyPressed);
      $container.off(events.Modals.Open, disableKeyListeners);
      $container.off(events.Modals.Closed, enableKeyListeners);
      $container.off(events.Controls.Disable, disableKeyListeners);
      $container.off(events.Controls.Enable, enableKeyListeners);
      $container.off(events.Quizzing.Complete, onQuizzingCompleteHandler);
      $container.off(events.Controls.PlayerResize, onPlayerResize);
      $container.off(events.Plugin.Added, onAddPlugin);
      mediaElement.removeEventListener(events.ExternalVideo.VideoSeeked, onMediaSeekedEvent);
      mediaElement.removeEventListener(events.ExternalVideo.VideoDurationChange, onVideoDurationChange);
      mediaElement.removeEventListener(events.ExternalVideo.VideoProgress, onVideoProgress);

      if (_requestAnimationId !== null) {
         _windowRef.cancelAnimationFrame(_requestAnimationId);
      }
   };

   var durationHasChanged = function() {
      _durationDisplayInitialized = false;
   };

   setup();

   return Object.defineProperties({
      getXPositionForQuestionSetMarker: getXPositionForQuestionSetMarker,
      updateQuizMarkers: updateQuizMarkers,
      updateLoadProgress: updateLoadProgress,
      allowSkipQuestionSetMarkersBeforeTime: allowSkipQuestionSetMarkersBeforeTime,
      durationHasChanged: durationHasChanged,
      createTocChapters: createTocChapters,
      destroy: destroy,
      setShouldRespectQuizMarkers: setShouldRespectQuizMarkers
   }, {
      scrubbing: {
         get: function() {
            return _scrubbing;
         },
         set: setScrubbing
      },
      lastScrubTime: {
         get: function() {
            return _lastScrubTime;
         }
      },
      controlElement: {
         get: function() {
            return _$scrubBar;
         }
      },
      displayWidth: {
         get: function() {
            return _currentProgressBarWidth;
         }
      }
   });
};

export default {
   /**
    *  Factory method that returns a new ProgressBarControlView object
    *  @function create
    *  @memberof TSC.ProgressBarControlView
    *  @param {jQuery} $container - Smart Player root dom element
    *  @param {HTMLVideoElement} mediaElement
    *  @param {TSC.Quiz} quizModel
    *  @static
    *  @return new ProgressBarControlView instance
    */
   create: ProgressBarControlView
};
