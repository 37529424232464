import reportType from '../constants/report-type';
import QuestionSetMarker from './question-set-marker';
import QuestionSet from './question-set';

var MIN_MARKER_TIME_DIFF = 2000;

/**
 * @memberof TSC
 * @class Quiz
 * @classdesc Quiz Model
 * @param {TSC.Xmp} xmpModel - Xmp Model
 */
var Quiz = function(xmpModel) {
   var _productionName;
   var _originId;
   var _projectName;
   var _quizTitle;
   var _quizId;
   var _quizInstanceId;
   var _authoredEmail;
   var _clientId;
   var _quizHash;
   var _reportMethod = reportType.NONE;
   var _requireUserId = true;
   var _hideReplay = false;
   var _questionSets = [];
   var _questionSetMarkers = [];
   var _mediaDuration = 0;
   var _locale = '';
   var _allowSkipQuiz = true;

   var createQuizDefinition = function() {
      var quizDef = {};
      quizDef.quizId = _quizId;
      quizDef.lang = _locale;
      quizDef.projectName = _projectName;
      quizDef.productionName = _productionName;
      quizDef.originId = _originId;
      quizDef.mediaDuration = _mediaDuration;
      quizDef.reportRecipients = [_authoredEmail];
      quizDef.questionSets = [];

      _questionSets.forEach(function(questionSet) {
         quizDef.questionSets.push(questionSet.toJSON());
      });

      return window.JSON.stringify(quizDef);
   };

   var setAllowSkipQuiz = function(allowSkipQuiz) {
      _allowSkipQuiz = !(allowSkipQuiz === '0' || allowSkipQuiz === false);
   };

   var setReportMethod = function(method) {
      if (reportType.isValid(method)) {
         _reportMethod = method;
      }
   };

   var getApiDataForQuestionSet = function(questionSetIndex) {
      if (questionSetIndex < 0 || questionSetIndex >= _questionSets.length) {
         return;
      }
      return _questionSets[questionSetIndex].getQuizServiceData(_quizId);
   };

   var createQuestionSetMarkers = function() {
      _questionSetMarkers = [];
      _questionSets.forEach(function(questionSet, index) {
         _questionSetMarkers.push(QuestionSetMarker.create(index, questionSet));
      });
   };

   var processXmp = function() {
      _productionName = xmpModel.title;
      _originId = xmpModel.originId;
      _projectName = xmpModel.project;
      _locale = xmpModel.quizLocale;
      _quizTitle = xmpModel.title;
      _quizId = xmpModel.quizGuid;
      _requireUserId = xmpModel.quizRequireUserId;
      _hideReplay = xmpModel.quizHideReplay;
      _authoredEmail = xmpModel.quizAuthoredEmail;
      _clientId = xmpModel.quizClientId;
      _quizHash = xmpModel.quizHash;
      _mediaDuration = xmpModel.mediaDuration;

      setReportMethod(xmpModel.quizReportMethod);
      setAllowSkipQuiz(xmpModel.quizAllowSkipQuiz);

      xmpModel.quizQuestionSetArray.forEach(function(tscmeQuestionSet, index) {
         _questionSets.push(QuestionSet.create(tscmeQuestionSet, index + 1));
      });
   };

   var getScormScore = function() {
      var scormScore = 0;
      var totalGradableQuestions = 0;
      var totalCorrectQuestions = 0;

      _questionSets.forEach(function(questionSet) {
         totalGradableQuestions += questionSet.numberGradedQuestions;
         totalCorrectQuestions += questionSet.numberCorrectQuestions;
      });

      if (totalGradableQuestions !== 0) {
         scormScore = totalCorrectQuestions / totalGradableQuestions * 100;
      }

      return scormScore;
   };

   var isLastQuestionComplete = function(considerQuestionSetCompleteId) {
      if (considerQuestionSetCompleteId === undefined) {
         considerQuestionSetCompleteId = -1;
      }

      return _questionSets.every(function(questionSet, index) {
         return questionSet.numberGradedQuestions === 0 || questionSet.completed || index === considerQuestionSetCompleteId;
      });
   };

   var getPreviousMarkerTimeForMarker = function(questionSetIndex) {
      var markerTime = 0;
      var timeDiff;

      if (questionSetIndex > 0) {
         if (questionSetIndex >= _questionSets.length) {
            markerTime = _questionSets[_questionSets.length - 1].startTime;
         } else {
            for (var i = questionSetIndex - 1; i >= 0; i--) {
               if (!_questionSets[i].completed) {
                  continue;
               }
               timeDiff = _questionSets[questionSetIndex].startTime - _questionSets[i].startTime;

               if (timeDiff >= MIN_MARKER_TIME_DIFF) {
                  markerTime = _questionSets[i].startTime;
                  break;
               }
            }
         }
      }
      return markerTime;
   };

   var getSnapShot = function() {
      return {
         qs: _questionSets.map(function(questionSet) {
            return questionSet.getSnapShot();
         })
      };
   };

   var populateQuiz = function(quizModelSnapshot) {
      quizModelSnapshot.qs.forEach(function(questionSetSnapshot) {
         for (var i = 0; i < _questionSets.length; i++) {
            if (_questionSets[i].name === questionSetSnapshot.n) {
               _questionSets[i].populateQuestionSet(questionSetSnapshot);
            }
         }
      });
   };

   processXmp();
   createQuestionSetMarkers();

   return Object.defineProperties({
      getApiDataForQuestionSet: getApiDataForQuestionSet,
      getScormScore: getScormScore,
      isLastQuestionComplete: isLastQuestionComplete,
      getPreviousMarkerTimeForMarker: getPreviousMarkerTimeForMarker,
      getSnapShot: getSnapShot,
      populateQuiz: populateQuiz
   }, {
      quizDefinition: {
         get: function() {
            return createQuizDefinition();
         }
      },
      useQuizApi: {
         get: function() {
            return _reportMethod === reportType.API || _reportMethod === reportType.APIANDSCORM;
         }
      },
      useScorm: {
         get: function() {
            return _reportMethod === reportType.SCORM || _reportMethod === reportType.APIANDSCORM;
         }
      },
      quizTitle: {
         get: function() {
            return _quizTitle;
         }
      },
      quizID: {
         get: function() {
            return _quizId;
         }
      },
      quizInstanceID: {
         set: function(quizInstanceId) {
            _quizInstanceId = quizInstanceId;
         },
         get: function() {
            return _quizInstanceId;
         }
      },
      authoredEmail: {
         get: function() {
            return _authoredEmail;
         }
      },
      clientId: {
         get: function() {
            return _clientId;
         }
      },
      quizHash: {
         get: function() {
            return _quizHash;
         }
      },
      mediaDuration: {
         get: function() {
            return _mediaDuration;
         }
      },
      reportMethod: {
         set: setReportMethod,
         get: function() {
            return _reportMethod;
         }
      },
      requireUserId: {
         get: function() {
            return _requireUserId;
         }
      },
      hideReplay: {
         get: function() {
            return _hideReplay;
         }
      },
      allowSkipQuiz: {
         get: function() {
            return _allowSkipQuiz;
         }
      },
      locale: {
         get: function() {
            return _locale;
         }
      },
      questionSetMarkerTimes: {
         get: function() {
            return _questionSetMarkers;
         }
      },
      numberQuestionSets: {
         get: function() {
            return _questionSets.length;
         }
      },
      questionSets: {
         get: function() {
            return _questionSets;
         }
      },
      minMarkerTimeDiff: {
         get: function() {
            return MIN_MARKER_TIME_DIFF;
         }
      },
      totalNumberOfGradedQuestionSets: {
         get: function() {
            var totalGradableQuestionSets = 0;
            _questionSets.forEach(function(questionSet) {
               if (questionSet.numberGradedQuestions > 0) {
                  totalGradableQuestionSets++;
               }
            });
            return totalGradableQuestionSets;
         }
      }
   });
};

export default {
   /**
    *  Factory method that returns a new Quiz object.
    *  @function create
    *  @memberof TSC.Quiz
    *  @param {TSC.Xmp} xmpModel - Xmp Model
    *  @static
    *  @return new Quiz instance
    */
   create: Quiz
};
