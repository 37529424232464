const openExternalLinkTemplate = ({screenReaderAlertText, headerText, link, subHeaderText, replayText, openButtonScreenReaderText, visitSiteText}) => `<div class="open-external-link-content">
   <span class="sr-only" role="status" aria-live="polite">${screenReaderAlertText}</span>
   <div class="open-external-link-text">${headerText}</div>
   <div class="open-external-link-text link-text" title=${link}>${link}</div>
   ${subHeaderText ? `<div class="open-external-link-text">${subHeaderText}</div>` : ''}
   <div class="open-external-link-actions">
      <div class="button-and-text-container">
         <div class="open-external-link-button-container">
            <button role="button" aria-label="${replayText}" class="open-external-link-replay ui open-external-link-replay-glyph"></button>
         </div>
         <div class="open-external-link-text">${replayText}</div>
      </div>
      <div class="button-and-text-container">
         <div class="open-external-link-button-container">
            <button role="button" class="open-external-link-open ui open-external-link-open-glyph" aria-label="${openButtonScreenReaderText}" title="${link}"></button>
         </div>
         <div class="open-external-link-text">${visitSiteText}</div>
      </div>
   </div>
</div>`;

export default openExternalLinkTemplate;
