import textSanitizer from '../utils/text-sanitizer';
import questionType from '../constants/question-type';

var VERSION = 1;

var questionSetToXml = function(questionSet, quizInstanceId) {
   var xmlStr = '<?xml version="1.0" encoding="UTF-8" ?>';
   xmlStr += '<UserResponse instance="' + quizInstanceId + '" quiz="' + questionSet.quizId + '" order="' + questionSet.order + '" version="' + VERSION + '">';

   questionSet.questions.forEach(function(question) {
      xmlStr += '<Question type="' + question.type + '" id="' + question.id + '">';
      xmlStr += '<Duration>' + question.duration + '</Duration>';
      xmlStr += '<Response>' + textSanitizer.htmlEncode(question.response) + '</Response>';

      if (question.type !== questionType.SHORT && question.correct !== undefined) {
         xmlStr += '<Correct>' + (question.correct ? 'True' : 'False') + '</Correct>';
      }

      xmlStr += '<SegmentWatched>' + question.segmentWatched + '</SegmentWatched>';
      xmlStr += '</Question>';
   });

   xmlStr += '</UserResponse>';
   return xmlStr;
};

var quizDefinitionToXml = function(quizDefinition) {
   var xmlStr = '<?xml version="1.0" encoding="UTF-8" ?>';
   xmlStr += '<QuizDefinition  version="' + VERSION + '" quiz="' + quizDefinition.quizId + '" locale="' + quizDefinition.lang + '">';
   xmlStr += '<ProjectName>' + textSanitizer.htmlEncode(quizDefinition.projectName) + '</ProjectName>';
   xmlStr += '<ProductionName>' + quizDefinition.productionName + '</ProductionName>';
   xmlStr += '<OriginId>' + quizDefinition.originId + '</OriginId>';
   xmlStr += '<MediaDuration>' + quizDefinition.mediaDuration + '</MediaDuration>';
   xmlStr += '<ReportRecipients>';

   quizDefinition.reportRecipients.forEach(function(recipient) {
      xmlStr += '<Email>' + recipient + '</Email>';
   });

   xmlStr += '</ReportRecipients>';
   xmlStr += '<QuestionSets>';

   quizDefinition.questionSets.forEach(function(questionSet) {
      xmlStr += '<QuestionSet name="' + textSanitizer.htmlEncode(questionSet.name) + '" mediaTimePosition="' + questionSet.mediaTimePosition + '" order="' + questionSet.order + '">';

      questionSet.questions.forEach(function(question) {
         xmlStr += '<Question type="' + question.type + '" id="' + question.id + '">';
         xmlStr += '<QuestionText>' + textSanitizer.htmlEncode(question.questionText, true) + '</QuestionText>';

         if (question.type === questionType.CHOICE) {
            xmlStr += '<Choices>';

            question.choices.forEach(function(choice) {
               xmlStr += '<Answer>' + textSanitizer.htmlEncode(choice, true) + '</Answer>';
            });

            xmlStr += '</Choices>';
            xmlStr += '<CorrectAnswer>' + question.correctAnswer + '</CorrectAnswer>';
         } else if (question.type === questionType.FILL_IN) {
            xmlStr += '<Answers>';

            question.choices.forEach(function(choice) {
               xmlStr += '<Answer>' + textSanitizer.htmlEncode(choice, true) + '</Answer>';
            });

            xmlStr += '</Answers>';
         }

         xmlStr += '</Question>';
      });

      xmlStr += '</QuestionSet>';
   });

   xmlStr += '</QuestionSets>';
   xmlStr += '</QuizDefinition>';

   return xmlStr;
};

export default {
   questionSetToXml: questionSetToXml,
   quizDefinitionToXml: quizDefinitionToXml
};
