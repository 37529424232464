import languageCodes from '../constants/language-codes';
import enUsLocale from './locale/en-us';
import deDeLocale from './locale/de-de';
import jaJpLocale from './locale/ja-jp';
import frFrLocale from './locale/fr-fr';
import esEsLocale from './locale/es-es';
import zhCnLocale from './locale/zh-cn';
import ptBrLocale from './locale/pt-br';

var getLocaleByLanguageCode = function(languageCode) {
   switch (languageCode) {
      case languageCodes.ENGLISH:
         return enUsLocale;
      case languageCodes.GERMAN:
         return deDeLocale;
      case languageCodes.JAPANESE:
         return jaJpLocale;
      case languageCodes.FRENCH:
         return frFrLocale;
      case languageCodes.SPANISH:
         return esEsLocale;
      case languageCodes.CHINESE:
         return zhCnLocale;
      case languageCodes.PORTUGUESE:
         return ptBrLocale;
      default:
         return enUsLocale;
   }
};

export default Object.defineProperties({
   getLocaleByLanguageCode: getLocaleByLanguageCode
}, {});
