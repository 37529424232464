/**
 * @memberof TSC
 * @class ProgressChapterModel
 * @classdesc Progress Chapter View Model
 * @param {Number} index - Index of the chapter
 * @param {String} name - Chapter name
 * @param {Number} startTime - Start time of the video for the chapter (in seconds)
 * @param {Number} startAsPercent - Percentage of the video when the chapter starts
 * @param {Number} endAsPercent - Percentage of the video when the chapter ends
 * @param {Number} durationAsPercent - Duration of the chapter as a percentage of the video duration
 * @param {jQuery} $chapterElement - jQuery wrapper of the chapter element
 */
var ProgressChapterModel = function(index, name, startTime, startAsPercent, endAsPercent, durationAsPercent, $chapterElement) {
   var _index = index;
   var _name = name;
   var _startTime = startTime;
   var _startAsPercent = startAsPercent;
   var _endAsPercent = endAsPercent;
   var _durationAsPercent = durationAsPercent;
   var _$chapterElement = $chapterElement;
   var _$loadProgressElement = $chapterElement.find('.load-progress');
   var _$seekHintProgressElement = $chapterElement.find('.seek-hint-progress');
   var _$playProgressElement = $chapterElement.find('.play-progress');

   return Object.defineProperties({}, {
      index: {
         get: function() {
            return _index;
         }
      },
      name: {
         get: function() {
            return _name;
         }
      },
      startTime: {
         get: function() {
            return _startTime;
         }
      },
      startAsPercent: {
         get: function() {
            return _startAsPercent;
         }
      },
      endAsPercent: {
         get: function() {
            return _endAsPercent;
         }
      },
      durationAsPercent: {
         get: function() {
            return _durationAsPercent;
         }
      },
      $element: {
         get: function() {
            return _$chapterElement;
         }
      },
      $loadProgressElement: {
         get: function() {
            return _$loadProgressElement;
         }
      },
      $seekHintProgressElement: {
         get: function() {
            return _$seekHintProgressElement;
         }
      },
      $playProgressElement: {
         get: function() {
            return _$playProgressElement;
         }
      }
   });
};

export default {
   /**
    *  Factory method that returns a new ProgressChapterModel object.
    *  @function create
    *  @memberof TSC.ProgressChapterModel
    *  @static
    *  @param {Number} index - Index of the chapter
    *  @param {String} name - Chapter name
    *  @param {Number} startTime - Start time of the video for the chapter (in seconds)
    *  @param {Number} startAsPercent - Percentage of the video when the chapter starts
    *  @param {Number} endAsPercent - Percentage of the video when the chapter ends
    *  @param {Number} durationAsPercent - Duration of the chapter as a percentage of the video duration
    *  @param {jQuery} $chapterElement - jQuery wrapper of the chapter element
    *  @return new ProgressChapterModel instance
    */
   create: ProgressChapterModel
};
