import languageCodes from '../../constants/language-codes';
import playerStringNames from '../../constants/player-string-names';

var strings = {};
strings[playerStringNames.xmpError] = 'Parece que há um problema no acesso a certos recursos deste vídeo. Entre em contato com o autor do vídeo.';
strings[playerStringNames.xmpSecurity] = 'Certos recursos do seu vídeo estão atualmente desativados devido à Segurança da Rede Local. Esses recursos funcionam corretamente quando o vídeo é reproduzido a partir de um site ou servidor.';
strings[playerStringNames.videoNotSupported] = 'Seu navegador não pode reproduzir este vídeo.';
strings[playerStringNames.noWebMSupport] = 'Este navegador não aceita vídeo WebM.';
strings[playerStringNames.search] = 'Pesquisar';
strings[playerStringNames.searchPlaceholder] = 'Pesquisar...';
strings[playerStringNames.playerRateText] = 'Velocidade de Reprodução';
strings[playerStringNames.playerRateOptionsText] = {
   0.25: '0,25',
   0.5: '0,5',
   0.75: '0,75',
   1: 'normal',
   1.25: '1,25',
   1.5: '1,5',
   1.75: '1,75',
   2.0: '2,0'
};
strings[playerStringNames.ccLanguageText] = 'Closed Captions';
strings[playerStringNames.searchDisabledFullScreen] = 'A pesquisa é desativada no modo de tela cheia.';
strings[playerStringNames.mediaNotFound] = 'Desculpe, mas não é possível conectar a este conteúdo. Tente atualizar a página ou <a href=\'https://www.techsmith.com/redirect.asp?target=media_not_found&ver=4.*.*&lang=enu&os=na&product=smartplayer\' target=\'_blank\'> clique aqui para obter mais ajuda.</a>';
strings[playerStringNames.videoLoading] = 'O vídeo está carregando.';
strings[playerStringNames.close] = 'Fechar';
strings[playerStringNames.txtRequiredMessage] = 'O dono do conteúdo solicita as seguintes informações:';
strings[playerStringNames.txtSkip] = 'Continuar sem perguntas';
strings[playerStringNames.txtBegin] = 'Enviar e continuar';
strings[playerStringNames.txtEnterFirstName] = 'Digite o nome';
strings[playerStringNames.txtEnterLastName] = 'Digite o sobrenome';
strings[playerStringNames.txtEnterEmail] = 'Digite o endereço de e-mail';
strings[playerStringNames.txtHotspot] = 'Hotspot';
strings[playerStringNames.txtHotspotScreenReaderAlert] = '{{numberOfHotspots}} novo hotspot';
strings[playerStringNames.txtHotspotScreenReaderAlertPlural] = '{{numberOfHotspots}} novos hotspots';
strings[playerStringNames.txtJumpToMedia] = 'Ir para mídia';
strings[playerStringNames.txtJumpToTime] = 'Ir para tempo';
strings[playerStringNames.txtOpenLink] = 'Abrir link';
strings[playerStringNames.txtOpenLinkHeader] = 'Este vídeo gostaria de direcionar você a:';
strings[playerStringNames.txtOpenLinkSubHeader] = '';
strings[playerStringNames.txtReview] = 'Repetir última seção';
strings[playerStringNames.txtReplayVideo] = 'Repetir vídeo';
strings[playerStringNames.txtAnswerQuestion] = 'Fazer o teste agora';
strings[playerStringNames.txtPrev] = 'Anterior';
strings[playerStringNames.txtNext] = 'Próximo';
strings[playerStringNames.txtHideQuiz] = 'Ocultar';
strings[playerStringNames.txtSubmit] = 'Enviar respostas';
strings[playerStringNames.txtSubmittingAnswers] = 'Enviando respostas.';
strings[playerStringNames.txtContinue] = 'Continuar';
strings[playerStringNames.txtReviewAnswer] = 'Ver respostas';
strings[playerStringNames.txtBadImageQuiz] = 'Desculpe, mas o teste solicitado não é compatível com imagens e não pode ser carregado.  Entre em contato com o autor deste teste.';
strings[playerStringNames.txtVideoQuizNetworkErrorMessage] = 'Desculpe, mas um erro na rede impediu o carregamento do teste. O vídeo está disponível para visualização. Para tentar recarregar o teste, atualize a página do navegador ou verifique sua conexão de rede.';
strings[playerStringNames.txtImageQuizNetworkErrorMessage] = 'Desculpe, um erro na rede impediu o carregamento do teste. A imagem está disponível para visualização. Para tentar recarregar o teste, atualize a página do navegador ou verifique sua conexão de rede.';
strings[playerStringNames.txtQuizErrorMessage] = 'Estamos tentando salvar sua resposta. Certifique-se de que você tenha uma conexão de internet ativa.';
strings[playerStringNames.txtQuizOutOfDateMessage] = 'As perguntas do teste foram alteradas. Atualize o player para acessar o teste mais recente.';
strings[playerStringNames.txtQuizBadDefinitionMessage] = 'Teste inválido. Não é possível enviar o feedback do teste. Indique <a href="https://www.techsmith.com/redirect.asp?target=bad_quiz_definition&ver=5.*.*&lang=enu&os=na&product=smartplayer" target="_blank">este artigo de ajuda</a> ao autor do vídeo.';
strings[playerStringNames.txtQuizOutOfDateRefreshButtonText] = 'Atualizar o player';
strings[playerStringNames.txtLoaderMessageDefinition] = 'O teste está carregando.';
strings[playerStringNames.txtQuestionCount] = 'Pergunta {{currentQuestion}} de {{totalQuestions}}';
strings[playerStringNames.txtResults] = 'Resultados: ';
strings[playerStringNames.txtCorrectString] = 'Correto';
strings[playerStringNames.txtIncorrectString] = 'Incorreto';
strings[playerStringNames.txtCorrect] = '{{numberCorrect}} Correto';
strings[playerStringNames.txtIncorrect] = '{{numberIncorrect}} Incorreto';
strings[playerStringNames.txtYourAnswer] = 'Sua resposta: ';
strings[playerStringNames.txtCorrectAnswer] = 'Resposta correta: ';
strings[playerStringNames.txtVisitSite] = 'Visitar site';
strings[playerStringNames.accessBtnAudioDescription] = 'Descrição do áudio';
strings[playerStringNames.accessBtnClearSearch] = 'Limpar pesquisa';
strings[playerStringNames.accessBtnRewind] = 'Retroceder {{duration}} segundos';
strings[playerStringNames.accessBtnForward] = 'Avançar {{duration}} segundos';
strings[playerStringNames.accessBtnPlay] = 'Reproduzir vídeo';
strings[playerStringNames.accessBtnPrevious] = 'Anterior';
strings[playerStringNames.accessBtnNext] = 'Próximo';
strings[playerStringNames.accessBtnProgressBar] = 'Barra deslizante de andamento';
strings[playerStringNames.accessBtnVolume] = 'Mudo';
strings[playerStringNames.accessTxtVolume] = 'Volume';
strings[playerStringNames.accessBtnClosedCaption] = 'Closed Caption';
strings[playerStringNames.accessBtnTableOfContents] = 'Mostrar Capítulos (T)';
strings[playerStringNames.accessBtnSettings] = 'Configurações do player';
strings[playerStringNames.accessBtnPip] = 'Picture-in-Picture';
strings[playerStringNames.accessBtnFullScreen] = 'Tela cheia';
strings[playerStringNames.accessBtnFullScreenExpand] = 'Abrir em uma nova guia';
strings[playerStringNames.accessBtnTakeQuiz] = 'Clique para fazer o teste.';
strings[playerStringNames.accessBtnShowQuiz] = 'Mostrar teste';
strings[playerStringNames.accessBtnHideQuiz] = 'Ocultar teste';
strings[playerStringNames.accessBtnPreviousQuestion] = 'Pergunta anterior';
strings[playerStringNames.accessBtnNextQuestion] = 'Próxima pergunta';
strings[playerStringNames.accessQuizHidden] = 'O teste foi ocultado';
strings[playerStringNames.accessQuizShown] = 'O teste está sendo exibido';
strings[playerStringNames.accessBtnShowAnswer] = 'Mostrar respostas';
strings[playerStringNames.accessBtnHideAnswer] = 'Ocultar respostas';
strings[playerStringNames.accessBtnAnswersSubmitted] = 'Respostas enviadas!';
strings[playerStringNames.accessBtnWereNotGraded] = ' não foram avaliadas.';
strings[playerStringNames.accessBtnWasNotGraded] = ' não foi avaliada.';
strings[playerStringNames.accessBtnCorrectOutOf] = ' correta(s) de ';
strings[playerStringNames.accessBtnGradedQuestions] = ' perguntas avaliadas.';
strings[playerStringNames.accessBtnClickToReplayLink] = 'Repetir';
strings[playerStringNames.accessResultsLabelCorrectSuffix] = 'está correta.';
strings[playerStringNames.accessResultsLabelIncorrectSuffix] = 'está incorreta.';
strings[playerStringNames.accessResultsLabelNoResponseSuffix] = 'você não respondeu a pergunta.';
strings[playerStringNames.accessResultsLabelNoResponseGradedSuffix] = 'você não respondeu a pergunta e sua resposta será considerada incorreta.';
strings[playerStringNames.scormApiNotFoundMessage] = 'Não é possível localizar a implementação da API do LMS.\nA comunicação com o LMS não ocorrerá.';
strings[playerStringNames.accessListTableOfContents] = 'Capítulos';
strings[playerStringNames.accessListSearchResults] = 'Resultados da pesquisa na ordem em que aparecem';
strings[playerStringNames.accessListNoSearchResults] = 'Nenhum resultado encontrado';
strings[playerStringNames.accessQuestionSetConfirmationTitle] = 'Teste';
strings[playerStringNames.accessVideoPausedForQuiz] = 'Vídeo pausado para teste';
strings[playerStringNames.accessQuizResults] = 'Resultados do teste';
strings[playerStringNames.accessQuestionFeedbackContext] = 'Resultado do teste: ';
strings[playerStringNames.accessProgressBarTimeConjunction] = ' de ';
strings[playerStringNames.accessPlayerSettingsClose] = 'Fechar configurações do player';
strings[playerStringNames.accessBtnTheaterMode] = 'Modo Cinema';
strings[playerStringNames.accessBtnExitTheaterMode] = 'Modo Padrão';
strings[playerStringNames.confirmOpenUrlModalScreenReaderAlertText] = 'O vídeo gostaria de abrir o seguinte URL URL_TOKEN.';
strings[playerStringNames.confirmOpenUrlConfirmButtonScreenReaderText] = 'Continuar para novo site';
strings[playerStringNames.fastForwardDisabledScreenReaderText] = 'A função de avanço rápido foi desativada para este vídeo';
strings[playerStringNames.scormCompleteVideoLater] = 'Concluir o vídeo mais tarde';
strings[playerStringNames.scormCompleteVideo] = 'Concluir o vídeo';
strings[playerStringNames.scormExitVideo] = 'Sair do vídeo';
strings[playerStringNames.scormConfirmCompleteModalText] = 'Tem certeza de que deseja sair do vídeo?';
strings[playerStringNames.scormConfirmCompleteModalResumeVideoText] = 'O vídeo será retomado de onde você parou na próxima vez que ele for aberto.';
strings[playerStringNames.scormConfirmCompleteModalRestartVideoText] = 'O vídeo será reiniciado do começo na próxima vez. Você perderá o andamento atual.';
strings[playerStringNames.scormConfirmCompleteModalFailedToSaveProgressText] = 'Houve um problema ao salvar seu progresso. O vídeo começará do início na próxima vez que você assisti-lo.';
strings[playerStringNames.scormConfirmCompleteModalDismissButtonText] = 'Retornar ao vídeo';
strings[playerStringNames.scormConfirmCompleteModalDismissButtonScreenReaderText] = 'Retornar ao vídeo';
strings[playerStringNames.scormConfirmCompleteModalConfirmButtonText] = 'Sair do vídeo';
strings[playerStringNames.scormConfirmCompleteModalConfirmButtonScreenReaderText] = 'Sair do vídeo';
strings[playerStringNames.scormSessionEndedText] = 'Você pode fechar a guia ou voltar para o seu curso.';
strings[playerStringNames.searchOrJumpTo] = 'Pesquisar ou pular para.';
strings[playerStringNames.searchTextCleared] = 'Texto de pesquisa apagado.';
strings[playerStringNames.searchResultsUpdated] = 'Resultados da pesquisa atualizados. {{number}} resultados.';
strings[playerStringNames.tableOfContentsDisplayed] = 'Capítulos exibidos.';
strings[playerStringNames.tableOfContentsHidden] = 'Capítulos ocultos.';
strings[playerStringNames.defaultMediaTitle] = 'TechSmith Smart Player';
strings[playerStringNames.hideThumbnailDueToQuiz] = 'Faça o teste para visualizar';
strings[playerStringNames.quizAtTimeHeading] = 'Teste às';
strings[playerStringNames.defaultFirstChapterName] = 'introdução';
strings[playerStringNames.acknowledgementLinkName] = 'Créditos';

var getLanguage = function() {
   return languageCodes.PORTUGUESE;
};

var getReadableTimeByLocale = function(hours, minutes, seconds) {
   var timeStringList = [];
   if (hours > 0) {
      var hoursReadableString = hours + ' hora';
      hoursReadableString += hours > 1 ? 's' : '';
      timeStringList.push(hoursReadableString);
   }
   if (minutes > 0) {
      var minutesReadableString = minutes + ' minuto';
      minutesReadableString += minutes > 1 ? 's' : '';
      timeStringList.push(minutesReadableString);
   }
   if (seconds > 0) {
      var secondsReadableString = seconds + ' segundo';
      secondsReadableString += seconds > 1 ? 's' : '';
      timeStringList.push(secondsReadableString);
   }
   return timeStringList.join(' e ');
};

export default Object.defineProperties({
   getReadableTimeByLocale: getReadableTimeByLocale
}, {
   language: {
      get: getLanguage
   },
   strings: {
      get: function() {
         return strings;
      }
   }
});
