import nullPipController from './null-pip-controller';
import StandardsPipController from './standards-pip-controller';
import LegacySafariPipController from './legacy-safari-pip-controller';
import playerConfiguration from '../models/player-configuration';

var getPipController = function(mediaElement) {
   if (!playerConfiguration.disablePictureInPicture && StandardsPipController.isControllerSupported()) {
      return StandardsPipController.create(mediaElement);
   } else if (!playerConfiguration.disablePictureInPicture && LegacySafariPipController.isControllerSupported(mediaElement)) {
      return LegacySafariPipController.create(mediaElement);
   }
   return nullPipController;
};

export default {
   getPipController: getPipController
};
