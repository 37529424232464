var playedTracker = function(video) {
   var mergeToleranceInSeconds = 0.5;
   // if two time updates are farther apart than this,
   // assume something like seek or replay happened, and we need a new segment
   var timeDifferenceToForceNewSegment = 5;

   var tracker = function() {
      var internalPlayed = [];
      var currentMediaSegmentIndex = 0;
      var that = this;
      var seeking = false;
      var lastTimeUpdate = 0;

      this.start = function(segmentIndex) {
         if (segmentIndex < 0 || segmentIndex > internalPlayed.length - 1) {
            throw new Error('INDEX_SIZE_ERR');
         }
         return internalPlayed[segmentIndex].start;
      };

      this.end = function(segmentIndex) {
         if (segmentIndex < 0 || segmentIndex > internalPlayed.length - 1) {
            throw new Error('INDEX_SIZE_ERR');
         }
         return internalPlayed[segmentIndex].end;
      };

      Object.defineProperty(this, 'length', {
         get: function() {
            return internalPlayed.length;
         }
      });

      var createNewPlayedMediaSegment = function(time) {
         var newIndex = 0;
         var numberPlayedSegments = internalPlayed.length;
         for (var i = 0; i < numberPlayedSegments; i++) {
            if (time > internalPlayed[i].end) {
               newIndex++;
            }
         }
         internalPlayed.splice(newIndex, 0, new PlayedMediaSegment());
         currentMediaSegmentIndex = newIndex;
      };

      var onTimeChange = function(event) {
         if (video.paused) {
            seeking = true;
            return;
         }

         var timeChangeSinceLastUpdate = Math.abs(event.data - lastTimeUpdate);
         lastTimeUpdate = event.data;

         if (seeking || timeChangeSinceLastUpdate > timeDifferenceToForceNewSegment) {
            seeking = false;
            onMediaSeekEnd(event);
         }

         if (that.length === 0) {
            createNewPlayedMediaSegment(event.data);
            internalPlayed[0].recordTime(0);
         }
         internalPlayed[currentMediaSegmentIndex].recordTime(event.data);
         if (internalPlayed.length > 1) {
            mergeOverlappingMediaSegments();
         }
      };

      var onMediaSeekEnd = function(event) {
         var mediaSegmentIndexForTime = getPlayedMediaSegmentIndexByTime(event.data);
         if (mediaSegmentIndexForTime === -1) {
            createNewPlayedMediaSegment(event.data);
         } else {
            currentMediaSegmentIndex = mediaSegmentIndexForTime;
         }
      };

      var mergeOverlappingMediaSegments = function() {
         var numberSegments = internalPlayed.length;
         for (var i = 0; i < numberSegments; i++) {
            if (currentMediaSegmentIndex !== i && that.areMediaSegmentsOverlapping(internalPlayed[currentMediaSegmentIndex], internalPlayed[i])) {
               mergeMediaSegments(currentMediaSegmentIndex, i);
               break;
            }
         }
      };

      var mergeMediaSegments = function(segmentOneIndex, segmentTwoIndex) {
         var mergedIndex = Math.min(segmentOneIndex, segmentTwoIndex);
         var notMergedIndex = Math.max(segmentOneIndex, segmentTwoIndex);
         internalPlayed[mergedIndex].start = Math.min(internalPlayed[segmentOneIndex].start, internalPlayed[segmentTwoIndex].start);
         internalPlayed[mergedIndex].end = Math.max(internalPlayed[segmentOneIndex].end, internalPlayed[segmentTwoIndex].end);
         internalPlayed.splice(notMergedIndex, 1);
         currentMediaSegmentIndex = mergedIndex;
      };

      var getPlayedMediaSegmentIndexByTime = function(time) {
         var segmentIndex = -1;
         var numberPlayedSegments = internalPlayed.length;
         for (var i = 0; i < numberPlayedSegments; i++) {
            if (time >= internalPlayed[i].start && time <= internalPlayed[i].end) {
               segmentIndex = i;
               break;
            }
         }
         return segmentIndex;
      };

      video.addEventListener('timeupdate', onTimeChange);
   };

   tracker.prototype.areMediaSegmentsOverlapping = function(segment1, segment2) {
      var startInOuterSegment = segment2.start >= segment1.start - mergeToleranceInSeconds && segment2.start <= segment1.end + mergeToleranceInSeconds;
      var endInOuterSegment = segment2.end >= segment1.start - mergeToleranceInSeconds && segment2.end <= segment1.end + mergeToleranceInSeconds;
      return startInOuterSegment || endInOuterSegment;
   };

   return new tracker();
};

var PlayedMediaSegment = function() {
   var startTime = -1;
   var endTime = -1;
   var segment = {};

   Object.defineProperty(segment, 'start', {
      get: function() {
         return startTime;
      },
      set: function(value) {
         startTime = +value;
      }
   });

   Object.defineProperty(segment, 'end', {
      get: function() {
         return endTime;
      },
      set: function(value) {
         endTime = +value;
      }
   });

   segment.recordTime = function(time) {
      if (startTime === -1 || time < startTime) {
         startTime = time;
      }

      if (time > endTime) {
         endTime = time;
      }
   };

   return segment;
};

export default playedTracker;
