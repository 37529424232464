import cssClasses from '../constants/css-classes';
import {CAPTION_POSITION} from '../constants/captions';

const CAPTION_VIDEO_WIDTH_PERCENTAGE = .75;
const LINE_HEIGHT_PADDING_UNDER_VIDEO = 24;
const MAX_DISPLAYED_LINES = 3;
var _scaledFontSize = 16;
var _currentCaptionWidth = 0;

var setStyle = function(captionsService, $positionContainer, $textContainer) {
   $textContainer.css({
      'font-family': captionsService.fontEnclosedWithQuotes,
      color: captionsService.textColor.toString()
   });

   $textContainer.find('span').css({
      background: captionsService.backgroundColor.toString()
   });

   $textContainer.addClass(cssClasses.captionXmpView);

   if (captionsService.position === CAPTION_POSITION.under) {
      // toHexString masks the alpha component so we get a non-transparent full bg
      $positionContainer.css('background', captionsService.backgroundColor.toHexString());
      $positionContainer.addClass(cssClasses.captionsUnderVideo);
      $positionContainer.removeClass(cssClasses.hide);
   }
};

var setWidth = function(scaledVideoWidth, $textContainer, captionPositionUnderVideo) {
   if (captionPositionUnderVideo) {
      $textContainer.css('width', `${scaledVideoWidth}px`);
   } else {
      _currentCaptionWidth = Math.ceil(scaledVideoWidth * CAPTION_VIDEO_WIDTH_PERCENTAGE);
      $textContainer.css('width', _currentCaptionWidth + 'px');
   }
};

var setScale = function(captionsService, canvasScale, $positionContainer) {
   var fontSize = Math.round(captionsService.fontSize * canvasScale);
   fontSize = Math.min(Math.max(captionsService.fontSizeMin, fontSize), captionsService.fontSizeMax);
   _scaledFontSize = fontSize;

   if (captionsService.position === CAPTION_POSITION.under) {
      const maxRequiredHeight = Math.ceil(_scaledFontSize * MAX_DISPLAYED_LINES + LINE_HEIGHT_PADDING_UNDER_VIDEO);
      $positionContainer.css('height', `${maxRequiredHeight}px`);
   }
   $positionContainer.css('font-size', `${_scaledFontSize}px`);
};

var setText = function(caption, $textContainer) {
   $textContainer.html('<span>' + caption.markupText.replace('&para', '&amp;para') + '</span>');
};

export default Object.defineProperties({
   setStyle: setStyle,
   setWidth: setWidth,
   setScale: setScale,
   setText: setText
}, {
   scaledFontSize: {
      get: function() {
         return _scaledFontSize;
      }
   },
   captionWidth: {
      get: function() {
         return _currentCaptionWidth;
      }
   }
});
