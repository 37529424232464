import externalMessageController from './external-message-controller';
import themeNames from '../../common/theme-names';
import playerConfiguration from '../models/player-configuration';
   
var _$spElement = null;

var setTheme = function(themeName) {
   playerConfiguration.setTheme(themeName);
   applyTheme();
};

var registerCallback = function() {
   externalMessageController.addCallback('SET_THEME', setTheme);
};

var setSmartPlayerContainerElement = function($element) {
   _$spElement = $element;
   applyTheme();
};

var getClassNameForTheme = function(theme) {
   return 'smartplayer-' + theme + '-theme';
};

var applyTheme = function() {
   if (_$spElement) {
      var themeClassNameAsString = Object.keys(themeNames).reduce(function(accumulator, currentValue) {
         return accumulator + getClassNameForTheme(themeNames[currentValue]) + ' ';
      }, '');

      var currentThemeClassName = getClassNameForTheme(playerConfiguration.getTheme());
      _$spElement.removeClass(themeClassNameAsString);
      _$spElement.addClass(currentThemeClassName);
   }
};

registerCallback();

export default {
   setTheme: setTheme,
   setSmartPlayerContainerElement: setSmartPlayerContainerElement
};
