const quizSetupTemplate = ({setupMessage, enterName1, name1Class, enterName2, name2Class, enterEmail, beginButtonText, skipControlText}) => `<div class="tsc_quiz_setup_ui_container">
   <div class="tsc_quiz_setup_container">
      <div class="tsc_setup_input_container">
         <h2 class="quiz-user-info-instructions">${setupMessage}</h2>
         <input type="text" name="${enterName1}" aria-label="${setupMessage} ${enterName1}" class="quiz-user-input name-component-input ${name1Class}"
                placeholder="${enterName1}" size="24" maxlength="256" spellcheck="false" data-cy="quiz-first-name-input" />
         <input type="text" aria-label="${enterName2}" name="${enterName2}" class="quiz-user-input name-component-input ${name2Class}"
                placeholder="${enterName2}" size="24" maxlength="256" spellcheck="false" data-cy="quiz-second-name-input" />
         <input type="email" aria-label="${enterEmail}" name="${enterEmail}" class="quiz-user-input email_address"
                placeholder="${enterEmail}" size="55" maxlength="150" spellcheck="false" data-cy="quiz-email-input" />
         <div class="button-container">
            <button type="button" class="button button-primary tsc_begin_button" disabled data-cy="quiz-user-submit-button">${beginButtonText}</button>
            <button type="button" class="button button-secondary skip_control" data-cy="quiz-skip-user-input-button">${skipControlText}</button>
         </div>
      </div>
   </div>
</div>`;

export default quizSetupTemplate;
