import mediaInterfaceConstants from '../constants/media-interface-constants';
import {playReplayControlStates} from '../views/before-after-play-controls-view';
import {CAPTION_POSITION} from '../constants/captions';
import events from '../constants/events';
import preloadOptions from '../constants/preload-options';

/**
 * @memberof TSC
 * @class VideoControlsDisplayModeController
 * @classdesc Video controls controller - binds all the controls used for a video in Smart Player
 * @param {jQuery} $rootSmartPlayerElement - jQuery wrapper of Top level dom element for Smart Player
 * @param {TSC.VideoControlsController} videoControlsController - Video Controls Controller
 * @param {TSC.playerConfiguration} playerConfiguration -  Player configuration
 */
var VideoControlsDisplayModeController = function($rootSmartPlayerElement, videoControlsController, playerConfiguration) {
   var _quizUIVisible = false;
   var _quizUIMinimized = false;
   var _pipViewActive = false;
   var _captionViewPosition = null;
   var _searchIsFocused = false;
   var _isScrubbing = false;
   var _externalLinkViewOpen = false;
   var _mediaError = false;

   var getDisplayMode = function() {
      if (!videoControlsController.playbackInitialized && playerConfiguration.getPreload() !== preloadOptions.metadata) {
         return mediaInterfaceConstants.controls.false;
      } else if (_mediaError) {
         return mediaInterfaceConstants.controls.false;
      } else if (_externalLinkViewOpen) {
         return mediaInterfaceConstants.controls.false;
      } else if (_pipViewActive) {
         return mediaInterfaceConstants.controls.false;
      } else if (_quizUIVisible) {
         return _quizUIMinimized ? mediaInterfaceConstants.controls.false : mediaInterfaceConstants.controls.true;
      } else if (videoControlsController.beforeAfterPlayControlsView.viewState !== playReplayControlStates.none || playerConfiguration.getDisableControls() || playerConfiguration.getDisableInteractions()) {
         return mediaInterfaceConstants.controls.false;
      } else if (_isScrubbing || _searchIsFocused || !playerConfiguration.getAutoHideControls() || _captionViewPosition === CAPTION_POSITION.under) {
         return mediaInterfaceConstants.controls.true;
      }
      return mediaInterfaceConstants.controls.autohide;
   };

   var updateVideoControlsDisplayMode = function() {
      videoControlsController.setDisplayMode(getDisplayMode());
   };

   var onDisplayCaptionControl = function(e, eventData) {
      _captionViewPosition = eventData && eventData.captionViewPosition || null;
      updateVideoControlsDisplayMode();
   };

   var onPipChanged = function(e, eventData) {
      _pipViewActive = eventData.pipViewActive;
      updateVideoControlsDisplayMode();
   };

   var onQuizUIVisible = function() {
      _quizUIVisible = true;
      updateVideoControlsDisplayMode();
   };

   var onQuizUIHidden = function() {
      _quizUIVisible = false;
      updateVideoControlsDisplayMode();
   };

   var onMinimizeQuizUI = function() {
      _quizUIMinimized = true;
      updateVideoControlsDisplayMode();
   };

   var onMaximizeQuizUI = function() {
      _quizUIMinimized = false;
      updateVideoControlsDisplayMode();
   };

   var onShowQuizSetup = function() {
      onMinimizeQuizUI();
      onQuizUIVisible();
   };

   var onQuizSetupComplete = function() {
      onQuizUIHidden();
   };

   var handleControlsUpdated = function() {
      videoControlsController.setDisplayMode(getDisplayMode());
   };

   var onSearchFocusChange = function(e, eventData) {
      _searchIsFocused = eventData.searchIsFocused;
      updateVideoControlsDisplayMode();
   };

   var onScrubbingStarted = function() {
      _isScrubbing = true;
      updateVideoControlsDisplayMode();
   };

   var onScrubbingEnded = function() {
      _isScrubbing = false;
      updateVideoControlsDisplayMode();
   };

   var onExternalLinkViewOpen = function() {
      _externalLinkViewOpen = true;
      updateVideoControlsDisplayMode();
   };

   var onExternalLinkViewClosed = function() {
      _externalLinkViewOpen = false;
      updateVideoControlsDisplayMode();
   };

   var onMediaError = function() {
      _mediaError = true;
      updateVideoControlsDisplayMode();
   };

   var bindEvents = function() {
      $rootSmartPlayerElement.on(events.Controls.StartedPlayback, updateVideoControlsDisplayMode);
      $rootSmartPlayerElement.on(events.Controls.HeroPlayControlVisible, updateVideoControlsDisplayMode);
      $rootSmartPlayerElement.on(events.Controls.HeroPlayControlHidden, updateVideoControlsDisplayMode);
      $rootSmartPlayerElement.on(events.Controls.ReplayControlVisible, updateVideoControlsDisplayMode);
      $rootSmartPlayerElement.on(events.Controls.ReplayControlHidden, updateVideoControlsDisplayMode);
      $rootSmartPlayerElement.on(events.Controls.DisplayCaptionControl, onDisplayCaptionControl);
      $rootSmartPlayerElement.on(events.Controls.PipChanged, onPipChanged);
      $rootSmartPlayerElement.on(events.Quizzing.UIVisible, onQuizUIVisible);
      $rootSmartPlayerElement.on(events.Quizzing.UIHidden, onQuizUIHidden);
      $rootSmartPlayerElement.on(events.Quizzing.ShowQuizSetup, onShowQuizSetup);
      $rootSmartPlayerElement.on(events.Quizzing.QuizSetupComplete, onQuizSetupComplete);
      $rootSmartPlayerElement.on(events.Quizzing.MinimizeUI, onMinimizeQuizUI);
      $rootSmartPlayerElement.on(events.Quizzing.MaximizeUI, onMaximizeQuizUI);
      $rootSmartPlayerElement.on(events.Controls.Updated, handleControlsUpdated);
      $rootSmartPlayerElement.on(events.Controls.SearchFocusChange, onSearchFocusChange);
      $rootSmartPlayerElement.on(events.Scrubbing.Started, onScrubbingStarted);
      $rootSmartPlayerElement.on(events.Scrubbing.Ended, onScrubbingEnded);
      $rootSmartPlayerElement.on(events.ExternalLinkView.Open, onExternalLinkViewOpen);
      $rootSmartPlayerElement.on(events.ExternalLinkView.Closed, onExternalLinkViewClosed);
      $rootSmartPlayerElement.on(events.Media.Error, onMediaError);
   };

   var destroy = function() {
      $rootSmartPlayerElement.off(events.Controls.StartedPlayback, updateVideoControlsDisplayMode);
      $rootSmartPlayerElement.off(events.Controls.HeroPlayControlVisible, updateVideoControlsDisplayMode);
      $rootSmartPlayerElement.off(events.Controls.HeroPlayControlHidden, updateVideoControlsDisplayMode);
      $rootSmartPlayerElement.off(events.Controls.ReplayControlVisible, updateVideoControlsDisplayMode);
      $rootSmartPlayerElement.off(events.Controls.ReplayControlHidden, updateVideoControlsDisplayMode);
      $rootSmartPlayerElement.off(events.Controls.DisplayCaptionControl, onDisplayCaptionControl);
      $rootSmartPlayerElement.off(events.Controls.PipChanged, onPipChanged);
      $rootSmartPlayerElement.off(events.Quizzing.UIVisible, onQuizUIVisible);
      $rootSmartPlayerElement.off(events.Quizzing.UIHidden, onQuizUIHidden);
      $rootSmartPlayerElement.off(events.Quizzing.ShowQuizSetup, onShowQuizSetup);
      $rootSmartPlayerElement.off(events.Quizzing.QuizSetupComplete, onQuizSetupComplete);
      $rootSmartPlayerElement.off(events.Quizzing.MinimizeUI, onMinimizeQuizUI);
      $rootSmartPlayerElement.off(events.Quizzing.MaximizeUI, onMaximizeQuizUI);
      $rootSmartPlayerElement.off(events.Controls.Updated, handleControlsUpdated);
      $rootSmartPlayerElement.off(events.Controls.SearchFocusChange, onSearchFocusChange);
      $rootSmartPlayerElement.off(events.Scrubbing.Started, onScrubbingStarted);
      $rootSmartPlayerElement.off(events.Scrubbing.Ended, onScrubbingEnded);
      $rootSmartPlayerElement.off(events.ExternalLinkView.Open, onExternalLinkViewOpen);
      $rootSmartPlayerElement.off(events.ExternalLinkView.Closed, onExternalLinkViewClosed);
      $rootSmartPlayerElement.off(events.Media.Error, onMediaError);
   };

   bindEvents();
   updateVideoControlsDisplayMode();

   return Object.defineProperties({
      destroy: destroy
   }, {});
};

export default {
   /**
    *  Factory method that returns a new VideoControlsDisplayModeController object
    *  @function create
    *  @memberof TSC.VideoControlsDisplayModeController
    *  @param {jQuery} $rootSmartPlayerElement - jQuery wrapper of Top level dom element for Smart Player
    *  @param {TSC.VideoControlsController} videoControlsController - Video Controls Controller
    *  @param {TSC.playerConfiguration} playerConfiguration -  Player configuration
    *  @static
    *  @return new VideoControlsDisplayModeController instance
    */
   create: VideoControlsDisplayModeController
};
