import messageBarLevels from '../constants/message-bar-type';
import templateGenerator from '../utils/template-generator';

var animationTimeMilliseconds = 300;

/**
 *  @memberof TSC
 *  @class MessageBarView
 *  @classdesc View to display player messages
 *  @param {jQuery} $container - jQuery element to append message bar view to.
 *  @param {String} type - Type of message bar to display
 *  @param {String} messageText - Text to display in message bar.
 *  @param {String} [primaryActionButtonText] - Text to display in action button
 *  @param {Function} [customActionButtonHandler] - Optional function to call when action button is clicked.
 */
var MessageBarView = function($container, type, messageText, primaryActionButtonText, customActionButtonHandler) {
   var _$messageBarContainer;
   var _$actionButton;

   var onActionClick = function() {
      remove();
      if (customActionButtonHandler) {
         customActionButtonHandler();
      }
   };

   var showView = function() {
      _$messageBarContainer.stop()
         .css('opacity', 0)
         .show()
         .animate({opacity: 1}, animationTimeMilliseconds);
   };

   var remove = function() {
      if (_$actionButton.length > 0) {
         _$actionButton.off('click');
      }
      _$messageBarContainer.remove();
   };

   var init = function() {
      var markup = templateGenerator.generateMessageBarMarkup(messageText, primaryActionButtonText);
      $container.append(markup);

      _$messageBarContainer = $container.find('.message-bar-view-container');
      _$actionButton = _$messageBarContainer.find('.message-bar-action-button');

      if (type === messageBarLevels.warning) {
         _$messageBarContainer.addClass('warning');
      }
   };

   var bindEvents = function() {
      if (_$actionButton.length > 0) {
         _$actionButton.on('click', onActionClick);
      }
   };

   init();
   bindEvents();
   showView();

   return {
      remove: remove
   };
};

export default {
   /**
    *  Factory method that returns a new MessageBarView object.
    *  @function create
    *  @memberof TSC.MessageBarView
    *  @static
    *  @param {jQuery} $container - jQuery element to append message bar view to.
    *  @param {String} type - Type of message bar to display
    *  @param {String} messageText - Text to display in message bar.
    *  @param {String} [primaryActionButtonText] - Text to display in action button
    *  @param {Function} [customActionButtonHandler] - Optional function to call when action button is clicked.
    *  @return new MessageBarView instance
    */
   create: MessageBarView
};
