import playerConfiguration from '../models/player-configuration';
import xmpCaptionView from './xmp-caption-view';
import adaCaptionView from './ada-caption-view';

var getCaptionView = function() {
   if (playerConfiguration.showADACompliantCaptions) {
      return adaCaptionView;
   }
   return xmpCaptionView;
};

export default {getCaptionView: getCaptionView};
