import Question from './question';

/**
 * @memberof TSC
 * @class QuestionSet
 * @classdesc Question Set Model
 * @param {tscme.QuestionSet} tscmeQuestionSetModel - Question set model from Media-Extensions-js
 * @param {Number} orderId - Order id of the question set.  Should be a number above 0.
 */
var QuestionSet = function(tscmeQuestionSetModel, orderId) {
   var _questions = [];
   var _orderId = orderId;
   var _percentOfVideoSegmentWatched = 0;
   var _numberGradedQuestions = 0;
   var _numberUngradedQuestions = 0;

   var createQuestionModels = function() {
      tscmeQuestionSetModel.questions.forEach(function(tscmeQuestion) {
         _questions.push(Question.create(tscmeQuestion));
      });
   };

   var cacheQuestionDetails = function() {
      _numberGradedQuestions = 0;
      _numberUngradedQuestions = 0;
      _questions.forEach(function(question) {
         if (question.isGraded) {
            _numberGradedQuestions++;
         } else {
            _numberUngradedQuestions++;
         }
      });
   };

   var toJSON = function() {
      return {
         name: tscmeQuestionSetModel.name,
         mediaTimePosition: tscmeQuestionSetModel.startTime,
         order: _orderId,
         questions: _questions.map(function(question) {
            return question.toJSON();
         })
      };
   };

   var getSnapShot = function() {
      return {
         n: tscmeQuestionSetModel.name,
         vsw: _percentOfVideoSegmentWatched,
         q: _questions.map(function(question) {
            return question.getSnapShot();
         })
      };
   };

   var populateQuestionSet = function(questionSetSnapShot) {
      if (tscmeQuestionSetModel.name === questionSetSnapShot.n) {
         _percentOfVideoSegmentWatched = questionSetSnapShot.vsw;
         questionSetSnapShot.q.forEach(function(questionSnapShot) {
            for (var i = 0; i < _questions.length; i++) {
               if (_questions[i].id === questionSnapShot.id) {
                  _questions[i].populateQuestion(questionSnapShot);
               }
            }
         });
      }
   };

   var getQuizServiceData = function(quizId) {
      var questionSetData = {};
      questionSetData.quizId = quizId;
      questionSetData.order = _orderId;
      questionSetData.questions = _questions.filter(function(question) {
         return question.responseLocked;
      }).map(function(question) {
         return question.getQuizServiceData(_percentOfVideoSegmentWatched);
      });
      return JSON.stringify(questionSetData);
   };

   createQuestionModels();
   cacheQuestionDetails();

   return Object.defineProperties({
      toJSON: toJSON,
      getSnapShot: getSnapShot,
      populateQuestionSet: populateQuestionSet,
      getQuizServiceData: getQuizServiceData,
      cacheQuestionDetails: cacheQuestionDetails
   }, {
      name: {
         get: function() {
            return tscmeQuestionSetModel.name;
         }
      },
      displayFeedback: {
         get: function() {
            return tscmeQuestionSetModel.feedback;
         }
      },
      startTime: {
         get: function() {
            return tscmeQuestionSetModel.startTime;
         }
      },
      questions: {
         get: function() {
            return _questions;
         }
      },
      inProgress: {
         get: function() {
            return _questions.some(function(question) {
               return question.responseLocked;
            });
         }
      },
      completed: {
         get: function() {
            return _questions.every(function(question) {
               return question.responseLocked;
            });
         }
      },
      percentOfVideoSegmentWatched: {
         get: function() {
            return _percentOfVideoSegmentWatched;
         },
         set: function(percentOfVideoSegmentWatched) {
            _percentOfVideoSegmentWatched = percentOfVideoSegmentWatched;
         }
      },
      numberGradedQuestions: {
         get: function() {
            return _numberGradedQuestions;
         }
      },
      numberUngradedQuestions: {
         get: function() {
            return _numberUngradedQuestions;
         }
      },
      numberCorrectQuestions: {
         get: function() {
            var correctQuestions = _questions.filter(function(question) {
               return question.isGraded && question.isResponseCorrect;
            });

            return correctQuestions.length;
         }
      },
      usesRationale: {
         get: function() {
            return _questions.some(function(question) {
               return question.feedback.length > 0;
            });
         }
      }
   });
};

export default {
   /**
    *  Factory method that returns a new QuestionSet object.
    *  @function create
    *  @memberof TSC.QuestionSet
    *  @param {tscme.QuestionSet} tscmeQuestionSetModel - Question set model from Media-Extensions-js
    *  @param {Number} orderId - Order id of the question set.  Should be a number above 0.
    *  @static
    *  @return new QuestionSet instance
    */
   create: QuestionSet
};
