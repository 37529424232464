const choiceAnswerTemplate = ({questionText, answers, placeHolderImg, focusableAnswerInput}) => `<form onsubmit="return false;">
   <fieldset>
     <legend class="sr-only">${questionText}</legend>
     ${answers.map(({answer, answerIndex, isChecked}) => `<label class="multiple-choice active-question-item">
        <img src='${placeHolderImg}' class='image-feedback feedback-placeholder' alt='' />
        <input type="radio" ${answerIndex === 0 ? `class="${focusableAnswerInput}"` : ''} name="multiple-choice" value="${answerIndex}" ${isChecked ? 'checked' : ''} />
        <span class="multiple-choice-answer-text">${answer}</span>
     </label>`).join('')}
   </fieldset>
 </form>`;

export default choiceAnswerTemplate;
