import templateGenerator from '../utils/template-generator';

var DISPLAY_DELAY_MILLISECONDS = 500;

/**
 *  @memberof TSC
 *  @class VideoSeekingSpinnerView
 *  @classdesc IoOverlay View
 *  @param {jQuery} $container - Top level DOM element wrapped in jQuery.
 */
var VideoSeekingSpinnerView = function($container) {
   var _viewOpen = false;
   var _displayTimeoutId = -1;

   var markup = templateGenerator.generateVideoSeekingSpinnerViewTemplateMarkup();
   $container.append(markup);

   var _$seekingSpinnerContainer = $container.find('.video-seeking-spinner-container');

   var showViewAfterDelay = function() {
      _displayTimeoutId = -1;
      _viewOpen = true;
      _$seekingSpinnerContainer.show();
   };

   var showView = function() {
      if (_viewOpen || _displayTimeoutId !== -1) {
         return;
      }

      _displayTimeoutId = window.setTimeout(showViewAfterDelay, DISPLAY_DELAY_MILLISECONDS);
   };

   var hideView = function() {
      if (_displayTimeoutId !== -1) {
         window.clearTimeout(_displayTimeoutId);
         _displayTimeoutId = -1;
      }
      if (_viewOpen) {
         _viewOpen = false;
         _$seekingSpinnerContainer.hide();
      }
   };

   return Object.defineProperties({
      hideView: hideView,
      showView: showView
   }, {});
};

export default {
   /**
    *  Factory method that returns a new VideoSeekingSpinnerView object.
    *  @memberof TSC.VideoSeekingSpinnerView
    *  @function create
    *  @static
    *  @param {jQuery} $container - Top level DOM element wrapped in jQuery.
    *  @return new VideoSeekingSpinnerView instance
    */
   create: VideoSeekingSpinnerView
};
