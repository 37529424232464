import templateGenerator from '../utils/template-generator';
import windowWrapper from '../../common/window-wrapper';
import events from '../constants/events';

var ConfirmOpenUrlView = function($container, url, newWindow) {
   var _$view;
   var _$externalLinkReplayButton;
   var _$externalLinkVisitButton;

   var onConfirmHandler = function() {
      $container.trigger(events.ExternalLinkView.Clicked);
      var windowTarget = newWindow === true ? '_blank' : '_top';
      windowWrapper.open(url, windowTarget);
   };

   var onReplay = function() {
      $container.trigger(events.ExternalLinkView.Closed);
      $container.trigger(events.Modals.Closed);
      $container.trigger(events.Media.Replay);
      destroy();
   };

   var createView = function() {
      _$view = $container.find('.open-external-link');
      _$view.html(templateGenerator.generateOpenExternalLinkMarkup(url));
      _$externalLinkReplayButton = _$view.find('.open-external-link-replay');
      _$externalLinkVisitButton = _$view.find('.open-external-link-open');
      bindEvents();

      $container.trigger(events.Modals.Open);
      $container.trigger(events.ExternalLinkView.Open);

      _$view.show();
   };

   var bindEvents = function() {
      _$externalLinkReplayButton.on('click', onReplay);
      _$externalLinkVisitButton.on('click', onConfirmHandler);
   };

   var destroy = function() {
      _$externalLinkReplayButton.off('click', onReplay);
      _$externalLinkVisitButton.off('click', onConfirmHandler);
      _$view.empty();
      _$view.hide();
   };

   createView();
};

/**
 *  Factory method that returns a new ConfirmOpenUrlView object.
 *  @function create
 *  @memberof TSC.ConfirmOpenUrlView
 *  @static
 *  @param {Object} $container - jQuery element that contains a .open-external-link where the view will be injected
 *  @param {String} url - URL to open if confirmed
 *  @param {Boolean} newWindow - Open URL in new window
 *  @return new ConfirmOpenUrlView instance
 */
export default {
   create: ConfirmOpenUrlView
};
