import events from '../constants/events';
import videoAnalytics from '../utils/video-analytics';
import log from '../../common/log';
import analyticsService from '../services/analytics-service';
import performanceWrapper from '../utils/performance-wrapper';
import scormController from './scorm-controller';

var EVENT_PERCENT_WATCH_INC = 10;
var MAX_PERCENT = 100;

/**
 * @memberof TSC
 * @class MediaAnalyticsController
 * @classdesc Controller for managing media analytics
 * @param {jQuery} $container - Smart Player main container jquery element
 * @param {MediaView} mediaView - Media view for current media. Created by MediaViewFactory.
 * @param {TSC.MediaList} mediaList
 * @param {TSC.QuizController} quizController
 * @param {TSC.playerConfiguration} playerConfiguration
 */
var MediaAnalyticsController = function($container, mediaView, mediaList, quizController, playerConfiguration) {
   var _eventPlayTracking = true;
   var _eventLastPercentReported = 0;

   var doesSCORMModuleNeedToResumeLastTime = function() {
      return mediaView.supportsSCORM && playerConfiguration.isScormLesson && scormController.supportsResumeSession && scormController.initialVideoTimeInSeconds > 0;
   };

   var initVideoAnalytics = function() {
      videoAnalytics.initPercentWatched(mediaView.mediaElement.duration * 1000);

      if (doesSCORMModuleNeedToResumeLastTime()) {
         resumeSCORMSession(scormController.initialVideoTimeInSeconds, scormController.initialVideoAnalyticsSnapshot);
         $container.trigger(events.Media.SeekToTime, {time: scormController.initialVideoTimeInSeconds, playAfterSeek: true});
      }
   };

   var updateAnalytics = function(currentTimeInMilliseconds) {
      if (!mediaView.mediaElement.duration) {
         return;
      }

      if (!videoAnalytics.isEnabled()) {
         return;
      }

      if (!videoAnalytics.isPercentWatchedInitialized()) {
         initVideoAnalytics();
      }

      if (!isNaN(currentTimeInMilliseconds)) {
         videoAnalytics.markTimeWatched(currentTimeInMilliseconds);
      } else {
         log.logMessage('Player-view: currentTime is NaN (' + currentTimeInMilliseconds + ')');
      }

      var percentWatched = Math.round(videoAnalytics.getPercentageWatched() * 100);

      if (scormController.initialized) {
         scormController.submitVideoWatchedPercentage(0.01 * percentWatched, videoAnalytics.getCurrentPlayTime());
      }

      if (playerConfiguration.getTrackEvents()) {
         if (percentWatched >= _eventLastPercentReported + EVENT_PERCENT_WATCH_INC) {
            while (_eventLastPercentReported + EVENT_PERCENT_WATCH_INC <= percentWatched) {
               _eventLastPercentReported += EVENT_PERCENT_WATCH_INC;

               if (_eventLastPercentReported > MAX_PERCENT) {
                  _eventLastPercentReported = MAX_PERCENT;
               }

               analyticsService.sendEvent(events.Analytics.VideoPercentViewed, 'Viewed: ' + _eventLastPercentReported + ' Percent');
               analyticsService.sendGA4Event(events.GA4.VideoPercentViewed, {percent_viewed: _eventLastPercentReported}); // eslint-disable-line
            }
         }
      }
   };

   var handleTimeUpdate = function(currentTimeInMilliseconds) {
      if (videoAnalytics.isEnabled()) {
         updateAnalytics(currentTimeInMilliseconds);
      }
   };

   var onVideoTimeUpdate = function() {
      handleTimeUpdate(mediaView.currentTimeInMilliseconds);
   };

   var onSeeked = function() {
      handleTimeUpdate(mediaView.currentTimeInMilliseconds);
   };

   var onCanPlayVideo = function() {
      if (videoAnalytics.isEnabled()) {
         videoAnalytics.setVideoLoadEndTime(new Date().getTime());

         if (playerConfiguration.getTrackEvents()) {
            var eventAction = playerConfiguration.getAltEventCategoryAsFilename() ? analyticsService.getCategory() : mediaList.currentMedia.uri;
            if (playerConfiguration.getAltLoadTimeAsSeconds()) {
               analyticsService.sendEvent(events.Analytics.VideoLoadingTimeInSeconds, eventAction, videoAnalytics.getVideoLoadTime() / 1000);
               analyticsService.sendGA4Event(events.GA4.VideoLoadingTimeInSeconds, {loading_duration: videoAnalytics.getVideoLoadTime() / 1000}); // eslint-disable-line
            } else {
               analyticsService.sendEvent(events.Analytics.VideoLoadingTime, eventAction, videoAnalytics.getVideoLoadTime());
               analyticsService.sendGA4Event(events.GA4.VideoLoadingTime, {loading_duration: videoAnalytics.getVideoLoadTime()}); // eslint-disable-line
            }
         }
      }

      mediaView.mediaWrapper.off('canplay', onCanPlayVideo);
   };

   var resetPlayStartAnalytics = function() {
      if (videoAnalytics.isEnabled()) {
         videoAnalytics.setVideoLoadStartTime(new Date().getTime());
      }

      if (playerConfiguration.getTrackEvents() && _eventPlayTracking) {
         _eventPlayTracking = false;
         var videoStartedEventAction = playerConfiguration.getAltEventCategoryAsFilename() ? analyticsService.getCategory() : mediaList.currentMedia.uri;
         analyticsService.sendEvent(events.Analytics.VideoStarted, videoStartedEventAction);
         analyticsService.sendEvent(events.Analytics.VideoPercentViewed, 'Viewed: 0 Percent');
         analyticsService.sendGA4Event(events.GA4.VideoStarted, null);
         analyticsService.sendGA4Event(events.GA4.VideoPercentViewed, {percent_viewed: 0}); // eslint-disable-line
      }
   };

   var onMediaEnd = function(e, eventData) {
      if (eventData.isPlaybackEnded && playerConfiguration.getTrackEvents()) {
         analyticsService.sendEvent(events.Analytics.VideoFinishedAction, playerConfiguration.getEndActionParam());
         analyticsService.sendGA4Event(events.GA4.VideoFinishedAction, {finished_action: playerConfiguration.getEndActionParam()}); // eslint-disable-line
      }
   };

   var onVideoReplayHandler = function() {
      if (playerConfiguration.getTrackEvents()) {
         var eventAction = playerConfiguration.getAltEventCategoryAsFilename() ? analyticsService.getCategory() : mediaList.currentMedia.uri;
         analyticsService.sendEvent(events.Analytics.VideoReplay, eventAction);
         analyticsService.sendGA4Event(events.GA4.VideoReplay, null);
      }
   };

   var onMediaWrapperMediaEnd = function() {
      if (playerConfiguration.getTrackEvents()) {
         var eventAction = playerConfiguration.getAltEventCategoryAsFilename() ? analyticsService.getCategory() : mediaList.currentMedia.uri;
         analyticsService.sendEvent(events.Analytics.VideoFinished, eventAction);
         analyticsService.sendGA4Event(events.GA4.VideoFinished, null);
      }
   };

   var onOpenExternalLink = function() {
      var eventAction = playerConfiguration.getAltEventCategoryAsFilename() ? analyticsService.getCategory() : mediaList.currentMedia.uri;
      analyticsService.sendEvent(events.Analytics.OpenExternalLinkView, eventAction);
      analyticsService.sendGA4Event(events.GA4.OpenExternalLinkView, null);
   };

   var onClickExternalLink = function() {
      var eventAction = playerConfiguration.getAltEventCategoryAsFilename() ? analyticsService.getCategory() : mediaList.currentMedia.uri;
      analyticsService.sendEvent(events.Analytics.OpenExternalLinkClick, eventAction);
      analyticsService.sendGA4Event(events.GA4.OpenExternalLinkClick, null);
   };

   var resumeSCORMSession = function(initialVideoTimeInSeconds, initialVideoAnalyticsSnapshot) {
      videoAnalytics.populateVideoSegmentsWatch(initialVideoAnalyticsSnapshot);
      videoAnalytics.markTimeWatched(initialVideoTimeInSeconds * 1000);
   };

   var onStartedPlayback = function() {
      var quizRequiresAnalytics = quizController && quizController.hasQuiz && quizController.quizEnabled;
      var playerConfigurationRequiresAnalytics = playerConfiguration.getTrackEvents() || playerConfiguration.isScormLesson || playerConfiguration.getReportScormComplete() || quizRequiresAnalytics;
      var shouldTrackVideoAnalytics = !mediaList.isPlaylist && playerConfigurationRequiresAnalytics;
      if (shouldTrackVideoAnalytics) {
         videoAnalytics.setEnabled(true);
      }
   };

   var onPlayerInitialized = function() {
      performanceWrapper.createMeasure(events.Timing.PlayerLoaded, events.Timing.PlayerLoaded);
      performanceWrapper.createMeasure(events.Timing.PlayerInitialized, events.Timing.PlayerInitialized);
      analyticsService.sendEvent(events.Analytics.ContentLoaded, mediaList.currentMedia.uri);
      analyticsService.sendGA4Event(events.GA4.PlayerInitialized, null);
   };

   var bindToEvents = function() {
      $container.on(events.Controls.StartedPlayback, onStartedPlayback);
      $container.on(events.Controls.PlayerInitialized, onPlayerInitialized);
      $container.on(events.Media.End, onMediaEnd);
      $container.on(events.Media.Replay, onVideoReplayHandler);
      $container.on(events.ExternalLinkView.Open, onOpenExternalLink);
      $container.on(events.ExternalLinkView.Clicked, onClickExternalLink);

      mediaView.mediaWrapper.on('ratechange', onVideoTimeUpdate, false);
      mediaView.mediaWrapper.on('play', onVideoTimeUpdate, false);

      var eventsToBind = {
         timeupdate: onVideoTimeUpdate,
         canplay: onCanPlayVideo,
         seeked: onSeeked,
         ended: onMediaWrapperMediaEnd
      };
      mediaView.mediaWrapper.on(eventsToBind);

      mediaView.mediaElement.addEventListener(events.Media.Play, resetPlayStartAnalytics);
   };

   var unbindToEvents = function() {
      $container.off(events.Controls.StartedPlayback, onStartedPlayback);
      $container.off(events.Controls.PlayerInitialized, onPlayerInitialized);
      $container.off(events.Media.End, onMediaEnd);
      $container.on(events.Media.Replay, onVideoReplayHandler);
      $container.on(events.ExternalLinkView.Open, onOpenExternalLink);
      $container.on(events.ExternalLinkView.Clicked, onClickExternalLink);

      mediaView.mediaWrapper.off('ratechange', onVideoTimeUpdate, false);
      mediaView.mediaWrapper.off('play', onVideoTimeUpdate, false);

      var eventsToBind = {
         timeupdate: onVideoTimeUpdate,
         canplay: onCanPlayVideo,
         seeked: onSeeked,
         ended: onMediaWrapperMediaEnd
      };
      mediaView.mediaWrapper.off(eventsToBind);

      mediaView.mediaElement.removeEventListener(events.Media.Play, resetPlayStartAnalytics);
   };

   var destroy = function() {
      unbindToEvents();
   };

   bindToEvents();

   if (mediaView.mediaWrapper.metadataReady) {
      mediaView.mediaWrapper.metadataReady.then(function() {
         return mediaView.mediaWrapper.canPlayReady.then(function() {
            onCanPlayVideo();
         });
      });
   }

   return Object.defineProperties({
      destroy: destroy
   }, {
   });
};

export default {
   /**
    *  Factory method that returns a new MediaAnalyticsController.
    *  @function create
    *  @memberof TSC.MediaAnalyticsController
    *  @static
    *  @param {jQuery} $container - Smart Player main container jquery element
    *  @param {MediaView} mediaView - Media view for current media. Created by MediaViewFactory.
    *  @param {TSC.MediaList} mediaList
    *  @param {TSC.QuizController} quizController
    *  @param {TSC.playerConfiguration} playerConfiguration
    *  @return new MediaAnalyticsController instance
    */
   create: MediaAnalyticsController
};
