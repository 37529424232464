import DisplayModes from '../constants/display-modes';

/**
 * @memberof TSC
 * @class PlayerState
 * @classdesc State object used for player instance player state.
 */
var PlayerState = function() {
   var _oneOrMoreHotspotsWithTimeActionArePaused = false;
   var _videoHasQuizQuestions = false;
   var _pausedForQuiz = false;
   var _displayMode = DisplayModes.Normal;

   return Object.defineProperties({}, {
      oneOrMoreHotspotsWithTimeActionArePaused: {
         get: function() {
            return _oneOrMoreHotspotsWithTimeActionArePaused;
         },
         set: function(oneOrMoreHotspotsWithTimeActionArePaused) {
            _oneOrMoreHotspotsWithTimeActionArePaused = oneOrMoreHotspotsWithTimeActionArePaused;
         }
      },
      mediaHasQuizQuestions: {
         get: function() {
            return _videoHasQuizQuestions;
         },
         set: function(videoHasQuizQuestions) {
            _videoHasQuizQuestions = videoHasQuizQuestions;
         }
      },
      pausedForQuiz: {
         get: function() {
            return _pausedForQuiz;
         },
         set: function(pausedForQuiz) {
            _pausedForQuiz = pausedForQuiz;
         }
      },
      displayMode: {
         get: function() {
            return _displayMode;
         },
         set: function(displayMode) {
            _displayMode = displayMode;
         }
      }
   });
};

export default {
   /**
    *  Factory method that returns a new PlayerState object
    *  @function create
    *  @memberof TSC.PlayerState
    *  @static
    *  @return new PlayerState instance
    */
   create: PlayerState
};
