import mouseButtons from '../constants/mouse-buttons';
import events from '../constants/events';

var DEFAULT_DOUBLE_CLICK_DELAY = 300;
var SINGLE_CLICK_COUNT = 1;
var DOUBLE_CLICK_COUNT = 2;

/**
 * @memberof TSC
 * @class ClickControlsView
 * @classdesc Control that allows uer to click on video area to toggle play / pause and full screen mode.
 * @param {jQuery} $container - root Smart Player dom element
 * @param {HTMLVideoElement} mediaElement
 * @param {TSC.FullscreenControlView} fullscreenControlView
 * @param {TSC.ProgressBarControlView} progressBarControlView
 * @param {TSC.playerConfiguration} playerConfiguration
 * @param {Number} [doubleClickDelay] - time delay to track double clicks
 */
var ClickControlsView = function($container, mediaElement, fullscreenControlView, progressBarControlView, playerConfiguration, doubleClickDelay) {
   var _$clickControls = $container.find('.click-controls');
   var _$playPauseStateContainer = $container.find('.play-pause-state');
   var _$playPauseStateIndicator = $container.find('.play-pause-state-indicator');
   var _clicks = 0;
   var _doubleClickTimer = null;
   var _enabled = true;
   var _draggingCaption = false;
   var _mediaPluginInteractionActive = false;
   var _doubleClickDelay = doubleClickDelay || DEFAULT_DOUBLE_CLICK_DELAY;

   var onClickPlay = function() {
      if (mediaElement.ended) {
         $container.trigger(events.Media.Replay);
      } else {
         mediaElement.play();
      }
      _$playPauseStateIndicator.removeClass('pause-button-background-glyph').addClass('play-button-background-glyph');
      _$playPauseStateContainer.show().fadeOut('slow');
   };

   var onClickPause = function() {
      if (mediaElement.currentTime !== mediaElement.duration) {
         mediaElement.pause();
         _$playPauseStateIndicator.removeClass('play-button-background-glyph').addClass('pause-button-background-glyph');
         _$playPauseStateContainer.show().fadeOut('slow');
      }
   };

   var processHotspotActiveChange = function(e, eventData) {
      if (e.type === events.Hotspots.Paused && eventData && eventData.hasTimeChangeAction) {
         disable();
      } else if (e.type === events.Hotspots.Resume) {
         enable();
      }
   };

   var onControlClick = function(e) {
      if (_mediaPluginInteractionActive || !_enabled || _draggingCaption || e.button !== mouseButtons.mainButton) {
         _mediaPluginInteractionActive = false;
         return;
      }

      _clicks++;
      clearTimeout(_doubleClickTimer);

      if (_clicks === SINGLE_CLICK_COUNT) {
         var mediaPaused = mediaElement.paused;

         _doubleClickTimer = setTimeout(function() {
            _clicks = 0;

            if (!playerConfiguration.getEnableSingleClickControl()) {
               return;
            }

            if (mediaElement.paused === mediaPaused && !progressBarControlView.scrubbing) {
               mediaElement.paused ? onClickPlay() : onClickPause();
            }
         }, _doubleClickDelay);
      } else if (_clicks === DOUBLE_CLICK_COUNT) {
         _clicks = 0;

         if (!playerConfiguration.getEnableDoubleClickControl()) {
            return;
         }

         fullscreenControlView.onDoubleClick();
      }
   };

   var onCaptionDragStart = function() {
      _draggingCaption = true;
   };

   var onCaptionDragEnd = function() {
      _draggingCaption = false;
   };

   var processMediaAreaPluginInteractiveHandler = function() {
      _mediaPluginInteractionActive = true;
   };

   var bindEvents = function() {
      $container.on(events.Captions.DragStart, onCaptionDragStart);
      $container.on(events.Captions.DragEnd, onCaptionDragEnd);
      $container.on(events.Hotspots.Paused, processHotspotActiveChange);
      $container.on(events.Hotspots.Resume, processHotspotActiveChange);
      $container.on(events.Plugin.MediaAreaPluginMouseDown, processMediaAreaPluginInteractiveHandler);
      $container.on(events.Plugin.MediaAreaPluginKeyDown, processMediaAreaPluginInteractiveHandler);
      _$clickControls.on('click', onControlClick);
   };

   var enable = function() {
      _enabled = true;
   };

   var disable = function() {
      _enabled = false;
   };

   var destroy = function() {
      $container.off(events.Captions.DragStart, onCaptionDragStart);
      $container.off(events.Captions.DragEnd, onCaptionDragEnd);
      $container.off(events.Hotspots.Paused, processHotspotActiveChange);
      $container.off(events.Hotspots.Resume, processHotspotActiveChange);
      $container.off(events.Plugin.MediaAreaPluginMouseDown, processMediaAreaPluginInteractiveHandler);
      $container.off(events.Plugin.MediaAreaPluginKeyDown, processMediaAreaPluginInteractiveHandler);
      _$clickControls.off('click', onControlClick);
   };

   bindEvents();

   return Object.defineProperties({
      enable: enable,
      disable: disable,
      destroy: destroy
   }, {});
};

export default {
   /**
    *  Factory method that returns a new ClickControlsView object
    *  @function create
    *  @memberof TSC.ClickControlsView
    *  @param {jQuery} $container - root Smart Player dom element
    *  @param {HTMLVideoElement} mediaElement
    *  @param {TSC.FullscreenControlView} fullscreenControlView
    *  @param {TSC.ProgressBarControlView} progressBarControlView
    *  @param {TSC.playerConfiguration} playerConfiguration
    *  @param {Number} [doubleClickDelay] - time delay to track double clicks
    *  @static
    *  @return new ClickControlsView instance
    */
   create: ClickControlsView
};
