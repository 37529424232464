const tocItemTemplate = ({showText, centerContent, dataTocTime, dataFileText, tocItemRawText, tocItemIndex, showThumbnail, tocItemText}) => `<li class="toc-list-item" role="listitem">
   <button class="toc-item ${showText ? 'limit-max-toc-item-height' : ''} ${centerContent ? 'center-flex-content' : ''}" ${dataTocTime} ${dataFileText} aria-label="${tocItemRawText}" tabindex="${tocItemIndex}">
      ${showThumbnail ? '<canvas class="thumbnail-placeholder"></canvas>' : ''}
      ${showText ? `<span class="toc-item-text">
            ${tocItemText}
         </span>` : ''}
   </button>
</li>`;

export default tocItemTemplate;

