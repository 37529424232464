const scormConfirmCompleteModalTemplate = ({screenReaderAlertText, confirmText, dismissButtonScreenReaderText, dismissButtonText, isDestructiveButton, confirmButtonScreenReaderText, confirmButtonText}) => `<div class="scorm-confirm-complete-modal-view">
   <div class="modal-overlay"></div>
   <span class="sr-only" role="status" aria-live="polite">${screenReaderAlertText}</span>
   <div class="scorm-confirm-complete-modal-container">
      <div class="modal-body">${confirmText}</div>
      <div class="modal-button-group">
         <button class="dismiss-complete-button button button-secondary" aria-label="${dismissButtonScreenReaderText}" type="button"><span>${dismissButtonText}</span></button>
         <button class="confirm-complete-button button ${isDestructiveButton ? 'button-destructive' : 'button-primary'}" aria-label="${confirmButtonScreenReaderText}" type="button"><span>${confirmButtonText}</span></button>
      </div>
   </div>
</div>`;

export default scormConfirmCompleteModalTemplate;
