var mediaType = Object.freeze({
   WEBM: 'webm',
   MP4: 'mp4',
   OGG: 'ogg',
   YOUTUBE: 'youtube',
   JPG: 'jpg',
   PNG: 'png',
   GIF: 'gif',
   PLAYLIST: 'playlist',
   UNKNOWN: 'unknown',
   NOT_AVAILABLE: 'not available',
   MULTIPLE_TYPES: 'multiple types'
});

export default mediaType;
