/**
 * @memberof TSC
 * @class fathomGrader
 * @classdesc Grader for fathom quiz service
 */

var processQuestionResults = function(question, results) {
   var questionApiId = question.id;
   var questionResult = results[questionApiId];
   if (questionResult !== undefined) {
      question.isResponseCorrect = questionResult.isCorrect;
      question.correctAnswer = questionResult.correctAnswer;
      question.isGraded = questionResult.isGraded;
   }
};

var processQuestionSetResults = function(questionSet, results) {
   questionSet.questions.forEach(function(question) {
      processQuestionResults(question, results);
   });
   questionSet.cacheQuestionDetails();
};

export default Object.defineProperties({
   processQuestionSetResults: processQuestionSetResults
}, {
   doesLocalGrading: {
      value: false
   }
});
