import questionType from '../constants/question-type';

/**
 * @memberof TSC
 * @class xmpGrader
 * @classdesc Xmp Grader question sets
 */
var gradeChoiceQuestion = function(question, response) {
   var correctAnswer = question.correctAnswer;
   var isCorrect;
   var responseNumber = parseInt(response, 10);
   if (isNaN(responseNumber)) {
      isCorrect = false;
   } else {
      /*eslint no-bitwise: "off"*/
      isCorrect = 1 << responseNumber === correctAnswer;
   }
   question.isResponseCorrect = isCorrect;
   question.isGraded = correctAnswer !== undefined && correctAnswer > 0;
};

var gradeFillInTheBlankQuestion = function(question, response) {
   var responseLowerCase = response !== undefined ? response.toLowerCase() : '';
   var possibleAnswers = question.answers;
   var numberPossibleAnswers = possibleAnswers ? possibleAnswers.length : 0;

   question.isGraded = numberPossibleAnswers > 0;

   if (question.isGraded) {
      question.isResponseCorrect = false;
      for (var i = 0; i < numberPossibleAnswers; i++) {
         if (responseLowerCase === possibleAnswers[i].text.toLowerCase()) {
            question.isResponseCorrect = true;
            break;
         }
      }
   }
};

var gradeQuestion = function(question) {
   if (question.type === questionType.CHOICE) {
      gradeChoiceQuestion(question, question.userResponse);
   } else if (question.type === questionType.FILL_IN) {
      gradeFillInTheBlankQuestion(question, question.userResponse);
   }
};

var gradeQuestionSet = function(questionSet) {
   questionSet.questions.forEach(function(question) {
      if (question.responseLocked) {
         gradeQuestion(question);
      }
   });
};

export default Object.defineProperties({
   gradeQuestionSet: gradeQuestionSet
}, {
   doesLocalGrading: {
      value: true
   }
});
