import $ from 'jquery';
import Promise from 'promise-polyfill';
import playerStringNames from '../constants/player-string-names';
import localizationStrings from '../models/localization-strings';
import QuestionSetView from '../views/question-set-view';
import questionSetConfirmationView from '../views/question-set-confirmation-view';
import QuizSetupView from '../views/quiz-setup-view';
import reviewAnswersConfirmationView from '../views/review-answers-confirmation-view';
import quizService from '../services/quiz-service';
import playerConfiguration from '../models/player-configuration';
import QuizProgressTracker from '../utils/quiz-progress-tracker';
import questionGrader from '../constants/question-grader';
import fathomGrader from '../services/fathom-grader';
import xmpGrader from '../services/xmp-grader';
import questionSetReviewStatusView from '../views/question-set-review-status';
import events from '../constants/events';
import urlService from '../services/url-service';
import hotkeyService from '../services/hotkey-service';
import scormController from './scorm-controller';
import statusCodes from '../constants/status-codes';
import messageBarType from '../constants/message-bar-type';
import MessageBarView from '../views/message-bar-view';
import {quizServiceErrorIds} from '../constants/api-error-ids';
import log from '../../common/log';
import mediaViewType from '../constants/media-view-type';
import preloadOptions from '../constants/preload-options';

var CONTROL_BAR_HEIGHT = 48;
var BOTTOM_PADDING = 14;

/**
 * @memberof TSC
 * @class QuizController
 * @classdesc Quiz Controller manages questions state and views.
 * @param {jQuery} $container - Smart Player main container jquery element
 * @param {MediaView} mediaView - Media view for current media. Created by MediaViewFactory.
 * @param {TSC.Quiz} quizModel
 * @param {TSC.BeforeAfterPlayControlsView} beforeAfterPlayControlsView
 * @param {boolean} enforceLinearAssessment
 *
 */
var QuizController = function($container, mediaView, quizModel, beforeAfterPlayControlsView, enforceLinearAssessment) {
   var hasQuiz = !!quizModel;
   if (!hasQuiz) {
      throw new Error('Attempted to create quiz controller with no quiz model');
   }

   var _quizSetupView = null;
   var _currentView = null;
   var _quizModel = null;
   var _retrySubmissionMessageDisplayBar = null;
   var _quizOutOfDateMessageView = null;
   var _quizBadDefinitionMessageView = null;
   var _progressTracker = null;

   var _numberQuestionSets = 0;
   var _currentQuestionSetIndex = 0;
   var _bottomOffset = 0;
   var _pointAt = null;
   var _quizEnabled = true;
   var _quizReady = false;
   var _mediaIsImage = mediaView.type === mediaViewType.image;
   var _lastStatusMessage = '';
   var _questionGrader;
   var _quizUserDetails;
   var _shouldReportViewedOnPageLoad = mediaView.type === mediaViewType.image;
   var _quizSetupPromise;
   var _resolveQuizSetup;

   var getMediaElement = function() {
      return mediaView.mediaElement;
   };

   var submitScoreToScormController = function() {
      if (_quizModel.useScorm) {
         // Built in Scorm support
         if (scormController.initialized) {
            scormController.submitScore(_quizModel.getScormScore(), _quizModel.isLastQuestionComplete());
            // Legacy Scorm support
         } else if (typeof window.userSubmitToLMS !== 'undefined') {
            window.userSubmitToLMS(_quizModel.getScormScore());
         }
      }
   };

   var onNetworkRetryEvent = function() {
      $container.trigger(events.Quizzing.ShowSubmissionRetryMessage);
   };

   var completeQuestionSetEventDispatch = function() {
      $container.trigger(events.Quizzing.Complete);
      hotkeyService.enableHotkeys();
   };

   var questionSetCompleteHandler = function() {
      _currentQuestionSetIndex = -1;
      _currentView.removeView(true);
      _currentView = null;
      completeQuestionSetEventDispatch();
   };

   var dispatchStatusEvent = function(ioMessage) {
      _lastStatusMessage = ioMessage;
      $container.trigger(events.Quizzing.Status, {message: ioMessage});
   };

   var onContinueHandler = function() {
      $container.off(events.Quizzing.CloseQuestionSetView, onContinueHandler);
      questionSetCompleteHandler();
   };

   var createQuestionSetFeedbackView = function(needOverlay) {
      var questionSetView = QuestionSetView.create($container, _quizModel.questionSets[_currentQuestionSetIndex], needOverlay, _mediaIsImage, true, mediaView.currentTime);
      $container.on(events.Quizzing.CloseQuestionSetView, onContinueHandler);

      _currentView = questionSetView;
      _currentView.showView();
   };

   var continueVideoHandler = function() {
      $(reviewAnswersConfirmationView.getContinueControlRef()).off('click', continueVideoHandler);
      $(reviewAnswersConfirmationView.getReviewControlRef()).off('click', initQuestionSetFeedback);

      reviewAnswersConfirmationView.removeView(true);
      _currentView = null;
      completeQuestionSetEventDispatch();
   };

   var initQuestionSetFeedback = function() {
      $(reviewAnswersConfirmationView.getContinueControlRef()).off('click', continueVideoHandler);
      $(reviewAnswersConfirmationView.getReviewControlRef()).off('click', initQuestionSetFeedback);

      reviewAnswersConfirmationView.removeView(false);
      _currentView = null;
      createQuestionSetFeedbackView();
   };

   var createReviewAnswersConfirmationView = function() {
      var numberCorrectText = null;
      var percentCorrectText = null;
      var numberIncorrectText = null;
      var percentIncorrectText = null;
      var numberCorrect = 0;
      var numberIncorrect = 0;
      var numberUngradable = 0;
      var possibleCorrect = _quizModel.questionSets[_currentQuestionSetIndex].numberGradedQuestions;

      if (possibleCorrect > 0) {
         numberCorrect = _quizModel.questionSets[_currentQuestionSetIndex].numberCorrectQuestions;
         numberIncorrect = possibleCorrect - numberCorrect;
         numberUngradable = _quizModel.questionSets[_currentQuestionSetIndex].numberUngradedQuestions;

         numberCorrectText = localizationStrings.getPlayerString(playerStringNames.txtCorrect).replace('{{numberCorrect}}', numberCorrect);
         percentCorrectText = Math.round(100 * (numberCorrect / possibleCorrect)) + '%';
         numberIncorrectText = localizationStrings.getPlayerString(playerStringNames.txtIncorrect).replace('{{numberIncorrect}}', numberIncorrect);
         percentIncorrectText = Math.round(100 * (numberIncorrect / possibleCorrect)) + '%';
      }

      if (numberUngradable === 1) {
         questionSetReviewStatusView.setUngradeableDetails(numberUngradable + localizationStrings.getPlayerString(playerStringNames.accessBtnWasNotGraded));
      } else {
         questionSetReviewStatusView.setUngradeableDetails(numberUngradable + localizationStrings.getPlayerString(playerStringNames.accessBtnWereNotGraded));
      }

      var markup = reviewAnswersConfirmationView.getViewMarkup(
         numberCorrectText,
         percentCorrectText,
         numberIncorrectText,
         percentIncorrectText,
         localizationStrings.getPlayerString(playerStringNames.txtContinue),
         localizationStrings.getPlayerString(playerStringNames.txtReviewAnswer));

      // add view template
      $container.append(markup);

      _currentView = reviewAnswersConfirmationView;

      $(_currentView.getContinueControlRef()).on('click', continueVideoHandler);
      $(_currentView.getReviewControlRef()).on('click', initQuestionSetFeedback);

      // fade in view
      _currentView.showView();
      updateViewBottom();
      if (_pointAt !== null) {
         _currentView.pointAt(_pointAt);
      }
   };

   var createQuestionSetReviewStatusView = function() {
      var numberCorrect = _quizModel.questionSets[_currentQuestionSetIndex].numberCorrectQuestions;
      var possibleCorrect = _quizModel.questionSets[_currentQuestionSetIndex].numberGradedQuestions;
      var numberUngradable = _quizModel.questionSets[_currentQuestionSetIndex].numberUngradedQuestions;

      questionSetReviewStatusView.setGradeableDetails(numberCorrect + localizationStrings.getPlayerString(playerStringNames.accessBtnCorrectOutOf) + possibleCorrect + localizationStrings.getPlayerString(playerStringNames.accessBtnGradedQuestions));
      if (numberUngradable === 1) {
         questionSetReviewStatusView.setUngradeableDetails(numberUngradable + localizationStrings.getPlayerString(playerStringNames.accessBtnWasNotGraded));
      } else {
         questionSetReviewStatusView.setUngradeableDetails(numberUngradable + localizationStrings.getPlayerString(playerStringNames.accessBtnWereNotGraded));
      }

      var markup = questionSetReviewStatusView.getViewMarkup();

      $container.append(markup);

      _currentView = questionSetReviewStatusView;

      $(questionSetReviewStatusView.getHideAnswersControlRef()).on('click', hideQuizStatus);
      $(questionSetReviewStatusView.getShowAnswersControlRef()).on('click', initFeedbackFromReviewStatusView);

      questionSetReviewStatusView.showView();
      _currentView.updateViewPosition();
   };

   var initFeedbackFromReviewStatusView = function() {
      $(questionSetReviewStatusView.getHideAnswersControlRef()).off('click', hideQuizStatus);
      $(questionSetReviewStatusView.getShowAnswersControlRef()).off('click', initFeedbackFromReviewStatusView);

      questionSetReviewStatusView.removeView(false);
      _currentView = null;
      createQuestionSetFeedbackView();
   };

   var hideQuizStatus = function() {
      $container.trigger(events.Quizzing.HideQuiz);
      _currentView.hideView();
   };

   var onRationaleActionHandler = function(e, data) {
      if (e.type === events.Quizzing.RationaleActionLocation) {
         urlService.openUrl(data.location, '_blank');
      } else if (e.type === events.Quizzing.RationaleActionJumpToTime) {
         $container.off(events.Quizzing.CloseQuestionSetView, onCloseQuestionSetView);
         $container.off(events.Quizzing.SubmitQuestionSet, onSubmit);
         $container.off(events.Quizzing.RationaleActionLocation, onRationaleActionHandler);
         $container.off(events.Quizzing.RationaleActionJumpToTime, onRationaleActionHandler);

         questionSetCompleteHandler();
      }
   };

   var onCloseQuestionSetView = function() {
      $container.off(events.Quizzing.CloseQuestionSetView, onCloseQuestionSetView);
      $container.off(events.Quizzing.SubmitQuestionSet, onSubmit);
      $container.off(events.Quizzing.RationaleActionLocation, onRationaleActionHandler);
      $container.off(events.Quizzing.RationaleActionJumpToTime, onRationaleActionHandler);

      $container.trigger(events.Quizzing.Submitted);

      if (_quizModel.questionSets[_currentQuestionSetIndex].displayFeedback) {
         if (_mediaIsImage) {
            $container.trigger(events.Quizzing.TransitionImageQuizToAnswers);
            _currentView.removeView(true);
            createQuestionSetReviewStatusView();
         } else {
            _currentView.removeView(false);
            createReviewAnswersConfirmationView();
         }
      } else {
         questionSetCompleteHandler();
      }
   };

   var submitResults = function() {
      dispatchStatusEvent(localizationStrings.getPlayerString(playerStringNames.txtSubmittingAnswers));
      quizService.addEventListener(events.Network.Retrying, onNetworkRetryEvent);

      if (_quizModel.useQuizApi) {
         var data = _quizModel.getApiDataForQuestionSet(_currentQuestionSetIndex);
         return quizService.submitQuestionSet(data).then(function(results) {
            quizService.removeEventListener(events.Network.Retrying, onNetworkRetryEvent);
            $container.trigger(events.Quizzing.HideSubmissionRetryMessage);

            if (!_questionGrader.doesLocalGrading && results === undefined) {
               return Promise.reject('Failed to get results for question set submission');
            }

            return results;
         });
      }
      return Promise.resolve();
   };

   var gradeResults = function(results) {
      if (!_questionGrader.doesLocalGrading && results) {
         _questionGrader.processQuestionSetResults(_quizModel.questionSets[_currentQuestionSetIndex], results);
      } else {
         _questionGrader.gradeQuestionSet(_quizModel.questionSets[_currentQuestionSetIndex]);
      }
      return Promise.resolve();
   };

   var onGradingComplete = function() {
      if (_quizModel.useScorm) {
         submitScoreToScormController();
      }

      if (_quizModel.questionSets[_currentQuestionSetIndex].usesRationale) {
         $container.trigger(events.Quizzing.HideLoadingMessage);
         _currentView.showFeedbackForCurrentQuestion();
      } else {
         onCloseQuestionSetView();
      }
   };

   var onSubmit = function() {
      submitResults().then(gradeResults).then(onGradingComplete).catch(apiErrorEventHandler);
   };

   var createQuestionSetView = function(needOverlay) {
      var questionSetView = QuestionSetView.create($container, _quizModel.questionSets[_currentQuestionSetIndex], needOverlay, _mediaIsImage, false, mediaView.currentTime);

      if (_quizModel.questionSets[_currentQuestionSetIndex].usesRationale) {
         $container.on(events.Quizzing.SubmitQuestionSet, onSubmit);
         $container.on(events.Quizzing.CloseQuestionSetView, onCloseQuestionSetView);
         $container.on(events.Quizzing.RationaleActionLocation, onRationaleActionHandler);
         $container.on(events.Quizzing.RationaleActionJumpToTime, onRationaleActionHandler);
      } else {
         $container.on(events.Quizzing.SubmitQuestionSet, onSubmit);
      }

      _currentView = questionSetView;
      _currentView.showView();
      $container.trigger(events.Quizzing.ShowQuestionSetView);

      return questionSetView;
   };

   var reviewHandler = function() {
      _currentView.removeView(true);
      _currentView = null;

      $container.trigger(events.Quizzing.Review);
      hotkeyService.enableHotkeys();
   };

   var onViewRemove = function() {
      if (_currentQuestionSetIndex < _numberQuestionSets) {
         createQuestionSetView(false);
      }
   };

   var confirmHandler = function() {
      _currentView.removeView();
      _currentView = null;
      onViewRemove();
   };

   var createConfirmView = function() {
      var reviewText;

      if (enforceLinearAssessment) {
         reviewText = localizationStrings.getPlayerString(playerStringNames.txtReview);
      } else {
         reviewText = localizationStrings.getPlayerString(playerStringNames.txtContinue);
      }

      var markup = questionSetConfirmationView.getViewMarkup(reviewText, localizationStrings.getPlayerString(playerStringNames.txtAnswerQuestion));
      $container.append(markup);

      _currentView = questionSetConfirmationView;

      updateViewBottom();

      if (_quizModel.hideReplay === true) {
         $(questionSetConfirmationView.getReviewControlRef()).hide();
      }

      $(questionSetConfirmationView.getReviewControlRef()).on('click', reviewHandler);
      $(questionSetConfirmationView.getConfirmControlRef()).on('click', confirmHandler);

      questionSetConfirmationView.showView();
      questionSetConfirmationView.updateViewPosition();

      $container.trigger(events.Quizzing.ShowStartQuizPrompt);
   };

   var destroyQuizSetupView = function() {
      if (_quizSetupView) {
         $container.off(events.Quizzing.BeginClicked, beginQuizHandler);
         $container.off(events.Quizzing.SkipClicked, skipQuizHandler);
         _quizSetupView.destroy();
         if (_quizSetupView.$view) {
            _quizSetupView.$view.remove();
         }
         _quizSetupView = null;
      }
   };

   var beginQuizHandler = function(e, eventData) {
      e.preventDefault();

      destroyQuizSetupView();

      hotkeyService.enableHotkeys();

      if (eventData && eventData.firstName) {
         _quizUserDetails = {
            firstName: eventData.firstName,
            lastName: eventData.lastName,
            email: eventData.email
         };
      }

      _resolveQuizSetup();
      if (_shouldReportViewedOnPageLoad) {
         init();
      } else {
         $container.trigger(events.Quizzing.Begin);
      }
   };

   var skipQuizHandler = function(e) {
      e.preventDefault();
      _quizEnabled = false;

      destroyQuizSetupView();

      _resolveQuizSetup();

      if (!_shouldReportViewedOnPageLoad) {
         $container.trigger(events.Quizzing.Skip);
      }
   };

   var createSetupView = function() {
      beforeAfterPlayControlsView && beforeAfterPlayControlsView.hideVideoClickToPlayLink();

      // disable the TOC hotkey, it interferes
      hotkeyService.disableHotkeys();

      _quizSetupView = QuizSetupView.create($container);

      $container.on(events.Quizzing.BeginClicked, beginQuizHandler);

      if (_quizModel.allowSkipQuiz && !playerConfiguration.getDisableContinueWithoutQuestions()) {
         $container.on(events.Quizzing.SkipClicked, skipQuizHandler);
      } else {
         _quizSetupView.hideSkipButton();
      }

      $container.trigger(events.Quizzing.ShowQuizSetup);
   };

   var updateViewBottom = function() {
      if (_currentView && _currentView.setBottomOffset) {
         _currentView.setBottomOffset(_bottomOffset);
         _currentView.updateViewPosition();
      }
   };

   var startQuizApiInstance = function(firstName, lastName, email) {
      if (_quizModel) {
         $container.trigger(events.Quizzing.ShowQuizBar);

         return quizService.startQuizInstance(_quizModel, firstName, lastName, email, playerConfiguration).then(function(responseData) {
            _quizModel.quizInstanceID = responseData.quizInstanceId;
            $container.trigger(events.Quizzing.CreatedInstance);
         }).catch(function(data) {
            apiErrorEventHandler();
            return Promise.reject(data);
         });
      }

      return Promise.resolve();
   };

   var apiErrorEventHandler = function(response) {
      _quizEnabled = false;
      $container.trigger(events.Quizzing.HideLoadingMessage);
      if (response && response.status === statusCodes.UnprocessableEntity) {
         var errorID = response.responseJSON && response.responseJSON.errorId ? response.responseJSON.errorId : null;
         if (errorID && errorID === quizServiceErrorIds.badDefinition) {
            $container.trigger(events.Quizzing.QuizBadDefinition);
         } else {
            $container.trigger(events.Quizzing.QuizIsOutOfDate);
         }
      } else {
         $container.trigger(events.Quizzing.Error);
      }
   };

   var displaySetupView = function() {
      return _quizModel && _quizModel.quizID && !_mediaIsImage && _quizEnabled && _quizModel.requireUserId && !playerConfiguration.getIsUserIdentified();
   };

   var gatherSetupIfNeeded = function() {
      if (displaySetupView()) {
         createSetupView();
      }
   };

   var getLastStatusMessage = function() {
      return _lastStatusMessage;
   };

   var removeSubmissionRetryMessage = function() {
      if (_retrySubmissionMessageDisplayBar === null) {
         return;
      }
      _retrySubmissionMessageDisplayBar.remove();
      _retrySubmissionMessageDisplayBar = null;
   };

   var showSubmissionRetryMessage = function() {
      if (_retrySubmissionMessageDisplayBar !== null) {
         return;
      }
      _retrySubmissionMessageDisplayBar = MessageBarView.create($container, messageBarType.info, localizationStrings.getPlayerString(playerStringNames.txtQuizErrorMessage));
   };

   var showQuizIsOutOfDateMessage = function() {
      _quizOutOfDateMessageView = MessageBarView.create($container, messageBarType.warning, localizationStrings.getPlayerString(playerStringNames.txtQuizOutOfDateMessage), localizationStrings.getPlayerString(playerStringNames.txtQuizOutOfDateRefreshButtonText), function() {
         window.location.reload();
      });
   };

   var showQuizBadDefinitionMessage = function() {
      _quizBadDefinitionMessageView = MessageBarView.create($container, messageBarType.warning, localizationStrings.getPlayerString(playerStringNames.txtQuizBadDefinitionMessage));
   };

   var displayNextQuestionSet = function() {
      if (_currentQuestionSetIndex < _numberQuestionSets) {
         return createQuestionSetView(true);
      }
   };

   var hasMultipleQuestionSets = function() {
      return _numberQuestionSets > 1;
   };

   var hasQuizQuestions = function() {
      return _numberQuestionSets > 0;
   };

   var findQuestionSet = function(time) {
      var activeQuestionSetId = -1;
      var markerTime = -1;

      if (!playerConfiguration.ignoreQuizQuestions) {
         var markers = _quizModel.questionSetMarkerTimes;
         var numberMarkers = markers.length;

         for (var i = 0; i < numberMarkers; i++) {
            if (!markers[i].completed && (enforceLinearAssessment && markers[i].startTime <= time && !markers[i].canSeekPast || markers[i].rangeStart <= time && time <= markers[i].rangeEnd)) {
               activeQuestionSetId = markers[i].questionSetIndex;
               markerTime = markers[i].startTime;
               break;
            }
         }
      }

      return {id: activeQuestionSetId, markerTime: markerTime};
   };

   var findPrevMarkerTimeForMarker = function(questionSetIndex) {
      return _quizModel.getPreviousMarkerTimeForMarker(questionSetIndex);
   };

   var updatePercentWatchedForQuestionSet = function(questionSetId, percentWatched) {
      _quizModel.questionSets[questionSetId].percentOfVideoSegmentWatched = percentWatched;
   };

   var displayConfirm = function(questionSetIndex) {
      if (!_currentView) {
         _currentQuestionSetIndex = questionSetIndex;

         hotkeyService.disableHotkeys();

         // no need to display confirm view if we are in the beginning of the video and force linear is on or if we are in the middle of a rationale quiz
         if (enforceLinearAssessment && _quizModel.questionSets[questionSetIndex].startTime <= _quizModel.minMarkerTimeDiff || _quizModel.questionSets[questionSetIndex].inProgress) {
            createQuestionSetView(true);
         } else {
            createConfirmView();
         }
      }
   };

   var showQuiz = function() {
      if (_currentView === null) {
         createQuestionSetView(true);
      } else {
         _currentView.showView();
      }
   };

   var hideQuiz = function() {
      _currentView.hideView();
   };

   var pointViewAt = function(xPos) {
      _pointAt = xPos;
      if (_currentView) {
         if (_currentView.pointAt) {
            _currentView.pointAt(_pointAt);
         }
         updateViewBottom();
      }
   };

   var setViewControlBarOffset = function(bottom) {
      _bottomOffset = bottom + BOTTOM_PADDING;
      updateViewBottom();
   };

   var setQuestionGrader = function(grader) {
      switch (grader) {
         case questionGrader.FATHOM: {
            _questionGrader = fathomGrader;
            break;
         }
         case questionGrader.XMP: {
            _questionGrader = xmpGrader;
            break;
         }
         default:
            throw new Error('Grader type not recognized');
      }
   };

   var startTrackingProgress = function() {
      if (_shouldReportViewedOnPageLoad) {
         return Promise.resolve();
      }

      return quizService.getAnalyticsRate().then(function(results) {
         _progressTracker = QuizProgressTracker.create(getMediaElement(), results.analyticsSampleRate, results.reportingRate);
         return Promise.resolve();
      });
   };

   var forceProgressUpdate = function(sampleRateInMilliseconds, segmentsCompleted) {
      return quizService.sendProgress(sampleRateInMilliseconds, segmentsCompleted);
   };

   var onMediaFirstPlayerHandler = function() {
      init();
   };

   var onMediaControlsReady = function() {
      // do not listen to another media controls ready event
      $container.off(events.Player.MediaControlsReady, onMediaControlsReady);

      setViewControlBarOffset(CONTROL_BAR_HEIGHT);

      if (!_shouldReportViewedOnPageLoad && !_mediaIsImage && _quizModel.useQuizApi && _quizModel.requireUserId && !playerConfiguration.getIsUserIdentified() && !playerConfiguration.ignoreQuizQuestions) {
         createSetupView();
      } else {
         _resolveQuizSetup();
      }
   };

   var bindToEvents = function() {
      $container.on(events.Media.FirstPlay, onMediaFirstPlayerHandler);
      $container.on(events.Player.MediaControlsReady, onMediaControlsReady);
      $container.on(events.Quizzing.ShowSubmissionRetryMessage, showSubmissionRetryMessage);
      $container.on(events.Quizzing.HideSubmissionRetryMessage, removeSubmissionRetryMessage);
      $container.on(events.Quizzing.QuizIsOutOfDate, showQuizIsOutOfDateMessage);
      $container.on(events.Quizzing.QuizBadDefinition, showQuizBadDefinitionMessage);
   };

   var destroy = function() {
      destroyQuizSetupView();

      if (_currentView) {
         _currentView.removeView(true);
         _currentView = null;
      }

      quizService.abortPendingRequests();

      if ($container) {
         $container.off(events.Media.FirstPlay, onMediaFirstPlayerHandler);
         $container.off(events.Player.MediaControlsReady, onMediaControlsReady);
         $container.off(events.Quizzing.ShowSubmissionRetryMessage, showSubmissionRetryMessage);
         $container.off(events.Quizzing.HideSubmissionRetryMessage, removeSubmissionRetryMessage);
         $container.off(events.Quizzing.QuizIsOutOfDate, showQuizIsOutOfDateMessage);
         $container.off(events.Quizzing.QuizBadDefinition, showQuizBadDefinitionMessage);
         $container.off(events.Quizzing.SubmitQuestionSet, onSubmit);
         $container.off(events.Quizzing.CloseQuestionSetView, onCloseQuestionSetView);
         $container.off(events.Quizzing.RationaleActionLocation, onRationaleActionHandler);
         $container.off(events.Quizzing.RationaleActionJumpToTime, onRationaleActionHandler);
      }

      _progressTracker && _progressTracker.destroy();

      removeSubmissionRetryMessage();

      _quizOutOfDateMessageView && _quizOutOfDateMessageView.remove();
      _quizBadDefinitionMessageView && _quizBadDefinitionMessageView.remove();

      _quizModel = null;

      _numberQuestionSets = 0;
      _currentQuestionSetIndex = 0;
      _bottomOffset = 0;
      _pointAt = null;
      _quizEnabled = true;
      _quizReady = false;
      _mediaIsImage = false;
      _lastStatusMessage = '';
      _questionGrader = null;
      _quizSetupPromise = null;
   };

   var init = function() {
      if (_quizModel.useQuizApi) {
         var startInstancePromise;
         if (_quizUserDetails) {
            startInstancePromise = startQuizApiInstance(_quizUserDetails.firstName, _quizUserDetails.lastName, _quizUserDetails.email);
         } else {
            startInstancePromise = startQuizApiInstance();
         }
         startInstancePromise.catch(function(e) {
            log.logException('Failed to initialize quiz - ' + e);
         });
      } else {
         $container.trigger(events.Quizzing.CreatedInstance);
      }
      _quizReady = true;
   };

   var simpleInit = function() {
      if (!displaySetupView()) {
         startQuizApiInstance().catch(function(e) {
            log.logException('Failed to initialize quiz - ' + e);
         });
      }
   };

   var populateQuizModel = function(suspendedQuizData) {
      _quizModel.populateQuiz(suspendedQuizData);
      submitScoreToScormController();
   };

   var setQuizModel = function(newQuizModel) {
      _quizModel = newQuizModel;
      _numberQuestionSets = _quizModel.numberQuestionSets;
      scormController.quizModel = _quizModel;
   };

   // begin initialization of quiz controller
   _quizSetupPromise = new Promise(function(resolveQuizSetup) {
      _resolveQuizSetup = resolveQuizSetup;
   });

   setQuizModel(quizModel);

   bindToEvents();

   if (playerConfiguration.getFathomId()) {
      setQuestionGrader(questionGrader.FATHOM);
   } else {
      setQuestionGrader(questionGrader.XMP);
   }

   if (_shouldReportViewedOnPageLoad) {
      gatherSetupIfNeeded();
      simpleInit();
      forceProgressUpdate(100, [0]);
   }

   var fullControlsAreShownOnPageLoad = playerConfiguration.getPreload() === preloadOptions.metadata && mediaView.type !== mediaViewType.youtube;
   if (fullControlsAreShownOnPageLoad) {
      onMediaControlsReady();
   }

   return Object.defineProperties({
      destroy: destroy,
      hasMultipleQuestionSets: hasMultipleQuestionSets,
      getLastStatusMessage: getLastStatusMessage,
      displayNextQuestionSet: displayNextQuestionSet,
      findQuestionSet: findQuestionSet,
      findPrevMarkerTimeForMarker: findPrevMarkerTimeForMarker,
      updatePercentWatchedForQuestionSet: updatePercentWatchedForQuestionSet,
      displayConfirm: displayConfirm,
      showQuiz: showQuiz,
      hideQuiz: hideQuiz,
      pointViewAt: pointViewAt,
      startTrackingProgress: startTrackingProgress,
      forceProgressUpdate: forceProgressUpdate,
      populateQuizModel: populateQuizModel
   }, {
      quizIsSetupPromise: {
         get: function() {
            return _quizSetupPromise;
         }
      },
      quizEnabled: {
         get: function() {
            return _quizEnabled;
         },
         set: function(value) {
            _quizEnabled = value;
         }
      },
      quizReady: {
         get: function() {
            return _quizReady;
         }
      },
      hasQuiz: {
         get: function() {
            return _quizModel && _quizModel.quizID;
         }
      },
      viewIsOpen: {
         get: function() {
            return _currentView !== null;
         }
      },
      mediaIsImage: {
         set: function(mediaIsImage) {
            _mediaIsImage = mediaIsImage;
         },
         get: function() {
            return _mediaIsImage;
         }
      },
      quizModel: {
         get: function() {
            return _quizModel;
         },
         set: setQuizModel
      },
      hasQuizQuestions: {
         get: hasQuizQuestions
      }
   });
};

export default {
   /**
    *  Factory method that returns a new QuizController.
    *  @function create
    *  @memberof TSC.QuizController
    *  @static
    *  @param {jQuery} $container - Smart Player main container jquery element
    *  @param {MediaView} mediaView - Media view for current media. Created by MediaViewFactory.
    *  @param {TSC.Quiz} quizModel
    *  @param {TSC.BeforeAfterPlayControlsView} beforeAfterPlayControlsView
    *  @param {boolean} enforceLinearAssessment
    *  @return new QuizController instance
    */
   create: QuizController
};
