import {CaptionController} from '@techsmith/tsc-media-extensions-js';
import tinycolor from 'tinycolor2';
import events from '../constants/events';

var BASE_FONT_SIZE_FOR_1080P_VIDEO = 48;
var BASE_VIDEO_WIDTH = 1920;
var BASE_VIDEO_HEIGHT = 1080;
var ROUNDING_MODIFIER = 0.5;

var _defaultTextColorObject = Object.freeze(tinycolor({r: 255, g: 255, b: 255, a: 1.0}));
var _defaultBgColorObject = Object.freeze(tinycolor({r: 0, g: 0, b: 0, a: 0.9}));

var _defaults = Object.freeze({
   captionFontFamily: 'Arial, Helvetica, sans-serif;',
   captionFontSize: 24,
   captionFontSizeMin: 12,
   captionFontSizeMax: 144,
   captionTextColor: _defaultTextColorObject,
   captionBgColor: _defaultBgColorObject,
   captionPosition: 'overlay',
   captionBarHeight: 0
});

/**
 * @memberof TSC
 * @class CaptionService
 * @classdesc The caption service
 */
var CaptionService = function() {
   var _position = _defaults.captionPosition;
   var _barHeight = _defaults.captionBarHeight;
   var _font = _defaults.captionFontFamily;
   var _fontSize = _defaults.captionFontSize;
   var _fontSizeForPlayerDimensions = _defaults.captionFontSize;
   var _fontSizeMin = _defaults.captionFontSizeMin;
   var _fontSizeMax = _defaults.captionFontSizeMax;
   var _textColor = _defaults.captionTextColor.clone();
   var _backgroundColor = _defaults.captionBgColor.clone();
   var _captionController = CaptionController.create();
   var _eventHandlers = {};

   var setupSupportedEvents = function() {
      _eventHandlers[events.CaptionService.NewCaptionTrack] = [];
   };

   var setFontSize = function(value) {
      _fontSize = Math.min(Math.max(value, _fontSizeMin), _fontSizeMax);
   };

   var initFromXmp = function(xmp) {
      _position = xmp.captionPosition;
      _barHeight = xmp.captionBarHeight;
      _font = xmp.captionFontFamily;
      _fontSize = xmp.captionFontSize;
      _fontSizeMin = xmp.minCaptionFontSize;
      _fontSizeMax = xmp.maxCaptionFontSize;
      _textColor = tinycolor(xmp.captionTextColor);
      _backgroundColor = tinycolor(xmp.captionBg);
      _captionController = xmp.captionController;
   };

   var initDefaults = function() {
      _position = _defaults.captionPosition;
      _barHeight = _defaults.captionBarHeight;
      _font = _defaults.captionFontFamily;
      _fontSize = _defaults.captionFontSize;
      _fontSizeForPlayerDimensions = _defaults.captionFontSize;
      _fontSizeMin = _defaults.captionFontSizeMin;
      _fontSizeMax = _defaults.captionFontSizeMax;
      _textColor = _defaults.captionTextColor.clone();
      _backgroundColor = _defaults.captionBgColor.clone();
   };

   var setFontSizeForPlayerDimensions = function(playerWidth, playerHeight) {
      var scaleX = playerWidth / BASE_VIDEO_WIDTH;
      var scaleY = playerHeight / BASE_VIDEO_HEIGHT;
      _fontSizeForPlayerDimensions = Math.floor(BASE_FONT_SIZE_FOR_1080P_VIDEO * Math.min(scaleX, scaleY) + ROUNDING_MODIFIER);
   };

   var dispatchEvent = function(eventName) {
      if (_eventHandlers[eventName]) {
         _eventHandlers[eventName].forEach(function(eventCallback) {
            eventCallback();
         });
      }
   };

   var addEventListener = function(eventName, eventHandler) {
      if (!_eventHandlers[eventName]) {
         return;
      }

      _eventHandlers[eventName].push(eventHandler);
   };

   var removeEventListener = function(eventName, eventHandler) {
      if (!_eventHandlers[eventName]) {
         return;
      }

      var eventEntry = _eventHandlers[eventName];
      for (var i = 0; i < eventEntry.length; i++) {
         if (eventEntry[i] === eventHandler) {
            eventEntry.splice(i, 1);
            break;
         }
      }
   };

   var setCaptionTrack = function(newCaptionTrack) {
      _captionController.addCaptionTrack(newCaptionTrack);
      _backgroundColor = tinycolor({r: _captionController.activeCaptionTrack.bgColor.r, g: _captionController.activeCaptionTrack.bgColor.g, b: _captionController.activeCaptionTrack.bgColor.b, a: _captionController.activeCaptionTrack.bgOpacity});
      _textColor = tinycolor({r: _captionController.activeCaptionTrack.fgColor.r, g: _captionController.activeCaptionTrack.fgColor.g, b: _captionController.activeCaptionTrack.fgColor.b});
      setFontSize(_captionController.activeCaptionTrack.fontSize);
      _font = _captionController.activeCaptionTrack.fontFamily;
      _barHeight = _captionController.activeCaptionTrack.barHeight;
      _position = _captionController.activeCaptionTrack.position;

      dispatchEvent(events.CaptionService.NewCaptionTrack);
   };

   setupSupportedEvents();

   return Object.defineProperties({
      initFromXmp: initFromXmp,
      initDefaults: initDefaults,
      setFontSizeForPlayerDimensions: setFontSizeForPlayerDimensions,
      addEventListener: addEventListener,
      removeEventListener: removeEventListener
   }, {
      position: {
         get: function() {
            return _position;
         }
      },
      height: {
         get: function() {
            return _barHeight;
         }
      },
      font: {
         get: function() {
            return _font;
         },
         set: function(value) {
            _font = value;
         }
      },
      fontEnclosedWithQuotes: {
         get: function() {
            var fontFamilies = _font.split(',');
            var quotedFontFamilies = fontFamilies.map(function(fontFamily) {
               return '\'' + fontFamily.trim() + '\'';
            });
            return quotedFontFamilies.join(',');
         }
      },
      fontSize: {
         get: function() {
            return _fontSize;
         },
         set: setFontSize
      },
      fontSizeForPlayerDimensions: {
         get: function() {
            return _fontSizeForPlayerDimensions;
         }
      },
      fontSizeMin: {
         get: function() {
            return _fontSizeMin;
         }
      },
      fontSizeMax: {
         get: function() {
            return _fontSizeMax;
         }
      },
      textColor: {
         get: function() {
            return _textColor;
         },
         set: function(value) {
            _textColor = tinycolor(value);
         }
      },
      backgroundColor: {
         get: function() {
            return _backgroundColor;
         },
         set: function(value) {
            _backgroundColor = tinycolor(value);
         }
      },
      backgroundOpacity: {
         get: function() {
            return _backgroundColor.getAlpha();
         },
         set: function(value) {
            _backgroundColor.setAlpha(value);
         }
      },
      captionController: {
         get: function() {
            return _captionController;
         }
      },
      captionTrack: {
         get: function() {
            return _captionController.activeCaptionTrack;
         },
         set: setCaptionTrack
      },
      hasCaptions: {
         get: function() {
            return _captionController.containsCaptions;
         }
      }
   });
};

export default {
   /**
    *  Factory method that returns a new CaptionService object.
    *  @function create
    *  @memberof TSC.CaptionService
    *  @static
    *  @return new CaptionService instance
    */
   create: function() {
      return CaptionService();
   },
   defaults: function() {
      return _defaults;
   }
};
