import windowWrapper from '../../common/window-wrapper';
import cssClasses from '../constants/css-classes';
import events from '../constants/events';
import BeforeAfterPlayControlsView from '../views/before-after-play-controls-view';
import ProgressBarControlView from '../views/progress-bar-control-view';
import PlayRewindControlsView from '../views/play-rewind-controls-view';
import PrevNextControlsView from '../views/prev-next-controls-view';
import VolumeControlView from '../views/volume-control-view';
import AudioDescriptionControlView from '../views/audio-description-control-view';
import CaptionsControlView from '../views/captions-control-view';
import TocControlView from '../views/toc-control-view';
import PlayerSettingsView from '../views/player-settings-view';
import PipControlView from '../views/pip-control-view';
import TheaterModeControlView from '../views/theater-mode-control-view';
import FullscreenControlView from '../views/fullscreen-control-view';
import ClickControlsView from '../views/click-controls-view';
import playerControlsType from '../constants/player-controls-type';
import mediaInterfaceConstants from '../constants/media-interface-constants';
import mediaViewType from '../constants/media-view-type';
import urlService from '../services/url-service';
import deviceInfo from '../utils/device-info';
import preloadOptions from '../constants/preload-options';

var ANIMATION_SPEED = 300;
var AUTO_HIDE_TIME_OUT_IN_MILLISECONDS = 5000;
var MOUSE_MOVE_TOLERANCE = 5;
var SURFACE_CLICK_LEAVE_DELAY = 100;

/**
 * @memberof TSC
 * @class VideoControlsController
 * @classdesc Video controls controller - binds all the controls used for a video in Smart Player
 * @param {jQuery} $rootSmartPlayerElement
 * @param {MediaView} mediaView
 * @param {TSC.XmpModel} xmpModel
 * @param {TSC.Quiz} quizModel
 * @param {TSC.playerConfiguration} playerConfiguration
 */
var VideoControlsController = function($rootSmartPlayerElement, mediaView, xmpModel, quizModel, playerConfiguration) { // eslint-disable-line
   var _windowRef = windowWrapper.getWindow();
   var _displayStartScreenOnPlaybackError = false;
   var _$videoControlsContainer;
   var _beforeAfterPlayControlsView;
   var _progressBarControlView;
   var _playRewindControlsView;
   var _prevNextControlsView;
   var _volumeControlView;
   var _audioDescriptionControlView;
   var _captionsControlView;
   var _tocControlView;
   var _playerSettingsView;
   var _pipControlView;
   var _theaterModeControlView;
   var _fullScreenControlView;
   var _clickControlsView;
   var _mediaIsSeeking = false;
   var _timeToSeekAfterCurrentSeekOperation = -1;
   var _displayMode = mediaInterfaceConstants.controls.autohide;
   var _playbackInitialized = false;
   var _userNeedToClickHeroPlayControl = false;
   var _lastClickTime;
   var _controlsHidden = true;
   var _autoHideControlsTimeoutId = -1;
   var _lastMouseMoveCoords = {
      x: 0,
      y: 0
   };

   var onPlayPromiseError = function() {
      // safari fires an abort error when playback is started and document is hidden
      // in this case we do not want to assume user interaction is needed to play
      if (window.document.hidden || !_displayStartScreenOnPlaybackError) {
         return;
      }
      // Assume user interaction is needed to play
      _playbackInitialized = false;
      _displayStartScreenOnPlaybackError = false;
      setControlsDisplayMode(mediaInterfaceConstants.controls.false);
      _beforeAfterPlayControlsView.showVideoClickToPlayLink();
   };

   var isScrubbingVideo = function() {
      return _progressBarControlView ? _progressBarControlView.scrubbing : false;
   };

   var hideControls = function(forceHide, hideElementAfterFadeOut) {
      if (_displayMode === mediaInterfaceConstants.controls.true) {
         return;
      }

      var controlsAreBusy = isScrubbingVideo() || _playerSettingsView && _playerSettingsView.isOpen;
      if (!forceHide && controlsAreBusy) {
         return;
      }

      _controlsHidden = true;
      _$videoControlsContainer.stop(true, true);
      _$videoControlsContainer.fadeTo(ANIMATION_SPEED, 0, function() {
         if (hideElementAfterFadeOut) {
            _$videoControlsContainer.hide();
         }
      });

      _volumeControlView.hideControls();

      $rootSmartPlayerElement.removeClass(cssClasses.playerControlsVisible);
      $rootSmartPlayerElement.trigger(events.Controls.VideoControlsHidden);
   };

   var hideControlsOnTimeout = function(forceHide) {
      hideControls(forceHide, false);
      if (_controlsHidden) {
         _clickControlsView && _clickControlsView.disable();
      }
   };

   var clearHideControlsTimeout = function() {
      _windowRef.clearTimeout(_autoHideControlsTimeoutId);
   };

   var resetHideControlsTimeout = function() {
      clearHideControlsTimeout();
      if (playerConfiguration.getAutoHideControls()) {
         _autoHideControlsTimeoutId = _windowRef.setTimeout(hideControlsOnTimeout, AUTO_HIDE_TIME_OUT_IN_MILLISECONDS);
      }
   };

   var enableClickControlsIfNotPausedForHotspot = function() {
      if (!$rootSmartPlayerElement.playerState.oneOrMoreHotspotsWithTimeActionArePaused && _clickControlsView) {
         if (deviceInfo.isTouchInterface()) {
            setTimeout(_clickControlsView.enable, ANIMATION_SPEED);
         } else {
            _clickControlsView.enable();
         }
      }
   };

   var showControls = function(autoHideControls) {
      if (_controlsHidden) {
         _controlsHidden = false;
         _$videoControlsContainer.removeClass(cssClasses.hide);
         _$videoControlsContainer.stop(true, true);
         _$videoControlsContainer.show();
         _$videoControlsContainer.fadeTo(ANIMATION_SPEED, 1);

         enableClickControlsIfNotPausedForHotspot();

         $rootSmartPlayerElement.addClass(cssClasses.playerControlsVisible);
         $rootSmartPlayerElement.trigger(events.Controls.VideoControlsVisible);
      }

      if (autoHideControls) {
         resetHideControlsTimeout();
      }
   };

   var showControlsWithAutoHide = function() {
      showControls(true);
   };

   var onContainerClick = function() {
      _lastClickTime = new Date().getTime();
      showControlsWithAutoHide();
   };

   var onContainerLeave = function() {
      //Older Win touch devices emulate clicks with Enter -> Click -> Leave, so we need hax for them
      if (!_lastClickTime || new Date().getTime() - _lastClickTime > SURFACE_CLICK_LEAVE_DELAY) {
         hideControlsOnTimeout();
      }
   };

   var onContainerMousemove = function(e) {
      var x = e.pageX;
      var y = e.pageY;

      if (!x && x !== 0 || !y && y !== 0) {
         //Just in case values aren't assigned for some reason
         showControlsWithAutoHide();
         return;
      }

      var deltaX = Math.abs(x - _lastMouseMoveCoords.x);
      var deltaY = Math.abs(y - _lastMouseMoveCoords.y);

      if (deltaX + deltaY > MOUSE_MOVE_TOLERANCE) {
         _lastMouseMoveCoords.x = x;
         _lastMouseMoveCoords.y = y;
         showControlsWithAutoHide();
      }
   };

   var enableAutoHideControls = function() {
      $rootSmartPlayerElement.on('mousemove', onContainerMousemove);
      $rootSmartPlayerElement.on('keydown', showControlsWithAutoHide);
      $rootSmartPlayerElement.on('click', onContainerClick);
      $rootSmartPlayerElement.on('mouseleave', onContainerLeave);

      resetHideControlsTimeout();
   };

   var disableAutoHideControls = function() {
      clearHideControlsTimeout();
      $rootSmartPlayerElement.off('mousemove', onContainerMousemove);
      $rootSmartPlayerElement.off('keydown', showControlsWithAutoHide);
      $rootSmartPlayerElement.off('click', onContainerClick);
      $rootSmartPlayerElement.off('mouseleave', onContainerLeave);
   };

   var seekVideo = function(timeInSeconds) {
      if (!mediaView.mediaElement || isNaN(mediaView.mediaElement.currentTime) || mediaView.mediaElement.currentTime === null || isNaN(timeInSeconds) || timeInSeconds === null) {
         return;
      }

      if (_mediaIsSeeking) {
         _timeToSeekAfterCurrentSeekOperation = timeInSeconds;
         return;
      }

      _timeToSeekAfterCurrentSeekOperation = -1;

      if (mediaView.mediaElement.currentTime.toFixed(4) === timeInSeconds.toFixed(4)) {
         return;
      }

      mediaView.mediaElement.currentTime = timeInSeconds;
   };

   var initCurrentMedia = function(e, eventData) {
      if (eventData.enablePreviousButton) {
         _prevNextControlsView.enablePreviousButton();
      } else {
         _prevNextControlsView.disablePreviousButton();
      }

      if (eventData.enableNextButton) {
         _prevNextControlsView.enableNextButton();
      } else {
         _prevNextControlsView.disableNextButton();
      }

      if (_progressBarControlView) {
         _progressBarControlView.durationHasChanged();
      }

      _displayStartScreenOnPlaybackError = true;

      // ensure current time starts at 0 for FireFox to record correct played time range
      if (deviceInfo.isFirefox()) {
         mediaView.mediaElement.currentTime = 0;
      }

      mediaView.mediaElement && play();

      // OSX Safari on some machines fast forwards videos when we call play with a quiz at frame 0.
      // Resetting the current time resolves this behavior. Ref https://github.com/TechSmith/SmartPlayer/issues/387
      if (deviceInfo.isSafari() && !deviceInfo.isIOS() && mediaView.mediaElement.readyState === 0) {
         mediaView.mediaElement.addEventListener('canplay', function resetTime() {
            mediaView.mediaElement.currentTime = 0;
            mediaView.mediaElement.removeEventListener('canplay', resetTime);
         });
      }
   };

   var onPlaybackInitialized = function() {
      if (!_playbackInitialized) {
         _playbackInitialized = true;
         $rootSmartPlayerElement.trigger(events.Controls.StartedPlayback);
      }
   };

   var play = function() {
      var playPromise = mediaView.mediaElement.play();

      if (playPromise !== undefined) {
         playPromise.then(function() {
            onPlaybackInitialized();
         }).catch(function(error) {
            onPlayPromiseError(error);
         });
      }
   };

   var onSeekToTime = function(e, eventData) {
      var time = eventData.time;

      seekVideo(time);
      setControlsDisplayMode(_displayMode);

      if (eventData.playAfterSeek) {
         mediaView.mediaElement && play();
      }
   };

   var onSetVolume = function(e, data) {
      if (_volumeControlView) {
         _volumeControlView.volume = data.volumeValue;
      }
   };

   var onSetMuted = function(e, data) {
      if (_volumeControlView) {
         _volumeControlView.muted = data.isMuted;
      }
   };

   var onSetPlaybackRate = function(e, data) {
      if (_playerSettingsView) {
         _playerSettingsView.playbackRate = data.playbackRate;
      }
   };

   var onVideoSeeking = function() {
      _mediaIsSeeking = true;
   };

   var onVideoSeeked = function() {
      _mediaIsSeeking = false;

      if (_timeToSeekAfterCurrentSeekOperation >= 0) {
         seekVideo(_timeToSeekAfterCurrentSeekOperation);
      }
   };

   var onVideoMetadataLoaded = function() {
      mediaView.mediaElement.removeEventListener(events.ExternalVideo.VideoLoadedMetadata, onVideoMetadataLoaded);
      var canSupportExpandVideoMode = !playerConfiguration.isScormLesson;
      _fullScreenControlView.initializeFullScreenSupport(canSupportExpandVideoMode);
      _pipControlView && _pipControlView.initialize();
      $rootSmartPlayerElement.trigger(events.Controls.HideLoadingMessage);
      seekToInitialTimeIfSet();
   };

   var teardownHideShowControls = function() {
      if (_displayMode === mediaInterfaceConstants.controls.autohide) {
         disableAutoHideControls();
      }
   };

   var setControlsDisplayMode = function(displayMode) {
      teardownHideShowControls();

      _displayMode = displayMode;

      if (_displayMode === mediaInterfaceConstants.controls.false) {
         disableAutoHideControls();
         hideControls(true, true);
      } else if (_displayMode === mediaInterfaceConstants.controls.autohide) {
         enableAutoHideControls();
         showControls(true);
      } else {
         showControls(false);
      }
   };

   var onDisplayCaptionControl = function(e, eventData) {
      if (eventData.displayCaptionControl) {
         _captionsControlView = CaptionsControlView.create($rootSmartPlayerElement);
      }
      if (_captionsControlView) {
         _captionsControlView.setCaptionButtonVisible(eventData.displayCaptionControl);
      }
   };

   var onDisplayTocControl = function() {
      _tocControlView = TocControlView.create($rootSmartPlayerElement, playerConfiguration);
   };

   var onTocScroll = function() {
      resetHideControlsTimeout();
   };

   var seekToInitialTimeIfSet = function() {
      if (!playerConfiguration.isScormLesson && playerConfiguration.jumpToTime) {
         var timeToSeek = urlService.convertUrlTime(playerConfiguration.jumpToTime);
         if (timeToSeek < mediaView.duration) {
            seekVideo(timeToSeek);
         }
      }
   };

   var initializePlayback = function(playVideo) {
      if (playVideo) {
         play();
      } else {
         onPlaybackInitialized();
      }
   };

   var onFirstPlay = function() {
      if (!_playbackInitialized) {
         initializePlayback(false);
      }
   };

   var onQuizSetupComplete = function() {
      $rootSmartPlayerElement.off(events.Quizzing.QuizSetupComplete, onQuizSetupComplete);
      initializePlayback(true);
   };

   var onHeroPlayControlClick = function() {
      $rootSmartPlayerElement.off(events.Controls.HeroPlayControlClicked, onHeroPlayControlClick);
      $rootSmartPlayerElement.trigger(events.Player.MediaControlsReady);

      // do not run this if we are waiting on quiz input
      if (quizModel && quizModel.quizID && quizModel.requireUserId && !playerConfiguration.getIsUserIdentified()) {
         $rootSmartPlayerElement.on(events.Quizzing.QuizSetupComplete, onQuizSetupComplete);
      } else {
         initializePlayback(true);
      }
   };

   var onMediaError = function() {
      _beforeAfterPlayControlsView.hideVideoClickToPlayLink();
      _beforeAfterPlayControlsView.setVideoClickToPlayErrorState(true);
      hideControls(true, true);
   };

   var onHotspotInactive = function() {
      enableClickControlsIfNotPausedForHotspot();
   };

   var onTryToAutoPlay = function() {
      _displayStartScreenOnPlaybackError = true;
      initializePlayback(true);
   };

   var setupControls = function() {
      _$videoControlsContainer = $rootSmartPlayerElement.find('.video-controls-container');

      mediaView.hotfixViewAndControlsCSS(_$videoControlsContainer);

      _beforeAfterPlayControlsView = BeforeAfterPlayControlsView.create($rootSmartPlayerElement, playerConfiguration);
      if (!mediaView.supportsClickToPlayControls) {
         _beforeAfterPlayControlsView.hideVideoClickToPlayLink();
      }
      _progressBarControlView = ProgressBarControlView.create($rootSmartPlayerElement, mediaView.mediaElement, quizModel);
      _playRewindControlsView = PlayRewindControlsView.create($rootSmartPlayerElement, mediaView.mediaElement);

      if (mediaView.playerControlType === playerControlsType.videoPlaylist) {
         _prevNextControlsView = PrevNextControlsView.create($rootSmartPlayerElement);
      }
      _volumeControlView = VolumeControlView.create($rootSmartPlayerElement, mediaView.mediaElement);

      if (playerConfiguration.audioDescriptionMediaSrc) {
         _audioDescriptionControlView = AudioDescriptionControlView.create($rootSmartPlayerElement);
      }

      _playerSettingsView = PlayerSettingsView.create($rootSmartPlayerElement, mediaView.mediaElement, xmpModel, null, playerConfiguration.audioDescriptionMediaSrc);

      if (mediaView.playerControlType !== playerControlsType.videoPlaylist) {
         _pipControlView = PipControlView.create($rootSmartPlayerElement, mediaView.mediaElement);
      }

      if (playerConfiguration.getEnableTheaterMode()) {
         _theaterModeControlView = TheaterModeControlView.create($rootSmartPlayerElement);
      }

      _fullScreenControlView = FullscreenControlView.create($rootSmartPlayerElement, mediaView.mediaElement);

      if (mediaView.supportsClickToPlayControls) {
         _clickControlsView = ClickControlsView.create($rootSmartPlayerElement, mediaView.mediaElement, _fullScreenControlView, _progressBarControlView, playerConfiguration);
      }
   };

   var initControls = function() {
      if (playerConfiguration.getPreload() !== preloadOptions.metadata && mediaView.type !== mediaViewType.youtube) {
         _userNeedToClickHeroPlayControl = true;
         if (playerConfiguration.getPosterImageSrc()) {
            _beforeAfterPlayControlsView.addVideoClickToPlayBackground(playerConfiguration.getPosterImageSrc());
         }
         $rootSmartPlayerElement.on(events.Controls.HeroPlayControlClicked, onHeroPlayControlClick);
      } else {
         _beforeAfterPlayControlsView.hideVideoClickToPlayLink();
         _beforeAfterPlayControlsView.hideVideoClickToReplayLink();
         _userNeedToClickHeroPlayControl = false;
      }
   };

   var bindEvents = function() {
      $rootSmartPlayerElement.on(events.Controls.DisplayCaptionControl, onDisplayCaptionControl);
      $rootSmartPlayerElement.on(events.Controls.DisplayTocControl, onDisplayTocControl);
      $rootSmartPlayerElement.on(events.Controls.TocScroll, onTocScroll);
      $rootSmartPlayerElement.on(events.Media.SeekToTime, onSeekToTime);
      $rootSmartPlayerElement.on(events.Media.SetVolume, onSetVolume);
      $rootSmartPlayerElement.on(events.Media.SetMutedState, onSetMuted);
      $rootSmartPlayerElement.on(events.Media.SetPlaybackRate, onSetPlaybackRate);
      $rootSmartPlayerElement.on(events.Media.InitializeCurrent, initCurrentMedia);
      $rootSmartPlayerElement.on(events.Media.TryToAutoPlay, onTryToAutoPlay);
      $rootSmartPlayerElement.on(events.Media.FirstPlay, onFirstPlay);
      $rootSmartPlayerElement.on(events.Media.Error, onMediaError);
      $rootSmartPlayerElement.on(events.Hotspots.AreaInactive, onHotspotInactive);
      mediaView.mediaElement.addEventListener(events.ExternalVideo.VideoLoadedMetadata, onVideoMetadataLoaded);
      mediaView.mediaElement.addEventListener(events.ExternalVideo.VideoSeeking, onVideoSeeking);
      mediaView.mediaElement.addEventListener(events.ExternalVideo.VideoSeeked, onVideoSeeked);
   };

   var destroy = function() {
      teardownHideShowControls();
      mediaView.mediaElement.removeEventListener(events.ExternalVideo.VideoLoadedMetadata, onVideoMetadataLoaded);
      mediaView.mediaElement.removeEventListener(events.ExternalVideo.VideoSeeking, onVideoSeeking);
      mediaView.mediaElement.removeEventListener(events.ExternalVideo.VideoSeeked, onVideoSeeked);
      $rootSmartPlayerElement.off(events.Controls.DisplayCaptionControl, onDisplayCaptionControl);
      $rootSmartPlayerElement.off(events.Controls.DisplayTocControl, onDisplayTocControl);
      $rootSmartPlayerElement.off(events.Controls.TocScroll, onTocScroll);
      $rootSmartPlayerElement.off(events.Media.SeekToTime, onSeekToTime);
      $rootSmartPlayerElement.off(events.Media.SetVolume, onSetVolume);
      $rootSmartPlayerElement.off(events.Media.SetMutedState, onSetMuted);
      $rootSmartPlayerElement.off(events.Media.SetPlaybackRate, onSetPlaybackRate);
      $rootSmartPlayerElement.off(events.Controls.ShowToc, disableAutoHideControls);
      $rootSmartPlayerElement.off(events.Controls.HideToc, enableAutoHideControls);
      $rootSmartPlayerElement.off(events.Media.InitializeCurrent, initCurrentMedia);
      $rootSmartPlayerElement.off(events.Media.TryToAutoPlay, onTryToAutoPlay);
      $rootSmartPlayerElement.off(events.Media.FirstPlay, onFirstPlay);
      $rootSmartPlayerElement.off(events.Media.Error, onMediaError);
      $rootSmartPlayerElement.off(events.Hotspots.AreaInactive, onHotspotInactive);
      _beforeAfterPlayControlsView && _beforeAfterPlayControlsView.destroy();
      _progressBarControlView && _progressBarControlView.destroy();
      _playRewindControlsView && _playRewindControlsView.destroy();
      _prevNextControlsView && _prevNextControlsView.destroy();
      _volumeControlView && _volumeControlView.destroy();
      _audioDescriptionControlView && _audioDescriptionControlView.destroy();
      _captionsControlView && _captionsControlView.destroy();
      _tocControlView && _tocControlView.destroy();
      _playerSettingsView && _playerSettingsView.destroy();
      _pipControlView && _pipControlView.destroy();
      _theaterModeControlView && _theaterModeControlView.destroy();
      _fullScreenControlView && _fullScreenControlView.destroy();
      _clickControlsView && _clickControlsView.destroy();
   };

   setupControls();
   bindEvents();
   initControls();

   return Object.defineProperties({
      /**
       *  Sets the display mode for the video controls
       *  @memberof TSC.VideoControlsController
       *  @function setControlsDisplayMode
       *  @param displayMode - Mode the video controls should be displayed with
       */
      setDisplayMode: setControlsDisplayMode,
      /**
       *  Tear downs the instance of video controls controller
       *  @memberof TSC.VideoControlsController
       *  @function destroy
       */
      destroy: destroy
   }, {
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.VideoControlsController
       *  @property {boolean} userNeedToClickHeroPlayControl - User needs to click hero play control to start playback
       */
      userNeedToClickHeroPlayControl: {
         get: function() {
            return _userNeedToClickHeroPlayControl;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.VideoControlsController
       *  @property {boolean} playbackInitialized - Playback has been initialized
       */
      playbackInitialized: {
         get: function() {
            return _playbackInitialized;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.VideoControlsController
       *  @property {TSC.ProgressBarControlView} progressBarControlView - progress bar view
       */
      progressBarControlView: {
         get: function() {
            return _progressBarControlView;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.VideoControlsController
       *  @property {TSC.FullscreenControlView} fullscreenControlView - full screen control view
       */
      fullscreenControlView: {
         get: function() {
            return _fullScreenControlView;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.VideoControlsController
       *  @property {TSC.BeforeAfterPlayControlsView} beforeAfterPlayControlsView - before after play controls view
       */
      beforeAfterPlayControlsView: {
         get: function() {
            return _beforeAfterPlayControlsView;
         }
      }
   });
};

export default {
   /**
    *  Factory method that returns a new VideoControlsController object
    *  @function create
    *  @memberof TSC.VideoControlsController
    *  @param {jQuery} $rootSmartPlayerElement
    *  @param {MediaView} mediaView
    *  @param {TSC.XmpModel} xmpModel
    *  @param {TSC.Quiz} quizModel
    *  @param {TSC.playerConfiguration} playerConfiguration
    *  @static
    *  @return new VideoControlsController instance
    */
   create: VideoControlsController
};
