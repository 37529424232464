import windowWrapper from '../../common/window-wrapper';
import events from '../constants/events';

/**
 * @memberof TSC
 * @class MediaSessionController
 * @classdesc Media Session controller will interface media with system controls
 * @param {jQuery} $rootSmartPlayerElement
 * @param {HTMLVideoElement} mediaElement
 * @param {string} mediaTitle
 * @param {string} mediaPosterImageSrc
 */
var MediaSessionController = function($rootSmartPlayerElement, mediaElement, mediaTitle, mediaPosterImageSrc) {
   var _windowRef = windowWrapper.getWindow();
   var _navigatorRef = windowWrapper.getNavigator();
   // This prevents unnecessary errors when Media Session API is not available.
   var _mediaSessionRef = _navigatorRef.mediaSession || {};
   _mediaSessionRef.setActionHandler = _navigatorRef.mediaSession.setActionHandler || function() {}; // eslint-disable-line
   _windowRef.MediaMetadata = _windowRef.MediaMetadata || function() {}; // eslint-disable-line

   var _metaDataSet = false;

   var setMetaData = function() {
      _mediaSessionRef.metadata = new _windowRef.MediaMetadata({
         title: mediaTitle,
         artwork: [{src: mediaPosterImageSrc}]
      });

      _metaDataSet = true;
      updateMediaSessionState();
   };

   var updateMediaSessionState = function() {
      if (!_metaDataSet) {
         return;
      }
      _mediaSessionRef.playbackState = mediaElement.paused ? 'paused' : 'playing';
      _mediaSessionRef.setPositionState({
         duration: mediaElement.duration,
         playbackRate: mediaElement.playbackRate,
         position: mediaElement.currentTime
      });
   };

   var onPlayAction = function() {
      if ($rootSmartPlayerElement.playerState.pausedForQuiz) {
         return;
      }
      mediaElement.play();
   };

   var onPauseAction = function() {
      if ($rootSmartPlayerElement.playerState.pausedForQuiz) {
         return;
      }
      mediaElement.pause();
   };

   var onSeekToAction = function(details) {
      if ($rootSmartPlayerElement.playerState.pausedForQuiz) {
         return;
      }

      $rootSmartPlayerElement.trigger(events.Media.SeekToTime, {time: details.seekTime});
   };

   var bindEvents = function() {
      mediaElement.addEventListener('loadedmetadata', setMetaData);
      mediaElement.addEventListener('playing', updateMediaSessionState);
      mediaElement.addEventListener('pause', updateMediaSessionState);
      mediaElement.addEventListener('durationchange', updateMediaSessionState);
      mediaElement.addEventListener('ratechange', updateMediaSessionState);
      mediaElement.addEventListener('timeupdate', updateMediaSessionState);

      _mediaSessionRef.setActionHandler('play', onPlayAction);
      _mediaSessionRef.setActionHandler('pause', onPauseAction);
      _mediaSessionRef.setActionHandler('seekto', onSeekToAction);
   };

   var destroy = function() {
      mediaElement.removeEventListener('loadedmetadata', setMetaData);
      mediaElement.removeEventListener('playing', updateMediaSessionState);
      mediaElement.removeEventListener('pause', updateMediaSessionState);
      mediaElement.removeEventListener('durationchange', updateMediaSessionState);
      mediaElement.removeEventListener('ratechange', updateMediaSessionState);
      mediaElement.removeEventListener('timeupdate', updateMediaSessionState);
   };

   bindEvents();

   return Object.defineProperties({
      destroy: destroy
   }, {});
};

var isSupported = function() {
   return !!windowWrapper.getNavigator().mediaSession;
};

export default {
   /**
    *  Factory method that returns a new MediaSessionController object
    *  @function create
    *  @memberof TSC.MediaSessionController
    *  @param {jQuery} $rootSmartPlayerElement
    *  @param {HTMLVideoElement} mediaElement
    *  @param {string} mediaTitle
    *  @param {string} mediaPosterImageSrc
    *  @static
    *  @return MediaSessionController instance
    */
   create: MediaSessionController,
   /**
    *  Is the Media Session api supported
    *  @function isSupported
    *  @memberof TSC.MediaSessionController
    *  @static
    *  @return {boolean} Is the api supported
    */
   isSupported: isSupported
};
