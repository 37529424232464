import $ from 'jquery';
import smartPlayerPreRollEventConstants from '../../common/pre-roll-event-constants';
import preRollView from '../views/pre-roll-view';

var _onPreRollStartedCallback;
var $preRollView;
var $window = $(window);

var preRollMessage = function(e) {
   e = e.originalEvent;
   // filter out non smart player events
   if (!e.data || typeof e.data.indexOf !== 'function' || e.data.indexOf('smartPlayerEventName') === -1) {
      return;
   }
   var message = window.JSON.parse(e.data);

   switch (message.smartPlayerEventName) {
      case smartPlayerPreRollEventConstants.PRE_ROLL_STARTED: {
         if (_onPreRollStartedCallback) {
            _onPreRollStartedCallback();
         }
         break;
      }
      case smartPlayerPreRollEventConstants.PRE_ROLL_FINISHED: {
         switch (message.data.action) {
            case smartPlayerPreRollEventConstants.PRE_ROLL_FINISHED_ACTIONS.REMOVE_NOW: {
               destroyView();
               break;
            }
         }
         break;
      }
   }
};

var onIFrameContentLoaded = function() {
   $preRollView.removeClass('preRollHidden');
};

var destroyView = function() {
   if ($preRollView) {
      $window.off('message', preRollMessage);
      $preRollView.off('load', onIFrameContentLoaded);
      $preRollView.remove();
      $preRollView = undefined;
      _onPreRollStartedCallback = undefined;
   }
};

export default {
   createView: function(preRollSrc, onPreRollStartedCallback) {
      $preRollView = $(preRollView.render(preRollSrc)).appendTo('body');
      $preRollView.on('load', onIFrameContentLoaded);
      _onPreRollStartedCallback = onPreRollStartedCallback;
      $window.on('message', preRollMessage);
   },
   viewExists: function() {
      return $preRollView !== undefined;
   },
   destroyView: destroyView
};
