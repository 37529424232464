import Promise from 'promise-polyfill';
import HttpService from './http-service';
import quizDataXmlConverter from '../utils/quiz-data-xml-converter';
import BackoffStrategyFactory from './backoff-strategy-factory';
import tokenService from './token-service';
import playerConfiguration from '../models/player-configuration';

var DEFAULT_PROGRESS_INTERVAL_TIME_MILLISECONDS = 10000;

var _definitionEndPoint = 'v/1/quiz/definition';
var _instanceEndPoint = 'v/1/quiz/instance?noop=1';
var _responseEndPoint = 'v/1/quiz/response?noop=1';
var _progressEndPoint = 'v/1/quiz/progress?noop=1';

var _baseURL = 'https://quiz.cloud.techsmith.com/';

var _quizCreateInstanceUrl = null;
var _quizInstanceDataPromiseResolve;
var _quizInstanceDataPromiseReject;
var _quizInstanceDataPromise = new Promise(function(resolve, reject) {
   _quizInstanceDataPromiseResolve = resolve;
   _quizInstanceDataPromiseReject = reject;
});

var httpService = HttpService.create();
var httpServiceWithUnlimitedRetries = HttpService.create(BackoffStrategyFactory.createWithUnlimitedRetries());
var httpServiceWithNoLogging = HttpService.create(BackoffStrategyFactory.create(), false);

var getBaseURL = function() {
   if (playerConfiguration.getQuizServiceBaseUrl()) {
      return playerConfiguration.getQuizServiceBaseUrl();
   }
   return _baseURL;
};

//should only be used for the purposes of unit tests!
var reset = function() {
   abortPendingRequests();

   httpServiceWithUnlimitedRetries = HttpService.create(BackoffStrategyFactory.createWithUnlimitedRetries());
   httpService = HttpService.create();
   httpServiceWithNoLogging = HttpService.create(BackoffStrategyFactory.create(), false);
   _quizInstanceDataPromise = new Promise(function(resolve, reject) {
      _quizInstanceDataPromiseResolve = resolve;
      _quizInstanceDataPromiseReject = reject;
   });
};

var submitQuizDefinition = function(quizJsonData, hash, clientId) {
   if (!quizJsonData) {
      return Promise.resolve();
   }

   if (typeof quizJsonData === 'string') {
      quizJsonData = JSON.parse(quizJsonData);
   }

   var quizDefinitionXml = quizDataXmlConverter.quizDefinitionToXml(quizJsonData);
   var url = getBaseURL() + _definitionEndPoint + '?Quiz-Checksum=' + hash + '&Quiz-ClientId=' + clientId + '&noop=1';
   var data = {DefinitionText: quizDefinitionXml};

   return httpService.sendFormDataPost(url, data).then(function() {
      return quizDefinitionXml;
   });
};

var getInstanceFormData = function(quizModel, firstName, lastName, email, playerConfig) {
   var data = {
      QuizId: quizModel.quizID,
      CheckForQuizDefinition: playerConfig.getFathomId() === undefined
   };

   if (playerConfig.getTechSmithAccessTokenEndpoint()) {
      return tokenService.getTechSmithAccessToken(playerConfig).then(function(token) {
         data.AccessToken = token;
         return data;
      });
   }

   data.FirstName = !firstName ? '' : firstName;
   data.LastName = !lastName ? '' : lastName;
   data.Email = !email ? '' : email;
   return Promise.resolve(data);
};

var startQuizInstance = function(quizModel, firstName, lastName, email, playerConfig) {
   var url = _quizCreateInstanceUrl || getBaseURL() + _instanceEndPoint;

   getInstanceFormData(quizModel, firstName, lastName, email, playerConfig).then(function(data) {
      httpService.sendFormDataPost(url, data).then(function(response) {
         if (response.DefinitionRequired) {
            return submitQuizDefinition(quizModel.quizDefinition, quizModel.quizHash, quizModel.clientId).then(function() {
               return response;
            });
         }
         return response;
      }).then(function(response) {
         _quizInstanceDataPromiseResolve({
            analyticsSampleRate: response.SampleRate,
            reportingRate: response.ReportingRate || DEFAULT_PROGRESS_INTERVAL_TIME_MILLISECONDS,
            quizInstanceId: response.Id
         });
      }).catch(_quizInstanceDataPromiseReject);
   });

   return _quizInstanceDataPromise;
};

var sendProgress = function(sampleRateInMilliseconds, completedSamples) {
   if (completedSamples && completedSamples.length > 0) {
      return _quizInstanceDataPromise.then(function(quizInstanceData) {
         var url = getBaseURL() + _progressEndPoint;
         var data = {
            InstanceId: quizInstanceData.quizInstanceId,
            Interval: sampleRateInMilliseconds,
            ViewedSegments: completedSamples
         };

         return httpServiceWithNoLogging.sendFormDataPost(url, data, 0);
      });
   }
};

var setQuizCreateInstanceUrl = function(quizCreateInstanceUrl) {
   _quizCreateInstanceUrl = quizCreateInstanceUrl;
};

var getQuizCreateInstanceUrl = function() {
   return _quizCreateInstanceUrl;
};

var submitQuestionSet = function(questionSetData) {
   return _quizInstanceDataPromise.then(function(quizInstanceData) {
      if (!quizInstanceData.quizInstanceId) {
         return Promise.reject('No quiz instance ID found');
      }

      if (typeof questionSetData === 'string') {
         questionSetData = JSON.parse(questionSetData);
      }

      var questionSetXml = quizDataXmlConverter.questionSetToXml(questionSetData, quizInstanceData.quizInstanceId);
      var url = getBaseURL() + _responseEndPoint;
      var data = {
         ResponseText: questionSetXml
      };

      return httpServiceWithUnlimitedRetries.sendFormDataPost(url, data).then(function(response) {
         return response.results;
      });
   });
};

var getAnalyticsRate = function() {
   return _quizInstanceDataPromise.then(function(response) {
      return {
         analyticsSampleRate: response.analyticsSampleRate,
         reportingRate: response.reportingRate
      };
   });
};

var abortPendingRequests = function() {
   httpService.abortPendingRequests();
   httpServiceWithUnlimitedRetries.abortPendingRequests();
   httpServiceWithNoLogging.abortPendingRequests();
   tokenService.abortPendingRequests();
};

var addEventListener = function(eventName, eventCallback) {
   httpService.addEventListener(eventName, eventCallback);
   httpServiceWithUnlimitedRetries.addEventListener(eventName, eventCallback);
   httpServiceWithNoLogging.addEventListener(eventName, eventCallback);
};

var removeEventListener = function(eventName, eventCallback) {
   httpService.removeEventListener(eventName, eventCallback);
   httpServiceWithUnlimitedRetries.removeEventListener(eventName, eventCallback);
   httpServiceWithNoLogging.removeEventListener(eventName, eventCallback);
};

export default {
   setQuizCreateInstanceUrl: setQuizCreateInstanceUrl,
   getQuizCreateInstanceUrl: getQuizCreateInstanceUrl,
   startQuizInstance: startQuizInstance,
   submitQuestionSet: submitQuestionSet,
   sendProgress: sendProgress,
   getAnalyticsRate: getAnalyticsRate,
   reset: reset,
   abortPendingRequests: abortPendingRequests,
   addEventListener: addEventListener,
   removeEventListener: removeEventListener
};
