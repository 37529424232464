import $ from 'jquery';
import deviceInfo from '../utils/device-info';
import quizService from '../services/quiz-service';
import mediaPlayer from './media-player';
import playerConfiguration from '../models/player-configuration';
import events from '../constants/events';
import DisplayModes from '../constants/display-modes';
import mediaInterfaceConstants from '../constants/media-interface-constants';
import themeController from './theme-controller';
import PluginController from './plugin-controller';
import CamtasiaQuizPreviewView from '../views/camtasia-quiz-preview-view';
import packageJson from '../../../../package.json';
import {textToTocModel} from '../utils/text-to-toc-parser';
import {deprecatedConfigGetter, deprecatedConfigSetter} from '../utils/deprecated-config';

var _started = false;
var _controls = mediaInterfaceConstants.controls.autohide;
var _authInfo = {};
var _lastError = null;
var _pluginController = null;

var addEventListener = function(eventName, callback) {
   if (eventName === events.External.AnalyticsEvent || eventName === events.External.GoogleAnalyticsCustomEvent) {
      playerConfiguration.useCustomEventTracking(true, callback);
   } else if (eventName === events.External.GoogleAnalytics4Event) {
      playerConfiguration.setGA4EventCallback(callback);
   } else {
      mediaPlayer.addEventListener(eventName, callback);
   }
};

var removeEventListener = function(eventName, callback) {
   if (eventName === events.External.AnalyticsEvent || eventName === events.External.GoogleAnalyticsCustomEvent) {
      playerConfiguration.useCustomEventTracking(false, callback);
   } else if (eventName === events.External.GoogleAnalytics4Event) {
      playerConfiguration.setGA4EventCallback(null);
   } else {
      mediaPlayer.removeEventListener(eventName, callback);
   }
};

var setAutoPlay = function(value) {
   playerConfiguration.setAutoPlayMedia(String(value).toLowerCase() === 'true');
};

var getControls = function() {
   return _controls;
};

var setControls = function(value) {
   var lowerCaseValue = String(value).toLowerCase();
   if (lowerCaseValue === mediaInterfaceConstants.controls.false) {
      _controls = mediaInterfaceConstants.controls.false;
      playerConfiguration.setDisableControls(true);
      playerConfiguration.setAutoHideControls(false);
      playerConfiguration.setDisableYouTubeControls(true);
   } else if (lowerCaseValue === mediaInterfaceConstants.controls.true) {
      _controls = mediaInterfaceConstants.controls.true;
      playerConfiguration.setDisableControls(false);
      playerConfiguration.setAutoHideControls(false);
      playerConfiguration.setDisableYouTubeControls(true);
   } else if (lowerCaseValue === mediaInterfaceConstants.controls.youtube) {
      _controls = mediaInterfaceConstants.controls.youtube;
      playerConfiguration.setDisableControls(true);
      playerConfiguration.setAutoHideControls(false);
      playerConfiguration.setDisableYouTubeControls(false);
   } else { // default 'autohide'
      _controls = mediaInterfaceConstants.controls.autohide;
      playerConfiguration.setDisableControls(false);
      playerConfiguration.setAutoHideControls(true);
      playerConfiguration.setDisableYouTubeControls(true);
   }

   mediaPlayer.triggerEvent(events.Controls.Updated);
};

var setDisableInteractions = function(disableInteractions) {
   playerConfiguration.setDisableInteractions(disableInteractions);
   mediaPlayer.triggerEvent(events.Controls.Updated);
};

var getLoop = function() {
   var endActionParam = playerConfiguration.getEndActionParam();
   return playerConfiguration.getEndActionType().toLowerCase() === 'loop' && (endActionParam === 'true' || endActionParam === '1');
};

var setLoop = function(value) {
   if (String(value).toLowerCase() === 'true') {
      playerConfiguration.setEndActionType('loop');
      playerConfiguration.setEndActionParam('true');
   } else {
      playerConfiguration.setEndActionType('stop');
      playerConfiguration.setEndActionParam('true');
   }
};

var getError = function() {
   return _lastError;
};

var setMuted = function(value) {
   mediaPlayer.setMuted(String(value).toLowerCase() === 'true');
};

var getContextMenuEnabled = function() {
   return !playerConfiguration.getDisableContextMenuForVideo();
};

var setContextMenuEnabled = function(value) {
   playerConfiguration.setDisableContextMenuForVideo(!value);
};

var getQuizCreateInstanceUrl = function() {
   return quizService.getQuizCreateInstanceUrl();
};

var setQuizCreateInstanceUrl = function(value) {
   quizService.setQuizCreateInstanceUrl(value);
};

var getAuthInfo = function() {
   return _authInfo;
};
var setAuthInfo = function(value) {
   if (typeof value === 'object') {
      _authInfo = $.extend(_authInfo, value);
   }
};

var addMediaSrc = function(src) {
   playerConfiguration.addMediaSrc(src);
};

var generalErrorHandler = function(event) {
   _lastError = event.data;
};

var run = function(containerId) {
   if (_started) {
      return;
   }

   deviceInfo.lookAtDevice();

   if (_authInfo.accessTokenEndpoint) {
      playerConfiguration.setTechSmithAccessTokenEndpoint(_authInfo.accessTokenEndpoint);
   }

   playerConfiguration.setDisableFullframeMode(true);
   playerConfiguration.setFullViewPort(false);

   mediaPlayer.init('#' + containerId);

   addEventListener('error', generalErrorHandler);

   _started = true;
};

var destroy = function() {
   if (!_started) {
      return;
   }
   var $spElement = mediaPlayer.getSmartPlayerRootElement();
   removeEventListener('error', generalErrorHandler);
   mediaPlayer.destroy();

   if ($spElement) {
      $spElement.removeClass();
      $spElement.empty();
   }

   playerConfiguration.clearConfiguration();

   _started = false;
   _controls = mediaInterfaceConstants.controls.autohide;
   _authInfo = {};
   _lastError = null;
   _pluginController = null;
};

var setTheme = function(themeName) {
   themeController.setTheme(themeName);
};

var setupPluginController = function() {
   _pluginController = PluginController.create();
   playerConfiguration.setPluginController(_pluginController);
};

var registerPlugin = function(pluginName, viewElementType) {
   if (!_pluginController) {
      setupPluginController();
   }
   var pluginInitPromise = _pluginController.registerPlugin(pluginName, viewElementType);
   mediaPlayer.triggerEvent(events.Plugin.Added, {viewElementType: viewElementType});
   return pluginInitPromise;
};

var displayQuizPreview = function(containerElement, xmpString) {
   var $container = $(containerElement);
   CamtasiaQuizPreviewView.create($container, xmpString);
};

var displayImageFullscreen = function() {
   mediaPlayer.triggerEvent(events.External.SwitchToFullscreen);
};

var exitImageFullscreen = function() {
   mediaPlayer.triggerEvent(events.External.ExitFullscreen);
};

var createTocFromDescription = function(descriptionText) {
   if (descriptionText && typeof descriptionText === 'string') {
      const tocModel = textToTocModel(decodeURIComponent(descriptionText));
      if (tocModel) {
         playerConfiguration.tocModelFromDescription = tocModel;
      }
   }
};

export default Object.defineProperties({
   play: mediaPlayer.play,
   pause: mediaPlayer.pause,
   addEventListener: addEventListener,
   removeEventListener: removeEventListener,
   addSrc: addMediaSrc,
   addCaptionSrc: playerConfiguration.addCaptionSrc,
   run: run,
   destroy: destroy,
   setTheme: setTheme,
   registerPlugin: registerPlugin,
   displayQuizPreview: displayQuizPreview,
   displayImageFullscreen: displayImageFullscreen,
   exitImageFullscreen: exitImageFullscreen,
   getSafeSeekTime: mediaPlayer.getSafeSeekTime,
   createTocFromDescription: createTocFromDescription
}, {
   version: {
      get: function() {
         return packageJson.version;
      }
   },
   autoplay: {
      get: playerConfiguration.getAutoPlayMedia,
      set: setAutoPlay
   },
   controls: {
      get: getControls,
      set: setControls
   },
   disableInteractions: {
      get: playerConfiguration.getDisableInteractions,
      set: setDisableInteractions
   },
   currentSrc: {
      get: mediaPlayer.getCurrentSrc
   },
   currentTime: {
      get: mediaPlayer.getCurrentTime,
      set: mediaPlayer.setCurrentTime
   },
   duration: {
      get: mediaPlayer.getDuration
   },
   error: {
      get: getError
   },
   ended: {
      get: mediaPlayer.getEnded
   },
   loop: {
      get: getLoop,
      set: setLoop
   },
   muted: {
      get: mediaPlayer.getMuted,
      set: setMuted
   },
   paused: {
      get: mediaPlayer.getPaused
   },
   playbackRate: {
      get: mediaPlayer.getPlaybackRate,
      set: mediaPlayer.setPlaybackRate
   },
   buffered: {
      get: mediaPlayer.getBuffered
   },
   played: {
      get: mediaPlayer.getPlayed
   },
   seekable: {
      get: mediaPlayer.getSeekable
   },
   preload: {
      get: playerConfiguration.getPreload,
      set: playerConfiguration.setPreload
   },
   seeking: {
      get: mediaPlayer.getSeeking
   },
   volume: {
      get: mediaPlayer.getVolume,
      set: mediaPlayer.setVolume
   },
   src: {
      get: playerConfiguration.getMediaSrc,
      set: playerConfiguration.setMediaSrc
   },
   // these are the SP extended interface options
   posterSrc: {
      get: playerConfiguration.getPosterImageSrc,
      set: playerConfiguration.setPosterImageSrc
   },
   xmpSrc: {
      get: playerConfiguration.getXMPSrc,
      set: playerConfiguration.setXMPSrc
   },
   tocSrc: {
      get: playerConfiguration.getTOCSpriteSrc,
      set: playerConfiguration.setTOCSpriteSrc
   },
   mediaTitle: {
      get: playerConfiguration.getMediaTitle,
      set: playerConfiguration.setMediaTitle
   },
   tocTitle: {
      get: playerConfiguration.getTOCTitle,
      set: playerConfiguration.setTOCTitle
   },
   tocChapters: {
      get: mediaPlayer.getTocChapters
   },
   prerollSrc: {
      get: playerConfiguration.getPreRollSrc,
      set: playerConfiguration.setPreRollSrc
   },
   audioDescriptionMediaSrc: {
      get: playerConfiguration.getAudioDescriptionMediaSrc,
      set: playerConfiguration.setAudioDescriptionMediaSrc
   },
   quizId: {
      get: playerConfiguration.getFathomId,
      set: playerConfiguration.setFathomId
   },
   quizCreateInstanceUrl: {
      get: getQuizCreateInstanceUrl,
      set: setQuizCreateInstanceUrl
   },
   background: {
      get: playerConfiguration.getBackgroundColor,
      set: playerConfiguration.setBackgroundColor
   },
   captions: {
      get: mediaPlayer.getCaptionCollection
   },
   captionTrack: {
      get: mediaPlayer.getCaptionTrack,
      set: mediaPlayer.setCaptionTrack
   },
   captionsVisible: {
      get: mediaPlayer.getCaptionsVisible,
      set: mediaPlayer.setCaptionsVisible
   },
   ignoreStoredCaptionVisible: {
      get: playerConfiguration.getIgnoreStoredCaptionVisibleSetting,
      set: playerConfiguration.setIgnoreStoredCaptionVisibleSetting
   },
   rotation: {
      get: playerConfiguration.getMediaRotation,
      set: playerConfiguration.setMediaRotation
   },
   scrubbing: {
      get: mediaPlayer.getScrubbing,
      set: mediaPlayer.setScrubbing
   },
   isYouTube: {
      get: mediaPlayer.getIsYouTube
   },
   isInFullScreenMode: {
      get: mediaPlayer.isInFullScreenMode
   },
   authInfo: {
      get: getAuthInfo,
      set: setAuthInfo
   },
   // debug layer flags
   contextMenu: {
      get: getContextMenuEnabled,
      set: setContextMenuEnabled
   },
   isIphoneOrIpod: {
      get: deviceInfo.isIPhoneOrIPod
   },
   endActionType: {
      get: playerConfiguration.getEndActionType,
      set: playerConfiguration.setEndActionType
   },
   endActionParam: {
      get: playerConfiguration.getEndActionParam,
      set: playerConfiguration.setEndActionParam
   },
   ignoreXmpControllerParameters: {
      get: playerConfiguration.getIgnoreXmpControllerParameters,
      set: playerConfiguration.setIgnoreXmpControllerParameters
   },
   ignoreQuizQuestions: {
      get: playerConfiguration.getIgnoreQuizQuestions,
      set: playerConfiguration.setIgnoreQuizQuestions
   },
   singleClickControlEnabled: {
      get: playerConfiguration.getEnableSingleClickControl,
      set: playerConfiguration.setEnableSingleClickControl
   },
   doubleClickControlEnabled: {
      get: playerConfiguration.getEnableDoubleClickControl,
      set: playerConfiguration.setEnableDoubleClickControl
   },
   useLargerProgressBar: {
      get: playerConfiguration.getUseLargerProgressBar,
      set: playerConfiguration.setUseLargerProgressBar
   },
   displayHotspotsShape: {
      get: playerConfiguration.getDisplayHotspotsShape,
      set: playerConfiguration.setDisplayHotspotsShape
   },
   disablePictureInPicture: {
      get: playerConfiguration.getDisablePictureInPicture,
      set: playerConfiguration.setDisablePictureInPicture
   },
   contentBase: {
      get: playerConfiguration.getContentBase,
      set: playerConfiguration.setContentBase
   },
   skipVideoControlsEnabled: {
      get: playerConfiguration.getSkipVideoControlsEnabled,
      set: playerConfiguration.setSkipVideoControlsEnabled
   },
   skipVideoDuration: {
      get: playerConfiguration.getSkipVideoDuration,
      set: playerConfiguration.setSkipVideoDuration
   },
   allowFastForward: {
      get: playerConfiguration.getAllowFastForward,
      set: playerConfiguration.setAllowFastForward
   },
   showADACompliantCaptions: {
      get: playerConfiguration.getShowADACompliantCaptions,
      set: playerConfiguration.setShowADACompliantCaptions
   },
   rememberUserInfo: {
      get: playerConfiguration.getRememberUserInfo,
      set: playerConfiguration.setRememberUserInfo
   },
   userInfo: {
      get: playerConfiguration.getUserInfo,
      set: playerConfiguration.setUserInfo
   },
   userSignedIn: {
      get: playerConfiguration.getUserSignedIn,
      set: playerConfiguration.setUserSignedIn
   },
   disableContinueWithoutQuestions: {
      get: playerConfiguration.getDisableContinueWithoutQuestions,
      set: playerConfiguration.setDisableContinueWithoutQuestions
   },
   logScormApiCalls: {
      get: playerConfiguration.getLogScormApiCalls,
      set: playerConfiguration.setLogScormApiCalls
   },
   videoThumbnailsSrc: {
      get: playerConfiguration.getVideoThumbnailSrc,
      set: playerConfiguration.setVideoThumbnailSrc
   },
   videoThumbnailWidth: {
      get: playerConfiguration.getVideoThumbnailWidth,
      set: playerConfiguration.setVideoThumbnailWidth
   },
   videoThumbnailHeight: {
      get: playerConfiguration.getVideoThumbnailHeight,
      set: playerConfiguration.setVideoThumbnailHeight
   },
   videoThumbnailInterval: {
      get: playerConfiguration.getVideoThumbnailInterval,
      set: playerConfiguration.setVideoThumbnailInterval
   },
   videoThumbnailColumns: {
      get: playerConfiguration.getVideoThumbnailColumns,
      set: playerConfiguration.setVideoThumbnailColumns
   },
   completeScormOnRequirementsComplete: {
      get: playerConfiguration.getCompleteScormOnRequirementsComplete,
      set: playerConfiguration.setCompleteScormOnRequirementsComplete
   },
   progressBarWidth: {
      get: mediaPlayer.getProgressBarDisplayWidth
   },
   quizServiceBaseUrl: {
      get: playerConfiguration.getQuizServiceBaseUrl,
      set: playerConfiguration.setQuizServiceBaseUrl
   },
   resultsServiceBaseUrl: {
      get: playerConfiguration.getResultsServiceBaseUrl,
      set: playerConfiguration.setResultsServiceBaseUrl
   },
   customFullScreenDomElement: {
      get: playerConfiguration.getCustomFullScreenDomElement,
      set: playerConfiguration.setCustomFullScreenDomElement
   },
   enableTheaterMode: {
      get: playerConfiguration.getEnableTheaterMode,
      set: playerConfiguration.setEnableTheaterMode
   },
   currentDisplayMode: {
      get: function() {
         return mediaPlayer.getCurrentDisplayMode() || DisplayModes.Normal;
      }
   },
   ga4EventCallback: {
      get: playerConfiguration.getGA4EventCallback,
      set: playerConfiguration.setGA4EventCallback
   },
   showAcknowledgementLink: {
      get: playerConfiguration.getShowAcknowledgementLink,
      set: playerConfiguration.setShowAcknowledgementLink
   },
   // deprecated
   environment: {
      get: () => {
         return deprecatedConfigGetter('mediaInterface.environment', 0);
      },
      set: () => {
         deprecatedConfigSetter('mediaInterface.environment');
      }
   }
});
