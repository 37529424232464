import playerConfiguration from '../../models/player-configuration';
import MessageBarView from '.././message-bar-view';
import messageBarType from '../../constants/message-bar-type';
import YouTubePlayer from '../../controllers/youtube-plugin';
import Promise from 'promise-polyfill';
import urlService from '../../services/url-service';
import playerControlsType from '../../constants/player-controls-type';
import mediaViewType from '../../constants/media-view-type';
import events from '../../constants/events';
import cssClasses from '../../constants/css-classes';

/**
 *  @memberof TSC
 *  @implement MediaView
 *  @class YoutubeElementView
 *  @classdesc Youtube Video element view
 *  @param {jQuery} $rootContainer - Smart Player top container jquery element
 *  @param {jQuery} $mediaContainer - Smart Player media container jquery element
 *  @param {string} containerElementUniqueId - Smart Player main container id
 *  @param {Array} mediaSources - html5 media DOM element (or wrapper presenting same interface)
 *  @param {Function} firstPlayCallback - callback function called on the first play click
 */
var YoutubeElementView = function($rootContainer, $mediaContainer, containerElementUniqueId, mediaSources, firstPlayCallback) {
   var _youtubePlayer;
   var _errorMessageBarView;
   var _$youtubePointerEventWorkaround = $mediaContainer.find('.youtube-pointer-event-workaround');

   var addHideBubbleClass = function() {
      $rootContainer.addClass(cssClasses.hideBubbleArrow);
   };

   var removeHideBubbleClass = function() {
      $rootContainer.removeClass(cssClasses.hideBubbleArrow);
   };

   // need to first MediaControlsReady event when using YouTube controls
   var internalFirstPlayCallback = function() {
      firstPlayCallback && firstPlayCallback();
   };

   var onControlsHidden = function() {
      if (playerConfiguration.getDisableYouTubeControls()) {
         _$youtubePointerEventWorkaround.removeClass(cssClasses.hide);
      }
   };

   var onControlsVisible = function() {
      _$youtubePointerEventWorkaround.addClass(cssClasses.hide);
   };

   var processVideoEnd = function() {
      return true;
   };

   var reshowVideoAfterClickToReplay = function() {
      // do nothing
   };

   var bindToEvents = function() {
      $rootContainer.on(events.Controls.VideoControlsVisible, onControlsVisible);
      $rootContainer.on(events.Controls.VideoControlsHidden, onControlsHidden);
   };

   var unbindToEvents = function() {
      $rootContainer.off(events.Controls.VideoControlsVisible, onControlsVisible);
      $rootContainer.off(events.Controls.VideoControlsHidden, onControlsHidden);
   };

   var destroy = function() {
      if (_youtubePlayer) {
         _youtubePlayer.destroy();
      }
      removeHideBubbleClass();
      _errorMessageBarView && _errorMessageBarView.remove();
      unbindToEvents();
   };

   var showCaptionTrack = function() {
      // our captions are not shown over youtube videos
   };

   var hideCaptionTrack = function() {
      // our captions are not shown over youtube videos
   };

   var addEventListener = function(eventName, handler) {
      _youtubePlayer.addEventListener(eventName, handler);
   };

   var removeEventListener = function(eventName, handler) {
      _youtubePlayer.removeEventListener(eventName, handler);
   };

   var onError = function(event) {
      _errorMessageBarView = MessageBarView.create($rootContainer, messageBarType.info, event.data);
   };

   var hotfixViewAndControlsCSS = function($controlsView) {
      if (playerConfiguration.getDisableControls() || playerConfiguration.getDisableInteractions()) {
         // Quiz location logic needs the controls to have reasonable width and height.
         // But in the case when we are using YouTube controls we want the controls hidden
         // so they can't be interacted with. By pushing it behind the player with z-index
         // it accomplishes "hiding" the controls but still allowing the controls to take
         // up the correct width and height for the quiz marker location logic. Using
         // _$controls.hide() does not accomplish the same affect and that is why it is
         // avoided here.
         $controlsView.css('z-index', '-1');
      } else {
         _youtubePlayer.css('height', 'calc(100% - ' + $controlsView.height() + 'px)');
      }
   };

   addHideBubbleClass();

   var startTime = playerConfiguration.getJumpToTime();
   if (startTime !== undefined) {
      startTime = urlService.convertUrlTime(startTime);
   } else {
      startTime = 0;
   }

   var youtubeElementViewObject = Object.defineProperties({
      hotfixViewAndControlsCSS: hotfixViewAndControlsCSS,
      showCaptionTrack: showCaptionTrack,
      hideCaptionTrack: hideCaptionTrack,
      addEventListener: addEventListener,
      removeEventListener: removeEventListener,
      reshowVideoAfterClickToReplay: reshowVideoAfterClickToReplay,
      processVideoEnd: processVideoEnd,
      loadMedia: function() {
         _youtubePlayer.load();
      },
      destroy: destroy
   }, {
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.YoutubeElementView
       *  @property {jQuery} mediaWrapper - jQuery wrapper of the video element.
       */
      mediaWrapper: {
         get: function() {
            return _youtubePlayer;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.YoutubeElementView
       *  @property {HTMLVideoElement} mediaElement - HTML video element
       */
      mediaElement: {
         get: function() {
            return _youtubePlayer;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.YoutubeElementView
       *  @property {Boolean} containsWebTrackCaptions - Does the video contain web track captions?
       */
      containsWebTrackCaptions: {
         get: function() {
            return false;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.YoutubeElementView
       *  @property {int} currentTimeInMilliseconds - The current time of the media, in milliseconds
       */
      currentTimeInMilliseconds: {
         get: function() {
            var mediaElement = this.mediaElement;
            var currentTimeInSeconds = mediaElement.currentTime > mediaElement.duration ? mediaElement.duration : mediaElement.currentTime;
            return currentTimeInSeconds ? currentTimeInSeconds * 1000 : 0;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.YoutubeElementView
       *  @property {Number} mediaWidth - video width
       */
      mediaWidth: {
         get: function() {
            return _youtubePlayer.width;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.YoutubeElementView
       *  @property {Number} mediaHeight - video height
       */
      mediaHeight: {
         get: function() {
            return _youtubePlayer.height;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.YoutubeElementView
       *  @property {Boolean}  - allows dragging extension content
       */
      canDragContentOnVideo: {
         get: function() {
            return false;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.YoutubeElementView
       *  @property {string} playerControlType - Type of player controls to use
       */
      playerControlType: {
         get: function() {
            return playerControlsType.video;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.YoutubeElementView
       *  @property {string} type - Media view type
       */
      type: {
         get: function() {
            return mediaViewType.youtube;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.YoutubeElementView
       *  @property {boolean} supportsClickToPlayControls - Does view support click to play controls over media
       */
      supportsClickToPlayControls: {
         get: function() {
            return false;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.YoutubeElementView
       *  @property {boolean} supportsSCORM - Does view support SCORM
       */
      supportsSCORM: {
         get: function() {
            return false;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.YoutubeElementView
       *  @property {TSC.VideoPlaybackQualityMetrics} playbackQualityMetrics - the playback quality metrics set up for this view (may be null)
       */
      playbackQualityMetrics: {
         get: function() {
            return null;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.YoutubeElementView
       *  @property {string} currentSrc - The current source path of the media
       */
      currentSrc: {
         get: function() {
            return _youtubePlayer.currentSrc;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.YoutubeElementView
       *  @property {Number} currentTime - The current time for the media
       */
      currentTime: {
         get: function() {
            return _youtubePlayer.currentTime;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.YoutubeElementView
       *  @property {Number} duration - The duration of the media
       */
      duration: {
         get: function() {
            return _youtubePlayer.duration;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.YoutubeElementView
       *  @property {Boolean} ended - Has the media ended
       */
      ended: {
         get: function() {
            return _youtubePlayer.ended;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.YoutubeElementView
       *  @property {Boolean} paused - Is the media paused
       */
      paused: {
         get: function() {
            return _youtubePlayer.paused;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.YoutubeElementView
       *  @property {Boolean} seeking - Is the media seeking
       */
      seeking: {
         get: function() {
            return false;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.YoutubeElementView
       *  @property {Number} volume - The volume of the media
       */
      volume: {
         get: function() {
            return _youtubePlayer.volume;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.YoutubeElementView
       *  @property {Boolean} muted - Is the media muted
       */
      muted: {
         get: function() {
            return _youtubePlayer.muted;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.YoutubeElementView
       *  @property {Number} playbackRate - Playback rate of the media
       */
      playbackRate: {
         get: function() {
            return _youtubePlayer.playbackRate;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.YoutubeElementView
       *  @property {Array} buffered - THe array of buffered segments
       */
      buffered: {
         get: function() {
            return _youtubePlayer.buffered;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.YoutubeElementView
       *  @property {Array} played - THe array of media segments that have been played
       */
      played: {
         get: function() {
            return _youtubePlayer.played;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.YoutubeElementView
       *  @property {Array} seekable - THe array of media segments that are seekable
       */
      seekable: {
         get: function() {
            return _youtubePlayer.seekable;
         }
      }
   });

   YouTubePlayer.addApi();

   return new Promise(function(resolve, reject) {
      try {
         var disableFullScreen = $rootContainer.playerState.mediaHasQuizQuestions;
         if (window.onYouTubeIframeAPIReady.ready) {
            _youtubePlayer = new YouTubePlayer.create(mediaSources[0].uri, containerElementUniqueId, internalFirstPlayCallback, onError, $mediaContainer.width(), $mediaContainer.height(), startTime, disableFullScreen);
            bindToEvents();
            $rootContainer.trigger(events.Controls.HideIOOverlay);
            resolve(youtubeElementViewObject);
         } else {
            window.onYouTubeIframeAPIReady.queued.push(function() {
               _youtubePlayer = new YouTubePlayer.create(mediaSources[0].uri, containerElementUniqueId, internalFirstPlayCallback, onError, $mediaContainer.width(), $mediaContainer.height(), startTime, disableFullScreen);
               bindToEvents();
               $rootContainer.trigger(events.Controls.HideIOOverlay);
               resolve(youtubeElementViewObject);
            });
         }
      } catch (_) {
         reject(new Error('unhandled error creating youtube element view'));
      }
   });
};

export default {
   create: YoutubeElementView
};
