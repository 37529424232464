const videoViewTemplate = ({videoTagUniqueId}) => `
   <div class="audio-description-track-wrapper"></div>
   <div class="media-container">
      <div id="${videoTagUniqueId}" class="video-div"></div>
      <div class="youtube-pointer-event-workaround hide"></div>
      <div class="hotspots-container"></div>
      <div class="media-plugin-container"></div>
      <div class="play-pause-state">
         <div class="play-state-indicator-container">
            <div class="ui play-pause-state-indicator"></div>
         </div>
      </div>
   </div>
   <div class="caption hide">
      <p class="hide"></p>
   </div>
`;

export default videoViewTemplate;
