const questionSetReviewConfirmationTemplate = ({title, alertText, numberCorrect, percentCorrect, numberInCorrect, percentInCorrect, reviewAnswerText, continueText}) => `<div class="review-confirmation-container" role="alertdialog" aria-label="${title}" aria-describedby="questionSetReviewConfirmationAlert">
   ${alertText ? ` <p id="questionSetReviewConfirmationAlert" class="sr-only alert-only">${alertText}</p>` : ''}
   ${numberCorrect ? `<div class="results-summary">
           <div class="results-container correct-result"><span class="result-label">${numberCorrect}</span> <span class="calculated-percentage">${percentCorrect}</span></div>
           <div class="results-container incorrect-result"><span class="result-label">${numberInCorrect}</span> <span class="calculated-percentage">${percentInCorrect}</span></div>
       </div>` : ''}
    <div class="controls-container">
        <button type="button" class="review-answers-button button button-secondary fill-parent top-button-element">${reviewAnswerText}</button>
        <button type="button" class="continue-video-button button button-primary fill-parent">${continueText}</button>
    </div>
    <div class="chat-bubble-arrow"></div>
</div>`;

export default questionSetReviewConfirmationTemplate;
