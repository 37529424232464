import localizationStrings from '../models/localization-strings';
import languageCodes from './language-codes';

var _keyTypes = Object.freeze({
   escape: 1,
   space: 2,
   leftArrow: 3,
   upArrow: 4,
   rightArrow: 5,
   downArrow: 6,
   tab: 7,
   enter: 8,
   tableOfContents: 9
});

var isKeyPassedOfSameType = function(key, type) {
   switch (key.toLowerCase()) {
      case 'esc': // IE11 and Edge
      case 'escape':
         return type === _keyTypes.escape;
      case 'spacebar': // IE11
      case ' ':
         return type === _keyTypes.space;
      case 'left': // IE11 and Edge
      case 'arrowleft':
         return type === _keyTypes.leftArrow;
      case 'up': // IE11 and Edge
      case 'arrowup':
         return type === _keyTypes.upArrow;
      case 'right': // IE11 and Edge
      case 'arrowright':
         return type === _keyTypes.rightArrow;
      case 'down': // IE11 and Edge
      case 'arrowdown':
         return type === _keyTypes.downArrow;
      case 'tab':
         return type === _keyTypes.tab;
      case 'enter':
         return type === _keyTypes.enter;
      default:
         return isKeyOfLanguageSpecificType(key.toLowerCase(), type);
   }
};

var isKeyOfLanguageSpecificType = function(key, type) {
   switch (localizationStrings.getLanguage()) {
      case languageCodes.ENGLISH:
         return isKeyOfEnglishSpecificType(key, type);
      case languageCodes.GERMAN:
         return isKeyOfGermanSpecificType(key, type);
      case languageCodes.JAPANESE:
         return isKeyOfJapaneseSpecificType(key, type);
      default:
         return false;
   }
};

var isKeyOfEnglishSpecificType = function(key, type) {
   switch (key) {
      case 't':
         return type === _keyTypes.tableOfContents;
      default:
         return false;
   }
};

var isKeyOfGermanSpecificType = function(key, type) {
   switch (key) {
      case 'i':
         return type === _keyTypes.tableOfContents;
      default:
         return false;
   }
};

var isKeyOfJapaneseSpecificType = function(key, type) {
   switch (key) {
      case 't':
         return type === _keyTypes.tableOfContents;
      default:
         return false;
   }
};

var isKeyOfType = function(key) {
   for (var i = 1; i < arguments.length; ++i) {
      if (isKeyPassedOfSameType(key, arguments[i])) {
         return true;
      }
   }
   return false;
};

var keys = Object.defineProperties({
   isKeyOfType: isKeyOfType
}, {
   keyTypes: {
      get: function() {
         return _keyTypes;
      }
   }
});

export default keys;
