import Promise from 'promise-polyfill';

/**
 * @memberof TSC
 * @class Plugin
 * @classdesc Plugin Object
 * @param {String} pluginName - name of plugin
 * @param {String} viewElementType - view element type to install plugin into
 */
var Plugin = function(pluginName, viewElementType) {
   var _initialized = false;
   var _initializedPromiseResolve;
   var _initializedPromise = new Promise(function(resolve) {
      _initializedPromiseResolve = resolve;
   });
   var _pluginName = pluginName;
   var _viewElementType = viewElementType;

   /**
    * @function init
    * @memberOf TSC.Plugin
    * Initialize plugin with dom element container that will resolve initialized promise.
    * @param {HTMLElement} pluginContainerElement - dom element that will be the container for plugin UI
    */
   var init = function(pluginContainerElement) {
      if (_initialized) {
         return;
      }
      _initialized = true;
      _initializedPromiseResolve({
         pluginContainerElement: pluginContainerElement
      });
   };

   return Object.defineProperties({
      init: init
   }, {
      initialized: {
         get: function() {
            return _initialized;
         }
      },
      initializedPromise: {
         get: function() {
            return _initializedPromise;
         }
      },
      pluginName: {
         get: function() {
            return _pluginName;
         }
      },
      viewElementType: {
         get: function() {
            return _viewElementType;
         }
      }
   });
};

export default {
   /**
    *  Factory method that returns a new Plugin object.
    *  @function create
    *  @memberof TSC.Plugin
    *  @static
    *  @return new Plugin instance
    */
   create: Plugin
};
