const shortAnswerTemplate = ({questionText, inputId, inputLabel, inputName, useAutofocus, userResponse, focusableAnswerInput}) => `<form onsubmit="return false;">
   <fieldset>
      <legend class="sr-only">${questionText}</legend>
      <label for="${inputId}" class="sr-only">${inputLabel}</label>
      <div class="fill-in-and-short-answer-answer-container">
         <textarea name="${inputName}" id="${inputId}" class="short-answer-input ${focusableAnswerInput}" maxlength="2000" rows="5" ${useAutofocus ? 'autofocus' : ''}>${userResponse ? userResponse : ''}</textarea>
      </div>
   </fieldset>
</form>`;

export default shortAnswerTemplate;
