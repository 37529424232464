import languageCodes from '../../constants/language-codes';
import playerStringNames from '../../constants/player-string-names';

var strings = {};
strings[playerStringNames.xmpError] = 'Parece que hay problemas para acceder a determinadas funciones de este vídeo. Póngase en contacto con el autor.';
strings[playerStringNames.xmpSecurity] = 'Actualmente determinadas funciones del vídeo están desactivadas debido a la configuración de seguridad de la red local. Funcionarán correctamente cuando el vídeo se reproduzca desde un sitio web o un servidor.';
strings[playerStringNames.videoNotSupported] = 'Su explorador no puede reproducir este vídeo.';
strings[playerStringNames.noWebMSupport] = 'Este explorador no admite vídeo WebM.';
strings[playerStringNames.search] = 'Buscar';
strings[playerStringNames.searchPlaceholder] = 'Buscar...';
strings[playerStringNames.playerRateText] = 'Velocidad de reproducción';
strings[playerStringNames.playerRateOptionsText] = {
   0.25: '0,25',
   0.5: '0,5',
   0.75: '0,75',
   1: 'normal',
   1.25: '1,25',
   1.5: '1,5',
   1.75: '1,75',
   2.0: '2,0'
};
strings[playerStringNames.ccLanguageText] = 'Subtítulos';
strings[playerStringNames.searchDisabledFullScreen] = 'La función Buscar está desactivada en el modo de pantalla completa.';
strings[playerStringNames.mediaNotFound] = 'Lo lamentamos, pero no hemos podido conectarnos a este contenido. Pruebe a actualizar la página o <a href=\'https://www.techsmith.com/redirect.asp?target=media_not_found&ver=4.*.*&lang=enu&os=na&product=smartplayer\' target=\'_blank\'> pulse aquí para obtener ayuda.</a>';
strings[playerStringNames.videoLoading] = 'El vídeo se está cargando.';
strings[playerStringNames.close] = 'Cerrar';
strings[playerStringNames.txtRequiredMessage] = 'El propietario del contenido solicita lo siguiente:';
strings[playerStringNames.txtSkip] = 'Continuar sin preguntas';
strings[playerStringNames.txtBegin] = 'Enviar y continuar';
strings[playerStringNames.txtEnterFirstName] = 'Introduzca su nombre';
strings[playerStringNames.txtEnterLastName] = 'Introduzca sus apellidos';
strings[playerStringNames.txtEnterEmail] = 'Introduzca su dirección de correo electrónico';
strings[playerStringNames.txtHotspot] = 'Zona activa';
strings[playerStringNames.txtHotspotScreenReaderAlert] = '{{numberOfHotspots}} zona activa nueva';
strings[playerStringNames.txtHotspotScreenReaderAlertPlural] = '{{numberOfHotspots}} zonas activas nuevas';
strings[playerStringNames.txtJumpToMedia] = 'Saltar al medio';
strings[playerStringNames.txtJumpToTime] = 'Saltar al tiempo';
strings[playerStringNames.txtOpenLink] = 'Abrir vínculo';
strings[playerStringNames.txtOpenLinkHeader] = 'Este vídeo quiere enviarte a:';
strings[playerStringNames.txtOpenLinkSubHeader] = '';
strings[playerStringNames.txtReview] = 'Reproducir última sección';
strings[playerStringNames.txtReplayVideo] = 'Volver a ver';
strings[playerStringNames.txtAnswerQuestion] = 'Responder al cuestionario ahora';
strings[playerStringNames.txtPrev] = 'Anterior';
strings[playerStringNames.txtNext] = 'Siguiente';
strings[playerStringNames.txtHideQuiz] = 'Ocultar';
strings[playerStringNames.txtSubmit] = 'Enviar respuestas';
strings[playerStringNames.txtSubmittingAnswers] = 'Enviando respuestas.';
strings[playerStringNames.txtContinue] = 'Continuar';
strings[playerStringNames.txtReviewAnswer] = 'Ver respuestas';
strings[playerStringNames.txtBadImageQuiz] = 'Lo lamentamos, el cuestionario solicitado no admite imágenes y no se puede cargar. Póngase en contacto con el autor de este cuestionario.';
strings[playerStringNames.txtVideoQuizNetworkErrorMessage] = 'Lo lamentamos, un error de red ha impedido la carga del cuestionario. El vídeo sí está disponible para su visualización. Para intentar volver a cargar el cuestionario, actualice la página del navegador o compruebe la conexión de red.';
strings[playerStringNames.txtImageQuizNetworkErrorMessage] = 'Lo lamentamos, un error de red ha impedido la carga del cuestionario. La imagen sí está disponible para su visualización. Para intentar volver a cargar el cuestionario, actualice la página del navegador o compruebe la conexión de red.';
strings[playerStringNames.txtQuizErrorMessage] = 'Estamos intentando guardar su respuesta. Verifique que tiene conexión a Internet.';
strings[playerStringNames.txtQuizOutOfDateMessage] = 'Las preguntas del cuestionario han cambiado. Actualiza el reproductor para ver el cuestionario más reciente.';
strings[playerStringNames.txtQuizBadDefinitionMessage] = 'Cuestionario no válido. No es posible enviar los comentarios del cuestionario. Refiere al propietario del vídeo a <a href="https://www.techsmith.com/redirect.asp?target=bad_quiz_definition&ver=5.*.*&lang=enu&os=na&product=smartplayer" target="_blank">este artículo de ayuda</a>.';
strings[playerStringNames.txtQuizOutOfDateRefreshButtonText] = 'Actualizar el reproductor';
strings[playerStringNames.txtLoaderMessageDefinition] = 'El cuestionario se está cargando.';
strings[playerStringNames.txtQuestionCount] = 'Pregunta {{currentQuestion}} de {{totalQuestions}}';
strings[playerStringNames.txtResults] = 'Resultados: ';
strings[playerStringNames.txtCorrectString] = 'Correcto';
strings[playerStringNames.txtIncorrectString] = 'Incorrecto';
strings[playerStringNames.txtCorrect] = '{{numberCorrect}} respuestas correctas';
strings[playerStringNames.txtIncorrect] = '{{numberIncorrect}} respuestas incorrectas';
strings[playerStringNames.txtYourAnswer] = 'Su respuesta: ';
strings[playerStringNames.txtCorrectAnswer] = 'Respuesta correcta: ';
strings[playerStringNames.txtVisitSite] = 'Visitar el sitio';
strings[playerStringNames.accessBtnAudioDescription] = 'Descripción de audio';
strings[playerStringNames.accessBtnClearSearch] = 'Borrar búsqueda';
strings[playerStringNames.accessBtnRewind] = 'Retroceder {{duration}} segundos';
strings[playerStringNames.accessBtnForward] = 'Avanzar {{duration}} segundos';
strings[playerStringNames.accessBtnPlay] = 'Reproducir vídeo';
strings[playerStringNames.accessBtnPrevious] = 'Anterior';
strings[playerStringNames.accessBtnNext] = 'Siguiente';
strings[playerStringNames.accessBtnProgressBar] = 'Control de barra de progreso';
strings[playerStringNames.accessBtnVolume] = 'Silencio';
strings[playerStringNames.accessTxtVolume] = 'Volumen';
strings[playerStringNames.accessBtnClosedCaption] = 'Subtítulo';
strings[playerStringNames.accessBtnTableOfContents] = 'Mostrar capítulos (T)';
strings[playerStringNames.accessBtnSettings] = 'Ajustes del reproductor';
strings[playerStringNames.accessBtnPip] = 'Imagen dentro de imagen';
strings[playerStringNames.accessBtnFullScreen] = 'Pantalla completa';
strings[playerStringNames.accessBtnFullScreenExpand] = 'Abrir en una nueva pestaña';
strings[playerStringNames.accessBtnTakeQuiz] = 'Pulse para responder al cuestionario.';
strings[playerStringNames.accessBtnShowQuiz] = 'Mostrar cuestionario';
strings[playerStringNames.accessBtnHideQuiz] = 'Ocultar cuestionario';
strings[playerStringNames.accessBtnPreviousQuestion] = 'Pregunta anterior';
strings[playerStringNames.accessBtnNextQuestion] = 'Pregunta siguiente';
strings[playerStringNames.accessQuizHidden] = 'El cuestionario se ha ocultado';
strings[playerStringNames.accessQuizShown] = 'El cuestionario se muestra ahora';
strings[playerStringNames.accessBtnShowAnswer] = 'Mostrar respuestas';
strings[playerStringNames.accessBtnHideAnswer] = 'Ocultar respuestas';
strings[playerStringNames.accessBtnAnswersSubmitted] = '¡Respuestas enviadas!';
strings[playerStringNames.accessBtnWereNotGraded] = ' no han sido calificadas.';
strings[playerStringNames.accessBtnWasNotGraded] = ' no ha sido calificada.';
strings[playerStringNames.accessBtnCorrectOutOf] = ' correcta(s) de ';
strings[playerStringNames.accessBtnGradedQuestions] = ' preguntas calificadas.';
strings[playerStringNames.accessBtnClickToReplayLink] = 'Reproducir';
strings[playerStringNames.accessResultsLabelCorrectSuffix] = 'es correcto.';
strings[playerStringNames.accessResultsLabelIncorrectSuffix] = 'es incorrecto.';
strings[playerStringNames.accessResultsLabelNoResponseSuffix] = 'no respondiste a esta pregunta.';
strings[playerStringNames.accessResultsLabelNoResponseGradedSuffix] = 'no respondiste a esta pregunta y se ha marcado como incorrecta.';
strings[playerStringNames.scormApiNotFoundMessage] = 'No es posible localizar la implementación de la API de LMS.\nNo se establecerá ninguna conexión con LMS.';
strings[playerStringNames.accessListTableOfContents] = 'Capítulos';
strings[playerStringNames.accessListSearchResults] = 'Resultados de la búsqueda en orden de aparición';
strings[playerStringNames.accessListNoSearchResults] = 'No se encontró ningún resultado';
strings[playerStringNames.accessQuestionSetConfirmationTitle] = 'Cuestionario';
strings[playerStringNames.accessVideoPausedForQuiz] = 'Vídeo en pausa para el cuestionario';
strings[playerStringNames.accessQuizResults] = 'Resultados del cuestionario';
strings[playerStringNames.accessQuestionFeedbackContext] = 'Comentarios de la pregunta: ';
strings[playerStringNames.accessProgressBarTimeConjunction] = ' de ';
strings[playerStringNames.accessPlayerSettingsClose] = 'Cerrar ajustes del reproductor';
strings[playerStringNames.accessBtnTheaterMode] = 'Modo de pantalla completa';
strings[playerStringNames.accessBtnExitTheaterMode] = 'Modo predeterminado';
strings[playerStringNames.confirmOpenUrlModalScreenReaderAlertText] = 'El vídeo quiere abrir la siguiente dirección url URL_TOKEN.';
strings[playerStringNames.confirmOpenUrlConfirmButtonScreenReaderText] = 'Continuar al nuevo sitio web';
strings[playerStringNames.fastForwardDisabledScreenReaderText] = 'Se ha desactivado el avance rápido en este vídeo';
strings[playerStringNames.scormCompleteVideoLater] = 'Terminar el vídeo más tarde';
strings[playerStringNames.scormCompleteVideo] = 'Terminar el vídeo';
strings[playerStringNames.scormExitVideo] = 'Salir del vídeo';
strings[playerStringNames.scormConfirmCompleteModalText] = '¿Seguro que quieres salir del vídeo?';
strings[playerStringNames.scormConfirmCompleteModalResumeVideoText] = 'La próxima vez que lo abras, el vídeo se reanudará en el punto en que lo dejaste.';
strings[playerStringNames.scormConfirmCompleteModalRestartVideoText] = 'La próxima vez que lo abras, el vídeo se volverá a reproducir desde el principio. No se conservará el progreso actual.';
strings[playerStringNames.scormConfirmCompleteModalFailedToSaveProgressText] = 'Ha habido un problema al guardar tu progreso. El vídeo comenzará desde el principio la próxima vez que lo veas.';
strings[playerStringNames.scormConfirmCompleteModalDismissButtonText] = 'Volver al vídeo';
strings[playerStringNames.scormConfirmCompleteModalDismissButtonScreenReaderText] = 'Volver al vídeo';
strings[playerStringNames.scormConfirmCompleteModalConfirmButtonText] = 'Salir del vídeo';
strings[playerStringNames.scormConfirmCompleteModalConfirmButtonScreenReaderText] = 'Salir del vídeo';
strings[playerStringNames.scormSessionEndedText] = 'Puedes cerrar la pestaña o volver al curso.';
strings[playerStringNames.searchOrJumpTo] = 'Buscar o acceder.';
strings[playerStringNames.searchTextCleared] = 'Texto de búsqueda borrado.';
strings[playerStringNames.searchResultsUpdated] = 'Resultados de búsqueda actualizados. {{number}} resultados.';
strings[playerStringNames.tableOfContentsDisplayed] = 'Capítulos mostrados.';
strings[playerStringNames.tableOfContentsHidden] = 'Capítulos ocultos.';
strings[playerStringNames.defaultMediaTitle] = 'TechSmith Smart Player';
strings[playerStringNames.hideThumbnailDueToQuiz] = 'Responder el cuestionario para ver vista previa';
strings[playerStringNames.quizAtTimeHeading] = 'Cuestionario en';
strings[playerStringNames.defaultFirstChapterName] = 'introducción';
strings[playerStringNames.acknowledgementLinkName] = 'Agradecimientos';

var getLanguage = function() {
   return languageCodes.SPANISH;
};

var getReadableTimeByLocale = function(hours, minutes, seconds) {
   var timeStringList = [];
   if (hours > 0) {
      var hoursReadableString = hours + ' hora';
      hoursReadableString += hours > 1 ? 's' : '';
      timeStringList.push(hoursReadableString);
   }
   if (minutes > 0) {
      var minutesReadableString = minutes + ' minuto';
      minutesReadableString += minutes > 1 ? 's' : '';
      timeStringList.push(minutesReadableString);
   }
   if (seconds > 0) {
      var secondsReadableString = seconds + ' segundo';
      secondsReadableString += seconds > 1 ? 's' : '';
      timeStringList.push(secondsReadableString);
   }

   return timeStringList.join(' y ');
};

export default Object.defineProperties({
   getReadableTimeByLocale: getReadableTimeByLocale
}, {
   language: {
      get: getLanguage
   },
   strings: {
      get: function() {
         return strings;
      }
   }
});
