import scormConstants from '../constants/scorm-constants';
import ScormDataModel from '../models/scorm-data-model';
import ScormApiWrapper from '../utils/scorm-api-wrapper';
import videoAnalytics from '../utils/video-analytics';
import reportType from '../constants/report-type';
import timeFormatter from '../utils/time-formatter';
import ScormNavigationBarView from '../views/scorm-navigation-bar-view';
import ScormConfirmCompleteModalView from '../views/scorm-confirm-complete-modal-view';
import ScormSessionEndedView from '../views/scorm-session-ended-view';
import cssClasses from '../constants/css-classes';
import events from '../constants/events';
import cssVariables from '../constants/css-variables';
import playerConfiguration from '../models/player-configuration';

var DEFAULT_PASSING_SCORE = 80;
var DEFAULT_VIDEO_WATCH_PERCENT_FOR_COMPLETE = 1;
var SCORM_NAV_CONTAINER_HEIGHT = '44px'; // note this should match the LESS var @scorm-ui-bar-height

/**
 * @memberof TSC
 * @class scormController
 * @static
 * @classdesc Scorm Controller
 */
var scormController = function() {
   var _$container = null;
   var _scormNavigationBarView = null;
   var _scormConfirmCompleteModalView = null;
   var _minimumScoreToPass = DEFAULT_PASSING_SCORE;
   var _minimumViewPercentageToCompleteLesson = DEFAULT_VIDEO_WATCH_PERCENT_FOR_COMPLETE;
   var _scormApiWrapper;
   var _isDisposed;
   var _initialized;
   var _scormModel;
   var _quizModel = null;
   var _sessionStartTimeInMilliseconds = 0;
   var _preventWindowClose = true;
   var _ignoreFailedToSuspendError = false;
   var _pendingScoExitValue = null;
   var _scoSessionHasEnded = false;

   var initializeInternalState = function() {
      _$container = null;
      _scormNavigationBarView = null;
      _scormConfirmCompleteModalView = null;
      _scormApiWrapper = ScormApiWrapper.create();
      _isDisposed = false;
      _initialized = false;
      _scormModel = ScormDataModel.create();
      _sessionStartTimeInMilliseconds = 0;
      _preventWindowClose = true;
      _ignoreFailedToSuspendError = false;
      _pendingScoExitValue = null;
      _scoSessionHasEnded = false;
   };

   var scoHasQuiz = function() {
      return _quizModel !== null;
   };

   var scormSessionHasUnfinishedQuiz = function() {
      return scoHasQuiz() && _scormModel.passingStatus === scormConstants.status.unknown;
   };

   var canSuspendScormSession = function() {
      return !scoHasQuiz() || _quizModel.reportMethod === reportType.NONE || _quizModel.reportMethod === reportType.SCORM;
   };

   var submitMinMaxScoreValuesToScormApi = function() {
      if (_scormApiWrapper.apiVersion === scormConstants.versions.SCORM_1_2) {
         _scormApiWrapper.apiCall(scormConstants.fn.setValue, scormConstants.dataModelID.cmiScoreMin, scormConstants.assessment.minScore.toString());
         _scormApiWrapper.apiCall(scormConstants.fn.setValue, scormConstants.dataModelID.cmiScoreMax, scormConstants.assessment.maxScore.toString());
      } else {
         _scormApiWrapper.apiCall(scormConstants.fn.setValue, scormConstants.dataModelID.cmiScoreMin, scormConstants.assessment.minScore);
         _scormApiWrapper.apiCall(scormConstants.fn.setValue, scormConstants.dataModelID.cmiScoreMax, scormConstants.assessment.maxScore);
      }

      _scormApiWrapper.apiCall(scormConstants.fn.commit);
   };

   var submitScoreToScormApi = function() {
      if (_scormApiWrapper.apiVersion === scormConstants.versions.SCORM_1_2) {
         _scormApiWrapper.apiCall(scormConstants.fn.setValue, scormConstants.dataModelID.cmiScoreRaw, _scormModel.quizPercentageCorrectAsString);
      } else {
         _scormApiWrapper.apiCall(scormConstants.fn.setValue, scormConstants.dataModelID.cmiScoreRaw, _scormModel.quizPercentageCorrect);
      }
      _scormApiWrapper.apiCall(scormConstants.fn.setValue, scormConstants.dataModelID.cmiScoreScaled, _scormModel.quizPercentageCorrect / scormConstants.assessment.scoreNormalizationDivider);
      _scormApiWrapper.apiCall(scormConstants.fn.commit);
   };

   var getSuspendedDataFromAPI = function() {
      var suspendedData = _scormApiWrapper.apiCall(scormConstants.fn.getValue, scormConstants.dataModelID.cmiSuspendData);
      _scormModel.parseSuspendedDataString(suspendedData);
   };

   var getSuspendData = function() {
      if (_quizModel) {
         _scormModel.quizModelSnapshot = _quizModel.getSnapShot();
      }
      _scormModel.videoAnalyticsSnapshot = videoAnalytics.getVideoSegmentSnapShot();
      return _scormModel.getSuspendedDataString();
   };

   var getSessionTimeValueByApiVersion = function() {
      var sessionTimeInMilliseconds = window.performance.now() - _sessionStartTimeInMilliseconds;
      var sessionTimeInSeconds = Math.round(sessionTimeInMilliseconds / 1000);
      if (_scormApiWrapper.apiVersion === scormConstants.versions.SCORM_1_2) {
         return timeFormatter.formatTimeForCmiTimespan(sessionTimeInSeconds);
      }
      return timeFormatter.formatTimeAsTimeInterval(sessionTimeInSeconds);
   };

   var scoObjectiveHasBeenCompleted = function() {
      return _scormModel.percentageComplete >= _minimumViewPercentageToCompleteLesson && !scormSessionHasUnfinishedQuiz();
   };

   var shouldSubmitQuizScoreToApi = function() {
      return _quizModel && _scormModel.completionStatus === scormConstants.status.completed && _scormModel.quizCompletionStatus === scormConstants.status.completed && _quizModel.totalNumberOfGradedQuestionSets > 0;
   };

   var endScormSession = function() {
      if (!_scoSessionHasEnded) {
         // need to mark this ended upfront
         _scoSessionHasEnded = true;

         if (_scormModel.percentageComplete >= _minimumViewPercentageToCompleteLesson) {
            _scormModel.completionStatus = scormConstants.status.completed;
         }

         var timeInSecondsAsString = videoAnalytics.getCurrentPlayTime().toString();

         _pendingScoExitValue = scormConstants.exitStates.complete;
         var scoCompletionStatusValue = _scormModel.completionStatus;
         var scoSuccessStatusValue;

         // SCORM 1.2 when video has quiz and not answered all questions complete status will be 'incomplete'
         // This is because SCORM 1.2 does not have a separate api value for pass / fail success
         if (_scormApiWrapper.apiVersion === scormConstants.versions.SCORM_1_2 && scormSessionHasUnfinishedQuiz()) {
            scoCompletionStatusValue = scormConstants.status.incomplete;
         }

         // Suspend SCO (Enables bookmarking feature) when viewer has not watched all the required video or they have not answered all the quiz questions if they exist
         if (canSuspendScormSession() && (_scormModel.completionStatus !== scormConstants.status.completed || scormSessionHasUnfinishedQuiz())) {
            _pendingScoExitValue = scormConstants.exitStates.suspend;
         }

         // If SCO attempt is complete and there is a quiz make sure we write the score out regardless if viewer has finished quiz
         if (_pendingScoExitValue === scormConstants.exitStates.complete) {
            if (scoHasQuiz()) {
               submitScore(_scormModel.quizPercentageCorrect, true);
            } else {
               _scormModel.passingStatus = scormConstants.status.passed;
            }
         }

         // SCORM 1.2 completionStatus value can be 'incomplete', 'passed' or 'failed'
         // SCORM 1.2 does not have a key to store an independent success status.
         if (_scormApiWrapper.apiVersion === scormConstants.versions.SCORM_1_2 && scoHasQuiz() && _scormModel.passingStatus !== scormConstants.status.unknown && scoCompletionStatusValue === scormConstants.status.completed) {
            scoCompletionStatusValue = _scormModel.passingStatus;
            scoSuccessStatusValue = '';
         } else {
            scoSuccessStatusValue = _scormModel.passingStatus;
         }

         var suspendedData = _pendingScoExitValue === scormConstants.exitStates.suspend ? getSuspendData() : '';

         var suspendSuccess = _scormApiWrapper.apiCall(scormConstants.fn.setValue, scormConstants.dataModelID.cmiSuspendData, suspendedData);

         // SCORM spec says boolean is returned, but in testing it is a boolean as a string.  So testing for both in case a LMS returns a boolean.
         if (typeof suspendSuccess === 'string' && suspendSuccess.toLowerCase() === 'false' || suspendSuccess === false) {
            _pendingScoExitValue = scormConstants.exitStates.complete;

            if (!_ignoreFailedToSuspendError) {
               _scormConfirmCompleteModalView = ScormConfirmCompleteModalView.create(_$container, {canResumeVideo: true, failedToSaveSuspendedData: true});
               _scoSessionHasEnded = false;
               return false;
            }
         }

         _scormApiWrapper.apiCall(scormConstants.fn.setValue, scormConstants.dataModelID.cmiSessionTime, getSessionTimeValueByApiVersion());
         _scormApiWrapper.apiCall(scormConstants.fn.setValue, scormConstants.dataModelID.cmiProgressMeasure, _scormModel.percentageComplete);
         _scormApiWrapper.apiCall(scormConstants.fn.setValue, scormConstants.dataModelID.cmiLocation, timeInSecondsAsString);

         if (shouldSubmitQuizScoreToApi()) {
            submitScoreToScormApi();
         }

         if (_scormModel.passingStatus !== scormConstants.status.completed) {
            _scormApiWrapper.apiCall(scormConstants.fn.setValue, scormConstants.dataModelID.cmiSuccessStatus, scoSuccessStatusValue);
         }

         _scormApiWrapper.apiCall(scormConstants.fn.setValue, scormConstants.dataModelID.cmiCompletionStatus, scoCompletionStatusValue);
         _scormApiWrapper.apiCall(scormConstants.fn.commit);
      }
      return true;
   };

   var terminateScormSession = function() {
      _initialized = false;
      _isDisposed = true;
      _scormApiWrapper.apiCall(scormConstants.fn.setValue, scormConstants.dataModelID.cmiExit, _pendingScoExitValue);
      _scormApiWrapper.apiCall(scormConstants.fn.setValue, scormConstants.dataModelID.adlNavRequest, scormConstants.navRequestStates.exitAll);
      _scormApiWrapper.apiCall(scormConstants.fn.terminate);
      ScormSessionEndedView.create(_$container);
   };

   var createNavigationBarView = function() {
      _scormNavigationBarView = ScormNavigationBarView.create(_$container);
      _scormNavigationBarView.updateView(scoObjectiveHasBeenCompleted());
      _scormApiWrapper.getTitle().then(function(scormTitle) {
         _scormNavigationBarView.setTitle(scormTitle);
      });
      _$container[0].style.setProperty(cssVariables.reservedSizeScorm, SCORM_NAV_CONTAINER_HEIGHT);
   };

   var onExitSessionHandler = function() {
      if (!scoObjectiveHasBeenCompleted()) {
         _scormConfirmCompleteModalView = ScormConfirmCompleteModalView.create(_$container, {canResumeVideo: canSuspendScormSession(), failedToSaveSuspendedData: false});
      } else {
         exitScormSession();
      }
   };

   var onExitSessionConfirmedHandler = function() {
      _scormConfirmCompleteModalView && _scormConfirmCompleteModalView.destroy();
      _scormConfirmCompleteModalView = null;
      exitScormSession();
   };

   var onExitSessionWithOutProgressConfirmed = function() {
      _ignoreFailedToSuspendError = true;
      onExitSessionConfirmedHandler();
   };

   var initView = function($container) {
      _$container = $container;
      if (_scormApiWrapper.apiVersion === scormConstants.versions.NOT_DETECTED) {
         _$container.trigger(events.SCORM.FailedToFindApi);
         return;
      }
      _$container.addClass(cssClasses.scormModule);
      _$container.on(events.SCORM.ExitSession, onExitSessionHandler);
      _$container.on(events.SCORM.ExitSessionConfirmed, onExitSessionConfirmedHandler);
      _$container.on(events.SCORM.ExitSessionWithOutProgressConfirmed, onExitSessionWithOutProgressConfirmed);

      createNavigationBarView();
   };

   var exitScormSession = function() {
      var endedSuccessStatus = endScormSession();
      if (endedSuccessStatus || _ignoreFailedToSuspendError) {
         terminateScormSession();
         _preventWindowClose = false;
      }
   };

   var preventPopUpWindowClose = function() {
      try {
         if (window.top && window.top.opener) {
            window.top.onbeforeunload = function() {
               if (_preventWindowClose) {
                  return '';
               }
            };
         }
      } catch (e) {
         // eslint-disable-line
      }
   };

   var initializeCompletionStatus = function() {
      var completionStatus = _scormApiWrapper.apiCall(scormConstants.fn.getValue, scormConstants.dataModelID.cmiCompletionStatus, true);
      if (completionStatus === scormConstants.status.unknown || completionStatus === scormConstants.status.notAttempted) {
         _scormModel.completionStatus = scormConstants.status.incomplete;
         _scormApiWrapper.apiCall(scormConstants.fn.setValue, scormConstants.dataModelID.cmiCompletionStatus, scormConstants.status.incomplete);
      } else {
         _scormModel.completionStatus = completionStatus;
      }
   };

   // as a backup, try and terminate the SCORM session if the user did not do it.
   // most likely this will not work with some browsers with some LMSs
   var tryAndTerminateSessionOnPageUnloadIfNeeded = function() {
      if (!_isDisposed) {
         exitScormSession();
      }
   };

   var init = function() {
      initializeInternalState();
      _sessionStartTimeInMilliseconds = window.performance.now();

      return _scormApiWrapper.init().then(function(apiResult) {
         if (apiResult === 'false') {
            console.error(_scormApiWrapper.getLastError());
            return;
         }

         preventPopUpWindowClose();
         getSuspendedDataFromAPI();
         initializeCompletionStatus();

         if (_quizModel !== null) {
            submitMinMaxScoreValuesToScormApi();
         }

         window.addEventListener('unload', tryAndTerminateSessionOnPageUnloadIfNeeded);
         _initialized = true;
      });
   };

   /**
    * Submit percentage of video watched to SCORM api
    * @function submitVideoWatchedPercentage
    * @memberof TSC.scormController
    * @param {Number} percentageWatched - Percentage of video watched from 0 - 1
    * @param {Number} timeInSeconds - Current time of video in seconds
    */
   var submitVideoWatchedPercentage = function(percentageWatched, timeInSeconds) {
      if (!_initialized) {
         return;
      }
      // Note: the percentage reported via value reports how much was actually watched
      //       and not the percentage of the timeline that the playhead is at.
      //       So, for example, if you started up a video and then immediately seeked to
      //       the 75% pt, the values reported here would still start from zero.
      _scormModel.percentageComplete = Math.max(0, Math.min(1, percentageWatched));
      _scormModel.videoTimeInSeconds = timeInSeconds;

      if (_scormModel.percentageComplete >= _minimumViewPercentageToCompleteLesson) {
         _scormModel.completionStatus = scormConstants.status.completed;
      }

      if (scoObjectiveHasBeenCompleted()) {
         if (_scormNavigationBarView) {
            _scormNavigationBarView.updateView(true);
         }
         if (playerConfiguration.completeScormOnRequirementsComplete) {
            endScormSession();
         }
      }
   };

   /**
    * Submit a score to the SCORM api
    * @memberof TSC.scormController
    * @function submitScore
    * @param {Number} currentScore - Score to report to Scorm
    * @param {Boolean} allQuestionsAnswered - Are all questions answered
    */
   var submitScore = function(currentScore, allQuestionsAnswered) {
      if (!_initialized) {
         return;
      }

      var scoreNeededToPass = _minimumScoreToPass;

      if (!isNaN(currentScore)) {
         var scoreNeededMultiplier = null;
         var maxPassingScoreValue = null;

         if (_scormApiWrapper.apiVersion === scormConstants.versions.SCORM_2004) {
            scoreNeededMultiplier = 100;
            maxPassingScoreValue = 1;
         } else if (_scormApiWrapper.apiVersion === scormConstants.versions.SCORM_1_2) {
            scoreNeededMultiplier = 1;
            maxPassingScoreValue = 100;
         }

         // lms defined mastery level will take precedence over sco defined mastery level
         var lmsPassingScore = _scormApiWrapper.apiCall(scormConstants.fn.getValue, scormConstants.dataModelID.cmiScaledPassingScore);
         if (lmsPassingScore !== null && lmsPassingScore !== '' && lmsPassingScore >= 0 && lmsPassingScore <= maxPassingScoreValue) {
            scoreNeededToPass = lmsPassingScore * scoreNeededMultiplier;
         }

         _scormModel.quizPercentageCorrect = currentScore;
         _scormModel.quizCompletionStatus = allQuestionsAnswered ? scormConstants.status.completed : scormConstants.status.incomplete;

         if (allQuestionsAnswered) {
            if (_quizModel.totalNumberOfGradedQuestionSets === 0) {
               _scormModel.passingStatus = scormConstants.status.completed;
            } else if (currentScore >= scoreNeededToPass) {
               _scormModel.passingStatus = scormConstants.status.passed;
            } else {
               _scormModel.passingStatus = scormConstants.status.failed;
            }
         } else {
            _scormModel.passingStatus = scormConstants.status.unknown;
         }

         if (scoObjectiveHasBeenCompleted()) {
            if (_scormNavigationBarView) {
               _scormNavigationBarView.updateView(true);
            }
            if (playerConfiguration.completeScormOnRequirementsComplete) {
               endScormSession();
            }
         }
      }
   };

   return Object.defineProperties({
      init: init,
      initView: initView,
      submitVideoWatchedPercentage: submitVideoWatchedPercentage,
      submitScore: submitScore
   }, {
      initialized: {
         get: function() {
            return _initialized;
         }
      },
      initialPercentageComplete: {
         get: function() {
            return _scormModel.percentageComplete;
         }
      },
      initialVideoAnalyticsSnapshot: {
         get: function() {
            return _scormModel.videoAnalyticsSnapshot;
         }
      },
      initialQuizModelSnapshot: {
         get: function() {
            return _scormModel.quizModelSnapshot;
         }
      },
      initialVideoTimeInSeconds: {
         get: function() {
            return _scormModel.videoTimeInSecondsSnapshot;
         }
      },
      minScoreToPassLesson: {
         get: function() {
            return _minimumScoreToPass;
         },
         set: function(minimumScoreToPass) {
            _minimumScoreToPass = minimumScoreToPass;
         }
      },
      minViewPercentageToCompleteLesson: {
         get: function() {
            return _minimumViewPercentageToCompleteLesson;
         },
         set: function(minimumViewPercentageToCompleteLesson) {
            _minimumViewPercentageToCompleteLesson = minimumViewPercentageToCompleteLesson;
         }
      },
      quizModel: {
         get: function() {
            return _quizModel;
         },
         set: function(quizModel) {
            _quizModel = quizModel;
         }
      },
      scoObjectiveHasBeenCompleted: {
         get: scoObjectiveHasBeenCompleted
      },
      supportsResumeSession: {
         get: canSuspendScormSession
      }
   });
};

export default scormController();
