var Media = function(media, type, mimeType, uri) {
   var _media = media;
   var _type = type;
   var _mimeType = mimeType;
   var _uri = uri;

   return Object.defineProperties({}, {
      media: {
         get: function() {
            return _media;
         }
      },
      type: {
         get: function() {
            return _type;
         }
      },
      mimeType: {
         get: function() {
            return _mimeType;
         }
      },
      uri: {
         get: function() {
            return _uri;
         }
      }
   });
};

export default {
   create: Media
};
