import $ from 'jquery';
import HotspotController from './hotspot-controller';
import CaptionController from './caption-controller';
import TableOfContentsController from './table-of-contents-controller';
import AudioDescriptionController from './audio-description-controller';
import EndActionsController from './end-actions-controller';
import QuizController from './quiz-controller';
import QuizEventController from './quiz-event-controller';
import MediaAnalyticsController from './media-analytics-controller';
import PluginEventsController from './plugin-events-controller';
import MediaSessionController from './media-session-controller';
import highlightDisabler from '../utils/highlight-disabler';
import deviceInfo from '../utils/device-info';
import mediaViewType from '../constants/media-view-type';
import reportType from '../constants/report-type';
import scormController from './scorm-controller';
import events from '../constants/events';
import localizationStrings from '../models/localization-strings';
import playerStringNames from '../constants/player-string-names';

/**
 * @memberof TSC
 * @class MediaExtensionsController
 * @classdesc Controller for managing XMP features
 * @param {jQuery} $container - Smart Player main container jquery element
 * @param {MediaView} mediaView - Media view for current media. Created by MediaViewFactory.
 * @param {TSC.XmpModel} xmpModel
 * @param {TSC.Quiz} quizModel
 * @param {TSC.MediaList} mediaList
 * @param {TSC.CaptionService} captionService
 * @param {TSC.playerConfiguration} playerConfiguration
 * @param {TSC.ResponsiveController} responsiveController
 * @param {TSC.TabController} tabController
 * @param {TSC.ProgressBarControlView} progressBarControlView
 * @param {TSC.BeforeAfterPlayControlsView} beforeAfterPlayControlsView
 * @param {TSC.IoOverlayView} ioOverlayView
 *
 */
var MediaExtensionsController = function($container,
   mediaView,
   xmpModel,
   quizModel,
   mediaList,
   captionService,
   playerConfiguration,
   responsiveController,
   tabController,
   progressBarControlView,
   beforeAfterPlayControlsView,
   ioOverlayView) {
   var _hotspotController;
   var _captionController;
   var _tableOfContentsController;
   var _audioDescriptionController;
   var _endActionsController;
   var _quizController;
   var _quizEventController;
   var _mediaAnalyticsController;
   var _pluginEventsController;
   var _mediaSessionController;

   var modifyPlayerConfigurationBasedOnDevice = function() {
      // ignore auto play if this is an iOS device that plays video inline or there is a quiz present
      // may want to move this out of the view at some point.
      var hasAnEnabledQuiz = _quizController && _quizController.hasQuiz && _quizController.quizEnabled;
      if ((deviceInfo.isIOS() || deviceInfo.isAndroid() || hasAnEnabledQuiz) && !playerConfiguration.getMobileWebViewCanAutoPlay()) {
         playerConfiguration.setAutoPlayMedia(false);
      }
   };

   var setupImageControllers = function() {
      if (quizModel) {
         _quizController = QuizController.create($container, mediaView, quizModel, beforeAfterPlayControlsView, playerConfiguration.enforceLinearAssessment);
         _quizEventController = QuizEventController.create($container, mediaView, _quizController, progressBarControlView, playerConfiguration.enforceLinearAssessment, ioOverlayView);
      }
      _mediaAnalyticsController = MediaAnalyticsController.create($container, mediaView, mediaList, _quizController, playerConfiguration);
   };

   var getMediaTitle = function() {
      if (playerConfiguration.mediaTitle) {
         return playerConfiguration.mediaTitle;
      } else if (playerConfiguration.tocTitle) {
         return playerConfiguration.tocTitle;
      } else if (xmpModel && xmpModel.title) {
         return xmpModel.title;
      }
      return localizationStrings.getPlayerString(playerStringNames.defaultMediaTitle);
   };

   var getMediaPosterSrc = function() {
      return playerConfiguration.getPosterImageSrc() || '';
   };

   var setupVideoControllers = function() {
      _endActionsController = EndActionsController.create($container, mediaView, progressBarControlView, beforeAfterPlayControlsView);

      if (playerConfiguration.audioDescriptionMediaSrc) {
         _audioDescriptionController = AudioDescriptionController.create($container, mediaView, playerConfiguration.audioDescriptionMediaSrc);
      }

      if (quizModel) {
         _quizController = QuizController.create($container, mediaView, quizModel, beforeAfterPlayControlsView, playerConfiguration.enforceLinearAssessment);
         _quizEventController = QuizEventController.create($container, mediaView, _quizController, progressBarControlView, playerConfiguration.enforceLinearAssessment, ioOverlayView);
      }

      _mediaAnalyticsController = MediaAnalyticsController.create($container, mediaView, mediaList, _quizController, playerConfiguration);

      if (MediaSessionController.isSupported()) {
         _mediaSessionController = MediaSessionController.create($container, mediaView.mediaElement, getMediaTitle(), getMediaPosterSrc());
      }

      if (mediaView.supportsSCORM && playerConfiguration.isScormLesson) {
         if (_quizController && _quizController.quizModel !== null && (_quizController.quizModel.reportMethod === reportType.SCORM || _quizController.quizModel.reportMethod === reportType.APIANDSCORM)) {
            scormController.submitScore(0, false);
         }

         // restore quizzing model state and seek video if not using TechSmith Quiz Service (it can not resume a quiz)
         if (_quizController && _quizController.quizModel && _quizController.quizModel.reportMethod === reportType.SCORM && scormController.initialQuizModelSnapshot) {
            _quizController.populateQuizModel(scormController.initialQuizModelSnapshot);
            progressBarControlView.updateQuizMarkers();
         }

         scormController.initView($container);
      }


      var hasCaptionItems = xmpModel && xmpModel.hasCaptionItems || captionService.captionTrack;
      if (hasCaptionItems) {
         _captionController = CaptionController.create($container, mediaView, xmpModel, mediaList, captionService);
      }

      var hasHotspotItems = xmpModel && xmpModel.hasHotspotItems;
      if (hasHotspotItems) {
         _hotspotController = HotspotController.create($container, mediaView, xmpModel, mediaList, tabController);
      }

      var hasTableOfContents = xmpModel && xmpModel.hasSearchableItems;
      if (hasTableOfContents) {
         _tableOfContentsController = TableOfContentsController.create($container, mediaView, xmpModel, mediaList, progressBarControlView, playerConfiguration);
      }
   };

   var setupControllers = function() {
      _pluginEventsController = PluginEventsController.create($container);

      if (mediaView.type === mediaViewType.image) {
         setupImageControllers();
      } else {
         setupVideoControllers();
      }
   };

   var preventEvent = function(e) {
      e.stopImmediatePropagation();
      e.preventDefault();
      return false;
   };

   var disableHighlightOnMouseDown = function() {
      highlightDisabler.disableHighlights($(this));
   };

   var activateHighlightDisabler = function() {
      $container.on('mousedown', '*', disableHighlightOnMouseDown);
   };

   var applyCustomBackgroundColor = function() {
      $container.css('background-color', playerConfiguration.getBackgroundColor());
   };

   var disableContextMenuIfNeeded = function() {
      if (playerConfiguration.getDisableContextMenuForVideo()) {
         $container.on('contextmenu', preventEvent);
      }
   };

   var configurePlayer = function() {
      modifyPlayerConfigurationBasedOnDevice();
      activateHighlightDisabler();
      applyCustomBackgroundColor();
      disableContextMenuIfNeeded();

      responsiveController.monitorContainerSize();

      if (!_quizController) {
         $container.trigger(events.Player.MediaExtensionsReady);
      } else {
         _quizController.quizIsSetupPromise.then(function() {
            $container.trigger(events.Player.MediaExtensionsReady);
         });
      }
   };

   var destroy = function() {
      _hotspotController && _hotspotController.destroy();
      _captionController && _captionController.destroy();
      _tableOfContentsController && _tableOfContentsController.destroy();
      _audioDescriptionController && _audioDescriptionController.destroy();
      _endActionsController && _endActionsController.destroy();
      _quizController && _quizController.destroy();
      _quizEventController && _quizEventController.destroy();
      _mediaAnalyticsController && _mediaAnalyticsController.destroy();
      _pluginEventsController && _pluginEventsController.destroy();
      _mediaSessionController && _mediaSessionController.destroy();
      $container.off('mousedown', '*', disableHighlightOnMouseDown);
      $container.off('contextmenu', preventEvent);

      responsiveController.clearContainerMonitors();
   };

   setupControllers();
   configurePlayer();

   return Object.defineProperties({
      destroy: destroy
   }, {
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.MediaExtensionsController
       *  @property {TSC.CaptionController} captionController - the caption controller
       */
      captionController: {
         get: function() {
            return _captionController;
         }
      }
   });
};

export default {
   /**
    *  Factory method that returns a new MediaExtensionsController.
    *  @function create
    *  @memberof TSC.MediaExtensionsController
    *  @static
    *  @param {jQuery} $container - Smart Player main container jquery element
    *  @param {MediaView} mediaView - Media view for current media. Created by MediaViewFactory.
    *  @param {TSC.XmpModel} xmpModel
    *  @param {TSC.Quiz} quizModel
    *  @param {TSC.MediaList} mediaList
    *  @param {TSC.CaptionService} captionService
    *  @param {TSC.playerConfiguration} playerConfiguration
    *  @param {TSC.TabController} tabController
    *  @param {TSC.ResponsiveController} responsiveController
    *  @param {TSC.ProgressBarControlView} progressBarControlView
    *  @param {TSC.BeforeAfterPlayControlsView} beforeAfterPlayControlsView
    *  @param {TSC.IoOverlayView} ioOverlayView
    *  @return new MediaExtensionsController instance
    */
   create: MediaExtensionsController
};
