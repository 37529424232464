import events from '../constants/events';
import videoApiUtils from '../utils/video-api-utils';
import viewHelper from '../utils/view-helper';
import videoAnalytics from '../utils/video-analytics';
import log from '../../common/log';
import MessageBarView from '../views/message-bar-view';
import messageBarType from '../constants/message-bar-type';
import localizationStrings from '../models/localization-strings';
import playerStringNames from '../constants/player-string-names';
import playerConfiguration from '../models/player-configuration';
import mediaViewType from '../constants/media-view-type';

var SEEK_DELAY_IN_MILLISECONDS = 100;

/**
 * @memberof TSC
 * @class QuizEventController
 * @classdesc Controller for managing quiz events
 * @param {jQuery} $container - Smart Player main container jquery element
 * @param {MediaView} mediaView - Media view for current media. Created by MediaViewFactory.
 * @param {TSC.QuizController} quizController
 * @param {TSC.ProgressBarControlView} progressBarControlView
 * @param {boolean} enforceLinearAssessment
 * @param {TSC.IoOverlayView} ioOverlayView - IO Overlay View used to show the user messages
 *
 */
var QuizEventController = function($container, mediaView, quizController, progressBarControlView, enforceLinearAssessment, ioOverlayView) {
   if (!quizController) {
      throw new Error('Attempted to create quiz event controller with no quiz controller');
   }

   var _lastQuestionSetId;
   var _lastQuestionSetMarkerTime;
   var _quizzingErrorMessageBarView;
   var _isScrubbing = false;

   var updateQuizPointAtPosition = function() {
      if (!progressBarControlView) {
         return;
      }
      var playerXOffset = $container.offset().left;
      var markerXPosition = progressBarControlView.getXPositionForQuestionSetMarker(_lastQuestionSetId);
      var pointAtXPosition = markerXPosition - playerXOffset;
      quizController.pointViewAt(pointAtXPosition);
   };

   var seekToTime = function(seekTime, playAfterSeek) {
      var safeSeekTime = videoApiUtils.getSafeSeekTime(mediaView.mediaElement, seekTime);
      $container.trigger(events.Media.SeekToTime, {time: safeSeekTime, playAfterSeek: playAfterSeek});
   };

   var updateQuizzing = function(currentTimeInMilliseconds) {
      if (!quizController.quizEnabled || _isScrubbing) {
         return;
      }

      var numberMillisecondsInSecond = 1000;

      var qs = quizController.findQuestionSet(currentTimeInMilliseconds);

      if (qs.id < 0) {
         _lastQuestionSetId = -1;
         return;
      }

      if (_lastQuestionSetId !== qs.id) {
         viewHelper.saveActiveElement();
         // make sure control bar is shown up and auto hide is disabled.
         $container.trigger(events.Controls.Disable);

         $container.trigger(events.Controls.HideToc);

         _lastQuestionSetId = qs.id;
         _lastQuestionSetMarkerTime = qs.markerTime / numberMillisecondsInSecond;

         $container.playerState.pausedForQuiz = true;
         mediaView.mediaElement.pause();
         $container.trigger(events.Quizzing.Paused);

         // Try pausing and seeking YouTube until player confirms the video is in the correct position
         // Fixes issues with YouTube video playing in the background
         if (mediaView.type === mediaViewType.youtube) {
            var intervalId = setInterval(function() {
               mediaView.mediaElement.pause();
               seekToTime(_lastQuestionSetMarkerTime, false);
               if (mediaView.mediaElement.currentTime.toFixed(4) === _lastQuestionSetMarkerTime.toFixed(4) && mediaView.mediaElement.paused) {
                  clearInterval(intervalId);
               }
            }, SEEK_DELAY_IN_MILLISECONDS);
         } else {
            setTimeout(function() {
               seekToTime(_lastQuestionSetMarkerTime, false);
            }, SEEK_DELAY_IN_MILLISECONDS);
         }

         quizController.displayConfirm(qs.id);

         updateQuizPointAtPosition();

         if (videoAnalytics.isEnabled()) {
            if (!isNaN(qs.markerTime) && videoAnalytics.isPercentWatchedInitialized()) {
               videoAnalytics.markTimeWatched(qs.markerTime);
            } else {
               log.logMessage('Player-view: videoAnalytics percent watched not initialized, or Quiz Marker time is NaN (' + qs.markerTime + ')');
            }

            var percentWatched = videoAnalytics.getPercentageWatchedForVideoSegment(quizController.findPrevMarkerTimeForMarker(qs.id), qs.markerTime);
            quizController.updatePercentWatchedForQuestionSet(_lastQuestionSetId, percentWatched);
         }
      }
   };

   var quizEventHandler = function(event, data) {
      switch (event.type) {
         case events.Quizzing.ShowStartQuizPrompt:
         case events.Quizzing.ShowQuestionSetView:
            $container.trigger(events.Quizzing.MaximizeUI);
            $container.trigger(events.Quizzing.UIVisible);
            break;
         case events.Quizzing.Complete:
         case events.Quizzing.Review:
            $container.trigger(events.Quizzing.UIHidden);
            $container.trigger(events.Controls.Enable);
            viewHelper.focusPreviousElement();

            progressBarControlView.updateQuizMarkers();

            $container.playerState.pausedForQuiz = false;

            if (event.type === events.Quizzing.Review && enforceLinearAssessment) {
               var prevMarkerTimeInSeconds = quizController.findPrevMarkerTimeForMarker(_lastQuestionSetId) / 1000;
               _lastQuestionSetId = -1;
               seekToTime(prevMarkerTimeInSeconds, true);
            } else {
               mediaView.mediaElement.play();
            }
            break;
         case events.Quizzing.Begin:
            progressBarControlView.updateQuizMarkers();
            $container.trigger(events.Quizzing.QuizSetupComplete);
            break;
         case events.Quizzing.Skip:
            progressBarControlView.setShouldRespectQuizMarkers(false);
            $container.trigger(events.Quizzing.QuizSetupComplete);
            break;
         case events.Quizzing.Error:
            _quizzingErrorMessageBarView = MessageBarView.create($container, messageBarType.info, localizationStrings.getPlayerString(playerStringNames.txtVideoQuizNetworkErrorMessage), localizationStrings.getPlayerString(playerStringNames.close));
            break;
         case events.Quizzing.Status:
            ioOverlayView.displayMessage(data.message);
            break;
         case events.Quizzing.HideLoadingMessage:
         case events.Quizzing.Submitted:
            ioOverlayView.hideView();
            break;
         case events.Quizzing.CreatedInstance:
            progressBarControlView && progressBarControlView.updateQuizMarkers();

            if (quizController.hasQuiz && quizController.quizModel.quizInstanceID) {
               quizController.startTrackingProgress();

               var eventLabel = 'QIID_' + quizController.quizModel.quizInstanceID;
               mediaView.playbackQualityMetrics && mediaView.playbackQualityMetrics.setEventLabel(eventLabel);
            }

            $container.trigger(events.Quizzing.UIHidden);
            break;
         case events.Quizzing.RationaleActionJumpToTime:
            var jumpToTimeInSeconds = data.jumpToTime / 1000;
            progressBarControlView.allowSkipQuestionSetMarkersBeforeTime(jumpToTimeInSeconds);
            seekToTime(jumpToTimeInSeconds);
            break;
      }
   };

   var onLoadedMetadata = function() {
      // check to see if quiz exists and it is ready to rumble
      if (!quizController.hasQuiz || quizController.hasQuiz && (!quizController.quizEnabled || quizController.quizReady)) {
         ioOverlayView.hideView();
      } else { // update overlay with status of quiz code
         var lastStatusMessage = quizController.getLastStatusMessage();
         if (lastStatusMessage) {
            mediaView.mediaElement.pause();
            ioOverlayView.displayMessage(lastStatusMessage);
         }
      }
   };

   var onVideoTimeUpdate = function() {
      updateQuizzing(mediaView.currentTimeInMilliseconds);
   };

   var onSeeked = function() {
      updateQuizzing(mediaView.currentTimeInMilliseconds);
   };

   var onVideoControlsVisible = function() {
      progressBarControlView.updateQuizMarkers();
   };

   var onScrubbingStarted = function() {
      _isScrubbing = true;
   };

   var onScrubbingEnded = function() {
      _isScrubbing = false;
      onVideoTimeUpdate();
   };

   var bindToEvents = function() {
      mediaView.mediaWrapper.on('ratechange', onVideoTimeUpdate, false);
      mediaView.mediaWrapper.on('play', onVideoTimeUpdate, false);

      var eventsToBind = {
         loadedmetadata: onLoadedMetadata,
         timeupdate: onVideoTimeUpdate,
         seeked: onSeeked
      };
      mediaView.mediaWrapper.on(eventsToBind);

      $container.on(events.Controls.VideoControlsVisible, onVideoControlsVisible);
      $container.on(events.Controls.PlayerResize, updateQuizPointAtPosition);
      $container.on(events.Quizzing.Complete, quizEventHandler);
      $container.on(events.Quizzing.Review, quizEventHandler);
      $container.on(events.Quizzing.Begin, quizEventHandler);
      $container.on(events.Quizzing.Skip, quizEventHandler);
      $container.on(events.Quizzing.Error, quizEventHandler);
      $container.on(events.Quizzing.Status, quizEventHandler);
      $container.on(events.Quizzing.SubmittingInstance, quizEventHandler);
      $container.on(events.Quizzing.Submitted, quizEventHandler);
      $container.on(events.Quizzing.CreatedInstance, quizEventHandler);
      $container.on(events.Quizzing.RationaleActionJumpToTime, quizEventHandler);
      $container.on(events.Quizzing.HideLoadingMessage, quizEventHandler);
      $container.on(events.Quizzing.ShowStartQuizPrompt, quizEventHandler);
      $container.on(events.Quizzing.ShowQuestionSetView, quizEventHandler);
      $container.on(events.Scrubbing.Started, onScrubbingStarted);
      $container.on(events.Scrubbing.Ended, onScrubbingEnded);
   };

   var unbindToEvents = function() {
      mediaView.mediaWrapper.off('ratechange', onVideoTimeUpdate, false);
      mediaView.mediaWrapper.off('play', onVideoTimeUpdate, false);

      var eventsToBind = {
         loadedmetadata: onLoadedMetadata,
         timeupdate: onVideoTimeUpdate,
         seeked: onSeeked
      };
      mediaView.mediaWrapper.off(eventsToBind);

      $container.off(events.Controls.VideoControlsVisible, onVideoControlsVisible);
      $container.off(events.Controls.PlayerResize, updateQuizPointAtPosition);
      $container.off(events.Quizzing.Complete, quizEventHandler);
      $container.off(events.Quizzing.Review, quizEventHandler);
      $container.off(events.Quizzing.Begin, quizEventHandler);
      $container.off(events.Quizzing.Skip, quizEventHandler);
      $container.off(events.Quizzing.Error, quizEventHandler);
      $container.off(events.Quizzing.Status, quizEventHandler);
      $container.off(events.Quizzing.SubmittingInstance, quizEventHandler);
      $container.off(events.Quizzing.Submitted, quizEventHandler);
      $container.off(events.Quizzing.CreatedInstance, quizEventHandler);
      $container.off(events.Quizzing.RationaleActionJumpToTime, quizEventHandler);
      $container.off(events.Quizzing.HideLoadingMessage, quizEventHandler);
      $container.off(events.Quizzing.ShowStartQuizPrompt, quizEventHandler);
      $container.off(events.Quizzing.ShowQuestionSetView, quizEventHandler);
      $container.off(events.Scrubbing.Started, onScrubbingStarted);
      $container.off(events.Scrubbing.Ended, onScrubbingEnded);
   };

   var destroy = function() {
      unbindToEvents();
      _quizzingErrorMessageBarView && _quizzingErrorMessageBarView.remove();
   };

   videoApiUtils.setQuizController(quizController);
   progressBarControlView && progressBarControlView.setShouldRespectQuizMarkers(quizController.quizModel && quizController.quizEnabled && !playerConfiguration.ignoreQuizQuestions);
   quizController.mediaIsImage = mediaView.type === mediaViewType.image;

   bindToEvents();

   return Object.defineProperties({
      destroy: destroy
   }, {
   });
};

export default {
   /**
    *  Factory method that returns a new QuizEventController.
    *  @function create
    *  @memberof TSC.QuizEventController
    *  @static
    *  @param {jQuery} $container - Smart Player main container jquery element
    *  @param {MediaView} mediaView - Media view for current media. Created by MediaViewFactory.
    *  @param {TSC.QuizController} quizController
    *  @param {TSC.ProgressBarControlView} progressBarControlView
    *  @param {boolean} enforceLinearAssessment
    *  @param {TSC.IoOverlayView} ioOverlayView - IO Overlay View used to show the user messages
    *  @return new QuizEventController instance
    */
   create: QuizEventController
};
