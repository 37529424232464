import $ from 'jquery';
import deviceInfo from '../utils/device-info';
import questionType from '../constants/question-type';
import log from '../../common/log';
import templateGenerator from '../utils/template-generator';
import viewHelper from '../utils/view-helper';
import playerStringNames from '../constants/player-string-names';
import localizationStrings from '../models/localization-strings';
import cssClasses from '../constants/css-classes';

var CHECK_SVG = '<svg id="Correct" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 42"><style>.st0{fill-rule:evenodd;clip-rule:evenodd;fill:#97C93D;} .st1{fill:#82AD40;} .st2{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}</style><path class="st0" d="M21 39c-9.9 0-18-8.1-18-18S11.1 3 21 3s18 8.1 18 18-8.1 18-18 18z"/><path class="st1" d="M21 5c8.8 0 16 7.2 16 16s-7.2 16-16 16S5 29.8 5 21 12.2 5 21 5m0-4C10 1 1 10 1 21s9 20 20 20 20-9 20-20S32 1 21 1z"/><path class="st2" d="M30 16.6l-11.2 12c-.8.8-1.8.3-2.3-.2l-4.8-5.9c-.5-.7-.4-1.6.3-2.2.7-.5 1.6-.4 2.1.3l3.7 4.5 10-10.8c.6-.6 1.5-.6 2.1 0 .7.7.7 1.7.1 2.3z"/></svg>';
var INCORRECT_SVG = '<svg id="Wrong" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 42"><style>.st0{fill-rule:evenodd;clip-rule:evenodd;fill:#EF4546;} .st1{fill:#B93130;} .st2{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}</style><path class="st0" d="M21 39c-9.9 0-18-8.1-18-18S11.1 3 21 3s18 8.1 18 18-8.1 18-18 18z"/><path class="st1" d="M21 5c8.8 0 16 7.2 16 16s-7.2 16-16 16S5 29.8 5 21 12.2 5 21 5m0-4C10 1 1 10 1 21s9 20 20 20 20-9 20-20S32 1 21 1z"/><path class="st2" d="M24.2 21l4.1 4.1c.9.9.9 2.4 0 3.3-.9.9-2.4.9-3.3 0l-4-4.2-4 4c-.9.9-2.3.9-3.2 0s-.9-2.3 0-3.2l4-4-4.1-4.1c-.9-.9-.9-2.4 0-3.3.9-.9 2.4-.9 3.3 0l4.1 4.1 4-4c.9-.9 2.3-.9 3.2 0 .9.9.9 2.3 0 3.2L24.2 21z"/></svg>';
var CORRECT_GLYPH_DATA_URL = 'data:image/svg+xml,' + window.encodeURIComponent(CHECK_SVG);
var INCORRECT_GLYPH_DATA_URL = 'data:image/svg+xml,' + window.encodeURIComponent(INCORRECT_SVG);
var ACTIVE_QUESTION_CLASS = 'active-question-item';
var ACTIVE_FEEDBACK_CLASS = 'active-feedback-item';
var CORRECT_ANSWER_TEXT = localizationStrings.getPlayerString(playerStringNames.txtCorrectAnswer);
var YOUR_ANSWER_TEXT = localizationStrings.getPlayerString(playerStringNames.txtYourAnswer);

/**
 * @memberof TSC
 * @class AnswersView
 * @classdesc Answers View
 */
var AnswersView = function($container, question, showResults) {
   var _$window = $(window);
   var _$textInput = null;

   var getUserResponse = function() {
      switch (question.type) {
         case questionType.CHOICE:
            return $container.find('input[name=multiple-choice]:checked').val();
         case questionType.FILL_IN:
            return $container.find('input').val();
         case questionType.SHORT:
            return $container.find('textarea').val().replace(/^[ \t]+|[ \t]+$/, '');
      }
   };

   var displayResponseFillInResults = function() {
      if (!question.isGraded) {
         return;
      }
      var $correctImage = $('<img src=\'' + CORRECT_GLYPH_DATA_URL + '\' class=\'image-fill-in-the-blank-feedback\' alt=\'' + localizationStrings.getPlayerString(playerStringNames.txtCorrectString) + '\' />');
      var $incorrectImage = $('<img src=\'' + INCORRECT_GLYPH_DATA_URL + '\' class=\'image-fill-in-the-blank-feedback\' alt=\'' + localizationStrings.getPlayerString(playerStringNames.txtIncorrectString) + '\' />');

      if (question.isResponseCorrect) {
         $container.find('img.feedback-placeholder').replaceWith($correctImage);
      } else {
         $container.find('img.feedback-placeholder').replaceWith($incorrectImage);
      }
   };

   var getCorrectAnswerIndex = function() {
      var numberAnswers = question.answers.length;
      var correctAnswerBitshifted = question.correctAnswer;
      for (var i = 0; i < numberAnswers; i++) {
         /*eslint no-bitwise: "off"*/
         if (1 << i === Number(correctAnswerBitshifted)) {
            return i;
         }
      }

      return -1;
   };

   var displayChoiceResults = function() {
      var correctAnswerIndex;
      var $placeHolderImgEls;
      var $userResponseInputImageEl;
      var $correctResponseInputImageEl;
      var $correctImage;
      var $incorrectImage;
      var answerIndex = question.userResponse;
      var $labelEls = $container.find('label');

      $labelEls.removeClass(ACTIVE_QUESTION_CLASS);
      $labelEls.addClass(ACTIVE_FEEDBACK_CLASS);

      if (showResults && question.isGraded) {
         correctAnswerIndex = getCorrectAnswerIndex();
         $placeHolderImgEls = $container.find('label img');
         $userResponseInputImageEl = $container.find('label:eq(' + answerIndex + ') img');
         $correctResponseInputImageEl = $container.find('label:eq(' + correctAnswerIndex + ') img');
         $correctImage = $('<img src=\'' + CORRECT_GLYPH_DATA_URL + '\' class=\'image-feedback\' alt=\'' + localizationStrings.getPlayerString(playerStringNames.txtCorrectString) + '\' />');
         $incorrectImage = $('<img src=\'' + INCORRECT_GLYPH_DATA_URL + '\' class=\'image-feedback\' alt=\'' + localizationStrings.getPlayerString(playerStringNames.txtIncorrectString) + '\' />');

         $placeHolderImgEls.removeClass('feedback-placeholder');

         if (answerIndex === correctAnswerIndex) {
            $userResponseInputImageEl.replaceWith($correctImage);
            $correctImage.css('opacity', 0);
            $correctImage.animate({opacity: 1}, 200);
         } else {
            $correctResponseInputImageEl.replaceWith($correctImage);
            $correctImage.css('opacity', 0);
            $correctImage.animate({opacity: 1}, 200);

            $userResponseInputImageEl.replaceWith($incorrectImage);
            $incorrectImage.css('opacity', 0);
            $incorrectImage.animate({opacity: 1}, 200);
         }
      }

      $container.find('input').hide();

      if (answerIndex !== undefined) {
         $container.find('label:eq(' + answerIndex + ')').addClass('picked-quiz-item');
      }
   };

   var disableInput = function() {
      var $inputEls = null;
      switch (question.type) {
         case questionType.CHOICE:
         case questionType.FILL_IN:
            $inputEls = $container.find('input');
            break;
         case questionType.SHORT:
            $inputEls = $container.find('textarea');
            break;
      }
      if ($inputEls) {
         $inputEls.attr('disabled', true);
      }
   };

   var focusFirstInput = function() {
      $container.find(`.${cssClasses.focusableAnswerInput}`).focus();
   };

   var displayResults = function() {
      switch (question.type) {
         case questionType.CHOICE:
            displayChoiceResults();
            break;
         case questionType.FILL_IN:
            displayResponseFillInResults();
            break;
      }
      disableInput();
   };

   var getAnswersMarkup = function(autoFocus) {
      if (deviceInfo.isIOS() || deviceInfo.isAndroid()) {
         autoFocus = false;
      }

      switch (question.type) {
         case questionType.CHOICE:
            return templateGenerator.generateChoiceAnswersMarkup(question.text, question.answers, question.userResponse);
         case questionType.FILL_IN:
            return templateGenerator.generateFillInTheBlankAnswerMarkup(question.text, question.userResponse, autoFocus);
         case questionType.SHORT:
            return templateGenerator.generateShortAnswerMarkup(question.text, question.userResponse, autoFocus);
         default:
            log.logException('Answers-view: unsupported question type (' + question.type + ')');
      }
   };

   var destroy = function() {
      if (!showResults && deviceInfo.isIPhoneOrIPod() || deviceInfo.isAndroid()) {
         _$window.off('resize', viewHelper.scrollActiveElementIntoView);
      }

      if (_$textInput &&
         (question.type === questionType.FILL_IN || question.type === questionType.SHORT) &&
         (deviceInfo.isIPhoneOrIPod() || deviceInfo.isAndroid())) {
         _$textInput.off('focus keydown', viewHelper.scrollIntoView);
      }
   };

   var getTextResponseSuffix = function(userAnsweredQuestion) {
      if (question.isGraded) {
         if (!userAnsweredQuestion) {
            return localizationStrings.getPlayerString(playerStringNames.accessResultsLabelNoResponseGradedSuffix);
         } else if (question.isResponseCorrect) {
            return localizationStrings.getPlayerString(playerStringNames.accessResultsLabelCorrectSuffix);
         }
         return localizationStrings.getPlayerString(playerStringNames.accessResultsLabelIncorrectSuffix);
      } else if (!userAnsweredQuestion) {
         return localizationStrings.getPlayerString(playerStringNames.accessResultsLabelNoResponseSuffix);
      }
      return '';
   };

   var getUsersAnswer = function(userAnsweredQuestion) {
      if (question.type === questionType.CHOICE) {
         return userAnsweredQuestion && question.answers[question.userResponse].text;
      }
      return question.userResponse;
   };

   var getCorrectAnswerOrEmptyString = function() {
      if (question.isGraded && question.type === questionType.CHOICE) {
         return question.answers[getCorrectAnswerIndex()].text;
      }
      return '';
   };

   var getCorrectAnswerText = function() {
      var userAnsweredQuestion = !(question.userResponse === null || typeof question.userResponse === 'undefined');
      var responseSuffix = getTextResponseSuffix(userAnsweredQuestion);
      var usersAnswer = getUsersAnswer(userAnsweredQuestion);
      var correctAnswer = getCorrectAnswerOrEmptyString();
      var fullAnswerText = '';

      if (correctAnswer) {
         fullAnswerText = CORRECT_ANSWER_TEXT + correctAnswer;
      }

      if (userAnsweredQuestion) {
         fullAnswerText += correctAnswer ? ' - ' : '';
         fullAnswerText += YOUR_ANSWER_TEXT + usersAnswer;
      }

      fullAnswerText += fullAnswerText && responseSuffix ? ' - ' : '';
      fullAnswerText += responseSuffix;

      return fullAnswerText;
   };

   var setupAnswers = function() {
      if (showResults) {
         displayResults();
      } else {
         if (deviceInfo.isIPhoneOrIPod() || deviceInfo.isAndroid()) {
            _$window.on('resize', viewHelper.scrollActiveElementIntoView);
         }

         // remove hover css when device is touch screen
         if (question.type === questionType.CHOICE && deviceInfo.isTouchInterface()) {
            $container.find('.multiple-choice').removeClass(ACTIVE_QUESTION_CLASS);
         }

         _$textInput = $container.find('input[type=text], textarea');
         if (_$textInput && (question.type === questionType.FILL_IN || question.type === questionType.SHORT)) {
            if (deviceInfo.isIPhoneOrIPod() || deviceInfo.isAndroid()) {
               _$textInput.on('focus keydown', viewHelper.scrollIntoView);
            }
         }
      }
   };

   var isTextInputOrAreaFocused = function() {
      return _$textInput && _$textInput.length > 0 && _$textInput.is(':focus');
   };

   var createView = function() {
      var answersMarkup = getAnswersMarkup();
      if (answersMarkup) {
         $container.html(answersMarkup);
      }
      $container.find('.multiple-choice > input').focus(function() {
         $(this).parent().addClass('highlight-background');
      }).blur(function() {
         $(this).parent().removeClass('highlight-background');
      });
   };

   createView();
   setupAnswers();
   focusFirstInput();

   return {
      getUserResponse: getUserResponse,
      destroy: destroy,
      getCorrectAnswerText: getCorrectAnswerText,
      isTextInputOrAreaFocused: isTextInputOrAreaFocused
   };
};

export default {
   /**
    *  Factory method that returns a new QuestionView object.
    *  @function create
    *  @memberof TSC.AnswersView
    *  @static
    *  @return new AnswersView instance
    */
   create: AnswersView
};
