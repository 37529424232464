import events from '../constants/events';
import highlightDisabler from '../utils/highlight-disabler';
import templateGenerator from '../utils/template-generator';
import viewHelper from '../utils/view-helper';
import tabIndex from '../constants/tab-index';
import supportedPluginViewElements from '../constants/supported-plugin-view-elements';

var rightParenRegex = /\)/gi;
var leftParenRegex = /\(/gi;

var playReplayControlStates = Object.freeze({
   none: 'none',
   playVideoDisplayed: 'playVideo',
   replayVideoDisplayed: 'replayVideo'
});

export {playReplayControlStates};

/**
 * @memberof TSC
 * @class BeforeAfterPlayControlsView
 * @param {jQuery} $container - Smart Player root dom element
 * @param {Object} playerConfiguration - Player configuration object
 * @classdesc View containing the large play video and replay video buttons at the beginning and end of videos
 */
var BeforeAfterPlayControlsView = function($container, playerConfiguration) {
   var _markup;
   var _$videoClickToPlayLink;
   var _$videoClickToReplayLink;
   var _$videoClickToPlayGraphic;
   var _$videoClickToReplayGraphic;
   var _$pluginContainer;
   var _viewState = playReplayControlStates.none;

   var onPlayHandler = function(e) {
      e.preventDefault();
      e.stopPropagation();
      if (playerConfiguration.disableInteractions) {
         return;
      }
      hideVideoClickToPlayLink();
      $container.trigger(events.Controls.HeroPlayControlClicked);
   };

   var showVideoClickToPlayLink = function() {
      _viewState = playReplayControlStates.playVideoDisplayed;
      _$videoClickToPlayLink.show();
      $container.trigger(events.Controls.HeroPlayControlVisible);
   };

   var hideVideoClickToPlayLink = function() {
      _viewState = playReplayControlStates.none;
      _$videoClickToPlayLink.hide();
      $container.trigger(events.Controls.HeroPlayControlHidden);
   };

   var setVideoClickToPlayErrorState = function(state) {
      if (state) {
         _$videoClickToPlayLink.addClass('video-click-to-play-error');
      } else {
         _$videoClickToPlayLink.removeClass('video-click-to-play-error');
      }
   };

   var hideVideoClickToReplayLink = function() {
      if (_viewState === playReplayControlStates.replayVideoDisplayed) {
         _viewState = playReplayControlStates.none;
         _$videoClickToReplayLink.hide();
         $container.trigger(events.Controls.ReplayControlHidden);
      }
   };

   var showVideoClickToReplayLink = function() {
      _viewState = playReplayControlStates.replayVideoDisplayed;
      _$videoClickToReplayLink.show();
      $container.trigger(events.Controls.ReplayControlVisible);

      if (viewHelper.elementOrChildHasFocus($container)) {
         _$videoClickToReplayGraphic.focus();
         highlightDisabler.disableHighlights(_$videoClickToReplayGraphic);
      }
   };

   var addVideoClickToPlayBackground = function(posterSrc) {
      posterSrc = posterSrc.replace(rightParenRegex, '\\\)');
      posterSrc = posterSrc.replace(leftParenRegex, '\\\(');

      var url = 'url(' + posterSrc + ')';
      _$videoClickToPlayLink.css('background-image', url);
      _$videoClickToReplayLink.css('background-image', url);
   };

   var replayHandler = function(e) {
      e.stopPropagation();
      hideVideoClickToReplayLink();
      $container.trigger(events.Media.Replay);
   };

   var disableControls = function() {
      _$videoClickToReplayGraphic && _$videoClickToReplayGraphic.attr('tabindex', tabIndex.Disabled);
   };

   var enableControls = function() {
      _$videoClickToReplayGraphic && _$videoClickToReplayGraphic.attr('tabindex', tabIndex.DomOrder);
   };

   var toggleFullscreen = function() {
      $container.trigger(events.Controls.ToggleFullScreen);
   };

   var createView = function() {
      _markup = templateGenerator.generateBeforeAfterPlayControlsMarkup();
      $container.find('.before-after-play-controls').html(_markup);

      _$videoClickToPlayLink = $container.find('.video-click-to-play-link');
      _$videoClickToReplayLink = $container.find('.video-click-to-replay-link');
      _$videoClickToPlayGraphic = $container.find('.video-click-to-play');
      _$videoClickToReplayGraphic = $container.find('.video-click-to-replay');
      _$pluginContainer = $container.find('.before-after-plugin-container');

      _$videoClickToReplayLink.hide();

      if (viewHelper.elementOrChildHasFocus($container)) {
         _$videoClickToPlayGraphic.focus();
         highlightDisabler.disableHighlights(_$videoClickToPlayGraphic);
      }
   };

   var initPlugins = function(event, data) {
      var pluginController = playerConfiguration.getPluginController();
      if (pluginController && data.viewElementType === supportedPluginViewElements.BEFORE_AFTER_PLAY_CONTROLS) {
         pluginController.initializePluginsForViewElement(supportedPluginViewElements.BEFORE_AFTER_PLAY_CONTROLS, _$pluginContainer);
      }
   };

   var bindEvents = function() {
      $container.on(events.Media.TryToAutoPlay, hideVideoClickToPlayLink);
      $container.on(events.Modals.Open, disableControls);
      $container.on(events.Modals.Closed, enableControls);
      $container.on(events.Plugin.Added, initPlugins);
      _$videoClickToReplayLink.on('dblclick', toggleFullscreen);
      _$videoClickToReplayGraphic.on('click', replayHandler);
      _$videoClickToPlayLink.on('click', onPlayHandler);
   };

   var disableClickToPlayEvents = function() {
      _$videoClickToPlayLink.addClass('noClickToPlayEvents');
   };

   var destroy = function() {
      $container.off(events.Media.TryToAutoPlay, hideVideoClickToPlayLink);
      $container.off(events.Modals.Open, disableControls);
      $container.off(events.Modals.Closed, enableControls);
      $container.off(events.Plugin.Added, initPlugins);
      _$videoClickToReplayLink.off('dblclick', toggleFullscreen);
      _$videoClickToReplayGraphic.off('click', replayHandler);
      _$videoClickToPlayLink.off('click', onPlayHandler);
   };

   createView();
   bindEvents();

   return Object.defineProperties({
      showVideoClickToPlayLink: showVideoClickToPlayLink,
      hideVideoClickToPlayLink: hideVideoClickToPlayLink,
      setVideoClickToPlayErrorState: setVideoClickToPlayErrorState,
      hideVideoClickToReplayLink: hideVideoClickToReplayLink,
      showVideoClickToReplayLink: showVideoClickToReplayLink,
      addVideoClickToPlayBackground: addVideoClickToPlayBackground,
      disableClickToPlayEvents: disableClickToPlayEvents,
      destroy: destroy
   },
   {
      viewState: {
         get: function() {
            return _viewState;
         }
      }
   });
};

export default {
   /**
    *  Factory method that returns a new BeforeAfterPlayControlsView object.
    *  @function create
    *  @memberof TSC.BeforeAfterPlayControlsView
    *  @static
    *  @param {jQuery} $container - Smart Player root dom element
    *  @param {Object} playerConfiguration - Player configuration object
    *  @return new BeforeAfterPlayControlsView instance
    */
   create: BeforeAfterPlayControlsView
};
