import localizationStrings from '../models/localization-strings';

const timecodeDelimiter = ':';
const maxSupportTimecodePlaces = 3;
const secondsMultiplier = 60;
const millisecondsMultiplier = 1000;

const padIfNecessary = (num) => {
   return num >= 10 ? num : `0${num}`;
};

const getHourMinuteSecondsFromTimeInSeconds = (timeInSeconds) => {
   timeInSeconds = Math.round(timeInSeconds);
   const hours = Math.floor(timeInSeconds / 3600);
   const secondsWithoutHours = timeInSeconds % 3600;
   const minutes = Math.floor(secondsWithoutHours / 60);
   const seconds = secondsWithoutHours % 60;
   return {
      hours: hours,
      minutes: minutes,
      seconds: seconds
   };
};

const formatTime = (timeInSeconds) => {
   const timeComponents = getHourMinuteSecondsFromTimeInSeconds(timeInSeconds);

   if (timeComponents.hours > 0) {
      return `${timeComponents.hours}:${padIfNecessary(timeComponents.minutes)}:${padIfNecessary(timeComponents.seconds)}`;
   } else if (timeComponents.minutes > 0) {
      return `${timeComponents.minutes}:${padIfNecessary(timeComponents.seconds)}`;
   }
   return `0:${padIfNecessary(timeComponents.seconds)}`;
};

const formatTimeForReadability = (timeInSeconds) => {
   const timeComponents = getHourMinuteSecondsFromTimeInSeconds(timeInSeconds);

   return localizationStrings.getReadableTimeByLocale(timeComponents.hours, timeComponents.minutes, timeComponents.seconds);
};

const formatTimeForCmiTimespan = (timeInSeconds) => {
   const timeComponents = getHourMinuteSecondsFromTimeInSeconds(timeInSeconds);
   return `${padIfNecessary(timeComponents.hours)}:${padIfNecessary(timeComponents.minutes)}:${padIfNecessary(timeComponents.seconds)}`;
};

const formatTimeAsTimeInterval = (timeInSeconds) => {
   const timeComponents = getHourMinuteSecondsFromTimeInSeconds(timeInSeconds);
   let timeInterval = 'PT';

   if (timeComponents.hours > 0) {
      timeInterval += timeComponents.hours + 'H';
   }

   if (timeComponents.minutes > 0) {
      timeInterval += timeComponents.minutes + 'M';
   }

   timeInterval += timeComponents.seconds + 'S';

   return timeInterval;
};

const convertTimecodeToMilliseconds = (timecode) => {
   if (!timecode || typeof timecode !== 'string') {
      return 0;
   }
   let timeInSeconds = 0;
   let multiplier = 1;
   const timecodeParts = timecode.split(timecodeDelimiter);
   const lowTimeBounds = timecodeParts.length > maxSupportTimecodePlaces ? timecodeParts.length - maxSupportTimecodePlaces : 0;

   for (let i = timecodeParts.length - 1; i >= lowTimeBounds; i--) {
      let timePartAsNumber = parseInt(timecodeParts[i], 10);
      if (!isNaN(timePartAsNumber)) {
         timeInSeconds += timePartAsNumber * multiplier;
      }
      multiplier *= secondsMultiplier;
   }

   return timeInSeconds * millisecondsMultiplier;
};

export default {
   /**
    *  Utility function for formatting a number seconds into a user-readable duration
    *  @function formatTime
    *  @memberof TSC.timeFormatter
    *  @static
    *  @param {Number} totalSeconds - numbers of seconds to format
    *  @returns {String} time formatted as string (ex: 132 would return '02:12')
    */
   formatTime: formatTime,
   /**
    *  Utility function for formatting a number seconds into a human readable string
    *  @function formatTimeForReadability
    *  @memberof TSC.timeFormatter
    *  @static
    *  @param {Number} totalSeconds - numbers of seconds to format
    *  @returns {String} seconds formatted as string (ex: 132 would return '2 minutes and 12 seconds')
    */
   formatTimeForReadability: formatTimeForReadability,
   /**
    *  Utility function for formatting number of seconds into CMI timespan format used for SCORM 1.2 reporting
    *  @function formatTimeForCmiTimespan
    *  @memberof TSC.timeFormatter
    *  @static
    *  @param {Number} totalSeconds - numbers of seconds to format
    *  @returns {String} time formatted as CMI Timespan (ex: 132 would return '00:02:12')
    */
   formatTimeForCmiTimespan: formatTimeForCmiTimespan,
   /**
    *  Utility function for formatting number of seconds into Time Interval format used for SCORM 2004 reporting
    *  @function formatTimeAsTimeInterval
    *  @memberof TSC.timeFormatter
    *  @static
    *  @param {Number} totalSeconds - numbers of seconds to format
    *  @returns {String} time formatted as Time Interval (Ex: 132 would return 'PT2M12S')
    */
   formatTimeAsTimeInterval: formatTimeAsTimeInterval,
   /**
    *  Utility function will take a timecode string and return the time in milliseconds.
    *  @function convertTimecodeToMilliseconds
    *  @memberof TSC.timeFormatter
    *  @static
    *  @param {String} timecode - String timecode HH:MM:SS (ex: 01:12:33)
    *  @returns {Number} time in milliseconds
    */
   convertTimecodeToMilliseconds: convertTimecodeToMilliseconds
};
