import cssClasses from '../constants/css-classes';
import events from '../constants/events';
import tabIndex from '../constants/tab-index';
import templateGenerator from '../utils/template-generator';

/**
 * @memberof TSC
 * @class AudioDescriptionControlView
 * @classdesc Audio Description Control View
 * @param {Object} $container - jQuery element that contains a .audio-description-control-wrapper element where the control will be injected
 */
var AudioDescriptionControlView = function($container) {
   var _audioDescriptionEnabled = false;
   var _$audioDescriptionButton;
   var _$audioDescriptionGlyph;

   var createView = function() {
      var _markup = templateGenerator.generateAudioDescriptionButtonMarkup();
      $container.find('.audio-description-control-wrapper').html(_markup);
      _$audioDescriptionButton = $container.find('.audio-description-button');
      _$audioDescriptionGlyph = $container.find('.audio-description-button-glyph');
   };

   var setAudioDescriptionState = function(audioDescriptionEnabled) {
      _audioDescriptionEnabled = audioDescriptionEnabled;

      if (_audioDescriptionEnabled) {
         _$audioDescriptionButton.attr('aria-pressed', true);
         _$audioDescriptionGlyph.removeClass(cssClasses.audioDescriptionButtonGlyph).addClass(cssClasses.audioDescriptionButtonActiveGlyph);
         _$audioDescriptionButton.removeClass(cssClasses.tertiaryButton).addClass(cssClasses.tertiaryButtonActive);
      } else {
         _$audioDescriptionButton.attr('aria-pressed', false);
         _$audioDescriptionGlyph.removeClass(cssClasses.audioDescriptionButtonActiveGlyph).addClass(cssClasses.audioDescriptionButtonGlyph);
         _$audioDescriptionButton.removeClass(cssClasses.tertiaryButtonActive).addClass(cssClasses.tertiaryButton);
      }
   };

   var onButtonClick = function(e) {
      e.preventDefault();
      $container.trigger(events.AudioDescription.Changed, {audioDescriptionEnabled: !_audioDescriptionEnabled});
   };

   var onAudioDescriptionChange = function(e, eventData) {
      setAudioDescriptionState(eventData.audioDescriptionEnabled);
   };

   var audioDescriptionFailedToPlay = function() {
      _$audioDescriptionButton.attr('disabled', true);
   };

   var disableControl = function() {
      _$audioDescriptionButton.attr('tabindex', tabIndex.Disabled);
   };

   var enableControl = function() {
      _$audioDescriptionButton.attr('tabindex', tabIndex.DomOrder);
   };

   var initializeHandlers = function() {
      _$audioDescriptionButton.on('click', onButtonClick);
      $container.on(events.AudioDescription.Failed, audioDescriptionFailedToPlay);
      $container.on(events.Controls.Disable, disableControl);
      $container.on(events.Controls.Enable, enableControl);
      $container.on(events.AudioDescription.Changed, onAudioDescriptionChange);
   };

   var destroy = function() {
      $container.off(events.AudioDescription.Failed, audioDescriptionFailedToPlay);
      $container.off(events.Controls.Disable, disableControl);
      $container.off(events.Controls.Enable, enableControl);
      $container.off(events.AudioDescription.Changed, onAudioDescriptionChange);
      _$audioDescriptionButton.off('click', onButtonClick);
   };

   createView();
   initializeHandlers();

   return Object.defineProperties({
      destroy: destroy
   }, {});
};

export default {
   /**
    *  Factory method that returns a new AudioDescriptionControlView object.
    *  @function create
    *  @memberof TSC.AudioDescriptionControlView
    *  @static
    *  @param {Object} $container - jQuery element that contains a .audio-description-control-wrapper element where the control will be injected
    *  @return new AudioDescriptionControlView instance
    */
   create: AudioDescriptionControlView
};
