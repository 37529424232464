import VideoElementView from './video-element-view';
import events from '../../constants/events';
import videoAnalytics from '../../utils/video-analytics';
import playerControlsType from '../../constants/player-controls-type';
import mediaViewType from '../../constants/media-view-type';

/**
 *  @memberof TSC
 *  @implement MediaView
 *  @class PlaylistElementView
 *  @classdesc HTML 5 Video element view
 *  @param {jQuery} $rootContainer - Smart Player main container jquery element
 *  @param {jQuery} $mediaContainer - Smart Player media container jquery element
 *  @param {MediaList} mediaList - the media sources to use when creating the view
 *  @param {Boolean} includeControls - Include the built in controls for the video element
 *  @param {Function} triggerRumDataEvent - Callback function to use when triggering rum data event
 *  @param {Function} firstPlayCallback - callback function called on the first play click
 *  @param {Boolean} [includePosterIfAvailable=false] - Include the poster image if available
 */
var PlaylistElementView = function($rootContainer, $mediaContainer, mediaList, includeControls, triggerRumDataEvent, firstPlayCallback, includePosterIfAvailable) {
   var _videoElementView = VideoElementView.create($rootContainer, $mediaContainer, mediaList.mediaList, includeControls, triggerRumDataEvent, firstPlayCallback, includePosterIfAvailable);

   var initCurrentMedia = function() {
      _videoElementView.mediaElement.src = mediaList.currentMedia.media;

      if (videoAnalytics.isEnabled()) {
         videoAnalytics.clearPercentWatched();
      }

      $mediaContainer.trigger(events.Media.InitializeCurrent, {
         enablePreviousButton: !mediaList.onFirstMedia,
         enableNextButton: !mediaList.onLastMedia
      });
   };

   var clickPrevHandler = function() {
      if (!mediaList.onFirstMedia) {
         mediaList.currentMediaIndex = 'previous';
         initCurrentMedia();
      }
   };

   var clickNextHandler = function() {
      if (!mediaList.onLastMedia) {
         mediaList.currentMediaIndex = 'next';
         initCurrentMedia();
      }
   };

   var processVideoEnd = function() {
      var wasPlaylistLastMediaItem = mediaList.onLastMedia;

      if (!wasPlaylistLastMediaItem) {
         mediaList.currentMediaIndex = 'next';
         initCurrentMedia();
      }

      return wasPlaylistLastMediaItem;
   };

   var restartPlaybackAtBeginning = function() {
      mediaList.currentMediaIndex = 0;
      initCurrentMedia();
   };

   var destroy = function() {
      $rootContainer.off(events.Controls.ClickPrev, clickPrevHandler);
      $rootContainer.off(events.Controls.ClickNext, clickNextHandler);
      $rootContainer.off(events.Media.SourceChanged, initCurrentMedia);

      _videoElementView.destroy();
   };

   var hotfixViewAndControlsCSS = function($controlsView) {}; // eslint-disable-line

   $rootContainer.on(events.Controls.ClickNext, clickNextHandler);
   $rootContainer.on(events.Controls.ClickPrev, clickPrevHandler);
   $rootContainer.on(events.Media.SourceChanged, initCurrentMedia);

   return Object.defineProperties({
      hotfixViewAndControlsCSS: hotfixViewAndControlsCSS,
      showCaptionTrack: _videoElementView.showCaptionTrack,
      hideCaptionTrack: _videoElementView.hideCaptionTrack,
      addEventListener: _videoElementView.addEventListener,
      removeEventListener: _videoElementView.removeEventListener,
      reshowVideoAfterClickToReplay: restartPlaybackAtBeginning,
      processVideoEnd: processVideoEnd,
      loadMedia: _videoElementView.loadMedia,
      destroy: destroy
   }, {
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.PlaylistElementView
       *  @property {jQuery} mediaWrapper - jQuery wrapper of the video element.
       */
      mediaWrapper: {
         get: function() {
            return _videoElementView.mediaWrapper;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.PlaylistElementView
       *  @property {HTMLVideoElement} mediaElement - HTML video element
       */
      mediaElement: {
         get: function() {
            return _videoElementView.mediaElement;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.PlaylistElementView
       *  @property {Boolean} containsWebTrackCaptions - Does the video contain web track captions?
       */
      containsWebTrackCaptions: {
         get: function() {
            return _videoElementView.containsWebTrackCaptions;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.PlaylistElementView
       *  @property {int} currentTimeInMilliseconds - The current time of the media, in milliseconds
       */
      currentTimeInMilliseconds: {
         get: function() {
            return _videoElementView.currentTimeInMilliseconds;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.PlaylistElementView
       *  @property {Number} mediaWidth - video width
       */
      mediaWidth: {
         get: function() {
            return _videoElementView.mediaWidth;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.PlaylistElementView
       *  @property {Number} mediaHeight - video height
       */
      mediaHeight: {
         get: function() {
            return _videoElementView.mediaHeight;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.PlaylistElementView
       *  @property {Boolean}  - allows dragging extension content
       */
      canDragContentOnVideo: {
         get: function() {
            return true;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.PlaylistElementView
       *  @property {string} playerControlType - Type of player controls to use
       */
      playerControlType: {
         get: function() {
            return playerControlsType.videoPlaylist;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.PlaylistElementView
       *  @property {string} type - Media view type
       */
      type: {
         get: function() {
            return mediaViewType.playlist;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.PlaylistElementView
       *  @property {boolean} supportsClickToPlayControls - Does view support click to play controls over media
       */
      supportsClickToPlayControls: {
         get: function() {
            return true;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.PlaylistElementView
       *  @property {boolean} supportsSCORM - Does view support SCORM
       */
      supportsSCORM: {
         get: function() {
            return false;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.PlaylistElementView
       *  @property {TSC.VideoPlaybackQualityMetrics} playbackQualityMetrics - the playback quality metrics set up for this view (may be null)
       */
      playbackQualityMetrics: {
         get: function() {
            return _videoElementView.playbackQualityMetrics;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.PlaylistElementView
       *  @property {string} currentSrc - The current source path of the media
       */
      currentSrc: {
         get: function() {
            return _videoElementView.currentSrc;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.PlaylistElementView
       *  @property {Number} currentTime - The current time for the media
       */
      currentTime: {
         get: function() {
            return _videoElementView.currentTime;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.PlaylistElementView
       *  @property {Number} duration - The duration of the media
       */
      duration: {
         get: function() {
            return _videoElementView.duration;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.PlaylistElementView
       *  @property {Boolean} ended - Has the media ended
       */
      ended: {
         get: function() {
            return _videoElementView.ended;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.PlaylistElementView
       *  @property {Boolean} paused - Is the media paused
       */
      paused: {
         get: function() {
            return _videoElementView.paused;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.PlaylistElementView
       *  @property {Boolean} seeking - Is the media seeking
       */
      seeking: {
         get: function() {
            return _videoElementView.seeking;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.PlaylistElementView
       *  @property {Number} volume - The volume of the media
       */
      volume: {
         get: function() {
            return _videoElementView.volume;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.PlaylistElementView
       *  @property {Boolean} muted - Is the media muted
       */
      muted: {
         get: function() {
            return _videoElementView.muted;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.PlaylistElementView
       *  @property {Number} playbackRate - Playback rate of the media
       */
      playbackRate: {
         get: function() {
            return _videoElementView.playbackRate;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.PlaylistElementView
       *  @property {Array} buffered - THe array of buffered segments
       */
      buffered: {
         get: function() {
            return _videoElementView.buffered;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.PlaylistElementView
       *  @property {Array} played - THe array of media segments that have been played
       */
      played: {
         get: function() {
            return _videoElementView.played;
         }
      },
      /**
       *  @public
       *  @readonly
       *  @memberof TSC.PlaylistElementView
       *  @property {Array} seekable - THe array of media segments that are seekable
       */
      seekable: {
         get: function() {
            return _videoElementView.seekable;
         }
      }
   });
};

export default {
   create: PlaylistElementView
};
