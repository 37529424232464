import quizService from '../services/quiz-service';

/**
 * @memberof TSC
 * @class QuizProgressTracker
 * @classdesc Quiz progress tracker manages quiz progress and processing played segments
 * @param {HTMLVideoElement} videoToTrack - The video element to monitor for progress.
 * @param {int} sampleRateInMilliseconds
 * @param {int} reportingRateInMilliseconds
 *
 */
var QuizProgressTracker = function(videoToTrack, sampleRateInMilliseconds, reportingRateInMilliseconds) {
   var _didAttachToEndedEvent = false;
   var _didAttachToPlayEvent = false;
   var _intervalId;
   var _playCallbackFunction;
   var _mergeToleranceInMilliseconds = 500;
   var _videoEndToleranceInMilliseconds = 1000;

   var processPlayedSegments = function(played) {
      var numberPlayedSegments = played.length;
      if (numberPlayedSegments < 1) {
         return [];
      }
      var mergedPlayedInMilliseconds = [{start: played.start(0) * 1000, end: played.end(0) * 1000}];
      var mergedPlayedIndex = 0;
      var i;

      for (i = 1; i < numberPlayedSegments; i++) {
         if (mergedPlayedInMilliseconds[mergedPlayedIndex].end >= played.start(i) * 1000 - _mergeToleranceInMilliseconds) {
            mergedPlayedInMilliseconds[mergedPlayedIndex].end = played.end(i) * 1000;
         } else {
            mergedPlayedInMilliseconds.push({start: played.start(i) * 1000, end: played.end(i) * 1000});
            mergedPlayedIndex++;
         }
      }

      return mergedPlayedInMilliseconds;
   };

   var computePercentageViewed = function(video) {
      var completedSamples = [];
      var i;
      var j;
      var videoStartMilliseconds;
      var videoEndMilliseconds;
      var isEndOfVideo;
      var firstSample;
      var lastSample;
      var entireVideoWasWatched;
      var mergedPlayedInMilliseconds;
      var videoDurationInMilliseconds = video.duration * 1000;

      mergedPlayedInMilliseconds = processPlayedSegments(video.played);

      for (i = 0; i < mergedPlayedInMilliseconds.length; i++) {
         videoStartMilliseconds = mergedPlayedInMilliseconds[i].start;
         videoEndMilliseconds = mergedPlayedInMilliseconds[i].end;

         isEndOfVideo = videoDurationInMilliseconds - video.currentTime * 1000 < _videoEndToleranceInMilliseconds;

         if (!isEndOfVideo && videoEndMilliseconds - videoStartMilliseconds < sampleRateInMilliseconds) {
            continue;
         }
         firstSample = Math.floor(videoStartMilliseconds / sampleRateInMilliseconds);
         if (videoStartMilliseconds % sampleRateInMilliseconds !== 0) {
            firstSample += 1;
         }

         lastSample = Math.floor(videoEndMilliseconds / sampleRateInMilliseconds);
         if (!isEndOfVideo) {
            lastSample -= 1;
         }

         if (lastSample < firstSample) {
            continue;
         }

         for (j = firstSample; j <= lastSample; j++) {
            completedSamples.push(j);
         }
      }

      quizService.sendProgress(sampleRateInMilliseconds, completedSamples);

      entireVideoWasWatched = completedSamples.length === Math.ceil(videoDurationInMilliseconds / sampleRateInMilliseconds);
      if (entireVideoWasWatched) {
         stopInterval();
         video.removeEventListener('play', _playCallbackFunction, false);
      }
   };

   var attachToEndedEvent = function(video) {
      var endedCallbackFunction = function() {
         sendProgress(video);
         stopInterval();
      };
      video.addEventListener('ended', endedCallbackFunction, false);
      _didAttachToEndedEvent = true;
   };

   var attachToPlayEvent = function(video) {
      _playCallbackFunction = function() {
         if (_intervalId !== -1) {
            return;
         }

         setupProgressInterval(video);
      };
      video.addEventListener('play', _playCallbackFunction, false);
      _didAttachToPlayEvent = true;
   };

   var sendProgress = function(video) {
      if (!video || video.duration === 0) {
         return;
      }

      if (!_didAttachToEndedEvent) {
         attachToEndedEvent(video);
      }

      if (!_didAttachToPlayEvent) {
         attachToPlayEvent(video);
      }

      computePercentageViewed(video);
   };

   var stopInterval = function() {
      if (_intervalId !== -1) {
         window.clearInterval(_intervalId);
         _intervalId = -1;
      }
   };

   var destroy = function() {
      stopInterval();
   };

   var setupProgressInterval = function(video) {
      var intervalFunction = function() {
         sendProgress(video);
         if (video.paused) {
            window.clearInterval(_intervalId);
            _intervalId = -1;
         }
      };
      _intervalId = window.setInterval(intervalFunction, reportingRateInMilliseconds);
   };

   stopInterval();
   setupProgressInterval(videoToTrack);

   return Object.defineProperties({
      destroy: destroy
   }, {
   });
};

export default {
   /**
    *  Factory method that returns a new QuizProgressTracker.
    *  @function create
    *  @memberof TSC.QuizProgressTracker
    *  @static
    *  @param {HTMLVideoElement} videoToTrack - The video element to monitor for progress.
    *  @param {int} sampleRateInMilliseconds
    *  @param {int} reportingRateInMilliseconds
    *  @return new QuizProgressTracker instance
    */
   create: QuizProgressTracker
};
