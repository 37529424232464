import templateGenerator from '../utils/template-generator';
import events from '../constants/events';

/**
 *  @memberof TSC.IoOverlayView
 *  @class IoOverlayView
 *  @classdesc IoOverlay View
 */
var IoOverlayView = function($container) {
   var _viewOpen = false;

   var markup = templateGenerator.generateIoOverlayMarkup();
   $container.append(markup);

   var _$ioContainer = $container.find('.tsc-io-container');
   var _$ioMessage = _$ioContainer.find('span');
   var _$ioImg = _$ioContainer.find('img');

   var setViewMessage = function(message) {
      _$ioMessage.html(message);
   };

   var hideLoadingGraphic = function() {
      _$ioImg.hide();
   };

   var showLoadingGraphic = function() {
      _$ioImg.show();
   };

   var showView = function() {
      _$ioContainer.stop()
         .css('opacity', 0)
         .show()
         .animate({opacity: 1}, 300);
      _viewOpen = true;
   };

   var hideView = function(completeCallback) {
      if (_viewOpen) {
         _viewOpen = false;
         _$ioContainer.stop().animate({opacity: 0}, 200, function() {
            if (completeCallback) {
               completeCallback.call();
            }
            _$ioContainer.hide();
         });
      }
   };

   var displayMessage = function(message, shouldHideLoadingGraphic) {
      setViewMessage(message);

      if (shouldHideLoadingGraphic) {
         hideLoadingGraphic();
      } else {
         showLoadingGraphic();
      }

      if (!_viewOpen) {
         showView();
      }
   };

   var destroy = function() {
      $container.off(events.Controls.HideIOOverlay, hideView);
   };

   var bindEvents = function() {
      $container.on(events.Controls.HideIOOverlay, hideView);
   };

   bindEvents();

   return Object.defineProperties({
      hideView: hideView,
      displayMessage: displayMessage,
      destroy: destroy
   }, {});
};

export default {
   /**
    *  Factory method that returns a new IoOverlayView object.
    *  @function create
    *  @memberof TSC.IoOverlayView
    *  @static
    *  @param {Object} $container - jQuery element that is the smartplayer top-level container
    *  @return new IoOverlayView instance
    */
   create: IoOverlayView
};
