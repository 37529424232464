import playerStringNames from '../constants/player-string-names';
import localizationStrings from '../models/localization-strings';
import localStorageWrapper from '../utils/local-storage-wrapper';
import deviceInfo from '../utils/device-info';
import events from '../constants/events';
import tabIndex from '../constants/tab-index';
import cssClasses from '../constants/css-classes';
import highlightDisabler from '../utils/highlight-disabler';
import templateGenerator from '../utils/template-generator';
import keys from '../constants/keys';

var VOLUME_CACHE_KEY = 'volume';
var MUTED_CACHE_KEY = 'muted';
var DEFAULT_VOLUME_VALUE = 1;
var VOLUME_SLIDER_MIN = 0;
var VOLUME_SLIDER_MAX = 100;
var MIN_VOLUME_POSITION_PERCENT = 0;
var MAX_VOLUME_POSITION_PERCENT = 1;
var MOUSEOUT_HIDE_SLIDER_TIMEOUT = 1000;
var FOCUSOUT_HIDE_SLIDER_TIMEOUT = 200;
var VOLUME_STRING = localizationStrings.getPlayerString(playerStringNames.accessTxtVolume);
var VOLUME_TRACK_WIDTH = 67;
var VOLUME_SLIDER_HALF_WIDTH = 6;
var KEYBOARD_VOLUME_MODIFIER = .1;
var MIN_VOLUME_PERCENT_HIGH_VOLUME_GLYPH = .7;
var MIN_VOLUME_PERCENT_MEDIUM_VOLUME_GLYPH = .3;

var getCachedVolumeValueAsValidNumber = function() {
   var cachedVolumeValue = localStorageWrapper.getItem(VOLUME_CACHE_KEY);
   if (cachedVolumeValue === undefined || cachedVolumeValue === null || +cachedVolumeValue > MAX_VOLUME_POSITION_PERCENT) {
      return DEFAULT_VOLUME_VALUE;
   }
   return +cachedVolumeValue;
};

/**
 * @memberof TSC
 * @class VolumeControlView
 * @classdesc VolumeControl View
 */
var VolumeControlView = function($container, mediaElement) {
   var _showVolumeSlider = false;
   var _secondaryControlsHidden = false;

   var _cachedVolume = getCachedVolumeValueAsValidNumber();
   var _cachedMuted = localStorageWrapper.getItem(MUTED_CACHE_KEY);
   var _sliderShowTimeoutId = -1;
   var _markup = templateGenerator.generateVolumeControlMarkup(_cachedVolume);

   var _$controlsContainer = $container.find('.player-controls');
   var _$primaryControlsContainer = $container.find('.primary-controls');
   var _$secondaryControlsContainer = $container.find('.secondary-controls');

   var _$volumeContainer = $container.find('.volume-wrapper');
   _$volumeContainer.html(_markup);
   var _$volumeSliderContainer = _$volumeContainer.find('.volume-slider-container');
   var _$volumeSlider = _$volumeContainer.find('.volume-slider');
   var _$volumeSliderTrack = _$volumeContainer.find('.volume-slider-track');
   var _$volumeControl = _$volumeContainer.find('.volume');
   var _initialVolume = _cachedVolume;
   var _initialMuted = _cachedMuted === 'true';
   var _sliderVolumeValue = _initialVolume;
   var _sliderActive = false;

   if (deviceInfo.isIOS() || deviceInfo.isAndroid()) {
      _$volumeControl.remove();
      _$volumeSliderContainer.remove();
   }

   var onKeyDownHandler = function(e) {
      if (keys.isKeyOfType(e.key, keys.keyTypes.upArrow) || keys.isKeyOfType(e.key, keys.keyTypes.downArrow)) {
         e.preventDefault();
      }
   };

   var onKeyHandler = function(e) {
      e.preventDefault();

      if (keys.isKeyOfType(e.key, keys.keyTypes.upArrow)) {
         setSliderValue(_sliderVolumeValue + KEYBOARD_VOLUME_MODIFIER);
         updateVolumeSliderPositionByVolume(_sliderVolumeValue);
         setVolume(_sliderVolumeValue);
      } else if (keys.isKeyOfType(e.key, keys.keyTypes.downArrow)) {
         setSliderValue(_sliderVolumeValue - KEYBOARD_VOLUME_MODIFIER);
         updateVolumeSliderPositionByVolume(_sliderVolumeValue);
         setVolume(_sliderVolumeValue);
      }
   };

   var clampVolumePercentage = function(volumeAsPercent) {
      volumeAsPercent = volumeAsPercent < MIN_VOLUME_POSITION_PERCENT ? MIN_VOLUME_POSITION_PERCENT : volumeAsPercent;
      volumeAsPercent = volumeAsPercent > MAX_VOLUME_POSITION_PERCENT ? MAX_VOLUME_POSITION_PERCENT : volumeAsPercent;
      return volumeAsPercent;
   };

   var setSliderValue = function(volumePercentageValue) {
      volumePercentageValue = clampVolumePercentage(volumePercentageValue);

      // do not store min volume, the volume will be muted then
      if (volumePercentageValue > MIN_VOLUME_POSITION_PERCENT) {
         _sliderVolumeValue = volumePercentageValue;
      }
   };

   var onSliderMouseDownHandler = function(e) {
      e.preventDefault();
      highlightDisabler.disableHighlights(_$volumeSliderContainer);

      var volumeSliderLeftOffset = _$volumeSliderTrack.offset().left;
      var pendingVolumeValue = _sliderVolumeValue;

      var updateSliderOnAnimationFrame = function() {
         updateVolumeSliderPositionByVolume(pendingVolumeValue);
         setVolume(pendingVolumeValue);

         if (_sliderActive) {
            window.requestAnimationFrame(updateSliderOnAnimationFrame);
         }
      };

      var updateSliderValue = function(pageX) {
         var currentDelta = pageX - volumeSliderLeftOffset;
         pendingVolumeValue = clampVolumePercentage(currentDelta / VOLUME_TRACK_WIDTH);
      };

      var onMouseMove = function(evt) {
         evt.preventDefault();
         updateSliderValue(evt.pageX);
      };

      var onMouseUp = function(evt) {
         evt.preventDefault();
         _sliderActive = false;
         setSliderValue(pendingVolumeValue);
         window.document.removeEventListener('mousemove', onMouseMove, true);
         window.document.removeEventListener('mouseup', onMouseUp, true);
      };

      window.document.addEventListener('mousemove', onMouseMove, true);
      window.document.addEventListener('mouseup', onMouseUp, true);

      updateSliderValue(e.pageX);
      _sliderActive = true;
      window.requestAnimationFrame(updateSliderOnAnimationFrame);
   };

   var updateVolumeSliderPositionByVolume = function(volumeFromMedia) {
      var volumeConverted = volumeFromMedia * VOLUME_SLIDER_MAX;
      var volumeSliderLeftCssValue = 'calc(' + volumeConverted + '% - ' + VOLUME_SLIDER_HALF_WIDTH + 'px)';
      var volumeTrackWidthCssValue = volumeConverted + '%';

      _$volumeSlider.css('left', volumeSliderLeftCssValue);
      _$volumeSliderTrack.css('width', volumeTrackWidthCssValue);
   };

   var showVolumeSlider = function() {
      _$volumeSliderContainer.removeClass(cssClasses.volumeSliderHidden);
      _showVolumeSlider = true;
      handleSecondaryControls();
   };

   var handleSecondaryControls = function() {
      var controlsWidth = _$primaryControlsContainer.width() + _$secondaryControlsContainer.width();
      var volumeSliderExpandedWidth = _$volumeSliderContainer.width() === 0 ? VOLUME_TRACK_WIDTH : 0;
      var shouldHideSecondaryControls = controlsWidth + volumeSliderExpandedWidth >= _$controlsContainer.width();

      if (_secondaryControlsHidden && !shouldHideSecondaryControls) {
         setSecondaryControlsVisibility(false);
      } else if (!_secondaryControlsHidden && shouldHideSecondaryControls) {
         setSecondaryControlsVisibility(true);
      }
   };

   var setSecondaryControlsVisibility = function(isHidden) {
      if (isHidden) {
         _$secondaryControlsContainer.addClass(cssClasses.hideSecondaryControls);
         _secondaryControlsHidden = true;
      } else {
         _$secondaryControlsContainer.removeClass(cssClasses.hideSecondaryControls);
         _secondaryControlsHidden = false;
      }
   };

   var hideControls = function() {
      if (_sliderShowTimeoutId !== -1) {
         hideVolumeSlider();
      }
   };

   var hideVolumeSlider = function() {
      _showVolumeSlider = false;

      if (_sliderShowTimeoutId !== -1) {
         clearTimeout(_sliderShowTimeoutId);
         _sliderShowTimeoutId = -1;
      }

      setSecondaryControlsVisibility(false);
      _$volumeSliderContainer.addClass(cssClasses.volumeSliderHidden);
   };

   var updateUI = function() {
      var volumeValue = mediaElement.muted ? 0 : Math.round(mediaElement.volume * VOLUME_SLIDER_MAX);

      _$volumeSlider.attr('aria-valuenow', volumeValue);
      _$volumeSlider.attr('aria-valuetext', volumeValue + '% ' + VOLUME_STRING);
      _$volumeControl.attr('aria-pressed', !!mediaElement.muted);

      _$volumeSlider.attr({value: volumeValue});
      _$volumeControl.removeClass(cssClasses.volumeHighButtonGlyph);
      _$volumeControl.removeClass(cssClasses.volumeMediumButtonGlyph);
      _$volumeControl.removeClass(cssClasses.volumeLowButtonGlyph);
      _$volumeControl.removeClass(cssClasses.volumeMutedButtonGlyph);

      if (volumeValue === MIN_VOLUME_POSITION_PERCENT) {
         _$volumeControl.addClass(cssClasses.volumeMutedButtonGlyph);
      } else if (mediaElement.volume > MIN_VOLUME_PERCENT_HIGH_VOLUME_GLYPH) {
         _$volumeControl.addClass(cssClasses.volumeHighButtonGlyph);
      } else if (mediaElement.volume > MIN_VOLUME_PERCENT_MEDIUM_VOLUME_GLYPH) {
         _$volumeControl.addClass(cssClasses.volumeMediumButtonGlyph);
      } else {
         _$volumeControl.addClass(cssClasses.volumeLowButtonGlyph);
      }
   };

   var setMuted = function(muted) {
      mediaElement.muted = muted;
      localStorageWrapper.setItem(MUTED_CACHE_KEY, muted);
   };

   var setVolume = function(newVolumeAsPercent) {
      newVolumeAsPercent = clampVolumePercentage(newVolumeAsPercent);

      if (newVolumeAsPercent === MIN_VOLUME_POSITION_PERCENT) {
         setMuted(true);
      } else {
         setMuted(false);
         localStorageWrapper.setItem(VOLUME_CACHE_KEY, newVolumeAsPercent);
         mediaElement.volume = newVolumeAsPercent;
      }
   };

   var startHideSliderTimeout = function(timeout) {
      if (_sliderShowTimeoutId !== -1) {
         clearTimeout(_sliderShowTimeoutId);
      }

      _sliderShowTimeoutId = setTimeout(function() {
         hideVolumeSlider();
         _sliderShowTimeoutId = -1;
      }, timeout);
   };

   var onContainerMouseOver = function() {
      if (_sliderShowTimeoutId !== -1) {
         clearTimeout(_sliderShowTimeoutId);
         _sliderShowTimeoutId = -1;
      }

      showVolumeSlider();
   };

   var onContainerMouseLeave = function(e) {
      if (e.buttons !== 1) {
         startHideSliderTimeout(MOUSEOUT_HIDE_SLIDER_TIMEOUT);
      }
   };

   var hideVolumeSliderOnFocusOut = function() {
      startHideSliderTimeout(FOCUSOUT_HIDE_SLIDER_TIMEOUT);
   };

   var onVolumeButtonClick = function() {
      setMuted(!mediaElement.muted);
      if (mediaElement.muted) {
         updateVolumeSliderPositionByVolume(0);
      } else {
         updateVolumeSliderPositionByVolume(_sliderVolumeValue);
         setVolume(_sliderVolumeValue);
      }
   };

   var disableControls = function() {
      _$volumeControl.attr('tabindex', tabIndex.Disabled);
      _$volumeSlider.attr('tabindex', tabIndex.Disabled);
   };

   var enableControls = function() {
      _$volumeControl.attr('tabindex', tabIndex.DomOrder);
      _$volumeSlider.attr('tabindex', tabIndex.DomOrder);
   };

   var initVolumeControls = function() {
      setVolume(_initialVolume);
      setMuted(_initialMuted);
      updateUI();
      updateVolumeSliderPositionByVolume(mediaElement.muted ? MIN_VOLUME_POSITION_PERCENT : _initialVolume);
   };

   var onResize = function() {
      if (_showVolumeSlider) {
         handleSecondaryControls();
      }
   };

   var onMediaVolumeChange = function() {
      if (!_sliderActive) {
         setSliderValue(mediaElement.volume);
         if (mediaElement.volume === VOLUME_SLIDER_MIN && !mediaElement.muted) {
            setMuted(true);
         }
      }
      updateUI();
   };

   var initializeHandlers = function() {
      _$volumeContainer.on('mousemove', onContainerMouseOver);
      _$volumeContainer.on('mouseout', onContainerMouseLeave);
      _$volumeSliderContainer.on('mousedown', onSliderMouseDownHandler);
      _$volumeControl.on('click', onVolumeButtonClick);
      _$volumeSlider.on('focus', showVolumeSlider);
      _$volumeSlider.on('focusout', hideVolumeSliderOnFocusOut);
      _$volumeSlider.on('keydown', onKeyDownHandler);
      _$volumeSlider.on('keyup', onKeyHandler);
      _$volumeSlider.attr('tabindex', tabIndex.DomOrder);
      _$volumeSlider.attr('aria-valuemin', VOLUME_SLIDER_MIN);
      _$volumeSlider.attr('aria-valuemax', VOLUME_SLIDER_MAX);
      _$volumeSlider.attr('role', 'slider');
      mediaElement.addEventListener('volumechange', onMediaVolumeChange);
      $container.on(events.Controls.Disable, disableControls);
      $container.on(events.Controls.Enable, enableControls);
      $container.on(events.Controls.PlayerResize, onResize);
   };

   var destroy = function() {
      _$volumeContainer.off('mousemove', onContainerMouseOver);
      _$volumeContainer.off('mouseout', onContainerMouseLeave);
      _$volumeSliderContainer.off('mousedown', onSliderMouseDownHandler);
      _$volumeControl.off('click', onVolumeButtonClick);
      _$volumeSlider.off('focus', showVolumeSlider);
      _$volumeSlider.off('focusout', hideVolumeSliderOnFocusOut);
      _$volumeSlider.off('keydown', onKeyDownHandler);
      _$volumeSlider.off('keyup', onKeyHandler);
      mediaElement.removeEventListener('volumechange', onMediaVolumeChange);
      $container.off(events.Controls.Disable, disableControls);
      $container.off(events.Controls.Enable, enableControls);
   };

   initializeHandlers();
   initVolumeControls();

   return Object.defineProperties({
      hideControls: hideControls,
      destroy: destroy
   }, {
      volume: {
         get: function() {
            return mediaElement.volume;
         },
         set: setVolume
      },
      muted: {
         get: function() {
            return mediaElement.muted;
         },
         set: setMuted
      }
   });
};

export default {
   /**
    *  Factory method that returns a new VolumeControlView object.
    *  @function create
    *  @memberof TSC.VolumeControlView
    *  @static
    *  @param {Object} $container - jQuery element that contains a .volume-wrapper element where the volume controls will be injected
    *  @param {Object} mediaElement - html5 media DOM element (or wrapper presenting same interface)
    *  @return new VolumeControlView instance
    */
   create: VolumeControlView
};
