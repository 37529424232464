import playerConfiguration from '../models/player-configuration';
import localizationStrings from '../models/localization-strings';
import QuizController from '../controllers/quiz-controller';
import Xmp from '../models/xmp';
import Quiz from '../models/quiz';
import VideoElementView from './media-views/video-element-view';
import templateGenerator from '../utils/template-generator';
import BeforeAfterPlayControlsView from '../views/before-after-play-controls-view';
import IoOverlayView from './io-overlay-view';

var CamtasiaQuizPreviewView = function($container, xmpString) {
   var xmpModel = Xmp.create();
   xmpModel.parse(xmpString);
   return xmpModel.ready.then(function() {
      playerConfiguration.configureFromXmp(xmpModel);
      localizationStrings.configureFromXmp(xmpModel);

      var beforeAfterPlayControlsView = BeforeAfterPlayControlsView.create($container, playerConfiguration);

      var noOp = function() {}; // eslint-disable-line
      var playerMarkup = templateGenerator.generateVideoViewMarkup('quiz-preview-' + new Date().getTime());
      $container.html(playerMarkup);
      var ioOverlayView = IoOverlayView.create($container);
      var mediaView = VideoElementView.create($container, $container.find('.media-container'), ['video.mp4'], true, noOp, noOp, ioOverlayView);

      // hack Camtasia quiz preview template to display preview correctly
      var previewElement = document.getElementsByClassName('video-view');
      if (previewElement.length > 0) {
         previewElement[0].style.height = '100%';
      }

      mediaView.mediaElement.style.display = 'none';

      var quizModel = Quiz.create(xmpModel);
      var quizController = QuizController.create($container, mediaView, quizModel, beforeAfterPlayControlsView, true);
      quizController.displayNextQuestionSet();
   });
};

export default {
   create: CamtasiaQuizPreviewView
};
