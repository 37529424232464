var cssVariables = Object.defineProperties({}, {
   playerWidth: {value: '--player-width'},
   playerHeight: {value: '--player-height'},
   mediaIntrinsicWidth: {value: '--media-intrinsic-width'},
   mediaIntrinsicHeight: {value: '--media-intrinsic-height'},
   mediaIntrinsicAspectRatio: {value: '--media-intrinsic-aspect-ratio'},
   mediaIntrinsicAspectRatioNumber: {value: '--media-intrinsic-aspect-ratio-number'},
   mediaViewPortWidth: {value: '--media-viewport-width'},
   mediaViewPortHeight: {value: '--media-viewport-height'},
   mediaViewPortAspectRatio: {value: '--media-viewport-aspect-ratio'},
   mediaViewPortAspectRatioNumber: {value: '--media-viewport-aspect-ratio-number'},
   reservedSizeScorm: {value: '--reserved-size-scorm-controls'},
   reservedSizeCaptions: {value: '--reserved-size-captions'},
   reservedSizeControls: {value: '--reserved-size-controls'},
   tocTopOffset: {value: '--toc-top-offset'}
});

export default cssVariables;
