import textSanitizer from '../utils/text-sanitizer';

var TIME_SALT = new Date().getMilliseconds();
var UNIQUE_TOKEN_1 = 'MJB_!_1_@__#_' + TIME_SALT;
var UNIQUE_TOKEN_2 = 'MJB_!_2_@__#_' + TIME_SALT;
var REGEX_REPLACEMENT_STRING = UNIQUE_TOKEN_1 + '$1' + UNIQUE_TOKEN_2;
var TOKEN_1_REGEX = new RegExp(UNIQUE_TOKEN_1, 'g');
var TOKEN_2_REGEX = new RegExp(UNIQUE_TOKEN_2, 'g');
var REGEX_ESCAPE = /[.*+?^${}()|[\]\\]/g;

var createHighlightedText = function(text, searchRegex) {
   var textWithSearchMatchPlaceholders = text.replace(searchRegex, REGEX_REPLACEMENT_STRING);
   var sanitizedText = textSanitizer.htmlEncode(textWithSearchMatchPlaceholders);
   return sanitizedText.replace(TOKEN_1_REGEX, '<span class="found-search-text">')
      .replace(TOKEN_2_REGEX, '</span>');
};

var simpleSearch = function(items, searchString) {
   var matches = [];
   if (!searchString) {
      return matches;
   }

   var searchRegex = new RegExp('(' + searchString.replace(REGEX_ESCAPE, '\\$&') + ')', 'gi');

   items.forEach(function(item) {
      var itemText = item.plainText || item.text || item.name || '';
      if (itemText.toLowerCase().indexOf(searchString) !== -1) {
         matches.push({
            rawText: itemText,
            text: createHighlightedText(itemText, searchRegex),
            startTime: item.startTime,
            file: item.file,
            imageRef: item.image,
            imageRect: item.imageRectangle
         });
      }
   });
   return matches;
};

var searchXmpForString = function(xmp, searchString) {
   searchString = searchString.toLowerCase();
   var itemsToSearch = xmp.tocItemArray.concat(xmp.captionItems).concat(xmp.screenTextItemArray);

   // captions are speech to text converted
   if (xmp.captionItems.length < 1 && xmp.speechTextItemArray.length > 0) {
      itemsToSearch = itemsToSearch.concat(xmp.speechTextItemArray);
   }

   return simpleSearch(itemsToSearch, searchString);
};

export default {
   searchXmpForString: searchXmpForString
};
