import events from '../constants/events';
import templateGenerator from '../utils/template-generator';

var SYNC_TOLERANCE_TIME_IN_SECONDS = .5;
var BUFFER_TIME_OFFSET_IN_SECONDS = 1;

/**
 * @memberof TSC
 * @class AudioDescriptionTrackView
 * @classdesc Audio Description Track
 *  @param {Object} $container - jQuery element to add audio description track audio dom.
 *  @param {Object} mediaElement - html5 media DOM element (or wrapper presenting same interface)
 *  @param {String} audioDescriptionMediaSrc - Audio source string
 */
var AudioDescriptionTrackView = function($container, mediaElement, audioDescriptionMediaSrc) {
   var _audioDescriptionAudioEl = null;
   var _audioTrackEnabled = false;
   var _videoIsSeeking = false;
   var _videoIsBuffering = false;
   var _lastBufferedVideoTime = 0;

   var onAudioError = function() {
      destroy();
      $container.trigger(events.AudioDescription.Failed);
   };

   var createView = function() {
      var _markup = templateGenerator.generateAudioDescriptionTrackMarkup(audioDescriptionMediaSrc);
      $container.find('.audio-description-track-wrapper').html(_markup);
      _audioDescriptionAudioEl = $container.find('.audio-description-track')[0];
   };

   var syncAudioTimeWithVideoTime = function() {
      if (!isNaN(_audioDescriptionAudioEl.duration)) {
         _audioDescriptionAudioEl.currentTime = mediaElement.currentTime;
      }
   };

   var syncAudioVolumeWithVideoVolume = function() {
      _audioDescriptionAudioEl.volume = mediaElement.muted ? 0 : mediaElement.volume;
   };

   var syncAudioPlaybackRateWithVideoPlaybackRate = function() {
      _audioDescriptionAudioEl.playbackRate = mediaElement.playbackRate;
      _audioDescriptionAudioEl.defaultPlaybackRate = mediaElement.playbackRate;
   };

   var pauseAudioDescription = function() {
      _audioDescriptionAudioEl.pause();
   };

   var onVideoPlaying = function() {
      syncAudioTimeWithVideoTime();
   };

   var onVideoStartSeeking = function() {
      _videoIsSeeking = true;

      // some browsers do not fire 'waiting' event when seeking and no data is buffered
      // manually fire this to make sure player pauses audio and checks for video buffering
      onWaiting();

      _audioDescriptionAudioEl.volume = 0;
      pauseAudioDescription();
   };

   var onVideoSeeked = function() {
      _videoIsSeeking = false;
      syncAudioTimeWithVideoTime();
   };

   var playAudioDescriptionIfVideoIsPlaying = function() {
      if (mediaElement.paused || _videoIsBuffering) {
         return;
      }
      var promise = _audioDescriptionAudioEl.play();
      if (promise !== undefined) {
         promise.catch(function() {}); // eslint-disable-line
      }
   };

   var onRateChange = function() {
      syncAudioPlaybackRateWithVideoPlaybackRate();
      syncAudioTimeWithVideoTime();
   };

   var onAudioTrackSeeked = function() {
      if (_videoIsSeeking) {
         return;
      }

      var mediaTimeDelta = Math.abs(mediaElement.currentTime - _audioDescriptionAudioEl.currentTime);
      if (mediaTimeDelta >= SYNC_TOLERANCE_TIME_IN_SECONDS) {
         syncAudioTimeWithVideoTime();
      } else {
         syncAudioVolumeWithVideoVolume();
         playAudioDescriptionIfVideoIsPlaying();
      }
   };

   var onAudioDescriptionControlChange = function(e, eventData) {
      e.preventDefault();
      _audioTrackEnabled = eventData.audioDescriptionEnabled;
      _audioDescriptionAudioEl.muted = !_audioTrackEnabled;
      syncAudioTimeWithVideoTime();
      playAudioDescriptionIfVideoIsPlaying();
   };

   var onWaiting = function() {
      _videoIsBuffering = true;
      if (!_audioDescriptionAudioEl.paused) {
         pauseAudioDescription();
      }
   };

   var onTimeUpdate = function() {
      if (_videoIsBuffering) {
         var timeDiff = Math.abs(mediaElement.currentTime - _lastBufferedVideoTime);
         if (timeDiff > BUFFER_TIME_OFFSET_IN_SECONDS) {
            _videoIsBuffering = false;
            syncAudioTimeWithVideoTime();
         }
      } else {
         _lastBufferedVideoTime = mediaElement.currentTime;
      }
   };

   var initializeHandlers = function() {
      _audioDescriptionAudioEl.addEventListener('error', onAudioError);
      _audioDescriptionAudioEl.addEventListener('seeked', onAudioTrackSeeked);
      _audioDescriptionAudioEl.addEventListener('pause', syncAudioTimeWithVideoTime);
      $container.on(events.AudioDescription.Changed, onAudioDescriptionControlChange);
      mediaElement.addEventListener('playing', onVideoPlaying);
      mediaElement.addEventListener('seeking', onVideoStartSeeking);
      mediaElement.addEventListener('seeked', onVideoSeeked);
      mediaElement.addEventListener('pause', pauseAudioDescription);
      mediaElement.addEventListener('volumechange', syncAudioVolumeWithVideoVolume);
      mediaElement.addEventListener('ratechange', onRateChange);
      mediaElement.addEventListener('ended', pauseAudioDescription);
      mediaElement.addEventListener('waiting', onWaiting);
      mediaElement.addEventListener('timeupdate', onTimeUpdate);
   };

   var destroy = function() {
      _audioDescriptionAudioEl.removeEventListener('error', onAudioError);
      _audioDescriptionAudioEl.removeEventListener('seeked', onAudioTrackSeeked);
      _audioDescriptionAudioEl.removeEventListener('pause', syncAudioTimeWithVideoTime);
      $container.off(events.AudioDescription.Changed, onAudioDescriptionControlChange);
      mediaElement.removeEventListener('playing', onVideoPlaying);
      mediaElement.removeEventListener('seeking', onVideoStartSeeking);
      mediaElement.removeEventListener('seeked', syncAudioTimeWithVideoTime);
      mediaElement.removeEventListener('pause', pauseAudioDescription);
      mediaElement.removeEventListener('volumechange', syncAudioVolumeWithVideoVolume);
      mediaElement.removeEventListener('ratechange', onRateChange);
      mediaElement.removeEventListener('ended', pauseAudioDescription);
      mediaElement.removeEventListener('waiting', onWaiting);
      mediaElement.removeEventListener('timeupdate', onTimeUpdate);
   };

   createView();
   initializeHandlers();
   syncAudioVolumeWithVideoVolume();

   return Object.defineProperties({
      destroy: destroy
   }, {});
};

export default {
   /**
    *  Factory method that returns a new AudioDescriptionTrackView object.
    *  @function create
    *  @memberof TSC.AudioDescriptionTrackView
    *  @param {Object} $container - jQuery element to add audio description track audio dom.
    *  @param {Object} mediaElement - html5 media DOM element (or wrapper presenting same interface)
    *  @param {String} audioDescriptionMediaSrc - Audio source string
    *  @static
    *  @return new AudioDescriptionTrackView instance
    */
   create: AudioDescriptionTrackView
};
