import playerType from '../constants/player-type';
import mediaType from '../constants/media-type';
import deviceInfo from '../utils/device-info';

var _currentPlayerType;
var MIN_IE_VERSION = 9;

var getPlayerType = function(mediaList) {
   var haveImage = mediaList.hasImage;
   var haveWebMAndCanPlay = mediaList.isMediaTypeAvailable(mediaType.WEBM) && deviceInfo.canPlayWebM();
   var haveMP4AndCanPlay = mediaList.isMediaTypeAvailable(mediaType.MP4) && deviceInfo.canPlayMP4();
   var haveOGGAndCanPlay = mediaList.isMediaTypeAvailable(mediaType.OGG) && deviceInfo.canPlayOgg();
   var haveYoutubeEmbedUrl = mediaList.isMediaTypeAvailable(mediaType.YOUTUBE);

   if (deviceInfo.isIE() && deviceInfo.getIEVersion() !== null && deviceInfo.getIEVersion() <= MIN_IE_VERSION) {
      return playerType.NOT_SUPPORTED;
   }

   if (haveYoutubeEmbedUrl) {
      return playerType.YOUTUBE;
   }

   if (haveWebMAndCanPlay || haveMP4AndCanPlay || haveOGGAndCanPlay) {
      return playerType.HTML5_VIDEO;
   }

   if (haveImage) {
      return playerType.IMAGE;
   }

   return playerType.NOT_SUPPORTED;
};

export default {
   getPlayerType: function(mediaList) {
      return _currentPlayerType || (_currentPlayerType = getPlayerType(mediaList));
   },
   clearPlayerType: function() {
      _currentPlayerType = undefined;
   }
};
